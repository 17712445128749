import { remoteAction } from '../../../services/actionService.js';
import * as R from 'ramda';
import api from '../../../services/api.js';
import * as at from '../../../actionTypes';
import {
  LocalDate,
  LocalTime,
} from '@js-joda/core';
import {
  now,
  format,
  timeConvert,
} from '../../../services/joda.js';
import { popup } from '../../../services/Popup.js';

export const addHoursPushed = () => ({
  type: at.OFFICE_HOURS_ADD_PUSHED,
  data: {
    id: undefined,
    start: format(now('date'), 'yyyy-MM-dd'),
    hours: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    }
  }
});

export const dateChanged = data => ({
  type: at.OFFICE_HOURS_DATE_CHANGED,
  data,
});

export const editHoursPushed = (id) => {
  return ({
    type: at.OFFICE_HOURS_EDIT_PUSHED,
    data: Number(id),
  });
};

export const cancelHoursPushed = () => {
  return ({
    type: at.OFFICE_HOURS_CANCEL_PUSHED,
  });
};

export const removeOpenPushed = (data) => {
  return ({
    type: at.OFFICE_HOURS_REMOVE_OPEN_PUSHED,
    data,
  });
};


export const timeChanged = (data) => {
  return ({
    type: at.OFFICE_HOURS_TIME_CHANGED,
    data,
  });
};

export const saveHoursPushed = (editing) => {
  const toTime = ({ hour, minute, a }) => (
    format(LocalTime.parse(timeConvert([hour, minute, a])), 'HH:mm:ss')
  );
  const saving = R.evolve({
    hours: R.mapObjIndexed(
      R.map(
        R.mapObjIndexed(toTime)
      )
    ),
    start: (start) => format(LocalDate.parse(start), 'yyyy-MM-dd'),
  })(editing);
  if (saving.id !== undefined) {
    return remoteAction({
      type: at.OFFICE_HOURS_SAVE_PUSHED,
      action: () =>
        api.put(`officeHours/${editing.id}`, saving)
          .then(
            () =>
              api.get('officeHours'))
          .catch((error) => {
            popup('Error!', 'Failed to save hours.');
            console.error(error);
          }),
    });
  } else {
    return remoteAction({
      type: at.OFFICE_HOURS_SAVE_PUSHED,
      action: () =>
        api.post('officeHours', saving)
          .then(
            () =>
              api.get('officeHours'))
          .catch((error) => {
            popup('Error!', 'Failed to save hours.');
            console.error(error);
          }),
    });
  }
};

export const closedPushed = (data) => ({
  type: at.OFFICE_HOURS_CLOSED_PUSHED,
  data,
});

export const addOpeningPushed = (data) => ({
  type: at.OFFICE_HOURS_ADD_OPENING_PUSHED,
  data,
});

export const get = () => remoteAction({
  type: at.OFFICE_HOURS_REMOTE_GET,
  action: () => api.get('officeHours')
});

export const removePushed = (id) => remoteAction({
  type: at.OFFICE_HOURS_REMOVE_PUSHED,
  action: () =>
    api.delete(`officeHours/${id}`)
      .then(
        () =>
          api.get('officeHours'))
      .catch((error) => {
        popup('Error!', 'Failed to remove hours.');
        console.error(error);
      }),
  metadata: { id }
});

export const pasteEntireHours = (hours) => ({
  type: at.OFFICE_HOURS_ADD_PUSHED,
  data: {
    isPasted: true,
    hours,
  }
});
