import * as React from 'react';
import {
  Grid, TextField, FormControl, FormControlLabel,
  Checkbox, InputLabel, Select, MenuItem, Button, IconButton,
  ListItemIcon, ListItemText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectIcon from '@mui/icons-material/Subject';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EventIcon from '@mui/icons-material/Event';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ListIcon from '@mui/icons-material/List';
import { OrderButtons } from './SectionChildOrderButtons.component';
import {
  Container, SimpleWidget, SimpleWidgetType, IndexAndConds,
} from '../intake-types';
import * as ctx from '../intake.context';
import { cond, equals, always, T, identity, dissoc } from 'ramda';
import { TipTap } from '../../../components/Editor/editor.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface SimpleWidgetProps {
  data: SimpleWidget;
  hideRequired?: boolean;
  hideWidth?: boolean;
  isEdit?: boolean,
  hideSave?: boolean;
  handleDelete?: () => void;
  onUpdate?: (newWidg: SimpleWidget) => void;
  index?: number;
  maxIndex?: number;
  questionCond?: IndexAndConds;
  noTemp?: boolean;
}

const options: SimpleWidgetType['type'][] = [
  'ShortText',
  'LongText',
  'Number',
  'Email',
  'Date',
  'CheckboxSingle',
  'Checkbox',
  'Radio',
  'Dropdown'
];

interface WidthOptions {
  value: number;
  label: string;
}

const widthOptions: WidthOptions[] = [{
  value: 3,
  label: '25%',
}, {
  value: 4,
  label: '33%',
}, {
  value: 6,
  label: '50%',
}, {
  value: 8,
  label: '66%',
}, {
  value: 9,
  label: '75%',
}, {
  value: 12,
  label: '100%',
}];

const QuestionTypeIcon = (o: SimpleWidgetType['type']) => {
  return cond([
    [equals('ShortText'), () => <ShortTextIcon />],
    [equals('LongText'), () => <SubjectIcon />],
    [equals('Number'), () => <LooksOneIcon />],
    [equals('Email'), () => <AlternateEmailIcon />],
    [equals('Date'), () => <EventIcon />],
    [equals('CheckboxSingle'), () => <CheckBoxIcon />],
    [equals('Checkbox'), () => <FormatListBulletedIcon />],
    [equals('Radio'), () => <RadioButtonCheckedIcon />],
    [equals('Dropdown'), () => <ListIcon />]
  ])(o);
};

const centeredGrid = {
  display: 'flex',
  alignItems: 'center',
};

interface WidgetOptionsProps {
  t: SimpleWidgetType
}

const prettyType = (o: SimpleWidgetType['type']) => cond([
  [equals('ShortText'), always('Short Text')],
  [equals('LongText'), always('Long Text')],
  [equals('CheckboxSingle'), always('Checkbox - Single')],
  [equals('Checkbox'), always('Checkbox - Multiple')],
  [T, identity],
])(o) as string;

const WidgetOptions = ({ t }: WidgetOptionsProps) => {
  if (t.type === 'Radio' ||
    t.type === 'Dropdown' ||
    t.type === 'Checkbox') {
    return (<Grid style={centeredGrid} item xs={12} sm={2}>
      {t.options?.join(', ')}
    </Grid>);
  }
  return <Grid style={centeredGrid} item xs={12} sm={2}></Grid>;
};

interface SimpleWidgetEditProps {
  data: SimpleWidget,
  handleSave: (w: SimpleWidget) => void
  hideRequired?: boolean;
  hideWidth?: boolean;
  hideSave?: boolean;
  handleDelete?: () => void
  onUpdate?: (newWidg: SimpleWidget) => void;
  handleAdd?: () => void;
  noTemp?: boolean;
}

let tempState: {[id: number]: SimpleWidget} = {};
const SimpleWidgetComponentEdit = ({
  data, handleSave, hideRequired, hideWidth,
  hideSave, handleDelete, onUpdate, handleAdd,
  noTemp = false,
}: SimpleWidgetEditProps) => {
  const [state, setState] = React.useState<SimpleWidget>(data);

  React.useEffect(() => {
    return () => {
      /* we don't want to accidentially delete a real question's temp value
         if we unmount a flex/fixed table's column questions
      */
      if (!noTemp) {
        tempState = dissoc(data.id, tempState);
      }
    };
  }, []);

  React.useEffect(() => {
    const oData = tempState[data.id];
    if (oData && !noTemp && !equals(oData, data)) {
      setState(oData);
    } else {
      setState(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (onUpdate) {
      onUpdate(state);
    }
    if (!noTemp) {
      tempState = {
        ...tempState,
        [data.id]: state,
      };
    }
  }, [state]);

  const update = (prop: keyof SimpleWidget, value: string | boolean | number) => {
    if (prop === 'width') {
      setState(w => ({
        ...w,
        [prop]: Number(value)
      }));
    } else {
      setState(w => ({
        ...w,
        [prop]: value
      }));
    }
  };

  const handleUpdate = (prop: keyof SimpleWidget) => (e: React.ChangeEvent<HTMLInputElement>) => {
    update(prop, e.target.value);
  };

  const handleRequiredUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const required = e.target.checked;
    setState(w => ({
      ...w,
      required,
    }));
  };

  const handleOptionsUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const options = e.target.value.split('\n');
    setState(w => ({
      ...w,
      questionType: {
        ...w.questionType,
        options
      }
    }));
  };

  const handleUpdateType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as SimpleWidgetType['type'];
    if (newType === 'ShortText' ||
      newType === 'LongText' ||
      newType === 'Number' ||
      newType === 'Date' ||
      newType === 'Email' ||
      newType === 'CheckboxSingle'
    ) {
      setState(w => ({
        ...w,
        questionType: {
          type: newType
        }
      }));
    }
    if (newType === 'Radio' ||
      newType === 'Dropdown' ||
      newType === 'Checkbox'
    ) {
      setState(w => ({
        ...w,
        questionType: {
          type: newType,
          direction: 'row',
          options: []
        }
      }));
    }
  };

  const widgetType = state.questionType.type;

  if (widgetType === 'SectionInstruction') {
    return (
      <div>
        <TipTap
          content={state.label}
          onChange={(body) => update('label', body)}
          width='100%'
          height={200}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          className='sked-test-form-builder-section-instruction-save-button'
          onClick={() => handleSave(state)}>
          Save
        </Button>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={1} style={{ marginBottom: '7px', marginTop: '7px' }}>
        <Grid item xs={12} sm={2}>
          <TextField
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
            value={state.label}
            onChange={handleUpdate('label')}
            type="text"
            label="Label"
            placeholder='New Question'
          />
          <br />
          {widgetType !== 'Checkbox' &&
            widgetType !== 'CheckboxSingle' &&
            widgetType !== 'Radio' &&
            widgetType !== 'Date' &&
            widgetType !== 'Number' &&
            <TextField
              style={{ width: '100%', marginTop: '5px' }}
              InputLabelProps={{ shrink: true }}
              value={state.placeholder}
              onChange={handleUpdate('placeholder')}
              type="text"
              label="Placeholder"
              placeholder='Placeholder Text'
            />}
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel shrink={true}>Question Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleUpdateType}
              value={prettyType(state.questionType.type)}
              renderValue={(s: string) => {
                return s;
              }}
            >
              {options.map(o => {
                const primary = prettyType(o);
                return (
                  <MenuItem key={o} value={o}>
                    <ListItemIcon>{QuestionTypeIcon(o)}</ListItemIcon>
                    <ListItemText primary={primary} style={{ fontSize: '12px' }} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {!hideRequired &&
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.required || false}
                  onChange={handleRequiredUpdate}
                  color="primary"
                />}
              label="Required" />
          </Grid>}
        {!hideWidth &&
          <Grid item xs={12} sm={2}>
            <Select
              style={{ width: '100%' }}
              required={true}
              value={state.width}
              onChange={handleUpdate('width')}
              className={
                `sked-test-form-builder-width-${state.questionType.type.toLowerCase()}-button`
              }
              label="Width">
              {widthOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              ))}
            </Select>
          </Grid>}
        {!hideSave &&
          <Grid item xs={12} sm={2}>
          </Grid>}
        {!hideSave &&
          <Grid item xs={12} sm={2}>
            <Button
              className={
                `sked-test-form-builder-save-${state.questionType.type.toLowerCase()}-button`
              }
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleSave(state)}
            >
              Save
            </Button>
            {handleAdd &&
              <IconButton
                className={
                  `sked-test-form-builder-add-${state.questionType.type.toLowerCase()}-button`
                }
                aria-label="add question"
                onClick={handleAdd}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>}
          </Grid>}
        {hideSave && handleDelete &&
          <Grid item xs={12} sm={2}>
            <IconButton
              className={
                `sked-test-form-builder-delete-${state.questionType.type.toLowerCase()}-button`
              }
              onClick={handleDelete}>
              <TrashIcon fontSize="inherit" />
            </IconButton>
          </Grid>}
      </Grid>
      {(widgetType === 'Radio' || widgetType === 'Checkbox' || widgetType === 'Dropdown') &&
        <Grid container spacing={1} style={{ marginBottom: '15px' }}>
          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              multiline
              minRows={6}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={state.questionType?.options?.join('\n')}
              onChange={handleOptionsUpdate}
              label="Options - one per line" />
          </Grid>
        </Grid>
      }
    </div>
  );
};

const SimpleWidgetComponentInner = ({
  data, hideWidth, hideRequired, isEdit, hideSave,
  handleDelete, onUpdate, index, questionCond, noTemp = false,
  maxIndex,
}: SimpleWidgetProps) => {
  const dispatch = useDispatch();

  const widgetType = data.questionType.type;
  const [edit, setEdit] = React.useState(isEdit || data.isEdit);
  const toggleEdit = () => {
    setEdit(!edit);
  };
  const handleSave = (w: SimpleWidget) => {
    setEdit(!edit);
    dispatch(ctx.updateSimpleWidget({
      ...w,
      isEdit: false,
    }));
  };

  const handleAdd = () => {
    dispatch(ctx.addSimpleWidgetAfter(data.id, questionCond.index));
  };

  const localHandleDelete = handleDelete ? handleDelete :
    () => dispatch(ctx.deleteSectionChild(data.id, questionCond.index));

  React.useEffect(() => {
    setEdit(isEdit || data.isEdit);
  }, [data]);


  if (!edit) {
    if (data.questionType.type === 'SectionInstruction') {
      return (
        <Grid id={`simple-${data.id}`} key={`simple-${data.id}`} container style={{ marginBottom: 5, marginTop: 25 }}>
          <Grid style={centeredGrid} item xs={12} sm={10}>
            <div
              onClick={() => setEdit(true)}
              dangerouslySetInnerHTML={{ __html: data.label }} />
          </Grid>
          <Grid style={{ ...centeredGrid, justifyContent: 'flex-end' }} item xs={12} sm={2}>
            <IconButton onClick={toggleEdit} aria-label="edit">
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton aria-label="add question" onClick={handleAdd}>
              <AddIcon fontSize="inherit" />
            </IconButton>
            <OrderButtons
              index={index}
              sectionMaxIndex={maxIndex}
              data={data}
              id={data.id}
              questionIndex={null}
              handleDelete={localHandleDelete}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <div id={`simple-${data.id}`} key={`simple-${data.id}`}>
        {!data.forOffice && <QuestionLabel cond={questionCond} />}
        <Grid container style={{ marginBottom: 5, marginTop: 25 }}>
          <Grid style={centeredGrid} item xs={12} sm={2}>
            <br/>
            <div style={{ overflowWrap: 'anywhere' }}>
              {data.label}
              {widgetType !== 'Checkbox' &&
              widgetType !== 'CheckboxSingle' &&
              widgetType !== 'Radio' &&
              widgetType !== 'Date' &&
              widgetType !== 'Number' &&
              data.placeholder !== '' &&
              <>
                <br />
                <p style={{ color: 'gray' }}>{data.placeholder}</p>
              </>}
            </div>
          </Grid>
          {!hideRequired &&
          <Grid style={centeredGrid} item xs={12} sm={2}>
            Required? {data.required ? 'yes' : 'no'}
          </Grid>}
          <Grid style={centeredGrid} item xs={12} sm={2}>
            {prettyType(data.questionType.type)}
          </Grid>
          {!hideWidth &&
          <Grid style={centeredGrid} item xs={12} sm={2}>
            {Math.trunc(data.width / 12 * 100) + '%'}
          </Grid>}
          <WidgetOptions t={data.questionType} />
          <Grid style={{ ...centeredGrid, justifyContent: 'flex-end' }} item xs={12} sm={2}>
            <IconButton onClick={toggleEdit} aria-label="edit">
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton aria-label="add question" onClick={handleAdd}>
              <AddIcon fontSize="inherit" />
            </IconButton>
            <OrderButtons
              index={index}
              sectionMaxIndex={maxIndex}
              data={data}
              id={data.id}
              questionIndex={questionCond.index}
              type='Question'
              handleDelete={localHandleDelete}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <SimpleWidgetComponentEdit
      data={data}
      handleSave={handleSave}
      hideRequired={hideRequired}
      hideWidth={hideWidth}
      hideSave={hideSave}
      handleDelete={localHandleDelete}
      handleAdd={handleAdd}
      onUpdate={onUpdate}
      noTemp={noTemp}
    />
  );
};

export const SimpleWidgetComponent = React.memo(SimpleWidgetComponentInner, (prev, next) => {
  return equals(prev, next);
});

interface ContainerProps {
  data: Container
}

export const ContainerComponent = ({ data }: ContainerProps) => {
  return <div style={{ borderBottom: 'solid 2px' }}>
    {data.children.map((w: SimpleWidget) => {
      return <div>
        <SimpleWidgetComponent data={w} />
      </div>;
    })}
  </div>;
};
