import * as at from '../../actionTypes';
import * as R from 'ramda';

const convertFromReplyToConversation = message => ({
  clientId: message.client.id,
  from: message.from.FromOffice ? 'Off' : 'Cli',
  msgId: message.msgId,
  officeId: message.from.FromOffice ? message.from.FromOffice : message.to.ToOffice.officeId,
  updatedAt: message.created,
  message: message.smsData && message.smsData.body,
  client: message.client,
  unread: message.from.FromOffice ? false : !message.to.ToOffice.hasRead,
});

const init = {
  busy: false,
  state: 'LIST',
  tab: 'messages',
  all: false,
  page: 1,
  filter: 'name',
  messages: [],
  messagePage: 1,
  perPage: 50,
  totalMessagePages: 1,
  totalMessageCount: 1,
  pending: [],
  drafts: [],
  inbox: [],
  inboxPage: 1,
  totalInboxPage: 1,
  totalInboxCount: 1,
  allLoaded: false,
  inboxFilter: 'name',
  templates: [],
  officeTemplates: [],
  query: '',
  noFolder: false,
  unread: false,
  templatePage: 1,
  totalTemplatePages: 1,
  totalTemplateCount: 1,
  officeTemplatePage: 1,
  totalOfficeTemplatePages: 1,
  totalOfficeTemplateCount: 1,
  contactType: '',
  smsTab: 'all',
  scheduled: [],
  sentScheduled: [],
};

export default function reducer(messages = init, action) {
  switch (action.type) {
    case at.MESSAGES_PATCH:
      return R.merge(messages, action.data);
    case at.MESSAGES_SELECT_PATCH:
      return R.evolve({
        [action.data.type]: (list) => {
          const message = R.pipe(
            R.nth(action.data.index),
            R.merge(R.__, { selected: action.data.value })
          )(list);
          return (R.update(action.data.index, message, list));
        }
      }, messages);
    case at.MESSAGES_SELECT_ALL_PATCH:
      return R.evolve({
        all: () => action.data.value,
        [action.data.type]: R.map((message) => R.merge(message, { selected: action.data.value }))
      }, messages);
    case at.MESSAGES_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(messages, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          msgs,
          drafts,
          officeTemplates,
          skedTemplates,
          hasApp,
          pending,
        } = action.data;
        const d = R.merge(messages, {
          messages: msgs.data ? R.pipe(
            R.map((message) => R.merge(message, message.messageType.OneTime)),
            R.sortBy(R.prop('createdAt')),
            R.reverse
          )(msgs.data.data) : msgs,
          messagePage: msgs.data ? msgs.data.page : 1,
          totalMessagePages: msgs.data ? msgs.data.totalPages : 1,
          totalMessageCount: msgs.data ? msgs.data.totalCount : 1,
          pending: pending.data ? R.pipe(
            R.map((message) => R.merge(message, message.messageType.OneTime)),
            R.sortBy(R.prop('createdAt')),
            R.reverse
          )(pending.data.data) : pending,
          drafts: drafts.data ? R.pipe(
            R.map((message) => R.merge(message, message.messageType.OneTime)),
            R.sortBy(R.prop('createdAt')),
            R.reverse
          )(drafts.data.data) : drafts,
          templates: skedTemplates.data ?
            R.pipe(
              R.ifElse(
                R.and(hasApp),
                R.identity,
                R.filter(({ name }) => !R.includes('Introducing', name))
              ),
              R.sortBy(R.prop('createdAt')),
              R.reverse,
            )(skedTemplates.data.data) :
            skedTemplates,
          templatePage: skedTemplates.data ? skedTemplates.data.page : 1,
          totalTemplatePages: skedTemplates.data ? skedTemplates.data.totalPages : 1,
          totalTemplateCount: skedTemplates.data ? skedTemplates.data.totalCount : 1,
          officeTemplates: officeTemplates.data ? R.pipe(
            R.sortBy(R.prop('createdAt')),
            R.reverse
          )(officeTemplates.data.data) : officeTemplates,
          officeTemplatePage: officeTemplates.data ? officeTemplates.data.page : 1,
          totalOfficeTemplatePages: officeTemplates.data ? officeTemplates.data.totalPages : 1,
          totalOfficeTemplateCount: officeTemplates.data ? officeTemplates.data.totalCount : 1,
          busy: false,
        });
        return d;
      } else if (action.state === 'ERROR') {
        return R.merge(messages, {
          busy: false,
        });
      }
      return messages;
    }
    case at.INBOX_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(messages, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        const [messageThreads, scheduled, sentScheduled] = action.data;
        const d = R.merge(messages, {
          busy: false,
          inbox: R.reverse(R.sortBy(R.prop('updatedAt'))(messageThreads.data)),
          inboxPage: messageThreads.page,
          totalInboxPage: messageThreads.totalPages,
          totalInboxCount: messageThreads.totalCount,
          allLoaded: messageThreads.data?.length < messages.perPage,
          scheduled,
          sentScheduled,
        });
        return d;
      } else if (action.state === 'ERROR') {
        return R.merge(messages, {
          busy: false,
        });
      }
      return messages;
    }
    case at.MESSAGES_DELETE_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(messages, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        if (action.data.type) {
          return R.merge(messages, {
            busy: false,
            all: false,
            officeTemplates: R.pipe(
              R.filter(({ isDeleted }) => !isDeleted),
              R.sortBy(R.prop('createdAt')),
              R.reverse
            )((action.data.officeTemplates))
          });
        }
        return R.merge(messages, {
          messages: R.pipe(
            R.map((message) => R.merge(message, message.messageType.OneTime)),
            R.sortBy(R.prop('createdAt')),
            R.reverse)(action.data.messages),
          pending: R.pipe(
            R.map((message) => R.merge(message, message.messageType.OneTime)),
            R.sortBy(R.prop('createdAt')),
            R.reverse
          )(action.data.pending),
          drafts: R.pipe(
            R.map((message) => R.merge(message, message.messageType.OneTime)),
            R.sortBy(R.prop('createdAt')),
            R.reverse)(action.data.drafts),
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(messages, {
          busy: false,
        });
      }
      return messages;
    }
    case at.MESSAGES_READ_REMOTE_GET:{
      const data = R.reverse(R.sortBy(R.prop('updatedAt'))(action.data));
      return R.merge(messages, {
        inbox: data,
        all: false,
        allLoaded: data.length < messages.perPage,
      });
    }
    case at.MESSAGES_REMOTE_DELETE: {
      if (action.state === 'REQUEST') {
        return R.merge(messages, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(messages, {
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(messages, {
          busy: false,
        });
      }
      return messages;
    }
    case at.MESSAGES_REMOTE_GET_SEARCH: {
      if (action.state === 'REQUEST') {
        return R.merge(messages, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(messages, action.data);
      } else if (action.state === 'ERROR') {
        return R.merge(messages, {
          busy: false,
        });
      }
      return messages;
    }
    case at.MESSAGE_THREAD_NEW_REPLY: {
      // Convert to the right format
      const newMessage = convertFromReplyToConversation(action.data);
      const isCurrentInbox = R.includes('inbox', window.location.href);
      const contactType = action.contactType;
      const fromLead = newMessage.client.isLead;

      if (isCurrentInbox && (fromLead && contactType !== 'Client') || (!fromLead && contactType !== 'Lead')) {
        if (R.has('OneOff', action.data.service)) {
          return R.evolve({
            inbox: R.pipe(
              // Remove the old message from the conversation
              R.reject(R.propEq('clientId', newMessage.clientId)),
              // add the new one in
              R.prepend(newMessage)
            )
          }, messages);
        }
      }
      return messages;
    }
    case at.MESSAGE_THREAD_READ_MESSAGE: {
      const message = action.data;
      const idx = R.findIndex(R.propEq('clientId', message.client.id), messages.inbox);
      return R.evolve({
        inbox: R.adjust(idx, R.assoc('unread', false))
      }, messages);
    }
    case at.MESSAGE_SENT_SCHEDULED: {
      const fake = {
        id: action.data.msgId,
        messageType: {
          OneTime: {
            receivers: {
              Clients: [action.data.client.id],
            },
            scheduledFor: action.data.created,
          },
        },
        sms: {
          body: action.data.smsData.body,
        },
      };
      return R.evolve({
        scheduled: R.filter(({ id }) => {
          return id !== action.data.service.SkedMessage.msgId;
        }),
        sentScheduled: R.prepend(fake),
      }, messages);
    }
    default:
      return messages;
  }
}
