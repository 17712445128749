import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from '../../../reducers';
import { useNavigate } from 'react-router-dom';
import {
  Popover, MenuItem, ListItemText, ListItemIcon, CircularProgress,
  InputAdornment, Typography, IconButton, Button,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNew from '../../../icons/OpenInNewTab.icon';
import PaperAirplane from '../../../icons/PaperAirplane.icon';
import WarningIcon from '../../../icons/WarningWithBackground.icon';
import { TextField, Tooltip } from '../../../components/components.component';
import { usePromise } from '../../../services/promise.hook';
import { isEmpty, take, drop, slice } from 'ramda';
import {
  getReplies, replacePlaceholders, generateDefaults,
} from '../../Settings/routes/QuickReplies/quick-replies.actions';
import { Client } from '../../Clients/clients.types';
import {
  getPros,
} from '../../../components/AutomationBasedMessage/automation-message.actions';
import {
  getAppts,
} from '../../Clients/components/client-dialog/client-dialog.actions';
import { Location } from '../../../components/hours';
import {
  getLoc,
} from '../../Settings/routes/Business/business.actions.js';
import { now, format } from '../../../services/joda.js';
import { EditDialog } from '../../Settings/routes/QuickReplies/quick-replies.page';

const RPopover = withStyles({
  paper: {
    borderRadius: 10,
    overflow: 'hidden',
  },
})(Popover);


const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateRows: '43px auto',
    fontSize: 12,
    width: 300,
    height: 300,
    pointerEvents: 'all',
    overflow: 'hidden',
  },
  pad: {
    padding: '15px 20px',
  },
  searchContainer: {
    padding: 5,
    borderBottom: '1px solid lightgray',
    height: 32,
    zIndex: 1302
  },
  searchContents: {
    overflow: 'auto',
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
  bodyPart: {
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  link: {
    color: '#008bcf',
    marginRight: 5,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 3,
  },
  smallGrid: {
    display: 'grid',
    gridTemplateColumns: 'min-content',
  },
}));

const usePreviewStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 300 - 32, // 32 for the padding
    maxHeight: 200,
    padding: 16,
    pointerEvents: 'all',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 16,
    color: '#4d4d4d',
    fontWeight: 500,
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
  body: {
    color: 'gray',
    maxHeight: 135,
    overflowY: 'auto',
    fontSize: 14,
  },
  buttonLeft: {
    alignSelf: 'flex-start',
  },
}));

interface QuickReplyProps {
  open: boolean;
  onClose: () => void;
  onClick: (s: string, id: number, fire: boolean, warning: boolean) => void;
  anchorEl: HTMLButtonElement;
  client: Client;
  pageWidth: number;
}

interface QuickReply {
  id: number;
  name: string;
  body: string;
  created: string;
  updated: string;
}

interface PreviewProps {
 anchorEl: HTMLElement;
 item: QuickReply;
 enteredPopover: () => void;
 manage: () => void;
 edit: () => void;
 direction: 'left' | 'right' | 'top';
}

const Preview = ({
  anchorEl, item, enteredPopover, manage, edit, direction = 'left',
}: PreviewProps) => {
  const classes = usePreviewStyles();
  const opposite = useMemo(() => {
    return direction === 'left' ? 'right' : 'left';
  }, [direction]);
  const editQuickReply = () => {
    edit();
  };
  if (!item) {
    return null;
  }
  return (
    <RPopover
      id='mouse-over-popover'
      style={{ zIndex: 1302 }}
      sx={{ pointerEvents: 'none' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: direction === 'top' ? direction : 'center',
        horizontal: direction === 'top' ? 'center' : direction,
      }}
      transformOrigin={{
        vertical: direction === 'top' ? 'bottom' : 'center',
        horizontal: direction === 'top' ? 'center' : opposite,
      }}
      open={Boolean(anchorEl)}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <div className={classes.root} onMouseEnter={enteredPopover}>
        <div className={classes.header}>
          <span className={classes.headerText}>
            {item.name}
          </span>
          <Button onClick={editQuickReply}>
            Edit
          </Button>
        </div>
        <div className={classes.body}>
          <i>{item.body}</i>
        </div>
        <Button
          className={classes.buttonLeft}
          onClick={manage}
          startIcon={<OpenInNew color='#008bcf' />}
        >
          Manage quick replies
        </Button>
      </div>
    </RPopover>
  );
};

const scrollIntoView = (t: number) => {
  document.getElementById(`quick-reply-item-${t}`).scrollIntoView({
    block: 'nearest',
  });
};

let tm: NodeJS.Timeout;
let closeTimeout: NodeJS.Timeout;
let longTime: NodeJS.Timeout;
let savedQuery = '';
const QuickReply = ({
  open, anchorEl, onClose, onClick, client, pageWidth,
}: QuickReplyProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const searchRef = React.useRef<HTMLInputElement>();
  const popoverRef = React.useRef();
  const scroller = React.useRef();
  const [query, setQuery] = useState(savedQuery);
  const [localData, setLocalData] = useState([]);
  const [previewAnchorEl, setPreviewAnchorEl] = useState(null);
  const [current, setCurrent] = useState(null);
  const [editReply, setEditReply] = useState(null);
  const [highlighted, setHighlighted] = useState(0); // index of localData

  const { office, features } = useSelector((state) => ({
    office: state.login.office,
    features: state.login.features,
  }));

  const proState = usePromise(getPros, []);
  const apptState = usePromise(getAppts, {
    data: [],
  });
  const locState = usePromise<void, Location[]>(getLoc, []);

  const handleClose = () => {
    savedQuery = query;
    setHighlighted(0);
    setPreviewAnchorEl(null);
    setCurrent(null);
    onClose();
  };

  const state = usePromise(getReplies, {
    data: [], perPage: 1, totalCount: 1, totalPages: 1,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    setHighlighted(0);
    setPreviewAnchorEl(null);
    setCurrent(null);
    if (open) {
      state.invoke({
        name: '', sort: 'Last Used (Most Recent First)'
      });
    } else {
      setQuery(savedQuery);
    }
  }, [open]);

  useEffect(() => {
    if (client?.id && open) {
      proState.invoke({});
      apptState.invoke({
        page: 1, perPage: 1, query: {
          clientId: client.id,
          after: format(now('tz', office.timezone), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          status: ['Scheduled'],
        } });
      if (features.includes('Locations')) {
        locState.invoke();
      }
    }
  }, [client, open]);

  useEffect(() => {
    if (tm) {
      clearTimeout(tm);
    }
    closePopover(true);
    const newData = state.data.data.
      filter(({ name }: {name: string}) => {
        return name.toLowerCase().includes(query.toLowerCase());
      });
    tm = setTimeout(() => {
      setLocalData(newData);
    }, 10);
  }, [query, state.data]);


  useEffect(() => {
    if (searchRef.current) {
      searchRef.current?.focus();
      searchRef.current?.select();
    }
  }, [searchRef.current]);

  const direction = useMemo(() => {
    const rootWidth = document.getElementById('root').clientWidth;
    if (rootWidth < 650) {
      return 'top';
    } else {
      return pageWidth > 650 ? 'right' : 'left';
    }
  }, [pageWidth]);

  const hasNext = useMemo(() => {
    return !isEmpty(apptState.data.data);
  }, [apptState.data.data]);

  const openPopover = (item: QuickReply, idx: number) => (e: React.MouseEvent<HTMLElement>) => {
    const elem = e.currentTarget;
    setPreviewAnchorEl(
      direction === 'top' ? popoverRef.current : elem
    );
    setCurrent(item);
    setHighlighted(idx);
  };

  const closePopover = (immediate = false) => {
    closeTimeout = setTimeout(() => {
      setPreviewAnchorEl(null);
      setCurrent(null);
    }, immediate ? 0 : 500);
  };

  const enteredPopover = () => {
    clearTimeout(closeTimeout);
  };

  const gotoManage = () => {
    navigate('/settings/quick-replies');
  };

  const selectReply = (qr: QuickReply, fire = false) => {
    setPreviewAnchorEl(null);
    setCurrent(null);
    setQuery('');
    const newBody = replacePlaceholders({
      body: qr.body,
      office,
      client,
      appointments: apptState.data.data,
      professionals: proState.data,
      locations: locState.data,
      forreal: true,
    });
    const warning = fire ? false :
      !hasNext && qr.body.includes('{{appt') || qr.body.includes('{{pro');
    onClick(newBody, qr.id, fire, warning);
    setHighlighted(0);
    setPreviewAnchorEl(null);
    setCurrent(null);
  };

  const editCurrent = () => {
    setEditReply(current);
    setPreviewAnchorEl(null);
    setCurrent(null);
  };

  const onEditClose = () => {
    setEditReply(null);
    state.invoke({
      name: query, sort: 'Last Used (Most Recent First)'
    });
  };

  const startLongPress = (item: QuickReply) => () => {
    longTime = setTimeout(() => {
      setPreviewAnchorEl(popoverRef.current);
      setCurrent(item);
    }, 300);
  };

  const endLongPress = () => {
    clearTimeout(longTime);
  };

  const quickSend = (item: QuickReply) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    selectReply(item, true);
  };

  const title = useMemo(() => {
    return `${client?.firstName || 'Client'} does not have a future appointment. Some placeholders won't be inserted.`;
  }, [client]);

  const noQRs = useMemo(() => {
    return isEmpty(state.data.data) && !state.loading;
  }, [state]);

  const onKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const e = event;
    const key = e.key;
    switch (key) {
      case 'ArrowDown': {
        e.preventDefault();
        const n = highlighted + 1;
        let t = n;
        if (n >= localData.length) {
          setHighlighted(0);
          t = 0;
        } else {
          setHighlighted(n);
        }
        scrollIntoView(t);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        const n = highlighted - 1;
        let t = n;
        if (n < 0) {
          t = localData.length - 1;
          setHighlighted(t);
        } else {
          setHighlighted(n);
        }
        scrollIntoView(t);
        break;
      }
      case 'Enter': {
        e.preventDefault();
        selectReply(localData[highlighted], false);
        break;
      }
    }
  }, [localData, highlighted]);

  const generate = async () => {
    state.setState({
      ...state,
      loading: true,
    });
    await generateDefaults();
    state.invoke({
      name: query, sort: 'Last Used (Most Recent First)',
    });
  };

  return (
    <RPopover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
    >
      <EditDialog
        open={Boolean(editReply)}
        onClose={onEditClose}
        reply={editReply}
      />
      <Preview
        anchorEl={previewAnchorEl}
        item={current}
        enteredPopover={enteredPopover}
        manage={gotoManage}
        edit={editCurrent}
        direction={direction}
      />
      {current &&
        <div onMouseEnter={() => closePopover()} style={{
          position: 'fixed', height: '100vh', width: '100vw', top: 0, left: 0,
          zIndex: 1300,
        }}></div>}
      <div className={classes.root} ref={popoverRef} style={{
        width: noQRs ? 324 : undefined,
        height: noQRs ? 158 : undefined,
      }}>
        {!isEmpty(state.data.data) &&
          <div ref={scroller} className={classes.searchContainer}>
            <TextField
              placeholder='Quick replies...'
              value={query}
              onChange={onChange}
              onKeyDown={onKeyPress}
              onBlur={() => searchRef?.current.focus()}
              style={{
                height: 20, width: '100%',
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#37AEFD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#37AEFD',
                  },
                  '& fieldset': {
                    borderRadius: 2.5,
                    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #d3d3d3',
                  },
                  '&:hover fieldset': {
                    borderColor: '#37AEFD',
                  },
                },
              }}
              inputRef={searchRef}
              InputProps={{
                style: {
                  padding: '5px 5px',
                  width: '100%',
                  minHeight: '32px',
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon style={{ fontSize: 16 }}/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {state.loading && <CircularProgress size={20} />}
                    {query !== '' &&
                    <IconButton onClick={() => setQuery('')}>
                      <CloseIcon style={{ fontSize: 14 }}/>
                    </IconButton>}
                  </InputAdornment>
                ),
              }}
            />
          </div>}
        {noQRs &&
          <div className={classes.pad}>
            <Typography component='h1' className={classes.header}>
              No quick replies added yet
            </Typography>
            <div className={classes.smallGrid}>
              <Typography component='p' style={{ marginBottom: 10 }}>
              You can create SMS templates to respond to common scenarios or questions.
              </Typography>
              <Typography component='p' className={classes.bodyPart}>
                <a
                  href="javascript:void()"
                  onClick={generate}
                  className={classes.link}>
                  Try our templates
                </a>
                or
                <a
                  href="javascript:void()"
                  onClick={gotoManage}
                  className={classes.link}
                  style={{ marginLeft: 7 }}
                >
                  <OpenInNew
                    className={classes.icon}
                    color='#008bcf'
                  />
                  create your own
                </a>
              </Typography>
            </div>
          </div>}
        {!noQRs &&
          <div className={classes.searchContents}>
            {localData.map((item: QuickReply, idx: number) => {
              const { name, id, body } = item;
              const usesAppt = body.includes('{{appt') || body.includes('{{pro');
              let b4 = '', aft = '';
              let dQuery;
              if (query !== '') {
                if (name.includes(query)) {
                  const idx = name.indexOf(query);
                  b4 = take(idx, name);
                  aft = drop(idx + query.length, name);
                  dQuery = slice(idx, idx + query.length, name);
                } else if (name.toLowerCase().includes(query)) {
                  const idx = name.toLowerCase().indexOf(query);
                  b4 = take(idx, name);
                  aft = drop(idx + query.length, name);
                  dQuery = slice(idx, idx + query.length, name);
                } else if (name.includes(query.toLowerCase())) {
                  const idx = name.indexOf(query.toLowerCase());
                  b4 = take(idx, name);
                  aft = drop(idx + query.length, name);
                  dQuery = query.toLowerCase();
                }
              } else {
                b4 = name;
              }
              const warn = !hasNext && usesAppt;
              return (
                <MenuItem
                  key={id}
                  id={`quick-reply-item-${idx}`}
                  aria-owns={current?.id === id ? 'mouse-over-popover' : undefined}
                  aria-haspopup='true'
                  onClick={() => selectReply(item)}
                  onTouchEnd={endLongPress}
                  onTouchStart={startLongPress(item)}
                  onMouseEnter={openPopover(item, idx)}
                  style={{ zIndex: 1302, height: 31 }}
                  sx={{
                    backgroundColor: highlighted === idx ? '#EEF5FF' : 'white',
                    '&:hover': {
                      backgroundColor: '#EEF5FF',
                    },
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant='body1'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 500,
                          color: '#4D4D4D',
                        }}
                      >
                        {b4}
                        {dQuery &&
                      <p style={{
                        backgroundColor: 'yellow',
                        display: 'inline'
                      }}>
                        {dQuery}
                      </p>}
                        {aft}
                      </Typography>
                    }/>
                  <ListItemIcon sx={{
                    justifyContent: 'flex-end',
                  }}>
                    {(hasNext || !usesAppt) && (current?.id === id || highlighted === idx) &&
                  <IconButton
                    onClick={quickSend(item)}>
                    <PaperAirplane />
                  </IconButton>}
                    {warn &&
                  <Tooltip
                    placement='top'
                    arrow
                    title={title}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                      padding: '0px 5px',
                      marginLeft: '5px',
                      cursor: 'default',
                    }}>
                      <WarningIcon color="#fcc713" />
                    </div>
                  </Tooltip>}
                  </ListItemIcon>
                </MenuItem>
              );
            })}
          </div>}
      </div>
    </RPopover>
  );
};

export default QuickReply;
