export const OFFICE_HOURS_ADD_PUSHED = 'OFFICE_HOURS_ADD';
export const OFFICE_HOURS_REMOVE_PUSHED = 'OFFICE_HOURS_REMOVE_PUSHED';
export const OFFICE_HOURS_EDIT_PUSHED = 'OFFICE_HOURS_EDIT_PUSHED';
export const OFFICE_HOURS_TIME_CHANGED = 'OFFICE_HOURS_TIME_CHANGED';
export const OFFICE_HOURS_REMOVE_OPEN_PUSHED =
  'OFFICE_HOURS_REMOVE_OPEN_PUSHED';
export const OFFICE_HOURS_DATE_CHANGED = 'OFFICE_HOURS_DATE_CHANGED';
export const OFFICE_HOURS_CANCEL_PUSHED = 'OFFICE_HOURS_CANCEL_PUSHED';
export const OFFICE_HOURS_SAVE_PUSHED = 'OFFICE_HOURS_SAVE_PUSHED';
export const OFFICE_HOURS_CLOSED_PUSHED = 'OFFICE_HOURS_CLOSED_PUSHED';
export const OFFICE_HOURS_ADD_OPENING_PUSHED =
  'OFFICE_HOURS_ADD_OPENING_PUSHED';
export const OFFICE_HOURS_REMOTE_GET = 'OFFICE_HOURS_REMOTE_GET';
export const OFFICE_HOURS_REMOTE_DELETE = 'OFFICE_HOURS_REMOTE_DELETE';
export const OFFICE_HOURS_COPY_HOURS = 'OFFICE_HOURS_COPY_HOURS';
export const OFFICE_REMOTE_GET_FEATURES = 'OFFICE_REMOTE_GET_FEATURES';
export const OFFICE_REMOTE_GET_SETTINGS = 'OFFICE_REMOTE_GET_SETTINGS';
export const OFFICE_REMOTE_GET_EHR_SETTINGS = 'OFFICE_REMOTE_GET_EHR_SETTINGS';
export const OFFICE_REMOTE_GET_OFFICE_GROUP = 'OFFICE_REMOTE_GET_OFFICE_GROUP';

export const PRO_PATCH = 'PRO_PATCH';
export const PRO_EDIT = 'PRO_EDIT';
export const PRO_TOGGLE_OPEN = 'PRO_TOGGLE_OPEN';
export const PRO_UPDATE_COLOR = 'PRO_UPDATE_COLOR';
export const PRO_ADD_PROFESSIONAL = 'PRO_ADD_PROFESSIONAL';
export const PRO_SAVED = 'PRO_SAVED';
export const PRO_REMOTE_TYPES_GET = 'PRO_REMOTE_TYPES_GET';
export const PRO_SELECT = 'PRO_SELECT';
export const PRO_REMOTE_PROS_GET = 'PRO_REMOTE_PROS_GET';
export const PRO_REMOTE_SAVE_FALLBACK = 'PRO_REMOTE_SAVE_FALLBACK';
export const PRO_REMOTE_DELETE_FALLBACK = 'PRO_REMOTE_DELETE_FALLBACK';


export const APPT_TYPE_TOGGLE = 'APPT_TYPE_TOGGLE';
export const APPT_TYPE_TOGGLE_ALL = 'APPT_TYPE_TOGGLE_ALL';
export const APPT_TYPE_BULK_UPDATE = 'APPT_TYPE_BULK_UPDATE';
export const APPT_TYPE_DUPLICATE = 'APPT_TYPE_DUPLICATE';


export const SPECIAL_OFFICE_HOURS_ADD = 'SPECIAL_OFFICE_HOURS_ADD';
export const SPECIAL_OFFICE_HOURS_CANCEL = 'SPECIAL_OFFICE_HOURS_CANCEL';
export const SPECIAL_OFFICE_HOURS_PATCH = 'SPECIAL_OFFICE_HOURS_PATCH';
export const SPECIAL_OFFICE_HOURS_REMOVE = 'SPECIAL_OFFICE_HOURS_REMOVE';
export const SPECIAL_OFFICE_HOURS_UPDATE_FORM = 'SPECIAL_OFFICE_HOURS_UPDATE_FORM';
export const SPECIAL_OFFICE_HOURS_UPDATE_FORM_DATE = 'SPECIAL_OFFICE_HOURS_UPDATE_FORM_DATE';
export const SPECIAL_OFFICE_HOURS_UPDATE_NAME = 'SPECIAL_OFFICE_HOURS_UPDATE_NAME';
export const SPECIAL_OFFICE_HOURS_REMOTE_GET =
  'SPECIAL_OFFICE_HOURS_REMOTE_GET';
export const SPECIAL_OFFICE_HOURS_CANCEL_PUSHED =
  'SPECIAL_OFFICE_HOURS_CANCEL_PUSHED';
export const SPECIAL_OFFICE_HOURS_NEW_HOURS = 'SPECIAL_OFFICE_HOURS_NEW_HOURS';
export const SPECIAL_OFFICE_HOURS_DELETE = 'SPECIAL_OFFICE_HOURS_DELETE';
export const SPECIAL_OFFICE_HOURS_EDIT_PUSHED =
  'SPECIAL_OFFICE_HOURS_EDIT_PUSHED';
export const SPECIAL_OFFICE_HOURS_DATE_CHANGED =
  'SPECIAL_OFFICE_HOURS_DATE_CHANGED';
export const SPECIAL_OFFICE_HOURS_DATE_TO_CHANGED =
  'SPECIAL_OFFICE_HOURS_DATE_TO_CHANGED';
export const SPECIAL_OFFICE_HOURS_NAME_CHANGED =
  'SPECIAL_OFFICE_HOURS_NAME_CHANGED';
export const SPECIAL_OFFICE_HOURS_INTERNAL_NAME_CHANGED =
  'SPECIAL_OFFICE_HOURS_INTERNAL_NAME_CHANGED';
export const SPECIAL_OFFICE_HOURS_EVENT_CHANGED =
  'SPECIAL_OFFICE_HOURS_EVENT_CHANGED';
export const SPECIAL_OFFICE_HOURS_CLOSED_PUSHED =
  'SPECIAL_OFFICE_HOURS_CLOSED_PUSHED';
export const SPECIAL_OFFICE_HOURS_ADD_OPENING_PUSHED =
  'SPECIAL_OFFICE_HOURS_ADD_OPENING_PUSHED';
export const SPECIAL_OFFICE_HOURS_REMOVE_OPEN_PUSHED =
  'SPECIAL_OFFICE_HOURS_REMOVE_OPEN_PUSHED';
export const SPECIAL_OFFICE_HOURS_TIME_CHANGED =
  'SPECIAL_OFFICE_HOURS_TIME_CHANGED';
export const SPECIAL_OFFICE_HOURS_SAVE_PUSHED =
  'SPECIAL_OFFICE_HOURS_SAVE_PUSHED';
export const SPECIAL_OFFICE_HOURS_ADD_PUSHED =
  'SPECIAL_OFFICE_HOURS_ADD_PUSHED';
export const SPECIAL_OFFICE_HOURS_LOCATION_CHANGED =
  'SPECIAL_OFFICE_HOURS_LOCATION_CHANGED';

export const REG_PATCH = 'REG_PATCH';
export const REG_ADDRESS_PATCH = 'REG_ADDRESS_PATCH';
export const REG_BILLING_PATCH = 'REG_BILLING_PATCH';
export const REG_INSTALL = 'REG_INSTALL';
export const REG_REGISTER = 'REG_REGISTER';
export const REG_CREATE_STRIPE_TOKEN = 'REG_CREATE_STRIPE_TOKEN';

export const LOGIN = 'LOGIN';
export const LOGIN_GET = 'LOGIN_GET';
export const LOGIN_PATCH = 'LOGIN_PATCH';
export const LOGIN_RESET = 'LOGIN_RESET';
export const SESSION_CHECKING = 'SESSION_CHECKING';
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_ERROR = 'SESSION_CREATE_ERROR';
export const SESSION_DESTROY = 'SESSION_DESTROY';
export const SESSION_LOAD_SUCCESS = 'SESSION_LOAD_SUCCESS';
export const SESSION_LOAD_ERROR = 'SESSION_LOAD_ERROR';

export const BUSINESS_PATCH = 'BUSINESS_PATCH';
export const BUSINESS_PATCH_OFFICE = 'BUSINESS_PATCH_OFFICE';
export const BUSINESS_PATCH_OFFICE_ADDRESS = 'BUSINESS_PATCH_OFFICE_ADDRESS';
export const USER_REMOTE_GET = 'USER_REMOTE_GET';
export const BUSINESS_REMOTE_GET = 'BUSINESS_REMOTE_GET';
export const BUSINESS_REMOTE_GET_LOCATIONS = 'BUSINESS_REMOTE_GET_LOCATIONS';
export const BUSINESS_REMOTE_POST_LOCATIONS = 'BUSINESS_REMOTE_POST_LOCATIONS';
export const BUSINESS_DELETE_LOCATIONS = 'BUSINESS_DELETE_LOCATIONS';
export const BUSINESS_REMOTE_DELETE_LOCATIONS = 'BUSINESS_REMOTE_DELETE_LOCATIONS';
export const OFFICE_REMOTE_GET = 'OFFICE_REMOTE_GET';
export const OFFICE_REMOTE_PUT = 'OFFICE_REMOTE_PUT';
export const OFFICE_FILTER_NAME = 'OFFICE_FILTER_NAME';
export const OFFICE_FILTER_SYSTEM = 'OFFICE_FILTER_SYSTEM';
export const OFFICE_RESYNC = 'OFFICE_RESYNC';

export const BILLING_PATCH = 'BILLING_PATCH';
export const BILLING_REMOTE_GET = 'BILLING_REMOTE_GET';

export const INSTALLER_PATCH = 'INSTALLER_PATCH';

export const STATUS_UPDATE = 'STATUS_UPDATE';

export const INTEGRATION_REMOTE_GET = 'INTEGRATION_REMOTE_GET';
export const INTEGRATION_REMOTE_PUT = 'INTEGRATION_REMOTE_PUT';
export const INTEGRATION_REMOTE_PUT_GEN = 'INTEGRATION_REMOTE_PUT_GEN';
export const INTEGRATION_REMOTE_POST = 'INTEGRATION_REMOTE_POST';
export const INTEGRATION_PATCH = 'INTEGRATION_PATCH';
export const INTEGRATION_MISC_PATCH = 'INTEGRATION_MISC_PATCH';
export const INTEGRATION_MAPPED_PATCH = 'INTEGRATION_MAPPED_PATCH';
export const INTEGRATION_REMOTE_POST_SELECT_OFFICE = 'INTEGRATION_REMOTE_POST_SELECT_OFFICE';
export const INTEGRATION_REMOTE_GET_GENESIS = 'INTEGRATION_REMOTE_GET_GENESIS';

export const CLIENTS_REMOTE_GET = 'CLIENTS_REMOTE_GET';
export const CLIENTS_BACK_TO_LIST = 'CLIENTS_BACK_TO_LIST';
export const CLIENTS_SEARCH_PATCH = 'CLIENTS_SEARCH_PATCH';
export const CLIENTS_UPDATE_CURRENT = 'CLIENTS_UPDATE_CURRENT';
export const CLIENTS_REMOTE_GET_TAGS = 'CLIENTS_REMOTE_GET_TAGS';
export const CLIENT_REMOTE_POST_MESSAGE = 'CLIENT_REMOTE_POST_MESSAGE';
export const CLIENT_RESET = 'CLIENT_RESET';

export const CLIENT_PATCH = 'CLIENT_PATCH';
export const CLIENT_REMOTE_GET = 'CLIENT_REMOTE_GET';
export const CLIENT_REMOTE_MESSAGES_GET = 'CLIENT_REMOTE_MESSAGES_GET';
export const CLIENT_REMOTE_APT_GET = 'CLIENT_REMOTE_APT_GET';
export const CLIENT_REMOTE_APTS_GET = 'CLIENT_REMOTE_APTS_GET';
export const CLIENT_CLIENT_PATCH = 'CLIENT_CLIENT_PATCH';
export const CLIENT_CANCEL_APT = 'CLIENT_CANCEL_APT';
export const CLIENT_FREQ_ADD = 'CLIENT_FREQ_ADD';
export const CLIENT_FREQ_PATCH = 'CLIENT_FREQ_PATCH';
export const CLIENT_PUT_FREQ = 'CLIENT_PUT_FREQ';
export const CLIENT_FREQ_ORDER = 'CLIENT_FREQ_ORDER';
export const CLIENT_FREQ_REMOVE = 'CLIENT_FREQ_REMOVE';
export const CLIENT_REMOTE_CREATE_GROUP = 'CLIENT_REMOTE_CREATE_GROUP';
export const CLIENT_REMOTE_TAG_DELETE = 'CLIENT_REMOTE_TAG_DELETE';
export const CLIENT_REMOTE_ADD_TAG_TO_CLIENT_POST = 'CLIENT_REMOTE_ADD_TAG_TO_CLIENT_POST';
export const CLIENT_VIEW_MESSAGE = 'CLIENT_VIEW_MESSAGE';

export const MESSAGES_REMOTE_GET = 'MESSAGES_REMOTE_GET';
export const MESSAGES_REMOTE_DELETE = 'MESSAGES_REMOTE_DELETE';
export const MESSAGES_PATCH = 'MESSAGES_PATCH';
export const MESSAGES_SELECT_PATCH = 'MESSAGES_SELECT_PATCH';
export const MESSAGES_SELECT_ALL_PATCH = 'MESSAGES_SELECT_ALL_PATCH';
export const MESSAGES_DELETE_REMOTE_GET = 'MESSAGES_DELETE_REMOTE_GET';
export const MESSAGES_READ_REMOTE_GET = 'MESSAGES_READ_REMOTE_GET';
export const MESSAGES_REMOTE_GET_SEARCH = 'MESSAGES_REMOTE_GET_SEARCH';
export const MESSAGES_LOCAL_SEARCH = 'MESSAGES_LOCAL_SEARCH';
export const MESSAGE_SENT_SCHEDULED = 'MESSAGE_SENT_SCHEDULED';

export const NEW_MESSAGE_PATCH = 'NEW_MESSAGE_PATCH';
export const NEW_MESSAGE_REMOTE_GET_TYPES = 'NEW_MESSAGE_REMOTE_GET_TYPES';
export const NEW_MESSAGE_REMOTE_GET = 'NEW_MESSAGE_REMOTE_GET';
export const NEW_MESSAGE_ADD_TYPE = 'NEW_MESSAGE_ADD_TYPE';
export const NEW_MESSAGE_ADD_TYPES = 'NEW_MESSAGE_ADD_TYPES';
export const NEW_MESSAGE_REMOVE_TYPE = 'NEW_MESSAGE_REMOVE_TYPE';
export const NEW_MESSAGE_REMOTE_GET_CLIENTS = 'NEW_MESSAGE_REMOTE_GET_CLIENTS';
export const NEW_MESSAGE_REMOVE_CLIENT = 'NEW_MESSAGE_REMOVE_CLIENT';
export const NEW_MESSAGE_ADD_CLIENT = 'NEW_MESSAGE_ADD_CLIENT';
export const NEW_MESSAGE_REMOTE_GET_LEADS = 'NEW_MESSAGE_REMOTE_GET_LEADS';
export const NEW_MESSAGE_REMOVE_LEAD = 'NEW_MESSAGE_REMOVE_LEAD';
export const NEW_MESSAGE_ADD_LEAD = 'NEW_MESSAGE_ADD_LEAD';
export const NEW_MESSAGE_SEND = 'NEW_MESSAGE_SEND';
export const NEW_MESSAGE_RESET = 'NEW_MESSAGE_RESET';

export const VIEW_MESSAGE_REMOTE_GET = 'VIEW_MESSAGE_REMOTE_GET';
export const VIEW_MESSAGES_REMOTE_GET = 'VIEW_MESSAGES_REMOTE_GET';
export const VIEW_MESSAGE_PATCH = 'VIEW_MESSAGE_PATCH';

export const RECURRING_REMOTE_GET = 'RECURRING_REMOTE_GET';
export const RECURRING_PATCH = 'RECURRING_PATCH';
export const RECURRING_SELECT_ALL_PATCH = 'RECURRING_SELECT_ALL_PATCH';
export const RECURRING_SELECT_PATCH = 'RECURRING_SELECT_PATCH';
export const RECURRING_TOGGLE = 'RECURRING_TOGGLE';
export const RECURRING_REMOTE_GET_SEARCH = 'RECURRING_REMOTE_GET_SEARCH';
export const RECURRING_REMOTE_DELETE = 'RECURRING_REMOTE_DELETE';
export const RECURRING_REMOTE_DELETE_MANY = 'RECURRING_REMOTE_DELETE_MANY';
export const RECURRING_LOCAL_SEARCH = 'RECURRING_LOCAL_SEARCH';
export const RECURRING_REMOTE_GET_AUTORESPONDER = 'RECURRING_REMOTE_GET_AUTORESPONDER';
export const RECURRING_REMOTE_SAVE_AUTORESPONDER = 'RECURRING_REMOTE_SAVE_AUTORESPONDER';
export const RECURRING_REMOTE_DELETE_AUTO = 'RECURRING_REMOTE_DELETE_AUTO';
export const RECURRING_REMOTE_DELETE_AUTO_MANY = 'RECURRING_REMOTE_DELETE_AUTO_MANY';

export const RECURRING_EDIT_PATCH = 'RECURRING_EDIT_PATCH';
export const RECURRING_EDIT_REMOTE_GET_TYPES =
  'RECURRING_EDIT_REMOTE_GET_TYPES';
export const RECURRING_EDIT_REMOTE_GET = 'RECURRING_EDIT_REMOTE_GET';
export const RECURRING_EDIT_ADD_TYPE = 'RECURRING_EDIT_ADD_TYPE';
export const RECURRING_EDIT_ADD_TYPES = 'RECURRING_EDIT_ADD_TYPES';
export const RECURRING_EDIT_REMOVE_TYPE = 'RECURRING_EDIT_REMOVE_TYPE';
export const RECURRING_EDIT_REMOTE_GET_CLIENTS =
  'RECURRING_EDIT_REMOTE_GET_CLIENTS';
export const RECURRING_EDIT_REMOVE_CLIENT = 'RECURRING_EDIT_REMOVE_CLIENT';
export const RECURRING_EDIT_ADD_CLIENT = 'RECURRING_EDIT_ADD_CLIENT';
export const RECURRING_EDIT_SEND = 'RECURRING_EDIT_SEND';
export const RECURRING_EDIT_RESET = 'RECURRING_EDIT_RESET';
export const RECURRING_EDIT_ADD_AUTOMATIONS = 'RECURRING_EDIT_ADD_AUTOMATIONS';
export const RECURRING_EDIT_ADD_CAMPAIGN = 'RECURRING_EDIT_ADD_CAMPAIGN';
export const RECURRING_EDIT_REMOVE_AUTOMATIONS = 'RECURRING_EDIT_REMOVE_AUTOMATIONS';
export const RECURRING_EDIT_UPDATE_AUTOMATIONS = 'RECURRING_EDIT_UPDATE_AUTOMATIONS';
export const RECURRING_EDIT_UPDATE_ALL_AUTOMATIONS = 'RECURRING_EDIT_UPDATE_ALL_AUTOMATIONS';

export const VIEW_RECURRING_PATCH = 'VIEW_RECURRING_PATCH';
export const VIEW_RECURRING_REMOTE_GET = 'VIEW_RECURRING_REMOTE_GET';
export const VIEW_RECURRINGS_REMOTE_GET = 'VIEW_RECURRINGS_REMOTE_GET';
export const VIEW_RECURRINGS_UPDATE_AUTOMATIONS = 'VIEW_RECURRINGS_UPDATE_AUTOMATIONS';
export const VIEW_RECURRINGS_REMOVE_AUTOMATIONS = 'VIEW_RECURRINGS_REMOVE_AUTOMATIONS';

export const MESSAGE_THREAD_PATCH = 'MESSAGE_THREAD_PATCH';
export const MESSAGE_THREAD_REMOTE_GET = 'MESSAGE_THREAD_REMOTE_GET';
export const MESSAGE_THREAD_REMOTE_GET_MORE = 'MESSAGE_THREAD_REMOTE_GET_MORE';
export const MESSAGE_THREAD_REMOTE_POST = 'MESSAGE_THREAD_REMOTE_POST';
export const MESSAGE_THREAD_REMOTE_SEND_MESSAGE = 'MESSAGE_THREAD_REMOTE_SEND_MESSAGE';
export const MESSAGE_THREAD_READ_MESSAGE = 'MESSAGE_THREAD_READ_MESSAGE';
export const MESSAGE_THREAD_NEW_REPLY = 'MESSAGE_THREAD_NEW_REPLY';
export const MESSAGE_THREAD_REMOTE_SAVE_SCHEDULED_SMS = 'MESSAGE_THREAD_REMOTE_SAVE_SCHEDULED_SMS';
export const MESSAGE_THREAD_REMOTE_DELETE_SCHEDULED_SMS = 'MESSAGE_THREAD_REMOTE_DELETE_SCHEDULED_SMS';
export const MESSAGE_THREAD_SENT_SCHEDULED_SMS = 'MESSAGE_THREAD_SENT_SCHEDULED_SMS';

export const TEMPLATES_REMOTE_GET = 'TEMPLATES_REMOTE_GET';
export const TEMPLATES_REMOTE_AUTO_BASED_GET = 'TEMPLATES_REMOTE_AUTO_BASED_GET';
export const TEMPLATES_PATCH = 'TEMPLATES_PATCH';
export const TEMPLATES_SELECT_ALL_PATCH = 'TEMPLATES_SELECT_ALL_PATCH';
export const TEMPLATES_SELECT_PATCH = 'TEMPLATES_SELECT_PATCH';
export const TEMPLATES_TOGGLE = 'TEMPLATES_TOGGLE';
export const TEMPLATES_REMOTE_GET_SEARCH = 'TEMPLATES_REMOTE_GET_SEARCH';
export const TEMPLATES_REMOTE_GET_ONE_TYPE = 'TEMPLATES_REMOTE_GET_ONE_TYPE';
export const TEMPLATES_REMOTE_DELETE = 'TEMPLATES_REMOTE_DELETE';
export const TEMPLATE_EDIT_PATCH = 'TEMPLATE_EDIT_PATCH';
export const TEMPLATE_EDIT_REMOTE_GET = 'TEMPLATE_EDIT_REMOTE_GET';
export const TEMPLATE_EDIT_REMOVE_TYPE = 'TEMPLATE_EDIT_REMOVE_TYPE';
export const TEMPLATE_EDIT_REMOTE_GET_CLIENTS =
  'TEMPLATE_EDIT_REMOTE_GET_CLIENTS';
export const TEMPLATE_EDIT_REMOVE_CLIENT = 'TEMPLATE_EDIT_REMOVE_CLIENT';
export const TEMPLATE_EDIT_ADD_CLIENT = 'TEMPLATE_EDIT_ADD_CLIENT';
export const TEMPLATE_EDIT_SEND = 'TEMPLATE_EDIT_SEND';
export const TEMPLATE_EDIT_RESET = 'TEMPLATE_EDIT_RESET';
export const TEMPLATE_EDIT_SAVE_INTO_PATH = 'TEMPLATE_EDIT_SAVE_INTO_PATH';

export const SKED_PATCH = 'SKED_PATCH';
export const SELECT_HOUR = 'SELECT_HOUR';
export const SKED_REMOTE_GET = 'SKED_REMOTE_GET';
export const SKED_REMOTE_APT_GET = 'SKED_REMOTE_APT_GET';
export const SKED_REMOTE_APTS_GET = 'SKED_REMOTE_APTS_GET';
export const SKED_CANCEL_APT = 'SKED_CANCEL_APT';
export const SKED_RESKED_APT = 'SKED_RESKED_APT';
export const SKED_REMOTE_RESKED_APT = 'SKED_REMOTE_RESKED_APT';
export const SKED_REMOTE_APT_ARRIVE = 'SKED_REMOTE_APT_ARRIVE';
export const SKED_REMOTE_APT_UNARRIVE = 'SKED_REMOTE_APT_UNARRIVE';
export const SKED_APPEND_APTS = 'SKED_APPEND_APTS';

export const DASH_GET_STUFF = 'DASH_GET_STUFF';
export const DASH_SELECT_APT = 'DASH_SELECT_APT';
export const DASH_REMOTE_APT_GET = 'DASH_REMOTE_APT_GET';
export const DASH_CLIENT_SELECT = 'DASH_CLIENT_SELECT';

export const WEBMODULE_PATCH = 'WEBMODULE_PATCH';
export const WEBMODULE_PATCH_PRO = 'WEBMODULE_PATCH_PRO';
export const WEBMODULE_ADD_TYPE = 'WEBMODULE_ADD_TYPE';
export const WEBMODULE_ADD_TYPES = 'WEBMODULE_ADD_TYPES';
export const WEBMODULE_REMOVE_TYPE = 'WEBMODULE_REMOVE_TYPE';
export const WEBMODULE_REMOTE_GET_TYPES = 'WEBMODULE_REMOTE_GET_TYPES';
export const WEBMODULE_SAVE = 'WEBMODULE_SAVE';

export const GENERAL_PATCH = 'GENERAL_PATCH';
export const GENERAL_REMOTE_GET_SETTINGS = 'GENERAL_REMOTE_GET_SETTINGS';
export const GENERAL_SAVE = 'GENERAL_SAVE';
export const GENERAL_REMOTE_GET_VERIFIED_EMAIL_STATUS = 'GENERAL_REMOTE_GET_VERIFIED_EMAIL_STATUS';
export const GENERAL_CONFIRM_EMAIL = 'GENERAL_CONFIRM_EMAIL';

export const ADMIN_DELETE_CLIENT = 'ADMIN_DELETE_CLIENT';
export const ADMIN_GET_CLIENT = 'ADMIN_GET_CLIENT';
export const ADMIN_PATCH = 'ADMIN_PATCH';
export const ADMIN_GET_AFFILIATES = 'ADMIN_GET_AFFILIATES';
export const ADMIN_SELECT_OFFICES_FOR_AFFILIATE = 'ADMIN_SELECT_OFFICES_FOR_AFFILIATE;';
export const ADMIN_REMOVE_OFFICES_FOR_AFFILIATE = 'ADMIN_REMOVE_OFFICES_FOR_AFFILIATE;';
export const ADMIN_SAVE_AFFILIATE = 'ADMIN_SAVE_AFFILIATE';
export const ADMIN_DELETE_AFFILIATE = 'ADMIN_DELETE_AFFILIATE';
export const ADMIN_GET_AFFILIATE_OFFICES = 'ADMIN_GET_AFFILIATE_OFFICES';

export const SKEDTYPE_REMOTE_GET_TYPES = 'SKEDTYPE_REMOTE_GET_TYPES';
export const SKEDTYPE_PATCH = 'SKEDTYPE_PATCH';
export const SKEDTYPE_PATCH_SCHEDULE = 'SKEDTYPE_PATCH_SCHEDULE';
export const SKEDTYPE_MOVE = 'SKEDTYPE_MOVE';
export const SKEDTYPE_ADD = 'SKEDTYPE_ADD';
export const SKEDTYPE_SORT = 'SKEDTYPE_SORT';
export const SKEDTYPE_REMOVE = 'SKEDTYPE_REMOVE';
export const SKEDTYPE_REMOTE_SAVE_SCHEDULE = 'SKEDTYPE_REMOTE_SAVE_SCHEDULE';
export const SKEDTYPE_RESET = 'SKEDTYPE_RESET';
export const SKEDTYPE_SKED_PATCH = 'SKEDTYPE_SKED_PATCH';
export const SKEDTYPE_REMOTE_GET_HOURS = 'SKEDTYPE_REMOTE_GET_HOURS';
export const SKEDTYPE_REMOTE_POST_HOURS = 'SKEDTYPE_REMOTE_POST_HOURS';
export const SKEDTYPE_REMOTE_DELETE_HOURS = 'SKEDTYPE_REMOTE_DELETE_HOURS';
export const SKEDTYPE_TYPE_DISABLED = 'SKEDTYPE_TYPE_DISABLED';

export const SETTINGS_APP_PATCH = 'SETTINGS_APP_PATCH';
export const SETTINGS_APP_GET_DATA = 'SETTINGS_APP_GET_DATA';
export const SETTINGS_APP_SELECT_PRO = 'SETTINGS_APP_SELECT_PRO';
export const SETTINGS_APP_ADD_APPOINTMENT = 'SETTINGS_APP_ADD_APPOINTMENT';
export const SETTINGS_APP_PRO_NAME_HIDDEN = 'SETTINGS_APP_PRO_NAME_HIDDEN';
export const SETTINGS_APP_SAVE = 'SETTINGS_APP_SAVE';
export const SETTINGS_APP_ADD_ALL_APPOINTMENTS =
  'SETTINGS_APP_ADD_ALL_APPOINTMENTS';
export const SETTINGS_APP_REMOVE_APPOINTMENT =
  'SETTINGS_APP_REMOVE_APPOINTMENT';
export const SETTINGS_APP_REMOVE_ALL_APPOINTMENTS =
  'SETTINGS_APP_REMOVE_ALL_APPOINTMENTS';
export const SETTINGS_APP_GET_LIMITS =
  'SETTINGS_APP_GET_LIMITS';
export const SETTINGS_APP_LIMITS_PATCH =
  'SETTINGS_APP_LIMITS_PATCH';
export const SETTINGS_APP_CLA_PATCH = 'SETTINGS_APP_CLA_PATCH';
export const SETTINGS_APP_GET_CLA = 'SETTINGS_APP_GET_CLA';
export const SETTINGS_APP_SAVE_CLA = 'SETTINGS_APP_SAVE_CLA';


export const SKEDTYPE_REMOTE_DELETE_SKED = 'SKEDTYPE_REMOTE_DELETE_SKED';

export const SETTINGS_REVIEWS_EDIT_FORM = 'SETTINGS_REVIEWS_EDIT_FORM';
export const SETTINGS_REVIEWS_INITIALIZE = 'SETTINGS_REVIEWS_INITIALIZE';
export const GET_SETTINGS_REVIEWS = 'GET_SETTINGS_REVIEWS';
export const SETTINGS_REVIEWS_SAVE = 'SETTINGS_REVIEWS_SAVE';

export const REVIEWS_PATCH = 'REVIEWS_PATCH';
export const REVIEWS_REMOTE_GET = 'REVIEWS_REMOTE_GET';
export const NEW_REVIEWS_GET = 'NEW_REVIEWS_GET';
export const REVIEWS_LAST_READ_UPDATE = 'REVIEWS_LAST_READ_UPDATE';
export const REVIEWS_REMOTE_GET_SENT = 'REVIEWS_REMOTE_GET_SENT';
export const SETTINGS_REVIEWS_UNMOUNT = 'SETTINGS_REVIEWS_UNMOUNT';

export const TAGS_REMOTE_GET = 'TAGS_REMOTE_GET';
export const TAGS_REMOTE_CLIENTS_GET = 'TAGS_REMOTE_CLIENTS_GET';
export const TAGS_REMOTE_LEADS_GET = 'TAGS_REMOTE_LEADS_GET';
export const TAGS_REMOTE_TAGGED_CLIENTS_GET = 'TAGS_REMOTE_TAGGED_CLIENTS_GET';
export const TAGS_REMOTE_TAGGED_LEADS_GET = 'TAGS_REMOTE_TAGGED_LEADS_GET';
export const TAGS_REMOTE_ADD_TAGS_POST = 'TAGS_REMOTE_ADD_TAGS_POST';
export const TAGS_PATCH = 'TAGS_PATCH';
export const TAGS_REMOTE_TAG_DELETE = 'TAGS_REMOTE_TAG_DELETE';
export const TAGS_REMOTE_ADD_TAG_TO_CLIENT_POST = 'TAGS_REMOTE_ADD_TAG_TO_CLIENT_POST';
export const TAGS_REMOTE_ADD_TAG_TO_LEAD_POST = 'TAGS_REMOTE_ADD_TAG_TO_LEAD_POST';
export const TAGS_REMOTE_REMVOE_TAG_TO_CLIENT_DELETE = 'TAGS_REMOTE_REMVOE_TAG_TO_CLIENT_DELETE';
export const TAGS_REMOTE_REMVOE_TAG_TO_LEAD_DELETE = 'TAGS_REMOTE_REMVOE_TAG_TO_LEAD_DELETE';
export const TAGS_SORT_TAGS = 'TAGS_SORT_TAGS';
export const TAGS_REMOTE_UPDATE_TAG_PUT = 'TAGS_REMOTE_UPDATE_TAG_PUT';
export const TAGS_INIT = 'TAGS_INIT';

export const SKED_REMOTE_GET_CALENDAR_INIT = 'SKED_REMOTE_GET_CALENDAR_INIT';
export const SKED_REMOTE_GET_CALENDAR_APTS = 'SKED_REMOTE_GET_CALENDAR_APTS';
export const SKED_CALENDAR_UPDATE_SETTINGS = 'SKED_CALENDAR_UPDATE_SETTINGS';
export const SKED_CALENDAR_PUT_APPT = 'SKED_CALENDAR_PUT_APPT';

export const INBOX_REMOTE_GET = 'INBOX_REMOTE_GET';

export const REMOTE_GET_REPORT_OVERVIEW = 'REMOTE_GET_REPORT_OVERVIEW';
export const REMOTE_SAVE_REPORT_OVERVIEW_SETTINGS = 'REMOTE_SAVE_REPORT_OVERVIEW_SETTINGS';
export const REMOTE_GET_REPORT_OVERVIEW_SETTINGS = 'REMOTE_GET_REPORT_OVERVIEW_SETTINGS';

export const REMOTE_WAITING_GET_SETTINGS = 'REMOTE_WAITING_GET_SETTINGS';
export const REMOTE_WAITING_SAVE_SETTINGS = 'REMOTE_WAITING_SAVE_SETTINGS';
export const WAITING_SET_STATE = 'WAITING_SET_STATE';
export const WAITING_UPDATE_APPT_SCHEDULED = 'WAITING_UPDATE_APPT_SCHEDULED';

export const STOPMANAGER_PATCH = 'STOPMANAGER_PATCH';
export const STOPMANAGER_REMOTE_GET = 'STOPMANAGER_REMOTE_GET';
export const STOPMANAGER_REMOTE_SAVE_POST = 'STOPMANAGER_REMOTE_SAVE_POST';
export const STOPMANAGER_REMOTE_NEW_GROUP_POST = 'STOPMANAGER_REMOTE_NEW_GROUP_POST';
export const STOPMANAGER_REMOTE_SAVE_PUT = 'STOPMANAGER_REMOTE_SAVE_PUT';
export const STOPMANAGER_REMOTE_COPIED_STOPS_GROUP_POST = 'STOPMANAGER_REMOTE_COPIED_STOPS_GROUP_POST';

export const TUTORIALS_EXPAND_SECTION = 'TUTORIALS_EXPAND_SECTION';
export const TUTORIALS_COMPRESS_SECTION = 'TUTORIALS_COMPRESS_SECTION';
export const TUTORIALS_REDIRECT_STEP = 'TUTORIALS_REDIRECT_STEP';
export const TUTORIALS_PATCH_FILTER_DATA = 'TUTORIALS_PATCH_FILTER_DATA';
export const TUTORIALS_GET_DATA = 'TUTORIALS_GET_DATA';
export const TUTORIALS_UPDATE_TUTORIAL = 'TUTORIALS_UPDATE_TUTORIAL';

export const CLIENTLISTS_PATCH = 'CLIENTLISTS_PATCH';
export const CLIENTLISTS_REMOTE_GET = 'CLIENTLISTS_REMOTE_GET';
export const CLIENTLISTS_REMOTE_SAVE_POST = 'CLIENTLISTS_REMOTE_SAVE_POST';
export const CLIENTLISTS_REMOTE_NEW_GROUP_POST = 'CLIENTLISTS_REMOTE_NEW_GROUP_POST';
export const CLIENTLISTS_REMOTE_SAVE_PUT = 'CLIENTLISTS_REMOTE_SAVE_PUT';
export const CLIENTLISTS_REMOTE_COPIED_CLIENTS_GROUP_POST = 'CLIENTLISTS_REMOTE_COPIED_CLIENTS_GROUP_POST';

export const AUTOMATIONS_PATCH = 'AUTOMATIONS_PATCH';
export const AUTOMATIONS_REMOTE_GET = 'AUTOMATIONS_REMOTE_GET';
export const AUTOMATIONS_REMOTE_POST = 'AUTOMATIONS_REMOTE_POST';
export const AUTOMATIONS_REMOTE_GET_NPPS = 'AUTOMATIONS_REMOTE_GET_NPPS';
export const AUTOMATIONS_UPDATE_ALL_AUTOMATIONS = 'AUTOMATIONS_UPDATE_ALL_AUTOMATIONS';
export const AUTOMATIONS_ADD_AUTOMATIONS = 'AUTOMATIONS_ADD_AUTOMATIONS';
export const AUTOMATIONS_REMOVE_AUTOMATIONS = 'AUTOMATIONS_REMOVE_AUTOMATIONS';
export const AUTOMATIONS_UPDATE_AUTOMATIONS = 'AUTOMATIONS_UPDATE_AUTOMATIONS';
