import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as rawActions from './recurring.actions.jsx';
import { patch as loginPatch } from '../Login/login.actions';
import { reset } from './routes/RecurringEdit/recurring-edit.actions.jsx';
import {
  CircularProgress,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import * as R from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import { MessageTable, sortOptions } from './components/messages-table.component.jsx';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { useTitle } from '../../services/useTitle.js';
import SidebarFolders, { currentPath } from '../../components/SidebarFolders/SidebarFolders.component';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import FolderIcon from '../../icons/Folder.icon';

const MessagesTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    busy,
    all,
    state,
    tz,
    query,
    filter,
    noFolder,
    office,
    birthdays,
    birthdayPage,
    birthdayTotalCount,
    birthdayTotalPages,
    hasFeature,
    tab,
    templates,
    officeTemplates,
    features,
    templatePage,
    totalTemplatePages,
    totalTemplateCount,
    officeTemplatePage,
    totalOfficeTemplatePages,
    totalOfficeTemplateCount,
    headerHeight,
    openedFolder,
    isMobile,
  } = useSelector((state) => ({
    ...state.recurring,
    tz: R.pathOr(null, ['login', 'office', 'timezone'])(state),
    office: R.pathOr(null, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    hasFeature: R.includes('Birthday', R.pathOr([], ['login', 'features'], state)),
    headerHeight: state.login.headerHeight,
    openedFolder: state.login.openedFolder,
    isMobile: state.login.isMobile,
  }));

  const [search, setSearch] = React.useState({
    recurring: '',
    templates: '',
    'sked-templates': '',
  });
  const [sort, setSort] = React.useState({
    recurring: 'Enabled',
    templates: 'Enabled',
  });
  const [selected, setSelected] = React.useState({
    recurring: 0,
    templates: 0,
    'sked-templates': 0,
  });

  useTitle('Birthday Messages');

  React.useEffect(() => {
    actions.getBirthdayMessages(navigate, features);
    dispatch(reset());
  }, [office]);

  React.useEffect(() => {
    if (state === 'COPY_BIRTHDAY') {
      navigate('/birthday/edit/0');
    }
  }, [state]);

  const handleChange = (value) => {
    if (tab === 'recurring') {
      actions.selectAll({ type: 'birthdays', value: false });
      actions.messagesPatch('query', value);
      setSearch({ ...search, [tab]: value });
      actions.search({ type: 'recurring', messageType: 'Birthday', query: value, page: birthdayPage, filter, noFolder });
      return;
    }
    if (tab === 'templates') {
      actions.selectAll({ type: 'officeTemplates', value: false });
      actions.messagesPatch('query', value);
      setSearch({ ...search, [tab]: value });
      actions.search({ type: 'officeTemplate', messageType: 'Birthday', query: value, page: officeTemplatePage, filter, noFolder });
      return;
    }
    if (tab === 'sked-templates') {
      actions.messagesPatch('query', value);
      setSearch({ ...search, [tab]: value });
      actions.search({ type: 'template', messageType: 'Birthday', features, query: value, page: templatePage, filter, noFolder });
    }
  };

  const handleSort = (value) => {
    setSort({ ...sort, [tab]: value });
    const sortBy = sortOptions.find(({ label }) => value === label).value;
    if (tab === 'templates') {
      actions.messagesPatch('filter', sortBy);
      const path = ['msg', 'rcr', 'tmp', 'br'];
      const curPath = path.join('.');
      let inPath = currentPath[curPath] ? currentPath[curPath] : undefined;
      if (noFolder) {
        inPath = path;
      }
      actions.selectAll({ type: 'officeTemplates', value: false });
      actions.search({
        type: 'officeTemplate',
        messageType: 'Birthday',
        query,
        page: officeTemplatePage,
        filter: sortBy,
        noFolder,
        path: inPath,
      });
    }
  };

  const setSelectedId = (t, id) => {
    setSelected({ ...selected, [t]: id });
  };
  
  const selectedId = React.useMemo(() => {
    return selected[tab] || 0;
  }, [tab, selected]);

  if (!hasFeature) {
    return (
      <FeatureDisabled title="Birthday Messages">
        <p>Let patients know you are thinking of them on their special day!</p>
      </FeatureDisabled>
    );
  }

  const heightDiff = headerHeight + 45;

  return (
    <>
      <Header
        title='Birthday Messages'
        pageId="birthday-messages"
        leftIcons={[
          (!openedFolder || isMobile) ?
            <HeaderButton
              Icon={FolderIcon}
              onClick={() => dispatch(loginPatch({ openedFolder: true }))}
              title='Folders'
              onlyIcon
              marginRight={5}
              padding={7}
              active={!!selectedId}
              type='filter'
            /> : <div />,
          <HeaderTabs
            value={tab}
            setTab={(value) => actions.messagesPatch('tab', value)}
            tabs={[
              { label: 'Messages', value: 'recurring', className: 'sked-test-birthday-messages' },
              { label: 'My Templates', value: 'templates', className: 'sked-test-birthday-templates' },
              { label: 'SKED Templates', value: 'sked-templates', className: 'sked-test-birthday-sked-templates' },
            ]}
          />,
        ]}
        rightIcons={[
          <Grid display="flex" marginRight={1}>
            {busy && <CircularProgress size={18} />}
          </Grid>,
          tab === 'templates' ?
            <FilterSelect
              items={sortOptions.map(item => item.label)}
              selected={sort[tab]}
              setSelected={handleSort}
              Icon={SwapVertIcon}
              noActionButton
              title='Sort'
              headerTitle='Sort By'
              noneText='Enabled'
              type='sort'
            /> : <div />,
          <FilterText
            value={search[tab]}
            headerTitle='Search'
            onChange={handleChange}
            helperText='Search all messages'
          />,
          <HeaderButton
            title='New Message'
            className='sked-test-birthday-compose'
            Icon={AddIcon}
            onClick={() => navigate('/birthday/edit/0')}
          />,
        ]}
        smallTabsWidth={tab === 'templates' ? 940 : 848}
        onlyIconsWidth={650}
        breakPoints={[
          {
            width: 550,
            mobileItems: [1, 3, 4]
          },
        ]}
      />
      <Grid display="flex">
        <PopupTemplate />
        <Grid>
          {tab === 'recurring' && (
            <SidebarFolders
              path={['msg', 'rcr', 'br']}
              deselect={Boolean(query)}
              open={openedFolder}
              onClose={() => dispatch(loginPatch({ openedFolder: false }))}
              selectedId={selectedId}
              setSelectedId={(v) => setSelectedId(tab, v)}
              noFolder={noFolder}
              onClick={(path, noFolder = false) => {
                actions.messagesPatch('noFolder', noFolder);
                actions.selectAll({
                  type: 'birthdays',
                  value: false,
                });
                actions.search({
                  type: 'recurring',
                  messageType: 'Birthday',
                  filter,
                  path,
                  page: 1,
                  noFolder,
                });
              }}
            />
          )}
          {tab === 'templates' && (
            <SidebarFolders
              path={['msg', 'rcr', 'tmp', 'br']}
              deselect={Boolean(query)}
              open={openedFolder}
              onClose={() => dispatch(loginPatch({ openedFolder: false }))}
              selectedId={selectedId}
              setSelectedId={(v) => setSelectedId(tab, v)}
              noFolder={noFolder}
              onClick={(path, noFolder = false) => {
                actions.messagesPatch('noFolder', noFolder);
                actions.selectAll({
                  type: 'officeTemplates',
                  value: false,
                });
                actions.search({
                  type: 'officeTemplate',
                  messageType: 'Birthday',
                  filter,
                  path,
                  page: 1,
                  noFolder,
                });
              }}
            />
          )}
          {tab === 'sked-templates' && (
            <SidebarFolders
              template
              deselect={Boolean(query)}
              path={['tmp', 'msg', 'rcr', 'br']}
              pageName='birthday'
              open={openedFolder}
              onClose={() => dispatch(loginPatch({ openedFolder: false }))}
              selectedId={selectedId}
              setSelectedId={(v) => setSelectedId(tab, v)}
              noFolder={noFolder}
              onClick={(path, noFolder = false) => {
                actions.messagesPatch('noFolder', noFolder);
                actions.search({
                  type: 'template',
                  messageType: 'Birthday',
                  features,
                  filter,
                  path,
                  page: 1,
                  noFolder,
                });
              }}
            />
          )}
        </Grid>
        <Grid flex={1}>
          <div hidden={tab !== 'recurring'}>
            <MessageTable
              messageType='birthdays'
              busy={false}
              messages={birthdays}
              url={'/birthday/view/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'birthdays'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'birthdays'), actions.selectMessage)}
              copyMessage={R.partial(actions.copyMessage, ['COPY_BIRTHDAY', false])}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              toggle={(m) => actions.toggleEnable(m, 'birthdays')}
              page={birthdayPage}
              query={query}
              filter={filter}
              totalPages={birthdayTotalPages}
              totalCount={birthdayTotalCount}
              messagesPatch={actions.messagesPatch}
              search={R.pipe(
                R.assoc('type', 'recurring'),
                R.assoc('messageType', 'Birthday'),
                R.assoc('filter', filter),
                R.assoc('noFolder', noFolder),
                actions.search
              )}
              error=''
              path={['msg', 'rcr', 'br']}
              heightDiff={heightDiff}
            />
          </div>
          <div hidden={tab !== 'templates'}>
            <MessageTable
              messageType='officeTemplates'
              url={'/birthday/edit/0'}
              busy={busy}
              messages={officeTemplates}
              all={all}
              tz={tz}
              filter={filter}
              selectAll={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectMessage)}
              copyMessage={R.partial(actions.copyMessage, ['COPY_BIRTHDAY', true])}
              deleteMessage={(d) => actions.deleteMessage(R.merge(d, { type: 'template/office/recurringmessage' }))}
              deleteSelected={(d) => actions.deleteSelected(R.merge(d, { type: 'template/office/recurringmessage' }))}
              search={R.pipe(
                R.assoc('type', 'officeTemplate'),
                R.assoc('messageType', 'Birthday'),
                R.assoc('filter', filter),
                R.assoc('noFolder', noFolder),
                actions.search
              )}
              page={officeTemplatePage}
              totalPages={totalOfficeTemplatePages}
              totalCount={totalOfficeTemplateCount}
              pageName={'templates'}
              error=''
              path={['msg', 'rcr', 'tmp', 'br']}
              heightDiff={heightDiff}
            />
          </div>
          <div hidden={tab !== 'sked-templates'}>
            <MessageTable
              busy={busy}
              url={'/birthday/edit/0'}
              messages={templates}
              tz={tz}
              copyMessage={R.partial(actions.copyMessage, ['COPY_BIRTHDAY', false])}
              pageName={'sked-templates'}
              error='Error: Failed to get templates'
              page={templatePage}
              totalPages={totalTemplatePages}
              totalCount={totalTemplateCount}
              query={query}
              filter={filter}
              search={R.pipe(
                R.assoc('type', 'template'),
                R.assoc('messageType', 'Birthday'),
                R.assoc('filter', filter),
                R.assoc('features', features),
                R.assoc('noFolder', noFolder),
                actions.search
              )}
              error=''
              heightDiff={heightDiff}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default MessagesTemplate;
