import React, { useState, useEffect, useRef } from 'react';

import {
  Button, Menu, MenuItem, InputAdornment, TextField, IconButton, Popover, Grid,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import FilterHeader from '../FilterHeader/FilterHeader.component';
import { useStyles } from './headerSearch.styles';

type HeaderSearchProps = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  options?: { value: string, label: string }[];
  selectedOption?: string;
  setOption?: (value: string) => void;
  timeOut?: number;
  justIcon?: boolean;
}

let searchTimeout: NodeJS.Timeout = null;

const HeaderSearch = ({
  value, placeholder, onChange, options, selectedOption,
  setOption, timeOut, justIcon,
}: HeaderSearchProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [localValue, setLocalValue] = useState(value);
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (value: string) => {
    setLocalValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      onChange(value);
    }, timeOut || 0);
  };

  const handleReset = () => {
    setLocalValue('');
    onChange('');
    setIconAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    setOption(value);
    handleClose();
  };

  useEffect(() => {
    if (anchorEl || iconAnchorEl) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [anchorEl, inputRef, iconAnchorEl]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const content = (
    <div className={`${classes.root} ${justIcon ? classes.justIcon : ''}`}>
      <TextField
        value={localValue}
        onChange={event => handleOnChange(event.target.value)}
        inputRef={inputRef}
        size='small'
        onKeyDown={event => {
          if (event.key === 'Enter') {
            onChange(localValue);
          }
        }}
        placeholder={placeholder}
        className={classes.input}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" className={classes.startIcon}>
              <SearchIcon style={{ fontSize: 16 }} />
            </InputAdornment>
          ),
          endAdornment: localValue.length ? <InputAdornment position='end'>
            <IconButton className={classes.closeButton} onClick={handleReset}>
              <CloseIcon />
            </IconButton>
          </InputAdornment> : null,
        }}
      />
      {options?.length && (
        <Button
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          variant='outlined'
          className={classes.button}
          color="primary">
          {options.find(item => item.value === selectedOption)?.label}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => handleSelect(item.value)}
            value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  if (justIcon) {
    return (
      <Grid>
        <HeaderButton
          onlyIcon
          title={'Search'}
          onClick={(event) => setIconAnchorEl(event.currentTarget)}
          type="filter"
          active={!!localValue?.length}
          Icon={SearchIcon}
        />
        <Popover
          open={Boolean(iconAnchorEl)}
          anchorEl={iconAnchorEl}
          onClose={() => setIconAnchorEl(null)}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Grid>
            <FilterHeader
              title={'Search'}
              reset={handleReset}
              resetDisabled={!localValue.length}
            />
            <Grid padding={2}>
              {content}
            </Grid>
          </Grid>
        </Popover>
      </Grid>
    );
  }
  return content;
};

export default HeaderSearch;
