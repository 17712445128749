import React, { useState } from 'react';
import * as R from 'ramda';

import { Grid, Popover, Typography, Button, PopoverOrigin } from '@mui/material';

import TemplateTagIcon from '../../icons/TemplateTag.icon';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { useStyles } from './placeholders.styles';

export type Placeholder = {
  title?: string,
  value?: string;
  name?: string;
  placeholders?: Placeholder[]
  feature?: string;
  isHTML?: boolean;
  info?: () => string;
  fake?: string;
}

type Props = {
  placeholders: Placeholder[];
  insertFunction: (value: string) => void;
  isHTML?: boolean;
  isSMS?: boolean;
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
}

const Placeholders = ({
  placeholders, isHTML, isSMS, insertFunction, anchorOrigin,
  transformOrigin
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const getLowerCase = (text: string) => {
    return text ? text.toLowerCase().replace(/ /g, '-') : '';
  };

  return (
    <Grid>
      <Grid>
        <HeaderButton
          Icon={TemplateTagIcon}
          title={'Placeholders'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          onlyIcon
          iconStyle={{ width: 21, height: 20 }}
          padding={5}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={anchorOrigin || {
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={transformOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Grid maxHeight="60vh" overflow="auto" minWidth="300px" padding={2} paddingTop={1} paddingBottom={1.5} borderRadius="8px" className='sked-test-template-tags-container'>
          {!!placeholders?.length && (
            R.map(({ title = '', placeholders = [], info }: Placeholder) => {
              return (
                <Grid key={title + Math.random() + '-title-key'}>
                  <Typography className={classes.title}>{title} {info ? info() : ''}</Typography>
                  <div>
                    {R.pipe(
                      R.filter((p) =>
                        R.cond([
                          [R.has('isHTML'), R.always(isHTML)],
                          [R.has('isSMS'), R.always(isSMS)],
                          [R.T, R.T]
                        ])(p))
                    )(placeholders).map((placeholder) =>
                      <div key={placeholder.value + 'item-key'}>
                        <Button
                          onClick={() => {
                            insertFunction(placeholder.value);
                            setAnchorEl(null);
                          }}
                          className={`${classes.item} sked-test-template-tag-${getLowerCase(`${title}-${placeholder.name}`)}-button`}
                        >
                          {placeholder.name}
                        </Button>
                      </div>
                    )}
                  </div>
                </Grid>
              );
            })(placeholders)
          )}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default Placeholders;
