import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Paper,
  Button,
  TextField,
  Autocomplete
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as adminActions from './admin.actions';
import { useSelector } from '../../reducers';
import {
  LocalDateTime,
} from '@js-joda/core';
import {
  format,
} from '../../services/joda.js';
import { useCheckIsAdmin } from '../../services/checkIsAdmin.hook';
import { Row, Spacer } from '../../components/PageHeader';
import { TableContainer, HeaderCell, BodyCell } from '../../components/CustomTable';
import { Affiliate } from './admin.reducer';
import { Office } from '../Login/login.reducer';

const fixLength = (str: string) => {
  if (str && str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

type AffiliateItemsProps = {
  affiliate: Affiliate;
  onDelete: (id: number) => void;
  onClick: (affiliate: Affiliate) => void;
}

function AffiliateItems({
  affiliate,
  onDelete,
  onClick
}: AffiliateItemsProps) {
  const {
    id,
    name,
    description,
    isHidden,
    created,
  } = affiliate;
  return (
    <TableRow>
      <BodyCell
        style={{ cursor: 'pointer' }}
        onClick={() => onClick(affiliate)}>
        {name}
      </BodyCell>
      <BodyCell
        style={{
          cursor: 'pointer'
        }}
        onClick={() => onClick(affiliate)}>
        {fixLength(description)}
      </BodyCell>
      <BodyCell>
        <Checkbox
          checked={isHidden}
          disabled={true}
          name="Is Hidden?"
        />
      </BodyCell>
      <BodyCell>
        {format(LocalDateTime.parse(created.split('.')[0]), 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        <IconButton onClick={() => onDelete(id)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </BodyCell>
    </TableRow>
  );
}

type OfficeItemProps = {
  office: Office;
  onClick: (office: Office) => void;
  hasRemove: boolean;
}

function OfficeItem({
  office,
  onClick,
  hasRemove,
}: OfficeItemProps) {
  const {
    id,
    name,
    ehrSystem,
    plan
  } = office;
  return (
    <TableRow>
      <BodyCell>
        <IconButton onClick={() => onClick(office)} aria-label="delete">
          {hasRemove ? <DeleteIcon /> : <Add />}
        </IconButton>
      </BodyCell>
      <BodyCell>
        {id}
      </BodyCell>
      <BodyCell>
        {name}
      </BodyCell>
      <BodyCell>
        {ehrSystem}
      </BodyCell>
      <BodyCell>
        {plan}
      </BodyCell>
    </TableRow>
  );
}

type OfficeSearchProps = {
  busy: boolean;
  allOffices: Office[];
  selectedOffices: Office[];
  selectOffices: (offices: Office[]) => void;
  removeOffices: (offices: Office[]) => void;
}

function OfficeSearch({
  busy,
  allOffices,
  selectedOffices,
  selectOffices,
  removeOffices,
}: OfficeSearchProps) {
  const officeToSearch = React.useMemo(() => {
    return allOffices.filter(o => !selectedOffices.some(s => s.id === o.id));
  }, [allOffices, selectedOffices]);
  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        options={officeToSearch}
        onChange={(e, value: Office) => value && selectOffices([value])}
        getOptionLabel={(o) => `${o.id} ${o.name} ${o.ehrSystem}`}
        style={{ width: 400 }}
        renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
      />

      <div style={{ padding: '10px' }}>
        <Row>
          <h5 style={{ fontSize: '16px' }}>Selected Offices</h5>
          <Spacer />
          {!R.isEmpty(selectedOffices) &&
            <Button
              variant="contained"
              style={{ marginLeft: '20px' }}
              startIcon={<DeleteIcon />}
              onClick={() => removeOffices(selectedOffices)}
            >
              Remove All Below
            </Button>}
        </Row>
        {busy ?
          <div className='loader' /> :
          <TableContainer>
            <TableHead>
              <TableRow>
                <HeaderCell>&nbsp;</HeaderCell>
                <HeaderCell>ID</HeaderCell>
                <HeaderCell>Name</HeaderCell>
                <HeaderCell>EHR System</HeaderCell>
                <HeaderCell>Product</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOffices.map((off) =>
                <OfficeItem
                  key={off.id}
                  office={off}
                  hasRemove={true}
                  onClick={(o: Office) => removeOffices([o])}
                />)}
            </TableBody>
          </TableContainer>}
      </div>
    </div>
  );
}

type AffiliateDataProps = {
  affiliate: Affiliate;
  patch: (data: adminActions.AdminPatchData) => void;
}

let timeout: NodeJS.Timeout;
function AffiliateData({
  affiliate,
  patch,
}: AffiliateDataProps) {
  const [name, setName] = useState(affiliate.name);
  const [description, setDescription] = useState(affiliate.description);
  const [isHidden, setIsHidden] = useState(affiliate.isHidden);

  return (
    <div>
      <TextField
        type='text'
        label="Name"
        value={name}
        onChange={(e) => {
          const n = e.target.value;
          setName(n);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            patch({
              affiliate: R.merge(affiliate, {
                name: n,
              })
            });
          }, 500);
        }} />
      <br />
      <br />
      <TextField
        type='text'
        label="Description"
        value={description}
        onChange={(e) => {
          const d = e.target.value;
          setDescription(d);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            patch({
              affiliate: R.merge(affiliate, {
                description: d,
              }),
            });
          }, 500);

        }} />
      <br />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={isHidden}
            onChange={(e) => {
              const h = e.target.checked;
              setIsHidden(h);
              patch({
                affiliate: R.merge(affiliate, {
                  isHidden: h,
                })
              });
            }}
            name="acknowledge-checkbox"
            color="primary"
          />
        }
        label="Mark group as hidden"
      />
    </div>
  );
}

function Affiliates() {
  const dispatch = useDispatch();
  const {
    affiliates,
    affiliate,
    state,
    lState,
    allOffices,
    selectedOffices,
    admin,
    busy,
  } = useSelector((state) => ({
    state: state.admin.state,
    busy: state.admin.busy,
    affiliates: state.admin.affiliates,
    affiliate: state.admin.affiliate,
    selectedOffices: state.admin.selectedOffices,
    lState: state.session.status,
    allOffices: state.login.offices,
    admin: state.login.admin,
  }));

  const actions = bindActionCreators(adminActions, dispatch);

  useCheckIsAdmin(admin, lState);

  useEffect(() => {
    if (lState === 'AUTHENTICATED') {
      actions.getAffiliateGroups();
    }
  }, [lState]);

  return (
    <div>
      <div>
        {lState === 'NOT_AUTHENTICATED' && <Navigate to="/login" />}
        {state !== 'SHOW' &&
          <>
            <br />
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <Button
                style={{ marginLeft: '20px' }}
                startIcon={<ChevronLeftIcon />}
                onClick={() => actions.adminPatch({ state: 'SHOW' })}
              >
                Back
              </Button>
              <Button
                style={{ marginRight: '20px' }}
                variant="contained"
                disabled={!affiliate.name}
                onClick={() => actions.saveAffiliateGroup(affiliate, selectedOffices)}
              >
                Save
              </Button>
            </div>
          </>}
        <Paper style={{
          margin: '20px',
          padding: '20px',
        }}>
          {R.cond([
            //TODO use react router for this instead
            [R.equals('SHOW'), () => (
              <div >
                <h3 style={{ height: '25px', marginLeft: 'unset' }}>
                  Affiliate Groups
                </h3>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => actions.adminPatch({
                    state: 'EDIT',
                    affiliate: {
                      name: '',
                      description: '',
                      isHidden: false,
                    },
                    selectedOffices: [],
                  })}
                >
                  Create
                </Button>
                <div style={{ marginTop: '10px' }}>
                  {busy && <div className='loader' />}
                  {R.isEmpty(affiliates) ?
                    <div>No Affiliates!</div>
                    :
                    <TableContainer>
                      <TableHead>
                        <TableRow>
                          <HeaderCell>Name</HeaderCell>
                          <HeaderCell>Description</HeaderCell>
                          <HeaderCell>Hidden</HeaderCell>
                          <HeaderCell>Created</HeaderCell>
                          <HeaderCell>&nbsp;</HeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {affiliates.map((aff) =>
                          <AffiliateItems
                            key={aff.id}
                            affiliate={aff}
                            onClick={(a: Affiliate) => {
                              actions.adminPatch({
                                state: 'EDIT',
                                affiliate: a
                              });
                              actions.getOffices(a.id, allOffices);
                            }}
                            onDelete={(id: number) => {
                              actions.deleteAffiliateGroup(id);
                            }}
                          />)}
                      </TableBody>
                    </TableContainer>}
                </div>
              </div>
            )],
            //TODO use react router for this instead
            [R.equals('EDIT'), () => (
              <div >
                <h3 style={{ height: '25px', marginLeft: 'unset' }}>
                  Edit Affiliate Group
                </h3>
                <div style={{ marginTop: '10px' }}>
                  <AffiliateData
                    affiliate={affiliate}
                    patch={actions.adminPatch}
                  />
                  <OfficeSearch
                    busy={busy}
                    allOffices={allOffices}
                    selectedOffices={selectedOffices}
                    selectOffices={actions.selectOffices}
                    removeOffices={actions.removeOffices}
                  />
                </div>
              </div>
            )],
            [R.T, () => (<div></div>)]
          ])(state)}
        </Paper>
      </div>
    </div>
  );
}

export default Affiliates;
