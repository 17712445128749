import { AnyAction } from '@reduxjs/toolkit';
import * as at from '../../actionTypes';
import * as R from 'ramda';
import { ZonedDateTime, LocalDateTime } from '@js-joda/core';
import {
  Appointment, AppointmentType, Professional, ProType
} from '../Appointments/appointments.types';

type CalendarReducer = {
  busy: boolean;
  aptBusy: boolean;
  schBusy: boolean;
  state: string;
  appointments: Appointment[];
  selectedAppointment: Appointment;
  date: LocalDateTime;
  types: AppointmentType[];
  selectableTypes: AppointmentType[];
  professionals: Professional[];
  selectedPro: Professional;
  proTypes: ProType[];
  colors: object[];
  open: string;
  close: string;
  snackbar: { open: boolean; msg: string };
  view: string;
}
const init: CalendarReducer = {
  busy: false,
  aptBusy: false,
  schBusy: false,
  state: 'SKED',
  appointments: [],
  selectedAppointment: {} as Appointment,
  date: LocalDateTime.now(),
  types: [],
  selectableTypes: [],
  professionals: [{ id: 0, displayFirstName: 'All Professionals' } as Professional],
  selectedPro: {} as Professional,
  proTypes: [],
  colors: [],
  open: '06:00:00',
  close: '22:00:00',
  snackbar: { open: false, msg: '' },
  view: 'day',
};

export default (calendar = init, action: AnyAction): CalendarReducer => {
  switch (action.type) {
    case at.SKED_PATCH:
      return R.merge(calendar, action.data);
    case at.SKED_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(calendar, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const mapIndexed = R.addIndex(R.map);
        const allType = {
          id: 0,
          name: 'All',
        };
        const allPros = {
          id: 0,
          displayFirstName: 'All Professionals',
          displayLastName: '',
        };
        const newPros = R.concat([allPros], action.data.pros);
        const newTypes = R.pipe(
          R.uniqBy(R.prop('internalName')),
          R.sortBy(R.prop('name')),
          R.concat([allType])
        )(action.data.types) as AppointmentType[];
        const incVal = Number((360 / R.pipe(R.tail, R.length)(newTypes)).toFixed(0));
        return R.merge(calendar, {
          busy: false,
          professionals: R.filter(({ isHidden }) => !isHidden)(newPros),
          types: action.data.types,
          selectableTypes: newTypes,
          proTypes: R.pipe(
            R.filter(({ isHidden }) => !isHidden),
            (prop: Professional[]) => R.sortBy(R.prop('lastName'))(prop),
            R.map((pro: Professional) => {
              const ts = R.pipe(
                R.filter(({ professionalId }) => pro.id === professionalId),
                (prop: Professional[]) => R.sortBy(R.prop('internalName'))(prop)
              )(action.data.types);
              const newPro = R.merge(pro, {
                id: undefined, // to prevent mis-selection when a type has the same id.
                professionalId: pro.id
              });
              return [newPro, ts];
            }),
            R.flatten
          )(action.data.pros as Professional[]) as ProType[],
          // selectedType: R.head(newTypes),
          selectedPro: R.head(newPros),
          colors: mapIndexed((type: AppointmentType, inx) => {
            return {
              name: type.internalName,
              color: String(inx * incVal) + ', 100%,',
            };
          })(R.tail(newTypes)) as object[],
        });
      } else if (action.state === 'ERROR') {
        return R.merge(calendar, {
          busy: false,
        });
      }
      return calendar;
    }
    case at.SKED_REMOTE_APTS_GET:
      if (action.state === 'REQUEST') {
        return R.merge(calendar, {
          schBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(calendar, action.data);
      }
      return calendar;
    case at.SKED_REMOTE_APT_GET:
      if (action.state === 'REQUEST') {
        return R.merge(calendar, {
          state: 'SKED_APT',
          aptBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(calendar, {
          selectedAppointment: action.data.appointment,
          aptBusy: false,
        });
      }
      return calendar;
    case at.SKED_CANCEL_APT:
      return R.evolve({
        appointments: R.filter(({ id }) => id !== action.data.id) as () => Appointment[],
        snackbar: R.always({
          open: true,
          msg: 'Successfully canceled appointment!'
        }),
      }, calendar);
    case at.SKED_RESKED_APT: {
      const apt = action.data;
      const start = ZonedDateTime.parse(apt.time).toString();
      const end = ZonedDateTime.parse(apt.time).plusMinutes(apt.appointmentType.duration).toString();
      const appointment = R.merge(apt, {
        key: apt.id,
        color: apt.appointmentType.color,
        title:
          '(' + apt.appointmentType.internalName + ') ' +
          R.splitAt(1, apt.client.firstName)[0] + '. ' +
          apt.client.lastName,
        start,
        end,
      });

      return R.evolve({
        appointments: R.map((a: Appointment) => {
          if (a.id === action.data.id) {
            return appointment;
          } else {
            return a;
          }
        }) as () => Appointment[],
        selectedAppointment: R.always(appointment) as () => Appointment,
        snackbar: R.always({
          open: true,
          msg: 'Successfully rescheduled appointment!'
        }),
      }, calendar);
    }
    case at.SKED_APPEND_APTS: {
      return R.evolve({
        appointments: (apts) => {
          const newApts = R.concat(apts, action.data.appointments);
          console.log(newApts);
          return newApts;
        },
      }, calendar);
    }
    default:
      return calendar;
  }
};
