import React from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';

import HeaderButton from '../HeaderButton/HeaderButton.component';
import Modal from '../Modal/Modal.component';

type Props = {
  title: string;
  text: string;
  discardText?: string;
  saveText?: string;
  open: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  onDiscard: () => void;
}

const DiscardDialog = ({
  title, text, open, onConfirm, onCancel, onDiscard,
  discardText = 'Discard', saveText = 'Save',
}: Props) => {
  return (
    <Modal
      title={title}
      maxWidth={520}
      open={open}
      onClose={onCancel}
      buttons={[
        <HeaderButton title={discardText} borderSolid onClick={onDiscard} />,
        onConfirm && <HeaderButton color='primary' title={saveText} onClick={onConfirm} />
      ]}
    >
      <Grid>
        <Typography>{text}</Typography>
      </Grid>
    </Modal>
  );
};

export default DiscardDialog;
