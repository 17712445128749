import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { merge } from 'ramda';
import { PopupTemplate, popupWithCancel } from '../../services/Popup.js';
import { Button, Grid, Box } from '@mui/material';
import api from '../../services/api.js';
import { useCheckIsAdmin } from '../../services/checkIsAdmin.hook';
import { infoSnackbar, errorSnackbar } from '../../components/Snackbar/snackbar.actions';

import { useSelector } from '../../reducers';

type UpdateProps = {
  busy?: boolean;
}

const CheckUnsynced = () => {
  const dispatch = useDispatch();
  const lState = useSelector((state) => state.session.status);
  const admin = useSelector((state) => state.login.admin);

  const [state, setState] = useState({
    busy: false,
  });

  const update = (data: UpdateProps) => {
    setState(merge(state, data));
  };

  const checkUnsynced = () => {
    update({ busy: true });
    api.post('admin/genesis/sendUnsent').then(() => {
      update({
        busy: false,
      });
      dispatch(infoSnackbar('Started checking for unsynced Genesis patients!'));
    }).catch((error) => {
      update({ busy: false });
      if (error.response.status === 401) {
        dispatch(errorSnackbar('Unauthorized! Go to Select Office page and return.'));
      } else {
        dispatch(errorSnackbar('Unable to check for unsynced patients!'));
      }
      console.log(error);
    });
  };

  useCheckIsAdmin(admin, lState);

  return (
    <div>
      <h3 style={{ marginBottom: 'unset', height: '20px' }}>
        <div className="title">Check Unsynced</div>
      </h3>
      <Grid>
        {lState === 'NOT_AUTHENTICATED' && <Navigate to="/login" />}
        <Box style={{ marginTop: '30px' }} display="flex" justifyContent='center'>
          <PopupTemplate />
          {state.busy ?
            <div className="loader"></div>
            :
            <Button
              type="button"
              style={{ background: '#F44336', color: '#FFF' }}
              onClick={() =>
                popupWithCancel(
                  'Verification',
                  'Are you sure you want to check for unsynced Genesis patients?',
                  () => checkUnsynced())}
            >
              Check 'Em!
            </Button>
          }
        </Box>
      </Grid>
    </div>
  );
};

export default CheckUnsynced;
