import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useMediaQuery, TextField as RawTextField, IconButton,
  InputAdornment, Paper, CircularProgress, Popover, Button, Tooltip,
  Box, Grid
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import * as R from 'ramda';
import SendIcon from '@mui/icons-material/Send';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import OpenInIcon from '@mui/icons-material/OpenInNew';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import 'emoji-mart/css/emoji-mart.css';
import { Picker, BaseEmoji } from 'emoji-mart';
import * as rawActions from './messages-thread.actions.jsx';
import { tzParseFormat } from '../../../../services/joda.js';
import { caseType } from '../../../../services/utilities.js';
import ClientEditDialog from '../../../Clients/components/client-dialog/client-dialog.component.jsx';
import EditLeadDialog from '../../../Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../../../Leads/components/Convert/Convert.component';
import { Spacer } from '../../../../components/PageHeader';
import { PopupTemplate, popup } from '../../../../services/Popup';
import { usePromise } from '../../../../services/promise.hook';
import InboxChat from '../../Inbox/InboxChat/InboxChat.component';
import { getFileSize } from '../../../../components/Editor/editor.component';
import { SentMessage, Change } from '../../message-types';
import { useSelector } from '../../../../reducers';

const TextField = withStyles({
  root: {
    width: '100%',
  },
})(RawTextField);

const characterLimit = 160;
const unicodeCharacterLimit = 70;

const countCredits = (str: string) => {
  // eslint-disable-next-line no-control-regex
  const hasUnicode = /[^\u0000-\u00ff]/.test(str);
  const limit = hasUnicode ? unicodeCharacterLimit : characterLimit;
  const strLength = str ? str.length : 0;
  const segment = Math.ceil(strLength / limit);
  return {
    strLength,
    limit,
    segment
  };
};

const onlyOneEmoji = (str: string) => {
  // eslint-disable-next-line no-control-regex
  const hasUnicode = /[^\u0000-\u00ff]/.test(str);
  return hasUnicode && str.length === 2;
};

const CharacterCount = ({ str }: { str: string }) => {
  const { strLength, limit, segment } = countCredits(str);
  return <Grid minWidth="158px">
    <div style={{ color: 'gray', fontSize: 14 }}>Characters: {strLength} / {limit} <br />Credits: {segment}</div>
  </Grid>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  textArea: {
    '& textarea': {
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(206, 206, 206)',
        borderRadius: ' 8px',
        '&:hover': {
          backgroundColor: 'gray',
          borderRadius: '8px',
        },
      },
    }
  },
  chatContainer: {
    height: '100%',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingRight: '20px',
    paddingLeft: '20px'
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'center',
    border: '1px solid lightgray',
    paddingLeft: '20px',
    paddingTop: '4.5px',
    paddingBottom: '4.5px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
      flexDirection: 'column-reverse',
      paddingRight: '50px !important',
      paddingLeft: '5px',
    }
  },
  inputArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',
    }
  },
  chatItemContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '700px'
  },
  chatItemRoot: {
    marginTop: '15px',
    width: '100%',
    justifyContent: 'center'
  },
  chatHeader: {
    display: 'flex',
    minHeight: '63px',
    backgroundColor: 'white',
    alignItems: 'center',
    borderBottom: '1px solid lightgray',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  systemMessage: {
    width: '100%',
    textAlign: 'center',
    color: 'gray'
  },
  headerText: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  loader: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'center'
  },
  messageContent: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  messageTime: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.5)',
    marginTop: '5px',
    '& span': {
      marginLeft: '5px'
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13
    }
  },
  mmsContainer: {
    position: 'relative',
    width: 'min-content',
  },
  popup: {
    width: 'min-content',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  mmsItem: {
    maxWidth: '290px',
  },
}));

const ShowStatus = ({ status } : { status: string }) => {
  if (status === 'Delivered') {
    return <CheckIcon fontSize="small" />;
  }
  if (status === 'Pending' || status === 'Sent') {
    return <MoreHorizIcon fontSize="small" />;
  }
  if (status === 'Failed') {
    return <ErrorIcon color="warning" fontSize="small" />;
  }
  return null;
};

export const MmsItem = ({ mms, fileName }: { mms: Urls, fileName: string }) => {
  const classes = useStyles();
  const { mimeType, phiId, url } = mms;
  const [anchorEl, setAnchorEl] = React.useState(false);
  const openPopup = () => {
    setAnchorEl(true);
  };
  const closePopup = () => {
    setAnchorEl(false);
  };
  const linkOnly = React.useMemo(() => {
    return (mimeType.indexOf('image') === -1 && mimeType.indexOf('video/ogg') === -1);
  }, [mimeType]);

  return (
    <div className={classes.mmsContainer} style={linkOnly ? { width: 'unset' } : {}} onMouseLeave={closePopup}>
      {(mimeType.indexOf('image') > -1 || mimeType.indexOf('video/ogg') > -1) &&
        <Box
          aria-haspopup="true"
          onMouseEnter={openPopup}>
          {mimeType.indexOf('image') > -1 &&
            <img id={url} key={phiId} src={url} className={classes.mmsItem} />}
          {mimeType.indexOf('video/ogg') > -1 &&
            <video id={url} key={phiId} src={url} className={classes.mmsItem} />}
        </Box>}
      {linkOnly &&
        <a key={phiId} href={url}>
          {fileName}
        </a>}
      <Paper
        id="mouse-over-popover"
        style={{ display: anchorEl ? 'block' : 'none' }}
        className={classes.popup}>
        <IconButton component='a' href={url} target='_blank'>
          <OpenInIcon style={{ color: 'black' }} />
        </IconButton>
      </Paper>
    </div>
  );
};

export interface Urls {
  url: string;
  change: Change,
  mimeType: string;
  phiId: number;
  fileId: number;
}

interface ChatItemProps {
  message: SentMessage;
  tz: string;
  classes: any;
  phis: Urls[];
}

export interface MmsAttachment {
  mms: Urls;
  fileName: string;
}

export interface AvailableFiles {
  status: string;
  path: string;
  mimeType: string;
  phiId: number;
  fileId: number;
  change: Change;
}

const ChatItem = ({ message, tz, classes, phis } : ChatItemProps) => {
  const toOffice = R.pathOr(false, ['to', 'ToOffice'])(message);
  const body = R.pathOr('', ['smsData', 'body'], message);
  const attachments = R.pathOr([], ['smsData', 'attachments'], message);
  const mmsAttachments: MmsAttachment[] = React.useMemo(() => {
    const msgPhi = R.pathOr([], ['smsData', 'phi'], message);
    return msgPhi.map((mp) => {
      const ms = phis.find((p) => p.phiId === mp.phiId);
      const f = mp.availableFiles.find(({ phiId }: AvailableFiles) => {
        return mp.phiId === phiId;
      });
      /* decode twice because sometimes the file names have things like %2520 in
         them and a single decodeURI won't do it all.
      */
      const fileName = f ? decodeURI(decodeURI(R.last(f.path.split('/'))))
        : decodeURI(mp.fileName);
      if (ms) {
        return {
          mms: ms,
          fileName,
        };
      }
      return {
        mms: null,
        fileName,
      };
    }).filter((p: MmsAttachment) => p.mms) || [];
  }, [message, phis]);

  return (
    <div className={classes.chatItemRoot}>
      <div className={classes.chatItemContainer}>
        {!toOffice && <Spacer />}
        <div style={{ width: '300px', fontSize: 14 }}>
          <Paper
            elevation={1}
            key={message.msgId}
            style={{
              padding: '5px',
              color: toOffice ? 'black' : 'white',
              backgroundColor: toOffice ? 'white' : 'rgba(0, 139, 207, 0.8)'
            }}>
            <div>
              <b>{toOffice ? `${message.client.firstName} ${caseType(message.client)}` : 'Me'}</b>
            </div>
            <div className={classes.messageContent} style={{ fontSize: onlyOneEmoji(body) ? '32px' : 'auto' }}>
              {body}
            </div>
            {!R.isEmpty(attachments) &&
              attachments.map(({ attachmentName, attachmentUrl }) => (
                <>
                  <a
                    style={{
                      color: toOffice ? 'unset' : 'white',
                      textDecoration: 'underline',
                    }}
                    href={attachmentUrl}
                    target='_blank'>
                    {attachmentName}
                  </a>
                  <br />
                </>
              ))}
            {mmsAttachments.map(({ mms, fileName }: MmsAttachment) => (
              <MmsItem mms={mms} fileName={fileName} />
            ))}
          </Paper>
          <Tooltip title={toOffice ? '' : message.smsStatus} arrow placement="left-end">
            <div className={classes.messageTime}>
              {!toOffice && <ShowStatus status={message.smsStatus} />}
              <span>{tzParseFormat(message.created, tz, 'MM/dd/yyyy h:mm a')}</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const defaultClient = {
  id: 0,
  allowSMS: true,
  firstName: '',
  lastName: ''
};

export const uploadSizeLimit = 1500000;

interface ChatThreadProps {
  clientId?: number;
  embedded?: boolean;
  heightMod?: number;
  smallScreen?: boolean;
}

const ChatThread = ({
  clientId, embedded, heightMod = 0, smallScreen = false,
}: ChatThreadProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [text, setText] = useState('');
  const [openLead, setOpenLead] = useState(false);
  const [convertLead, setConvertLead] = useState(false);
  const [textHeight, setTextHeight] = useState(0);
  const [attachments, setAttachments] = useState([]);
  const [prev, setPrev] = useState([]);
  const [prevLength, setPrevLength] = useState(0);
  const formRef = useRef(null);
  const textFieldRef = useRef(null);
  const attachmentInputRef = useRef(null);
  const showEmoji = useMediaQuery('(min-width:600px)');
  const getPhiState = usePromise(rawActions.getPhis, []);

  const {
    busy, state, tz, messages, page, client = defaultClient, allLoaded, perPage,
    moreBusy, createBusy, isOptedOutTwoWay, newlySentMessage, oldLayout, banners
  } = useSelector((state) => ({
    ...state.messageThread,
    isOptedOutTwoWay: R.includes('TwoWayMessages', R.pathOr([], ['messageThread', 'clientOptOut', 'optouts'], state)),
    tz: R.pathOr('America/New_York', ['login', 'office', 'timezone'])(state),
    unread: undefined,
    oldLayout: R.pathOr(true, ['login', 'oldLayout'])(state),
    banners: state.login.banners,
  }));

  const params = useParams();
  const urlId = R.pathOr(clientId, ['id'], params);
  const actions = bindActionCreators(rawActions, dispatch);
  const fullPage = !embedded;
  const isLead = location.href.includes('sms-leads-inbox');

  const onHeightChange = () => {
    const height = formRef.current?.getBoundingClientRect()?.height;
    if (heightMod) {
      setTextHeight(heightMod);
    } else {
      setTextHeight(height);
    }
  };

  useEffect(() => {
    if (fullPage) {
      window.document.title = 'Chat with client';
    }
  }, [client.firstName]);

  useEffect(() => {
    actions.loadMessage({ clientId: Number(urlId), perPage });
    setTimeout(() => {
      onHeightChange();
    });
  }, [urlId]);

  useEffect(() => {
    actions.messageThreadPatch({
      client: {},
    });
  }, []);

  React.useEffect(() => {
    // Break out of react's update
    // but it's the easiest way to do this
    const div = document.getElementById('monkey-inner');
    div.style.height = fullPage ? `calc(100vh - 55px - ${banners?.length * 33}px)` : 'auto';
    return () => {
      div.style.removeProperty('height');
    };
  }, [banners, urlId]);

  useEffect(() => {
    if (messages.length !== prevLength) {
      getPhiState.invoke(messages);
      setPrevLength(messages.length);
    }
  }, [messages]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target: HTMLElement = e.target as HTMLElement;
    const bounds = target.getBoundingClientRect();
    const scrollSpace = target.scrollHeight + target.scrollTop - bounds?.height;
    if (scrollSpace <= 10 && !allLoaded && !moreBusy) {
      actions.loadMoreMessages({
        clientId: client.id,
        page: page + 1,
        perPage,
      });
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (newlySentMessage) {
      console.log('this is the data from quick message send', newlySentMessage);
      actions.pollForStatus({
        msgId: newlySentMessage.msg.msgId,
        tmpId: newlySentMessage.tmpId,
      });
      setText('');
      setAttachments([]);
      setPrev([]);
    }
  }, [newlySentMessage]);

  const submit = () => {
    if (busy) {
      return;
    }

    if (text.trim().length === 0 && attachments.length === 0) {
      popup('Warning!', 'You must fill in the body before sending.');
      return;
    }
    actions.sendMessageQuick({ body: text, attachments }, Number(urlId));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      onHeightChange();
    });
    setText(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
      e.preventDefault();
      submit();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const insertPlaceholder = (body: string, placeholder: string) => {
    const splitAt = (index: number, index2: number) => (x: string) => [x.slice(0, index), x.slice(index2)];
    const ctl = textFieldRef.current;
    const startPos = ctl.selectionStart;
    const endPos = ctl.selectionEnd;
    const splitBody = splitAt(startPos, endPos)(body);
    ctl.focus();
    const newBody = splitBody[0] + placeholder + splitBody[1];
    return newBody;
  };

  const handleEmojiSelect = (e: BaseEmoji) => {
    if (e?.native) {
      setText(insertPlaceholder(text, e.native));
    }
    handleClose();
  };

  React.useEffect(() => {
    if (prev.length < attachments.length) {
      rawActions.uploadAttachments(attachments, setAttachments);
      setPrev(attachments);
    }
  }, [attachments]);

  const handleDelete = (ind: number) => () => {
    setAttachments(R.remove(ind, 1, attachments));
    setPrev(R.remove(ind, 1, prev));
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      files
    } = e.target;
    const size: number = R.reduce((a, c) => {
      return a + c.size;
    }, 0, files as unknown as Blob[]);
    if (size < uploadSizeLimit) {
      R.forEach((f) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
      }, files as unknown as Blob[]);
      const filesArray = R.map((f) => ({
        file: f,
        progress: 0,
      }))(files as unknown as File[]);
      setAttachments(attachments.concat(filesArray));
    } else {
      console.log('too big');
      alert('Error! MMS messages cannot contain attachments greater than 1.5MB!');
    }
  };

  const handleNameClick = () => {
    if (isLead) {
      setOpenLead(true);
      return;
    }
    actions.selectClient(client);
  };

  const handleConvert = () => {
    navigate(-1);
  };

  const handleUpdate = () => {
    actions.loadMessage({ clientId: client.id, perPage });
  };

  if (!oldLayout) {
    return (
      <InboxChat
        fullPage={fullPage}
        heightMod={smallScreen ? 360 : 270}
      />
    );
  }

  return (
    <div className={classes.root} style={fullPage ? { minHeight: `calc(100vh - 58px - ${banners?.length * 32}px)` } : {}}>
      {fullPage && <div className={classes.chatHeader}>
        <Button component='a' href={isLead ? '#/sms-leads-inbox' : '#/inbox'} startIcon={<ChevronLeftIcon />}>Back</Button>
        <Spacer />
        <span className={classes.headerText}>SMS Chat</span>
        <Spacer />
        <>
          {isLead && <Button variant='contained' onClick={() => setConvertLead(true)}>Convert</Button>}
          <Button onClick={handleNameClick}>{client && client.firstName} {client && client.lastName} {caseType(client)}</Button>
        </>
      </div>}
      <Spacer style={{
        position: 'relative',
        height: fullPage ? `calc(100% - ${textHeight - (banners?.length * 32)}px - 54px)` : `${470 - textHeight - (attachments.length * 40)}px`,
        overflow: 'hidden',
      }}>
        <div className={classes.chatContainer} onScroll={handleScroll}>
          <div className={classes.loader}>
            {busy && <CircularProgress />}
          </div>
          {!busy && (!client.allowSMS || isOptedOutTwoWay) &&
            <div className={classes.systemMessage}>
              {client.firstName} disabled SMS notifications. 😢
            </div>}
          {!busy && messages.length === 0 && client.allowSMS &&
            <div className={classes.systemMessage}>
              Start a conversation with {client.firstName} 💬
            </div>}
          {!busy && messages.map((message: SentMessage) => (
            <ChatItem key={message.msgId} message={message} tz={tz} classes={classes} phis={getPhiState.data} />
          ))}
          <div className={classes.loader} style={{ paddingTop: allLoaded ? '5px' : '55px' }}>
            {moreBusy && <CircularProgress />}
          </div>
        </div>
      </Spacer>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
        {attachments.map((f, ind) => {
          const {
            file,
            progress,
            attachment,
          } = f;
          const url = R.propOr(false, 'attachmentUrl')(attachment);
          return (
            <div
              key={ind}
              style={{
                backgroundColor: 'lightgray',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '5px',
                borderRadius: '5px',
                marginBottom: '5px',
                width: '95%',
              }}>
              <div style={{
                display: 'flex',
              }}>
                {url ?
                  <a href={url as string} target='_blank'>
                    {file ? file.name : attachment.attachmentName}</a>
                  :
                  <p style={{
                    margin: 'unset',
                  }}>
                    {file ? file.name : attachment.attachmentName} </p>}
                  &nbsp;
                {file &&
                    <p style={{
                      margin: 'unset',
                    }}>{ '(' + getFileSize(file.size, true) + ')' }</p>}
              </div>
              {progress !== 100 ?
                <div style={{
                  width: '100px',
                  height: '10px',
                  backgroundColor: '#b3b3b3',
                }}>
                  <div style={{
                    width: progress + 'px',
                    height: '100%',
                    backgroundColor: '#008BCF',
                  }}></div>
                </div> :
                <CloseIcon
                  style={{
                    cursor: 'pointer',
                    fontSize: 18,
                  }}
                  onClick={handleDelete(ind)}
                />}
            </div>);
        })}
      </div>
      <form
        noValidate
        autoComplete="off"
        style={{ paddingRight: fullPage ? '150px' : '20px' }}
        className={classes.bottom}
        ref={formRef}
        onSubmit={handleSubmit}>
        <CharacterCount str={text} />
        <div className={classes.inputArea}>
          <TextField
            className={classes.textArea}
            inputRef={textFieldRef}
            multiline
            maxRows={4}
            placeholder={(!client.allowSMS || isOptedOutTwoWay) ? (client.firstName + ' disabled SMS notifications. 😢') : ''}
            value={text}
            onKeyPress={handleKeyPress}
            onChange={handleTextChange}
            disabled={createBusy || !client.allowSMS || isOptedOutTwoWay}
            InputProps={showEmoji ? {
              endAdornment: (
                <InputAdornment position="start">
                  <input
                    accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.vcf"
                    style={{ display: 'none' }}
                    id="chat-attachment"
                    multiple
                    type="file"
                    ref={attachmentInputRef}
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="chat-attachment" style={{ width: '41px' }}>
                    <IconButton
                      component="span"
                      aria-label="Upload Attachment"
                      disabled={!client.allowSMS || isOptedOutTwoWay}>
                      <AttachmentIcon style={{ transform: 'rotate(-90deg)' }} />
                    </IconButton>
                  </label>
                  <IconButton
                    type='button'
                    disabled={!client.allowSMS || isOptedOutTwoWay}
                    onClick={handleClick}
                    onMouseDown={handleMouseDown}>
                    <InsertEmoticonIcon />
                  </IconButton>
                </InputAdornment>
              ),
            } : null}
            id="sms-chat-input"
            variant="outlined" />
          <IconButton
            disabled={createBusy || (attachments.length === 0 && text.length === 0)}
            aria-label='send'
            type='submit'>
            <SendIcon />
          </IconButton>
        </div>
      </form>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Picker native={true} title="Emoji Picker" onSelect={handleEmojiSelect} />
      </Popover>
      <PopupTemplate />
      {fullPage && <ClientEditDialog
        open={state === 'CLIENT_SELECT'}
        onClose={actions.backToMessage}
        back={actions.back}
        from={'MESSAGE'}
        gotoClient={actions.back} />}
      {fullPage && <EditLeadDialog
        open={openLead}
        close={() => setOpenLead(false)}
        lead={client}
        convertClick={() => setConvertLead(true)}
        onUpdate={handleUpdate}
      />}
      {fullPage && <ConvertLeadDialog
        open={convertLead}
        lead={client}
        close={() => setConvertLead(false)}
        onConvert={handleConvert}
      />}
    </div>
  );
};

export default ChatThread;
