import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDrag, useDrop } from 'react-dnd';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import {
  IconButton, Menu, MenuItem, InputLabel, FormControl,
  Select as MuiSelect, ListItem, Divider, List, ListItemText,
  ListSubheader, Chip, Checkbox, Tooltip, CircularProgress, Collapse,
  ListItemIcon, Grid, Popover, OutlinedInput, SelectChangeEvent,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PendingIcon from '@mui/icons-material/MoreHoriz';
import MenuIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ZonedDateTime, ZoneId, ChronoZonedDateTime } from '@js-joda/core';
import { PopupTemplate } from '../../services/Popup.js';
import GearIcon from '../../icons/Settings.icon';
import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';
import FileIcon from '@mui/icons-material/Assignment';
import TodayIcon from '@mui/icons-material/Today';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Appointments from '../Appointments/Appointments.component';
import { Appointment } from '../Appointments/appointments.types';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import {
  merge, map, pipe, prop, includes, uniqBy, pathOr, assoc, prepend,
  evolve, allPass, both, has, filter, propEq, sort, ascend, descend,
  mapObjIndexed, values, reduce, and, propOr, uniq, isEmpty, find, always,
  equals, cond, groupBy, flatten, length, head, keys, update, append, without,
  join, ifElse, all, identity, anyPass
} from 'ramda';

import { usePromise } from '../../services/promise.hook';
import { caseType } from '../../services/utilities.js';
import {
  tzParseFormat, decipherTime, formatStandard,
  tzParse, now, startOf, endOf, format,
} from '../../services/joda.js';
import api from '../../services/api.js';
import {
  getSettings, getStopManagers, updateFrontdeskMessage, getStop,
  setWaitingState, updateWithNextAppt, handleApptEvent,
  handleWaitingRoomUpdate, updateApptOptimistic, initFrontDesk,
  getInitialState, frontDeskLeavePage, SCHEDULED, IN_LOBBY, IN_ROOM,
  SEEN, CANCELED, MISSED, RESCHEDULED
} from './waiting.actions.js';
import { getProfessionals } from '../Professionals/professionals.actions.jsx';
import { SettingsPopup } from './components/settings.component.jsx';
import { reset, backToClient, getCurrentClient } from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import { Client } from '../Clients/clients.types';
import { useTitle } from '../../services/useTitle.js';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { useSocket } from '../../services/websocket.hook.js';
import { SchedulerDialog } from '../CalendarV2/components/scheduler-dialog.component.js';
import { getLocations } from '../Settings/routes/Business/business.actions.js';
import StopDialog, { CreateStopDialog } from './stop-dialog.component.jsx';
import StopManagerTemplateDialog from '../StopManagers/stop-managers.page.jsx';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import ClearRoomIcon from '../../icons/ClearRoom.icon';
import StopIcon from '../../icons/Stop.icon';
import TaskIcon from '../../icons/Warning.icon';
import { useSelector } from '../../reducers';
import { useStyles } from './frontDesk.styles';

type Professional = {
  id: number;
  firstName?: string;
  lastName?: string;
  displayFirstName?: string;
  displayLastName?: string
  locationId?: number;
}

type AppointmentType = {
  id: number;
  professionalId: number;
  name?: string;
  internalName?: string;
  clicked?: boolean;
  time?: string;
  color?: {
    saturation: string;
    hue: string;
    lightness: string;
  };
}

type Stop = {
  id?: number;
  isDeleted?: boolean;
  done?: string;
  stopType?: string;
  message?: string;
  client?: Client;
}

type ItemType = {
  name?: string | number;
  status?: string;
  roomNumber?: number;
  clientFirstName?: string;
  clientLastName?: string;
  clientId?: number;
}

type Filters = {
  roomIds: number[],
  professionalIds: number[],
  locationIds: number[],
}

const bookingKeys = ['Confirmed', 'Pending', 'OverScheduled'];
const getBookingStatus = (bookingStatus: string) => {
  return bookingKeys.find(k => has(k, bookingStatus)) || 'Error';
};

const LargerListSectionHeader = withStyles({
  root: {
    fontSize: '14px',
  },
})(ListSubheader);

const BookingStatusStandard = ({ status = '', confirmed = '', timezone = '' }) => {
  const fontSize = 20;
  if (status === 'Confirmed') {
    const time = tzParseFormat(confirmed, timezone, 'yyyy-MM-dd h:mm a');
    const strTime = time.includes('error') ? '' : `: ${time}`;
    return <Tooltip placement="top" arrow title={'Confirmed' + strTime}>
      <CheckBoxIcon style={{ fontSize, color: '#65B67F' }} />
    </Tooltip>;
  }
  if (status === 'Pending') {
    return (
      <Tooltip arrow title="Pending" placement="top">
        <PendingIcon style={{ fontSize }} className="pending" />
      </Tooltip>
    );
  }
  if (status === 'OverScheduled') {
    return (
      <Tooltip placement="top" arrow title="Over Scheduled Error.  A reschedule is needed.">
        <CloseIcon style={{ fontSize }} className="error" />
      </Tooltip>
    );
  }
  return (
    <Tooltip placement="top" arrow title="Unknown Error.  Contact support for help.">
      <CloseIcon style={{ fontSize }} className="error" />
    </Tooltip>
  );
};

const updateStatus = ({ item, dropResult }: { item: ItemType, dropResult: ItemType }) => {
  if (item.status !== IN_ROOM && item.status === dropResult.status) {
    return;
  }
  if (dropResult.status === SCHEDULED) {
    return Promise.resolve().then(() => {
      if (item.status === IN_ROOM || item.status === SEEN) {
        return api.delete(`/waitingroom/${item.name}`);
      }
      return;
    }).then(() => {
      return api.put(`appointment/${item.name}`, { status: { 'Scheduled': [] }, source: 'Admin' });
    });
  }
  if (dropResult.status === IN_LOBBY) {
    return Promise.resolve().then(() => {
      if (item.status === IN_ROOM || item.status === SEEN) {
        return api.delete(`/waitingroom/${item.name}`);
      }
      return;
    }).then(() => {
      return api.put(`appointment/${item.name}`, { status: { 'Arrived': [] }, source: 'Admin' });
    });
  }
  if (dropResult.status === IN_ROOM) {
    if (item.status === dropResult.status && item.roomNumber === dropResult.roomNumber) {
      return;
    }
    return Promise.resolve().then(() => {
      if (item.status === SCHEDULED) {
        return api.put(`appointment/${item.name}`, { status: { 'Arrived': [] }, source: 'Admin' });
      }
    }).then(() => {
      return api.post('/waitingroom/arrived/room', { apptId: item.name, roomNumber: dropResult.roomNumber });
    });
  }
  if (dropResult.status === SEEN) {
    return Promise.resolve().then(() => {
      return api.post('/waitingroom/arrived', { apptId: item.name });
    }).then(() => {
      if (item.status === SCHEDULED) {
        return api.put(`appointment/${item.name}`, { status: { 'Arrived': [] }, source: 'Admin' });
      }
    });
  }
  return;
};

const getMissedStatus = (status: string, time: string) => {
  const now = new Date().toISOString();
  if (has('Canceled', status)) {
    return CANCELED;
  }
  if (has('Missed', status) || (time < now && has('Scheduled', status))) {
    return MISSED;
  }
  return SCHEDULED;
};

const MissedStatus = ({ status }: { status: string }) => {
  if (status === CANCELED) {
    return <Tooltip arrow title={CANCELED}><ClearIcon style={{ fontSize: 16 }} /></Tooltip>;
  }
  if (status === MISSED) {
    return <Tooltip arrow title={MISSED}><ScheduleIcon style={{ fontSize: 16 }} /></Tooltip>;
  }
  if (status === RESCHEDULED) {
    return <Tooltip arrow title={RESCHEDULED}><CallMissedIcon style={{ fontSize: 16 }} /></Tooltip>;
  }
  return null;
};

const fixLength = (str: string, l = 50) => {
  if (str && str.length > l) {
    return str.slice(0, l + 1) + '...';
  }
  return str;
};

type AData = {
  id: number;
  roomNumber?: number;
  appointmentType?: AppointmentType;
  bookingStatus?: string;
  confirmed?: string;
  waitingRoomStatus?: string;
  client?: Client;
  status?: string;
  stop?: Stop;
  time?: string;
  loading?: boolean;
  professional?: Professional;
  arrivedAt?: string;
  nextAppt?: {
    time: string;
    apptType: string;
  }
}

type AppointmentEntryProps = {
  a: AData,
  client: Client;
  timeAt: string;
  time: string;
  appointmentType: string;
  changedAt: string;
  apptId: number;
  status: string;
  appointmentStatus: string;
  updateApptStatus: (value: { item: ItemType, dropResult: ItemType }) => void;
  openMenu: (value: { a: AData, apptId: number, client: Client }) => (event: React.MouseEvent<HTMLDivElement>) => void;
  loading?: boolean;
  stop: Stop;
  professional?: Professional;
  timezone?: string;
  hasScroll?: boolean;
}

const AppointmentEntry = ({
  a,
  client,
  timeAt,
  time,
  appointmentType,
  changedAt,
  apptId,
  status,
  appointmentStatus,
  updateApptStatus,
  openMenu,
  loading,
  stop,
  professional,
  timezone,
  hasScroll
}: AppointmentEntryProps) => {
  const missedStatus = getMissedStatus(appointmentStatus, time);
  const classes = useStyles();
  const [, drag] = useDrag({
    item: {
      name: apptId,
      type: 'APPT',
      status,
      roomNumber: a.roomNumber,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      clientId: client.id
    },
    type: 'APPT',
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        updateApptStatus({ item, dropResult });
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const color = a.appointmentType.color ?
    'hsl(' + a.appointmentType.color.hue + ',' + a.appointmentType.color.saturation + '%,' + a.appointmentType.color.lightness + '%)' :
    'white';

  const stopStatus = stop && head(keys(stop.done));

  return (
    <>
      <ListItem
        ref={drag}
        style={{ borderLeft: '5px solid ' + color, marginRight: hasScroll ? 1 : 6 }}
        disableRipple
        className={classes.card}
        button
        onClick={(event) => openMenu?.({ a, apptId, client })(event)}
      >
        <Grid width="100%" display="flex" flexDirection="column" className={classes.iconContainer}>
          <Grid display="flex" alignItems="center">
            <BookingStatusStandard status={getBookingStatus(a.bookingStatus)} confirmed={a.confirmed} timezone={timezone} />
            <p className={classes.cardTitle}>{client.firstName} {client.lastName} {caseType(client)}</p>
          </Grid>
          <div className={classes.cardText2}>{appointmentType}<span className={classes.cardText}> @ {timeAt}</span></div>
          {professional && <Grid display="flex" alignItems="center">
            <AccountCircleIcon style={{ fontSize: 12, marginRight: 3 }} /><span className={classes.cardText2}> {professional.firstName} {professional.lastName}</span>
          </Grid>}
          <span className={classes.cardText}>{changedAt}</span>
          {(a.nextAppt?.time || a.nextAppt?.apptType) && <div className={classes.cardText}>
            <div className={classes.nextAppt}>Next: {a.nextAppt.time} {a.nextAppt.apptType}</div>
          </div>}
        </Grid>
        <div style={{ position: 'absolute', right: '12px', top: '5px' }}>
          {loading ? <MenuIcon style={{ fontSize: 20 }} /> : <MissedStatus status={missedStatus} />}
        </div>
        {stop && !stop.isDeleted && // includes(stopStatus, ['Done', 'Open']) &&
          <div style={{ position: 'relative', fontSize: 14 }}>
            <Tooltip arrow title={stopStatus}>
              <div
                style={{
                  backgroundColor: titleStyle(stopStatus),
                  width: '10px',
                  height: '10px',
                  borderRadius: '10px',
                  position: 'absolute',
                  top: '13px',
                  left: '13px',
                }}
              />
            </Tooltip>
            {stop.stopType === 'Task' ?
              <TaskIcon style={{ verticalAlign: 'middle', marginRight: 5 }} /> :
              <StopIcon style={{ verticalAlign: 'middle', marginRight: 5 }} />}
            &nbsp;
            {fixLength(stop.message)}
          </div>}
      </ListItem>
    </>
  );
};

type RoomProps = {
  number: number;
  name: string;
  displayName: string;
  allData: AData[];
  tz: string;
  loading: boolean;
  isLast?: boolean;
  status: string;
  id?: number;
  index?: number;
  hasScroll?: boolean;
  updateApptStatus: (value: { item: ItemType, dropResult: ItemType}) => void;
  openMenu: ({ a, apptId, client }: { a: AData, apptId: number, client: Client }) => (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Room = ({
  number,
  name,
  displayName,
  allData,
  tz,
  loading,
  status,
  index,
  isLast,
  updateApptStatus,
  openMenu,
  hasScroll,
}: RoomProps) => {
  const classes = useStyles();
  const data = useMemo(() => allData.filter(a => Number(a.roomNumber) === Number(number)), [allData]);

  const [{ isOver }, drop] = useDrop(({
    accept: 'APPT',
    drop: () => ({
      name: displayName || name,
      roomNumber: number,
      status
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const clearRoomHandler = () => {
    const dropResult = { status: SEEN };
    const items = data.map(a => ({
      name: a.id,
      status: a.waitingRoomStatus,
      clientName: ''
    }));

    return items.reduce((prom, item) => prom.then(() => {
      return updateApptStatus({ item, dropResult });
    }), Promise.resolve());
  };

  return (
    <List ref={drop}
      style={{
        backgroundColor: isOver ? 'rgba(0, 139, 207, 0.2)' : 'white',
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: data?.length > 0 ? 8 : 0,
      }}>
      <Grid
        className={classes.roomTitle}
        borderTop={index !== 0 ? '1px solid rgba(0, 0, 0, 0.2)' : 0}
        borderBottom={isLast && data?.length === 0 ? '1px solid rgba(0, 0, 0, 0.2)' : 0}
        style={isOver ? { backgroundColor: 'rgba(0, 139, 207, 0.2)', paddingRight: hasScroll ? 1 : 6 } : { paddingRight: hasScroll ? 1 : 6 } }>
        <span>{name}</span>
        {data?.length > 0 ? (
          <Tooltip title="Clear Room">
            <IconButton size="small" onClick={clearRoomHandler}>
              <ClearRoomIcon opacity={0.8} />
            </IconButton>
          </Tooltip>
        ) : <div />}
      </Grid>
      {!loading && data && data.length > 0 && data.map((a) => (
        <AppointmentEntry
          key={a.id}
          a={a}
          apptId={a.id}
          status={status}
          client={a.client}
          openMenu={openMenu}
          appointmentType={a.appointmentType.internalName}
          appointmentStatus={a.status}
          updateApptStatus={updateApptStatus}
          time={a.time}
          loading={a.loading}
          timeAt={a.time && tzParseFormat(a.time, tz, 'h:mm a')}
          changedAt={a.arrivedAt && decipherTime(a.arrivedAt)}
          stop={a.stop}
          professional={a.professional}
          timezone={tz}
          hasScroll={hasScroll}
        />
      ))}
    </List>
  );
};

type RoomsListProps = {
  loading: boolean;
  data: AData[];
  className: string;
  title: string;
  error?: boolean;
  failedMessage?: string;
  rooms?: RoomProps[];
  openMenu: (value : { a: AData, apptId: number, client: Client }) => (event: React.MouseEvent<HTMLDivElement>) => void;
  status: string;
  updateApptStatus: (value: { item: ItemType, dropResult: ItemType }) => void;
  tz?: string;
  height?: number;
}

const RoomsList = ({
  loading,
  data,
  className,
  title,
  error,
  failedMessage,
  rooms = [],
  openMenu,
  status,
  updateApptStatus,
  tz,
  height,
} : RoomsListProps) => {
  const classes = useStyles();
  const [hasScroll, setHasScroll] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const ref = React.useRef(null);

  const checkScroll = () => {
    if (ref.current) {
      const hasVerticalScrollbar = ref.current.scrollHeight > ref.current.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }
  };
  useEffect(() => {
    checkScroll();
  }, [rooms.length, ref.current, hover]);

  return (
    <Grid className={classes.listContainer + ' ' + className}>
      <Grid paddingX={1} className={classes.gridHeader}>
        <ListItemText primary={title} />
        <Chip size="small" label={data ? data.length : 0} />
      </Grid>
      <Divider />
      {(loading || error) ? <List classes={{ root: classes.listRoot }} style={{ height: height || '84vh' }}>
        {loading && <ListItem><div>Loading...</div></ListItem>}
        {error && <ListItem><div>{failedMessage}</div></ListItem>}
      </List> : <List classes={{ root: `${classes.listRoot} ${hasScroll && hover ? classes.onHover : ''}` }} ref={ref} style={{ height: height || '84vh' }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {rooms.map(({ id, name, displayName }, index) => <Room
          key={id}
          updateApptStatus={updateApptStatus}
          status={status}
          openMenu={openMenu}
          loading={loading}
          number={id}
          name={name}
          displayName={displayName}
          allData={data}
          index={index}
          isLast={index === rooms.length - 1}
          tz={tz}
          hasScroll={hasScroll && hover}/>
        )}
      </List>}
    </Grid>
  );
};

type SectionItemProps = {
  objKey: string;
  appts: AData[];
  openMenu: (value : { a: AData, apptId: number, client: Client }) => (event: React.MouseEvent<HTMLDivElement>) => void;
  updateApptStatus:(value: { item: ItemType, dropResult: ItemType}) => void;
  tz: string;
}

const SectionItem = ({
  objKey,
  appts,
  openMenu,
  updateApptStatus,
  tz,
}:SectionItemProps) => {
  const classes = useStyles();
  const key = objKey;
  const [open, setOpen] = useState(true);
  return (
    <>
      <ListItem
        key={key}
        className={classes.sectionContainer}
        onClick={() => {
          setOpen(!open);
        }}>
        <ListItemText
          primary={
            <>
              {key}
              &nbsp;
              <Chip className={classes.sectionChip} size="small" label={appts.length} />
            </>}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {appts.map((a) => (
          <AppointmentEntry
            key={a.id}
            a={a}
            apptId={a.id}
            status={a.waitingRoomStatus}
            client={a.client}
            appointmentType={a.appointmentType.internalName}
            appointmentStatus={a.status}
            time={a.time}
            loading={a.loading}
            openMenu={openMenu}
            updateApptStatus={updateApptStatus}
            timeAt={a.time && tzParseFormat(a.time, tz, 'h:mm a')}
            changedAt={a.arrivedAt && decipherTime(a.arrivedAt)}
            stop={a.stop}
            timezone={tz}
          />
        ))}
      </Collapse>
    </>
  );
};

type WaitingRoomListProps = {
  status: string;
  error: boolean;
  data: unknown[] | unknown;
  title: string;
  failedMessage: string;
  emptyMessage?: string;
  loading: boolean;
  tz: string;
  height?: number;
  className?: string;
  updateApptStatus: (value: { item: ItemType, dropResult: ItemType }) => void;
  openMenu: (value : { a: AData, apptId: number, client: Client }) => (event: React.MouseEvent<HTMLDivElement>) => void;
}

const WaitingRoomList = ({
  status,
  error,
  data,
  title,
  failedMessage,
  emptyMessage,
  loading,
  tz,
  height,
  className,
  updateApptStatus,
  openMenu
}:WaitingRoomListProps) => {
  const classes = useStyles();
  const [hover, setHover] = React.useState(false);
  const [{ isOver }, drop] = useDrop(({
    accept: status !== MISSED ? 'APPT' : 'MISSED',
    drop: () => ({ name: title, status }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const [hasScroll, setHasScroll] = React.useState(false);

  useEffect(() => {
    const ref = document.getElementById(`appt-list-${title.replace(/ /g, '-')}`);
    if (ref) {
      const hasVerticalScrollbar = ref.scrollHeight > ref.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }
  }, [data, title, hover]);

  return (
    <Grid className={classes.listContainer + ' ' + className} >
      <Grid width="100%" paddingX={1} className={classes.gridHeader}>
        <ListItemText primary={title} />
        {status !== SCHEDULED &&
          <Chip size="small" label={<span>{data ? (data as unknown[]).length : 0}</span>} />}
        {status === SCHEDULED &&
          <Chip size="small" label={<span>{data ? pipe(values, flatten, length)((data as unknown[])) : 0}</span>} />}
      </Grid>
      <Divider />
      <List
        ref={drop}
        id={`appt-list-${title.replace(/ /g, '-')}`}
        classes={{ root: `${classes.listRoot} ${hasScroll && hover ? classes.onHover : ''}` }}
        style={{ backgroundColor: isOver ? 'rgba(0, 139, 207, 0.2)' : 'white', height: height || '84vh' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {loading && <ListItem className={classes.emptyContainer}><div>Loading...</div></ListItem>}
        {error && <ListItem className={classes.emptyContainer}><div>{failedMessage}</div></ListItem>}

        {!loading && data && isEmpty(data) && <ListItem className={classes.emptyContainer}>
          <div>{emptyMessage}</div>
        </ListItem>}

        {!loading && data && status !== SCHEDULED && !isEmpty(data) &&
          (data as unknown as AData[]).map((a) => (
            <AppointmentEntry
              key={a.id}
              a={a}
              apptId={a.id}
              status={a.waitingRoomStatus}
              client={a.client}
              appointmentType={a.appointmentType.internalName}
              appointmentStatus={a.status}
              time={a.time}
              loading={a.loading}
              openMenu={openMenu}
              updateApptStatus={updateApptStatus}
              timeAt={a.time && tzParseFormat(a.time, tz, 'h:mm a')}
              changedAt={a.arrivedAt && decipherTime(a.arrivedAt)}
              stop={a.stop}
              professional={a.professional}
              timezone={tz}
              hasScroll={hasScroll && hover}
            />
          ))}

        {!loading && data && status === SCHEDULED && !isEmpty(data) &&
          pipe(
            mapObjIndexed((appts: AData[], key: string) => {
              return (
                <SectionItem
                  key={key}
                  objKey={key}
                  appts={appts}
                  openMenu={openMenu}
                  updateApptStatus={updateApptStatus}
                  tz={tz}
                />
              );
            }),
            values,
          )(data as unknown as Record<string, AData[]>)}
      </List>
    </Grid>
  );
};

type PredicateProps = {
  statuses: string[];
  professionalIds: number[];
  search: object;
  locationProfessionalIds: number[];
  locationIds: number[];
  onlyStops: boolean;
}

type ItemProp = {
  client: Client;
  appointmentType: AppointmentType;
  waitingRoomStatus: string;
  stop: Stop;
  time: string;
  status?: string;
  arrivedAt: string;
}

type ClientPart = {
  lastName: string;
  firstName: string;
  phone: string;
}

const predicate = ({
  statuses,
  professionalIds,
  search,
  locationProfessionalIds,
  locationIds,
  onlyStops,
}: PredicateProps) => (item: ItemProp): boolean => {
  const { client, appointmentType, waitingRoomStatus, stop } = item;
  const lowerCaseClient: ClientPart = {
    lastName: (client.lastName || '').toLowerCase(),
    firstName: (client.firstName || '').toLowerCase(),
    phone: (client.phone || '').toLowerCase(),
  };
  const query = mapObjIndexed((s: string) => {
    if (s) {
      return s.toLowerCase().trim();
    }
    return undefined;
  }, search);
  const containsName = pipe(
    mapObjIndexed((s: string, key: keyof ClientPart) => {
      if (s) {
        return lowerCaseClient[key].split(s).length > 1;
      }
      return true;
    }),
    values,
    reduce(and, true),
  )(query as ClientPart);
  const hasProFilter = professionalIds.length === 0 ||
    includes(appointmentType.professionalId, professionalIds);
  const statusFilter = includes(waitingRoomStatus, statuses);
  const hasLocation = locationIds.length === 0 ||
    includes(appointmentType?.professionalId, locationProfessionalIds);
  const hasStop = onlyStops ? stop : true;
  return containsName && hasProFilter && statusFilter && hasLocation && !!hasStop;
};

const selectAppointmentAPI = ({ client, appointment }: { client: Client, appointment: Appointment }) => {
  return api.get(`appointment/${appointment.id}/log`).then(log => {
    return {
      ...appointment,
      client,
      aptData: log
    };
  });
};


const locationProfessionals = (professionals: Professional[], locationIds: number[]) => {
  return professionals.filter((pro) => includes(pro.locationId, locationIds)).map((pro) => pro.id);
};

const titleStyle = (status: string) => cond([
  [equals('Done'), () => 'lightgray'],
  [equals('Open'), () => '#4CAF50'],
  [equals('Waiting'), () => '#FF9900'],
  [equals('Postponed'), () => '#0089D2'],
])(status);

type StopListItemProps = {
  stop: Stop;
  appointments: AppointmentType[];
  selectStop: (stop: Stop) => void;
  stopType: string;
  tz: string;
}

const StopListItem = ({
  stop,
  appointments,
  selectStop,
  stopType,
  tz,
}: StopListItemProps) => {
  const classes = useStyles();
  const { id, done, client, message } = stop;
  const apptId = propOr(0, 'appointmentId', stop);
  const appt = find(propEq('id', apptId), appointments) as AppointmentType;
  const status = head(keys(done));
  const header = client ? `${client.firstName} ${client.lastName}` : '';

  return (
    <ListItem
      key={id}
      className={classes.card}
      button
      onClick={() => {
        selectStop(stop);
      }}
    >
      <Grid width="100%">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
          <div>
            <Tooltip arrow title={status}>
              <div
                style={{
                  backgroundColor: titleStyle(status),
                  width: '10px',
                  height: '10px',
                  borderRadius: '10px',
                  position: 'absolute',
                  top: '23px',
                  left: '23px',
                }}
              />
            </Tooltip>
            {stopType === 'Task' ?
              <TaskIcon style={{ verticalAlign: 'middle' }} /> :
              <StopIcon style={{ verticalAlign: 'middle' }} />
            }
              &nbsp;&nbsp;
            <span className={classes.cardTitle}>{header}</span>
          </div>
          <span className={classes.time}>
            {appt && tzParseFormat(appt.time, tz, 'h:mm a')}
          </span>
        </div>

        <div style={{
          fontSize: 14,
          marginTop: 5,
          width: '100%',
        }}>
          {message}
        </div>

      </Grid>
    </ListItem>
  );
};

const doneToNum = pipe(
  propOr({ Postponed: [] }, 'done'),
  keys,
  head,
  cond([
    [equals('Open'), always(0)],
    [equals('Waiting'), always(1)],
    [equals('Done'), always(2)],
    [equals('Postponed'), always(3)],
  ])
);

const sortByDone = (a: Stop, b: Stop) => doneToNum(a) - doneToNum(b);

const findAndReplace = (stop: UpdateFromFrontDeskType) => (s: { id: number }) => {
  if (s.id === stop.id) {
    return assoc('done', stop.done, s);
  }
  return s;
};

type UpdateFromFrontDeskType = {
  clientId: number;
  isDeleted: boolean;
  appointmentId: number;
  stopType: string;
  done: {
      Open: string[]
  },
  created: string;
  from: {
      OneOff: []
  },
  id: number;
  message: string;
  client: Client;
}

type StopsDrawerProps = {
  open: boolean;
  onClose: () => void;
  tz: string;
  height?: number;
  appointments: AppointmentType[];
  updateFrontDesk: (s: { from: { StopManager: {appointmentId : number }}}) => void;
  updateFromFrontDesk: UpdateFromFrontDeskType;
}

type StateStop = {
  stop: Stop, stopType: string, index: number
}

const StopsDrawer = ({
  open,
  onClose,
  tz,
  height,
  appointments,
  updateFrontDesk,
  updateFromFrontDesk,
}: StopsDrawerProps) => {
  const classes = useStyles();
  const [openStops, setOpenStops] = useState(true);
  const [openTasks, setOpenTasks] = useState(true);
  const [search, setSearch] = useState('');
  const [stops, setStops] = useState<Stop[]>([]);
  const [tasks, setTasks] = useState([]);
  const [isStopOpen, setIsStopOpen] = useState(false);
  const [stop, setStop] = useState<StateStop>({} as StateStop);
  const stopsState = usePromise(getStopManagers, {});
  const tasksState = usePromise(getStopManagers, {});
  const updateStop = usePromise(updateFrontdeskMessage, {});
  const [hover, setHover] = React.useState(false);
  const [hasScroll, setHasScroll] = React.useState(false);
  const ref = React.useRef(null);

  const closeStopDialog = () => {
    setIsStopOpen(false);
    setStop({} as StateStop);
  };

  const checkScroll = () => {
    if (ref.current) {
      const hasVerticalScrollbar = ref.current.scrollHeight > ref.current.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }
  };
  useEffect(() => {
    checkScroll();
  }, [stops.length, ref.current, hover]);

  useEffect(() => {
    if (open) {
      const rightNow = now('datetime');
      const startOfToday = format(startOf(rightNow, 'day'), 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'');
      const endOfToday = format(endOf(rightNow, 'day'), 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'');
      stopsState.invoke({
        page: 1,
        perPage: 1000,
        query: {
          stopType: ['Stop'],
          greater: startOfToday,
          lesser: endOfToday,
        },
      }).then(({ data }: { data: Stop[] }) => {
        setStops(sort(sortByDone, data));
      });
      tasksState.invoke({
        page: 1,
        perPage: 1000,
        query: {
          stopType: ['Task'],
          greater: startOfToday,
          lesser: endOfToday,
        },
      }).then(({ data }) => {
        setTasks(sort(sortByDone, data));
      });
    }
  }, [open]);

  useEffect(() => {
    console.log('UPDATRE FROM : ', updateFromFrontDesk);
    if (updateFromFrontDesk) {
      const { stopType } = updateFromFrontDesk;
      if (stopType === 'Stop') {
        if (stops.find(stop => stop?.id === updateFromFrontDesk.id)) {
          const result = pipe(
            map(findAndReplace(updateFromFrontDesk)),
            sort(sortByDone)
          )(stops as { id: number }[]);
          setStops(result);
        } else {
          const result = pipe(
            append(updateFromFrontDesk as unknown as Stop),
            sort(sortByDone),
          )(stops);
          setStops(result);
        }
      } else {
        if (tasks.find(task => task.id === updateFromFrontDesk.id)) {
          const result = pipe(
            map(findAndReplace(updateFromFrontDesk)),
            sort(sortByDone),
          )(tasks);
          setTasks(result);
        } else {
          const result = pipe(
            append(updateFromFrontDesk as unknown as Stop),
            sort(sortByDone),
          )(tasks);
          setTasks(result);
        }
      }
    }
  }, [updateFromFrontDesk]);

  const handleSearch = (str: string ) => {
    const splitUp = str.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim().toLowerCase() : '';
    const firstName = splitUp[1] ? splitUp[1].trim().toLowerCase() : '';
    // const phone = splitUp[2] ? splitUp[2].trim().toLowerCase() : '';
    const filterByClient = ({ client }: { client: Client }) => {
      return includes(firstName, client.firstName.toLowerCase()) &&
        includes(lastName, client.lastName.toLowerCase());
    };
    setSearch(str);
    setStops(pipe(
      pathOr([], ['data', 'data']),
      filter(filterByClient),
      sort(sortByDone)
    )(stopsState));
    setTasks(pipe(
      pathOr([], ['data', 'data']),
      filter(filterByClient),
      sort(sortByDone)
    )(tasksState));
  };

  return (
    <>
      <StopDialog
        isOpen={isStopOpen}
        close={closeStopDialog}
        stop={stop.stop}
        busy={updateStop.loading}
        // tz={tz}
        patch={(status: string) => () => {
          updateStop.invoke({ stop: stop.stop, status }).then((s) => {
            if (stop.stopType === 'Stop') {
              setStops(update(stop.index, merge(s, { client: stop.stop.client }), stops));
            } else {
              setTasks(update(stop.index, merge(s, { client: stop.stop.client }), tasks));
            }
            closeStopDialog();
            updateFrontDesk(s);
          });
        }}
      />
      <div hidden={!open}>
        <Grid width="100%" paddingX={1} className={classes.gridHeader}>
          <ListItemText primary="Today's Alerts" />
          <Grid display="flex" alignItems="center">
            <FilterText
              value={search}
              placeholder='LastName FirstName'
              onChange={handleSearch}
              headerTitle="Today's Alerts"
            />
            <IconButton
              size='small'
              onClick={onClose}>
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <List
          style={{ overflowX: 'hidden', background: 'transparent', height: height || '84vh' }}
          classes={{ root: `${classes.listRoot} ${hasScroll && hover ? classes.onHover : ''}` }}
          ref={ref}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ListItem
            key='stops'
            className={classes.sectionContainer}
            style={{ paddingRight: hasScroll && hover ? 11 : 16 }}
            onClick={() => {
              setOpenStops(!openStops);
            }}>
            <ListItemText
              primary={
                <>
                  Stops
                  &nbsp;
                  <Chip
                    size="small"
                    className={classes.chip}
                    label={stops ? stops.length : 0}
                  />
                </>}
            />
            {openStops ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {stopsState.loading ?
            <CircularProgress />
            :
            <Collapse in={openStops} timeout='auto' unmountOnExit>
              {stops && <Grid paddingBottom={1.5} paddingX="5px" paddingRight={hasScroll && hover ? 0 : '5px'}>
                {stops.map((stop, idx) => (
                  <StopListItem
                    key={stop.id}
                    stop={stop}
                    tz={tz}
                    selectStop={(s) => {
                      setStop({
                        stop: s,
                        index: idx,
                        stopType: 'Stop',
                      });
                      setIsStopOpen(true);
                    }}
                    appointments={appointments}
                    stopType='Stop'
                  />
                ))}
              </Grid>
              }
            </Collapse>
          }
          <ListItem
            key='tasks'
            className={classes.sectionContainer}
            style={{ paddingRight: hasScroll && hover ? 9 : 16 }}
            onClick={() => {
              setOpenTasks(!openTasks);
            }}>
            <ListItemText
              primary={
                <>
                  Tasks
                  &nbsp;
                  <Chip
                    size="small"
                    className={classes.sectionChip}
                    label={tasks ? tasks.length : 0}
                  />
                </>}
            />
            {openTasks ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {tasksState.loading ?
            <CircularProgress />
            :
            <Collapse in={openTasks} timeout='auto' unmountOnExit>
              {tasks && <Grid paddingBottom={1.5} paddingX="5px" paddingRight={hasScroll && hover ? 0 : '5px'}>
                {tasks.map((task, idx) => (
                  <StopListItem
                    key={task.id}
                    stop={task}
                    tz={tz}
                    selectStop={(s) => {
                      setStop({
                        stop: s,
                        index: idx,
                        stopType: 'Task',
                      });
                      setIsStopOpen(true);
                    }}
                    appointments={appointments}
                    stopType='Task'
                  />
                ))}
              </Grid>
              }
            </Collapse>
          }
        </List>
      </div>
    </>
  );
};

const FrontDesk = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators({
    getProfessionals,
    getSettings,
    reset,
    backToClient,
    getCurrentClient,
    getLocations,
    setWaitingState,
    handleApptEvent,
    updateWithNextAppt,
    handleWaitingRoomUpdate,
    updateApptOptimistic,
    initFrontDesk,
    getInitialState,
    frontDeskLeavePage
  }, dispatch);
  const setState = actions.setWaitingState;

  const {
    settings,
    office,
    tz,
    interval,
    isEnabled,
    proState,
    professionalsToShow,
    locations,
    hasLocations,
    hasTimeBased,
    hasStopManager,
    hasFrontdesk,
    state,
    desktopOpen,
    headerHeight,
  } = useSelector(state => ({
    ...state.waiting,
    tz: state.login.office.timezone,
    interval: state.calendarv2.localInterval || pathOr(15, ['login', 'ehrSettings', 'blockLength'])(state),
    office: state.login.office,
    isEnabled: includes('FrontDesk', pathOr([], ['login', 'features'], state)),
    proState: state.professionals,
    professionalsToShow: state.professionals.professionals.filter(({ isHidden }: { isHidden: boolean }) => !isHidden),
    locations: pathOr([], ['business', 'locations'], state),
    hasLocations: pipe(pathOr([], ['login', 'features']), includes('Locations'))(state),
    hasTimeBased: includes('TimeBasedCheckIn', pathOr([], ['login', 'features'], state)),
    hasStopManager: includes('StopManager', pathOr([], ['login', 'features'], state)),
    hasFrontdesk: includes('FrontDesk', pathOr([], ['login', 'features'], state)),
    desktopOpen: pathOr(true, ['login', 'desktopOpen'], state),
    headerHeight: state.login.headerHeight,
  }));

  const update = (data: object) => setState((s: object) => merge(s, data));
  const [smallPage, setSmallPage] = React.useState(false);

  const selectedApptState = usePromise(selectAppointmentAPI, null);
  const updateStop = usePromise(updateFrontdeskMessage, {});
  const singleStopState = usePromise(getStop, {});

  React.useEffect(() => {
    try {
      const hasStop = location.href.split('stopId=');
      if (hasStop.length > 1) {
        const id = hasStop[1];
        singleStopState.invoke(id).then((s) => {
          console.log(s);
          openStopDialog(s);
        });
      } else {
        return;
      }
    } catch (e) {
      console.log('Failed to parse query param for stop.');
    }
  }, []);

  const handleClickOpen = () => {
    update({ settingsOpen: true });
  };
  const handleClose = () => {
    update({ settingsOpen: false });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItem, setMenuItem] = React.useState(null);

  const handleMenuOpen = (item: { a: AData, apptId: number, client: Client }) => (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const handleMenuClose = () => {
    setMenuItem(null);
    setAnchorEl(null);
  };

  const handleMenuMove = (status: string, roomNumber?: number) => (/*event*/) => {
    console.log('whats in menu item?', menuItem);
    const clientFirstName = menuItem.client.firstName;
    const clientLastName = menuItem.client.lastName;
    const clientId = menuItem.client.id;
    const room = settings.rooms.find(propEq('id', roomNumber));
    const name = room ? (room.displayName || room.name) : '';
    updateApptStatus({
      item: {
        name: menuItem.apptId,
        status: menuItem.a.waitingRoomStatus,
        clientFirstName,
        clientLastName,
        clientId
      },
      dropResult: { status, roomNumber, name }
    });
    handleMenuClose();
  };

  const [selectedTime, setSeletedTime] = React.useState(null);
  const [isSchedulerOpen, setIsSchedulerOpen] = React.useState(false);
  const [isStopsOpen, setIsStopsOpen] = React.useState(false);
  const [isApptOpen, setIsApptOpen] = React.useState(false);
  const [isStopOpen, setIsStopOpen] = React.useState(false);
  const [apptState, setApptState] = React.useState(null);
  const [isClientOpen, setIsClientOpen] = React.useState(false);
  // const [appointment, setAppointment] = useState({});
  const [stop, setStop] = useState({});
  const [showStopsOnly, setshowStopsOnly] = useState(false);
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
  const [isNewStopDialogOpen, setIsNewStopDialogOpen] = useState(false);
  const [stopAnchorEl, setStopAnchorEl] = useState(null);
  const [filterAnchorEl, setFilerAnchorEl] = useState(null);
  const [height, setHeight] = useState(700);

  const openStops = () => {
    setIsStopsOpen(true);
    setStopAnchorEl(null);
  };

  const openTemplateDialog = () => {
    setIsTemplateDialogOpen(true);
  };

  const openNewStopDialog = () => {
    setIsNewStopDialogOpen(true);
    setAnchorEl(null);
  };

  const closeStopsDrawer = () => {
    setIsStopsOpen(false);
  };

  const openScheduler = () => {
    const coeff = 1000 * 60 * interval;
    const date = new Date();
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    const isoTime = rounded.toISOString();
    const time = ZonedDateTime
      .parse(isoTime)
      .withZoneSameInstant(ZoneId.of(tz));
    setSeletedTime({ time });
    setIsSchedulerOpen(true);
  };

  const openApptDialog = () => {
    const client = menuItem.client;
    const appointment = menuItem.a;
    setApptState('APT');
    setIsApptOpen(true);
    selectedApptState.invoke({ appointment, client }).then(() => {
      setAnchorEl(null);
    });
  };

  const openSendMessage = () => {
    const client = menuItem.client;
    const appointment = menuItem.a;
    setApptState('MESSAGE');
    setIsApptOpen(true);
    selectedApptState.invoke({ appointment, client }).then(() => {
      setAnchorEl(null);
    });
  };

  const closeApptDialog = () => {
    setApptState(null);
    setIsApptOpen(false);
  };

  const openClientDialog = (client: Client) => {
    actions.getCurrentClient(client);
    setIsClientOpen(true);
  };

  const viewClient = () => {
    openClientDialog(menuItem.client);
    handleMenuClose();
  };

  const closeClientDialog = () => {
    setIsClientOpen(false);
  };

  const openStopDialog = (s = null as object) => {
    if (menuItem && menuItem.a.stop) {
      setStop(menuItem.a.stop);
    } else if (s) {
      setStop(s);
    } else {
      return;
    }
    setIsStopOpen(true);
    handleMenuClose();
  };

  const closeStopDialog = () => {
    setIsStopOpen(false);
    setStop({});
  };

  const initListenSchedule = (socket: EventTarget) => {
    if (!socket) {
      return () => null as string;
    }

    const handleStopUpdate = (data: { appointmentId?: number, id: number }) => {
      console.log('stop update', data);
      setState(evolve({
        appointments: pipe(
          map((apt: { id: number }) => {
            if (data.appointmentId === apt.id) {
              return assoc('stop', data, apt);
            } else {
              return apt;
            }
          })
        ),
        stops: pipe(
          prepend(data),
          uniqBy(prop('id'))
        ),
        updateFromFrontDesk: always(data),
      }));
    };

    const handleSocketUpdate = (event: unknown) => {
      console.log('handle socket update!');
      try {
        const parsed = JSON.parse((event as { data: string }).data);
        console.log(parsed, new Date().toString());
        const { contents } = parsed;
        if (parsed.tag === 'Appointment') {
          actions.handleApptEvent(parsed.contents);
        } else if (parsed.tag === 'Stops') {
          handleStopUpdate(parsed.contents);
        } else if (parsed.tag === 'WaitingRoom') {
          const waitingEvent = parsed.contents.tag;
          if (waitingEvent === 'Deleted' || waitingEvent === 'CheckedIn') {
            actions.handleWaitingRoomUpdate(IN_LOBBY, contents.contents);
          } else if (waitingEvent === 'ToRoom') {
            actions.handleWaitingRoomUpdate(IN_ROOM, contents.contents);
          } else if (waitingEvent === 'Arrived') {
            if (settings.roomsSyncEnabled) {
              actions.handleWaitingRoomUpdate(SEEN, contents.contents);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    socket.addEventListener('message', handleSocketUpdate);
    return () => {
      socket.removeEventListener('message', handleSocketUpdate);
    };

  };

  useTitle('Front Desk');

  useEffect(() => {
    try {
      const { professionalIds, roomIds, locationIds = [], showStopsOnly = false } = JSON.parse(localStorage[`sked-front-desk-${office.id}`]);
      setshowStopsOnly(showStopsOnly);
      const l = uniq(locationIds);
      actions.initFrontDesk({ professionalIds, roomIds, locationIds: l });
    } catch (e) {
      actions.initFrontDesk({});
      console.log('error gettings front desk settings');
    }
    actions.getSettings();

    (actions.getProfessionals() as unknown as Promise<string>).then(() => {
      actions.getInitialState();
    });

    if (hasLocations) {
      actions.getLocations();
    }

    return () => {
      console.log('leaving page!');
      actions.frontDeskLeavePage();
    };
  }, [office.id]);

  const updateApptStatus = ({ item, dropResult }: { item: ItemType, dropResult: ItemType}) => {
    if (item.status !== IN_ROOM && item.status === dropResult.status) {
      return;
    }
    if (item.status === IN_ROOM && item.status === dropResult.status && item.roomNumber === dropResult.roomNumber) {
      return;
    }

    const payload = {
      id: item.name,
      status: dropResult.status,
      roomNumber: dropResult.roomNumber
    };

    //Optimistic Update
    actions.updateApptOptimistic(payload);

    //Update via the api
    return updateStatus({ item, dropResult });
  };

  //Need to do this every render to get updated
  //State value
  const socket = useSocket();
  useEffect(() => {
    const cleanupSocket = initListenSchedule(socket);
    return () => {
      cleanupSocket();
    };
  }, [socket, state]);

  const [time, setTime] = useState(new Date().toISOString());

  const getMissedTime = () => {
    return formatStandard(ZonedDateTime.now().minusMinutes(Number(settings.minutesUntilMissed || 5)));
  };

  useEffect(() => {
    const mm = getMissedTime();
    setTime(mm);
    const id = setInterval(() => {
      const m = getMissedTime();
      setTime(m);
    }, 60e3);
    return () => {
      clearInterval(id);
    };
  }, [settings.minutesUntilMissed]);

  const deps = [state.appointments, state.professionalIds, state.search, time, state.locationIds, showStopsOnly, office.id];

  const locationProfessionalIds = locationProfessionals(professionalsToShow, state.locationIds);

  // Missed, canceled or rescheduled
  const missed = useMemo(() => {
    return pipe(
      filter(allPass([
        predicate({
          statuses: [SCHEDULED],
          professionalIds: state.professionalIds,
          search: state.search,
          locationProfessionalIds,
          locationIds: state.locationIds,
          onlyStops: showStopsOnly,
        }),
        anyPass([
          // Missed
          both(a => a.time < time, a => has('Scheduled', a.status)),
          // Or canceled
          a => has('Canceled', a.status),
          a => has('Missed', a.status),
        ])
      ])),
      sort(ascend(prop('time')))
    )(state.appointments as AppointmentType[]);
  }, deps);

  const scheduled = useMemo(() => {
    const today = now('tz', tz);
    const startOfToday = startOf(today, 'day');
    const enfOfToday = endOf(today, 'day');
    const earlyMorning = ['Early Morning', startOfToday, startOfToday.plusHours(8)];
    const morning = ['Morning', startOfToday.plusHours(8), startOfToday.plusHours(12)];
    const afternoon = ['Afternoon', startOfToday.plusHours(12), startOfToday.plusHours(17)];
    const evening = ['Evening', startOfToday.plusHours(17), enfOfToday];
    const tomorrow = ['Tomorrow', startOfToday.plusDays(1), enfOfToday.plusDays(1)];
    const sections = [earlyMorning, morning, afternoon, evening, tomorrow];
    return pipe(
      filter(allPass([
        predicate({
          statuses: [SCHEDULED],
          professionalIds: state.professionalIds,
          search: state.search,
          locationProfessionalIds,
          locationIds: state.locationIds,
          onlyStops: showStopsOnly,
        }),
        (a: { time: string }) => a.time >= time,
        a => has('Scheduled', a.status)
      ])),
      sort(ascend(prop('time'))),
      groupBy((a) => {
        const datetime = tzParse(a.time, tz);
        const section = find((sect: string[]) => {
          return (datetime.isAfter(sect[1] as unknown as ChronoZonedDateTime) || datetime.isEqual(sect[1] as unknown as ChronoZonedDateTime)) && datetime.isBefore(sect[2] as unknown as ChronoZonedDateTime);
        })(sections);
        return section[0];
      })
    )(state.appointments);
  }, deps);

  const inLobby = useMemo(() => {
    return pipe(
      filter(predicate({
        statuses: [IN_LOBBY],
        professionalIds: state.professionalIds,
        search: state.search,
        locationProfessionalIds,
        locationIds: state.locationIds,
        onlyStops: showStopsOnly,
      })),
      sort(ascend(prop('arrivedAt')))
    )(state.appointments);
  }, deps);

  const inRoom = useMemo(() => {
    return pipe(
      filter(predicate({
        statuses: [IN_ROOM],
        professionalIds: state.professionalIds,
        search: state.search,
        locationProfessionalIds,
        locationIds: state.locationIds,
        onlyStops: showStopsOnly,
      })),
      sort(descend(prop('arrivedAt')))
    )(state.appointments);
  }, deps);

  const seen = useMemo(() => {
    return pipe(
      filter(predicate({
        statuses: [SEEN],
        professionalIds: state.professionalIds,
        search: state.search,
        locationProfessionalIds,
        locationIds: state.locationIds,
        onlyStops: showStopsOnly,
      })),
      sort(descend(prop('arrivedAt')))
    )(state.appointments);
  }, deps);

  const showPro = (pro: Professional) => {
    const showName = pro.firstName || pro.lastName;
    const name = ` (${pro.firstName || ''} ${pro.lastName || ''})`;
    const displayName = `${pro.displayFirstName || ''} ${pro.displayLastName || ''}`;
    return displayName + (showName ? name : '');
  };

  const showSelectedProfessionals = (s: number[]) => {
    if (s.length === 0) {
      return 'All Professionals';
    }

    return s.map((pId: number, index: number) => {
      const pro = proState.professionals.find(propEq('id', pId)) || {};
      if (index === 0) {
        return 'Professionals: ' + showPro(pro);
      }
      return showPro(pro);
    }).join(', ');
  };

  const showSelectedRooms = (s: number[]) => {
    if (s.length === 0) {
      return 'All Rooms';
    }

    return s.map((id: number, index: number) => {
      const room = settings.rooms.find(propEq('id', id)) || {};
      if (index === 0) {
        return 'Rooms: ' + room.name;
      }
      return room.name;
    }).join(', ');
  };

  const showSelectedLocations = (s: number[]) => {
    console.log(s);
    if (hasLocations) {
      if (s.length === 0) {
        return 'All Locations';
      }

      return s.map((pId: number, index: number) => {
        const loc = locations.find(propEq('id', pId)) || {};
        if (index === 0) {
          return 'Locations: ' + loc.name;
        }
        return loc.name;
      }).join(', ');
    }
    return '';
  };

  const selectedRooms = useMemo(() => {
    if (state.roomIds.length === 0) {
      return settings.rooms;
    }
    return state.roomIds.map((id: string) => settings.rooms.find(propEq('id', id))).filter((r: number) => r);
  }, [settings.rooms, state.roomIds]);

  if (!isEnabled) {
    return (
      <FeatureDisabled title="The Front Desk">
        <p>
          Move your patients though the flow of your office easily!
        </p>
      </FeatureDisabled>
    );
  }

  const [defaultClients, alertAppt] = useMemo(() => {
    const c = propOr(null, 'client', menuItem);
    const aptId = pathOr(null, ['a', 'id'], menuItem);
    return [c ? [c] : [], aptId];
  }, [menuItem]);

  const handleFilterUpdate = (e: SelectChangeEvent<string[]>) => {
    const v = e.target.value as string[];
    let data: Filters = {
      roomIds: [],
      professionalIds: [],
      locationIds: [],
    };
    let showStopsOnly = false;

    v.forEach((i) => {
      const [prop, id] = i.split('-');
      if (prop === 'stops') {
        setshowStopsOnly(id === 'true');
        showStopsOnly = id === 'true';
      } else {
        data = evolve({
          [prop]: append(id === 'null' ? null : Number(id)),
        }, data);
      }
    });
    localStorage[`sked-front-desk-${office.id}`] = JSON.stringify(data);
    update({ ...data, showStopsOnly });
  };

  const getActive = () => {
    return state.roomIds?.length > 0 ||
      state.professionalIds?.length > 0 ||
      state.locationIds?.length > 0 || showStopsOnly;
  };

  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    setHeight(windowHeight - 94 - headerHeight);
    setSmallPage(window.innerWidth < 600);
  };

  useEffect(() => {
    updateHeight();
  }, [headerHeight]);

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [headerHeight]);

  return (
    <Grid>
      <Header
        title='Front Desk'
        pageId='frontdesk'
        leftIcons={[
          <HeaderButton
            title='Filters'
            onClick={(e) => setFilerAnchorEl(e.currentTarget)}
            Icon={FilterListIcon}
            active={getActive()}
            type='filter'
            className='sked-test-front-desk-filters'
          />,
        ]}
        rightIcons={[
          <Grid marginRight="5px">
            <IconButton onClick={actions.getInitialState} className='sked-test-front-desk-refresh'>
              <RefreshIcon className={`${state.loading ? 'sked-spin' : ''} ${classes.icon}`} />
            </IconButton>
          </Grid>,
          hasStopManager ?
            <HeaderButton
              Icon={WarningIcon}
              className='sked-test-front-desk-alerts'
              onClick={(e) => setStopAnchorEl(e.currentTarget)}
              borderSolid
              title='Alerts'
              warningIcon
              borderRadius={4}
            >
            </HeaderButton>
            : <div />,
          <HeaderButton title='Add Appt' onClick={openScheduler} className={'sked-test-front-desk-scheduler'} Icon={AddIcon} />,
          <FilterText
            value={state.searchText}
            placeholder='LastName FirstName'
            headerTitle='Search'
            className='sked-test-front-desk-search'
            saveClassName='sked-test-front-desk-search-save-button'
            resetClassName='sked-test-front-desk-search-reset-button'
            inputClassName='sked-test-front-desk-search-input-box'
            onChange={str => {
              const splitUp = str.split(' ');
              const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
              const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
              const phone = splitUp[2] ? splitUp[2].trim() : undefined;
              update({
                searchText: str,
                search: {
                  lastName,
                  firstName,
                  phone,
                }
              });
            }}
          />,
          <HeaderButton onClick={handleClickOpen} className={'sked-test-front-desk-settings'} Icon={GearIcon} onlyIcon />,
        ]}
        onlyIconsWidth={614}
        breakPoints={[
          {
            width: 422,
            mobileItems: [4, 5],
          },
        ]}
      />
      <div
        className="page-root"
        style={{
          maxWidth: `calc(100vw - ${desktopOpen && !smallPage ? 240 : 0}px)`,
          overflowY: 'unset',
        }}
      >
        <PanelGroup autoSaveId="handleResizeFrontDesk" direction="horizontal">
          <Panel minSize={70} style={{ overflow: 'visible', overflowX: 'auto' }}>
            <div>
              {state.settingsOpen &&
                <SettingsPopup
                  settings={settings}
                  open={state.settingsOpen}
                  handleClose={handleClose}
                  hasTimeBased={hasTimeBased}
                  hasFrontdesk={hasFrontdesk}
                  ehrSystem={office.ehrSystem}
                  title='Front Desk Settings'
                />}
              <PopupTemplate />
              <Popover
                open={Boolean(filterAnchorEl)}
                anchorEl={filterAnchorEl}
                onClose={() => setFilerAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Grid padding={2} width={366}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel shrink={true} id="room-label">
                      Filter
                    </InputLabel>
                    <MuiSelect
                      labelId="room-label"
                      multiple
                      input={<OutlinedInput size='small' label="Filter" />}
                      displayEmpty={true}
                      inputProps={{ className: 'sked-test-front-desk-filters-selection' }}
                      MenuProps={{
                        style: {
                          maxHeight: 'calc(100% - 100px)',
                          width: 350
                        }
                      }}
                      renderValue={(s) => {
                        let data: Filters = {
                          roomIds: [],
                          professionalIds: [],
                          locationIds: [],
                        };
                        s.forEach((i) => {
                          const [prop, id] = i.split('-');
                          data = evolve({
                            [prop]: append(id === 'null' ? null : Number(id)),
                          }, data);
                        });
                        return (
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                            {pipe(
                              flatten,
                              without([false]),
                              ifElse(
                                all(includes('All')),
                                always(['All']),
                                identity
                              ),
                              join('\n')
                            )([
                              showSelectedRooms(data.roomIds),
                              showSelectedProfessionals(data.professionalIds),
                              showSelectedLocations(data.locationIds),
                              showStopsOnly && 'Alerts Only',
                            ])}
                          </div>
                        );
                      }}
                      value={flatten([
                        state.roomIds.map((id: number) => `roomIds-${id}`),
                        state.professionalIds.map((id: number) => `professionalIds-${id}`),
                        state.locationIds.map((id: number) => `locationIds-${id}`),
                        showStopsOnly ? 'stops-true' : 'stops-false',
                      ])}
                      onChange={handleFilterUpdate}>
                      <LargerListSectionHeader disableSticky>Rooms</LargerListSectionHeader>
                      {settings.rooms.map(({ id, name }: { id: number, name: string }) => (
                        <MenuItem key={id} value={`roomIds-${id}`}>
                          <Checkbox checked={state.roomIds.indexOf(id) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                      <LargerListSectionHeader disableSticky>Professionals</LargerListSectionHeader>
                      {professionalsToShow.map((professional: Professional) => (
                        <MenuItem key={professional.id} value={`professionalIds-${professional.id}`}>
                          <Checkbox checked={state.professionalIds.indexOf(professional.id) > -1} />
                          <ListItemText primary={showPro(professional)} />
                        </MenuItem>
                      ))}
                      {!isEmpty(locations) && hasLocations &&
                        <LargerListSectionHeader disableSticky>Locations</LargerListSectionHeader>}
                      {hasLocations &&
                        locations.map(({ id, name }: { id: number, name: string }) => (
                          <MenuItem key={id} value={`locationIds-${id}`}>
                            <Checkbox checked={state.locationIds.indexOf(id) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      {hasStopManager &&
                        <LargerListSectionHeader>Others</LargerListSectionHeader>}
                      {hasStopManager &&
                        <MenuItem key={'stops'} value={`stops-${!showStopsOnly}`}>
                          <Checkbox checked={showStopsOnly} />
                          <ListItemText primary={'Appointment Alerts Only'} />
                        </MenuItem>}
                    </MuiSelect>
                  </FormControl>
                </Grid>
              </Popover>
              <div>
                <Menu
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  id="long-menu"
                  anchorEl={stopAnchorEl}
                  open={Boolean(stopAnchorEl)}
                  onClose={() => setStopAnchorEl(null)}
                >
                  <MenuItem onClick={openStops} className='sked-test-front-desk-add-alerts-view-today'>
                    <ListItemIcon>
                      <TodayIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary="View Today's Alerts" />
                  </MenuItem>
                  <MenuItem
                    className='sked-test-front-desk-alerts-new'
                    onClick={() => {
                      setStopAnchorEl(null);
                      openNewStopDialog();
                    }}>
                    <ListItemIcon>
                      <AddIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary='Add New Alert' />
                  </MenuItem>
                  <MenuItem
                    className='sked-test-front-desk-alert-templates'
                    onClick={() => {
                      setStopAnchorEl(null);
                      openTemplateDialog();
                    }}>
                    <ListItemIcon>
                      <FileIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary='Add/Edit Templates' />
                  </MenuItem>
                </Menu>

                <div className={classes.dashboardListContainer}>
                  <WaitingRoomList
                    tz={tz}
                    status={SCHEDULED}
                    title="Pending Arrival"
                    className='scheduled'
                    loading={state.loading}
                    error={!!state.errorMessage}
                    data={scheduled}
                    failedMessage="Failed to load"
                    updateApptStatus={updateApptStatus}
                    openMenu={handleMenuOpen}
                    height={height}
                    emptyMessage="No appointments are scheduled" />
                  <WaitingRoomList
                    tz={tz}
                    title="In Lobby"
                    status={IN_LOBBY}
                    className='in-lobby'
                    loading={state.loading}
                    error={state.errorMessage}
                    data={inLobby}
                    updateApptStatus={updateApptStatus}
                    openMenu={handleMenuOpen}
                    failedMessage="Failed to load"
                    height={height}
                    emptyMessage="The lobby is empty" />
                  <RoomsList
                    tz={tz}
                    title="In Room"
                    status={IN_ROOM}
                    className='in-room'
                    rooms={selectedRooms}
                    loading={state.loading}
                    error={state.errorMessage}
                    data={inRoom as unknown as AData[]}
                    updateApptStatus={updateApptStatus}
                    openMenu={handleMenuOpen}
                    height={height}
                    failedMessage="Failed to load"
                    // emptyMessage="No one in room."
                  />
                  <WaitingRoomList
                    tz={tz}
                    title="Seen"
                    status={SEEN}
                    className='seen'
                    loading={state.loading}
                    error={state.errorMessage}
                    data={seen}
                    updateApptStatus={updateApptStatus}
                    openMenu={handleMenuOpen}
                    height={height}
                    failedMessage="Failed to load"
                    emptyMessage="No one has been seen." />
                  <WaitingRoomList
                    tz={tz}
                    status={MISSED}
                    title="Canceled/Missed"
                    className='missed'
                    loading={state.loading}
                    error={!!state.errorMessage}
                    data={missed}
                    updateApptStatus={updateApptStatus}
                    openMenu={handleMenuOpen}
                    height={height}
                    failedMessage="Failed to load"
                    emptyMessage="No missed appointments!" />
                </div>
              </div>
              <Menu
                id="appt-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={openApptDialog}>View Appointment</MenuItem>
                <MenuItem onClick={viewClient}>View Client</MenuItem>
                {pathOr(false, ['a', 'stop'], menuItem) &&
                  <MenuItem onClick={openStopDialog}>View Alert</MenuItem>}
                <MenuItem onClick={openSendMessage}>Send Message</MenuItem>
                {hasStopManager &&
                  <MenuItem onClick={openNewStopDialog}>Create Alert</MenuItem>}
                <Divider />
                <MenuItem onClick={handleMenuMove(SCHEDULED)}>Move To Pending Arrival</MenuItem>
                <MenuItem onClick={handleMenuMove(IN_LOBBY)}>Move To Lobby</MenuItem>
                {selectedRooms.map(({ id, name }: { id: number, name: string }) => <MenuItem key={id} onClick={handleMenuMove(IN_ROOM, id)}>Move To {name}</MenuItem>)}
                <MenuItem onClick={handleMenuMove(SEEN)}>Move To Seen</MenuItem>
              </Menu>

              <Appointments
                open={isApptOpen}
                onClose={closeApptDialog}
                appointment={isApptOpen ? selectedApptState.data : null}
                busy={selectedApptState.loading}
                state={apptState}
                from={'VWR'}
                tz={tz}
                gotoClient={actions.getCurrentClient}
                back={() => actions.backToClient()} />
              <ClientEditDialog
                open={isClientOpen}
                from={'VWR'}
                onClose={closeClientDialog}
                back={actions.backToClient}
                gotoClient={actions.backToClient} />
              <SchedulerDialog
                isOpen={isSchedulerOpen}
                close={() => setIsSchedulerOpen(false)}
                selectedTime={selectedTime} />
              <StopDialog
                isOpen={isStopOpen}
                close={closeStopDialog}
                stop={stop}
                busy={updateStop.loading}
                // tz={tz}
                patch={(status: string) => () => {
                  updateStop.invoke({ stop, status }).then((s) => {
                    closeStopDialog();
                    setState(evolve({
                      appointments: pipe(
                        map((apt: { id: number }) => {
                          if (s.appointmentId === apt.id) {
                            return assoc('stop', s, apt);
                          } else {
                            return apt;
                          }
                        })
                      ),
                      updateFromFrontDesk: always(s),
                    }));
                  });
                }}
              />
              <StopManagerTemplateDialog
                open={isTemplateDialogOpen}
                onClose={() => setIsTemplateDialogOpen(false)}
              />
              <CreateStopDialog
                open={isNewStopDialogOpen}
                onClose={() => {
                  setIsNewStopDialogOpen(false);
                  setMenuItem(null);
                }}
                // ehrSystem={office.ehrSystem}
                tz={office.timezone}
                defaultClients={defaultClients}
                aptId={alertAppt}
              />
            </div>
          </Panel>
          {isStopsOpen && (
            <Panel minSize={15} defaultSize={20} style={{ position: 'relative', minWidth: 220 }}>
              <PanelResizeHandle className={classes.resizeHandle} />
              <StopsDrawer
                open={isStopsOpen}
                onClose={closeStopsDrawer}
                tz={tz}
                height={height}
                appointments={state.appointments}
                updateFromFrontDesk={state.updateFromFrontDesk}
                updateFrontDesk={(s: { from: { StopManager: {appointmentId : number }}}) => {
                  setState(evolve({
                    appointments: pipe(
                      map((apt: { id: number }) => {
                        if (s.from.StopManager.appointmentId === apt.id) {
                          return assoc('stop', s, apt);
                        } else {
                          return apt;
                        }
                      })
                    ),
                  }));
                }}
              />
            </Panel>
          )}
        </PanelGroup>
      </div>
    </Grid>
  );
};

export default FrontDesk;
