import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import api from '../../../../../services/api.js';
import {
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { usePromise } from '../../../../../services/promise.hook';
import { successSnackbar, errorSnackbar } from '../../../../../components/Snackbar/snackbar.actions';

export default function AppSettings({ client, isCheckIn }) {
  const dispatch = useDispatch();
  const [actions, setActions] = useState([]);

  const jailState = usePromise(clientId => api.get(`client/jail/${clientId}`), []);
  const jailSaveState = usePromise(({ clientId, actions }) => api.post('client/jail', { clientId, actions }), {});

  const onSaveHandler = () => {
    jailSaveState
      .invoke({ clientId: client.id, actions })
      .then(() => {
        dispatch(successSnackbar('Saved SKED Jail Settings!'));
      })
      .catch(() => {
        dispatch(errorSnackbar('Failed to save SKED Jail Settings'));
      });
  };

  useEffect(() => {
    jailState.invoke(client.id).then(jails => setActions(jails));
  }, []);

  return (
    <div>
      <div>
        {jailState.loading && <CircularProgress />}
        {!jailState.loading &&
          <div style={{ margin: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={R.includes('Schedule', actions)}
                  onChange={(e) => {
                    setActions(
                      e.target.checked ?
                        R.append('Schedule', actions)
                        :
                        R.without(['Schedule'], actions)
                    );
                  }}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="Scheduling Disabled"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={R.includes('Reschedule', actions)}
                  onChange={(e) => {
                    setActions(
                      e.target.checked ?
                        R.append('Reschedule', actions)
                        :
                        R.without(['Reschedule'], actions)
                    );
                  }}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="Rescheduling Disabled"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={R.includes('Cancel', actions)}
                  onChange={(e) => {
                    setActions(
                      e.target.checked ?
                        R.append('Cancel', actions)
                        :
                        R.without(['Cancel'], actions)
                    );
                  }}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="Canceling Disabled"
            />
            {isCheckIn &&
              <>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={R.includes('CheckIn', actions)}
                      onChange={(e) => {
                        setActions(
                          e.target.checked ?
                            R.append('CheckIn', actions)
                            :
                            R.without(['CheckIn'], actions)
                        );
                      }}
                      name="acknowledge-checkbox"
                      color="primary"
                    />
                  }
                  label="Virtual Check-in Disabled"
                />
              </>}
          </div>}
      </div>
      <div>
        <Button disabled={jailState.loading || jailSaveState.loading} onClick={onSaveHandler} variant='contained'>
          Save
        </Button>
      </div>
    </div>
  );
}
