import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import
CreateAutomationMessage
  from '../../components/AutomationBasedMessage/create-automation-message.component';
import { includes } from 'ramda';
import queryString from 'query-string';




const SmartReplyMessageEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const msgId = params?.id ? Number(params.id) : 0;
  const type = includes('template', location.pathname) ? 'template' : 'message';
  const query = queryString.parse(location.search);
  const copying = Number(query?.copying || 0);
  const fromTemplate = Number(query?.fromSkedTemplate || 0);
  return (
    <CreateAutomationMessage
      messageId={msgId}
      copying={copying}
      fromTemplate={fromTemplate}
      filter={'Clients'}
      messageType={type}
      automationMessageType={'SmartReply'}
      includeHeader
      goBack={() => navigate(-1)}
    />
  );
};

export default SmartReplyMessageEdit;
