import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import { etd } from '../../../../services/utilities.js';
// import { cond, equals, always, T } from 'ramda';
import { popup } from '../../../../services/Popup.js';

export const patch = (prop, value) => dispatch =>
  dispatch({
    type: at.GENERAL_PATCH,
    data: {
      [prop]: value,
    },
  });

export const getSettings = (navigate) => remoteAction({
  type: at.GENERAL_REMOTE_GET_SETTINGS,
  action: () => Promise.all([
    api.get('office/notifications').catch(() => ({ timeSetting: 'Never' })),
    api.get('office/settings'),
    api.get('office/undeliveredSettings'),
    api.get('office/features'),
    api.get('office/verified-email-status'),
  ].map(etd)).catch((error) => {
    if (error.response) {
      console.error(error);
    } else {
      navigate?.('/offline');
    }
  }),
});

export const getVerifiedEmail = () => remoteAction({
  type: at.GENERAL_REMOTE_GET_VERIFIED_EMAIL_STATUS,
  action: () => api.get('office/verified-email-status').catch(() => {
    return Promise.resolve(); // we don't care if there is an error for this
  }),
});


export const save = (
  timeSetting,
  sendTextNotifications,
  allowGrouping,
  time,
) => (dispatch) => {
  dispatch(
    remoteAction({
      type: at.GENERAL_SAVE,
      action: () =>
        Promise.all([
          api.put('office/notifications', { timeSetting, dummy: [] }),
          api.put('office/settings', { sendTextNotifications, allowGrouping }),
          api.post('office/undeliveredSetting', { time, dummy: [] }),
        ]).then(() => {
          dispatch(getSettings());
        }),
      successText: 'Information saved!',
    }));
};

export const confirmOfficeEmail = () => (dispatch) => dispatch(
  remoteAction({
    type: at.GENERAL_CONFIRM_EMAIL,
    action: () => api.put('office/confirm-office-email').then(() => {
      popup('Success!', 'Your email has been verified!');
    }),
  })
);
