import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  CircularProgress, Box, Tabs, Tab, Button, MenuItem, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import api, { skedApi } from '../../../services/api.js';
import { usePromise } from '../../../services/promise.hook';
import TypeSelector from '../../../components/TypeSelector/type-selector.component';
import { AppointmentType, Professional } from '../../Appointments/appointments.types';
import {
  without, has, prop, pipe, filter, values, all, equals, not, either, isEmpty,
  mapObjIndexed, mergeAll, identity,
} from 'ramda';
import { Folder } from '../../../components/SidebarFolders/FolderItem.component';
import {
  Email, Push, Sms
} from '../../Templates/routes/TemplatesEdit/templates-edit.reducer';
import {
  MessageComposer, createSMSData, clientPlaceholders, officePlaceholders,
  removePlaceholders
} from '../../../components/CreateMessage/CreateMessage.component';
import {
  fetchSettingsList
} from '../../Settings/routes/NewPatientPortal/NewPatientPortal.actions.js';
import {
  getTemplates,
} from './MessageTemplateDialog.component';
import {
  Select,
} from '../../../components/components.component';

type LoginState = {
  login: {
    features?: string[];
    office: {
      id: number;
    },
  }
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    height: 800,
  },
}));

const strPath = 'spark.0.apptTypes';
const listPath = strPath.split('.');

const getAllProTypes = () => {
  return api.get('appointmentType').then((types) => {
    return api.get('professional').then((pros) => {
      return { types, pros };
    });
  });
};

const getFromPath = ({ types }: { types: AppointmentType[] }) => {
  return api.post('paths/query', {
    page: 1,
    perPage: 1000,
    query: {
      isDirectDescendant: listPath
    }
  }).then(({ data }) => {
    return data.map(({ resource }: Folder) => {
      if (!has('PathLabel', resource))
        return types.find(({ id }) => id === resource.AppointmentType);
    });
  });
};

const checkForMasterList = (): Promise<boolean> => {
  return api.get('paths/path/spark.0.master_list')
    .then((path) => {
      return Boolean(path);
    }).catch(() => {
      return false;
    });
};

const toggleSparkIt = (data: { enable: boolean }): Promise<void> => {
  return api.post('spark/toggle', data);
};

const createSpark = (officeId: number) => (rawData: ComposeState) => {
  // make the Next Day Seasonal the proper msg 3
  const correctData = {
    ...rawData,
    push3: rawData['pushNext Day Seasonal'],
    sms3: rawData['smsNext Day Seasonal'],
    email3: rawData['emailNext Day Seasonal']
  };
  const data = mapObjIndexed((value, key) => {
    if (key.indexOf('email') > -1 && has('html', value)) {
      return !value.subject && !value.body && !value.html ?
        undefined
        :
        {
          attachmentIds: value.attachments.map(({ attachmentId }) => attachmentId),
          subject: value.subject,
          body: {
            HTML: value.html ? {
              body: value.html,
              shouldFrame: value.shouldFrame,
            } : undefined,
            PlainText: value.body ? value.body : undefined,
          },
        };
    } else if (key.indexOf('sms') > -1 && has('attachments', value) && !has('html', value)) {
      return createSMSData(value);
    } else if (!has('attachments', value) && !has('html', value)) {
      return !value.subject && !value.body ? undefined : value;
    }
  }, correctData);

  return skedApi({
    method: 'POST',
    url: `spark/create/${officeId}`,
    data,
    headers: {
      'X-As-Office': null,
      'Authorization': skedApi.defaults.headers.common.Authorization,
    }
  }).then(() => {
    return;
  });
};

const killSpark = () => {
  return api.delete('paths/path/spark.0/recursive/resource', { data: {
    types: [
      'Message', 'Automation', 'ClientList', 'AppointmentList',
    ]
  } }).then(() => {
    return api.delete('paths/path/spark.0/recursive', { data: [] });
  });
};

const updateSpark = (id: number) => {
  return api.post(`spark/update/${id}`);
};

interface SaveToPathProps {
  news: AppointmentType[];
  olds: AppointmentType[];
}

const saveToPath = ({ news, olds }: SaveToPathProps): Promise<void[]> => {
  const newReqs = news.map(({ id }) => {
    return api.post('paths', {
      path: listPath.concat([String(id)]),
      resource: { AppointmentType: id },
    });
  });
  const oldReqs = olds.map(({ id }) => {
    return api.delete(`paths/path/${strPath}.${id}`, { data: {} });
  });
  return Promise.all(newReqs.concat(oldReqs));
};

interface ComposeState {
  push1: Push;
  email1: Email;
  sms1: Sms;
  push1_2: Push;
  email1_2: Email;
  sms1_2: Sms;
  push2: Push;
  email2: Email;
  sms2: Sms;
  push2_2: Push;
  email2_2: Email;
  sms2_2: Sms;
  ['pushNext Day Seasonal']: Push;
  ['emailNext Day Seasonal']: Email;
  ['smsNext Day Seasonal']: Sms;
}

const defaultComposeState: ComposeState = {
  push1: { subject: '{{client.firstName}}, we\'ve missed you...', body: 'Hey {{client.firstName}}, it\'s been 60 days since your last visit! To get the best results, it\'s important to stay consistent. - {{office.name}} - click to schedule your next visit today- {{client.registerLink}}' },
  email1: {
    subject: '{{client.firstName}}, we\'ve missed you...',
    body: '', html: `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <meta name="format-detection" content="telephone=no,address=no,email=no,date=no,url=no">
    <meta name="color-scheme" content="light dark">
    <meta name="supported-color-schemes" content="light dark">
    <title>60 Day Appointment Invite</title>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->    
    <!--[if mso]>
        <style>
            * {
                font-family: sans-serif !important;
            }
        </style>
    <![endif]-->  
    <!--[if !mso]><!-->
    <!--<![endif]-->
    <style>       
        :root {
          color-scheme: light dark;
          supported-color-schemes: light dark;
        }        
        html,
        body {
            margin-top: 0 !important;
            margin-bottom: 0 1important;
            margin-left: auto !important;
            margin-right: auto !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            height: 100% !important;
            width: 100% !important;
        }        
        * {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }        
        p {
            margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;
        }        
        h1 p, h2 p, .content-block.footer p {
            font-style: inherit;
            line-height: inherit;
            color: inherit;
            font-weight: inherit;
            margin-top: 0;
            margin-left: auto;
            margin-bottom: 0;
            margin-right: auto;
        }        
        div[style*="margin: 16px 0"] {
            margin: 0 !important;
        }        
        #MessageViewBody, #MessageWebViewDiv{
            width: 100% !important;
        }        
        table,
        td {
            mso-table-lspace: 0pt !important;
            mso-table-rspace: 0pt !important;
        }       
        table {
            border-spacing: 0 !important;
            border-collapse: collapse !important;
            margin: 0 auto !important;
            opacity: 1 !important;
        }   
        table#cta {
            border-spacing: 20px !important;
        table.layout {
            max-width: 680px;
        }
        img {
            -ms-interpolation-mode:bicubic;
        }        
        a {
			text-decoration: none;
		}
		b {
		    color: #ec6631;
		}
        .content-block a {
            color: #3b9fe3;
            text-decoration: underline;
        }        
        *[x-apple-data-detectors],
        .unstyle-auto-detected-links *,
        .aBn {
            border-bottom: 0 !important;
            cursor: default !important;
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        .unstyle-auto-detected-links * {
            text-decoration: none !important;
            border: none !important;
            color: #ffffff !important;
            font-family: sans-serif !important;
        }
        .im {
            color: inherit !important;
        }       
        .a6S {
            display: none !important;
            opacity: 0.01 !important;
        }        
        img.g-img + div {
            display: none !important;
        }        
        @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
            u ~ div .email-container {
                min-width: 320px !important;
            }
        }        
        @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
            u ~ div .email-container {
                min-width: 375px !important;
            }
        }        
        @media only screen and (min-device-width: 414px) {
            u ~ div .email-container {
                min-width: 414px !important;
            }
        }
    </style>    
    <style>
         hr {
             height: 1px;
             width: 100%;
             background-color: #e5e8ea;
             border: none;
         }
        .button-td,
	    .button-a {
	        transition: all 100ms ease-in;
	        text-align: center;
	        min-width: 100%;
	        filter: contrast(1.1);
	    }
	    .button-a a {
	        font-size: 18px;
            line-height: 18px;
            font-weight: 600;
	    }
        .button-td.button-td-primary .button-a a {
            color: #ffffff !important;
        }
        .button-td.button-td-secondary .button-a a {
            color: #121212 !important;
        }
	    .button-td:hover, .button-td:hover .button-a {
	        opacity: 0.7 !important;
	    }
	    .button-td:hover .button-a a {
	        opacity: 0.7 !important;
	    }
	    @media screen and (max-width: 640px) {	        
	        .stack-column,
	        .stack-column-center {
	            display: block !important;
	            position: relative !important;
	            margin-bottom: 20px !important;
	        }	        
	        .stack-column-center {
	            text-align: center !important;
	        }	        
	        .center-on-narrow {
	            text-align: center !important;
	            display: block !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	            float: none !important;
	        }
	        table.center-on-narrow {
	            display: inline-block !important;
	        }
            .email-container h1 p {
	            font-size: 26px !important;
	        }
            .email-container h2 p {
	            font-size: 21px !important;
	        }	        
	        .email-container p {
	            font-size: 17px !important;
	        }
	        div.post-script {
	            width: 100% !important;
	            max-width: 85% !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	        }
	        .mod-bottom {
	            padding-bottom: 10px !important;
	        }
	        .mod-top {
	            padding-top: 10px !important;
	        }
	        .named, .named p {
	            font-size: 10px !important;
	        }
	    }
        u + .body .gmail-screen { background:#000; mix-blend-mode:screen; }
        u + .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        /*u + .body .gmail-difference1 { background:#fff !important; mix-blend-mode:difference; }
        u + .body .gmail-lighten {background:#fff !important; mix-blend-mode:exclusion; border: none !important; height: 0 !important;}*/
        /*.body .gmail-screen { background:#000; mix-blend-mode:screen; }
        .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        .body .gmail-difference1 { background:#fff !important;  mix-blend-mode:difference; }
        .body .gmail-lighten {background:#fff !important; mix-blend-mode: exclusion; border: none !important; height: 0 !important;}*/
    </style>
</head>
<body class="body" width="100%" style="margin-top: 0; margin-bottom: 0; margin-right: 0; margin-left: 0; padding-top: 0 !important; padding-bottom: 0 !important; padding-left: 0 !important; padding-right: 0 !important; mso-line-height-rule: exactly; background-color: #f6f9fb; color: #1D1D27;">
  <center role="article" aria-roledescription="email" lang="en" style="width: 100%; background-color: #f6f9fb; color: #1D1D27;">
    <!--[if mso | IE]>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" style="background-color: #f6f9fb; color: #1D1D27;">
    <tr>
    <td>
    <![endif]-->        
        <div style="max-height:0; overflow:hidden; mso-hide:all;" aria-hidden="true">Please don't wait until you're in too much pain to listen to what your body is trying to tell you. </div>        
        <div style="display: none; font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
            &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
        </div>
        <div style="max-width: 680px; margin: 0 auto;" class="email-container">
            <!--[if mso]>
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="510">
            <tr>
            <td width="100%">
            <![endif]-->	        
	        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
	            <tr>
					<td width="100%" bgcolor="#f6f9fb" aria-hidden="true" height="35" style="font-size: 0px; line-height: 0px; background-color: #f6f9fb; color: #1D1D27;">&nbsp;</td>
				</tr>
				<tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="15" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27; border-radius: 20px 20px 0 0;">&nbsp;</td>
				</tr>
	            <tr>
	                <td width="100%" bgcolor="#ffffff" style="text-align: center; background: #ffffff;" align="center">
	                    <a href="https://www.mvmt.health/" style="text-decoration: none !important; font-size: inherit; border-style: none;" border="0"><img src="https://i1.createsend1.com/ti/j/C4/421/FAB/053926/csimport/mvmt_logo_0.png" width="200" height="" alt="MVMY Health" border="0" style="height: auto; font-family: sans-serif; font-size: 17px; line-height: 17px;"></a>
	                </td>
	            </tr>
	            <tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="10" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">&nbsp;</td>
				</tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center; position: relative; background-image: url(https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/60-DAY/ce1.jpg); background-size:cover; background-position: center; background-repeat: no-repeat;">
                                    <!--[if gte mso 9]>
                                        <v:image xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block; width: 510pt; height: 370pt;" src="https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/60-DAY/ce1.jpg" />                
                                        <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block;position: absolute; width: 510pt; height:370pt;">
                                        <v:fill  opacity="0%" color="#000000”  />
                                        <v:textbox inset="0,0,0,0">
                                    <![endif]-->
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 52.5%" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 20px 0 0 0;"></td>
                                                        <td align="center" width="75%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 45px; padding-bottom: 0px;" class="content-block">
                                                <h1 style="font-size: 28px; line-height: 32px; color: #ffffff; font-weight: bold; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">We miss you at {{office.name}}, {{client.firstName}}!</span></span></font></h1>
                                                        </td>
                                                        <td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 20px 0 0;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <!--[if gte mso 9]>
                                        </v:textbox>
                                        </v:fill>
                                        </v:rect>
                                        </v:image>
                                    <![endif]-->
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 0;" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
                                                        <td align="center" width="70%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 15px; padding-bottom: 30px;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">Make sure you have an upcoming appointment scheduled so you can stay consistent in caring for yourself.</span></span></font></td>
                                                        <td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 0;" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 0 20px;"></td>
                                                        <td align="center" width="40%" bgcolor="#ec6631" style="background-color: #ec6631;">
                                                            <table border="0" cellpadding="0" cellspacing="0" id="cta" role="presentation" width="100%">	
																<tr>
																	<td class="button-td button-td-primary" style="border-radius: 4px; background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#2F5899); color: #1D1D27;" width="100%">
																		<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																			<tr>
																				<td class="button-a button-a-primary" style="background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#3A8AC6); color: #1D1D27; font-family: sans-serif; font-size: 20px; line-height: 20px; text-decoration: none; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; color: #ffffff; display: block; border-radius: 4px;" width="100%">
																					<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																						<tr>
																							<td height="13" width="100%"></td>
																						</tr>
																						<tr>
																							<td align="center" width="100%">
																								<a href="{{patientportal.id.url}}"><font color="#FFFFFF"><font color="#FFFFFF"><span class="gmail-screen"><span class="gmail-difference">Schedule Online</span></span></font></font></a>
																							</td>
																						</tr>
																						<tr>
																							<td height="13" width="100%"></td>
																						</tr>
																					</table>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
																<tr>
																	<td height="50" width="100%"></td>
																</tr>
															</table>
                                                        </td>
                                                        <td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 20px 0;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
				<tr>
				    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">  
						<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
							<tr>
								<td width="100%" style="background-color: #ffffff; color: #1D1D27;">
									<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
										<tr>
											<td width="12.5%" height="50"></td>
											<td width="75%" height="50"></td> 
											<td width="12.5%" height="50"></td>
										</tr>
										<tr>
											<td width="12.5%"></td>
											<td width="75%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #70707c; text-align: center;" class="content-block">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td width="100%" align="center">
															<h2 style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 24px; line-height: 28px; color: #1D1D27; font-weight: bold;"><font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">How are you doing?</span></span></font></h2>
														</td>
													</tr>
													<tr>
														<td width="100%" height="25"></td>
													</tr>
													<tr>
														<td width="100%" align="center" class="content-block">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">I ask because a few days ago, one of our patients came in for an appointment, and when I mentioned it had been 60 days since her last visit, it caught her by surprise.<br/><br/>Life got in the way, and she kept putting herself and her health at the bottom of her priority list.<br/><br/>That was until this past week—her back got to where she could barely stand without pain. After coming in, she felt much better.<br/><br/>When you wait too long to take care of yourself, getting well always takes much longer. Please don't wait until you're in too much pain to listen to what your body is trying to tell you.<br/><br/><hr/><br/><b style="color: #ec6631;"><font color="#1d1d27">Make sure you have an upcoming appointment scheduled so you can stay consistent in caring for yourself.</font></b><br/><br/><a style="font-weight: bold; color: #3b9fe3;" href="{{patientportal.id.url}}">Click Here to Schedule</a></span></span></font>
														</td>
													</tr>
													<tr>
														<td width="100%" height="13" align="center"></td>
													</tr>
													<tr>
														<td width="100%" align="center" style="text-align: center; font-size: 17px; line-height: 24px;">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">Looking forward to seeing you soon,<br/> Dr. (Chiropractor’s Name)</span></span></font>
<br/><br/>
<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">You're always welcome back at {{office.name}}. <br/>Questions? Call or text us at <a href="tel:{{office.phone}}">
{{office.phone}}</span></span></font>
														</td>
													</tr>
												</table>
											</td>
											<td width="12.5%"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
				    </td>
				</tr>
				<tr>
				    <td width="100%">               
					    <table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						    <tr>
							    <td widtdh="100%" aria-hidden="true" height="40" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">
								    &nbsp;
							    </td>
						    </tr>
						<tr>
							<td class="unstyle-auto-detected-links" width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 35px; padding-left: 50px; font-family: sans-serif; font-size: 14px; line-height: 20px; text-align: center; background-color: #ec6631; background-image:linear-gradient(#ec6631,#ec6631); color: #ffffff; border-radius: 0 0 20px 20px;">
								<font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">{{office.name}}<br><span class="unstyle-auto-detected-links">{{office.address.street}} {{office.address.city}}, {{office.address.state}} {{office.address.zipcode}}</span><br><span class="unstyle-auto-detected-links">{{office.phone}}</span></span></span></font>
							</td>
						</tr>
					</table>            
					<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						<tr>
							<td width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 60px; padding-left: 50px; font-family: sans-serif; font-size: 12px; line-height: 18px; text-align: center; color: #70707c;" class="content-block footer">
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1">You're receiving this email because you're a patient. We're excited to serve you and hope to see you soon!</span></span></font><br><br>
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1"><a href="{{client.optoutUrl}}" style="color: #70707c; text-decoration: underline;">Unsubscribe</a></span></span></font>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	    <!--[if mso]>
	    </td>
		</tr>
		</table>
	    <![endif]-->
	    </div>
	    <!--[if mso | IE]>
	    </td>
	    </tr>
	    </table>
	    <![endif]-->
    </center>
</body>
</html>
`, attachments: [], shouldFrame: false },
  sms1: { body: 'Hey {{client.firstName}}, it\'s been 60 days since your last visit! To get the best results, it\'s important to stay consistent. - Dr. [Chiropractor\'s Name] - click to schedule your next visit today- {{client.registerLink}}', attachments: [] },
  push1_2: { subject: '{{client.firstName}}, you\'re always welcome back at {{office.name}}', body: 'I just wanted to let you know you\'re always welcome back at {{office.name}}! It\'s been a little while since your last visit, and I\'d love to help you stay your best. Click here to schedule a visit today- {{client.registerLink}}' },
  email1_2: { subject: '{{client.firstName}}, you\'re always welcome back at {{office.name}}', body: '', html: `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <meta name="format-detection" content="telephone=no,address=no,email=no,date=no,url=no">
    <meta name="color-scheme" content="light dark">
    <meta name="supported-color-schemes" content="light dark">
    <title>62 Day Appointment Reminder</title>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->    
    <!--[if mso]>
        <style>
            * {
                font-family: sans-serif !important;
            }
        </style>
    <![endif]-->  
    <!--[if !mso]><!-->
    <!--<![endif]-->
    <style>       
        :root {
          color-scheme: light dark;
          supported-color-schemes: light dark;
        }        
        html,
        body {
            margin-top: 0 !important;
            margin-bottom: 0 1important;
            margin-left: auto !important;
            margin-right: auto !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            height: 100% !important;
            width: 100% !important;
        }        
        * {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }        
        p {
            margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;
        }        
        h1 p, h2 p, .content-block.footer p {
            font-style: inherit;
            line-height: inherit;
            color: inherit;
            font-weight: inherit;
            margin-top: 0;
            margin-left: auto;
            margin-bottom: 0;
            margin-right: auto;
        }        
        div[style*="margin: 16px 0"] {
            margin: 0 !important;
        }        
        #MessageViewBody, #MessageWebViewDiv{
            width: 100% !important;
        }        
        table,
        td {
            mso-table-lspace: 0pt !important;
            mso-table-rspace: 0pt !important;
        }       
        table {
            border-spacing: 0 !important;
            border-collapse: collapse !important;
            margin: 0 auto !important;
            opacity: 1 !important;
        }   
        table#cta {
            border-spacing: 20px !important;
        table.layout {
            max-width: 680px;
        }
        img {
            -ms-interpolation-mode:bicubic;
        }        
        a {
			text-decoration: none;
		}
		b {
		    color: #ec6631;
		}
        .content-block a {
            color: #3b9fe3;
            text-decoration: underline;
        }        
        *[x-apple-data-detectors],
        .unstyle-auto-detected-links *,
        .aBn {
            border-bottom: 0 !important;
            cursor: default !important;
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        .unstyle-auto-detected-links * {
            text-decoration: none !important;
            border: none !important;
            color: #ffffff !important;
            font-family: sans-serif !important;
        }
        .im {
            color: inherit !important;
        }       
        .a6S {
            display: none !important;
            opacity: 0.01 !important;
        }        
        img.g-img + div {
            display: none !important;
        }        
        @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
            u ~ div .email-container {
                min-width: 320px !important;
            }
        }        
        @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
            u ~ div .email-container {
                min-width: 375px !important;
            }
        }        
        @media only screen and (min-device-width: 414px) {
            u ~ div .email-container {
                min-width: 414px !important;
            }
        }
    </style>    
    <style>
         hr {
             height: 1px;
             width: 100%;
             background-color: #e5e8ea;
             border: none;
         }
        .button-td,
	    .button-a {
	        transition: all 100ms ease-in;
	        text-align: center;
	        min-width: 100%;
	        filter: contrast(1.1);
	    }
	    .button-a a {
	        font-size: 18px;
            line-height: 18px;
            font-weight: 600;
	    }
        .button-td.button-td-primary .button-a a {
            color: #ffffff !important;
        }
        .button-td.button-td-secondary .button-a a {
            color: #121212 !important;
        }
	    .button-td:hover, .button-td:hover .button-a {
	        opacity: 0.7 !important;
	    }
	    .button-td:hover .button-a a {
	        opacity: 0.7 !important;
	    }
	    @media screen and (max-width: 640px) {	        
	        .stack-column,
	        .stack-column-center {
	            display: block !important;
	            position: relative !important;
	            margin-bottom: 20px !important;
	        }	        
	        .stack-column-center {
	            text-align: center !important;
	        }	        
	        .center-on-narrow {
	            text-align: center !important;
	            display: block !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	            float: none !important;
	        }
	        table.center-on-narrow {
	            display: inline-block !important;
	        }
            .email-container h1 p {
	            font-size: 26px !important;
	        }
            .email-container h2 p {
	            font-size: 21px !important;
	        }	        
	        .email-container p {
	            font-size: 17px !important;
	        }
	        div.post-script {
	            width: 100% !important;
	            max-width: 85% !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	        }
	        .mod-bottom {
	            padding-bottom: 10px !important;
	        }
	        .mod-top {
	            padding-top: 10px !important;
	        }
	        .named, .named p {
	            font-size: 10px !important;
	        }
	    }
        u + .body .gmail-screen { background:#000; mix-blend-mode:screen; }
        u + .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        /*u + .body .gmail-difference1 { background:#fff !important; mix-blend-mode:difference; }
        u + .body .gmail-lighten {background:#fff !important; mix-blend-mode:exclusion; border: none !important; height: 0 !important;}*/
        /*.body .gmail-screen { background:#000; mix-blend-mode:screen; }
        .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        .body .gmail-difference1 { background:#fff !important;  mix-blend-mode:difference; }
        .body .gmail-lighten {background:#fff !important; mix-blend-mode: exclusion; border: none !important; height: 0 !important;}*/
    </style>
</head>
<body class="body" width="100%" style="margin-top: 0; margin-bottom: 0; margin-right: 0; margin-left: 0; padding-top: 0 !important; padding-bottom: 0 !important; padding-left: 0 !important; padding-right: 0 !important; mso-line-height-rule: exactly; background-color: #f6f9fb; color: #1D1D27;">
  <center role="article" aria-roledescription="email" lang="en" style="width: 100%; background-color: #f6f9fb; color: #1D1D27;">
    <!--[if mso | IE]>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" style="background-color: #f6f9fb; color: #1D1D27;">
    <tr>
    <td>
    <![endif]-->        
        <div style="max-height:0; overflow:hidden; mso-hide:all;" aria-hidden="true">We understand how busy life can be, and it’s easy to prioritize everything and everyone in your life over your own health.</div>        
        <div style="display: none; font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
            &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
        </div>
        <div style="max-width: 680px; margin: 0 auto;" class="email-container">
            <!--[if mso]>
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="510">
            <tr>
            <td width="100%">
            <![endif]-->	        
	        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
	            <tr>
					<td width="100%" bgcolor="#f6f9fb" aria-hidden="true" height="35" style="font-size: 0px; line-height: 0px; background-color: #f6f9fb; color: #1D1D27;">&nbsp;</td>
				</tr>
				<tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="15" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27; border-radius: 20px 20px 0 0;">&nbsp;</td>
				</tr>
	            <tr>
	                <td width="100%" bgcolor="#ffffff" style="text-align: center; background: #ffffff;" align="center">
	                    <a href="https://www.mvmt.health/" style="text-decoration: none !important; font-size: inherit; border-style: none;" border="0"><img src="https://i1.createsend1.com/ti/j/C4/421/FAB/053926/csimport/mvmt_logo_0.png" width="200" height="" alt="MVMY Health" border="0" style="height: auto; font-family: sans-serif; font-size: 17px; line-height: 17px;"></a>
	                </td>
	            </tr>
	            <tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="10" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">&nbsp;</td>
				</tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%">
                                    <table role="presentation" cellspacgin="0" cellpadding="0" border="0" width="100%">
                                        <tr>
								            <td width="100%" style="background-color: #ffffff; color: #1D1D27;">
									            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
									                <tr>
												        <td width="12.5%" height="25"></td>
									                    <td width="75%" height="25"><hr/><br/></td> 
													    <td width="12.5%" height="25"></td>
									                </tr>
									            </table>
								            </td>
							            </tr>
                                    </table>
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td width="7.5%"></td>
                                            <td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 20px 0 0 0;"></td>
                                            <td align="center" width="75%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 45px; padding-bottom: 0px;" class="content-block">
                                                <h1 style="font-size: 24px; line-height: 28px; color: #ffffff; font-weight: bold; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">You’re always welcome back at {{office.name}}, {{client.firstName}}!</span></span></font></h1>
                                            </td>
                                            <td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 20px 0 0;"></td>
                                            <td width="7.5%"></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 0;" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
                                                        <td align="center" width="70%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 15px; padding-bottom: 25px;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">Today is the day to take the actions needed to get the results you want for your health. And we're proud to be your partner for moving, feeling, and functioning your best.</span></span></font></td>
                                                        <td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 0;" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%" ></td>
                                                        <td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631;  border-radius: 0 0 0 20px;"></td>
                                                        <td align="center" width="40%" bgcolor="#ec6631" style="background-color: #ec6631;">
                                                            <table border="0" cellpadding="0" cellspacing="0" id="cta" role="presentation" width="100%">
																<tr>
																	<td class="button-td button-td-primary" style="border-radius: 4px; background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#2F5899); color: #1D1D27;" width="100%">
																		<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																			<tr>
																				<td class="button-a button-a-primary" style="background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#3A8AC6); color: #1D1D27; font-family: sans-serif; font-size: 20px; line-height: 20px; text-decoration: none; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; color: #ffffff; display: block; border-radius: 4px;" width="100%">
																					<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																						<tr>
																							<td height="13" width="100%"></td>
																						</tr>
																						<tr>
																							<td align="center" width="100%">
																								<a href="{{patientportal.id.url}}"><font color="#FFFFFF"><font color="#FFFFFF"><span class="gmail-screen"><span class="gmail-difference">Schedule Online</span></span></font></font></a>
																							</td>
																						</tr>
																						<tr>
																							<td height="13" width="100%"></td>
																						</tr>
																					</table>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
																<tr>
																	<td height="50" width="100%"></td>
																</tr>
															</table>
                                                        </td>
                                                        <td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 20px 0;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
				<tr>
				    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);"> 
						<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
							<tr>
								<td width="100%" style="background-color: #ffffff; color: #1D1D27;">
									<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
										<tr>
											<td width="12.5%" height="40"></td>
											<td width="75%" height="40"></td> 
											<td width="12.5%" height="40"></td>
										</tr>
										<tr>
											<td width="12.5%"></td>
											<td width="75%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #70707c; text-align: center;" class="content-block">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
															<td width="100%" align="center">
																<h2 style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 24px; line-height: 28px; color: #1D1D27; font-weight: bold; padding-top: 10px;"><font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">We understand how busy life can be...</span></span></font></h2>
															</td>
														</tr>
														<tr>
															<td width="100%" height="25"></td>
														</tr>
														<tr>
															<td width="100%" align="center" class="content-block">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">it’s easy to prioritize everything and everyone in your life over your own health.We've found that being proactive with your healthcare is the best way to maintain the highest quality of life possible.<br/><br/>Taking care of small problems before they become big problems is a great idea and the best way to do that is to ensure you always have a next appointment scheduled.</span></span></font>
															</td>
														</tr>
														<tr>
															<td width="100%" height="25"></td>
														</tr>
													<tr>
														<td width="100%" align="center" class="content-block">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">So, if you don't have your next visit booked, click below to schedule now so we can help you reach and exceed your health goals. <br/><br/><a style="font-weight: bold; color: #3b9fe3;" href="{{patientportal.id.url}}">Click Here to Schedule</a></span></span></font>
														</td>
													</tr>
													<tr>
													<tr>
														<td width="100%" height="13" align="center"></td>
													</tr>
													<tr>
														<td width="100%" align="center" style="text-align: center; font-size: 17px; line-height: 24px;">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">Looking forward to seeing you soon,<br/> Dr. (Chiropractor’s Name)</span></span></font>
<br/><br/>
<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">You're always welcome back at {{office.name}}. <br/>Questions? Call or text us at <a href="tel:{{office.phone}}">
{{office.phone}}</span></span></font>
														</td>
													</tr>
												</table>
											</td>
											<td width="12.5%"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
				    </td>
				</tr>
				<tr>
				    <td width="100%">               
					    <table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						    <tr>
							    <td widtdh="100%" aria-hidden="true" height="40" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">
								    &nbsp;
							    </td>
						    </tr>
						<tr>
							<td class="unstyle-auto-detected-links" width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 35px; padding-left: 50px; font-family: sans-serif; font-size: 14px; line-height: 20px; text-align: center; background-color: #ec6631; background-image:linear-gradient(#ec6631,#ec6631); color: #ffffff; border-radius: 0 0 20px 20px;">
								<font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">{{office.name}}<br><span class="unstyle-auto-detected-links">{{office.address.street}} {{office.address.city}}, {{office.address.state}} {{office.address.zipcode}}</span><br><span class="unstyle-auto-detected-links">{{office.phone}}</span></span></span></font>
							</td>
						</tr>
					</table>            
					<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						<tr>
							<td width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 60px; padding-left: 50px; font-family: sans-serif; font-size: 12px; line-height: 18px; text-align: center; color: #70707c;" class="content-block footer">
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1">You're receiving this email because you're a patient. We're excited to serve you and hope to see you soon!</span></span></font><br><br>
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1"><a href="{{client.optoutUrl}}" style="color: #70707c; text-decoration: underline;">Unsubscribe</a></span></span></font>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	    <!--[if mso]>
	    </td>
		</tr>
		</table>
	    <![endif]-->
	    </div>
	    <!--[if mso | IE]>
	    </td>
	    </tr>
	    </table>
	    <![endif]-->
    </center>
</body>
</html>
`, attachments: [], shouldFrame: false },
  sms1_2: { body: 'I just wanted to let you know you\'re always welcome back at {{office.name}}! It\'s been a little while since your last visit, and I\'d love to help you stay your best. Click here to schedule a visit today- {{client.registerLink}}', attachments: [] },
  push2: { subject: '{{client.firstName}}, it\'s been 4 months!', body: '{{client.firstName}}, can you believe it\'s been over 4 months since your last visit? Don\'t worry; we\'ve always got your back. Click here to schedule your next visit today- {{client.registerLink}}' },
  email2: { subject: '{{client.firstName}}, it\'s been 4 months!', body: '', html: `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <meta name="format-detection" content="telephone=no,address=no,email=no,date=no,url=no">
    <meta name="color-scheme" content="light dark">
    <meta name="supported-color-schemes" content="light dark">
    <title>150 Day Appointment Invite</title>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->    
    <!--[if mso]>
        <style>
            * {
                font-family: sans-serif !important;
            }
        </style>
    <![endif]-->  
    <!--[if !mso]><!-->
    <!--<![endif]-->
    <style>       
        :root {
          color-scheme: light dark;
          supported-color-schemes: light dark;
        }        
        html,
        body {
            margin-top: 0 !important;
            margin-bottom: 0 1important;
            margin-left: auto !important;
            margin-right: auto !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            height: 100% !important;
            width: 100% !important;
        }        
        * {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }        
        p {
            margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;
        }        
        h1 p, h2 p, .content-block.footer p {
            font-style: inherit;
            line-height: inherit;
            color: inherit;
            font-weight: inherit;
            margin-top: 0;
            margin-left: auto;
            margin-bottom: 0;
            margin-right: auto;
        }        
        div[style*="margin: 16px 0"] {
            margin: 0 !important;
        }        
        #MessageViewBody, #MessageWebViewDiv{
            width: 100% !important;
        }        
        table,
        td {
            mso-table-lspace: 0pt !important;
            mso-table-rspace: 0pt !important;
        }       
        table {
            border-spacing: 0 !important;
            border-collapse: collapse !important;
            margin: 0 auto !important;
            opacity: 1 !important;
        }   
        table#cta {
            border-spacing: 20px !important;
        table.layout {
            max-width: 680px;
        }
        img {
            -ms-interpolation-mode:bicubic;
        }        
        a {
			text-decoration: none;
		}
		b {
		    color: #ec6631;
		}
        .content-block a {
            color: #3b9fe3;
            text-decoration: underline;
        }        
        *[x-apple-data-detectors],
        .unstyle-auto-detected-links *,
        .aBn {
            border-bottom: 0 !important;
            cursor: default !important;
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        .unstyle-auto-detected-links * {
            text-decoration: none !important;
            border: none !important;
            color: #ffffff !important;
            font-family: sans-serif !important;
        }
        .im {
            color: inherit !important;
        }       
        .a6S {
            display: none !important;
            opacity: 0.01 !important;
        }        
        img.g-img + div {
            display: none !important;
        }        
        @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
            u ~ div .email-container {
                min-width: 320px !important;
            }
        }        
        @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
            u ~ div .email-container {
                min-width: 375px !important;
            }
        }        
        @media only screen and (min-device-width: 414px) {
            u ~ div .email-container {
                min-width: 414px !important;
            }
        }
    </style>    
    <style>
         hr {
             height: 1px;
             width: 100%;
             background-color: #e5e8ea;
             border: none;
         }
        .button-td,
	    .button-a {
	        transition: all 100ms ease-in;
	        text-align: center;
	        min-width: 100%;
	        filter: contrast(1.1);
	    }
	    .button-a a {
	        font-size: 18px;
            line-height: 18px;
            font-weight: 600;
	    }
        .button-td.button-td-primary .button-a a {
            color: #ffffff !important;
        }
        .button-td.button-td-secondary .button-a a {
            color: #121212 !important;
        }
	    .button-td:hover, .button-td:hover .button-a {
	        opacity: 0.7 !important;
	    }
	    .button-td:hover .button-a a {
	        opacity: 0.7 !important;
	    }
	    @media screen and (max-width: 640px) {	        
	        .stack-column,
	        .stack-column-center {
	            display: block !important;
	            position: relative !important;
	            margin-bottom: 20px !important;
	        }	        
	        .stack-column-center {
	            text-align: center !important;
	        }	        
	        .center-on-narrow {
	            text-align: center !important;
	            display: block !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	            float: none !important;
	        }
	        table.center-on-narrow {
	            display: inline-block !important;
	        }
            .email-container h1 p {
	            font-size: 26px !important;
	        }
            .email-container h2 p {
	            font-size: 21px !important;
	        }	        
	        .email-container p {
	            font-size: 17px !important;
	        }
	        div.post-script {
	            width: 100% !important;
	            max-width: 85% !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	        }
	        .mod-bottom {
	            padding-bottom: 10px !important;
	        }
	        .mod-top {
	            padding-top: 10px !important;
	        }
	        .named, .named p {
	            font-size: 10px !important;
	        }
	    }
        u + .body .gmail-screen { background:#000; mix-blend-mode:screen; }
        u + .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        /*u + .body .gmail-difference1 { background:#fff !important; mix-blend-mode:difference; }
        u + .body .gmail-lighten {background:#fff !important; mix-blend-mode:exclusion; border: none !important; height: 0 !important;}*/
        /*.body .gmail-screen { background:#000; mix-blend-mode:screen; }
        .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        .body .gmail-difference1 { background:#fff !important;  mix-blend-mode:difference; }
        .body .gmail-lighten {background:#fff !important; mix-blend-mode: exclusion; border: none !important; height: 0 !important;}*/
    </style>
</head>
<body class="body" width="100%" style="margin-top: 0; margin-bottom: 0; margin-right: 0; margin-left: 0; padding-top: 0 !important; padding-bottom: 0 !important; padding-left: 0 !important; padding-right: 0 !important; mso-line-height-rule: exactly; background-color: #f6f9fb; color: #1D1D27;">
  <center role="article" aria-roledescription="email" lang="en" style="width: 100%; background-color: #f6f9fb; color: #1D1D27;">
    <!--[if mso | IE]>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" style="background-color: #f6f9fb; color: #1D1D27;">
    <tr>
    <td>
    <![endif]-->        
        <div style="max-height:0; overflow:hidden; mso-hide:all;" aria-hidden="true">It's hard to believe, but it has already been over 4 months since we last saw you...</div>        
        <div style="display: none; font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
            &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
        </div>
        <div style="max-width: 680px; margin: 0 auto;" class="email-container">
            <!--[if mso]>
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="510">
            <tr>
            <td width="100%">
            <![endif]-->	        
	        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
	            <tr>
					<td width="100%" bgcolor="#f6f9fb" aria-hidden="true" height="35" style="font-size: 0px; line-height: 0px; background-color: #f6f9fb; color: #1D1D27;">&nbsp;</td>
				</tr>
				<tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="15" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27; border-radius: 20px 20px 0 0;">&nbsp;</td>
				</tr>
	            <tr>
	                <td width="100%" bgcolor="#ffffff" style="text-align: center; background: #ffffff;" align="center">
	                    <a href="https://www.mvmt.health/" style="text-decoration: none !important; font-size: inherit; border-style: none;" border="0"><img src="https://i1.createsend1.com/ti/j/C4/421/FAB/053926/csimport/mvmt_logo_0.png" width="200" height="" alt="MVMY Health" border="0" style="height: auto; font-family: sans-serif; font-size: 17px; line-height: 17px;"></a>
	                </td>
	            </tr>
	            <tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="10" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">&nbsp;</td>
				</tr>
	            
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center; position: relative; background-image: url(https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/150-DAY/ce1.jpg); background-size:cover; background-position: center; background-repeat: no-repeat;">
                                    <!--[if gte mso 9]>
                                        <v:image xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block; width: 510pt; height: 370pt;" src="https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/150-DAY/ce1.jpg" />                
                                        <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block;position: absolute; width: 510pt; height:370pt;">
                                        <v:fill  opacity="0%" color="#000000”  />
                                        <v:textbox inset="0,0,0,0">
                                    <![endif]-->
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 52.5%" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 20px 0 0 0;"></td>
                                                        <td align="center" width="75%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 45px; padding-bottom: 0px;" class="content-block">
                                                <h1 style="font-size: 28px; line-height: 32px; color: #ffffff; font-weight: bold; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">{{client.firstName}}, it's already been 4 months since we've seen you!</span></span></font></h1>
                                                        </td>
                                                        <td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 20px 0 0;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <!--[if gte mso 9]>
                                        </v:textbox>
                                        </v:fill>
                                        </v:rect>
                                        </v:image>
                                    <![endif]-->
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 0;" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
                                                        <td align="center" width="70%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 15px; padding-bottom: 30px;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">But rest assured, here at {{office.name}}, we've always got your back.</span></span></font></td>
                                                        <td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                                <td width="100%" style="background-color: #ffffff; text-align: center;">
                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td style="padding-top: 0;" width="100%">
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                     <tr>
                                                        <td width="7.5%"></td>
                                                        <td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 0 20px;"></td>
                                                        <td align="center" width="40%" bgcolor="#ec6631" style="background-color: #ec6631;">
                                                            <table border="0" cellpadding="0" cellspacing="0" id="cta" role="presentation" width="100%">
																<tr>
																	<td class="button-td button-td-primary" style="border-radius: 4px; background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#2F5899); color: #1D1D27;" width="100%">
																		<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																			<tr>
																				<td class="button-a button-a-primary" style="background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#3A8AC6); color: #1D1D27; font-family: sans-serif; font-size: 20px; line-height: 20px; text-decoration: none; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; color: #ffffff; display: block; border-radius: 4px;" width="100%">
																					<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																						<tr>
																							<td height="13" width="100%"></td>
																						</tr>
																						<tr>
																							<td align="center" width="100%">
																								<a href="{{patientportal.id.url}}"><font color="#FFFFFF"><font color="#FFFFFF"><span class="gmail-screen"><span class="gmail-difference">Schedule Online</span></span></font></font></a>
																							</td>
																						</tr>
																						<tr>
																							<td height="13" width="100%"></td>
																						</tr>
																					</table>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
																<tr>
																	<td height="50" width="100%"></td>
																</tr>
															</table>
                                                        </td>
                                                        <td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 20px 0;"></td>
                                                        <td width="7.5%"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>   
				<tr>
				    <td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);"> 
						<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
							<tr>
								<td width="100%" style="background-color: #ffffff; color: #1D1D27;">
									<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
										<tr>
											<td width="12.5%" height="50"></td>
									        <td width="75%" height="50"></td> 
											<td width="12.5%" height="50"></td>
									     </tr>
									     <tr>
											<td width="12.5%"></td>
											<td width="75%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #70707c; text-align: center;" class="content-block">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td width="100%" align="center">
															<h2 style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 24px; line-height: 28px; color: #1D1D27; font-weight: bold;"><font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">Time flies, doesn't it?</span></span></font></h2>
												        </td>
												    </tr>
												    <tr>
														<td width="100%" height="25"></td>
												    </tr>
												    <tr>
														<td width="100%" align="center" class="content-block">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">Consistency is key with chiropractic care, like your new exercise and nutritional goals. And we believe in the importance of regular care to maintain optimal health and well-being. </span></span></font>
												         </td>
												     </tr>
												     <tr>
														<td>
															<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
																<tr>
																	<td width="50%" align="center" style="padding-top: 30px; padding-bottom: 32px;">
																		<p style="tetx-align: center;"><img src="https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/150-DAY/ce1-2.jpg" /></p>
																	</td>
																	<td width="50%" align="center" style="padding-top: 30px; padding-bottom: 32px;">
																		<p style="text-align: center;"><img src="https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/150-DAY/ce1-3.jpg" /></p>
																	</td>
												                </tr>
												            </table>
												        </td>
												    </tr>
												    <tr>
														<td width="100%" align="center" class="content-block">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1"><hr/><br/><b>It's time to put your health back at the top of your priority list!</b><br/><br/>We look forward to welcoming you back and helping you feel your best again, and we hope to see you soon.<br/><br/><a style="font-weight: bold; color: #3b9fe3;" href="{{patientportal.id.url}}">Click Here to Schedule</a></span></span></font>
												         </td>
												    </tr>
													 <tr>
														<td width="100%" height="13" align="center"></td>
													 </tr>
													 <tr>
														<td width="100%" align="center" style="text-align: center; font-size: 17px; line-height: 24px;">
															<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">Looking forward to seeing you soon,<br/> Dr. (Chiropractor’s Name)</span></span></font>
<br/><br/>
<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">You're always welcome back at {{office.name}}. <br/>Questions? Call or text us at <a href="tel:{{office.phone}}">
{{office.phone}}</span></span></font>
												        </td>
												     </tr>
												 </table>
											</td>
											<td width="12.5%"></td>
										</tr>
									</table>
								</td>
							 </tr>
						 </table>	
				    </td>
				</tr>
				<tr>
				    <td width="100%">               
					    <table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						    <tr>
							    <td widtdh="100%" aria-hidden="true" height="40" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">
								    &nbsp;
							    </td>
						    </tr>
						<tr>
							<td class="unstyle-auto-detected-links" width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 35px; padding-left: 50px; font-family: sans-serif; font-size: 14px; line-height: 20px; text-align: center; background-color: #ec6631; background-image:linear-gradient(#ec6631,#ec6631); color: #ffffff; border-radius: 0 0 20px 20px;">
								<font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">{{office.name}}<br><span class="unstyle-auto-detected-links">{{office.address.street}} {{office.address.city}}, {{office.address.state}} {{office.address.zipcode}}</span><br><span class="unstyle-auto-detected-links">{{office.phone}}</span></span></span></font>
							</td>
						</tr>
					</table>            
					<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						<tr>
							<td width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 60px; padding-left: 50px; font-family: sans-serif; font-size: 12px; line-height: 18px; text-align: center; color: #70707c;" class="content-block footer">
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1">You're receiving this email because you're a patient. We're excited to serve you and hope to see you soon!</span></span></font><br><br>
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1"><a href="{{client.optoutUrl}}" style="color: #70707c; text-decoration: underline;">Unsubscribe</a></span></span></font>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	    <!--[if mso]>
	    </td>
		</tr>
		</table>
	    <![endif]-->
	    </div>
	    <!--[if mso | IE]>
	    </td>
	    </tr>
	    </table>
	    <![endif]-->
    </center>
</body>
</html>
`
  , attachments: [], shouldFrame: false },
  sms2: { body: '{{client.firstName}}, can you believe it\'s been over 4 months since your last visit? Don\'t worry; we\'ve always got your back. Click here to schedule your next visit today- {{client.registerLink}}', attachments: [] },
  push2_2: { subject: 'Checking in...', body: '😊 We\'ve missed you at {{office.name}}! Stay your best by ensuring you have your next appointment scheduled. Click here to choose your best day/time- {{client.registerLink}}' },
  email2_2: { subject: 'Checking in...', body: '', html: `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <meta name="format-detection" content="telephone=no,address=no,email=no,date=no,url=no">
    <meta name="color-scheme" content="light dark">
    <meta name="supported-color-schemes" content="light dark">
    <title>152 Day Appointment Invite</title>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->    
    <!--[if mso]>
        <style>
            * {
                font-family: sans-serif !important;
            }
        </style>
    <![endif]-->  
    <!--[if !mso]><!-->
    <!--<![endif]-->
    <style>       
        :root {
          color-scheme: light dark;
          supported-color-schemes: light dark;
        }        
        html,
        body {
            margin-top: 0 !important;
            margin-bottom: 0 1important;
            margin-left: auto !important;
            margin-right: auto !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            height: 100% !important;
            width: 100% !important;
        }        
        * {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }        
        p {
            margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;
        }        
        h1 p, h2 p, .content-block.footer p {
            font-style: inherit;
            line-height: inherit;
            color: inherit;
            font-weight: inherit;
            margin-top: 0;
            margin-left: auto;
            margin-bottom: 0;
            margin-right: auto;
        }        
        div[style*="margin: 16px 0"] {
            margin: 0 !important;
        }        
        #MessageViewBody, #MessageWebViewDiv{
            width: 100% !important;
        }        
        table,
        td {
            mso-table-lspace: 0pt !important;
            mso-table-rspace: 0pt !important;
        }       
        table {
            border-spacing: 0 !important;
            border-collapse: collapse !important;
            margin: 0 auto !important;
            opacity: 1 !important;
        }   
        table#cta {
            border-spacing: 20px !important;
        table.layout {
            max-width: 680px;
        }
        img {
            -ms-interpolation-mode:bicubic;
        }        
        a {
			text-decoration: none;
		}
		b {
		    color: #ec6631;
		}
        .content-block a {
            color: #3b9fe3;
            text-decoration: underline;
        }        
        *[x-apple-data-detectors],
        .unstyle-auto-detected-links *,
        .aBn {
            border-bottom: 0 !important;
            cursor: default !important;
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        .unstyle-auto-detected-links * {
            text-decoration: none !important;
            border: none !important;
            color: #ffffff !important;
            font-family: sans-serif !important;
        }
        .im {
            color: inherit !important;
        }       
        .a6S {
            display: none !important;
            opacity: 0.01 !important;
        }        
        img.g-img + div {
            display: none !important;
        }        
        @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
            u ~ div .email-container {
                min-width: 320px !important;
            }
        }        
        @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
            u ~ div .email-container {
                min-width: 375px !important;
            }
        }        
        @media only screen and (min-device-width: 414px) {
            u ~ div .email-container {
                min-width: 414px !important;
            }
        }
    </style>    
    <style>
         hr {
             height: 1px;
             width: 100%;
             background-color: #e5e8ea;
             border: none;
         }
        .button-td,
	    .button-a {
	        transition: all 100ms ease-in;
	        text-align: center;
	        min-width: 100%;
	        filter: contrast(1.1);
	    }
	    .button-a a {
	        font-size: 18px;
            line-height: 18px;
            font-weight: 600;
	    }
        .button-td.button-td-primary .button-a a {
            color: #ffffff !important;
        }
        .button-td.button-td-secondary .button-a a {
            color: #121212 !important;
        }
	    .button-td:hover, .button-td:hover .button-a {
	        opacity: 0.7 !important;
	    }
	    .button-td:hover .button-a a {
	        opacity: 0.7 !important;
	    }
	    @media screen and (max-width: 640px) {	        
	        .stack-column,
	        .stack-column-center {
	            display: block !important;
	            position: relative !important;
	            margin-bottom: 20px !important;
	        }	        
	        .stack-column-center {
	            text-align: center !important;
	        }	        
	        .center-on-narrow {
	            text-align: center !important;
	            display: block !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	            float: none !important;
	        }
	        table.center-on-narrow {
	            display: inline-block !important;
	        }
            .email-container h1 p {
	            font-size: 26px !important;
	        }
            .email-container h2 p {
	            font-size: 21px !important;
	        }	        
	        .email-container p {
	            font-size: 17px !important;
	        }
	        div.post-script {
	            width: 100% !important;
	            max-width: 85% !important;
	            margin-left: auto !important;
	            margin-right: auto !important;
	        }
	        .mod-bottom {
	            padding-bottom: 10px !important;
	        }
	        .mod-top {
	            padding-top: 10px !important;
	        }
	        .named, .named p {
	            font-size: 10px !important;
	        }
	    }
        u + .body .gmail-screen { background:#000; mix-blend-mode:screen; }
        u + .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        /*u + .body .gmail-difference1 { background:#fff !important; mix-blend-mode:difference; }
        u + .body .gmail-lighten {background:#fff !important; mix-blend-mode:exclusion; border: none !important; height: 0 !important;}*/
        /*.body .gmail-screen { background:#000; mix-blend-mode:screen; }
        .body .gmail-difference { background:#000; mix-blend-mode:difference; }
        .body .gmail-difference1 { background:#fff !important;  mix-blend-mode:difference; }
        .body .gmail-lighten {background:#fff !important; mix-blend-mode: exclusion; border: none !important; height: 0 !important;}*/
    </style>
</head>
<body class="body" width="100%" style="margin-top: 0; margin-bottom: 0; margin-right: 0; margin-left: 0; padding-top: 0 !important; padding-bottom: 0 !important; padding-left: 0 !important; padding-right: 0 !important; mso-line-height-rule: exactly; background-color: #f6f9fb; color: #1D1D27;">
  <center role="article" aria-roledescription="email" lang="en" style="width: 100%; background-color: #f6f9fb; color: #1D1D27;">
    <!--[if mso | IE]>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" style="background-color: #f6f9fb; color: #1D1D27;">
    <tr>
    <td>
    <![endif]-->        
        <div style="max-height:0; overflow:hidden; mso-hide:all;" aria-hidden="true">I noticed that it's been a while since your last visit, and I wanted to check in and ensure that you're feeling your absolute best.</div>        
        <div style="display: none; font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
            &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
        </div>
        <div style="max-width: 680px; margin: 0 auto;" class="email-container">
            <!--[if mso]>
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="510">
            <tr>
            <td width="100%">
            <![endif]-->	        
	        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
	            <tr>
					<td width="100%" bgcolor="#f6f9fb" aria-hidden="true" height="35" style="font-size: 0px; line-height: 0px; background-color: #f6f9fb; color: #1D1D27;">&nbsp;</td>
				</tr>
				<tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="25" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27; border-radius: 20px 20px 0 0;">&nbsp;</td>
				</tr>
	            <tr>
	                <td width="100%" bgcolor="#ffffff" style="text-align: center; background: #ffffff;" align="center">
	                    <a href="https://www.mvmt.health/" style="text-decoration: none !important; font-size: inherit; border-style: none;" border="0"><img src="https://i1.createsend1.com/ti/j/C4/421/FAB/053926/csimport/mvmt_logo_0.png" width="200" height="" alt="MVMY Health" border="0" style="height: auto; font-family: sans-serif; font-size: 17px; line-height: 17px;"></a>
	                </td>
	            </tr>
	           <tr>
					<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="5" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">&nbsp;</td>
				</tr>
				<tr>
				    <td width="100%">
				        <table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
							        <tr>
								        <td width="100%" style="background-color: #ffffff; color: #1D1D27;">
									        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
									            <tr>
												    <td width="12.5%" height="5"></td>
									                <td width="75%" height="5"></td> 
													<td width="12.5%" height="5"></td>
									            </tr>
									            <tr>
									                <td width="12.5%"></td>
											        <td width="75%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #70707c; text-align: center;" class="content-block">
											            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
									                        <tr>
									                            <td width="100%" align="center">
												                   <hr/><br/><h2 style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 24px; line-height: 28px; color: #1D1D27; font-weight: bold;"><font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">How have you been?</span></span></font></h2>
												                </td>
												            </tr>
												            <tr>
												                <td width="100%" height="25"></td>
												            </tr>
												            <tr>
												                <td width="100%" align="center" class="content-block">
												                    <font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">I noticed that it's been a while since your last visit, and I wanted to check in and ensure that you're feeling your absolute best.</span></span></font>
												                </td>
												            </tr>
												        </table>
											        </td>
											        <td width="12.5%"></td>
										        </tr>
									        </table>
								        </td>
							        </tr>
						        </table>
							</td>
						</tr>
						<tr>
							<td width="100%" bgcolor="#ffffff" aria-hidden="true" height="40" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">&nbsp;</td>
						</tr>
						<tr>
							<td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
								<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
									<tr>
										<td width="100%" style="background-color: #ffffff; text-align: center; position: relative; background-image: url(https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/152-DAY/ce1.jpg); background-size:cover; background-position: center; background-repeat: no-repeat;">
										<!--[if gte mso 9]>
											<v:image xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block; width: 510pt; height: 370pt;" src="https://chiroemails.b-cdn.net/dev-send/email-storage/campaigns/SPARK/IMAGES/152-DAY/ce1.jpg" />                
											<v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block;position: absolute; width: 510pt; height:370pt;">
											<v:fill  opacity="0%" color="#000000”  />
											<v:textbox inset="0,0,0,0">
										<![endif]-->
											<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
												<tr>
													<td style="padding-top: 52.5%" width="100%">
														<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
															<tr>
																<td width="7.5%"></td>
																<td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 20px 0 0 0;"></td>
																<td align="center" width="75%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 45px; padding-bottom: 0px;" class="content-block">
																	<h1 style="font-size: 28px; line-height: 32px; color: #ffffff; font-weight: bold; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">We've always got your back at {{office.name}}, {{client.firstName}}!</span></span></font></h1>
																</td>
																<td width="5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 20px 0 0;"></td>
																<td width="7.5%"></td>
															</tr>
														</table>
													</td>
												</tr>
											</table>
										<!--[if gte mso 9]>
											</v:textbox>
											</v:fill>
											</v:rect>
											</v:image>
										<![endif]-->
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
						<td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
							<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
								<tr>
									<td width="100%" style="background-color: #ffffff; text-align: center;">
										<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
											<tr>
												<td style="padding-top: 0;" width="100%">
													<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
														 <tr>
															<td width="7.5%"></td>
															<td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
															<td align="center" width="70%" bgcolor="#ec6631" style="background-color: #ec6631;  color: #ffffff; text-align: center; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #ffffff; padding-top: 15px; padding-bottom: 30px;"><font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">Scheduling your next appointment is quick and easy.</span></span></font></td>
															<td width="7.5%" bgcolor="#ec6631" style="background-color: #ec6631;"></td>
															<td width="7.5%"></td>
														</tr>
													</table>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
							<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
								<tr>
									<td width="100%" style="background-color: #ffffff; text-align: center;">
										<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
											<tr>
												<td style="padding-top: 0;" width="100%">
													<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
														 <tr>
															<td width="7.5%"></td>
															<td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 0 20px;"></td>
															<td align="center" width="40%" bgcolor="#ec6631" style="background-color: #ec6631;">
																<table border="0" cellpadding="0" cellspacing="0" id="cta" role="presentation" width="100%">
																	<tr>
																		<td class="button-td button-td-primary" style="border-radius: 4px; background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#2F5899); color: #1D1D27;" width="100%">
																			<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																				<tr>
																					<td class="button-a button-a-primary" style="background-color: #3A8AC6; background-image:linear-gradient(#3A8AC6,#3A8AC6); color: #1D1D27; font-family: sans-serif; font-size: 20px; line-height: 20px; text-decoration: none; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; color: #ffffff; display: block; border-radius: 4px;" width="100%">
																						<table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
																							<tr>
																								<td height="13" width="100%"></td>
																							</tr>
																							<tr>
																								<td align="center" width="100%">
																									<a href="{{patientportal.id.url}}"><font color="#FFFFFF"><font color="#FFFFFF"><span class="gmail-screen"><span class="gmail-difference">Schedule Online</span></span></font></font></a>
																								</td>
																							</tr>
																							<tr>
																								<td height="13" width="100%"></td>
																							</tr>
																						</table>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																	<tr>
																		<td height="50" width="100%"></td>
																	</tr>
																</table>
															</td>
															<td width="22.5%" bgcolor="#ec6631" style="background-color: #ec6631; border-radius: 0 0 20px 0;"></td>
															<td width="7.5%"></td>
														</tr>
													</table>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</td>
					</tr> 
					<tr>
						<td width="100%" bgcolor="#ffffff" style="background-color: #ffffff; background-image: linear-gradient(#ffffff, #ffffff);">
							<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
								<tr>
									<td width="100%" style="background-color: #ffffff; color: #1D1D27;">
										<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
											<tr>
												<td width="12.5%" height="50"></td>
												<td width="75%" height="50"></td> 
												<td width="12.5%" height="50"></td>
											</tr>
											<tr>
												<td width="12.5%"></td>
												<td width="75%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; font-family: sans-serif; font-size: 17px; line-height: 24px; color: #70707c; text-align: center;" class="content-block">
													<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
														<tr>
															<td width="100%" align="center" class="content-block">
																<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1"><b>It's always our goal to keep you living your best life.</b><br/><br/>Regular care can greatly improve your overall well-being and help prevent issues from popping back up.<br/><br/>I invite you to click below to schedule your next appointment on my calendar quickly and easily. <br/><br/><a style="font-weight: bold; color: #3b9fe3;" href="{{patientportal.id.url}}">Click Here to Schedule</a></span></span></font>
															</td>
														</tr>
														<tr>
															<td width="100%" height="13" align="center"></td>
														</tr>
														<tr>
															<td width="100%" align="center" style="text-align: center; font-size: 17px; line-height: 24px;">
																<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">Looking forward to seeing you soon,<br/> Dr. (Chiropractor’s Name)</span></span></font>
<br/><br/>
<font color="#1D1D27"><span class="gmail-lighten"><span class="gmail-difference1">You're always welcome back at {{office.name}}. <br/>Questions? Call or text us at <a href="tel:{{office.phone}}">
{{office.phone}}</span></span></font>
															</td>
														</tr>
													</table>
												</td>
												<td width="12.5%"></td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td width="100%">               
							<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
								<tr>
									<td widtdh="100%" aria-hidden="true" height="40" style="font-size: 0px; line-height: 0px; background-color: #ffffff; color: #1D1D27;">
								    &nbsp;
									</td>
								</tr>
								<tr>
								<td class="unstyle-auto-detected-links" width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 35px; padding-left: 50px; font-family: sans-serif; font-size: 14px; line-height: 20px; text-align: center; background-color: #ec6631; background-image:linear-gradient(#ec6631,#ec6631); color: #ffffff; border-radius: 0 0 20px 20px;">
									<font color="#ffffff"><span class="gmail-screen"><span class="gmail-difference">{{office.name}}<br><span class="unstyle-auto-detected-links">{{office.address.street}} {{office.address.city}}, {{office.address.state}} {{office.address.zipcode}}</span><br><span class="unstyle-auto-detected-links">{{office.phone}}</span></span></span></font>
								</td>
							</tr>
						</table>            
						<table class="layout" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
							<tr>
								<td width="100%" style="margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; padding-top: 30px; padding-right: 50px; padding-bottom: 60px; padding-left: 50px; font-family: sans-serif; font-size: 12px; line-height: 18px; text-align: center; color: #70707c;" class="content-block footer">
									<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1">You're receiving this email because you're a patient. We're excited to serve you and hope to see you soon!</span></span></font><br><br>
								<font color="#70707c"><span class="gmail-lighten"><span class="gmail-difference1"><a href="{{client.optoutUrl}}" style="color: #70707c; text-decoration: underline;">Unsubscribe</a></span></span></font>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
	    <!--[if mso]>
	    </td>
		</tr>
		</table>
	    <![endif]-->
	    </div>
	    <!--[if mso | IE]>
	    </td>
	    </tr>
	    </table>
	    <![endif]-->
    </center>
</body>
</html>
`, attachments: [], shouldFrame: false },
  sms2_2: { body: '😊 We\'ve missed you at {{office.name}}! Stay your best by ensuring you have your next appointment scheduled. Click here to choose your best day/time- {{client.registerLink}}', attachments: [] },
  'pushNext Day Seasonal': { subject: '', body: '' },
  'emailNext Day Seasonal': { subject: '', body: '', html: '', attachments: [], shouldFrame: false },
  'smsNext Day Seasonal': { body: '', attachments: [] },
};

interface MassUpdate {
  email?: Email;
  isEmail?: boolean;
  sms?: Sms;
  isSms?: boolean;
  push: Push;
  isPush?: boolean;
}

interface ComposeProps {
  setCreateState: (s: ComposeState) => void;
}

const tabs = ['1', '1_2', '2', '2_2', 'Next Day Seasonal'];
const defaultButtonTypes = mergeAll(tabs.map((t) => ({ [t]: 'sms' })));

let timeout: NodeJS.Timeout;
const Compose = ({ setCreateState }: ComposeProps) => {
  const [tab, setTab] = useState(tabs[0]);
  const [state, setState] = useState(defaultComposeState);
  const [smsError, setSMSError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState(false);
  const [pushError, setPushError] = useState(false);
  const [buttonType, setButtonType] = useState(defaultButtonTypes);
  const [template, setTemplate] = useState(null);
  const ppState = usePromise(fetchSettingsList, []);
  const templatesState = usePromise(getTemplates, {
    data: [],
  });

  console.log(smsError, emailError, pushError);

  const { features } = useSelector((s: LoginState) => ({
    features: s.login.features,
  }));

  useEffect(() => {
    if (timeout)
      clearTimeout(timeout);
    timeout = setTimeout(() => {
      setCreateState(state);
    }, 500);
  }, [state]);

  const { push, email, sms } = useMemo(() => {
    return {
      push: prop(`push${tab}` as keyof ComposeState, state),
      email: prop(`email${tab}` as keyof ComposeState, state),
      sms: prop(`sms${tab}` as keyof ComposeState, state),
    };
  }, [tab, state]);

  const iss = useMemo(() => {
    return {
      isEmail: pipe(
        filter((a) => typeof a !== 'boolean'),
        values,
        all(either(equals(null), isEmpty)),
        not
      )(email),
      isSMS: !all(isEmpty)(values(sms)),
      isPush: !all(isEmpty)(values(push)),
    };
  }, [push, email, sms]);

  useEffect(() => {
    ppState.invoke({});
    templatesState.invoke({});
  }, []);

  const actions = {
    messageMassPatch: (p: MassUpdate) => {
      const ks = Object.keys(p);
      const prop = ks.find((k) => k.indexOf('is') === -1);
      setState({
        ...state,
        [`${prop}${tab}` as keyof ComposeState]: p[prop as keyof MassUpdate],
      });
    },
    messagesPatch: (prop: string, data: Email | Sms | Push) => {
      setState({
        ...state,
        [`${prop}${tab}` as keyof ComposeState]: data,
      });
    },
  };

  const handleButtonType = (newType: string) => {
    setButtonType({
      ...buttonType,
      [tab]: newType,
    });
  };

  const clientHolders =
    filter((holder) => removePlaceholders(features, holder))(clientPlaceholders);
  const officeHolders =
    filter((holder) => removePlaceholders(features, holder))(officePlaceholders);
  const placeholders = useMemo(() => {
    return [
      { title: 'Client', placeholders: clientHolders },
      { title: 'Office', placeholders: officeHolders },
      !isEmpty(ppState.data) &&
      {
        title: 'Patient Portals',
        placeholders: ppState.data.map(({ name, newPatientPortalSettingsId }: {
          name: string;
          newPatientPortalSettingsId: number;
        }) => ({
          value: `{{patientportal.${newPatientPortalSettingsId}.url}}`,
          name,
        }))
      },

    ];
  }, [ppState.data]);

  const selectTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tId = Number(e.target.value);
    const temp = templatesState.data.data
      .find(({ id }: { id: number }) => id === tId);
    setTemplate(tId);
    setState({
      ...state,
      [`email${tab}` as keyof ComposeState]: temp.email ? {
        html: temp.email?.body?.HTML?.body || temp.email?.body?.PlainText?.body || '',
        subject: temp.email?.subject || '',
        attachments: temp.email.attachmentIds.map((id: number) => ({ attachmentId: id })),
        shouldFrame: false,
      } :
        defaultComposeState[`email${tab}` as keyof ComposeState],
      [`sms${tab}` as keyof ComposeState]: temp.sms ?
        { ...temp.sms, attachments: temp.sms.attachmentIds.map((id: number) => ({ attachmentId: id })), }
        :
        defaultComposeState[`sms${tab}` as keyof ComposeState],
      [`push${tab}` as keyof ComposeState]: temp.push ?
        { ...temp.push, attachments: temp.push.attachmentIds?.map((id: number) => ({ attachmentId: id })) || [], }
        :
        defaultComposeState[`push${tab}` as keyof ComposeState],
    });
  };

  return (
    <>
      <Box sx={{
        borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: 3,
      }}>
        <Tabs id='msgType' value={tab} onChange={(_, e) => {
          setEmailError(false);
          setPushError(false);
          setTab(e);
        }} aria-label="compose-tabs">
          {tabs.map((t) => {
            const [num, isFollowUp] = t.split('_');
            const isNan = Number.isNaN(Number(num));
            return (
              <Tab label={`${isNan ? '' : 'Message'} ${num} ${isFollowUp ? 'Follow-up' : ''}`} value={t} />
            );
          })}
        </Tabs>
      </Box>
      <div>
        <Select
          value={template}
          onChange={selectTemplate}
          displayEmpty
          renderValue={(v) => {
            if (v === null) {
              return 'Select a template';
            }
            const temp = templatesState.data.data
              .find(({ id }: { id: number }) => id === Number(v));
            return temp?.name || v;
          }}
          style={{
            marginBottom: 10,
            minWidth: 250,
          }}
        >
          {templatesState.data.data.map(({
            name, id,
          }: { name: string, id: number }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <MessageComposer
          messageType='Automation'
          features={features}
          forFeature='Spark'
          {...iss}
          email={email as Email}
          sms={sms as Sms}
          push={push as Push}
          actions={actions}
          placeholders={placeholders}
          setSMSError={setSMSError}
          setEmailError={setEmailError}
          setPushError={setPushError}
          defaultButtonType={buttonType[tab]}
          setDefaultButtonType={handleButtonType}
          forceNonHtml={true}
        />
      </div>
    </>
  );
};

interface SparkSettingsProps {
  open: boolean;
  reload: (close?: boolean) => void;
  onClose: () => void;
  loading: boolean;
}

interface ProType {
  types: AppointmentType[];
  pros: Professional[];
}

const SparkSettings = ({
  open, onClose, reload, loading,
}: SparkSettingsProps) => {
  const classes = useStyles();
  const [selectedTypes, setSelectedTypes] = useState<AppointmentType[]>(null);
  const [createState, setCreateState] = useState<ComposeState>(defaultComposeState);
  const proTypesState = usePromise<void, ProType>(getAllProTypes, { types: [], pros: [] });
  const enabledState = usePromise<void, boolean>(checkForMasterList, false);
  const killState = usePromise<void, boolean>(killSpark, false);
  const updateState = usePromise<number, void>(updateSpark, null);
  const apptTypePathState = usePromise<
    {types: AppointmentType[]}, AppointmentType[]
  >(getFromPath, []);
  const saveAppointmentTypeState = usePromise<SaveToPathProps, void[]>(saveToPath, []);
  const toggleState = usePromise<{enable: boolean}, void>(toggleSparkIt, null);

  const { officeId } = useSelector((s: LoginState) => ({
    officeId: s.login.office.id,
  }));

  const createSparkState = usePromise<ComposeState, void>(createSpark(officeId), null);

  useEffect(() => {
    if (open) {
      proTypesState.invoke().then(({ types }) => {
        apptTypePathState.invoke({ types }).then((data) => {
          setSelectedTypes(data.filter(identity));
        });
      });
      enabledState.invoke();
    }
  }, [open]);

  const busy = useMemo(() => {
    return proTypesState.loading || apptTypePathState.loading ||
      saveAppointmentTypeState.loading || toggleState.loading || createSparkState.loading ||
      loading;
  }, [
    proTypesState.loading, apptTypePathState.loading,
    saveAppointmentTypeState.loading, toggleState.loading, createSparkState.loading,
    loading,
  ]);

  const onSave = async () => {
    await saveAppointmentTypeState.invoke({
      news: without(apptTypePathState.data, selectedTypes),
      olds: without(selectedTypes, apptTypePathState.data),
    });
    if (!enabledState.data) {
      try {
        await createSparkState.invoke(createState);
        reload();
        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const toggleSpark = (enable: boolean) => () => {
    toggleState.invoke({ enable }).then(() => {
      reload();
    });
  };

  return (
    <Modal
      title="Spark Campaign Settings"
      size="md"
      open={open}
      onClose={onClose}
      className='sked-test-spark-settings-modal'
      buttons={[
        <Grid marginRight={1} display="flex">
          {busy && <CircularProgress size={20} />}
        </Grid>,
        <HeaderButton
          title={enabledState.data ? 'Save' : 'Create'}
          onClick={onSave}
          disabled={busy}
          color='primary'
          className='sked-test-spark-settings-save-modal'
        />
      ]}
    >
      <div className={classes.modalContainer}>
        {enabledState.data &&
          'Spark is enabled for this office!'}
        <h2>Appointment Type Folder</h2>
        <p>
           Select which appointment types are placed in the spark appointment
           type folder. This can be used with the <i>Last Appointment</i> client
           filter in automations.
        </p>
        <TypeSelector
          types={proTypesState.data?.types}
          selected={selectedTypes ? selectedTypes.map(({ id }) => id) : null}
          professionals={proTypesState.data?.pros}
          onChange={(ts: number[]) => {
            const types = ts.map((id) => {
              return proTypesState.data.types.find((t) => t.id === id);
            });
            setSelectedTypes(types);
          }}
        />
        {!enabledState.data &&
          <>
            <h2>First Year Messages</h2>
            <Compose setCreateState={setCreateState} />
          </>}
        {enabledState.data &&
          <>
            <h2>Spark Enable/Disable</h2>
            <Button
              color='primary'
              variant='contained'
              onClick={toggleSpark(true)}
              style={{ marginRight: 10 }}
            >
             Enable
            </Button>
            <Button
              color='error'
              variant='contained'
              onClick={toggleSpark(false)}
            >
             Disable
            </Button>
          </>}
        {enabledState.data &&
          <>
            <h2 style={{ marginBottom: 0 }}>Update Spark</h2>
            <i style={{ marginBottom: 5 }}>
              This will update all spark automations to the latest version.
              <br/>
              <b>This is update any spark automations including ones that you've updated.</b>
            </i>
            <br/>
            <Button
              color='primary'
              variant='contained'
              onClick={() => updateState.invoke(officeId).then(() => reload(true))}
              disabled={updateState.loading}
            >
              {updateState.loading ? 'Updating...' : 'Update'}
            </Button>
          </>}
        {enabledState.data &&
          <>
            <h2 style={{ marginBottom: 0 }}>Delete Spark</h2>
            <i style={{ marginBottom: 5 }}>
              There is no popup to verify this action. Pushing this button deletes <b>everything</b> in Spark.
            </i>
            <br/>
            <Button
              color='error'
              variant='contained'
              onClick={() => killState.invoke().then(() => reload(true))}
              disabled={killState.loading}
            >
              {killState.loading ? 'Killing them all...' : 'Delete'}
            </Button>
          </>}
      </div>
    </Modal>
  );
};

export default SparkSettings;
