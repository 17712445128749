import {
  ZonedDateTime,
  ZoneId,
  ZoneOffset,
  DateTimeFormatter,
  LocalDate,
  LocalDateTime,
  LocalTime,
  ChronoUnit,
  ChronoField,
} from '@js-joda/core';
import '@js-joda/timezone';
import {
  Locale
} from '@js-joda/locale_en-us';
import {
  cond,
  equals,
  T,
} from 'ramda';


// parse :: String -> Either 't' 'd' 'dt' -> Either LocalDateTime LocalTime
export const parse = (d, type = 'dt') => {
  try {
    if (type === 't') {
      return LocalTime.parse(d);
    } else if (type === 'd') {
      return LocalDate.parse(d);
    } else {
      return LocalDateTime.parse(d);
    }
  } catch (e) {
    console.error(e);
    return d;
  }
};

// utcParse :: DateTimeString -> ZonedDateTime
export const utcParse = (d) => ZonedDateTime.parse(d);

// tzParse :: DateTimeString -> TimeZoneString -> ZonedDateTime
export const tzParse = (d, tz) => ZonedDateTime.parse(d).withZoneSameInstant(ZoneId.of(tz));

// tzChange :: ZonedDateTime -> TimeZoneString -> ZonedDateTime
export const tzChange = (d, tz) => d.withZoneSameInstant(ZoneId.of(tz));

// format :: DateTime -> FormatString -> String
export const format = (d, f) => {
  if (typeof d !== 'object') {
    return d;
  }
  return d.format(DateTimeFormatter.ofPattern(f).withLocale(Locale.US));
};

// tzFormat :: ZonedDatetime -> TimeZoneString -> FormatString -> String
export const tzFormat = (d, tz, f) => d.withZoneSameInstant(ZoneId.of(tz))
  .format(DateTimeFormatter.ofPattern(f).withLocale(Locale.US));

// tzParseFormat :: DateTimeString -> TimeZoneString -> FormatString -> String
export const tzParseFormat = (d, tz, f) => {
  try {
    return ZonedDateTime.parse(d)
      .withZoneSameInstant(ZoneId.of(tz))
      .format(DateTimeFormatter.ofPattern(f).withLocale(Locale.US));
  } catch (e) {
    return 'Parse error';
  }
};

// tzParseFormat :: DateTimeString -> TimeZoneString -> FormatString -> String
export const localParseFormat = (d, tz, f) => {
  try {
    return LocalDateTime.parse(d)
      .format(DateTimeFormatter.ofPattern(f).withLocale(Locale.US));
  } catch (e) {
    return 'Parse error';
  }
};

export const now = (type, tz = null) => {
  switch (type) {
    case 'date': {
      return LocalDate.now();
    }
    case 'datetime': {
      return LocalDateTime.now();
    }
    case 'tz':
      if (tz) {
        return ZonedDateTime.now(ZoneId.of(tz));
      } else {
        return ZonedDateTime.now();
      }
    case 'time': {
      return LocalTime.now();
    }
    default: {
      return LocalDateTime.now();
    }
  }
};

// this is only because LocalTime.parse expects everything in the format: 'HH:mm:ss'
// timeConvert :: [HourString, MinuteString, AmPmString] => StandardTimeString
export const timeConvert = (hour) => {
  const [h, m, a = 'am'] = hour;
  const n = Number(h);
  if (a.toLowerCase() === 'pm') {
    return n === 12 ?
      12 + ':' + m :
      (n + 12) + ':' + m;
  } else {
    if (n < 10) {
      return '0' + Number(h) + ':' + m;
    } else if (n === 12) {
      return '23:59';
    } else {
      return h >= 24 ?
        23 + ':' + m :
        h + ':' + m;
    }
  }
};

export const isBetween = (d1, d2, d3, interval) => {
  switch (interval) {
    case '[]': {
      return ((d1.equals(d2) || d1.isAfter(d2)) && (d1.equals(d3) || d1.isBefore(d3)));
    }
    case '[)': {
      return ((d1.equals(d2) || d1.isAfter(d2)) && d1.isBefore(d3));
    }
    case '(]': {
      return (d1.isAfter(d2) && (d1.equals(d3) || d1.isBefore(d3)));
    }
    case '()': {
      return (d1.isAfter(d2) && d1.isBefore(d3));
    }
    default: {
      return (d1.isAfter(d2) && d1.isBefore(d3));
    }
  }
};

/*
   Adjusts a time to the closest increment of `interval`. For example, if you have
   12:13 with an interval of 15, it would output 12:15.
*/
export const adjustToInterval = (d, interval) => {
  const newMinute = ((interval - (d.minute() % interval)) + d.minute());
  return newMinute === 60 ?
    LocalTime.of(d.hour() + 1, 0)
    :
    LocalTime.of(d.hour(), newMinute);
};

// format to yyyy-MM-ddTHH:mm:ssZ
export const formatStandard = (d, includeZ = true) => {
  const str = d.withZoneSameInstant(ZoneOffset.UTC).toString();
  return includeZ ? str : str.replace('Z', '');
};

export const startOf = (d, prop) => {
  return cond([
    [equals('year'), () =>
      d.withMonth(1).withDayOfMonth(1).withHour(0).withMinute(0).withSecond(0).withNano(0)],
    [equals('dyear'), () =>
      d.withMonth(1).withDayOfMonth(1)],
    [equals('month'), () =>
      d.withDayOfMonth(1).withHour(0).withMinute(0).withSecond(0).withNano(0)],
    [equals('dmonth'), () =>
      d.withDayOfMonth(1)],
    [equals('week'), () => {
      const day = d.dayOfWeek().value();
      return d.withDayOfMonth(d.dayOfMonth() - day).withHour(0).withMinute(0).withSecond(0).withNano(0);
    }],
    [equals('dweek'), () => {
      const day = d.dayOfWeek().value();
      return d.withDayOfMonth(d.dayOfMonth() - day);
    }],
    [equals('day'), () =>
      d.withHour(0).withMinute(0).withSecond(0).withNano(0)],
    [equals('hour'), () =>
      d.withMinute(0).withSecond(0).withNano(0)],
    [equals('minute'), () =>
      d.withSecond(0).withNano(0)],
    [equals('second'), () =>
      d.withNano(0)],
    [T, () =>
      d.withMonth(1).withDayOfMonth(1).withHour(0).withMinute(0).withSecond(0).withNano(0)]
  ])(prop);
};

export const endOf = (d, prop) => {
  return cond([
    [equals('year'), () =>
      d.withMonth(12)
        .withDayOfMonth(d.range(ChronoField.DAY_OF_MONTH)._maxLargest)
        .withHour(23)
        .withMinute(59)
        .withSecond(59)
        .withNano(999999999)],
    [equals('dyear'), () =>
      d.withMonth(12).withDayOfMonth(d.range(ChronoField.DAY_OF_MONTH)._maxLargest)],
    [equals('month'), () =>
      d.withDayOfMonth(d.range(ChronoField.DAY_OF_MONTH)._maxLargest)
        .withHour(23)
        .withMinute(59)
        .withSecond(59)
        .withNano(999999999)],
    [equals('dmonth'), () =>
      d.withDayOfMonth(d.range(ChronoField.DAY_OF_MONTH)._maxLargest)],
    [equals('week'), () => {
      const day = d.dayOfWeek().value();
      return d.withDayOfMonth(d.dayOfMonth() - day)
        .withHour(59)
        .withMinute(59)
        .withSecond(59)
        .withNano(999999999);
    }],
    [equals('dweek'), () => {
      const day = d.dayOfWeek().value();
      return d.withDayOfMonth(d.dayOfMonth() - day);
    }],
    [equals('day'), () =>
      d.withHour(23).withMinute(59).withSecond(59).withNano(999999999)],
    [equals('hour'), () =>
      d.withMinute(59).withSecond(59).withNano(999999999)],
    [equals('minute'), () =>
      d.withSecond(59).withNano(999999999)],
    [equals('second'), () =>
      d.withNano(999999999)],
    [T, () =>
      d.withMonth(12).withDayOfMonth(1).withHour(23).withMinute(59).withSecond(59).withNano(999999999)]
  ])(prop);
};

// doesn't use joda but can still validate a date
export const isValidDate = (s) => {
  const bits = s.split('/');
  const d = new Date(bits[2], bits[0] - 1, bits[1]);
  return d && (d.getMonth() + 1) == bits[0]; // eslint-disable-line eqeqeq
};

export const untilVerbose = (d1, d2, brief = false) => {
  const differences = [
    {
      value: () => d1.until(d2, ChronoUnit.ERAS),
      unit: 'era',
    },
    {
      value: () => d1.until(d2, ChronoUnit.YEARS),
      unit: 'year',
      brief: 'yr',
    },
    {
      value: () => d1.until(d2, ChronoUnit.MONTHS),
      unit: 'month',
      brief: 'mon',
    },
    {
      value: () => d1.until(d2, ChronoUnit.DAYS),
      unit: 'day',
      brief: 'day',
    },
    {
      value: () => d1.until(d2, ChronoUnit.HOURS),
      unit: 'hour',
      brief: 'hr',
    },
    {
      value: () => d1.until(d2, ChronoUnit.MINUTES),
      unit: 'minute',
      brief: 'min',
    },
    {
      value: () => d1.until(d2, ChronoUnit.SECONDS),
      unit: 'second',
      brief: 'sec',
    },
    {
      value: () => d1.until(d2, ChronoUnit.MICROS),
      unit: 'micro',
      brief: 'µ',
    },
    {
      value: () => d1.until(d2, ChronoUnit.NANOS),
      unit: 'nano',
      brief: 'n',
    },
  ];
  /* for loops let me break without doing all the calculations.
     if you try to compare two datetimes that are really far apart,
     i.e. years apart, the seconds, micros, or nanos will go too small
     and overflow the JS int type.
  */
  let closest;
  for (let i = 0; i < differences.length; i++) {
    const d = differences[i];
    const val = d.value();
    if (val !== 0) {
      closest = { value: val, unit: d.unit, brief: d.brief };
      break;
    }
  }
  const {
    value,
    unit,
  } = closest;
  if (value > 0) {
    if (brief) {
      return value + ' ' + closest.brief + (value > 1 ? 's' : '');
    }
    return value + ' ' + unit + (value > 1 ? 's' : '') + ' until';
  } else {
    if (unit === 'nano' || unit === 'micro') {
      return 'just now';
    }
    if (unit === 'second') {
      return 'a few seconds ago';
    }
    if (brief) {
      return (value * -1) + ' ' + closest.brief + (value * -1 > 1 ? 's' : '');
    }
    return (value * -1) + ' ' + unit + (value * -1 > 1 ? 's' : '') + ' ago';
  }
};

export const toISOUTCString = (date = '2021-01-01', time = '13:30', zone = 'America/New_York') => {
  try {
    return ZonedDateTime
      .of(
        LocalDate.parse(date),
        LocalTime.parse(time),
        ZoneId.of(zone)
      )
      .withZoneSameInstant(ZoneOffset.UTC)
      .toString();
  } catch (e) {
    console.log(e);
    console.log('error in parsing date', { date, time, zone });
    return '';
  }
};

export const decipherTime = time => untilVerbose(now('tz'), ZonedDateTime.parse(time));

const english_ordinal_rules = new Intl.PluralRules(
  'en', {
    type: 'ordinal'
  }
);
const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
};

// ordinal :: number -> string
export const ordinal = (number) => {
  const category = english_ordinal_rules.select(number);
  return suffixes[category];
};
