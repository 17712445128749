import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';

import { Card, Typography, Grid } from '@mui/material';
import { useSelector } from '../../reducers';

import Help from '../Help/Help.component';
import HeaderMore from '../HeaderMore/HeaderMore.component';

import { patch } from '../../routes/Login/login.actions';
import { useStyles } from './pageHeader.styles';

interface Icon extends JSX.Element {
  type: {
    name: string;
  };
}

type BreakPoint = {
  width: number;
  mobileItems: number[];
}

type PageHeaderProps = {
  title?: string;
  pageId?: string;
  leftIcons?: Element[] | JSX.Element[];
  rightIcons?: Element[] | JSX.Element[];
  breakPoints?: BreakPoint[];
  onlyIconsWidth?: number;
  smallTabsWidth?: number;
  updateSize?: number;
  getWidth?: (value: number) => void;
}

let lastCallTime: number;

const PageHeader = ({
  title, pageId, leftIcons, rightIcons, breakPoints,
  onlyIconsWidth, updateSize, getWidth, smallTabsWidth,
}: PageHeaderProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(480);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [mobileIcons, setMobileIcons] = useState<JSX.Element[]>([]);

  const desktopOpen = useSelector(state => state.login.desktopOpen);

  const updateWidth = () => {
    const now = new Date().getTime();
    if (!lastCallTime || (now - lastCallTime >= 200)) {
      const headerWidth = ref.current?.offsetWidth;
      setWidth(headerWidth);
      getWidth?.(headerWidth);
      lastCallTime = now;
    }
  };

  const mobileItemsWithTabs = useMemo(() => {
    if (!showMore || !mobileIcons?.length) {
      return null;
    } 
    const items = mobileIcons.map((item, i) => {
      if ((item as Icon).type?.name === 'HeaderTabs') {
        return React.cloneElement(item as React.ReactElement, { isSmall: true, key: i });
      }
      return item;
    });
    return <HeaderMore items={items} />;
  }, [showMore, mobileIcons]);

  useEffect(() => {
    if (!breakPoints) {
      setLeft(leftIcons);
      setRight(rightIcons);
      return;
    }
    const sorted = R.sortBy(R.prop('width'), breakPoints);
    const breakPoint = sorted.find(bp => bp.width >= width);
    if (breakPoint) {
      const leftSize = leftIcons?.length || 0;
      const rightSize = rightIcons?.length || 0;
      const mobileItems = [] as unknown as object[];
      breakPoint.mobileItems.forEach(item => {
        if (item < leftSize) {
          const icon = leftIcons[item];
          mobileItems.push(icon as unknown as object);
        } else if (item - leftSize < rightSize) {
          const icon = rightIcons[item - leftSize];
          mobileItems.push(icon as unknown as object);
        }
      });
      const leftRest = R.without(mobileItems, leftIcons || []);
      const rightRest = R.without(mobileItems, rightIcons || []);
      setLeft(leftRest as unknown as | JSX.Element[]);
      setRight(rightRest as unknown as JSX.Element[]);
      setMobileIcons(mobileItems as unknown as JSX.Element[]);
      setShowMore(!!mobileItems.length);
    } else {
      setLeft(leftIcons);
      setRight(rightIcons);
      setMobileIcons([]);
      setShowMore(false);
    }
  }, [breakPoints, width, rightIcons, leftIcons]);

  const getItems = (items: JSX.Element[]) => {
    if (onlyIconsWidth >= width) {
      return items?.map((item, i) => {
        if (!item) {
          return null;
        }
        return React.cloneElement(item as React.ReactElement, { onlyIcon: true, isSmall: true, key: i });
      });
    }
    if (smallTabsWidth >= width) {
      return items?.map((item, i) => {
        if (!item) {
          return null;
        }
        return React.cloneElement(item as React.ReactElement, { isSmall: true, key: i });
      });
    }
    return items;
  };

  const leftComponent = useMemo(() => {
    return getItems(left);
  }, [left, onlyIconsWidth, smallTabsWidth, width]);
  
  const rightComponent = useMemo(() => {
    return getItems(right);
  }, [right, onlyIconsWidth, smallTabsWidth, width]);

  useEffect(() => {
    function handleResize() {
      updateWidth();
      dispatch(patch({ pageHeaderHeight: ref.current.clientHeight }));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    updateWidth();
  }, [desktopOpen, updateSize]);

  return (
    <Card className={classes.root} ref={ref}>
      <Grid display="flex" alignItems="center">
        <Typography className={classes.title} variant='h4'>{title}</Typography>
        {pageId ? <Help pageId={pageId} pageTitle={title} /> : <>&nbsp;</>}
      </Grid>
      &nbsp;
      <Grid
        className={classes.icons}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
      >
        <Grid display="flex" alignItems="center">
          {leftComponent}
        </Grid>
        <Grid display="flex" alignItems="center">
          {rightComponent}
          {mobileItemsWithTabs}
        </Grid>
      </Grid>
    </Card>
  );
};

export default PageHeader;
