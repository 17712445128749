import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Tooltip } from '@mui/material';

import { ItemType } from './sectionItems';

import { useStyles } from './section.styles';

type SectionProps = {
  menuItem: ItemType;
  onClose?: () => void;
  noneEHR?: boolean;
  hasSmsNotification?: boolean;
  hasNotification?: boolean;
  isSpark?: boolean;
  onSwitchToOldLook?: () => void;
}

const Section = ({
  menuItem, onClose, isSpark, noneEHR, onSwitchToOldLook,
  hasSmsNotification, hasNotification
}: SectionProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { title, items } = menuItem;

  const handleClick = ({
    link, switchToOldLook
  }: {
    link?: string, adminOrAgency?: boolean, switchToOldLook?: boolean
  }) => {
    if (switchToOldLook) {
      onSwitchToOldLook?.();
      onClose?.();
      return;
    }
    if (link) {
      navigate(link);
      onClose?.();
    }
  };

  if (!items.length || (isSpark && menuItem.hideSpark)) {
    return null;
  }

  return (
    <Grid className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      {items.map(item => (
        <Tooltip
          title={item.description || ''}
          arrow
          enterDelay={800}
          disableInteractive
          key={item.title}
        >
          <Grid
            className={`${classes.item} ${item.onlyAdmin ? classes.onlyAdmin : ''} ${item.className || ''}`}
            onClick={() => handleClick(item)}
          >
            {!!item.title && (
              <Typography className={classes.itemTitle}>
                {(noneEHR && item.noneEHRTitle) ? item.noneEHRTitle : item.title}
                {(hasSmsNotification && item.showSmsNotification) && <div className={classes.badge} />}
                {(hasNotification && item.showNotification) && <div className={classes.badge} />}
              </Typography>
            )}
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default Section;
