import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress,
  MenuItem,
  IconButton,
  Checkbox,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Menu,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  includes, always, T, propOr, pipe, cond, has, update,
  remove, adjust, evolve, assoc, filter,
  isEmpty, map, not, nth, pathOr, equals
} from 'ramda';
import { Row } from '../../components/PageHeader';
import Modal from '../../components/Modal/Modal.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { useTitle } from '../../services/useTitle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PasteIcon from '@mui/icons-material/Assignment';
import UndoIcon from '@mui/icons-material/Undo';
import * as rawActions from './stop-managers.actions.jsx';
import CreateStopManager from './components/create-stop-manager.component.jsx';
import {
  tzParseFormat,
} from '../../services/joda.js';
import { usePromise } from '../../services/promise.hook';
import api from '../../services/api.js';


const useStyles = makeStyles(() => ({
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  pageArrows: {
    marginLeft: '0px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  headerRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  progessSection: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '20px'
  },
  // progessHeader: {
  //   display: 'flex',
  //   flexDirection: 'row'
  // },
  stepsContainer: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
}));


const fixLength = (str) => {
  if (str && str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

const prettyTriggerNames = cond([
  [has('OnDate'), always('On Date')],
  [has('AfterDate'), always('After Date')],
  [has('AfterDateRepeat'), always('Repeat Date')],
  [has('AfterXAppointments'), always('Upon Arrival')],
  [has('AfterXAppointmentsRepeat'), always('Repeat Arrival')],
  [T, always('Unknown')],
]);

const deleteStop = (id) => api.delete(`stopmanager/template/${id}`, { data: {} });
const RowContainer = ({
  stopManager,
  tz,
  copyStop,
  removeStop,
  edit,
  select,
  isTemplate,
}) => {
  const {
    message,
    id,
    trigger,
    created,
    stopType,
  } = stopManager;

  const [selected, setSelected] = React.useState(false);
  const stopState = usePromise(deleteStop, {});

  React.useEffect(() => {
    setSelected(propOr(false, 'selected', stopManager));
  }, [stopManager]);

  const onRemoveStop = () => {
    stopState.invoke(id).then(() => {
      removeStop();
    });
  };

  return (
    <TableRow key={id}>
      <TableCell className="TableCell-name">
        <Checkbox
          checked={Boolean(selected)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelected(checked);
            select(checked);
          }}
        />
      </TableCell>
      <TableCell>
        {stopType}
      </TableCell>
      <TableCell>
        {fixLength(message)}
      </TableCell>
      <TableCell>
        {prettyTriggerNames(trigger)}
      </TableCell>
      <TableCell>
        {tzParseFormat(created, tz, 'MM/dd/yyyy h:mm a')}
      </TableCell>
      {isTemplate ?
        <TableCell>
          <IconButton
            onClick={() => edit(stopManager)} >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => copyStop(stopManager)} >
            <FileCopyIcon />
          </IconButton>
          {stopState.loading ?
            <CircularProgress />
            :
            <IconButton
              onClick={onRemoveStop}>
              <DeleteIcon />
            </IconButton>}
        </TableCell>
        :
        <TableCell>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton
              onClick={() => edit(stopManager)} >
              <EditIcon />
            </IconButton>
          </div>
        </TableCell>}
    </TableRow>
  );
};

const updateGroupPost = ({ groupId, name }) => api.put(`stopmanager/template/group/${groupId}`, { name });
const deleteGroupDelete = ({ groupId }) => api.delete(`stopmanager/template/group/${groupId}`, { data: {} });
export const GroupSection = ({
  group, tz, patch, editGroupPatch,
  removeGroupPatch, removeStopPatch,
  selectStopPatch, removeSelected,
  selectAllStops, busy, copyGroup,
  copyStop, hasCopied, pasteStopsToGroup,
  isTemplate = true,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [groupName, setGroupName] = React.useState(group.name);
  const [isEdit, setIsEdit] = React.useState(group.isEdit);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const updateGroup = usePromise(updateGroupPost, {});
  const deleteGroup = usePromise(deleteGroupDelete, {});

  const editBox = React.useRef();

  React.useEffect(() => {
    const s = filter(propOr(false, 'selected'), group.stops);
    setSelected(s);
    setIsEdit(group.isEdit);
    setGroupName(group.name);
  }, [group]);

  React.useEffect(() => {
    if (editBox.current) {
      editBox.current.focus();
      editBox.current.select();
    }
  }, [isEdit]);

  const editStop = (stop) => {
    patch({
      state: 'EDIT',
      selectedGroup: group,
      selectedStopManager: stop,
    });
  };
  const saveGroup = () => {
    const data = { ...group, name: groupName, isEdit: false };
    updateGroup.invoke(data).then(() => {
      editGroupPatch(data);
      setIsEdit(false);
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      saveGroup();
    }
  };
  return (
    <Paper key={group.id} className={classes.progessSection}>
      <Row style={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={() => {
            setOpen(!open);
          }}>
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
            style={{ height: '50px', width: '50px', marginLeft: '-20px' }}>
            {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
          {isEdit ?
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              ref={editBox}
              onKeyPress={onKeyPress}
              style={{ width: '100%' }}
            />
            :
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}>
              {group.name}
            </div>}
        </div>
        <div>
          {isEdit &&
            <div style={{ width: '100px' }}>
              {updateGroup.loading ?
                <CircularProgress />
                :
                <Tooltip arrow title='Save Group Name'>
                  <IconButton
                    onClick={saveGroup}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>}
              <Tooltip arrow title='Cancel Edit'>
                <IconButton
                  onClick={() => {
                    setIsEdit(false);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>}

          {!isEdit && isTemplate &&
            <div style={{ width: 'max-content' }}>
              <Tooltip arrow title='Add Alert Template'>
                <IconButton onClick={() => {
                  setAnchorEl(null);
                  patch({
                    state: 'EDIT',
                    selectedGroup: group,
                  });
                }}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              {hasCopied &&
                <Tooltip arrow title='Paste Copied Alerts'>
                  <IconButton onClick={pasteStopsToGroup}>
                    <PasteIcon />
                  </IconButton>
                </Tooltip>}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            </div>}
          <Menu
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={() => {
              setIsEdit(true);
              setAnchorEl(null);
            }}>
              <ListItemIcon>
                <EditIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Edit Group Name' />
            </MenuItem>
            <MenuItem onClick={() => {
              setAnchorEl(null);
              copyGroup();
            }}>
              <ListItemIcon>
                <FileCopyIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Copy Group' />
            </MenuItem>
            <MenuItem onClick={() => {
              deleteGroup.invoke(group);
              removeGroupPatch();
              setAnchorEl(null);
            }}>
              <ListItemIcon>
                <DeleteIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Delete Group' />
            </MenuItem>
          </Menu>
        </div>
      </Row>
      <div style={{ maxHeight: (open ? (group.stops.length * 200) + 'px' : 0) }} className={classes.stepsContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={Boolean(selectAll)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setSelectAll(checked);
                      selectAllStops(checked);
                    }}
                  />
                  {busy && <CircularProgress />}
                  {!isEmpty(selected) && isTemplate &&
                    <IconButton
                      onClick={removeSelected}>
                      <DeleteIcon />
                    </IconButton>}
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Message
                </TableCell>
                <TableCell>
                  Trigger
                </TableCell>
                <TableCell>
                  Created
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group.stops.map((s, index) =>
                <RowContainer
                  key={s.id}
                  stopManager={s}
                  tz={tz}
                  edit={editStop}
                  removeStop={() => removeStopPatch(index)}
                  select={(selected) => selectStopPatch(index, selected)}
                  copyStop={copyStop}
                  isTemplate={isTemplate}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

const removeStops = (stops) => Promise.all(stops.map(({ id }) => api.delete(`stopmanager/template/${id}`)));

export const StopSign = ({
  size = 'medium',
  style,
}) => {
  const biggieBoi = cond([
    [equals('small'), always(20)],
    [equals('almost-small'), always(25)],
    [equals('medium'), always(40)],
  ])(size);
  return (
    <svg
      style={style}
      height={biggieBoi}
      width={biggieBoi}
      viewBox='0 0 60 60'
      fill="none"
      align="center"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        points="20 6.5, 40 6.5, 54.14 20, 54.14 40,40 54.14, 20 54.14, 6.5 40, 6.5 20"
        fill="red"
        stroke="black"
        strokeWidth="2" />
      <text
        fill="#ffffff"
        fontSize="16"
        fontFamily="Verdana"
        x="11"
        y="36">
        STOP
      </text>
    </svg>
  );
};

export const TaskSign = ({
  style,
  size = 'medium',
}) => {
  const biggieBoi = cond([
    [equals('small'), always(20)],
    [equals('almost-small'), always(25)],
    [equals('medium'), always(40)],
  ])(size);
  return (
    <svg
      style={style}
      height={biggieBoi}
      width={biggieBoi}
      viewBox='0 0 60 60'
      fill="none"
      align="center"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        points="5,55 55,55 30,5"
        fill='yellow'
        stroke='black'
        strokeWidth="2"
      />
      <text
        fill="black"
        fontSize="35"
        fontFamily="Verdana"
        x="26"
        y="45">
        !
      </text>
    </svg>
  );
};

const StopManagerTemplateDialog = ({ open = null, onClose = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  useTitle('Alerts');

  const removeStopsState = usePromise(removeStops, []);

  const {
    busy,
    state,
    stopManagers,
    selectedStopManager,
    office,
    selectedGroup,
    copiedStops,
    previousCopy,
  } = useSelector((state) => ({
    ...state.stopManagers,
    office: state.login.office,
    hasFeature: includes('StopManager', pathOr([], ['login', 'features'], state))
  }));

  React.useEffect(() => {
    if (open)
      actions.getStopManagers({ page: 1 });
  }, [open]);

  const {
    data,
  } = stopManagers;

  const editGroupPatch = (idx) => (d) => {
    actions.stopsPatch({
      stopManagers: {
        ...stopManagers,
        data: update(idx, d, data),
      }
    });
  };
  const removeGroupPatch = (idx) => () => {
    actions.stopsPatch({
      stopManagers: {
        ...stopManagers,
        data: remove(idx, 1, data),
      }
    });
  };
  const removeStopPatch = (idx) => (i) => {
    actions.stopsPatch({
      stopManagers: {
        ...stopManagers,
        data: adjust(idx, evolve({
          stops: remove(i, 1),
        }), data),
      }
    });
  };
  const selectStopPatch = (idx) => (i, selected) => {
    actions.stopsPatch({
      stopManagers: {
        ...stopManagers,
        data: adjust(idx, evolve({
          stops: adjust(i, assoc('selected', selected)),
        }), data),
      }
    });
  };
  const selectAllStops = (idx) => (selected) => {
    actions.stopsPatch({
      stopManagers: {
        ...stopManagers,
        data: adjust(idx, evolve({
          stops: map(assoc('selected', selected)),
        }), data),
      }
    });
  };
  const removeSelected = (idx) => () => {
    const stopsToRemove = pipe(nth(idx), propOr([], 'stops'), filter(propOr(false, 'selected')))(data);
    removeStopsState.invoke(stopsToRemove);
    actions.stopsPatch({
      stopManagers: {
        ...stopManagers,
        data: adjust(idx, evolve({
          stops: filter(pipe(propOr(false, 'selected'), not)),
        }), data),
      }
    });
  };
  const copyGroup = (idx) => () => {
    const g = data[idx];
    actions.copyGroup(g);
  };
  const copyStop = (stop) => {
    // eslint-disable-next-line valid-typeof
    const stops = typeof stop === 'array' ? stop : [stop];
    actions.stopsPatch({
      copiedStops: stops,
      previousCopy: copiedStops,
    });
  };
  const pasteStopsToGroup = (group, idx) => () => {
    actions.copyStopsToGroup(group, idx, copiedStops);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Alert Template Groups'
      className='sked-test-front-desk-add-templates-modal'
      buttons={[
        <HeaderButton
          borderSolid
          onClick={() => actions.newGroup()}
          className='sked-test-front-desk-add-templates-modal-button-add-group'
          title='Add group'
          Icon={AddIcon}
          iconStyle={{ marginRight: -8 }}
        />
      ]}
    >
      <div>
        {/* <HelpDialog */}
        {/*   pageId={ehrSystem === 'None' ? "clients-tab-sas" : "clients-tab"} */}
        {/*   handleClose={helpState.handleClose} */}
        {/*   open={helpState.isOpen} /> */}
        <Row className={classes.headerRow}>
          <div style={{
            width: '165px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
            {!isEmpty(copiedStops) &&
              <Tooltip arrow title='Undo Copy'>
                <IconButton
                  variant='contained'
                  onClick={() => {
                    actions.stopsPatch({
                      copiedStops: previousCopy,
                      previousCopy: [],
                    });
                  }}
                >
                  <UndoIcon />
                </IconButton>
              </Tooltip>}
          </div>
        </Row>
        {busy && <CircularProgress />}
        <div>
          <CreateStopManager
            open={state === 'EDIT'}
            onClose={() => actions.stopsPatch({
              state: 'LIST',
              selectedStopManager: {},
              selectedGroup: {},
            })}
            isTemplate={true}
            stopManager={selectedStopManager}
            group={selectedGroup}
            save={actions.saveStop}
          />
          {data.map((group, idx) => (
            <GroupSection
              key={group.id}
              group={group}
              tz={office.timezone}
              patch={actions.stopsPatch}
              busy={removeStopsState.loading}
              hasCopied={!isEmpty(copiedStops)}
              editGroupPatch={editGroupPatch(idx)}
              removeGroupPatch={removeGroupPatch(idx)}
              removeStopPatch={removeStopPatch(idx)}
              selectStopPatch={selectStopPatch(idx)}
              selectAllStops={selectAllStops(idx)}
              removeSelected={removeSelected(idx)}
              copyGroup={copyGroup(idx)}
              copyStop={copyStop}
              pasteStopsToGroup={pasteStopsToGroup(group, idx)}
            />
          ))}
          {/* <div className={classes.pageControls}> */}
          {/*   <div className={classes.pageArrows}> */}
          {/*     <div style={{marginRight: '5px'}}> */}
          {/*       {outputLocation(page, perPage, totalCount, data.length)} */}
          {/*     </div> */}
          {/*     <IconButton */}
          {/*       disabled={page === 1} */}
          {/*       onClick={addPage(-1)} > */}
          {/*       <ChevronLeftIcon /> */}
          {/*     </IconButton> */}
          {/*     <IconButton */}
          {/*       disabled={page === totalPages} */}
          {/*       onClick={addPage(1)} > */}
          {/*       <ChevronRightIcon /> */}
          {/*     </IconButton> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default StopManagerTemplateDialog;
