import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    borderRadius: 6,
    boxShadow: '-2px 2px 5px 0px rgba(0,0,0,0.12)',
    border: '1px solid rgba(0,0,0,0.1)',
    padding: 4,
    cursor: 'pointer',
    background: '#FFFFFF',

    '& td': {
      width: '20px !important',
      border: 'none',
      display: 'flex',
      padding: '6px 12px',
    }
  },
  apptName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.85)',
  },
  proName: {
    marginLeft: 5,
    lineHeight: '14px',
    height: 15,
    overflow: 'hidden',
  },
  proIcon: {
    width: '16px !important',
    height: '16px !important',
  },
  empty: {
    color: 'rgba(0, 0, 0, 0.4)',
    padding: 10,
    fontWeight: 500,
  },
  time: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  statusRed: {
    color: theme.palette.warning.main,
    marginLeft: 5,
  },
  statusGreen: {
    color: '#26a82d',
    marginLeft: 5,
  },
  statusGray: {
    marginLeft: 5,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  unread: {
    border: '1px solid #37AEFD',
    borderLeft: '3px solid #37AEFD',
    borderRadius: 6,
    borderBottomLeftRadius: 8,
    position: 'relative',
    paddingTop: 40,
    marginTop: 3,
  },
  unreadHead: {
    background: 'transparent linear-gradient(270deg, #DCF1FF 0%, #FFFFFF 100%)',
    borderBottom: '1px solid #95D5FF',
    width: '100%',
    padding: '5px 17px',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 38,
  },
  unreadBody: {
    padding: 4,
    paddingLeft: 8,
  },
  reject: {
    background: '#FFF',
  },
}));
