import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import {
  Grid, CircularProgress, Typography, Button,
} from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import ClientEditDialog from '../../../Clients/components/client-dialog/client-dialog.component';
import EditLeadDialog from '../../../Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../../../Leads/components/Convert/Convert.component';
import { caseType, stopDelay } from '../../../../services/utilities';
import {
  tzParseFormat, tzParse, ordinal,
} from '../../../../services/joda';
import { usePromise } from '../../../../services/promise.hook';
import * as rawActions from '../../routes/MessagesThread/messages-thread.actions';
import * as messageActions from '../../messages.actions';
import { useSelector } from '../../../../reducers';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import Avatar from '../../../../components/Avatar/Avatar.component';
import { useStyles } from './inboxChat.styles';
import InboxTextBox, {
  SubmitType
} from './InboxTextBox.component';
import { Message } from '../../message-types';
import ChatItem from '../InboxChatItem/ChatItem.component';

function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber || !phoneNumber.includes('+1')) {
    return phoneNumber;
  }
  const cleaned = phoneNumber?.replace('+1', '');
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
}

type InboxChatProps = {
  resizeNumber?: number,
  fullPage?: boolean;
  heightMod?: number;
  onClose?: () => void;
  clientId?: number;
}

const InboxChat = ({
  resizeNumber, fullPage = true, heightMod = 270, onClose, clientId
}: InboxChatProps) => {
  const classes = useStyles();
  const formRef = useRef(null);
  const [isUnread, setIsUnread] = useState(false);
  const [showRead, setShowRead] = useState(false);
  const [onlyIcon, setOnlyIcon] = useState(false);
  const [divHeight, setDivHeight] = useState(500);
  const [attachments, setAttachments] = useState(0);
  const [textHeight, setTextHeight] = React.useState(0);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadToConvert, setLeadToConvert] = useState(null);
  const [prevLength, setPrevLength] = useState(0);
  const [messagesByDate, setMessagesByDate] = useState<{ [x: string]: Message[] }>(null);
  const [warningAnchor, setWarningAnchor] = useState<boolean>(false);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const getPhiState = usePromise(rawActions.getPhis, []);

  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    client, busy, state, isOptedOutTwoWay, messages, tz,
    allLoaded, moreBusy, page, inbox, banners, conversationId, smsTab,
    isArchived, scheduledMsgs, perPage
  } = useSelector(state => ({
    client: state.messageThread.client,
    conversationId: state.messageThread.conversationId,
    busy: state.messageThread.busy,
    state: state.messageThread.state,
    messages: state.messageThread.messages,
    allLoaded: state.messageThread.allLoaded,
    page: state.messageThread.page,
    moreBusy: state.messageThread.moreBusy,
    isArchived: state.messageThread.isArchived,
    isOptedOutTwoWay: R.includes(
      'TwoWayMessages',
      R.pathOr([], ['messageThread', 'clientOptOut', 'optouts'], state)
    ),
    tz: R.pathOr('', ['login', 'office', 'timezone'])(state),
    query: state.messages.query,
    inboxFilter: state.messages.inboxFilter,
    unread: state.messages.unread,
    inbox: state.messages.inbox,
    banners: state.login.banners,
    contactType: state.messages.contactType,
    smsTab: state.messages.smsTab,
    scheduledMsgs: state.messageThread.scheduled,
    perPage: state.messageThread.perPage,
  }));

  const params = useParams();
  const urlId = R.pathOr(clientId || client.id, ['id'], params);

  const handleSubmit = ({
    text, usedQr, attachments, scheduledFor, msgId
  }: SubmitType) => {
    if (busy) {
      return;
    }

    if (text.trim().length === 0 && attachments.length === 0) {
      return;
    }

    if (scheduledFor) {
      actions.createAndSendScheduledSms({
        body: text, attachments, client, scheduledFor, timezone: tz,
        msgId,
      });
    } else {
      actions.sendMessageQuick({
        body: text, attachments, usedQr,
      }, client.id);
    }
    setTimeout(() => {
      scrollRef.current.scrollTo(0, 0);
      dispatch(messageActions.readAllMessages(client.id));
    }, 150);
  };

  const unreadMessage = () => {
    messageActions.handleReadMessage(conversationId, false).then(() => {
      messageActions.unArchiveMessage(conversationId).then(() => {
        dispatch(messageActions.refresh());
        dispatch(messageActions.handleCountReadMessage());
      });
      setIsUnread(true);
    });
  };

  const readMessage = async () => {
    messageActions.handleReadMessage(conversationId, true).then(() => {
      dispatch(messageActions.refresh());
      dispatch(messageActions.handleCountReadMessage());
      setIsUnread(false);
    });
  };

  const archiveChat = () => {
    messageActions.archiveMessage(conversationId).then(() => {
      dispatch(messageActions.refresh());
      actions.messageThreadPatch({ isArchived: true });
    });
  };

  const unachiveChat = () => {
    messageActions.unArchiveMessage(conversationId).then(() => {
      dispatch(messageActions.refresh());
      actions.messageThreadPatch({ isArchived: false });
    });
  };

  const getName = () => {
    const name = `${client?.firstName} ${client?.lastName}`;
    if (name.trim().length === 0) {
      return client.metadata.caseType;
    }
    return name;
  };

  const onWidthChange = () => {
    const width = rootRef?.current?.getBoundingClientRect().width;
    if (width < 690) {
      setOnlyIcon(true);
    } else {
      setOnlyIcon(false);
    }
  };

  const getDate = (date: string) => {
    if (date && tz) {
      const day = tzParse(date, tz);
      const ord = ordinal(day.dayOfMonth());
      return tzParseFormat(date, tz, `EE, MMM  d'${ord}' yyyy`);
    }
    return '';
  };

  const mountMessages = () => {
    const messagesObj: { [x: string]: Message[] } = {};
    messages.forEach((message: Message) => {
      if (message.created) {
        const parsed = tzParse(message.created, tz).toString();
        const date = parsed.split('T');
        if (Object.prototype.hasOwnProperty.call(messagesObj, date[0])) {
          messagesObj[date[0]].push(message);
        } else {
          messagesObj[date[0]] = [message];
        }
      }
    });
    setMessagesByDate(messagesObj);
  };

  useEffect(() => {
    if (messages.length !== prevLength) {
      getPhiState.invoke(messages);
      setPrevLength(messages.length);
    }
  }, [messages]);

  useEffect(() => {
    if (client?.id) {
      const chat = inbox.find((msg: Message) => msg.clientId === client.id);
      if (chat) {
        actions.messageThreadPatch({ isArchived: !!chat.archived });
      }
    }
  }, [client]);

  useEffect(() => {
    if (fullPage) {
      onWidthChange();
    }
  }, [resizeNumber, fullPage]);

  useEffect(() => {
    getPhiState.invoke(messages);
    setShowRead(!!messages[0]?.from?.FromClient);
  }, [messages]);

  useEffect(() => {
    mountMessages();
  }, [messages]);

  useEffect(() => {
    stopDelay();
    return () => {
      console.log('unmounted inbox chat: ');
      stopDelay();
    };
  }, [client]);

  const onHeightChange = () => {
    const height = formRef.current?.getBoundingClientRect()?.height;
    setTextHeight(height);
  };

  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    setDivHeight(windowHeight);
    setTimeout(() => {
      onHeightChange();
    });
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }
    if (fullPage) {
      updateHeight();
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fullPage]);

  useEffect(() => {
    if (urlId) {
      actions.messageThreadPatch({ client: {} });
      actions.loadMessage({ clientId: Number(urlId), perPage });
    }
    setTimeout(() => {
      onHeightChange();
    });
  }, []);

  return (
    <>
      <Grid className={classes.root} ref={rootRef}>
        {fullPage && (
          <Header
            leftIcons={[
              (!!client.firstName || !!client.lastName) ?
                <Avatar
                  name={getName()}
                  className={classes.avatar}
                  isLead={client?.isLead}
                /> : <div />,
              !!client.firstName &&
            <Button
              onClick={() => client?.isLead ?
                setSelectedLead(client)
                :
                actions.selectClient(client)}
              color='inherit'
              className={classes.titleButton}>
              <Typography className={classes.title} variant='h4'>
                {`${client.firstName || ''} ${client.lastName || ''} ${client && caseType(client)}`} <span>{formatPhoneNumber(client.phone)}</span>
              </Typography>
            </Button>
            ]}
            rightIcons={[
              <Grid display="flex" marginX="5px">
                {busy && <CircularProgress size={18} />}
              </Grid>,
              (showRead && !!client.firstName) ?
                <Grid marginRight="5px">
                  {isUnread ?
                    <HeaderButton onlyIcon={onlyIcon} title='Mark Read' Icon={DraftsIcon} onClick={readMessage} />
                    :
                    <HeaderButton onlyIcon={onlyIcon} title='Mark Unread' Icon={DraftsIcon} onClick={unreadMessage} />
                  }
                </Grid> : <div />,
              !!client.firstName &&
              <>
                {isArchived ? (
                  <HeaderButton onlyIcon={onlyIcon} title='Unarchive Chat' Icon={ArchiveIcon} onClick={unachiveChat} active type='archiveChat' iconStyle={{ color: '#FFF' }} />
                ) : (
                  <HeaderButton onlyIcon={onlyIcon} title='Archive Chat' Icon={ArchiveIcon} onClick={archiveChat} active type='archiveChat' iconStyle={{ color: '#FFF' }} />
                )}
              </>,
              !!onClose &&
            <HeaderButton marginLeft={5} marginRight={-10} title='Close' onlyIcon Icon={CloseIcon} onClick={onClose} />
            ]}
            onlyIconsWidth={resizeNumber ? undefined : 560}
          />
        )}

        <Grid
          className={classes.content}
          ref={scrollRef}
          style={{ height: fullPage ? `calc(${divHeight - textHeight - (banners.length * 33) - (attachments * 40)}px - 64px - 54px - ${warningAnchor ? 35 : 0}px)` : `calc(${heightMod - attachments * 40}px - ${warningAnchor ? 35 : 0}px)` }}
          onScroll={(e) => {
            const bounds = e.currentTarget.getBoundingClientRect();
            const scrollSpace = e.currentTarget.scrollHeight + e.currentTarget.scrollTop - bounds.height;
            if (scrollSpace <= 10 && !allLoaded && !moreBusy) {
              actions.loadMoreMessages({
                clientId: client.id,
                page: page + 1
              });
            }
          }}
        >
          <div className={classes.loader}>
            {busy && <CircularProgress />}
          </div>
          {!busy && client.id && !!client?.firstName &&
         (!client.allowSMS || isOptedOutTwoWay) &&
         <div className={classes.systemMessage}>
           {client?.firstName} disabled SMS notifications. 😢
         </div>}
          {!busy && messages.length === 0 && scheduledMsgs.length === 0 &&
          client.allowSMS &&
          <div className={classes.systemMessage}>
            Start a conversation with {client.firstName} 💬
          </div>}
          {!busy && scheduledMsgs.map((message: Message) => {
            return (
              <ChatItem
                key={message.msgId}
                message={message}
                tz={tz}
                phis={getPhiState.data}
                handleSubmit={handleSubmit}
              />
            );
          })}
          {(!busy && messagesByDate) && (
            Object.entries(messagesByDate).map((item) => (
              <>
                {item[1].map((message: Message) => (
                  <ChatItem
                    key={message.msgId}
                    message={message}
                    tz={tz}
                    phis={getPhiState.data}
                  />
                ))}
                <span className={classes.date}>
                  {getDate(item[1][0].created)}
                </span>
              </>
            )
            ))}
          <div
            className={classes.loader}
            style={{ paddingTop: allLoaded ? '5px' : '55px' }}>
            {!!messages.length && moreBusy && <CircularProgress />}
          </div>
        </Grid>
        <InboxTextBox
          handleSubmit={handleSubmit}
          client={client}
          fullPage={fullPage}
          formRef={formRef}
          onAttach={(n) => setAttachments(n)}
          state={state}
          warningAnchorState={[warningAnchor, setWarningAnchor]}
          pageWidth={rootRef?.current?.clientWidth}
        />
      </Grid>
      {fullPage && (
        <ClientEditDialog
          open={state === 'CLIENT_SELECT'}
          onClose={actions.backToMessage}
          back={actions.back}
          from={'MESSAGE'}
          gotoClient={actions.back} />
      )}
      {fullPage && (
        <>
          <EditLeadDialog
            open={!!selectedLead}
            close={() => setSelectedLead(null)}
            lead={selectedLead}
            convertClick={() => {
              setLeadToConvert(selectedLead);
              setSelectedLead(null);
            }}
            onUpdate={() => {
              setTimeout(() => {
                dispatch(messageActions.refresh());
                actions.loadMessage({
                  clientId: client?.id,
                  unread: smsTab === 'unread',
                  archived: smsTab === 'archived',
                });
              }, 800);
            }}
          />
          <ConvertLeadDialog
            open={!!leadToConvert}
            lead={leadToConvert}
            close={() => setLeadToConvert(null)}
            onConvert={() => {
              setLeadToConvert(null);
              setTimeout(() => {
                dispatch(messageActions.refresh());
                actions.loadMessage({
                  clientId: client?.id,
                  unread: smsTab === 'unread',
                  archived: smsTab === 'archived',
                });
              }, 800);
            }}
          />
        </>
      )}
    </>
  );
};

export default InboxChat;
