import * as at from '../../../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  state: 'VIEW',
  messages: [],
  unread: 0,
  unreadMessages: [],
  body: '',
  totalCount: 0,
  totalPages: 0,
  perPage: 25,
  page: 1,
  isSending: false,
  client: {},
  clientOptOut: {
    email: true,
    sms: true,
    optouts: []
  },
  moreBusy: false,
  allLoaded: false,
  createBusy: false,
  clientId: null,
  conversationId: null,
  isArchived: false,
  newlySentMessage: null,
  scheduled: [],
};

const oneTimeToOneOff = (client) => (msg) => ({
  to: { ToClient: msg.messageType.OneTime.receivers.Clients[0] },
  smsData: msg.sms,
  msgId: msg.id,
  smsStatus: 'Scheduled',
  created: msg.messageType.OneTime.scheduledFor,
  client,
});

export default function reducer(messageThread = init, action) {
  switch (action.type) {
    case at.CLIENT_CLIENT_PATCH: {
      if (action.data.id === messageThread.client.id) {
        return R.pipe(
          R.assoc('clientOptOut', action.data.optout),
          R.assocPath(['client', 'allowEmail'], action.data.optout.email),
          R.assocPath(['client', 'allowSMS'], action.data.optout.sms)
        )(messageThread);
      }
      return messageThread;
    }
    case at.MESSAGE_THREAD_PATCH: {
      if (action.data.newThreadMessages) {
        const unreadMessages = R.uniq(action.data.unreadMessages);
        return R.merge(
          messageThread,
          {
            messages: R.concat(action.data.newThreadMessages, messageThread.messages),
            unread: action.data.unread !== R.length(unreadMessages) ? R.length(unreadMessages) : action.data.unread,
            unreadMessages,
          });
      }
      return R.merge(messageThread, action.data);
    }
    case at.MESSAGE_THREAD_REMOTE_GET_MORE: {
      if (action.state === 'REQUEST') {
        return R.merge(messageThread, {
          moreBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const messages = R.sort(R.descend(R.prop('created')), action.data.messages);
        return R.merge(messageThread, {
          messages: R.pipe(
            R.concat(messageThread.messages),
            R.uniqBy(R.prop('msgId'))
          )(messages),
          page: action.data.page,
          allLoaded: messages.length < messageThread.perPage,
          moreBusy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(messageThread, {
          moreBusy: false,
        });
      }
      return messageThread;
    }
    case at.MESSAGE_THREAD_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(messageThread, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const messages = R.pipe(
          R.uniqBy(R.prop('msgId')),
          R.sort(R.descend(R.prop('created')))
        )(action.data.data);
        const client = action.data.client;
        const clientIsvalid = !!client?.firstName;
        return R.merge(messageThread, {
          messages: clientIsvalid ? messages : init.messages,
          totalCount: clientIsvalid ? action.data.totalCount : init.totalCount,
          totalPages: clientIsvalid ? action.data.totalPages : init.totalPages,
          page: clientIsvalid ? action.data.page : init.page,
          perPage: clientIsvalid ? action.data.perPage : init.perPage,
          allLoaded: messages.length < messageThread.perPage,
          busy: false,
          client: clientIsvalid ? client : init.client,
          clientOptOut: clientIsvalid ? action.data.clientOptOut : init.clientOptOut,
          conversationId: clientIsvalid ? action.data.conversationId : init.conversationId,
          scheduled: R.reverse(
            R.sortBy(
              R.prop('created'),
              action.data.scheduled.map(oneTimeToOneOff(client))
            )
          ),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(messageThread, {
          busy: false,
          moreBusy: false,
        });
      }
      return messageThread;
    }
    case at.MESSAGE_THREAD_REMOTE_POST: {
      if (action.state === 'REQUEST') {
        return R.merge(messageThread, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(messageThread, {
          messages: R.reverse(R.sortBy(R.prop('created'))(action.data.data)),
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          page: action.data.page,
          body: '',
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(messageThread, {
          busy: false,
        });
      }
      return messageThread;
    }
    case at.MESSAGE_THREAD_REMOTE_SEND_MESSAGE: {
      if (action.state === 'REQUEST') {
        return R.evolve({
          messages: R.prepend(action.params),
          body: R.always(''),
          isSending: R.T,
          createBusy: R.T,
        }, messageThread);
      } else if (action.state === 'RESPONSE') {
        const clientId = R.pathOr(null, ['data', 'msg', 'client', 'id'], action);
        if (clientId === messageThread.client.id) {
          return R.evolve({
            messages: R.pipe(
              R.reject(msg => {
                return ((action.data.tmpId && action.data.tmpId === msg.tmpId) ||
                  (action.data.msg.msgId === msg.msgId));
              }),
              R.prepend(action.data.msg)
            ),
            body: R.always(''),
            isSending: R.F,
            createBusy: R.F,
            newlySentMessage: () => action.data.msg.done ? null : action.data,
          }, messageThread);
        } else {
          return messageThread;
        }
      } else if (action.state === 'ERROR') {
        return R.merge(messageThread, {
          createBusy: false
        });
      }
      return messageThread;
    }
    case at.MESSAGE_THREAD_READ_MESSAGE: {
      if (action.noCount) {
        return messageThread;
      }
      const message = action.data;
      const prop = R.propEq('msgId', message.msgId);
      const unreadMessages = R.pathOr(true, ['to', 'ToOffice', 'hasRead'], message) ||
        R.pathOr(true, ['from', 'FromOffice', 'hasRead'], message) ?
        R.reject(prop, messageThread.unreadMessages) :
        R.ifElse(
          R.find(prop),
          R.identity,
          R.append(message)
        )(messageThread.unreadMessages);
      return R.merge(messageThread, {
        unreadMessages,
        unread: unreadMessages.length
      });
    }
    case at.MESSAGE_THREAD_NEW_REPLY: {
      const message = action.data;
      if (!R.has('OneOff', action.data.service)) {
        return messageThread;
      }
      // Update the thread if it's for the current client
      const isForCurrentClient = R.pathEq(['client', 'id'], message.client.id, messageThread);
      const messagesToAppend = isForCurrentClient ? [message] : [];

      const messages = R.pipe(
        R.concat(messagesToAppend),
        R.uniqBy(R.prop('msgId'))
      )(messageThread.messages);

      if (message.from.FromOffice) {
        return R.merge(messageThread, { messages });
      }
      // Remove any dups and add to unread list
      const unreadMessages = R.pipe(
        R.reject(R.propEq('msgId', message.msgId)),
        R.append(message)
      )(messageThread.unreadMessages);

      return R.merge(messageThread, {
        unreadMessages,
        unread: unreadMessages.length,
        messages,
      });
    }
    case at.MESSAGE_THREAD_REMOTE_DELETE_SCHEDULED_SMS:
    case at.MESSAGE_THREAD_REMOTE_SAVE_SCHEDULED_SMS: {
      if (action.state === 'REQUEST') {
        return R.evolve({
          body: R.always(''),
          isSending: R.T,
          createBusy: R.T,
        }, messageThread);
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          body: R.always(''),
          isSending: R.F,
          createBusy: R.F,
          scheduled: () => R.reverse(
            R.sortBy(
              R.prop('created'),
              action.data.map(oneTimeToOneOff(messageThread.client))
            )
          ),
        }, messageThread);
      } else if (action.state === 'ERROR') {
        return R.merge(messageThread, {
          createBusy: false
        });
      }
      return messageThread;
    }
    case at.MESSAGE_THREAD_SENT_SCHEDULED_SMS: {
      return R.evolve({
        scheduled: R.filter(({ msgId }) => {
          return msgId !== action.data.id;
        }),
        messages: R.prepend(action.data.message),
      }, messageThread);
    }
    default:
      return messageThread;
  }
}
