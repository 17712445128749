import * as R from 'ramda';
import axios from 'axios';
import queryString from 'query-string';
import LocalSession from './local-session';

export const skedApi = axios.create({
  baseURL: process.env.API_URL,
  responseType: 'text'
});

let accessTokenExpireDate = undefined;
let userType = null;

export const setSessionHeaders = (access_token) => {
  const session = R.pipe(
    R.split('.'),
    R.nth(1),
    atob,
    s => JSON.parse(s),
  )(access_token);
  accessTokenExpireDate = R.pipe(
    R.prop('exp'),
    d => new Date(d * 1000)
  )(session);
  userType = session.userType;
  console.log('Token expires at: ', accessTokenExpireDate);
  skedApi.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  skedApi.defaults.headers.common['Content-Type'] = 'application/json';
  skedApi.defaults.headers.common.Accept = 'application/json;charset=utf-8';
};

export const setOfficeHeader = (officeId) => {
  skedApi.defaults.headers.common['X-As-Office'] = officeId;
  const session = R.assoc('officeId', officeId, JSON.parse(localStorage.getItem('sked-session')));
  localStorage.setItem('sked-session', JSON.stringify(session));
};

export const clearOfficeHeader = () => {
  delete skedApi.defaults.headers.common['X-As-Office'];
  const session = R.assoc('officeId', undefined, JSON.parse(localStorage.getItem('sked-session')));
  localStorage.setItem('sked-session', JSON.stringify(session));
};

export const clearSessionHeaders = () => {
  // accessTokenExpireDate = undefined;
  delete skedApi.defaults.headers.common.Authorization;
  clearOfficeHeader();
};

let currentController = new AbortController();
export const postAuth = (body, controller = undefined) => axios.post(
  `${process.env.AUTH_URL}/auth/token`,
  queryString.stringify(body),
  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic YWRtaW46dGVzdA=='
    },
    signal: controller ? controller.signal : undefined,
  }
).then((data) => {
  return data;
});


export const getNewToken = (refresh_token, controller) => postAuth({
  refresh_token,
  grant_type: 'refresh_token'
}, controller).then( ({ data: session }) => {
  setSessionHeaders(session.access_token);
  LocalSession.setSession(session);
  const raw = R.pipe(
    R.split('.'),
    R.nth(1),
    atob,
    s => JSON.parse(s),
  )(session.access_token);
  accessTokenExpireDate = R.pipe(
    R.prop('exp'),
    d => new Date(d * 1000)
  )(raw);
  userType = raw.userType;
  return session;
});

// to keep refresh token alive event if you're not making requests
const checkAccessTime = () => {
  console.log('checking access token expiry...');
  if (accessTokenExpireDate < new Date()) {
    console.log('access token is expired!');
    const refresh_token = R.propOr('', 'refresh_token', LocalSession.getSession());
    if (!refresh_token) {
      throw new Error('No refresh token!');
    }
    currentController.abort();
    currentController = new AbortController();
    return getNewToken(refresh_token, currentController);
  }
  return;
};


let timeout = null;
Object.keys(window).forEach(key => {
  if (/^on(blur|focus|load|resize|scroll|click|dblclick|change|select|submit|keypress|error)/.test(key)) {
    if (userType === 'Admin') {
      window.addEventListener(key.slice(2), () => {
        checkAccessTime();
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          console.log('Inactive for too long... Bye-Bye!');
          LocalSession.destroySession();
          clearSessionHeaders();
          window.location.reload();
        }, 60 * 1000 * 10); // 10 minutes
      });
    } else {
      window.addEventListener(key.slice(2), () => {
        checkAccessTime();
      });
    }
  }
});

const checkRefreshToken = action => Promise.resolve()
  .then(checkAccessTime).then(() => action());

const api = {
  get: (url, params, options) => checkRefreshToken(() =>
    skedApi.get(url, { params }, options).then((response) => response.data)),
  post: (url, body, options) => checkRefreshToken(() =>
    skedApi.post(url, body, options).then((response) => response.data)),
  patch: (url, body, options) => checkRefreshToken(() =>
    skedApi.patch(url, body, options).then((response) => response.data)),
  put: (url, body, options) => checkRefreshToken(() =>
    skedApi.put(url, body, options).then((response) => response.data)),
  delete: (url, body) => checkRefreshToken(() =>
    skedApi.delete(url, body).then((response) => response.data)),
  ajax: (params) => checkRefreshToken(() =>
    skedApi(params).then((response) => response.data)),
};

export const oldApi = {
  get: (url, params) =>
    skedApi.get(url, { params }).then((response) => response.data),
  post: (url, body, options) =>
    skedApi.post(url, body, options).then((response) => response.data),
  patch: (url, body, options) =>
    skedApi.patch(url, body, options).then((response) => response.data),
  put: (url, body, options) =>
    skedApi.put(url, body, options).then((response) => response.data),
  delete: (url, body, options) =>
    skedApi.delete(url, body, options).then((response) => response.data),
  ajax: (params) =>
    skedApi(params).then((response) => response.data),
};

export default api;
