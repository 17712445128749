import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import './templates-edit.css';
import { pathOr, merge } from 'ramda';
import * as rawActions from './templates-edit.actions';
import { resetTemplates } from '../../templates.actions';
import CreateMessage from '../../../../components/CreateMessage/CreateMessage.component';
import {
  updateAllAutomation, addLocalAutomation, removeAutomation, updateAutomation,
  getAutomationsByMsgId
} from '../../../Automations/automations.actions';
import { useCheckIsAdmin } from '../../../../services/checkIsAdmin.hook';
import { useParams } from 'react-router-dom';


const TemplateEdit = () => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    merge(rawActions, {
      updateAllAutomation,
      addLocalAutomation,
      removeAutomation,
      updateAutomation,
      getAutomationsByMsgId,
    }),
    dispatch);
  const props = useSelector((state: any) => ({
    ...state.templateEdit,
    ...state.login.office,
    automations: pathOr([], ['automations', 'automations', 'data'])(state),
    admin: state.login.admin,
    sessionStatus: state.session.status,
  }));
  const { id } = useParams();
  useCheckIsAdmin(props.admin, props.sessionStatus);
  return (
    <CreateMessage
      {...props}
      messageType='Template'
      urlId={id}
      actions={actions}
      resetMessages={() => {
        dispatch(resetTemplates());
      }}
      skedTemplate
    />
  );
};
export default TemplateEdit;
