import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  DialogContentText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  TextField,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';

import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalDate } from '@js-joda/core';
import api from '../../../../services/api';
import * as R from 'ramda';

import { TimeSelectBusyness } from '../../../CalendarV2/components/time-select';
import { AppointmentTypeAutocomplete } from '../../../CalendarV2/components/AppointmentTypeSelect.component';
import { getFilterData } from '../../../Calendar/calendar.actions';
import { createAppointments } from '../../../CalendarV2/services/scheduler.service';
import * as actions from '../../leads.actions';
import { useSelector } from '../../../../reducers';
import Modal from '../../../../components/Modal/Modal.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import ApptRequest from '../../../../components/ApptRequest/ApptRequest.component';
import { Appointment } from '../../../Appointments/appointments.types';
import { errorSnackbar } from '../../../../components/Snackbar/snackbar.actions';

import { Lead } from '../../leads.reducer';
import { useStyles } from './convert.styles';

type Appt = {
  time: string;
  date: string;
  typeId: number;
}

type ConvertLeadProps = {
  lead: Lead;
  open: boolean;
  close: () => void;
  onConvert?: () => void;
}

const ConvertLead = ({ open, lead, close, onConvert }: ConvertLeadProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(false);
  const [first, setFirst] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Appt[]>([]);
  const [requests, setRequests] = useState<Appointment[]>([]);
  const [width, setWidth] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    interval,
    professionals,
    types,
    timezone,
  } = useSelector(state => ({
    interval: state.calendarv2.localInterval || R.pathOr(15, ['login', 'ehrSettings', 'blockLength'])(state),
    professionals: state.calendar.professionals,
    types: state.calendar.types,
    timezone: state.login.office.timezone || 'America/New_York',
  }));

  const handleClose = () => {
    setChecked(false);
    close();
  };

  const handleConvert = async (onlyConvert?: boolean) => {
    if (onlyConvert) {
      actions.convertLead(lead.id)(dispatch).then(() => {
        onConvert?.();
      });
      handleClose();
      return;
    }
    try {
      await createAppointments({ selectedClients: [lead], appointmentsToSchedule: appointments, timezone });
      actions.convertLead(lead.id)(dispatch).then(() => {
        onConvert?.();
      });
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(errorSnackbar('There was an error when attempting to convert this lead.'));
    }
  };

  const addAppointment = () => {
    setAppointments([...appointments, { time: '08:00', date: LocalDate.now().toString(), typeId: types[0].id }]);
  };

  const handleUpdate = (index: number, label: string, value: string | number) => {
    const newItems = R.update(index, { ...appointments[index], [label]: value }, appointments);
    setAppointments(newItems);
  };

  const handleRemove = (index: number) => {
    const newItems = R.remove(index, 1, appointments);
    setAppointments(newItems);
  };

  const getRequests = async (id: number) => {
    const result = await actions.getExistingApts(id);
    setRequests(result);
  };

  const handleApprove = () => {
    handleConvert(true);
  };
  
  const handleReject = async (apptId: number) => {
    const data = {
      reason: 'Rejected via the SKED Admin.',
      source: 'Admin',
    };
    try {
      await api.delete(`appointment/${apptId}`, { data });
      setRequests(requests.filter(r => r.id !== apptId));
    } catch (e) {
      console.error(e);
      dispatch(errorSnackbar('There was an error when attempting to cancel this appointment.'));
    }
  };

  useEffect(() => {
    if (checked && appointments.length === 0 && types.length) {
      setAppointments([{ time: '08:00', date: LocalDate.now().toString(), typeId: types[0].id }]);
    }
  }, [checked, appointments, types]);

  useEffect(() => {
    if (!types.length && !first) {
      dispatch(getFilterData());
      setFirst(true);
    }
  }, [types, first]);
  
  useEffect(() => {
    if (lead?.id) {
      getRequests(lead.id);
    }
  }, [lead?.id]);
  
  const small = useMemo(() => {
    return width < 550;
  }, [width < 550]);
  
  const mobile = useMemo(() => {
    return width < 424;
  }, [width < 424]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={requests.length ? 'Pending Appt Request' : 'Convert Lead'}
      maxWidth={requests.length ? 540 : 580}
      className='sked-test-covert-lead-modal'
      getWidth={setWidth}
      buttons={requests.length ? [
        <HeaderButton
          title='Reject'
          borderSolid
          className={classes.reject}
          marginLeft={10}
          onClick={() => handleReject(requests[0].id)}
        />,
        <Tooltip
          title="Approve Appt Request and convert this Lead into a Client"
          placement='bottom'
          arrow
        >
          <div>
            <HeaderButton
              title='Approve'
              color='primary'
              marginRight={-5}
              onClick={handleApprove}
            />
          </div>
        </Tooltip>
      ] : [
        !mobile &&
        <HeaderButton
          title={small ? 'Convert' : 'Convert only'}
          borderSolid
          onClick={() => handleConvert(true)}
          className='sked-test-covert-lead-modal-button-convert-only'
        />,
        !mobile &&
        <HeaderButton
          title={small ? 'Convert & Schedule' : 'Convert & schedule appointment'}
          color='primary'
          onClick={() => handleConvert(false)}
          disabled={!checked}
          className='sked-test-covert-lead-modal-button-convert-and-schedule'
        />,
        mobile &&
        <Grid>
          <HeaderButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            color='primary'
            title={'Convert'}
            EndIcon={ArrowDownIcon}
            iconStyle={{ color: '#FFF' }}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => handleConvert(true)}
              value={'convert'}>
                Convert to client
            </MenuItem>
            {checked && (
              <MenuItem
                onClick={() => handleConvert(false)}
                value={'schedule'}>
                  Convert & schedule new appointment
              </MenuItem>
            )}
          </Menu>
        </Grid>
      ]}
    >
      {requests.length ? (
        <Grid>
          <DialogContentText>
            Approving appointment requests automatically converts leads to clients.
          </DialogContentText>
          {requests.map((appt) => (
            <Grid key={appt.id} marginTop={1}>
              <ApptRequest
                appointment={appt}
                tz={timezone}
                professional={professionals.find((pro) => pro.id === appt.appointmentType?.professionalId)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>
          <DialogContentText>
          Would you like to convert this lead to a client? Clients cannot be converted back to leads.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                color="primary"
              />
            }
            label="Schedule Appointment"
          />
          {checked && (
            <>
              <hr />
              <div className={classes.row}>
                <Typography component="h3">New Appointments</Typography>
                <IconButton aria-label="add appointment" onClick={addAppointment}>
                  <AddIcon />
                </IconButton>
              </div>
              {appointments.map(({ date, time, typeId }, i) => (
                <div className={classes.row} key={i}>
                  <div>
                    <TextField
                      className={classes.date}
                      label="Choose Day"
                      value={LocalDate.parse(date)}
                      onChange={(event) => handleUpdate(i, 'date', event.target.value)}
                      type='date'
                      variant='standard'
                    />
                  </div>
                  <div className={classes.margins}>
                    <TimeSelectBusyness
                      appointmentTypeId={typeId}
                      date={date}
                      interval={interval}
                      id={i}
                      value={time}
                      onChange={(value: string) => handleUpdate(i, 'time', value)} />
                  </div>
                  <div>
                    <AppointmentTypeAutocomplete
                      value={typeId}
                      minWidth="222px"
                      // maxWidth="300px"
                      onChange={(event: { target: { value: number } }) => handleUpdate(i, 'typeId', event.target.value)}
                      types={types}
                      professionals={professionals}
                      id={`apt-type-${i}`}
                      includeErrors={undefined}
                    />
                  </div>
                  <IconButton
                    aria-label="close"
                    onClick={() => handleRemove(i)}
                    style={{ marginLeft: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
              <br />
            </>
          )}    
        </div>
      )}
    </Modal>
  );
};

export default ConvertLead;
