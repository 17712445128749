import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CircularProgress, TableHead, TableRow, TableBody, Button,
  IconButton, TextField, Menu, MenuItem,
  ListItemText, ListItemIcon, FormControl, InputLabel, Select,
  SelectChangeEvent, FormControlLabel, Checkbox, Grid,
  DialogContentText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Spacer } from '../../components/PageHeader';
import {
  fetchFormList, createForm, deleteForm, unprotectedFetchFormRevisionById,
  copyForm, saveForm, fetchFormRevisions, revisionToForm, getSettingsForm,
  saveSettings, GlobalSettings, generateBlankPdf, GenerateBlank, initPdfListener, PdfGen, closePdfEvents,
} from './intake.service';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from '@mui/icons-material/Add';
import { includes, pathOr, isEmpty } from 'ramda';
import { usePromise } from './../../services/promise.hook';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import { TableContainer, HeaderCell, BodyCell } from '../../components/CustomTable';
import Modal from '../../components/Modal/Modal.component';
import GearIcon from '../../icons/Settings.icon';
import CopyIcon from '../../icons/Copy.icon';
import { Form, CrForm, Revision } from './ServerTypes';
import { IntakeForm } from './intake-types';
import { useTitle } from '../../services/useTitle';
import { successSnackbar, errorSnackbar } from '../../components/Snackbar/snackbar.actions';
import { useSelector } from '../../reducers';

const urlBase = process.env.PORTAL_URL + '/';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    paddingBottom: '100px',
    overflowY: 'unset',
  },
  descriptionCell: {
    maxWidth: '400px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  inputWidth: {
    minWidth: '45%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 5px 16px 24px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  copy: {
    height: 20,
    width: 20,
    marginLeft: 2,
  },
}));

interface SettingsDialogProps {
  open: boolean;
  form?: Form;
  onClose: (form?: Form) => void;
}

interface PdfEhrOptions {
  value: 'OnGeneration' | 'Manual',
  label: string;
}
const pdfEhrOptions: PdfEhrOptions[] = [
  { value: 'OnGeneration', label: 'Automatic' },
  { value: 'Manual', label: 'Manual' },
];

interface PrintDialogProps {
  open: boolean;
  onClose: (accept: boolean) => void;
}

const ConditionalsPrintDialog = ({ open, onClose }: PrintDialogProps) => {
  const close = (accept: boolean) => () => {
    onClose(accept);
  };
  return (
    <Modal
      open={Boolean(open)}
      onClose={close(false)}
      title='Printing Conditional Form'
      maxWidth={500}
      className='sked-test-form-builder-conditional-print-modal'
      buttons={[
        <HeaderButton
          title='Continue to Print'
          onClick={close(true)}
          color="primary"
          className='sked-test-form-builder-action-conditional-print-continue'
        />
      ]}
    >
      When allowing a patient to fill out a form in the office, the Admin will become locked to prevent them from gaining access to your appointment and patient information. You'll need to enter your personal Admin PIN to regain access to the Admin.
    </Modal>
  );
};

export const SettingsDialog = ({ open, form, onClose }: SettingsDialogProps) => {
  const {
    ehrSystem,
    hasPdf,
  } = useSelector((state) => ({
    ehrSystem: state.login.office.ehrSystem,
    hasPdf: includes('FormsPdf', pathOr([], ['login', 'features'], state)),
  }));
  const [localForm, setLocalForm] = React.useState<Form>(form);
  const saveFormState = usePromise<CrForm, Form>(saveForm, null);

  React.useEffect(() => {
    setLocalForm(form);
  }, [open, form]);

  const onSave = () => {
    saveFormState.invoke({
      ...localForm,
      pdfGeneration: localForm.pdfEhr === 'OnGeneration' ? 'ClientSubmit' : 'Manual',
    }).then((newForm) => {
      onClose(newForm);
    });
  };

  const updateForm = (prop: keyof Form) => (event: SelectChangeEvent<'OnGeneration' | 'Manual'>) => {
    setLocalForm({
      ...localForm,
      [prop]: event.target.value,
    });
  };
  const updateFolder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalForm({
      ...localForm,
      ehrFolder: event.target.value
    });
  };
  return (
    <Modal
      open={Boolean(open)}
      onClose={() => onClose()}
      title={`Form settings ${form?.name ? `for ${form?.name}` : ''}`}
      maxWidth={500}
      className='sked-test-form-builder-action-settings-modal'
      buttons={[
        <Grid display="flex" marginRight={1}>
          {saveFormState.loading && <CircularProgress size={20} />}
        </Grid>,
        <HeaderButton
          title='Save'
          onClick={onSave}
          disabled={saveFormState.loading}
          color="primary"
          className='sked-test-form-builder-action-settings-modal'
        />
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {(hasPdf && (ehrSystem === 'PlatinumApi' || ehrSystem === 'Genesis' || ehrSystem === 'ChiroTouch')) && (
          <DialogContentText>
            {(ehrSystem === 'PlatinumApi' || ehrSystem === 'Genesis') &&
              'Upload patient forms to your EHR Manually or Automatically. Selecting \'Manual\' requires you to initiate the upload, while \'Automatic\' will upload the form for you once the patient submits it.'}
            {(ehrSystem === 'ChiroTouch') &&
              `Upload patient forms to your EHR Manually or Automatically. Selecting 'Manual' requires you to initiate the upload, while 'Automatic' will upload the form for you once the patient submits it.
  Each form will be uploaded into a folder within ChiroTouch. Name your folder below.`}
            <br />
          </DialogContentText>
        )}
        {(hasPdf && ehrSystem !== 'None') &&
          <>
            <FormControl fullWidth>
              <InputLabel id='select-generation-label'>
                Upload PDF to EHR
              </InputLabel>
              <Select
                labelId='select-outlined-label'
                value={localForm?.pdfEhr ? localForm?.pdfEhr : ''}
                fullWidth
                onChange={updateForm('pdfEhr')}
                label='Upload PDF to EHR'
              >
                {pdfEhrOptions.map(({ value, label }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
          </>
        }
        {(hasPdf && ehrSystem === 'ChiroTouch') &&
          <>
            <TextField
              value={localForm?.ehrFolder ? localForm?.ehrFolder : ''}
              style={{ width: '100%' }}
              onChange={updateFolder}
              label='PDF Folder Name in ChiroTouch'
              error={localForm?.ehrFolder?.match(/^[a-z0-9 \\\-_]+$/i) === null}
              helperText={localForm?.ehrFolder?.match(/^[a-z0-9 \\\-_]+$/i) === null ? 'Alphanumerical, space, \\, -, and _ characters only' : ''}
            />
            <br />
            <br />
          </>
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={localForm?.sendNotification}
              onChange={(e) => {
                setLocalForm({
                  ...localForm,
                  sendNotification: e.target.checked
                });
              }}
              color="primary"
            />
          }
          label="Send office notification when a form has been submitted."
        />
        {saveFormState.errorMessage && <p style={{ color: 'red' }}>{saveFormState.errorMessage}</p>}
      </div>
    </Modal>
  );
};

export const GlobalSettingsDialog = ({ open, onClose }: SettingsDialogProps) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<GlobalSettings>({
    pin: '',
  });
  const getSettingsState = usePromise<void, GlobalSettings>(getSettingsForm, null);
  const saveState = usePromise<GlobalSettings, GlobalSettings>(saveSettings, null);

  React.useEffect(() => {
    if (open) {
      getSettingsState.invoke().then((s) => setState(s));
    }
  }, [open]);

  const onSave = () => {
    saveState.invoke(state).then(() => {
      dispatch(successSnackbar('Saved settings!'));
    }).catch(() => {
      dispatch(errorSnackbar('Failed to save settings'));
    });
  };

  const updateState = (prop: keyof GlobalSettings) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [prop]: e.target.value,
    });
  };

  const error = React.useMemo(() => {
    return state.pin.length < 4 || state.pin.length > 8;
  }, [state.pin]);

  return (
    <Modal
      open={Boolean(open)}
      onClose={onClose}
      title='Intake Forms Settings'
      className='sked-test-form-builder-settings-modal'
      maxWidth={700}
      buttons={[
        <Grid display="flex" marginRight={1}>
          {saveState.loading && <CircularProgress size={20} />}
        </Grid>,
        <HeaderButton
          title='Save'
          color='primary'
          onClick={onSave}
          disabled={saveState.loading || error}
          marginLeft={10}
          className='sked-test-form-builder-settings-modal-button-save'
        />
      ]}
    >
      <div>
        <DialogContentText>
          When allowing a patient to fill out a form in the office, the Admin will become locked to prevent them from gaining access to your appointment and patient information. You'll need to enter your personal Admin PIN to regain access to the Admin.
        </DialogContentText>
        <FormControl fullWidth>
          <TextField
            value={state.pin}
            fullWidth
            onChange={updateState('pin')}
            label='Admin PIN'
            error={error}
            helperText='Between 4-8 characters'
          />
        </FormControl>
        <br />
        <br />
        {saveState.errorMessage && <p style={{ color: 'red' }}>{saveState.errorMessage}</p>}
      </div>
    </Modal>
  );
};


let w: Window;

const IntakeSettingsList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listState = usePromise<void, Form[]>(fetchFormList, []);
  const templateState = usePromise<void, IntakeForm[]>(unprotectedFetchFormRevisionById, []);

  useTitle('Forms List');

  const store = useSelector(state => ({
    isEnabled: includes(
      'Forms',
      pathOr([], ['login', 'features'], state)),
    hasPdf: includes(
      'FormsPdf',
      pathOr([], ['login', 'features'], state)),
    ehrSystem: pathOr('None', ['login', 'office', 'ehrSystem'], state),
    canUpload: !includes(
      pathOr('None', ['login', 'office', 'ehrSystem'], state),
      ['None', 'ChiroHD']
    ),
    hasNotifications: includes('FormNotifications', pathOr([], ['login', 'features'], state)),
    headerHeight: state.login.headerHeight,
  }));

  const [formId, setFormId] = React.useState(null);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [open, setOpen] = React.useState(null);
  const [tab, setTab] = React.useState('forms');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [form, setForm] = React.useState<Form>(null);
  const [openSetting, setOpenSetting] = React.useState(false);
  const [globalSettings, setGlobalSettings] = React.useState(false);
  const [phi, setPhi] = React.useState<PdfGen>(null);
  const [revId, setRevId] = React.useState<number>(null);
  const [pdf, setPdf] = React.useState<number>(null);
  const [openConds, setOpenConds] = React.useState<boolean>(false);

  const handleClickOpen = (t: string) => () => {
    setOpen(t);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const creationState = usePromise<CrForm, Form>(createForm, null);
  const copyState = usePromise<IntakeForm, number>(copyForm, null);
  const saveFormState = usePromise<CrForm, Form>(saveForm, null);
  const copyFormState = usePromise<number, Revision>(fetchFormRevisions, null);
  const deleteState = usePromise<number, void>(deleteForm, null);
  const pdfState = usePromise<GenerateBlank, string>(generateBlankPdf, null);

  const handleCreate = () => {
    if (open === 'create') {
      creationState.invoke({ name, description, sendNotification: true }).then((data) => {
        navigate('/forms-edit/' + data.id);
        setName('');
        handleClose();
      });
    } else {
      saveFormState.invoke({ id: formId, name, description }).then((data: Form) => {
        listState.setState({
          ...listState,
          data: listState.data.map((f: Form) => {
            if (f.id === formId) {
              return data;
            } else {
              return f;
            }
          })
        });
        setName('');
        setDescription('');
        setFormId(null);
        handleClose();
      });
    }
  };

  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);

  const handleClickModalDelete = (item: Form) => {
    setAnchorEl(null);
    setModalDeleteOpen(true);
    setItemToDelete(item);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setItemToDelete(null);
  };

  const confirmDelete = () => {
    deleteState.invoke(itemToDelete.id).then(() => {
      handleModalDeleteClose();
      listState.invoke();
      dispatch(successSnackbar('Deleted Form'));
    }).catch(() => {
      dispatch(errorSnackbar('Failed to delete Form'));
    });
  };

  React.useEffect(() => {
    listState.invoke();
    templateState.invoke();
    initPdfListener(setPhi, 'FormBlankPdfCreated');
    return () => {
      closePdfEvents();
    };
  }, []);

  React.useEffect(() => {
    if (phi && phi.revId === revId) {
      w.location = phi.url;
      w.focus();
      setRevId(null);
    }
  }, [phi]);

  if (!store.isEnabled) {
    return (
      <>
        <Header title='Forms' />
        <div className={classes.root}>
          <div style={{ padding: '10px 20px' }}>
            <p>
              Create custom forms and have your patients fill them out completely online!
            </p>
          </div>
        </div>
      </>
    );
  }

  const handleCopy = (form: IntakeForm) => () => {
    copyState.invoke({
      ...form,
    }).then((formId) => {
      navigate('/forms-edit/' + formId);
    });
  };

  const handleCopyForm = (form: Form) => () => {
    copyFormState.invoke(form.id).then((revision) => {
      let intakeForm;
      if (revision) {
        intakeForm = revisionToForm(revision);
      } else {
        intakeForm = {
          id: 0,
          name: form.name,
          description: form.description,
          children: [],
          logoUrl: null,
          logoAlignment: null,
          info: null,
          formId: 0,
          conditionals: [],
        };
      }
      intakeForm.sendNotification = form.sendNotification;
      copyState.invoke(intakeForm).then((formId) => {
        navigate('/forms-edit/' + formId);
      });
    });
  };

  const openSettings = (form: Form) => {
    setOpenSetting(true);
    setForm(form);
    setAnchorEl(null);
  };

  const closeSettings = (newForm?: Form) => {
    if (newForm) {
      listState.setState({
        ...listState,
        data: listState.data.map((f) => f.id === form.id ? newForm : f),
      });
    }
    setOpenSetting(false);
    setForm(null);
  };

  const openGlobalSettings = () => {
    setGlobalSettings(true);
  };

  const closeGlobalSettings = () => {
    setGlobalSettings(false);
  };

  const clickPdf = async (form: Form) => {
    setAnchorEl(null);
    pdfState.setState({
      ...pdfState,
      loading: true,
    });
    const {
      conditionals, revisionId, pdf
    } = await fetchFormRevisions(form.id);
    setRevId(revisionId);
    if (!isEmpty(conditionals)) {
      setOpenConds(true);
      setPdf(pdf);
      return;
    }
    w = window.open(`${process.env.APP_URL}/#/loading`, '_blank');
    pdfState.invoke({
      revId: revisionId,
      attachmentId: pdf,
    }).then((url) => {
      w.location = url;
      w.focus();
    });
  };

  const closedDialog = (accept: boolean) => {
    setRevId(null);
    setPdf(null);
    setOpenConds(false);
    if (accept) {
      w = window.open(`${process.env.APP_URL}/#/loading`, '_blank');
      pdfState.invoke({
        revId,
        attachmentId: pdf,
      }).then((url) => {
        w.location = url;
        w.focus();
      });
    }
  };

  return (
    <>
      <Header
        title='Forms'
        pageId="forms-settings"
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={setTab}
            tabs={[
              { value: 'forms', label: 'My Forms', className: 'sked-test-form-builder-my-forms' },
              { value: 'templates', label: 'Templates', className: 'sked-test-form-builder-templates' },
            ]}
          />,
        ]}
        rightIcons={[
          <HeaderButton className='sked-test-form-builder-create' title='Add Form' onClick={handleClickOpen('create')} Icon={AddIcon} />,
          <HeaderButton className='sked-test-form-builder-settings' onClick={openGlobalSettings} Icon={GearIcon} onlyIcon />,
        ]}
        smallTabsWidth={494}
        onlyIconsWidth={440}
      />
      <div>
        <ConditionalsPrintDialog
          open={openConds}
          onClose={closedDialog}
        />
        <SettingsDialog
          open={openSetting}
          form={form}
          onClose={closeSettings}
        />
        <GlobalSettingsDialog
          open={globalSettings}
          onClose={closeGlobalSettings}
        />
        {listState.errorMessage && <div>{listState.errorMessage}</div>}
        <div style={{ paddingBottom: '110px' }} hidden={tab !== 'forms'}>
          {listState.data.length > 0 &&
            <TableContainer
              checkHasScroll={!!listState.data?.length}
              maxHeight={`calc(100vh - ${store.headerHeight}px - 45px - 70px)`}
            >
              <TableHead>
                <TableRow>
                  <HeaderCell fixed>
                      Name
                  </HeaderCell>
                  <HeaderCell>
                      Description
                  </HeaderCell>
                  {store.hasPdf && store.canUpload &&
                      <HeaderCell>
                        Transfer to EHR
                      </HeaderCell>}
                  <HeaderCell>
                      Actions
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listState.data.map(config => {
                  return (
                    <TableRow key={config.id}>
                      <BodyCell fixed>
                        <Link to={`/forms-edit/${config.id}`}>
                          {config.name}
                        </Link>
                      </BodyCell>
                      <BodyCell className={classes.descriptionCell}>
                        {config.description}
                      </BodyCell>
                      {store.hasPdf && store.canUpload &&
                          <BodyCell>
                            {config.pdfEhr === 'OnGeneration' ?
                              'Automatic' :
                              'Manual'}
                          </BodyCell>}
                      <BodyCell>
                        <IconButton
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setForm(config);
                          }}>
                          <MenuIcon />
                        </IconButton>
                        <Spacer />
                      </BodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableContainer>}
          <Menu
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            id="action-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}>
            <MenuItem
              onClick={handleCopyForm(form)}>
              <ListItemIcon>
                <CopyIcon className={classes.copy}/>
              </ListItemIcon>
              <ListItemText primary='Duplicate' />
            </MenuItem>
            <MenuItem
              onClick={() => handleClickModalDelete(form)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary='Delete' />
            </MenuItem>
            {store.hasPdf &&
              <MenuItem
                onClick={() => clickPdf(form)}>
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary='Print' />
              </MenuItem>}
            {((store.hasPdf && store.ehrSystem !== 'None') || store.hasNotifications) &&
              <MenuItem
                onClick={() => openSettings(form)}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='Settings' />
              </MenuItem>}
          </Menu>
        </div>
        <div style={{ paddingBottom: '110px' }} hidden={tab !== 'templates'}>
          {templateState.loading && <CircularProgress />}
          {!isEmpty(templateState.data) &&
            <TableContainer>
              <TableHead>
                <TableRow>
                  <HeaderCell>
                      Template Name
                  </HeaderCell>
                  <HeaderCell>
                      Actions
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templateState.data.map(config => (
                  <TableRow key={config.id}>
                    <BodyCell>
                      {config.name}
                    </BodyCell>
                    <BodyCell>
                      <Button
                        startIcon={<CopyIcon className={classes.copy}/>}
                        onClick={handleCopy(config)}>
                          Duplicate
                      </Button>
                      <Button
                        component="a"
                        target="_blank"
                        href={`${urlBase}intake-preview?previewId=${config.id}`}>
                          Preview
                      </Button>
                      <Spacer />
                    </BodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          }
        </div>
        <Modal
          open={Boolean(open)}
          onClose={handleClose}
          title={`${open === 'create' ? 'Create new' : 'Edit'} form`}
          className={`sked-test-form-builder-${open === 'create' ? 'add' : 'edit'}-modal`}
          buttons={[
            <Grid display="flex" marginRight={1}>
              {creationState.loading && <CircularProgress size={20} />}
            </Grid>,
            <HeaderButton
              title={open === 'create' ? 'Create' : 'Save'}
              onClick={handleCreate}
              color='primary'
              className={`sked-test-new-form-${open === 'create' ? 'add' : 'edit'}-button-save`}
              disabled={creationState.loading || name === ''}
            />
          ]}
        >
          <div>
            <DialogContentText>
              Create a form that your patients and potential patients can fill out and submit online. The name and description are not visible to patients while filling out the form.
            </DialogContentText>
            <Grid direction="row" container justifyContent="space-between">
              <TextField
                autoFocus
                className={
                  classes.inputWidth + ' ' +
                  'sked-test-new-form-name'
                }
                margin="dense"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
                label="Name"
                type="text"
                required
                error={name === ''}
                helperText="Required"
              />
            </Grid>
            <br />
            <TextField
              margin="dense"
              className={
                classes.inputWidth + ' ' +
                'sked-test-new-form-description'
              }
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              label="Description"
              type="text"
            />
            {creationState.errorMessage}
          </div>
        </Modal>
        <Modal
          open={modalDeleteOpen}
          onClose={handleModalDeleteClose}
          size="xs"
          title='Confirm delete'
          className='sked-test-form-builder-action-delete-modal'
          buttons={[
            <Grid display="flex" marginRight={1}>
              {deleteState.loading && <CircularProgress size={20} />}
            </Grid>,
            <HeaderButton
              title='Delete'
              color='danger'
              onClick={confirmDelete}
              disabled={deleteState.loading}
              className='sked-test-form-builder-action-delete-modal-button-delete'
            />
          ]}
        >
          <div>
            <DialogContentText>
              Delete {itemToDelete && itemToDelete.name}? {deleteState.errorMessage}
            </DialogContentText>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default IntakeSettingsList;
