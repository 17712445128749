import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  icon: {
    padding: 5,

    '& svg': {
      fontSize: 22,
    }
  },
  folderIcon: {
    fontSize: 17,
    marginRight: 5
  },
  moreButton: {
    borderRadius: 6,
    fontSize: 20,
    background: 'linear-gradient(90deg, rgba(236, 244, 255, 0) 0%, #ECF4FF 33.96%, #ECF4FF 100%)',
    position: 'absolute',

    right: 0,
    paddingLeft: 8,

    '&:hover': {
      background: 'linear-gradient(90deg, rgba(236, 244, 255, 0) 0%, #ECF4FF 33.96%, #ECF4FF 100%)',
      // background: 'linear-gradient(90deg, #ECF4FF 0%, #c1dafb 33.96%, #c1dafb 100%)',
    }
  },
  folderBox: {
    padding: '2px 8px',
    position: 'relative',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#616166',
    height: 30,
    marginBottom: 3,

    '&:hover': {
      background: '#ECF4FF',
    },
  },
  opened: {
    background: '#ECF4FF',

    '&:hover': {
      background: '#E9F2FF',
    },
  },
  clickablePart: {
    display: 'flex',
    alignItems: 'center',
  },
  wordPart: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 14,
    maxWidth: 180,
  },
}));
