import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import axios from 'axios';
import { TableHead, TableRow, Button, Grid } from '@mui/material';
import api from '../../../../../services/api.js';
import { PopupTemplate, popup } from '../../../../../services/Popup.js';
import { caseType, birthdayFormatter, setQueryParam } from '../../../../../services/utilities.js';
import { TableContainer, HeaderCell, BodyCell } from '../../../../../components/CustomTable';

const perPage = 25;

const GroupTable = (props) => {
  const groupItem = (member) => {
    const without = props.without ? props.without : [];

    return (
      <TableRow key={member.id}>
        <BodyCell>
          <a onClick={() => {
            setQueryParam('cli', member.id, true);
            props.select(member);
          }}>
            {member.firstName} {member.lastName} {caseType(member)}
          </a>
        </BodyCell>
        {!R.includes('birthday', without) &&
          <BodyCell>
            {birthdayFormatter(member.birthday)}
          </BodyCell>}
        {!R.includes('phone', without) &&
          <BodyCell>
            {member.phone}
          </BodyCell>}
        {!R.includes('email', without) &&
          <BodyCell>
            {member.email}
          </BodyCell>}
      </TableRow>
    );
  };

  const {
    open,
    group,
    without = []
  } = props;

  return (
    <div className="scroll-content" style={{ height: open ? '250px' : '430px' }}>
      {group && !R.isEmpty(group) &&
        <Grid marginX={-2}>
          <TableContainer>
            <TableHead>
              <TableRow>
                <HeaderCell>
                  Name
                </HeaderCell>
                {!R.includes('birthday', without) &&
                  <HeaderCell>
                    Birthday
                  </HeaderCell>}
                {!R.includes('phone', without) &&
                  <HeaderCell>
                    Phone
                  </HeaderCell>}
                {!R.includes('email', without) &&
                  <HeaderCell>
                    Email
                  </HeaderCell>}
              </TableRow>
            </TableHead>
            <tbody>
              {group.map(groupItem)}
            </tbody>
          </TableContainer>
        </Grid>
      }
    </div>
  );
};

const CancelToken = axios.CancelToken;
let cancel;
const EditGroup = (props) => {
  const [state, setState] = useState({
    busy: false,
    query: {},
    group: [],
    adds: [],
    removes: [],
  });

  const update = (data) => {
    setState(R.merge(state, data));
  };

  useEffect(() => {
    update({
      group: props.group,
    });
  }, []);

  useEffect(() => {
    if (props.group !== state.group) {
      update({
        group: props.group,
      });
    }
  }, [props.group]);

  const search = (d) => {
    update({
      busy: true,
      query: d.query,
    });
    const { query } = d;
    const splitUp = query.search.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
    const phone = splitUp[2] ? splitUp[2].trim() : undefined;
    const data = R.merge(
      d,
      {
        query: {
          birthday: query.birthday ? query.birthday : undefined,
          lastName,
          firstName,
          phone,
          status: ['Active'],
          sortBy: [{
            direction: 'Asc',
            field: 'LastName'
          }, {
            direction: 'Asc',
            field: 'FirstName'
          }],
          isLead: false,
        },
      });
    if (cancel)
      cancel('User aborted request.');
    api.post('client/query', data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      })
    }).then((clients) => {
      const without = R.filter(
        R.pipe(
          R.includes(R.__, state.group),
          R.not
        ))(clients.data);
      update({
        query: d.query,
        clients: without,
      });
    }).catch((error) => {
      if (axios.isCancel(error)) {
        return;
      } else {
        console.error(error);
        popup('Error!', 'Failed to get clients.');
      }
    });
  };

  const {
    group,
    query,
    clients,
    adds,
    removes,
  } = state;

  const {
    open,
    groupId,
    pushUpdate,
  } = props;

  return (
    <div>
      <Button variant='contained' onClick={() => {
        const data = {};
        const addReqs = R.map(({ id }) => {
          const data = {
            clientId: id,
            groupId,
          };
          return api.post('group/add', data);
        })(adds);
        const removeReqs = R.map(({ id }) => {
          return api.delete(`group/client/${id}`, { data });
        })(removes);
        const reqs = R.concat(addReqs, removeReqs);
        Promise.all(reqs).then(() => {
          const shouldRemoveGroup = R.isEmpty(group);
          if (shouldRemoveGroup) {
            return api.delete(`group/${groupId}`, { data }).then(() => {
              pushUpdate({ group, groupId: undefined });
            });
          } else {
            pushUpdate({ group });
            return null;
          }
        }).catch((error) => {
          console.error(error);
          popup('Error!', 'Failed to add clients to group!');
        });
      }}>
          Save
      </Button>
        &nbsp;
      <Button variant='outlined' onClick={() => pushUpdate()}>
          Cancel
      </Button>
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}>
        <PopupTemplate />
        <div>
          <div style={{
            display: 'flex',
          }}>
            <label>
                Name:
              <br />
              <input
                type='text'
                value={query.search}
                placeholder="LastName FirstName"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== '') {
                    search({
                      query: R.merge(query, { search: value }),
                      perPage,
                      page: 1
                    });
                  } else {
                    update({
                      query: R.merge(query, { search }),
                      page: 1,
                    });
                  }
                }}
              />
            </label>
              &nbsp;
            <label>
                Birthday:
              <br />
              <input
                type='text'
                placeholder='MM/DD/YYYY'
                value={query.birthday}
                onChange={(e) => {
                  const search = e.target.value.trim();
                  const origLen = R.length(query.birthday);
                  const len = R.length(e.target.value);
                  if (len === 10) {
                    search({
                      query: R.merge(query, { birthday: search }),
                      perPage,
                      page: 1
                    });
                  } else if ((origLen === 3 && len === 2) || (origLen === 6 && len === 4)) {
                    update({ query: R.merge(query, { birthday: search }) });
                  } else if (len === 2 || len === 5) {
                    update({ query: R.merge(query, { birthday: search + '/' }) });
                  } else {
                    update({ query: R.merge(query, { birthday: search }) });
                  }
                }}
              />
            </label>
          </div>
          {!R.isEmpty(clients) &&
              <GroupTable
                open={open}
                group={clients}
                without={['email']}
                select={(cli) => {
                  update({ busy: true });
                  api.get(`client/${cli.id}/group`).then((g) => {
                    if (R.isEmpty(g)) {
                      update({
                        busy: false,
                        group: R.append(cli, group),
                        adds: R.append(cli, adds),
                      });
                    } else {
                      const groupString = R.reduce(
                        (acc, v) => {
                          const fullName = v.firstName + ' ' + v.lastName;
                          if (acc === '') {
                            return fullName;
                          } else {
                            return acc + ', ' + fullName;
                          }
                        },
                        '',
                        g
                      );
                      popup('Warning!', 'This client is already in a group with ' + groupString + '! You must remove them from this group to add them to complete this action.');
                      update({
                        busy: false,
                      });
                    }
                  });
                }}
              />}
        </div>
        <div style={{
          width: '1px',
          backgroundColor: '#ccc',
          marginRight: '24px',
          marginLeft: '24px',
        }}>
        </div>
        <div>
          <p>New/Current Group Members: </p>
          <GroupTable
            open={open}
            group={group}
            without={['email']}
            select={(cli) => {
              update({
                group: R.without([cli], group),
                adds: R.without([cli], adds),
                removes: R.includes(cli, props.group) ? R.append(cli, removes) : removes,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Group = (props) => {
  const [state, setState] = useState({
    busy: false,
    stateType: 'GROUP', // Either GROUP EDIT
    group: [],
    groupId: undefined,
  });

  const update = (data) => {
    setState(R.merge(state, data));
  };

  useEffect(() => {
    if (props.group !== state.group) {
      update({
        group: props.group,
        groupId: props.groupId,
      });
    }
  }, [props.group]);

  useEffect(() => {
    update({
      group: props.group,
      groupId: props.groupId,
    });
  }, []);

  const createGroup = (client) => {
    update({ busy: true });
    api.post('group/create', { clientId: client.id })
      .then((groupId) => {
        update({
          group: [client],
          groupId,
          groupState: 'EDIT',
          busy: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const {
    busy,
    stateType,
    group,
    groupId,
  } = state;

  const {
    open,
    selectClient,
    client,
    allowEdit,
    updateGroup,
  } = props;

  return (
    allowEdit ?
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
      }}>
        <PopupTemplate />
        {busy && <div className="loader"></div>}
        {!busy && !groupId &&
          <div>
            <Button variant='contained' onClick={() => {
              createGroup(client);
            }}>
              Create group
            </Button>
          </div>}
        {!busy &&
          stateType === 'GROUP' &&
          groupId &&
          <div>
            <Button variant='contained' onClick={() => {
              update({ stateType: 'EDIT' });
            }}>
              Edit group
            </Button>
          </div>}
        {!busy && groupId && R.has('error')(group) &&
          <div>
            {'Unable to display data: ' + group.error.message}
          </div>}
        {!busy &&
          stateType === 'GROUP' &&
          groupId &&
          !R.has('error')(group) &&
          <GroupTable
            open={open}
            group={group}
            select={selectClient}
          />}
        {!busy &&
            stateType === 'EDIT' &&
            groupId &&
            <EditGroup
              group={group}
              groupId={groupId}
              pushUpdate={(g = undefined) => {
                if (g) {
                  update(
                    R.merge(
                      g,
                      {
                        stateType: 'GROUP',
                      }));
                  updateGroup(R.merge({ groupId, group }, g));
                } else {
                  update({ stateType: 'GROUP' });
                }
              }}
            />}
      </div> :
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
      }}>
        <GroupTable
          open={open}
          group={group}
          select={selectClient}
        />
      </div>
  );
};

export default Group;
