import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  warning: {
    background: '#FCE9EA',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    padding: 15,
    marginTop: 10,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,

    '& span': {
      marginLeft: 10,
      marginRight: 4,
      fontWeight: 700,
    }
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
    color: '#616166',
  }
}));
