import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pipe, path, prop, propOr, map } from 'ramda';

import {
  MenuItem,
  Button,
  Paper,
  TextField,
  FormHelperText,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoopIcon from '@mui/icons-material/Loop';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { getFirstErrorOrDefault, getFormObject, getValue, initForm, requiredValidation, submitForm, updateValue, validateField } from '../../../../services/form-validation.js';
import { skedApi } from '../../../../services/api.js';
import { usePromise } from '../../../../services/promise.hook';
import { successSnackbar, errorSnackbar } from '../../../../components/Snackbar/snackbar.actions';

const saveKey = ({ officeId, key }) => {
  const headers = {
    'X-As-Office': undefined
  };
  return skedApi.post('settings/chirohd/set-chirohd-key', { key, officeId }, { headers }).then(prop('data'));
};

const proItem = (item, select) => (
  <MenuItem
    key={item.id}
    onClick={() => select('defaultPro', item)}
    value={item.id}>
    {item.name}
  </MenuItem>
);

const updateCb = e => e.target.value;

const formObject = {
  key: {
    default: '',
    validation: [
      requiredValidation('An API key is required.')
    ]
  },
};

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
}));

export const ChiroHDIntegrationPage = ({
  proTypes,
  integrationPatch,
  pros,
  defaultPro,
  integrationMiscPatch,
  save,
  table,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const features = useSelector(path(['login', 'features']))

  const { officeId, admin } = useSelector(s => ({
    admin: path(['login', 'admin'], s),
    officeId: path(['login', 'office', 'id'], s)
  }));
  const savedState = usePromise(saveKey, {});

  const [form, setForm] = useState(initForm(formObject));

  const update = (prop = '', cb = updateCb) => e => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const handleOnSubmit = e => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const { key } = getFormObject(validatedForm);

    savedState.invoke({ key, officeId }).then(() => {
      dispatch(successSnackbar('ChiroHD SKED API key saved'));
      setForm(updateValue('key', ''));
    }).catch(() => {
      dispatch(errorSnackbar('Failed to save SKED API key'));
    });
  };
  return (
    <>
      <Header title='EHR Settings' pageId='ehr-settings' />
      <div style={{ margin: '20px' }}>
        {admin && (
          <Paper style={{ padding: '20px' }}>
            <form onSubmit={handleOnSubmit}>
              <div className={classes.formControl}>
                <FormHelperText>
                  Enter the ChiroHD SKED API here
                </FormHelperText>
              </div>
              <div className={classes.formControl}>
                <TextField
                  autoFocus
                  fullWidth
                  style={{ width: '500px' }}
                  multiline
                  id="chirohd-key"
                  label="Key"
                  type="text"
                  helperText={getFirstErrorOrDefault('key', form)}
                  error={!form.key.isValid}
                  value={getValue('key', form)}
                  onChange={update('key')}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                disabled={savedState.loading}
                startIcon={savedState.loading ? <LoopIcon /> : null}
                color="primary">
                {savedState.loading ? 'Loading...' : 'Save'}
              </Button>
              {savedState.errorMessage &&
                <div>
                  Error: {savedState.errorMessage}.  Couldn't save ChiroHD key.  It may be wrong or SKED may not be able to reach ChiroHD's servers.
                </div>}
            </form>
          </Paper>
        )}

        <Paper style={{ padding: '20px', marginTop: '10px' }}>
          <PageHeader variant='h6'>Defaults</PageHeader>
          {pros &&
            <div>
              <PageHeader variant='h6'>Professional</PageHeader>
              <TextField
                select
                value={propOr(null, 'id')(defaultPro)}>
                {map(
                  (pro) =>
                    proItem(pro, integrationMiscPatch))(pros)}
              </TextField>
            </div>}
          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
          <PageHeader variant='h6'>
            Appointment Types
          </PageHeader>
          {proTypes && table(proTypes, integrationPatch)}
          <Button
            variant='contained'
            onClick={() => {
              save(proTypes, 'chirohd', defaultPro);
            }}>
            Save
          </Button>
        </Paper>
      </div>
    </>
  );
};
