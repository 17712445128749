import React, { useEffect, useState } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalDate } from '@js-joda/core';
import * as R from 'ramda';
import { TableRow } from '../CustomTable';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { Appointment, Professional } from '../../routes/Appointments/appointments.types';
import ProfessionalIcon from '../../icons/Professional.icon';
import { pickColor } from '../../services/utilities';
import { tzParseFormat } from '../../services/joda';

import { useStyles } from './ApptRequest.styles';

type ApptCardProps = {
  appointment: Appointment;
  tz?: string;
  professional?: Professional;
  selecteds?: Appointment[];
  setSelecteds?: (appts: Appointment[]) => void;
  onClick?: (appt: Appointment) => void;
  onReject?: (apptId: number) => void;
  onApprove?: (id: number) => void;
  minutesUntilMissed?: number;
  showName?: boolean;
}

const ApptCard = ({
  appointment, selecteds, professional, onReject, showName,
  tz, setSelecteds, onClick, minutesUntilMissed, onApprove,
}: ApptCardProps) => {
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState(false);

  const getDate = (strDate: string) => {
    if (!strDate) {
      return '';
    }
    const date = tzParseFormat(strDate, tz, 'eee, MM/dd/yyyy h:mm a');
    return date.replace(' AM', 'a').replace(' PM', 'p');
  };

  const getStatus = (apptStatus: object) => {
    const statusList = Object.keys(apptStatus);
    const status = statusList?.length ? statusList[0] : '';

    if (status === 'Scheduled') {
      const days = minutesUntilMissed >= 60 ? Math.trunc(minutesUntilMissed / 60) : 0;
      const appointmentDate = LocalDate.parse(tzParseFormat(appointment.time, tz, 'yyyy-MM-dd')).plusDays(days);
      if (appointment.time && LocalDate.now().isAfter(appointmentDate)) {
        return (
          <span className={classes.statusRed}>
            Missed
          </span>
        );
      }
      return (
        <span className={classes.statusGray}>
          {status}
        </span>
      );
    }
    if (status === 'Arrived') {
      return (
        <span className={classes.statusGreen}>
          {status}
        </span>
      );
    }
    if (status === 'Missed' || status === 'Canceled' || status === 'Deleted') {
      return (
        <span className={classes.statusRed}>
          {status}
        </span>
      );
    }
    return null;
  };

  const handleReject = () => {
    setSelecteds?.([appointment]);
    setTimeout(() => {
      onReject?.(appointment.id);
    }, 200);
  };

  useEffect(() => {
    if (!appointment) {
      return;
    }
    const isConfirmed = R.has('Confirmed', appointment?.bookingStatus);
    setConfirmed(isConfirmed);
  }, [appointment]);

  return (
    <TableRow
      noSelectHover
      className={`${classes.card} ${classes.unread}`}
      checked={!!selecteds?.find(item => item.id === appointment.id)}
      setChecked={(checked) => {
        if (checked) {
          setSelecteds([...selecteds, appointment]);
        } else {
          setSelecteds(selecteds?.filter(item => item.id !== appointment.id));
        }
      }}
    >
      <Grid className={classes.unreadHead}>
        <Typography component="span" className={classes.apptName}>
          New Appt Request
        </Typography>
        <Grid display="flex" alignItems="center">
          {!!onReject && (
            <HeaderButton
              title='Reject'
              borderSolid
              className={classes.reject}
              marginLeft={10}
              onClick={handleReject}
            />
          )}
          {!!onApprove && (
            <Tooltip
              title="Approve Appt Request and convert this Lead into a Client"
              placement='bottom'
              arrow
            >
              <div>
                <HeaderButton
                  title='Approve'
                  color='primary'
                  marginRight={-5}
                  onClick={() => onApprove?.(appointment.client.id)}
                />
              </div>
            </Tooltip>
          )}
        </Grid>    
      </Grid>
      <Grid
        onClick={() => onClick?.(appointment)}
        className={classes.unreadBody}
      >
        {showName && (
          <Typography className={classes.apptName} marginBottom={0.5}>
            {appointment.client.firstName} {appointment.client.lastName}
          </Typography>
        )}
        <Grid display="flex" alignItems="center">
          <div style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: pickColor(appointment.appointmentType.color),
            marginRight: '5px',
          }}
          />
          <Typography component="span" className={classes.apptName}>
            {appointment.appointmentType?.internalName}
          </Typography>
          {!R.isEmpty(professional) && (
            <Grid display="flex" alignItems="center" paddingLeft="5px">
              <Grid display="flex">
                <ProfessionalIcon color='rgba(0,0,0,0.6)' className={classes.proIcon} />
              </Grid>
              <Typography className={classes.proName}>
                {professional?.firstName + ' ' + professional?.lastName} <span>&nbsp;</span>
              </Typography>
              {confirmed && (
                <Tooltip placement="top" arrow title={'Confirmed'}>
                  <CheckCircleIcon style={{ fontSize: 16, color: '#65B67F' }} />
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
        <Grid display="flex" alignItems="center">
          <Typography className={classes.time}>
            {getDate(appointment.time)} {getStatus(appointment.status)}
          </Typography>
        </Grid>
      </Grid>
    </TableRow>
  );
};

export default ApptCard;
