import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import hb from 'honeybadger-js';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './reducers';
import './styles/main.css';

hb.configure({
  apiKey: process.env.REACT_ADMIN_HB_KEY,
  environment: process.env.REACT_ADMIN_HB_ENV
});

const router = createHashRouter([
  {
    path: '/*',
    element: <App />,
  },
]);

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
