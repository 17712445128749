import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  IconButton, Tooltip, Menu, MenuItem, Divider, Grid,
  CircularProgress, ListItemIcon, ListItemText, List, ListItem,
  Typography, Drawer,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/MoreVert';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { usePromise } from './../../services/promise.hook';
import api from '../../services/api.js';
import { DriveFileMoveIcon, FolderOffIcon } from '../../icons/CustomIcons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import {
  isNil, adjust, evolve, remove, isEmpty, pathOr, includes, dropLast
} from 'ramda';
import { Row } from '../PageHeader';
import { Folder, FolderItem, AddDialog, DeleteDialog } from './FolderItem.component';
import { useSelector } from '../../reducers';
import { successSnackbar, errorSnackbar } from '../Snackbar/snackbar.actions';

import { useStyles } from './sidebarFolders.styles';

/*
   Path/Folders shorthand dictionary:
   Message: msg
   Templates: tmp
   Automations: au
   One-Time: ot
   Recurring: rc
   Reminder: rm
   Birthday: br
   Reactivation: rv
   Rapid: rp
   ClientList: cl
   Campaign: cmp

   Automation Based Messages end with fdr for folder:
   i.e.: ['msg', 'rcr', 'smart_reply', 'fdr']
*/

interface FoldersProps {
  path: string[];
  onClick: (path: string[], noFolder?: boolean) => void;
  template?: boolean;
  deselect?: boolean;
  open: boolean;
  onClose: () => void;
  setSelectedId: (v: number) => void;
  selectedId?: number;
  noFolder?: boolean;
  hasNoFolder?: boolean;
}

interface FetchFolderType {
  hasSubpath: string[];
  template?: boolean;
  page?: number;
  perPage?: number;
}

interface FolderQueryType {
  data: Folder[],
  page: number,
  totalPages: number,
  totalCount: number,
}

interface MoveToFolderProps {
  open: boolean;
  path: string[];
  messages: any[];
  template?: boolean;
  onMove: (path?: string[]) => void;
}

interface MoveToFolderType {
  path: string[];
  msgIds?: number[];
  template?: boolean;
}

interface CurrentPath {
  [path: string]: string[];
}

export let currentPath: CurrentPath = {};

const ALL_ITEMS = {
  hash: null as string,
  path: [] as string[],
  pathId: 0,
  resource: {
    PathLabel: {
      created: '',
      id: 0,
      label: 'All Items'
    }
  }
};

const NO_FOLDER = {
  hash: null as string,
  path: [] as string[],
  pathId: -1,
  resource: {
    PathLabel: {
      created: '',
      id: -1,
      label: 'Unassigned'
    }
  }
};

export const FOLDERS_WIDTH = 240;

const fetchFolders = ({ hasSubpath, template, page, perPage = 20 }: FetchFolderType): Promise<FolderQueryType> => {
  const body = {
    page: page ? page : 1,
    perPage,
    query: {
      isDirectDescendant: hasSubpath,
    },
  };
  const url = template ? 'templates/paths/query' : 'paths/query';
  return api.post(url, body);
};

const moveToFolder = ({ path, msgIds, template }: MoveToFolderType): Promise<any[]> => {
  const url = template ? 'templates/paths' : 'paths';
  const stringPath = dropLast(1, path).join('.');
  return Promise.all(
    msgIds.map(async (id) => {
      const resource = {
        Message: id
      };
      if (currentPath[stringPath]) {
        const oldPath = currentPath[stringPath].concat(['msg_' + id]);
        const uriPath = oldPath.join('.');
        const pathsUrl = template ? `templates/paths/path/${uriPath}` : `paths/path/${uriPath}`;
        await api.delete(pathsUrl, { data: {} });
      }
      const newPath = path.concat(['msg_' + id]);
      const uriPath = newPath.join('.');
      const pathsUrl = template ? `templates/paths/path/${uriPath}` : `paths/path/${uriPath}`;
      try {
        const p = await api.get(pathsUrl).catch(() => '');
        if (p?.id) {
          return api.delete(pathsUrl, { data: {} }).then(() => {
            return api.post(url, { path: newPath, resource });
          });
        }
        return api.post(url, { path: newPath, resource });
      } catch (e) {
        return api.post(url, { path: newPath, resource });
      }
    }));
};

const removeFromFolder = ({ path, msgIds, template }: MoveToFolderType): Promise<any[]> => {
  return Promise.all(msgIds.map((id) => {
    const newPath = path.concat(['msg_' + id]);
    const uriPath = newPath.join('.');
    const pathsUrl = template ? `templates/paths/path/${uriPath}` : `paths/path/${uriPath}`;
    return api.delete(pathsUrl, { data: {} });
  }));
};

const defaultFolderQueryData: FolderQueryType = {
  data: [],
  totalCount: 0,
  totalPages: 1,
  page: 1,
};

export const RemoveFromFolder = ({ open, path, messages, template, onMove }: MoveToFolderProps) => {
  const dispatch = useDispatch();
  const {
    hasFeature,
    isAdmin
  } = useSelector((state) => ({
    hasFeature: includes('Folders', pathOr([], ['login', 'features'], state)),
    isAdmin: isEmpty(state.login.office) ? state.login.admin : false,
  }));
  const removeState = usePromise<MoveToFolderType, any[]>(removeFromFolder, null);
  const stringPath = path?.join('.');
  const handleClick = () => {
    removeState.invoke({
      path: currentPath[stringPath],
      msgIds: messages.filter(({ selected }: any) => selected).map((message: any) => message.id),
      template,
    }).then(() => {
      dispatch(successSnackbar('Successfully Removed Messages!'));
      onMove && onMove(currentPath[stringPath]);
    }).catch((e) => {
      console.log(e);
      dispatch(errorSnackbar('Failed to Remove Messages!'));
    });
  };
  if (!currentPath[stringPath]) {
    return <div></div>;
  }
  if (!hasFeature && !isAdmin) {
    return (
      <div />
    );
  }
  return (
    <div>
      <Tooltip title='Remove from Folder'>
        <IconButton
          onClick={handleClick}
          style={{
            display: open ? 'flex' : 'none',
          }}
        >
          <FolderOffIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const MoveToFolder = ({ open, path, messages, template, onMove }: MoveToFolderProps) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    hasFeature,
    isAdmin,
  } = useSelector((state) => ({
    hasFeature: includes('Folders', pathOr([], ['login', 'features'], state)),
    isAdmin: isEmpty(state.login.office) ? state.login.admin : false,
  }));
  const foldersState = usePromise<FetchFolderType, FolderQueryType>(fetchFolders, defaultFolderQueryData);
  const moveState = usePromise<MoveToFolderType, any[]>(moveToFolder, null);

  useEffect(() => {
    if (open || anchorEl) {
      foldersState.invoke({
        hasSubpath: path,
        template,
        perPage: 100,
      });
    }
  }, [open, anchorEl, path]);

  const handleFolderMove = (path: string[]) => () => {
    setAnchorEl(null);
    const curPath = currentPath[dropLast(1, path).join('.')];
    moveState.invoke({
      path,
      msgIds: messages.filter(({ selected }: any) => selected).map((message: any) => message.id),
      template,
    }).then(() => {
      dispatch(successSnackbar('Successfully Moved Messages!'));
      onMove(curPath);
    }).catch((e) => {
      console.log(e);
      if (e?.response?.status === 404) {
        dispatch(successSnackbar('Successfully Moved Messages!'));
        onMove(curPath);
      } else {
        dispatch(errorSnackbar('Failed to Moved Messages!'));
      }
    });
  };

  if (!hasFeature && !isAdmin) {
    return (
      <div />
    );
  }

  return (
    <div>
      <Tooltip title='Move to Folder'>
        <IconButton>
          {moveState.loading ?
            <CircularProgress size={20} />
            :
            <DriveFileMoveIcon onClick={(e) => setAnchorEl(e.currentTarget)} />}
        </IconButton>
      </Tooltip>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem disabled={true}>
          Select Folder
        </MenuItem>
        <Divider />
        {foldersState.data.data.map(({ resource, path, pathId }: Folder) => {
          if (typeof resource?.PathLabel === 'number')
            return;
          const name = resource?.PathLabel?.label;
          return (
            <MenuItem
              key={pathId}
              onClick={handleFolderMove(path)}>
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const Sidebarfolders = ({
  path, onClick, template, deselect, open, onClose, selectedId,
  setSelectedId, noFolder, hasNoFolder = true,
}: FoldersProps) => {
  const classes = useStyles();
  const [folders, setFolders] = useState<Folder[]>([]);
  const foldersState = usePromise<FetchFolderType, FolderQueryType>(fetchFolders, defaultFolderQueryData);
  const [isAdd, setIsAdd] = useState(false);
  const [showingMore, setShowingMore] = useState(false);
  const {
    isAdmin,
    hasFeature,
    isMobile,
    headerHeight,
  } = useSelector((state) => ({
    isAdmin: isEmpty(state.login.office) ? state.login.admin : false,
    hasFeature: includes('Folders', pathOr([], ['login', 'features'], state)),
    isMobile: state.login.isMobile,
    headerHeight: state.login.headerHeight,
  }));

  const handleClick = (path: string[], noFol?: boolean) => {
    onClick(path, noFol);
    if (isMobile) {
      onClose();
    }
  };

  /* useMemo doesn't work with arrays */
  const stringPath = path.join('.');

  const hasSubpath = React.useMemo(() => {
    return path;
  }, [stringPath]);

  useEffect(() => {
    foldersState.invoke({
      hasSubpath,
      template,
    }).then(({ data }: FolderQueryType) => {
      setFolders(data);
    });
    return () => {
      currentPath = {};
    };
  }, [hasSubpath]);

  useEffect(() => {
    if (deselect)
      setSelectedId(0);
  }, [deselect]);
  
  useEffect(() => {
    if (noFolder) {
      setSelectedId(-1);
    }
  }, [noFolder]);

  const handleClose = (shouldReload = false) => {
    if (shouldReload) {
      foldersState.invoke({
        hasSubpath,
        template,
        page: 1,
        perPage: showingMore ? 100 : 20,
      }).then(({ data }: FolderQueryType) => {
        setFolders(data);
      });
    }
    setIsAdd(false);
  };

  const onShowMore = () => {
    foldersState.invoke({
      hasSubpath,
      template,
      page: 1,
      perPage: 100,
    }).then(({ data }: FolderQueryType) => {
      setFolders(data);
      setShowingMore(true);
    });
  };

  const onShowLess = () => {
    foldersState.invoke({
      hasSubpath,
      template,
      page: 1,
    }).then(({ data }: FolderQueryType) => {
      setFolders(data);
      setShowingMore(false);
    });
  };

  const showMore = React.useMemo(() => {
    return foldersState.data?.page < foldersState.data?.totalPages && !showingMore;
  }, [foldersState.data, showingMore]);

  const updateFolder = (idx: number) => (name: string) => {
    if (isNil(name)) {
      foldersState.invoke({
        hasSubpath,
        template,
      });
    } else {
      const newFolders = name === '' ?
        remove(idx, 1, folders)
        :
        adjust(idx, evolve({
          resource: ({ PathLabel }) => ({
            PathLabel: {
              ...PathLabel,
              label: name,
            }
          }),
        }), folders);
      setFolders(newFolders);
    }
  };

  if (!hasFeature && !isAdmin) {
    return (
      <div />
    );
  }

  const content = (
    <Grid
      width={open ? FOLDERS_WIDTH : 0}
      height={isMobile ? '100%' : `calc(100vh - ${headerHeight + 45}px)`}
      className={classes.root}
    >
      <Grid className={classes.header}>
        <Grid display="flex" alignItems="center">
          <IconButton
            onClick={onClose}
            className={classes.icon}
          >
            <BackIcon />
          </IconButton>
          <Typography className={classes.title}>
            Folders
          </Typography>
        </Grid>
        {(isAdmin || !template) ?
          <Tooltip arrow title='Add Folder' placement='top'>
            <IconButton
              className={classes.icon}
              onClick={() => setIsAdd(true)} >
              <AddIcon />
            </IconButton>
          </Tooltip>
          : <div />
        }
      </Grid>
      <div className={classes.container}>
        <AddDialog
          open={isAdd}
          onClose={handleClose}
          folder={{
            path,
            pathId: 0,
            resource: {},
          }}
          template={template}
        />
        <FolderItem
          folder={ALL_ITEMS}
          onClick={() => {
            if (selectedId === 0) {
              if (isMobile) {
                onClose();
              }
              return;
            }
            handleClick(null);
            setSelectedId(0);
            currentPath = {
              ...currentPath,
              [path.join('.')]: undefined,
            };
          }}
          path={path}
          selectedId={selectedId ? selectedId : 0}
          disableMenu
        />
        {hasNoFolder && (
          <FolderItem
            folder={NO_FOLDER}
            onClick={(f) => {
              if (selectedId === -1) {
                handleClick(null);
                setSelectedId(0);
                currentPath = {
                  ...currentPath,
                  [path.join('.')]: undefined,
                };
                return;
              }
              handleClick(f.path, true);
              setSelectedId(-1);
              currentPath = {
                ...currentPath,
                [path.join('.')]: undefined,
              };
            }}
            path={path}
            selectedId={selectedId ? selectedId : 0}
            disableMenu
          />
        )}
        {/* )} */}
        {folders.map((folder, idx) => {
          return (
            <FolderItem
              key={folder.pathId}
              folder={folder}
              onClick={(f) => {
                if (f) {
                  handleClick(f.path);
                  setSelectedId(f.pathId);
                  currentPath = {
                    ...currentPath,
                    [path.join('.')]: f.path,
                  };
                  return;
                }
                handleClick(null);
                setSelectedId(0);
                currentPath = {
                  ...currentPath,
                  [path.join('.')]: undefined,
                };
              }}
              path={path}
              template={template}
              update={updateFolder(idx)}
              selectedId={selectedId}
              disableMenu={!(isAdmin || !template)}
            />
          );
        })}
        {showMore &&
          <div
            // className={classes.otherDivs}
            style={{
              justifyContent: 'flex-start',
              marginLeft: '5px',
            }}>
            <a onClick={onShowMore}>
              Show More...
            </a>
          </div>}
        {showingMore &&
          <div
            // className={classes.otherDivs}
            style={{
              justifyContent: 'flex-start',
              marginLeft: '5px',
            }}>
            <a onClick={onShowLess}>
              Show Less...
            </a>
          </div>}
      </div>
    </Grid>
  );

  if (isMobile) {
    return (
      <Drawer open={open} onClose={onClose}>
        {content}
      </Drawer>
    );
  }
  return content;
};

interface FoldersListProps {
  paths: Folder[];
  selected: number;
  onClick: (pathId: number) => void;
  allowMutation?: boolean;
  updatePathList?: (idx: number, newPath?: Folder) => void;
  getPaths?: () => void;
}

export const FoldersList = ({
  paths,
  selected,
  onClick,
  allowMutation,
  updatePathList,
  getPaths,
}: FoldersListProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [edit, setEdit] = useState(null);
  const [deleteF, setDelete] = useState(null);
  const [selectedPath, setSelectedPath] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [add, setAdd] = useState(false);

  const openMenu = (path: Folder, idx: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedPath(path);
    setSelectedIdx(idx);
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const openEdit = (path: Folder) => () => {
    setEdit(path);
    setAnchorEl(null);
  };
  const openDelete = (path: Folder) => () => {
    setDelete(path);
    setAnchorEl(null);
  };
  const handleClose = (name: string) => {
    if (updatePathList) {
      if (name === '') {
        setDelete(null);
        updatePathList(selectedIdx, null);
      } else {
        setEdit(null);
        updatePathList(selectedIdx, {
          ...edit,
          resource: {
            PathLabel: {
              ...edit.resource.PathLabel,
              label: name,
            }
          }
        });
      }
    }
  };
  const newFolder = () => {
    setAdd(true);
  };
  return (
    <div className={classes.templatePathContainer}>
      <AddDialog
        open={add}
        onClose={(update) => {
          setAdd(false);
          if (update)
            getPaths();
        }}
        folder={{
          path: ['tmp', 'auto'],
          pathId: 0,
          resource: {
            PathLabel: 0
          },
        }}
        template
      />
      {deleteF &&
        <DeleteDialog
          id={deleteF.pathId}
          open={Boolean(deleteF)}
          onClose={handleClose}
          template
          recursive
          folder={deleteF}
        />}
      {edit &&
        <AddDialog
          open={Boolean(edit)}
          onClose={handleClose}
          folder={edit}
          id={edit.pathId}
          template
        />}
      <Row>
        <div style={{
          width: '10%',
          borderBottom: 'gray solid 1px',
        }}></div>
        &nbsp;
        <div className={classes.headerTitles}>
          Folders
        </div>
        &nbsp;
        <div style={{
          width: '100%',
          borderBottom: 'gray solid 1px',
        }}></div>
        <IconButton style={{ marginRight: '12px' }} onClick={newFolder}>
          <CreateNewFolderIcon />
        </IconButton>
      </Row>
      <List>
        {paths.map((path, idx) => {
          return (
            <ListItem
              key={path.pathId}
              className={classes.pathListItem}
              style={selected === path.pathId ? {
                backgroundColor: '#008BCF36',
              } : {}}
              onClick={() => onClick(path.pathId)}>
              <ListItemText>
                {pathOr('Unassigned', ['resource', 'PathLabel', 'label'], path)}
              </ListItemText>
              {path.pathId !== 0 && allowMutation &&
                <IconButton onClick={openMenu(path, idx)}>
                  <MenuIcon style={{ fontSize: '18px' }} />
                </IconButton>}
            </ListItem>
          );
        })}
      </List>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        <MenuItem onClick={openEdit(selectedPath)}>
          <ListItemIcon>
            <EditIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={openDelete(selectedPath)}>
          <ListItemIcon>
            <DeleteIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Sidebarfolders;
