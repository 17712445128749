import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as rawActions from './templates.actions';
import { reset, messagesPatch } from './routes/TemplatesEdit/templates-edit.actions';
import {
  Button, Checkbox, Tabs, Tab, TableContainer, Table, TableHead, TableRow,
  TableBody, TableCell, IconButton, Menu, MenuItem, ListItemText,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  format,
} from '../../services/joda.js';
import {
  LocalDateTime,
} from '@js-joda/core';
import * as R from 'ramda';
import { useSelector } from '../../reducers';
import { Message } from './templates.reducer';
import { Folders } from '../../components/Folders/folders.component';
import { MoveToFolder, RemoveFromFolder } from '../../components/SidebarFolders/SidebarFolders.component';
import TrashIcon from '@mui/icons-material/Delete';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: '20px',
    width: 'auto',
    '& table tr td': {
      fontSize: '13px'
    },
    '& table tr th': {
      fontSize: '13px'
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingRight: '20px'
    },
    '& .MuiTableCell-sizeSmall:first-child': {
      paddingLeft: '20px'
    }
  },
}));

const anySelected = (messages: Message[]) => R.pipe(
  R.filter((message: Message) => message.selected),
  (items: Message[]) => R.length(items),
  R.equals(0),
  R.not
)(messages);

const fixLength = (str: string) => {
  if (str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

type MessageTableItem = {
  message: Message,
  select: ({ value }: { value: boolean }) => void,
  loadMessage: (message: Message) => void,
  copyMessage: (message: Message) => void,
  deleteMessage: ({ id }: { id: number }) => void,
}

const MessageTableItem = ({
  message,
  select,
  loadMessage,
  copyMessage,
  deleteMessage,
}: MessageTableItem) => {
  return (
    <TableRow
      key={message.id}>
      <TableCell>
        <Checkbox
          checked={R.propOr(false, 'selected')(message)}
          onChange={() => select({ value: !R.propOr(false, 'selected')(message) })}
        />
      </TableCell>
      <TableCell>
        <a style={{ cursor: 'pointer' }} onClick={() => loadMessage(message)}>{message.name}</a>
      </TableCell>
      <TableCell>
        {R.cond([
          [R.prop('email'), () => fixLength(message.email.subject)],
          [R.prop('push'), () => fixLength(message.push.subject)],
          [R.prop('sms'), () => ''],
          [R.T, () => message.subject && fixLength(message.subject)],
        ])(message)}
      </TableCell>
      <TableCell>
        <span style={{ display: 'flex', width: '100%', height: '100%' }}>
          {message.sms &&
            <span style={{ marginRight: '5px', height: '100%', display: 'flex', alignItems: 'center' }}>
              <ChatBubbleIcon style={{ fontSize: '20px' }} />
            </span>}
          {message.email &&
            <span style={{ marginRight: '5px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
              <EmailIcon style={{ fontSize: '20px' }} />
            </span>}
          {message.push &&
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <SmartphoneIcon style={{ fontSize: '20px' }} />
            </span>}
        </span>
      </TableCell>
      <TableCell>
        {R.cond([
          [R.equals('HtmlEmail'), R.always('HTML')],
          [R.equals('NewMessaging'), R.always('PlainText')],
          [R.T, R.always('Old')],
        ])(message.forFeature)}
      </TableCell>
      <TableCell>
        {format(LocalDateTime.parse(R.dropLast(1, message.createdAt)), 'MM/dd/yyyy h:mm a')}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => copyMessage(message)} >
          <FileCopyIcon />
        </IconButton>
        <IconButton onClick={() => deleteMessage({ id: message.id })}>
          <TrashIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

type MessageTableProps = {
  messages: Message[];
  all: boolean;
  selectAll: ({ value }: { value: boolean }) => void;
  selectMessage: ({ value, type, index }: { type?: string, index?: number, value?: boolean }) => void;
  copyMessage: (message: Message) => void;
  deleteMessage: ({ id }: { id: number }) => void;
  deleteSelected: ({ messages }: { messages: Message[] }) => void;
  loadMessage: (message: Message) => void;
  path?: string[];
  search: (path?: string[]) => void;
}

const MessageTable = ({
  messages,
  all,
  selectAll,
  selectMessage,
  copyMessage,
  deleteMessage,
  deleteSelected,
  loadMessage,
  path,
  search,
}: MessageTableProps) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.tableContainer}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                name="all"
                checked={all}
                onChange={() => selectAll({ value: !all })}
              />
            </TableCell>
            {anySelected(messages) ?
              <TableCell style={{ display: 'flex' }}>
                <MoveToFolder
                  template
                  open={anySelected(messages)}
                  path={path}
                  messages={messages}
                  onMove={search}
                />
                <RemoveFromFolder
                  template
                  open={anySelected(messages)}
                  path={path}
                  messages={messages}
                  onMove={search}
                />
                <IconButton
                  style={{
                    fontSize: '18px',
                    marginTop: '7px',
                  }}
                  onClick={() => deleteSelected({ messages })}>
                  <TrashIcon />
                </IconButton>
              </TableCell>
              :
              <TableCell>
                Name
              </TableCell>}
            <TableCell>
              Subject
            </TableCell>
            <TableCell>
              Send Via
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Created
            </TableCell>
            <TableCell>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map(
            (message, index) => (
              <MessageTableItem
                message={message}
                select={R.pipe(R.assoc('index', index), selectMessage)}
                loadMessage={loadMessage}
                copyMessage={copyMessage}
                deleteMessage={deleteMessage}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Templates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = bindActionCreators(rawActions, dispatch);
  const [tab, setTab] = useState('OneTime');
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    busy, all, OneTime, Recurring, Recall, Birthday, ApptChange,
    Automation, AutomationBased, state, admin, loadId, autoBusy,
  } = useSelector((state) => ({
    ...state.templates,
    admin: state.login.admin,
  }));
  useEffect(() => {
    actions.getMessages();
    dispatch(reset());
  }, []);
  useEffect(() => {
    // `tab` is set to the path for automation based tabs
    // 
    if (tab !== 'Automation')
      actions.getAutomationBasedMessages(tab);
  }, [tab]);
  const handleClick = (messageType: string) => (path: string[]) =>
    actions.search({
      page: 1,
      messageType,
      path,
    });
  const onMove = (messageType: string, path: string[]) => {
    actions.search({
      page: 1,
      messageType,
      path,
    });
  };
  useEffect(() => {
    if (loadId) {
      navigate(`/template/edit/${loadId}${tab === 'Automation' ? '?variant=smart-reply' : ''}`);
      actions.messagesPatch('loadId', 0);
    }
  }, [loadId]);

  useEffect(() => {
    if (!admin) {
      navigate('/dashboard');
    }
  }, [admin]);

  useEffect(() => {
    if (state === 'COPY' && tab.indexOf('.') === -1) {
      navigate(`/template/edit/0${tab === 'Automation' ? '?variant=smart-reply' : ''}`);
    }
  }, [state]);

  const uri = useMemo(() => {
    if (tab.indexOf('.') === -1) {
      return '/template/edit/0';
    } else {
      return `/automation-based-template/edit/0?path=${tab}`;
    }
  }, [tab]);

  const redirectToAutoBasedTemplates = (t: string) => (m: Message) => {
    if (t === 'COPY') {
      navigate(`/automation-based-template/edit/0?path=${tab}&fromTemplate=${m.id}&copying=${m.id}`);
    } else
      navigate(`/automation-based-template/edit/${m.id}?path=${tab}`);
  };

  return (
    <div style={{ paddingBottom: 75 }}>
      {busy && <div className="loader"></div>}
      {!busy &&
        <div>
          <h3>
            <div className="title">Templates</div>
          </h3>
          <div>
            {tab === 'Automation' ?
              <Button
                variant="contained"
                style={{ margin: '10px' }}
                onClick={(e) => setAnchorEl(e.currentTarget)}>
              Compose
              </Button>
              :
              <Link to={uri}>
                <Button
                  variant="contained"
                  style={{ margin: '10px' }}>
                Compose
                </Button>
              </Link>}
          </div>
          <div>
            <Tabs
              value={tab}
              onChange={(e, val) => {
                setTab(val);
                dispatch(messagesPatch('templateType', val));
              }}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab value='OneTime' label="One Time" />
              <Tab value='Recurring' label="Reminder" />
              <Tab value='Recall' label="Reactivation" />
              <Tab value='Birthday' label="Birthday" />
              <Tab value='ApptChange' label="Rapid" />
              <Tab value='Automation' label="Automation" />
              <Tab value='tmp.msg.rcr.smart_reply' label="Smart Reply" />
              <Tab value='tmp.msg.rcr.time_based' label="Time Based" />
              <Tab value='tmp.msg.rcr.spark' label="Spark" />
            </Tabs>
            <div hidden={tab !== 'OneTime'}>
              <Folders
                path={['tmp', 'msg', 'ot']}
                onClick={handleClick('OneTime')}
                template
              />
              <MessageTable
                messages={OneTime}
                all={all}
                selectAll={R.pipe(R.assoc('type', 'OneTime'), actions.selectAll)}
                selectMessage={R.pipe(R.assoc('type', 'OneTime'), actions.selectMessage)}
                copyMessage={actions.copyMessage}
                deleteMessage={R.pipe(R.assoc('type', 'OneTime'), actions.deleteMessage)}
                deleteSelected={R.pipe(R.assoc('type', 'OneTime'), actions.deleteSelected)}
                loadMessage={actions.loadMessage}
                path={['tmp', 'msg', 'ot']}
                search={(path?: string[]) => onMove('OneTime', path || ['tmp', 'msg', 'ot'])}
              />
            </div>
            <div hidden={tab !== 'Recurring'}>
              <Folders
                path={['tmp', 'msg', 'rcr', 'rm']}
                onClick={handleClick('Recurring')}
                template
              />
              <MessageTable
                messages={Recurring}
                all={all}
                selectAll={R.pipe(R.assoc('type', 'Recurring'), actions.selectAll)}
                selectMessage={R.pipe(R.assoc('type', 'Recurring'), actions.selectMessage)}
                copyMessage={actions.copyMessage}
                deleteMessage={R.pipe(R.assoc('type', 'Recurring'), actions.deleteMessage)}
                deleteSelected={R.pipe(R.assoc('type', 'Recurring'), actions.deleteSelected)}
                loadMessage={actions.loadMessage}
                path={['tmp', 'msg', 'rcr', 'rm']}
                search={(path?: string[]) => onMove('Recurring', path || ['tmp', 'rcr', 'rm'])}
              />
            </div>
            <div hidden={tab !== 'Recall'}>
              <Folders
                path={['tmp', 'msg', 'rcr', 'rc']}
                onClick={handleClick('Recall')}
                template
              />
              <MessageTable
                messages={Recall}
                all={all}
                selectAll={R.pipe(R.assoc('type', 'Recall'), actions.selectAll)}
                selectMessage={R.pipe(R.assoc('type', 'Recall'), actions.selectMessage)}
                copyMessage={actions.copyMessage}
                deleteMessage={R.pipe(R.assoc('type', 'Recall'), actions.deleteMessage)}
                deleteSelected={R.pipe(R.assoc('type', 'Recall'), actions.deleteSelected)}
                loadMessage={actions.loadMessage}
                path={['tmp', 'msg', 'rcr', 'rc']}
                search={(path?: string[]) => onMove('Recall', path || ['tmp', 'rcr', 'rc'])}
              />
            </div>
            <div hidden={tab !== 'Birthday'}>
              <Folders
                path={['tmp', 'msg', 'rcr', 'br']}
                onClick={handleClick('Birthday')}
                template
              />
              <MessageTable
                messages={Birthday}
                all={all}
                selectAll={R.pipe(R.assoc('type', 'Birthday'), actions.selectAll)}
                selectMessage={R.pipe(R.assoc('type', 'Birthday'), actions.selectMessage)}
                copyMessage={actions.copyMessage}
                deleteMessage={R.pipe(R.assoc('type', 'Birthday'), actions.deleteMessage)}
                deleteSelected={R.pipe(R.assoc('type', 'Birthday'), actions.deleteSelected)}
                loadMessage={actions.loadMessage}
                path={['tmp', 'msg', 'rcr', 'br']}
                search={(path?: string[]) => onMove('Birthday', path || ['tmp', 'rcr', 'br'])}
              />
            </div>
            <div hidden={tab !== 'ApptChange'}>
              <Folders
                path={['tmp', 'msg', 'rcr', 'rv']}
                onClick={handleClick('ApptChange')}
                template
              />
              <MessageTable
                messages={ApptChange}
                all={all}
                selectAll={R.pipe(R.assoc('type', 'ApptChange'), actions.selectAll)}
                selectMessage={R.pipe(R.assoc('type', 'ApptChange'), actions.selectMessage)}
                copyMessage={actions.copyMessage}
                deleteMessage={R.pipe(R.assoc('type', 'ApptChange'), actions.deleteMessage)}
                deleteSelected={R.pipe(R.assoc('type', 'ApptChange'), actions.deleteSelected)}
                loadMessage={actions.loadMessage}
                path={['tmp', 'msg', 'rcr', 'rv']}
                search={(path?: string[]) => onMove('ApptChange', path || ['tmp', 'rcr', 'rv'])}
              />
            </div>
            <div hidden={tab !== 'Automation'}>
              <Folders
                path={['tmp', 'msg', 'rcr', 'auto']}
                onClick={handleClick('Automation')}
                template
              />
              {autoBusy ?
                <CircularProgress />
                :
                <MessageTable
                  messages={Automation}
                  all={all}
                  selectAll={R.pipe(R.assoc('type', 'Automation'), actions.selectAll)}
                  selectMessage={R.pipe(R.assoc('type', 'Automation'), actions.selectMessage)}
                  copyMessage={actions.copyMessage}
                  deleteMessage={R.pipe(R.assoc('type', 'Automation'), actions.deleteMessage)}
                  deleteSelected={R.pipe(R.assoc('type', 'Automation'), actions.deleteSelected)}
                  loadMessage={actions.loadMessage}
                  path={['tmp', 'msg', 'rcr', 'auto']}
                  search={(path?: string[]) => onMove('Automation', path || ['tmp', 'rcr', 'auto'])}
                />}
            </div>
            <div hidden={tab !== 'tmp.msg.rcr.smart_reply'}>
              <Folders
                path={['tmp', 'msg', 'smart_reply']}
                onClick={handleClick('Automation')}
                template
              />
              {autoBusy ?
                <CircularProgress />
                :
                <MessageTable
                  messages={AutomationBased}
                  all={all}
                  selectAll={R.pipe(R.assoc('type', 'Automation'), actions.selectAll)}
                  selectMessage={R.pipe(R.assoc('type', 'Automation'), actions.selectMessage)}
                  copyMessage={redirectToAutoBasedTemplates('COPY')}
                  deleteMessage={R.pipe(R.assoc('type', 'Automation'), actions.deleteMessage)}
                  deleteSelected={R.pipe(R.assoc('type', 'Automation'), actions.deleteSelected)}
                  loadMessage={redirectToAutoBasedTemplates('LOAD')}
                  path={['tmp', 'msg', 'smart_reply']}
                  search={(path?: string[]) => onMove('Automation', path || ['tmp', 'msg', 'smart_reply'])}
                />}
            </div>
            <div hidden={tab !== 'tmp.msg.rcr.time_based'}>
              <Folders
                path={['tmp', 'msg', 'time_based']}
                onClick={handleClick('Automation')}
                template
              />
              {autoBusy ?
                <CircularProgress />
                :
                <MessageTable
                  messages={AutomationBased}
                  all={all}
                  selectAll={R.pipe(R.assoc('type', 'Automation'), actions.selectAll)}
                  selectMessage={R.pipe(R.assoc('type', 'Automation'), actions.selectMessage)}
                  copyMessage={redirectToAutoBasedTemplates('COPY')}
                  deleteMessage={R.pipe(R.assoc('type', 'Automation'), actions.deleteMessage)}
                  deleteSelected={R.pipe(R.assoc('type', 'Automation'), actions.deleteSelected)}
                  loadMessage={redirectToAutoBasedTemplates('LOAD')}
                  path={['tmp', 'msg', 'time_based']}
                  search={(path?: string[]) => onMove('Automation', path || ['tmp', 'msg', 'rcr', 'time_based'])}
                />}
            </div>
            <div hidden={tab !== 'tmp.msg.rcr.spark'}>
              <Folders
                path={['tmp', 'msg', 'spark']}
                onClick={handleClick('Automation')}
                template
              />
              {autoBusy ?
                <CircularProgress />
                :
                <MessageTable
                  messages={AutomationBased}
                  all={all}
                  selectAll={R.pipe(R.assoc('type', 'Automation'), actions.selectAll)}
                  selectMessage={R.pipe(R.assoc('type', 'Automation'), actions.selectMessage)}
                  copyMessage={redirectToAutoBasedTemplates('COPY')}
                  deleteMessage={R.pipe(R.assoc('type', 'Automation'), actions.deleteMessage)}
                  deleteSelected={R.pipe(R.assoc('type', 'Automation'), actions.deleteSelected)}
                  loadMessage={redirectToAutoBasedTemplates('LOAD')}
                  path={['tmp', 'msg', 'spark']}
                  search={(path?: string[]) => onMove('Automation', path || ['tmp', 'msg', 'spark'])}
                />}
            </div>
          </div>
        </div>}
      <Menu
        anchorOrigin={{
          horizontal: 'right', vertical: 'top'
        }}
        id="automation-message-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          navigate('/template/edit/0');
        }}>
          <ListItemText primary='Automation' />
        </MenuItem>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          navigate('/template/edit/0?variant=smart-reply');
        }}>
          <ListItemText primary='Smart Reply' />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Templates;
