import React from 'react';
import { useDragLayer } from 'react-dnd';
import { ItemTypes } from './constants.jsx';
import TypeDrag from './type-drag.component';

const getFieldStyle = (isDragging: boolean) => ({
  width: 300,
  maxWidth: 300,
  opacity: isDragging ? 0.8 : 1,
});

const getItemStyles = (currentOffset: { x: number, y: number }) => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const FieldDragLayer = () => {

  const {
    item,
    itemType,
    isDragging,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const renderItem = (type: any, item: any) => {
    switch (type) {
      case ItemTypes.TYPE:
        return (
          <div>
            {item.selecteds &&
              item.selecteds.map((type: any) => (
                <TypeDrag
                  key={type.id}
                  type={type}
                  selected
                  handleSeletion={null}
                  selectedTypes={undefined}
                  setActiveDrag={undefined}
                  clearSelection={undefined}
                  activeIndex={undefined}
                />
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div style={getItemStyles(currentOffset)}>
        <div style={getFieldStyle(isDragging)}>
          {renderItem(itemType, item)}
        </div>
      </div>
    </div>
  );
};

export default FieldDragLayer;
