import { Dispatch } from '@reduxjs/toolkit';
import * as actionTypes from './snackbar.actionTypes';
import { Severity } from './snackbar.reducer';

export function errorSnackbar(message: string, timer = 5000) {
  return createSnackbar('error', message, timer);
}
export function infoSnackbar(message: string, timer = 5000) {
  return createSnackbar('info', message, timer);
}
export function warningSnackbar(message: string, timer = 5000) {
  return createSnackbar('warning', message, timer);
}
export function successSnackbar(message: string, timer = 5000) {
  return createSnackbar('success', message, timer);
}

function createSnackbar(severity: Severity, message: string, timer: number) {
  return (dispatch: Dispatch) => {
    const id = new Date().getTime();
    dispatch({
      type: actionTypes.ADD_SNACKBAR,
      data: { message, id, timer, severity }
    });

    setTimeout(() => {
      dispatch({ type: actionTypes.CLOSE_SNACKBAR, id });
    }, timer);
  };
}

export function closeAlert(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CLOSE_SNACKBAR, id });
  };
}
