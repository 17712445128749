import * as at from '../../actionTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import * as R from 'ramda';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import {
  reset,
  getCurrentClient,
  backToClient
} from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import { popup } from '../../services/Popup.js';
import { Appointment } from '../Appointments/appointments.types';
import { Client } from '../Clients/clients.types';
import { RootState } from '../../reducers';

type GetStore = () => RootState;

export const getFilterData = (stayBusy = false, navigate?: NavigateFunction) => remoteAction({
  type: at.SKED_REMOTE_GET,
  action: () => api.get('professional').then((pros) => {
    return api.get('appointmentType').then((types) => {
      return {
        pros,
        types,
        stayBusy,
      };
    });
  }).catch((error) => {
    if (error.response) {
      console.error(error);
    } else {
      navigate && navigate('/offline');
    }
  }),
});

export const selectAppointment = (appointment: Appointment) => {
  return remoteAction({
    type: at.SKED_REMOTE_APT_GET,
    action: () => api.get(`appointmentType/${appointment.appointmentTypeId}`).then((data) => {
      const aptWithType = R.assoc('type', data.name)(appointment);
      return api.get(`appointment/${appointment.id}/log`)
        .then((data) => {
          return {
            appointment: R.assoc(
              'aptData',
              R.reverse(R.sortBy(R.prop('changedAt'))(data)),
              aptWithType),
          };
        });
    }).catch((error) => {
      popup('Error!', 'Failed to get appointment data.');
      console.error(error);
    }),
  });
};

export const skedPatch = (prop: string, value: string) => (dispatch: Dispatch) =>
  dispatch({
    type: at.SKED_PATCH,
    data: {
      [prop]: value,
    }
  });

export const backToSked = () => (dispatch: Dispatch) => {
  reset()(dispatch);
  return dispatch({
    type: at.SKED_PATCH,
    data: {
      state: 'SKED',
    }
  });
};

export const selectClientDialog = (client: Client) => (dispatch: Dispatch, getStore: GetStore) =>
  getCurrentClient(client)(dispatch, getStore);

export const back = () => (dispatch: Dispatch) =>
  backToClient()(dispatch);

export const cancelAppointment = ({ id }: { id: number }) => ({
  type: at.SKED_CANCEL_APT,
  data: {
    id
  }
});

export const changeAppointmentStatus = ({ id }: Appointment, status: string) => remoteAction({
  type: at.SKED_REMOTE_APT_ARRIVE,
  action: () => api.put(`appointment/${id}`, { status: { [status]: [] }, source: 'Admin' })
    .then(() => {
      return {
        id,
        status,
      };
    })
    .catch((error) => {
      popup('Error!', `Failed to mark appointment as ${status.toLowerCase()}.`);
      console.error(error);
    }),
});

export const reskedAppointment = (apt: Appointment) => ({
  type: at.SKED_RESKED_APT,
  data: apt
});
