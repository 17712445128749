// TODO: Put admin stuff in the private routes
import React, { Suspense } from 'react';
import { includes, cond, T, pathOr } from 'ramda';
import { useDispatch } from 'react-redux';
import {
  Navigate, Route, Routes, Outlet,
  useLocation, useNavigate,
} from 'react-router-dom';
import { TextField, Paper, Container, CircularProgress, useMediaQuery } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { useSKEDAdmin } from './services/init.hook.js';
import { checkUser, patch, resetSelectedOffice } from './routes/Login/login.actions';
import Snackbar from './components/Snackbar/snackbar.component';
import AdminHeader from './components/Header/admin-header.component';
import { useSelector } from './reducers';
import './styles/main.css';

const Dashboard = React.lazy(() => import('./routes/Dashboard/dashboard.component.jsx'));
const CalendarV2 = React.lazy(() => import('./routes/CalendarV2/calendar.component'));
const OverviewReport = React.lazy(() => import('./routes/OverviewReport/overview.component.jsx'));
const Schedule = React.lazy(() => import('./routes/Schedule/schedule.component.jsx'));
const Clients = React.lazy(() => import('./routes/Clients/Clients.page'));
const Leads = React.lazy(() => import('./routes/Leads/Leads.page'));
const OfficeHours = React.lazy(() => import('./routes/OfficeHours/OfficeHourTabs.jsx'));
const SpecialHoursPage = React.lazy(() => import('./routes/OfficeHours/SpecialHours.page.jsx'));
const EventHoursPage = React.lazy(() => import('./routes/OfficeHours/EventHours.page.jsx'));
const Messages = React.lazy(() => import('./routes/Messages/messages.component.jsx'));
const Inbox = React.lazy(() => import('./routes/Messages/Inbox/Inbox.page'));
const MessagesNew = React.lazy(() => import('./routes/Messages/routes/MessagesNew/messages-new.component.jsx'));
const MessageView = React.lazy(() => import('./routes/Messages/routes/MessagesView/messages-view.component.jsx'));
const MessageThread = React.lazy(() => import('./routes/Messages/routes/MessagesThread/messages-thread.component.jsx'));
const InboxChat = React.lazy(() => import('./routes/Messages/Inbox/InboxChat/InboxChat.component'));
const BirthdayMessages = React.lazy(() => import('./routes/RecurringMessage/birthday.page.jsx'));
const ReminderMessages = React.lazy(() => import('./routes/RecurringMessage/reminders.page.jsx'));
const ReactivationMessages = React.lazy(() => import('./routes/RecurringMessage/reactivation.page.jsx'));
const AutomationMessages = React.lazy(() => import('./routes/RecurringMessage/automation.page.jsx'));
const ChangeMessages = React.lazy(() => import('./routes/RecurringMessage/changes.page.jsx'));
const AutomatedMessageEdit = React.lazy(() => import('./routes/RecurringMessage/routes/RecurringEdit/automated-edit.page.jsx'));
const AutomatedMessageView = React.lazy(() => import('./routes/RecurringMessage/routes/RecurringView/automated-view.page.jsx'));
const AutoResponderMessages = React.lazy(() => import('./routes/RecurringMessage/autoresponder.page.jsx'));

const Templates = React.lazy(() => import('./routes/Templates/templates.component'));
const TemplateEdit = React.lazy(() => import('./routes/Templates/routes/TemplatesEdit/templates-edit.component'));
const Reviews = React.lazy(() => import('./routes/Reviews/reviews.component.jsx'));
const Announcements = React.lazy(() => import('./routes/Announcements/announcements.component'));
const Support = React.lazy(() => import('./routes/Support/support.component.jsx'));
const SettingsBusiness = React.lazy(() => import('./routes/Settings/routes/Business/business.component.jsx'));
const SettingsLocations = React.lazy(() => import('./routes/Settings/routes/Locations/locations.page'));
const SettingsInstaller = React.lazy(() => import('./routes/Settings/routes/Installer/installer.component.jsx'));
const Integration = React.lazy(() => import('./routes/Settings/routes/Integration/integration.component.jsx'));
const Profile = React.lazy(() => import('./routes/Settings/routes/Profile/profile.component'));
const Webmodule = React.lazy(() => import('./routes/Settings/routes/Webmodule/webmodule.component.jsx'));
const SettingsSubscription = React.lazy(() => import('./routes/Settings/routes/Subscription/subscription.component.jsx'));
const General = React.lazy(() => import('./routes/Settings/routes/General/general.component.jsx'));
const Professionals = React.lazy(() => import('./routes/Professionals/professionals.component.jsx'));
const AppSettings = React.lazy(() => import('./routes/Settings/routes/App/app.component.jsx'));
const ReviewsSettings = React.lazy(() => import('./routes/Settings/routes/Reviews/reviews.component.jsx'));
const GoogleIntegration = React.lazy(() => import('./routes/Settings/routes/GoogleIntegration/GoogleIntegration.page'));
const Tutorials = React.lazy(() => import('./routes/Tutorials/tutorials.component.jsx'));
const Offline = React.lazy(() => import('./routes/Offline/offline.component.jsx'));
const Login = React.lazy(() => import('./routes/Login/login.component'));
const ForgotPassword = React.lazy(() => import('./routes/ForgotPassword/forgot-password.component.jsx'));
const PasswordReset = React.lazy(() => import('./routes/ForgotPassword/password-reset.component.jsx'));
const PasswordResetClient = React.lazy(() => import('./routes/ForgotPassword/password-reset-client.component.jsx'));
const Unsubscribe = React.lazy(() => import('./routes/Unsubscribe/unsubscribe.component.jsx'));
const PrivateRoute = React.lazy(() => import('./PrivateRoute'));
const SelectOffice = React.lazy(() => import('./routes/Settings/routes/SelectOffice/selectOffice.component'));
const Registration = React.lazy(() => import('./routes/Registration/registration.component.jsx'));
const SignUp = React.lazy(() => import('./routes/SignUp/SignUp.page'));
const AccountDeletion = React.lazy(() => import('./routes/Help/AccountDeletion.page'));
const ResetUser = React.lazy(() => import('./routes/Admin/reset-user.component'));
const CheckUnsynced = React.lazy(() => import('./routes/Admin/check-unsynced.component'));
const WaitingRoom = React.lazy(() => import('./routes/waiting/waiting.component.jsx'));
const WaitingRoomNext = React.lazy(() => import('./routes/waiting/FrontDesk.page'));
const Tags = React.lazy(() => import('./routes/Tags/tags.component.jsx'));
const TransferOffice = React.lazy(() => import('./routes/Admin/transfer-office.component.jsx'));
const TransferMessages = React.lazy(() => import('./routes/Admin/transfer-messages.page'));
const TransferForms = React.lazy(() => import('./routes/Admin/transfer-forms.page'));
const TutorialsV2 = React.lazy(() => import('./routes/Tutorial/index.page.jsx'));

const ScheduleBlocksPage = React.lazy(() => import('./routes/Schedule/blocks.page.jsx'));
const SMSNumber = React.lazy(() => import('./routes/Settings/routes/SMSNumber/sms-number.component.jsx'));
const NewPatientPortalSettings = React.lazy(() => import('./routes/Settings/routes/NewPatientPortal/NewPatientPortal.page.jsx'));
const NewPatientPortalClients = React.lazy(() => import('./routes/Settings/routes/NewPatientPortal/Clients.page.jsx'));
const NewPatientPortalSettingsList = React.lazy(() => import('./routes/Settings/routes/NewPatientPortal/NewPatientPortalList.page.jsx'));
const CLASettingsPage = React.lazy(() => import('./routes/Settings/routes/CLA/cla.page.jsx'));
const CLAMatchPage = React.lazy(() => import('./routes/Settings/routes/CLA/cla-match.page.js'));

const AppointmentsReportPage = React.lazy(() => import('./routes/Reports/Appointments.page.jsx'));
const StopManagersReportPage = React.lazy(() => import('./routes/Reports/StopManagers.page.jsx'));

const Help = React.lazy(() => import('./routes/Help/Help.component'));
import ErrorBoundary from './components/error-boundary';
const Affiliates = React.lazy(() => import('./routes/Admin/affiliates.component'));
const Banners = React.lazy(() => import('./routes/Admin/Banners/banners.page'));
const Agencies = React.lazy(() => import('./routes/Admin/Agencies/agencies.page'));
const Users = React.lazy(() => import('./routes/Admin/Users/users.page'));
const ProfileSettings = React.lazy(() => import('./routes/Admin/Profile/profile.page'));
const ClientLists = React.lazy(() => import('./routes/ClientLists/clientlists.page'));
const ClientList = React.lazy(() => import('./routes/ClientLists/clientlist.page'));
const Campaigns = React.lazy(() => import('./routes/ClientLists/campaigns.page'));
const Campaign = React.lazy(() => import('./routes/ClientLists/campaign.page'));
const SparkCampaign = React.lazy(() => import('./routes/ClientLists/spark.page'));
const SparkReporting = React.lazy(() => import('./routes/AppointmentLists/spark-reporting.page'));
const Automations = React.lazy(() => import('./routes/Automations/automations.page'));
const TemplateAutomations = React.lazy(() => import('./routes/Templates/automation-templates.page.jsx'));
const StopManagers = React.lazy(() => import('./routes/StopManagers/stop-managers.page.jsx'));

const IntakeSettingsList = React.lazy(() => import('./routes/IntakeForms/intake-list.page'));
const IntakeSettingsEdit = React.lazy(() => import('./routes/IntakeForms/intake-edit.page'));
const IntakeSubmissions = React.lazy(() => import('./routes/IntakeForms/intake-submissions.page'));
const IntakeSubmission = React.lazy(() => import('./routes/IntakeForms/intake-submission.page'));

const AutomationBaseMessageView = React.lazy(() => import('./routes/AutomationBasedMessage/view-message.page'));
const TimeBasedMessageEdit = React.lazy(() => import('./routes/AutomationBasedMessage/time-based-edit.page'));
const SmartReplyMessageEdit = React.lazy(() => import('./routes/AutomationBasedMessage/smart-reply-edit.page'));
const AutomationBasedTemplateEdit = React.lazy(() => import('./routes/Templates/automation-based-template-edit.page'));
const AutomationBasedMessageList = React.lazy(() => import('./components/AutomationBasedMessage/list-automation-message.component'));

const GoHighLevel = React.lazy(() => import('./routes/Settings/routes/Integration/gohighlevel.page'));
const SparkWebinar = React.lazy(() => import('./routes/SparkWebinar/SparkWebinar.page'));
const QuickReplies = React.lazy(() => import('./routes/Settings/routes/QuickReplies/quick-replies.page'));

import { useStyles } from './styles/app.styles';
import { skedTheme, sparkTheme } from './theme';
import { str2ab } from './services/utilities';
import Logo from '../src/images/logo-blue.png';

const attemptDecryption = async (value: string) => {
  try {
    const encryptedSalt = localStorage.getItem('ss');
    const salt = new Uint8Array(str2ab(encryptedSalt));
    const encodedPass = new Uint8Array(16);
    let i = 0;
    for (const n of new TextEncoder().encode(value)) {
      encodedPass[i] = n;
      i = i + 1;
    }
    for (const n of salt) {
      encodedPass[i] = n;
      i = i + 1;
    }
    const key = await window.crypto.subtle.importKey(
      'raw',
      encodedPass,
      'AES-GCM',
      true,
      [
        'encrypt',
        'decrypt',
      ]);
    const session = localStorage.getItem('sked-session');
    const decrypted = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: new Uint8Array(12) },
      key,
      str2ab(session),
    );
    const decoded = new window.TextDecoder().decode(new Uint8Array(decrypted));
    localStorage.setItem('sked-session', decoded);
    if (decoded) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const Loading = () => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <CircularProgress />
    </div>
  );
};

const Public = () => (
  <Container maxWidth={false}>
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  </Container>
);

const Admin = () => (
  <div>
    <div className="sked-content" style={{ marginLeft: 'unset' }}>
      <AdminHeader />
      <div className="content-inner" style={{ overflowY: 'auto' }}>
        <Suspense fallback={<div className="loader" />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  </div>
);

const LockedPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.lockedDialog}>
      <Paper className={classes.lockedContainer}>
        <img src={Logo} width='200px' />
        <p style={{
          margin: '10px 0 0 0',
        }}>
          This device is locked. Please enter your pin to access the Admin.
        </p>
        <TextField
          label='Admin PIN'
          type='password'
          fullWidth
          style={{ width: 'unset' }}
          onChange={(e) => {
            const v = e.target.value;
            attemptDecryption(v).then((isDecrypted) => {
              if (isDecrypted)
                dispatch(patch({ locked: false }));
            });
          }}
        />
      </Paper>
    </div>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loading = location.pathname === '/loading';
  const isSSO = location.pathname.indexOf('/sso') > -1;
  const {
    locked,
    theme,
    isAdmin,
    desktopOpen
  } = useSelector((state) => ({
    locked: pathOr(false, ['login', 'locked'], state),
    theme: state.login.theme,
    isAdmin: pathOr(false, ['login', 'admin'], state),
    desktopOpen: pathOr(true, ['login', 'desktopOpen'], state),
  }));

  const isMobile = useMediaQuery(`(max-width:${(desktopOpen ? 240 : 0) + 920}px)`);

  // inits the websocket connections,
  // gets unread messages,
  // gets reviews
  // gets notifications for waiting room
  useSKEDAdmin();

  React.useEffect(() => {
    const session = localStorage.getItem('sked-session');
    try {
      JSON.parse(session);
    } catch (e) {
      dispatch(patch({ locked: true }));
    }
  }, []);

  React.useEffect(() => {
    dispatch(checkUser(navigate, isSSO));
    /* Fixes issues with HubSpot tracking query params */
    const splitter = window.location.href.split(/\/\?/s);
    const afterDomain = splitter[1];
    const queryParams = afterDomain?.split('#/');
    if (queryParams && !isSSO) {
      window.location.href = `${splitter[0]}/#/${queryParams[1]}`;
    }
  }, []);

  const handleKeyPress = React.useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey === true) {
      if (event.key === ';' && isAdmin) {
        dispatch(resetSelectedOffice());
      }
    }
  }, [isAdmin]);

  React.useEffect(() => {
    dispatch(patch({ isMobile }));
    if (isMobile) {
      setTimeout(() => {
        dispatch(patch({ openedFolder: false }));
      }, 50);
    }
  }, [isMobile]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Suspense fallback={<div />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme === 'sked' ? skedTheme : sparkTheme}>
          <ErrorBoundary>
            <Snackbar />
            {locked && !loading &&
              <LockedPage />}
            {locked && loading &&
              <Loading />}
            {!locked &&
              <Routes>
                <Route element={<Public />}>
                  <Route path='/login' element={<Login />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/passwordReset/office' element={<PasswordReset />} />
                  <Route path='/passwordReset/client' element={<PasswordResetClient />} />
                  <Route path='/unsubscribe' element={<Unsubscribe />} />
                  <Route path='/registration' element={<Registration />} />
                  <Route path='/spark-registration' element={<Registration />} />
                  <Route path='/account-deletion' element={<AccountDeletion />} />
                  <Route path='/sso/zendesk' element={<Login />} />
                </Route>
                <Route element={<Admin />}>
                  <Route path='/settings/select-office' element={<SelectOffice />} />
                  <Route path='/templates' element={<Templates />} />
                  <Route path='/template/edit/:id' element={<TemplateEdit />} />
                  <Route path='/automations-templates' element={<TemplateAutomations />} />
                  <Route path='/campaign-templates' element={<Campaigns template />} />
                  <Route path='/campaign-template/:id' element={<Campaign template />} />
                  <Route path="/automation-based-template/edit/:id" element={<AutomationBasedTemplateEdit />} />
                  <Route path='/reset-user' element={<ResetUser />} />
                  <Route path='/check-unsynced' element={<CheckUnsynced />} />
                  <Route path="/transfer-office" element={<TransferOffice />} />
                  <Route path="/transfer-forms" element={<TransferForms />} />
                  <Route path="/transfer-messages" element={<TransferMessages />} />
                  <Route path="/affiliates" element={<Affiliates />} />
                  <Route path="/banners" element={<Banners />} />
                  <Route path="/agencies" element={<Agencies />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/profile/settings" element={<ProfileSettings />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path='/' element={<Navigate to={'/dashboard'} />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/calendar-v2' element={<CalendarV2 />} />
                  <Route path='/schedule' element={<Schedule />} />
                  <Route path='/clients' element={<Clients />} />
                  <Route path='/leads' element={<Leads />} />
                  <Route path='/professionals' element={<Professionals />} />
                  <Route path='/office-hours' element={<OfficeHours />} />
                  <Route path='/special-office-hours' element={<SpecialHoursPage />} />
                  <Route path='/office-events' element={<EventHoursPage />} />
                  <Route path='/messages/new/:id' element={<MessagesNew />} />
                  <Route path='/messages/view/:id' element={<MessageView />} />
                  <Route path='/inbox/thread/:id' element={<MessageThread />} />
                  <Route path='/inbox/chat/:id' element={<InboxChat />} />
                  <Route path='/messages' element={<Messages />} />
                  <Route path='/recurring/edit/:id' element={<AutomatedMessageEdit type="Recurring" />} />
                  <Route path='/recurring/view/:id' element={<AutomatedMessageView type="Recurring" />} />
                  <Route path='/change-messages' element={<ChangeMessages />} />
                  <Route path='/birthday-messages' element={<BirthdayMessages />} />
                  <Route path='/reminder-messages' element={<ReminderMessages />} />
                  <Route path='/reactivation-messages' element={<ReactivationMessages />} />
                  <Route path='/reactivation/edit/:id' element={<AutomatedMessageEdit type="Recall" />} />
                  <Route path='/reactivation/view/:id' element={<AutomatedMessageView type="Recall" />} />
                  <Route path='/birthday/edit/:id' element={<AutomatedMessageEdit type="Birthday" />} />
                  <Route path='/birthday/view/:id' element={<AutomatedMessageView type="Birthday" />} />
                  <Route path='/apptchange/edit/:id' element={<AutomatedMessageEdit type="ApptChange" />} />
                  <Route path='/apptchange/view/:id' element={<AutomatedMessageView type="ApptChange" />} />
                  <Route path='/autoresponder-messages' element={<AutoResponderMessages />} />
                  <Route path='/smart-reply-messages' element={<AutomationMessages />} />
                  <Route path='/smart-reply-old/edit/:id' element={<AutomatedMessageEdit type="Automation" />} />
                  <Route path='/smart-reply/view/:id' element={<AutomatedMessageView type="Automation" />} />
                  <Route path='/automation-messages' element={<AutomationMessages />} />
                  <Route path='/automation/edit/:id' element={<AutomatedMessageEdit type="Automation" />} />
                  <Route path='/automation/view/:id' element={<AutomatedMessageView type="Automation" />} />
                  <Route path='/inbox' element={<Inbox />} />
                  <Route path='/reviews' element={<Reviews />} />
                  <Route path='/announcements' element={<Announcements />} />
                  <Route path='/support' element={<Support />} />
                  <Route path='/tutorials' element={<Tutorials />} />
                  <Route path='/offline' element={<Offline />} />
                  <Route path='/waiting' element={<WaitingRoom />} />
                  <Route path='/front-desk' element={<WaitingRoomNext />} />
                  <Route path='/tags' element={<Tags />} />
                  <Route path='/settings/sms-number' element={<SMSNumber />} />
                  <Route path='/settings/business' element={<SettingsBusiness />} />
                  <Route path='/settings/locations' element={<SettingsLocations />} />
                  <Route path='/settings/integration' element={<Integration />} />
                  <Route path='/settings/gohighlevel' element={<GoHighLevel />} />
                  <Route path='/settings/profile' element={<Profile />} />
                  <Route path='/settings/subscription' element={<SettingsSubscription />} />
                  <Route path='/settings/client-installer' element={<SettingsInstaller />} />
                  <Route path='/settings/webmodule' element={<Webmodule />} />
                  <Route path='/settings/general' element={<General />} />
                  <Route path='/settings/app' element={<AppSettings />} />
                  <Route path="/settings/reviews" element={<ReviewsSettings />} />
                  <Route path="/settings/google-integration" element={<GoogleIntegration />} />
                  <Route path='/settings/new-patient-portal' element={<NewPatientPortalSettingsList />} />
                  <Route path='/settings/new-patient-portal/:id' element={<NewPatientPortalSettings />} />
                  <Route path='/settings/new-patient-portal-clients/:id' element={<NewPatientPortalClients />} />
                  <Route path='/settings/cla' element={<CLASettingsPage />} />
                  <Route path='/settings/cla/match' element={<CLAMatchPage />} />
                  <Route path='/settings/quick-replies' element={<QuickReplies />} />
                  <Route path='/reports/overview' element={<OverviewReport />} />
                  <Route path='/reports/appointments' element={<AppointmentsReportPage />} />
                  <Route path='/reports/stops' element={<StopManagersReportPage />} />
                  <Route path="/help" element={<Help />} />
                  <Route path='/schedule/blocks/:id' element={<ScheduleBlocksPage />} />
                  <Route path='/stop-managers' element={<StopManagers />} />
                  <Route path="/getting-started" element={<TutorialsV2 />} />
                  <Route path="/client-lists" element={<ClientLists />} />
                  <Route path="/client-list/:id" element={<ClientList />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/campaign/:id" element={<Campaign />} />
                  <Route path="/spark-campaign" element={<SparkCampaign />} />
                  <Route path="/spark-reporting" element={<SparkReporting />} />
                  <Route path="/automations" element={<Automations />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/spark-webinar" element={<SparkWebinar />} />
                  <Route path="/forms-settings" element={<IntakeSettingsList />} />
                  <Route path="/forms-edit/:id" element={<IntakeSettingsEdit />} />
                  <Route path="/forms-submissions" element={<IntakeSubmissions />} />
                  <Route path="/form-submission/:id" element={<IntakeSubmission />} />
                  <Route path="/time-based/edit/message/:id" element={<TimeBasedMessageEdit />} />
                  <Route path="/time-based/edit/template/:id" element={<TimeBasedMessageEdit />} />
                  <Route path="/time-based/view-message/:id" element={<AutomationBaseMessageView />} />
                  <Route path="/time-based/list" element={
                    <AutomationBasedMessageList
                      path='msg.rcr.time_based'
                      composeUrl='/time-based'
                      title='Set-Time Reminders'
                    />
                  }/>
                  <Route path="/smart-reply/edit/message/:id" element={<SmartReplyMessageEdit />} />
                  <Route path="/smart-reply/edit/template/:id" element={<SmartReplyMessageEdit />} />
                  <Route path="/smart-reply/view-message/:id" element={<AutomationBaseMessageView />} />
                  <Route path="/smart-reply/list" element={
                    <AutomationBasedMessageList
                      path='msg.rcr.smart_reply'
                      composeUrl='/smart-reply'
                      title='Smart Replies Messages'
                    />
                  }/>
                </Route>
                <Route path="/loading" element={<Loading />} />
              </Routes>
            }
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

document.title = cond([
  [includes('localhost'), () => 'SKED Admin | Local'],
  [includes('plat'), () => 'SKED Admin | Plat'],
  [includes('int'), () => 'SKED Admin | Int'],
  [T, () => 'SKED Admin']
])(document.location.href);

export default App;
