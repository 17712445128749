import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    padding: '3px 4px',
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center'
  },
  justIcon: {
    padding: '7px 4px',
    borderRadius: 10,
    paddingLeft: 10,
  },
  button: {
    padding: '0px 10px',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: 0,
    color: 'rgba(0,0,0,.87)',
    border: 'none',
    borderLeft: 'solid 2px rgba(0,0,0,.2)',
    margin: 0,
    whiteSpace: 'nowrap',

    '&:hover': {
      border: 'none',
      borderLeft: 'solid 2px rgba(0,0,0,.2)',
    }
  },
  input: {
    padding: 0,
    marginRight: 5,
    width: 170,

    '& .MuiInput-input': {
      padding: 0,
    }
  },
  startIcon: {
    marginRight: 5,
  },
  closeButton: {
    padding: 2,
    marginRight: -2,
    marginLeft: -8,

    '& svg': {
      fontSize: 14,
    }
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
}));
