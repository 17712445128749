import React, { useState } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { PopupTemplate, popupWithCancel } from '../../services/Popup.js';
import {
  Checkbox, FormControlLabel, Card, TextField, Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import * as actions from './tags.actions.jsx';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import Header from '../../components/PageHeader/PageHeader.component';
import FilterText from '../../components/FilterText/FilterText.component';
import ClientsList from './components/ClientsList/ClientList.component';
import { useStyles } from './tags.styles';

const TagChip = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    editting: false,
    tagEdit: {},
  });

  const update = (data) => {
    setState(R.merge(state, data));
  };
  
  const {
    tag,
    selected,
    onClick,
    onDelete,
    updateTag,
  } = props;

  const {
    isEditting,
    tagEdit,
  } = state;

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: selected ? '#008BCF' : '#CECECE',
      }}
    >
      {isEditting ?
        <input
          className={classes.input}
          value={tagEdit.tag}
          onChange={(e) => update({ tagEdit: { ...tagEdit, tag: e.target.value } })}
        />
        :
        <div
          key={tag.tagId}
          className={classes.name}
          style={{
            color: selected ? 'white' : 'black',
          }}
          onClick={() => onClick(tag)}>
          {tag.tag}
        </div>}
      <div
        title={isEditting ? 'Save' : 'Edit Tag Name'}
        className={classes.chip}
        onClick={() => {
          if (isEditting) {
            updateTag(tagEdit);
            update({ isEditting: false, tagEdit: {} });
          } else {
            update({ isEditting: true, tagEdit: tag });
          }
        }}>
        {isEditting ? <CheckIcon style={{ color: '#CECECE', fontSize: 16 }} /> : <EditIcon style={{ color: '#CECECE', fontSize: 14 }} />}
      </div>
      <div
        title={isEditting ? 'Cancel' : 'Delete Tag'}
        className={classes.chip}
        onClick={() => {
          if (isEditting) {
            update({ isEditting: false, tagEdit: {} });
          } else {
            popupWithCancel(
              'Verification',
              'Are you sure you want to delete this tag? All clients with this tag will be untagged.',
              () => onDelete(tag),
              'danger'
            );
          }
        }}>
        <CloseIcon style={{ fontSize: 16, color: '#CECECE' }} />
      </div>
    </div>
  );
};

let timeout;
const Tags = props => {
  const [isLead, setIsLead] = React.useState(false);
  const [search, setSearch] = React.useState({ clients: '', leads: '' });
  const [selectedSearch, setSelectedSearch] = React.useState({ clients: '', leads: '' });

  React.useEffect(() => {
    window.document.title = 'Tags';
    props.getTags();
    props.getClients();
    props.getLeads();
    return () => {
      props.resetPage();
    };
  }, []);

  const {
    tags,
    busyClients,
    clients,
    leads,
    selectedClients,
    selectedLeads,
    busySelectedClients,
    selectedTags,
    isEnabled,
    hasLead,
    allTags,
  } = props;

  if (!isEnabled) {
    return (
      <FeatureDisabled title="Tags">
        <p>
          Send messages targeted to patients based on symptoms, care plan status, or any custom category you create!
        </p>
      </FeatureDisabled>
    );
  }

  return (
    <>
      <Header
        title='Tags'
        pageId="campaigns"
        rightIcons={[
          <FilterText
            Icon={AddIcon}
            title='Add Tags'
            value=''
            placeholder='Ex: Lower back pain...'
            onSave={(value) => {
              const str = value;
              const splitUp = str.split(',');
              const trimmed = R.map((t) => t.trim())(splitUp);
              props.addTags(trimmed);
            }}
            noSearchIcon
            noReset
            saveTitle='Add'
          />
        ]}
      />
      <div
        style={{
          padding: '20px',
          paddingBottom: 40,
          overflowY: 'auto',
        }}>
        <PopupTemplate />
        <div style={{ paddingBottom: '2px' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: '',
          }}>
            {!R.isEmpty(allTags) &&
              <Card
                style={{
                  padding: '10px',
                  marginRight: '20px',
                }}>
                Tags
                <Card
                  style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '10px',
                    maxHeight: '100vh',
                    width: '300px',
                    overflowY: 'scroll',
                    marginBottom: '10px',
                  }}>
                  <TextField
                    name="search"
                    label="Search"
                    underlineFocusStyle={{ borderColor: '#008BCF' }}
                    floatingLabelFocusStyle={{ color: '#008BCF' }}
                    onChange={(e) => {
                      props.sortTags(e.target.value);
                    }} />
                  {tags.map((tag, ind) => {
                    const selected = R.includes(tag, selectedTags);
                    return (
                      <TagChip
                        key={ind}
                        tag={tag}
                        selected={selected}
                        onClick={(t) => {
                          if (selected) {
                            const tagz = R.without([t], selectedTags);
                            props.update({ selectedTags: tagz });
                            if (!R.isEmpty(tagz)) {
                              const actionsGet = [props.getSelectedLeads, props.getSelectedClients];
                              for (const action of actionsGet) {
                                action({
                                  query: {
                                    tags: {
                                      And: R.map(R.prop('tagId'))(tagz),
                                    },
                                  },
                                  page: 1,
                                  hide: true,
                                  search: '',
                                });
                              }
                            }
                          } else {
                            const tagz = R.append(t, selectedTags);
                            props.update({ selectedTags: tagz });
                            const actionsGet = [props.getSelectedLeads, props.getSelectedClients];
                            for (const action of actionsGet) {
                              action({
                                query: {
                                  tags: {
                                    And: R.map(R.prop('tagId'))(tagz),
                                  },
                                },
                                page: 1,
                                hide: true,
                                search: '',
                              });
                            }
                          }
                        }}
                        onDelete={(t) => {
                          props.deleteTag(t.tagId);
                        }}
                        updateTag={(t) => {
                          props.updateTag(t);
                        }}
                      />
                    );
                  })}
                </Card>
              </Card>}
            {!R.isEmpty(selectedTags) &&
              <Card
                style={{
                  padding: '10px',
                }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  <div>
                    Add tag to:
                    <Card
                      style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        borderRadius: '10px',
                        maxHeight: '75vh',
                        width: '300px',
                        overflowY: 'scroll',
                        marginBottom: '10px',
                        marginRight: '50px',
                      }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}>
                        <TextField
                          name="search"
                          label="Search"
                          value={isLead ? search.leads : search.clients}
                          placeholder='LastName FirstName'
                          underlineFocusStyle={{ borderColor: '#008BCF' }}
                          floatingLabelFocusStyle={{ color: '#008BCF' }}
                          onChange={(e) => {
                            const str = e.target.value;
                            const value = isLead ? { leads: str } : { clients: str };
                            setSearch({ ...search, ...value });
                            const splitUp = str.split(' ');
                            const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
                            const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
                            const phone = splitUp[2] ? splitUp[2].trim() : undefined;
                            if (timeout)
                              clearTimeout(timeout);
                            timeout = setTimeout(() => {
                              const actionGet = isLead ? props.getLeads : props.getClients;
                              actionGet({
                                query: {
                                  firstName,
                                  lastName,
                                  phone,
                                },
                                search: str,
                                page: 1,
                              });
                            }, 500);
                          }} />
                        &nbsp;
                        <Tooltip
                          title="Search format: lastName firstName phone (i.e. Smith John 1231231234) <br/> Pro Tip: hover over a client to view email and phone number!"
                          placement="top"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 14, marginBottom: 15 }} />
                        </Tooltip>
                      </div>
                      {hasLead && (
                        <div>
                          <FormControlLabel
                            style={{ marginLeft: 5 }}
                            control={
                              <Checkbox
                                checked={!isLead}
                                onChange={() => setIsLead(!isLead)}
                                color="primary"
                              />
                            }
                            labelPlacement="start"
                            label="Clients:"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isLead}
                                onChange={() => setIsLead(!isLead)}
                                color="primary"
                              />
                            }
                            label="Leads:"
                            labelPlacement="start"
                          />
                        </div>
                      )}
                      {busyClients && <div className='loader' />}
                      {!busyClients && (
                        <ClientsList
                          clients={isLead ? leads : clients}
                          getClients={isLead ? props.getLeads : props.getClients}
                          onClick={(c) => {
                            const addAction = isLead ? props.addTagsToLead : props.addTagsToClient;
                            addAction(
                              c,
                              R.map(R.prop('tagId'))(selectedTags),
                            );
                          }}
                        />
                      )}
                    </Card>
                  </div>
                  {(!selectedClients.hide || !selectedLeads.hide) &&
                    <div style={{ textOverflow: 'wrap', }}>
                      {`${isLead ? 'Leads' : 'Clients'}`} with tags:
                      &nbsp;
                      {R.reduce((acc, cur) => {
                        if (acc === null) {
                          return cur.tag;
                        } else {
                          return acc + ', ' + cur.tag;
                        }
                      }, null)(selectedTags)}
                      <Card
                        style={{
                          backgroundColor: 'white',
                          padding: '10px',
                          borderRadius: '10px',
                          maxHeight: '75vh',
                          width: '300px',
                          overflowY: 'scroll',
                          marginBottom: '10px',
                          marginRight: '50px',
                        }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}>
                          <TextField
                            name="search"
                            label="Search"
                            value={isLead ? selectedSearch.leads : selectedSearch.clients}
                            placeholder='LastName FirstName'
                            underlineFocusStyle={{ borderColor: '#008BCF' }}
                            floatingLabelFocusStyle={{ color: '#008BCF' }}
                            onChange={(e) => {
                              const str = e.target.value;
                              const value = isLead ? { leads: str } : { clients: str };
                              setSelectedSearch({ ...selectedSearch, ...value });
                              const splitUp = str.split(' ');
                              const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
                              const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
                              const phone = splitUp[2] ? splitUp[2].trim() : undefined;
                              const actionGet = isLead ? props.getSelectedLeads : props.getSelectedClients;
                              actionGet({
                                page: 1,
                                search: str,
                                query: {
                                  firstName,
                                  lastName,
                                  phone,
                                  tags: {
                                    And: R.map(R.prop('tagId'))(selectedTags),
                                  },
                                }
                              });
                            }} />
                          &nbsp;
                          <Tooltip
                            title="Search format: lastName firstName phone (i.e. Smith John 1231231234) <br/> Pro Tip: hover over a client to view email and phone number!"
                            placement="top"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 14, marginBottom: 15 }} />
                          </Tooltip>
                        </div>
                        {busySelectedClients && <div className='loader' />}
                        {!busySelectedClients && (
                          <ClientsList
                            clients={isLead ? selectedLeads : selectedClients}
                            getClients={isLead ? props.getSelectedLeads : props.getSelectedClients}
                            queryTags={{
                              And: R.map(R.prop('tagId'))(selectedTags),
                            }}
                            onClick={(c) => {
                              const removeAction = isLead ? props.removeTagsFromLead : props.removeTagsFromClient;
                              removeAction(c, R.map(R.prop('tagId'))(selectedTags));
                            }}
                          />
                        )}
                      </Card>
                    </div>}
                </div>
              </Card>}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.tags,
  office: R.pathOr(null, ['login', 'office'])(state),
  isEnabled: R.includes('Campaigns', R.pathOr([], ['login', 'features'], state)),
  hasLead: R.includes('Leads', R.pathOr([], ['login', 'features'], state)),
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
