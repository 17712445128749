import React, { useCallback } from 'react';
import {
  Typography, Paper, Button, Menu, MenuItem, Divider,
  IconButton, ListItemIcon, ListItemText, Popover,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ShortTextIcon from '@mui/icons-material/ShortText';
import GridOnIcon from '@mui/icons-material/GridOn';
import FileUploadIcon from '@mui/icons-material/CloudUpload';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SquareIcon from '@mui/icons-material/CropSquare';
import TitleIcon from '@mui/icons-material/Title';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SubjectIcon from '@mui/icons-material/Subject';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EventIcon from '@mui/icons-material/Event';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ListIcon from '@mui/icons-material/List';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EyeIcon from '@mui/icons-material/Visibility';
import SchoolIcon from '@mui/icons-material/School';
import {
  cond, equals, T, always, identity, difference, isEmpty
} from 'ramda';
import { SimpleWidgetComponent } from './SimpleWidgetContainer.component';
import { RadioGridComponent } from './RadioGrid.component';
import { FileUploadComponent } from './FileUpload.component';
import { SignatureComponent } from './Signature.component';
import { DrawablePictureComponent } from './DrawablePicture.component';
import { FixedTableComponent } from './FixedTable.component';
import { FlexibleTableComponent } from './FlexibleTable.component';
import { ConsentPapersComponent } from './ConsentPapers.component';
import { ContainerComponent as AnswerSimpleWidgetComponent } from './AnswerSimpleWidgetContainer.component';
import { RadioGridComponent as AnswerRadioGridComponent } from './AnswerRadioGrid.component';
import { TableComponent } from './AnswerTable.component';
import { DrawablePictureComponent as AnswerDrawablePicture } from './AnswerDrawablePicture.component';
import { SignatureComponent as AnswerSignature } from './AnswerSignature.component';
import { ConsentPapersComponent as AnswerConsentPapers } from './AnswerConsentPapers.component';
import * as ctx from '../intake.context';
import ConditionalIndicator from './conditional-indicator.component';
import {
  FormChild, Instruction, Header, Section, SectionChild, IntakeForm,
  ComplexWidget, ComplexWidgetType, PageBreak, SimpleWidgetType, IndexAndConds,
} from '../intake-types';
import { TipTap } from '../../../components/Editor/editor.component';
import { useDispatch } from 'react-redux';
import { OrderButtons } from './SectionChildOrderButtons.component';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
    overflow: 'hidden',
  },
  paper: {
    padding: theme.spacing(1),
  },
  hoverSectionChildContainer: {
    backgroundColor: 'unset',
    borderRadius: 'unset',
    '&:hover': {
      backgroundColor: '#f6f8fc96',
      borderRadius: 4,
    },
  },
}));

interface ComplexWidgetProps {
  data: ComplexWidget
  index: number;
  maxIndex: number;
  questionCond: IndexAndConds;
}

const ComplexWidgetComponent = ({
  data, index, questionCond, maxIndex,
}: ComplexWidgetProps) => {
  if (data.typeData.type === 'RadioGrid') {
    return (
      <RadioGridComponent
        id={data.id}
        data={data}
        required={data.required}
        index={index}
        maxIndex={maxIndex}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />
    );
  }
  if (data.typeData.type === 'FixedTable') {
    return (
      <FixedTableComponent
        id={data.id}
        data={data}
        index={index}
        maxIndex={maxIndex}
        required={data.required}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />
    );
  }
  if (data.typeData.type === 'FlexibleTable') {
    return (
      <FlexibleTableComponent
        id={data.id}
        data={data}
        index={index}
        maxIndex={maxIndex}
        required={data.required}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />
    );
  }
  if (data.typeData.type === 'FileUpload') {
    return (
      <FileUploadComponent
        id={data.id}
        data={data}
        required={data.required}
        index={index}
        maxIndex={maxIndex}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />
    );
  }
  if (data.typeData.type === 'Signature') {
    return (
      <SignatureComponent
        id={data.id}
        data={data}
        required={data.required}
        index={index}
        maxIndex={maxIndex}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />
    );
  }
  if (data.typeData.type === 'DrawablePicture') {
    return (
      <DrawablePictureComponent
        id={data.id}
        data={data}
        required={data.required}
        index={index}
        maxIndex={maxIndex}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />
    );
  }
  if (data.typeData.type === 'ConsentPapers') {
    return (
      <ConsentPapersComponent
        id={data.id}
        data={data}
        required={data.required}
        index={index}
        maxIndex={maxIndex}
        forOffice={data.forOffice}
        questionCond={questionCond}
      />);
  }
};

interface SectionChildProps {
  data: SectionChild;
  index: number;
  maxIndex: number;
  isPrivate?: boolean;
  revision: IntakeForm;
}
const SectionChildComponent = ({
  data, index, isPrivate = false, revision, maxIndex,
}: SectionChildProps) => {
  const questionCond = React.useMemo(() => {
    const qind = ctx.getIndex(revision.children, 'question', data.id);
    return ctx.getIndexAndConds(revision.conditionals, 'question', data.id, qind);
  }, [revision]);
  if (data.type === 'ComplexWidget') {
    const d = { ...data, forOffice: isPrivate };
    return (
      <ComplexWidgetComponent
        data={d}
        index={index}
        maxIndex={maxIndex}
        questionCond={questionCond}
      />
    );
  }
  if (data.type === 'SimpleWidget') {
    const d = { ...data, forOffice: isPrivate };
    return (
      <SimpleWidgetComponent
        data={d}
        index={index}
        maxIndex={maxIndex}
        questionCond={questionCond}
      />
    );
  }
  return <div>{data.type} not done yet</div>;
};

interface SectionEditProps {
  data: Section;
  onSave: (name: string, description: string) => void;
  index?: number;
}

interface SectionEditState {
  name: string;
  description: string;
}

const SectionEditComponent = ({ data, onSave, index }: SectionEditProps) => {
  const [state, setState] = React.useState<SectionEditState>({
    name: data.name, description: data.description
  });

  return (
    <div>
      <div>
        <TipTap
          content={state.name}
          onChange={(body) => setState(s => ({ ...s, name: body }))}
          width='100%'
          height={200}
        />
        <br />
        <br />
        <TipTap
          content={state.description}
          onChange={(body) => setState(s => ({ ...s, description: body }))}
          width='100%'
          height={200}
        />
        <br />
        <Button
          onClick={() => onSave(state.name, state.description)}
          className={`sked-test-form-builder-new-section-save-button${index}`}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </div>
    </div>
  );
};


interface SectionProps {
  data: Section;
  index: number;
  isPrivate?: boolean;
  sectionCond: IndexAndConds;
  revision: IntakeForm;
}

const options: SimpleWidgetType['type'][] = [
  'ShortText',
  'LongText',
  'Number',
  'Email',
  'Date',
  'CheckboxSingle',
  'Checkbox',
  'Radio',
  'Dropdown'
];

const complexOptions = [
  { widget: 'RadioGrid', label: 'Radio Grid', icon: <GridOnIcon /> },
  { widget: 'FileUpload', label: 'File Upload', icon: <FileUploadIcon /> },
  { widget: 'FixedTable', label: 'Fixed Table', icon: <TableChartIcon /> },
  { widget: 'FlexibleTable', label: 'Flexible Table', icon: <LowPriorityIcon /> },
  { widget: 'DrawablePicture', label: 'Drawable Picture', icon: <InsertPhotoIcon /> },
  { widget: 'Signature', label: 'Signature', icon: <EditIcon /> },
  { widget: 'ConsentPapers', label: 'Consent Papers', icon: <DescriptionIcon /> },
];

const QuestionTypeIcon = (o: SimpleWidgetType['type']) => {
  return cond([
    [equals('ShortText'), () => <ShortTextIcon />],
    [equals('LongText'), () => <SubjectIcon />],
    [equals('Number'), () => <LooksOneIcon />],
    [equals('Email'), () => <AlternateEmailIcon />],
    [equals('Date'), () => <EventIcon />],
    [equals('CheckboxSingle'), () => <CheckBoxIcon />],
    [equals('Checkbox'), () => <FormatListBulletedIcon />],
    [equals('Radio'), () => <RadioButtonCheckedIcon />],
    [equals('Dropdown'), () => <ListIcon />]
  ])(o);
};

const SectionComponentInner = ({
  data, index, isPrivate, sectionCond, revision
}: SectionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editSection, setEditSection] = React.useState(data.isEdit);
  const [sideAnchorEl, setSideAnchorEl] = React.useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [popoverType, setPopoverType] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSide = () => {
    setSideAnchorEl(null);
  };

  const saveSection = (name: string, description: string) => {
    setEditSection(false);
    dispatch(ctx.editFormChild({
      ...data,
      name,
      description
    }));
  };

  const openSimpleMenu = (event: any) => {
    setSideAnchorEl(event.currentTarget);
    /* handleClose(); */
  };

  const addSimpleQuestion = (type: SimpleWidgetType['type']) => (): void => {
    setSideAnchorEl(null);
    handleClose();
    dispatch(ctx.appendSectionChild(data.id, 'SimpleWidget', type));
  };

  const addComplexWidget = (type: ComplexWidgetType['type']) => {
    handleClose();
    dispatch(ctx.appendComplexWidget(data.id, type));
  };

  const addFormChild = (type: FormChild['type'], id: number) => {
    handleClose();
    dispatch(ctx.addFormChildAfter(type, id));
  };

  const openPopover = (type: string) => (event: React.MouseEvent<HTMLElement>) => {
    setPopoverType(type);
    setPopoverAnchorEl(event.currentTarget);
  };

  const closePopover = useCallback(() => {
    setPopoverAnchorEl(null);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(ctx.deleteFormChild(data.id, 'Section', sectionCond.index));
  }, [data.id, sectionCond.index]);

  const sectionMaxIndex = React.useMemo(() => {
    return data.children.length - 1;
  }, [data.children]);

  return (
    <Paper
      id={`${data.type}-${data.id}`}
      style={{
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: isPrivate ? '#008bcf66' : '#fff',
      }}
      className="intake-forms-child">
      <Popover
        id="mouse-over-popover"
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={closePopover}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
      >
        <div style={{
          maxHeight: '600px',
          overflow: popoverType === 'DrawablePicture' ? 'hidden' : 'unset',
        }}>
          {cond([
            [equals('SectionInstruction'), () => (
              <div>This is an in-section instruction.</div>
            )],
            [equals('ShortText'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Example',
                  type: 'SimpleWidget',
                  placeholder: 'A short answer',
                  forOffice: false,
                  questionType: {
                    type: 'ShortText',
                  },
                }}
              />
            )],
            [equals('LongText'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Long Example',
                  type: 'SimpleWidget',
                  placeholder: 'A longer answer\nwith multiple lines',
                  forOffice: false,
                  questionType: {
                    type: 'LongText',
                  },
                }}
              />
            )],
            [equals('Number'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Number Picker',
                  type: 'SimpleWidget',
                  forOffice: false,
                  placeholder: '10',
                  questionType: {
                    type: 'Number',
                  },
                }}
              />
            )],
            [equals('Email'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Email Input',
                  type: 'SimpleWidget',
                  placeholder: 'test@email.com',
                  forOffice: false,
                  questionType: {
                    type: 'Email',
                  },
                }}
              />
            )],
            [equals('Dropdown'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Select one',
                  type: 'SimpleWidget',
                  placeholder: 'Option 1',
                  forOffice: false,
                  questionType: {
                    type: 'Dropdown',
                    options: [],
                  },
                }}
              />
            )],
            [equals('Date'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Date Selection',
                  type: 'SimpleWidget',
                  forOffice: false,
                  questionType: {
                    type: 'Date',
                  },
                }}
              />
            )],
            [equals('Radio'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Radio Options:',
                  type: 'SimpleWidget',
                  forOffice: false,
                  questionType: {
                    type: 'Radio',
                    options: ['Option 1', 'Option 2', 'Option 3'],
                  },
                }}
              />
            )],
            [equals('CheckboxSingle'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Checkbox?',
                  type: 'SimpleWidget',
                  forOffice: false,
                  questionType: {
                    type: 'CheckboxSingle',
                  },
                }}
              />
            )],
            [equals('Checkbox'), () => (
              <AnswerSimpleWidgetComponent
                noCtx
                data={{
                  id: 0,
                  label: 'Pick all that apply: ',
                  type: 'SimpleWidget',
                  forOffice: false,
                  questionType: {
                    type: 'Checkbox',
                    options: ['Option 1', 'Option 2', 'Option 3'],
                    direction: 'row',
                  },
                }}
              />
            )],
            [equals('RadioGrid'), () => (
              <AnswerRadioGridComponent
                noCtx
                id={0}
                required
                data={{
                  type: 'RadioGrid',
                  columnLabels: ['Low', 'Medium', 'High'],
                  rowLabels: ['Option 1', 'Option 2', 'Option 3'],
                }}
              />
            )],
            [equals('FileUpload'), () => (
              <div style={{ paddingBottom: '20px' }}>
                Select a nice file:
                <br />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<FileUploadIcon />}
                  >
                    Upload File
                  </Button>
                </label>
              </div>
            )],
            [equals('FixedTable'), () => (
              <TableComponent
                noCtx
                id={0}
                required
                data={{
                  type: 'FixedTable',
                  inputs: [
                    {
                      id: 0,
                      type: 'SimpleWidget',
                      label: 'Checkbox',
                      forOffice: false,
                      questionType: {
                        type: 'CheckboxSingle',
                      },
                    },
                    {
                      id: 1,
                      label: 'Short Text',
                      type: 'SimpleWidget',
                      placeholder: 'A short answer',
                      forOffice: false,
                      questionType: {
                        type: 'ShortText',
                      },
                    },
                    {
                      id: 2,
                      label: 'Date Selection',
                      type: 'SimpleWidget',
                      forOffice: false,
                      questionType: {
                        type: 'Date',
                      },
                    }
                  ],
                  rows: {
                    header: 'Example Table',
                    labels: ['Row 1', 'Row 2', 'Row 3'],
                  },
                }}
              />
            )],
            [equals('FlexibleTable'), () => (
              <TableComponent
                noCtx
                id={0}
                required
                data={{
                  type: 'FlexibleTable',
                  defaultRowCount: 3,
                  inputs: [
                    {
                      id: 0,
                      type: 'SimpleWidget',
                      label: 'Checkbox',
                      forOffice: false,
                      questionType: {
                        type: 'CheckboxSingle',
                      },
                    },
                    {
                      id: 1,
                      label: 'Short Text',
                      type: 'SimpleWidget',
                      placeholder: 'A short answer',
                      forOffice: false,
                      questionType: {
                        type: 'ShortText',
                      },
                    },
                    {
                      id: 2,
                      label: 'Date Selection',
                      type: 'SimpleWidget',
                      forOffice: false,
                      questionType: {
                        type: 'Date',
                      },
                    }
                  ],
                }}
              />
            )],
            [equals('DrawablePicture'), () => (
              <AnswerDrawablePicture
                noCtx
                id={0}
                required
                printMode={false}
                data={{
                  type: 'DrawablePicture',
                  pictureUrl: 'https://s3.amazonaws.com/sked-v2/uploads/Dev%20%22int%22/office/119/images/5d3dcb603868bf9fc93498aefa090d3bc46da6938e14dee39c7e86090759517e/front%20back%20diagram.jpg',
                  options: [
                    { label: 'Color 1', color: 'red' },
                    { label: 'Color 2', color: 'blue' },
                    { label: 'Color 3', color: 'green' },
                  ],
                }}
              />
            )],
            [equals('Signature'), () => (
              <AnswerSignature
                noCtx
                id={0}
                required
                data={{
                  type: 'Signature',
                }}
              />
            )],
            [equals('ConsentPapers'), () => (
              <AnswerConsentPapers
                noCtx
                id={0}
                required
                data={{
                  type: 'ConsentPapers',
                  name: 'Terms of Service',
                  body: 'Muhahah',
                }}
              />
            )]
          ])(popoverType)}
        </div>
      </Popover>
      <div style={{ display: 'flex' }}>
        <Typography onClick={() => setEditSection(true)} variant="h5" component="h2" gutterBottom>
          {isPrivate ? 'Private Section ' : 'Section '}
          {!isPrivate &&
          <>
          #{sectionCond.index}
            <ConditionalIndicator
              type='Section'
              index={sectionCond.index}
              conds={sectionCond.conditionals}/>
          </>}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <OrderButtons
          index={index}
          data={data}
          id={data.id}
          questionIndex={sectionCond.index}
          type='Section'
          handleDelete={handleDelete}
          handleEdit={() => setEditSection(true)}
        />
      </div>
      {!editSection && <div>
        <div
          onClick={() => setEditSection(true)}
          className='sked-test-form-builder-new-section-description-body'
          dangerouslySetInnerHTML={{ __html: data.name }}
        >
        </div>
        <div
          onClick={() => setEditSection(true)}
          className='sked-test-form-builder-new-section-description-body'
          dangerouslySetInnerHTML={{ __html: data.description }}
        >
        </div>
        {data.name === '' && data.description === '' &&
          <IconButton
            className='sked-test-form-builder-new-section-description-edit-button'
            aria-label="edit section name & description"
            onClick={() => setEditSection(true)}>
            <EditIcon fontSize="inherit" />
          </IconButton>}
      </div>}
      {editSection &&
        <SectionEditComponent
          data={data}
          onSave={saveSection}
          index={sectionCond?.index}
        />}
      <div>
        {data.children.map((child, i) =>
          <div
            key={`child-container-${child.id}`}
            className={classes.hoverSectionChildContainer}>
            <SectionChildComponent
              key={`child-${child.id}`}
              data={child}
              isPrivate={false}
              index={i}
              maxIndex={sectionMaxIndex}
              revision={revision}
            />
          </div>)}
        <div style={{ marginTop: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClick}
            className={`sked-test-form-builder-new-section-add-button${sectionCond?.index}`}
          >
            Add
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={addSimpleQuestion('SectionInstruction')}
              className={`sked-test-form-builder-new-section-instructions${sectionCond?.index}`}
              style={{
                width: '230px',
              }}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Section Instruction" />
              <div
                onMouseEnter={openPopover('SectionInstruction')}
                onMouseLeave={closePopover}
                aria-owns={popoverAnchorEl ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                style={{
                  zIndex: 1000,
                  position: 'absolute',
                  right: 0,
                  width: '24px',
                }}
              >
                <EyeIcon
                  style={{
                    color: 'gray',
                    fontSize: '14px',
                  }} />
              </div>
            </MenuItem>
            <MenuItem
              onClick={openSimpleMenu}
              className={`sked-test-form-builder-new-simple-question${sectionCond?.index}`}
              style={{
                width: '230px',
              }}>
              <ListItemIcon>
                <ShortTextIcon />
              </ListItemIcon>
              <ListItemText primary="Simple Question" />
              <div
                style={{
                  zIndex: 1000,
                  position: 'absolute',
                  right: 0,
                  width: '30px',
                }}>
                <ArrowRightIcon />
              </div>
            </MenuItem>
            {complexOptions.map(({ label, widget, icon }) => {
              return (
                <MenuItem
                  className={`sked-test-form-builder-new-${label.toLowerCase().replace(/ /g, '-')}${sectionCond?.index}`}
                  onClick={() => addComplexWidget(widget as ComplexWidgetType['type'])}>
                  <ListItemIcon>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={label} />
                  <div
                    onMouseEnter={openPopover(widget)}
                    onMouseLeave={closePopover}
                    aria-owns={popoverAnchorEl ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    style={{
                      zIndex: 1000,
                      position: 'absolute',
                      right: 0,
                      width: '24px',
                    }}
                  >
                    <EyeIcon
                      style={{
                        color: 'gray',
                        fontSize: '14px',
                      }} />
                  </div>
                </MenuItem>
              );
            })}
            <Divider />
            <MenuItem
              className={`sked-test-form-builder-edit-add-section${sectionCond?.index}`}
              onClick={() => addFormChild('Section', data.id)}>
              <ListItemIcon>
                <SquareIcon />
              </ListItemIcon>
              <ListItemText primary="Section" />
            </MenuItem>
            <MenuItem
              className={`sked-test-form-builder-edit-add-header${sectionCond?.index}`}
              onClick={() => addFormChild('Header', data.id)}>
              <ListItemIcon>
                <TitleIcon />
              </ListItemIcon>
              <ListItemText primary="Header" />
            </MenuItem>
            <MenuItem
              className={`sked-test-form-builder-edit-add-instruction${sectionCond?.index}`}
              onClick={() => addFormChild('Instruction', data.id)}>
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>
              <ListItemText primary="Instruction" />
            </MenuItem>
            <MenuItem
              className={`sked-test-form-builder-edit-add-pagebreak${sectionCond?.index}`}
              onClick={() => addFormChild('PageBreak', data.id)}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Page Break" />
            </MenuItem>
          </Menu>

          <Menu
            id="simpli-menu"
            anchorEl={sideAnchorEl}
            keepMounted
            open={Boolean(sideAnchorEl)}
            onClose={handleCloseSide}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            {options.map(o => {
              const primary = cond([
                [equals('ShortText'), always('Short Text')],
                [equals('LongText'), always('Long Text')],
                [equals('CheckboxSingle'), always('Checkbox - Single')],
                [equals('Checkbox'), always('Checkbox - Multiple')],
                [T, identity],
              ])(o) as string;
              return (
                <MenuItem
                  className={`sked-test-form-builder-edit-add-${o.toLowerCase()}${sectionCond?.index}`}
                  key={o}
                  onClick={addSimpleQuestion(o)}
                  style={{
                    width: '250px',
                  }}
                >
                  <ListItemIcon>{QuestionTypeIcon(o)}</ListItemIcon>
                  <ListItemText primary={primary} />
                  <div
                    onMouseEnter={openPopover(o)}
                    onMouseLeave={closePopover}
                    aria-owns={popoverAnchorEl ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    style={{
                      zIndex: 1000,
                      position: 'absolute',
                      right: 0,
                      width: '24px',
                    }}
                  >
                    <EyeIcon
                      style={{
                        color: 'gray',
                        fontSize: '14px',
                      }} />
                  </div>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </Paper>);
};

const SectionComponent = React.memo(SectionComponentInner, (prev, next) => {
  if (equals(prev.data, next.data) &&
    equals(prev.index, next.index) &&
    equals(prev.sectionCond, next.sectionCond)) {
    const cdiff = difference(prev.revision.children, next.revision.children);
    const condiff = difference(prev.revision.conditionals, next.revision.conditionals);
    /* If one of the conditionals within this section has update, we must render
     to update the conditional tags
  */
    const condChanges = condiff.filter(({
      questionIndex, target
    }) => {
      const quests = target
        .filter(({ Question }) => Boolean(Question))
        .map(({ Question }) => Question);
      return ctx.inSection(
        next.revision.children,
        next.data.id,
        [questionIndex].concat(quests)
      );
    });
    if (!isEmpty(condChanges)) {
      return false;
    }
    if (!isEmpty(cdiff)) {
    /* If the current section has updated, we must render. */
      const dataChanges = cdiff.filter(({ id }) => id === next.data.id);
      if (!isEmpty(dataChanges)) {
        return false;
      }
      /* If the size of any previous revision has changed, we must render to reindex */
      const pind = prev.revision.children.findIndex((s) => s.id === cdiff[0].id);
      const nind = next.revision.children.findIndex((s) => s.id === cdiff[0].id);
      if (nind >= next.index) {
        const p = prev.revision.children[pind];
        const n = next.revision.children[nind];
        if (p.type === 'Section' && n.type === 'Section') {
          if (p.children.length !== n.children.length)
            return false;
        }
      }
    }
    return true;
  }
  return false;
});


interface HeaderEditProps {
  data: Header | Instruction;
  onSave: (text: string) => void;
}

const HeaderEditComponent = ({ data, onSave }: HeaderEditProps) => {
  const [state, setState] = React.useState({ text: data.text });
  return (
    <div id={`${data.type}-${data.id}`} style={{ marginBottom: '20px' }}>
      <div>
        {data.type === 'Header' &&
          <TipTap
            content={state.text}
            onChange={(body) => setState(s => ({ ...s, text: body }))}
            width='100%'
            height={200}
          />}
        {data.type === 'Instruction' &&
          <TipTap
            content={state.text}
            onChange={(body) => setState(s => ({ ...s, text: body }))}
            width='100%'
            height={200}
          />}
        <br />
        <Button
          className='sked-test-form-builder-edit-add-header-save'
          onClick={() => onSave(state.text)}
          variant="contained"
          color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

interface HeaderProps {
  data: Header | Instruction;
  index: number;
}
const HeaderComponent = ({ data, index }: HeaderProps) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(data.isEdit);

  const saveHeader = (text: string) => {
    setEdit(false);
    dispatch(ctx.editFormChild({
      ...data,
      isEdit: false,
      text
    }));
  };

  const handleDelete = () => {
    dispatch(ctx.deleteFormChild(data.id, null, index));
  };

  if (edit) {
    return <HeaderEditComponent data={data} onSave={saveHeader} />;
  }

  return (
    <div
      id={`${data.type}-${data.id}`}
      style={{ display: 'flex' }}
      className="intake-forms-child">
      {data.type === 'Header' &&
        <div
          onClick={() => setEdit(true)}
          dangerouslySetInnerHTML={{ __html: data.text }}
        >
        </div>}
      {data.type === 'Instruction' &&
        <div
          onClick={() => setEdit(true)}
          dangerouslySetInnerHTML={{ __html: data.text }}
        >
        </div>}
      <div style={{ flexGrow: 1 }}></div>
      {data.text === '' &&
        <IconButton aria-label="edit header or instruction" onClick={() => setEdit(true)}>
          <EditIcon fontSize="inherit" />
        </IconButton>}
      <OrderButtons
        index={index}
        data={data}
        id={data.id}
        questionIndex={null}
        type={data.type}
        handleDelete={handleDelete}
        handleEdit={() => setEdit(true)}
      />
    </div>
  );
};


interface PageBreakProps {
  data: PageBreak;
  index: number;
  pageCond: IndexAndConds;
}

const PageBreakComponentInner = ({
  data, index, pageCond,
}: PageBreakProps) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(ctx.deleteFormChild(data.id, 'Page', pageCond.index));
  };
  return (
    <Paper id={`${data.type}-${data.id}`} style={{ marginBottom: '20px', padding: '10px' }}>
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Page Break (Page #{pageCond.index})
          <ConditionalIndicator
            type='Page'
            index={pageCond.index}
            conds={pageCond.conditionals}/>
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <OrderButtons
          index={index}
          data={data}
          id={data.id}
          questionIndex={pageCond.index}
          type='Page Break'
          handleDelete={handleDelete}
        />
      </div>
    </Paper>
  );
};

const PageBreakComponent = React.memo(PageBreakComponentInner, (prev, next) => {
  return equals(prev, next);
});

interface FormChildProps {
  child: FormChild;
  index: number;
  revision: IntakeForm;
}


const LocalFormChildComponent = ({
  child, index, revision,
}: FormChildProps) => {
  const ct = child.type === 'PageBreak' ? 'page' : 'section';
  const sectionCond = React.useMemo(() => {
    const sind = ctx.getIndex(revision.children, ct, child.id);
    return ctx.getIndexAndConds(revision.conditionals, ct, child.id, sind);
  }, [revision]);
  if (child.type === 'Header') {
    return <HeaderComponent data={child} index={index} />;
  }
  if (child.type === 'Instruction') {
    return <HeaderComponent data={child} index={index} />;
  }
  if (child.type === 'Section') {
    return (
      <SectionComponent
        data={child}
        index={index}
        sectionCond={sectionCond}
        revision={revision}
      />
    );
  }
  if (child.type === 'PrivateSection') {
    return (
      <SectionComponent
        data={child}
        index={index}
        isPrivate
        sectionCond={sectionCond}
        revision={revision}
      />
    );
  }
  if (child.type === 'PageBreak') {
    return (
      <PageBreakComponent
        data={child}
        index={index}
        pageCond={sectionCond}
      />
    );
  }
};

export const FormChildComponent = React.memo(LocalFormChildComponent, (prev, next) => {
  if (
    (prev.child.type === 'Header' || prev.child.type === 'Instruction')
    &&
    (next.child.type === 'Header' || next.child.type === 'Instruction')) {
    return prev.index === next.index && prev.child.text === next.child.text;
  }
  if (prev.child.type === 'PageBreak') {
    return prev.index === next.index;
  }
  return prev.index === next.index && equals(prev.child, next.child) &&
    equals(prev.revision, next.revision);
});
