import { makeStyles } from '@mui/styles';

const activeColor = 'rgb(255 255 255 / 26%)';
const width = 240;

// Regular 400, Medium 500, Semi-Bold 600, Bold 700

export const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width,
      flexShrink: 0,
    },
    '@media print': {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width,
    color: 'rgb(238, 238, 238)',
    height: 'calc(100% - 54px)',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    top: '54px',
    borderRight: 'none',
    borderBottomRightRadius: 30,
    background: theme.palette.drawer.main,

    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& a': {
      outline: 'none',
    },
    '@media print': {
      display: 'none'
    },
  },
  drawerRootMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  drawerPaperMobile: {
    width,
    background: theme.palette.drawer.main,
    color: 'rgb(238, 238, 238)',
    height: '100%',
    scrollbarWidth: 'none',
    top: '0px',
    borderRight: 'none',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  drawerList: {
    paddingTop: 0,
    paddingBottom: 102,
  },
  listSubheaderRoot: {
    color: 'rgb(238, 238, 238)',
    paddingTop: '14px',
    paddingBottom: '0px',
    background: 'transparent',
    marginBottom: 8,

    '@media print': {
      display: 'none'
    },
  },
  listSubheaderTypography: {
    fontSize: 13,
    fontWeight: 400,
    color: '#FFF'
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    color: '#FFF',
  },
  subName: {
    fontSize: 11.5,
    fontWeight: 400,
    color: '#F0F0F0'
  },
  subMenuText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#ffffffde',
    padding: '6px 8px',
    paddingLeft: 16,
    borderRadius: 8,
  },
  subMenuDisabled: {
    color: 'rgb(255, 255, 255, 0.3)'
  },
  itemIcon: {
    minWidth: 36,

    '& svg': {
      width: 23,
      height: 23,
      opacity: '0.8',
      scale: 0.9,
    },
  },
  imageName: {
    height: 17,
    width: 'auto',
    opacity: '0.94 !important'
  },
  opacity: {
    opacity: '1 !important'
  },
  hover: {
    backgroundColor: theme.palette.drawer.hover,
  },
  itemContainer: {
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 8,
  
    '&:hover': {
      backgroundColor: theme.palette.drawer.hover,
    },
  },
  listItem: {
    outline: 'none',
    padding: '0px 8px',

    '&:hover': {
      backgroundColor: 'unset',
    },

    '& svg': {
      color: '#FAFAFA',
      fontSize: '20px',
      opacity: '0.8'
    },
    '&.Mui-selected': {
      backgroundColor: 'unset',
      textDecoration: 'none',
      
      '& > div': {
        backgroundColor: activeColor,
      },
      '& svg': {
        opacity: 1,
      }
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'unset',

      '& > div': {
        backgroundColor: activeColor,
      },
    },
    '& .MuiListItemText-root': {
      marginTop: 1,
      marginBottom: 1,
    }
  },
  listSubItem: {
    outline: 'none',
    padding: 0,
    paddingRight: 8,
    paddingLeft: 35,

    '&:hover': {
      backgroundColor: 'unset',

      '& > div': {
        backgroundColor: theme.palette.drawer.hover,
      },
    },

    '& svg': {
      color: '#FAFAFA',
      fontSize: '20px',
      width: '20px',
      height: '20px',
      opacity: '0.8'
    },
    '&.Mui-selected': {
      backgroundColor: 'unset',
      textDecoration: 'none',

      '& > div': {
        backgroundColor: activeColor,
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'unset',

      '& > div': {
        backgroundColor: activeColor,
      },
    },
    '& .MuiListItemText-root': {
      marginTop: 1,
      marginBottom: 1,
      borderRadius: 8,
    }
  },
  listItemDisabled: {
    '& svg': {
      color: 'rgb(255, 255, 255, 0.3)',
    },
    '& span': {
      color: 'rgb(255, 255, 255, 0.3)',
    },
    '& p': {
      color: 'rgb(255, 255, 255, 0.3)',
    },
  },
  helpIcon: {
    color: '#FAFAFA',
  },
  skedContainer: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    width,
    height: 45,
    bottom: 90,
    zIndex: 'inherit',
    borderTop: `1px solid ${theme.palette.drawer.border}`,

    '& a': {
      borderTop: 'none',
    }
  },
  noBorderTop: {
    borderTop: 'none !important',
  },
  settingsContainer: {
    borderTop: `1px solid ${theme.palette.drawer.border}`,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width,
    height: 90,
    bottom: 0,
    borderBottomRightRadius: 30,
    zIndex: 'inherit',
  },
  settingsContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  settingsItem: {
    backgroundColor: theme.palette.drawer.background,
    flex: 1,
    height: 45,

    '&:hover': {
      backgroundColor: theme.palette.drawer.background,
      '& > div': {
        backgroundColor: theme.palette.drawer.hover,
      },
    },
  },
  borderRadius: {
    borderBottomRightRadius: 30,
  },
  hoverRadius: {
    borderRadius: '8px 8px 22px 8px',
  },
  switchOffice: {
    backgroundColor: theme.palette.drawer.background,
    padding: 10,
    paddingLeft: 0,
    height: 45,
    borderBottomRightRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '& > div': {
      padding: '9px 12px',
      borderRadius: '8px 8px 22px 8px',
    },

    '&:hover': {
      backgroundColor: theme.palette.drawer.background,
      '& > div': {
        backgroundColor: theme.palette.drawer.hover,
      },
    },
  },
  onboardItem: {
    borderBottom: `1px solid ${theme.palette.drawer.border}`,
    paddingTop: 4,
    paddingBottom: 4,

    '& > div': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  headerRoot: {
    background: '#FFF',
    position: 'fixed',
    width,
    minHeight: '54px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: theme.zIndex.drawer,

    '@media print': {
      display: 'none'
    },
  },
  headerContainer: {
    width,
    background: theme.palette.drawer.header,
    minHeight: '54px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.drawer.border}`,
    borderTopRightRadius: 30,
    boxSizing: 'border-box',
    paddingLeft: 32,
    paddingRight: 16,

    '@media print': {
      display: 'none'
    },
  },
  headerContainerMobile: {
    paddingLeft: 16,
    paddingRight: 12,

    '@media print': {
      display: 'none'
    },
  },
  newLabel: {
    backgroundColor: theme.palette.drawer.badgeNew,
    borderRadius: 6,
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: 12,
    marginLeft: 10,
    color: '#FFF',
  },
  betaLabel: {
    backgroundColor: '#40A2ED',
    borderRadius: 6,
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: 12,
    marginLeft: 10,
    color: '#FFF',
  },
  chipRoot: {
    borderRadius: '6px',
    height: '19px',
    backgroundColor: '#65B67F',
  
    ' & span': {
      color: '#FFF',
      fontWeight: 500,
      paddingLeft: '6px',
      paddingRight: '6px',
      fontSize: '12px'
    }
  },
  hideButton: {
    position: 'absolute',
    right: -15,
    top: 38,
    backgroundColor: 'white',
    color: 'gray',
    width: 30,
    height: 30,
    paddingLeft: 12,
    boxShadow: '0 1px 3px 1px rgba(0,0,0, 0.25)',
    transition: 'box-shadow 0.2s ease-in-out',
    zIndex: theme.zIndex.drawer,

    '&:hover': {
      backgroundColor: 'white',
      boxShadow: '0 1px 1px 1px rgba(0,0,0, 0.25)',
    }
  },
  sparkLogo: {
    height: 19,
    width: 'auto',
    marginTop: 4,
    opacity: '1 !important'
  },
}));
