import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    transition: '0.2s',
    overflow: 'hidden',
    overflowY: 'auto',
    paddingBottom: 10,
    borderRight: '2px solid rgba(0, 0, 0, 0.1)',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '4px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '4px',
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    paddingBottom: 6,
  },
  icon: {
    padding: 5,

    '& svg': {
      fontSize: 22,
    }
  },
  title: {
    color: '#616166',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 4,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    paddingTop: 6,

    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '6px',
      },
    },
  },
  otherDivs: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitles: {
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  pathListItem: {
    cursor: 'pointer',
  },
  templatePathContainer: {
    margin: '0px 10px',
  },
}));
