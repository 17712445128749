import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableHead,
  TableRow,
  TableBody,
  Grid,
} from '@mui/material';

import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog.component';
import { TableContainer, HeaderCell } from '../../../../components/CustomTable';
import Loading from '../../../../components/Loading/Loading.component';
import { Lead } from '../../leads.reducer';
import * as leadActions from '../../leads.actions';
import { useSelector } from '../../../../reducers';

import LeadTableRow from '../TableRow/TableRow.component';
import ConvertLead from '../Convert/Convert.component';
import EditLeadDialog from '../EditLead/EditLead.component';

type LeadsTableProps = {
  leads: Lead[];
  busy: boolean;
  page: number;
  totalPages?: number;
  perPage?: number;
  totalCount?: number;
  setPerPage?: (value: number) => void;
  remove: (id: number) => void;
  setPage: (page: number) => void;
  getLeads?: () => void;
  maxHeight: number | string;
}

const LeadsTable = ({
  leads,
  busy,
  page,
  totalPages,
  perPage,
  totalCount,
  remove,
  setPage,
  setPerPage,
  getLeads,
  maxHeight,
}: LeadsTableProps) => {
  const dispatch = useDispatch();
  const [toClient, setToClient] = useState<Lead>(null);
  const [toRemove, setToRemove] = useState<Lead>(null);

  const toEdit = useSelector(state => state.leads.toEdit);

  const handleDelete = () => {
    remove(toRemove.id);
    setToRemove(null);
  };

  const handleToEdit = (lead: Lead) => {
    dispatch(leadActions.handleToEdit(lead));
  };

  return (
    <div>
      <TableContainer
        maxHeight={maxHeight}
        pagination
        scrollPaddingBottom={20}
        paginationData={{
          addPage: (value) => setPage(page + value),
          page,
          perPage,
          setPerPage,
          totalCount,
          totalPages,
          currentLength: leads.length,
        }}
      >
        <TableHead>
          <TableRow>
            <HeaderCell fixed minWidth={180}>Name</HeaderCell>
            <HeaderCell>Phone</HeaderCell>
            <HeaderCell>Email</HeaderCell>
            <HeaderCell>Referral</HeaderCell>
            <HeaderCell>Source</HeaderCell>
            <HeaderCell>Created</HeaderCell>
            <HeaderCell align='center'>Actions</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!busy && leads.map((lead) =>
            <LeadTableRow
              key={lead.id}
              lead={lead}
              handleClient={() => setToClient(lead)}
              onRemove={() => setToRemove(lead)}
              onEdit={() => handleToEdit(lead)}
            />
          )}
        </TableBody>
      </TableContainer>
      {(!busy && !leads.length) && <Grid display="flex" justifyContent="center">No Leads</Grid>}
      <ConfirmationDialog
        open={!!toRemove?.id}
        cancel={() => setToRemove(null)}
        title="Remove Lead?"
        text={`Are you sure you want to remove the lead, ${toRemove?.firstName} ${toRemove?.lastName}?`}
        confirm={handleDelete}
        confirmText="Confirm"
      />
      <EditLeadDialog
        open={!!toEdit?.id}
        close={() => handleToEdit(null)}
        lead={toEdit}
        convertClick={() => setToClient(toEdit)}
      />
      <ConvertLead
        open={!!toClient?.id}
        lead={toClient}
        close={() => setToClient(null)}
        onConvert={getLeads}
      />
      <Loading loading={busy} vh={76} />
    </div>
  );
};

export default LeadsTable;
