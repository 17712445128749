import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import './recurring-edit.css';
import { pipe, pathOr, dissoc, merge } from 'ramda';
import * as rawActions from './recurring-edit.actions.jsx';
import { resetRecurring } from '../../recurring.actions.jsx';
import CreateMessage from '../../../../components/CreateMessage/CreateMessage.component';
import {
  updateAllAutomation, addLocalAutomation, removeAutomation, updateAutomation,
  getAutomationsByMsgId, automationsPatch,
} from '../../../Automations/automations.actions';

const AutomatedMessageEdit = ({ type }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const actions = bindActionCreators(
    merge(rawActions, {
      updateAllAutomation,
      addLocalAutomation,
      removeAutomation,
      updateAutomation,
      getAutomationsByMsgId,
      automationsPatch,
    }),
    dispatch);
  const props = useSelector((state) => {
    return ({
      ...state.editRecurring,
      ...pipe(pathOr({}, ['login', 'office']), dissoc('email'))(state),
      office: pathOr({}, ['login', 'office'])(state),
      features: pathOr([], ['login', 'features'])(state),
      isCopied: pathOr(false, ['messages', 'state'])(state) === 'COPY',
      automations: pathOr([], ['automations', 'automations', 'data'])(state),
    });
  });

  const isSpark = location.search.split('spark=')[1] === 'true';
  const { id } = useParams();

  return (
    <CreateMessage
      {...props}
      messageType={type}
      urlId={id}
      actions={actions}
      resetMessages={() => dispatch(resetRecurring())}
      isSpark={isSpark}
    />
  );
};

export default AutomatedMessageEdit;
