import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Paper, Button } from '@mui/material';

import { warningSnackbar } from '../../../components/Snackbar/snackbar.actions';

import BackdropLoading from '../../../components/BackdropLoading/backdropLoading.component';
import Profile from '../../../components/Profile/profile.component';

import { useSelector } from '../../../reducers';

import { createCredtCard } from './profile.service';

import { useStyles } from './profile.styles';

const ProfileSettingsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoading = location.hash.includes('loading=true');

  const user = useSelector((state) => state.login.user);
  const agencyAdmin = useSelector((state) => state.login.agencyAdmin);

  const [loading, setLoading] = useState(false);

  const handleCard = async () => {
    setLoading(true);
    const windowLocation: string = window.location as unknown as string;
    const w = window.open(`${windowLocation}?loading=true`, '_blank');
    const url = await createCredtCard(user?.userType?.UserAgency?.agencyId);
    if (url) {
      w.location = url;
      w.focus();
    } else {
      w.close();
      dispatch(warningSnackbar('Error opening stripe'));
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <h3>Profile Settings</h3>
      <Profile />
      {agencyAdmin && (
        <>
          <br />
          <h3>Billing Information</h3>
          <Paper className={classes.card}>
            <Button
              variant='contained'
              onClick={handleCard}
            >
              Update
            </Button>
          </Paper>
          <BackdropLoading loading={loading || isLoading} />
        </>
      )}
    </div>
  );
};

export default ProfileSettingsPage;
