import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalDate, ZoneId } from '@js-joda/core';
import {
  CircularProgress, IconButton, TableHead, TableRow,
  TableBody, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  set, lensPath,
  pipe, over, prop,
  propEq, assoc,
  head, keys,
} from 'ramda';
import qs from 'query-string';

import { Print as PrintIcon } from '@mui/icons-material';

import { getProfessionals } from '../Professionals/professionals.actions.jsx';
import { getStopManagers } from '../waiting/waiting.actions.js';
import * as clientActions from '../Clients/clients.actions';
import { Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import { usePromise } from '../../services/promise.hook';
import { tzParseFormat, formatStandard } from '../../services/joda.js';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component';

import Header from '../../components/PageHeader/PageHeader.component';
import FilterStartEndDate from '../../components/FilterStartEndDate/FilterStartEndDate.component';
import FilterStatus from '../../components/FilterStatus/FilterStatus.component';
import FilterApptTypes from '../../components/FilterApptTypes/FilterApptTypes.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { TableContainer, HeaderCell, BodyCell } from '../../components/CustomTable';
import AlertIcon from '../../icons/NotificationType.icon';

const useStyles = makeStyles(() => ({
  table: {
    '& > div': {
      '@media print': {
        overflow: 'visible !important',
        width: '100% !important',

        '& tr td div': {
          whiteSpace: 'normal',

          '& a': {
            whiteSpace: 'normal'
          },
        },
      }
    },
  },
  messageItem: {
    maxWidth: '500px',
  },
  icon: {
    width: 17,
    height: 17,
  }
}));

const fixLength = (str, l = 50) => {
  if (str && str.length > l) {
    return str.slice(0, l + 1) + '...';
  }
  return str;
};

const statuses = [
  'Done',
  'Open',
  'Postponed',
  'Waiting',
];

const stopTypes = [
  'Stop',
  'Task',
];

const emptyArray = [];

const StopManagerReports = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tz, proState, officeId, ehrSystem, headerHeight, clientsState } = useSelector(state => ({
    tz: state.login.office.timezone,
    officeId: state.login.office.id,
    proState: state.professionals,
    headerHeight: state.login.headerHeight,
    clientsState: state.clients.state,
  }));

  React.useEffect(() => {
    dispatch(getProfessionals());
  }, [officeId]);

  const [dateRange, setDateRange] = React.useState({
    start: LocalDate.now().toString(),
    end: LocalDate.now().toString()
  });


  const [query, setQuery] = React.useState({
    query: {
      stopType: [],
      done: statuses,
    },
    perPage: 25,
    page: 1
  });

  const setStatuses = (statuses) => {
    setQuery({
      ...query,
      query: {
        ...query.query,
        done: statuses
      },
    });
  };

  const setStopTypes = (statuses) => {
    setQuery({
      ...query,
      query: {
        ...query.query,
        stopType: statuses
      },
    });
  };

  const stopsState = usePromise(getStopManagers, {
    data: [],
    page: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const [perPage, setPerPage] = React.useState(25);
  const [apptTypes, setApptTypes] = React.useState([]);

  const getReport = (params = {}) => {
    const page = params.page || 1;
    const selectedTypes = params.types || apptTypes;
    const newQuery = pipe(
      set(lensPath(['page']), Number(page)),
      set(lensPath(['perPage']), params.perPage || Number(perPage)),
      set(lensPath(['query', 'greater']), params.dateRange?.start || dateRange.start),
      set(lensPath(['query', 'lesser']), params.dateRange?.end || dateRange.end),
      over(lensPath(['query', 'done']), s => (s || emptyArray).length === 0 ? undefined : s),
      over(lensPath(['query', 'stopType']), s => (s || emptyArray).length === 0 ? undefined : s),
      set(lensPath(['query', 'appointmentTypeIds']), selectedTypes.length > 0 ? selectedTypes.map(prop('id')) : undefined),
    )(query);

    if (params.status) {
      newQuery.query.done = params.status;
    }
    if (params.stopType) {
      newQuery.query.stopType = params.stopType;
    }

    const search = qs.stringify({ ...newQuery.query, perPage, page });

    navigate({ search });
  };

  const handleReset = () => {
    navigate({ search: '' });
  };

  const parseArray = (value, cb) => {
    if (!value) {
      return undefined;
    }
    if (Array.isArray(value)) {
      return value.map(n => cb(n));
    }
    return [cb(value)];
  };

  React.useEffect(() => {
    const s = qs.parse(location.search);
    const start = s.greater || LocalDate.now().toString();
    const end = s.lesser || LocalDate.now().toString();
    const greater = formatStandard(LocalDate.parse(start).atStartOfDay(ZoneId.of(tz)), true);
    const lesser = formatStandard(LocalDate.parse(end).atStartOfDay(ZoneId.of(tz)).plusDays(1), true);
    if (!location.search) {
      s.done = statuses;
      s.stopType = [];
    }
    const appointmentTypeIds = parseArray(s.appointmentTypeIds, Number);
    const query = {
      greater,
      lesser,
      stopType: parseArray(s.stopType, String),
      done: parseArray(s.done, String),
      appointmentQuery: appointmentTypeIds ? {
        appointmentTypeIds,
      } : undefined,
    };
    const perPage = Number(s.perPage || 25);
    const page = Number(s.page || 1);
    const body = {
      query,
      perPage,
      page
    };
    setQuery(pipe(
      assoc('query', query),
      assoc('perPage', perPage),
      assoc('page', page)
    ));
    setDateRange({
      start,
      end
    });
    setApptTypes(((query.appointmentQuery && query.appointmentQuery.appointmentTypeIds) || emptyArray).map(id => {
      const defaultType = { id, internalName: 'loading' };
      const type = proState.types.find(propEq('id', id)) || defaultType;
      return type;
    }));
    stopsState.invoke(body);
  }, [location.search, ehrSystem]);

  React.useEffect(() => {
    if (proState.types.length > 0) {
      setApptTypes(t => t.map(({ id }) => {
        const defaultType = { id, internalName: 'loading' };
        const type = proState.types.find(propEq('id', id)) || defaultType;
        return type;
      }));
    }
  }, [proState.types]);

  useTitle('Alert Reports');

  const addPage = (dir) => {
    const page = query.page + dir;
    getReport({ page });
  };

  const changePerPage = (perPage) => {
    setPerPage(perPage);
    getReport({ perPage });
  };

  const saveStopState = ({ status }) => {
    getReport({ stopType: status });
  };

  const filteredProfessionals = proState
    .professionals
    .filter(p => !p.isHidden);

  const handlePrint = () => {
    const total = stopsState.data.totalCount;
    const fetched = stopsState.data.data.length;
    if (total > fetched) {
      const newQuery = {
        ...query,
        perPage: total,
        page: 1
      };
      setPerPage(total);
      return stopsState.invoke(newQuery).then(() => {
        setTimeout(() => {
          window.print();
        }, 100);
      });
    } else {
      window.print();
    }
  };

  React.useEffect(() => {
    function afterPrint() {
      const fetched = stopsState.data.data.length;
      if (perPage === fetched) {
        setTimeout(() => {
          setPerPage(query.perPage);
          stopsState.invoke(query);
        }, 100);
      }
    }
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, [query, stopsState, perPage]);

  return (
    <>
      <Header
        title='Alert Reports'
        pageId='alert-reports'
        leftIcons={[
          <FilterStartEndDate dateRange={dateRange} setDateRange={setDateRange} save={getReport} />,
          <FilterStatus
            statuses={stopTypes}
            selectedStatuses={query.query.stopType || emptyArray}
            setStatuses={setStopTypes}
            defaultStatuses={[]}
            save={saveStopState}
            Icon={AlertIcon}
            title='Alert Type'
          />,
          <FilterStatus
            statuses={statuses}
            selectedStatuses={query.query.done || emptyArray}
            setStatuses={setStatuses}
            defaultStatuses={statuses}
            save={getReport}
          />,
          <FilterApptTypes
            allTypes={proState.types}
            setApptTypes={setApptTypes}
            professionals={filteredProfessionals}
            selectedTypes={apptTypes}
            save={getReport}
          />,
        ]}
        rightIcons={[
          <Grid display="flex" marginRight={1}>
            {stopsState.loading && <CircularProgress size={18} />}
          </Grid>,
          <IconButton aria-label="settings" onClick={handlePrint}>
            <PrintIcon className={classes.icon} />
          </IconButton>,
          <HeaderButton
            onClick={handleReset}
            title='Reset Filters'
            borderSolid
          />
        ]}
        onlyIconsWidth={850}
        breakPoints={[
          {
            width: 543,
            mobileItems: [3, 6]
          },
          {
            width: 440,
            mobileItems: [0, 1, 2, 3, 6]
          },
        ]}
      />
      <div>
        <div>
          <Row style={{ display: 'block' }}>
            <TableContainer
              className={classes.table}
              maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
              pagination
              paginationData={{
                addPage,
                currentLength: stopsState.data.data?.length,
                page: stopsState.data?.page,
                totalCount: stopsState.data?.totalCount,
                totalPages: stopsState.data?.totalPages,
                perPage,
                setPerPage: changePerPage,
              }}
            >
              <TableHead>
                <TableRow>
                  <HeaderCell fixed>Name</HeaderCell>
                  <HeaderCell>Phone</HeaderCell>
                  <HeaderCell>Date & Time</HeaderCell>
                  <HeaderCell>Alert Type</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell>Message</HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stopsState.data.data.map((stop) =>
                  <TableRow key={stop.id}>
                    <BodyCell nowrap fixed>
                      <a onClick={() => dispatch(clientActions.selectClient(stop.client))}>
                        {stop.client.firstName + ' ' + stop.client.lastName}
                      </a>
                    </BodyCell>
                    <BodyCell>
                      {stop.client.phone}
                    </BodyCell>
                    <BodyCell nowrap>
                      {tzParseFormat(stop.created, tz, 'MM/dd/yyyy h:mm a')}
                    </BodyCell>
                    <BodyCell>
                      {stop.stopType}
                    </BodyCell>
                    <BodyCell>
                      {head(keys(stop.done))}
                    </BodyCell>
                    <BodyCell minWidth={180} className={classes.messageItem}>
                      {fixLength(stop.message, 160)}
                    </BodyCell>
                  </TableRow>
                )}
              </TableBody>
            </TableContainer>
          </Row>
        </div>
      </div>
      <ClientEditDialog
        open={clientsState === 'CLIENT_SELECT'}
        from={'CLIENTS'}
        onClose={() => dispatch(clientActions.backToList())}
        back={() => dispatch(clientActions.back())}
        gotoClient={() => dispatch(clientActions.back())}
      />
    </>
  );
};

export default StopManagerReports;
