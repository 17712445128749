import * as R from 'ramda';
import { Dispatch } from '@reduxjs/toolkit';
import * as at from '../../actionTypes';
import api from '../../services/api.js';
import { popup } from '../../services/Popup.js';
import { remoteAction } from '../../services/actionService.js';
import { GetStore } from '../../reducers';
import { Appointment } from '../Appointments/appointments.types';

import {
  fetchCalendarData,
  fetchAppointments
} from './services/getCalendarApi';
import { getAppointmentType, getClient } from '../Appointments/appointments.service';

type GetCalendarInitProps = {
  date: string;
  view: string;
  timezone: string;
  officeId: number;
  admin: boolean;
}

export const getCalendarInit = ({
  date, view, timezone, officeId, admin
}: GetCalendarInitProps) => (dispatch: Dispatch) => {
  const key = admin ? `calendarSettings-${officeId}` : 'calendarSettings';
  const settings = JSON.parse(localStorage[key] || '{}');

  console.log('load settings from ' + key, settings);

  dispatch(updateSettings({ settings, officeId, admin }));

  return remoteAction({
    type: at.SKED_REMOTE_GET_CALENDAR_INIT,
    action: () => fetchCalendarData({ date, view, timezone })
  })(dispatch);
};

export const getCalendarAptsLoading = () => ({
  type: at.SKED_REMOTE_GET_CALENDAR_APTS,
  state: 'REQUEST'
});

type Settings = {
  groupByProfessional?: boolean;
  cellHeight?: number;
  cellWidth?: number;
  localInterval?: number;
  nameDisplay?: string;
}

type UpdateSettingsProps = {
  settings: Settings; 
  officeId: number;
  admin: boolean;
}
export const updateSettings = ({ settings, officeId, admin }: UpdateSettingsProps) => {
  const key = admin ? `calendarSettings-${officeId}` : 'calendarSettings';

  console.log('save settings to ' + key, settings);

  localStorage[key] = JSON.stringify(settings);

  return {
    type: at.SKED_CALENDAR_UPDATE_SETTINGS,
    payload: settings
  };
};

type GetCalendarAptsProps = {
  date: string;
  view: string;
  timezone: string;
}

export const getCalendarApts = ({
  date, view, timezone
}: GetCalendarAptsProps) => (dispatch: Dispatch, getStore: GetStore) => {
  const sequence = getStore().calendarv2.sequence + 1;
  return remoteAction({
    type: at.SKED_REMOTE_GET_CALENDAR_APTS,
    action: () => fetchAppointments({ date, view, timezone }).then(apts => ({
      appointments: apts,
      sequence
    }))
  })(dispatch);
};

export const reskedAppointmentRemote = (apt: Appointment) => remoteAction({
  type: at.SKED_REMOTE_RESKED_APT,
  action: () => api.put(`appointment/${apt.id}`, { time: apt.time, source: 'Admin' })
    .then(() => apt)
    .catch((error) => {
      popup('Error!', 'Failed to reschedule appointment');
      console.error(error);
    }),
});

const putAppt = (appt: Appointment) => ({
  type: at.SKED_CALENDAR_PUT_APPT,
  data: appt
});

const cancelAppt = (appt: Appointment) => ({
  type: at.SKED_CANCEL_APT,
  data: appt
});

export const handleScheduleUpdate = (data: Appointment) => (dispatch: Dispatch, getStore: GetStore) => {
  const appts = getStore().calendarv2.appointments;
  const existingAppt = appts.find(R.propEq('id', data.id));
  if (existingAppt) {
    if (R.has('Canceled', data.status)) {
      return dispatch(cancelAppt(data));
    } else {
      const appt = R.merge(data, {
        client: existingAppt.client,
        appointmentType: existingAppt.appointmentType
      });
      return dispatch(putAppt(appt));
    }
  }

  return Promise.all([
    getClient(data.clientId),
    getAppointmentType(data.appointmentTypeId)
  ]).then(([client, appointmentType]) => {
    const appt = R.merge(data, {
      client,
      appointmentType
    });
    return dispatch(putAppt(appt));
  }).catch(() => {
    console.log('error getting client and appointment type for appointment update');
  });
};

type Event = {
  data: string;
}

type Socket = {
  addEventListener: (event: string, f: (e: Event) => void) => void;
  removeEventListener: (event: string, f: (e: Event) => void) => void;
}

type Parsed = {
  tag: string;
  contents: Appointment;
}

export const initListenUpdates = (socket?: Socket) => (dispatch?: Dispatch, getStore?: GetStore) => {
  if (!socket) {
    return () => null as string;
  }

  const handleSocketUpdate = (event: Event) => {
    try {
      const parsed: Parsed = JSON.parse(event.data);
      if (parsed.tag === 'Appointment') {
        handleScheduleUpdate(parsed.contents)(dispatch, getStore);
      }
    } catch (e) {
      console.error(e);
    }
  };

  socket.addEventListener('message', handleSocketUpdate);
  return () => {
    socket.removeEventListener('message', handleSocketUpdate);
  };
};
