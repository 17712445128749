import React, { useMemo } from 'react';
import { DialogContentText, Grid } from '@mui/material';
import { LocalDateTime, ChronoUnit } from '@js-joda/core';
import { format } from '../../services/joda';

import Modal from '../../components/Modal/Modal.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';

type Props = {
  open: boolean;
  onClose: () => void;
  confirm: () => void;
  clients: number;
  sentTo: string;
  isSMS: boolean;
  isEmail: boolean;
  isPush: boolean;
  scheduledFor: string;
  timezone: string;
}

const SendModal = ({
  open, onClose, confirm, clients, sentTo, isSMS, isEmail, isPush, scheduledFor, timezone
}: Props) => {
  const total = clients > 0 ? clients : 'some';
  const comma = isEmail && (isSMS || isPush) ? ', ' : '';
  const comma2 = isSMS && isPush ? ', ' : '';

  const date = useMemo(() => {
    if (!scheduledFor) {
      return '';
    }
    if (scheduledFor === 'now') {
      return <span>Send <b>immediately</b></span>;
    }

    const parsed = LocalDateTime.parse(scheduledFor);

    const formated = format(parsed, 'eee, MM/dd/yyyy h:mm a')
      .replace('AM', 'am').replace('PM', 'pm');
 
    const currentDate = LocalDateTime.now();
    const daysBetween = currentDate.until(parsed, ChronoUnit.DAYS);
    return <span>Send on <b>{formated}</b> ({daysBetween} day{daysBetween > 1 ? 's' : ''} from now)</span>;
  }, [scheduledFor, timezone]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Are you sure you want to send?"
      className={'sked-test-custom-modal'}
      maxWidth={600}
      buttons={[
        <HeaderButton
          color={'primary'}
          onClick={confirm}
          title={'Yes, send'}
          className='sked-test-otm-confirm-modal-button-yes'
        />,
      ]}
    >
      <Grid>
        <DialogContentText>
          Your message will be sent to {total} {sentTo}{clients > 1 ? 's' : ''}.
        </DialogContentText>
        <ul>
          <li>
            <DialogContentText>
              {date}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText>
                Send via <b>{isEmail ? 'Email' : ''}{comma}{isSMS ? 'SMS' : ''}{comma2}{isPush ? 'Push Notification' : ''}</b>
            </DialogContentText>
          </li>
        </ul>
      </Grid>
    </Modal>
  );
};

export default SendModal;
