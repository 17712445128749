import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, TextField, Grid, IconButton, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import { checkEmailIsTaken } from '../../routes/Admin/Users/users.service';
import { editEmail } from '../../routes/Admin/Profile/profile.service';
import { getUserData } from '../../routes/Login/login.actions';
import * as at from '../../actionTypes';

import { useSelector } from '../../reducers';

import BackdropLoading from '../BackdropLoading/backdropLoading.component';

import { successSnackbar, errorSnackbar, warningSnackbar } from '../Snackbar/snackbar.actions';

import { useStyles } from './profile.styles';

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>();

  const user = useSelector((state) => state.login.user);

  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState(user?.email);
  const [name, setName] = useState(user?.name);
  const [loading, setLoading] = useState(true);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEmail(user.email);
  };

  const handleSave = async () => {
    if (user.email === email && user.name === name) {
      setIsEditing(false);
      return;
    }
    if (user.email !== email) {
      setLoading(true);
      const emailIsTaken = await checkEmailIsTaken(email);
      if (emailIsTaken) {
        dispatch(warningSnackbar('Email is already being used'));
        setLoading(false);
        return;
      }
    }
    const result = await editEmail(user.email !== email ? email : undefined, user.name !== name ? name : undefined);
    if (result) {
      dispatch(successSnackbar('Profile Edited!'));
      dispatch({ type: at.STATUS_UPDATE, data: { user: { ...user, email, name } } });
      setIsEditing(false);
    } else {
      dispatch(errorSnackbar('Error editing profile'));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!user?.id) {
      dispatch(getUserData());
    } else {
      setEmail(user.email);
      setName(user.name);
      setLoading(false);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditing, inputRef]);

  return (
    <Paper className={classes.card}>
      <div className={classes.content}>
        <div className={classes.input}>
          {isEditing ? (
            <Grid>
              <TextField
                label='Name'
                inputRef={inputRef}
                value={name}
                className={classes.inputName}
                required
                type="text"
                placeholder="Name"
                onChange={(event) => setName(event.target.value)}
              />
              <TextField
                label='Email'
                value={email}
                required
                type="email"
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
          ) : (
            <Grid>
              <Grid>
                <span>Name</span>
                <Typography variant="subtitle1">
                  {user.name}
                </Typography>
              </Grid>
              <Grid>
                <span>Email</span>
                <Typography variant="subtitle1">
                  {user.email}
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
        <div className={classes.action}>
          {isEditing ? (
            <Grid>
              <IconButton
                title='save'
                type="button"
                onClick={handleSave}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                title='Cancel'
                type="button"
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ) : (
            <IconButton
              title='Edit'
              type="button"
              onClick={handleEdit}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      </div>
      <BackdropLoading loading={loading} />
    </Paper>
  );
};

export default Profile;
