import React, { useRef, useState } from 'react';
import { sort, ascend, prop, pipe, map } from 'ramda';

import TypeContainer from './type-container.component.jsx';
import ProContainer from './pro-container.component.jsx';
import TypeDrag from './type-drag.component.jsx';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './constants.jsx';
import ItemsDragLayer from './items-drag-layer';

import { useStyles } from './drag-container.styles';

const sortByName = sort(ascend(prop('name')));
const sortByLastName = sort(ascend(prop('displayLastName')));


export function DragContainer({
  nameEdit,
  nameRef,
  onDragEnd,
  localPatch,
  schedules,
  busy,
  save,
  patch,
  getHours,
  skedPatch,
  removeWithoutId,
  remove,
  move,
  changeOrder,
  displayRoom,
  parentHeight,
  updatedNumberOfAppointments,
  hasLinkedScheduleFeature
}) {
  const classes = useStyles();

  const divScroll = useRef(null);

  const [, dropRef] = useDrop({
    accept: ItemTypes.TYPE,
    hover: (item, monitor) => {
      if (divScroll.current) {
        const cursor = monitor.getClientOffset();
        const rect = divScroll.current.getBoundingClientRect();
        
        if (cursor.x - rect.left < 100) divScroll.current.scrollLeft -= 4;
        if (rect.right - cursor.x < 100) divScroll.current.scrollLeft += 4;
      }
    },
  });

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [activeDrag, setActiveDrag] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSeletion = (type, index) => {
    if (index !== activeIndex) {
      setSelectedTypes([type]);
      setActiveIndex(index);
      return;
    }
    const typeIndex = selectedTypes.findIndex(item => item.id === type.id);

    if (typeIndex === -1) {
      setSelectedTypes(sortByName()([...selectedTypes, type]));
    } else {
      setSelectedTypes(selectedTypes.filter(item => item.id !== type.id));
    }
    setActiveIndex(index);
  };

  const [firstSked, ...scheduleList] = schedules;

  return (
    <div ref={dropRef} className={classes.root}>
      <ItemsDragLayer />
      {firstSked && (
        <div className={classes.firstContent}>
          <TypeContainer
            sked={firstSked}
            index={0}
            nameEdit={nameEdit}
            nameRef={nameRef}
            onDragEnd={onDragEnd}
            localPatch={localPatch}
            schedules={schedules}
            hasLinkedScheduleFeature={hasLinkedScheduleFeature}
            busy={busy}
            save={save}
            patch={patch}
            getHours={getHours}
            skedPatch={skedPatch}
            removeWithoutId={removeWithoutId}
            remove={remove}
            changeOrder={changeOrder}
            move={move}
            displayRoom={displayRoom}
            parentHeight={parentHeight}
            updatedNumberOfAppointments={updatedNumberOfAppointments}
          >
            {pipe(
              sortByLastName,
              map((pro) => (<ProContainer
                key={pro.id}
                pro={pro}
                sked={firstSked}
                index={0}
                skedPatch={skedPatch}
              >
                {pipe(
                  sortByName,
                  map(type => (
                    <TypeDrag
                      key={type.id}
                      type={type}
                      index={0}
                      selected={!!selectedTypes.find(item => item.id === type.id)}
                      selectedTypes={selectedTypes}
                      handleSeletion={handleSeletion}
                      clearSelection={() => setSelectedTypes([])}
                      activeDrag={activeDrag}
                      setActiveDrag={setActiveDrag}
                      activeIndex={activeIndex}
                    />
                  ))
                )(pro.types)}
              </ProContainer>
              ))
            )(firstSked.professionals)}
          </TypeContainer>
        </div>
      )}
      <div ref={divScroll} className={classes.scrollX} style={{ minHeight: parentHeight - 5 }}>
        {scheduleList.map((sked, index) =>
          <TypeContainer
            key={index + 1}
            sked={sked}
            index={index + 1}
            nameEdit={nameEdit}
            nameRef={nameRef}
            onDragEnd={onDragEnd}
            localPatch={localPatch}
            schedules={schedules}
            hasLinkedScheduleFeature={hasLinkedScheduleFeature}
            busy={busy}
            save={save}
            patch={patch}
            getHours={getHours}
            skedPatch={skedPatch}
            removeWithoutId={removeWithoutId}
            remove={remove}
            move={move}
            changeOrder={changeOrder}
            displayRoom={displayRoom}
            parentHeight={parentHeight}
            updatedNumberOfAppointments={updatedNumberOfAppointments}
          >
            {pipe(
              sortByLastName,
              map((pro) => (<ProContainer
                key={pro.id}
                pro={pro}
                sked={sked}
                index={index + 1}
                skedPatch={skedPatch}
              >
                {pipe(
                  sortByName,
                  map(type => (
                    <TypeDrag
                      key={type.id}
                      type={type}
                      index={index + 1}
                      selected={!!selectedTypes.find(item => item.id === type.id)}
                      selectedTypes={selectedTypes}
                      handleSeletion={handleSeletion}
                      clearSelection={() => setSelectedTypes([])}
                      activeDrag={activeDrag}
                      setActiveDrag={setActiveDrag}
                      activeIndex={activeIndex}
                    />
                  ))
                )(pro.types)}
              </ProContainer>
              ))
            )(sked.professionals)}
          </TypeContainer>)}
      </div>
    </div>
  );
}

export default DragContainer;
