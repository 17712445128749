import * as at from '../../actionTypes';
import { remoteAction } from '../../services/actionService.js';
import api from '../../services/api.js';
import axios from 'axios';
import { loadingMessage } from './routes/MessagesNew/messages-new.actions.js';
import { queryScheduledMessages } from './routes/MessagesThread/messages-thread.actions.jsx';
import * as R from 'ramda';
import { popup } from '../../services/Popup.js';
import { etd, convertEmail, getFeatureForTemplate } from '../../services/utilities.js';
import { now, formatStandard } from '../../services/joda.js';

const sortBy = [{
  field: 'Created',
  direction: 'Desc'
}];

const scheduledSms = {
  NotExists: {
    hasSubpath: 'msg.ot.scheduled_sms',
  },
};

const queryBody = (forFeature) => ({
  query: {
    messageType: 'OneTime',
    isDeleted: false,
    forFeature,
    path: scheduledSms,
  },
  page: 1,
  perPage: 1000,
});

export const morphTime = () => R.pipe(
  R.split('.'),
  R.head,
  R.concat(R.__, 'Z')
)(formatStandard(now('tz'), false));

export const getMessages = (navigate, features) => remoteAction({
  type: at.MESSAGES_REMOTE_GET,
  action: () => Promise.all([
    api.post('message/query', {
      page: 1,
      perPage: 50,
      query: {
        messageType: 'OneTime',
        isDeleted: false,
        wasSent: true,
        sortBy,
        path: scheduledSms,
      }
    }),
    api.post('message/query', {
      page: 1,
      perPage: 50,
      query: {
        messageType: 'OneTime',
        isDeleted: false,
        wasSent: false,
        scheduledAfter: morphTime(),
        isEnabled: true,
        sortBy,
        path: scheduledSms,
      }
    }),
    api.post('message/query', {
      page: 1,
      perPage: 50,
      query: {
        messageType: 'OneTime',
        isEnabled: false,
        isDeleted: false,
        sortBy,
        path: scheduledSms,
      }
    }),
    api.post('message/template/query', queryBody(getFeatureForTemplate(features))),
    api.post('message/query', {
      page: 1,
      perPage: 1000,
      query: {
        messageType: 'OneTime',
        isTemplate: true,
        isDeleted: false,
        sortBy,
        path: scheduledSms,
      }
    }),
  ].map(etd)).then(([
    messages,
    pending,
    drafts,
    templates,
    officeTemplates,
  ]) => {
    return {
      msgs: messages,
      skedTemplates: templates,
      officeTemplates,
      drafts,
      hasApp: R.includes('SkedApp', features),
      pending,
    };
  }).catch((error) => {
    if (error.response) {
      console.error(error);
      popup('Error!', 'Failed to get messages.');
    } else {
      navigate?.('/offline');
    }
  })
});

export const getThreadMessages = (navigate, contactType, perPage = 50) => remoteAction({
  type: at.INBOX_REMOTE_GET,
  action: () => Promise.all([
    api.post('sentmsg/query/conversation', {
      page: 1,
      perPage,
      query: {
        client: {
          isLead: contactType ? (contactType === 'Lead') : undefined,
          leadsAndClients: contactType === '' ? true : undefined,
        }
      }
    }),
    queryScheduledMessages({
      wasSent: false, perPage: 1000,
    }),
    queryScheduledMessages({
      wasSent: true, perPage: 1000,
    }),
  ]).then((data) => {
    return data;
  }).catch((error) => {
    if (error.response) {
      console.error(error);
      popup('Error!', 'Failed to get messages.');
    } else {
      navigate?.('/offline');
    }
  })
});

export const deleteMessage = ({ id, type = 'message' }) => remoteAction({
  type: at.MESSAGES_DELETE_REMOTE_GET,
  action: () => api.delete(`message/${id}`)
    .then(() => {
      if (type === 'message') {
        return Promise.all([
          api.post('message/query', {
            page: 1,
            perPage: 50,
            query: {
              messageType: 'OneTime',
              isDeleted: false,
              wasSent: true,
              sortBy,
            }
          }),
          api.post('message/query', {
            page: 1,
            perPage: 50,
            query: {
              messageType: 'OneTime',
              isDeleted: false,
              isEnabled: true,
              wasSent: false,
              scheduledAfter: morphTime(),
              sortBy,
            }
          }),
          api.post('message/query', {
            page: 1,
            perPage: 50,
            query: {
              messageType: 'OneTime',
              isEnabled: false,
              isDeleted: false,
              sortBy,
            }
          })
        ]).then(([messages, pending, drafts]) => {
          return {
            messages: messages.data,
            messagePage: messages.page,
            totalMessagePages: messages.totalPages,
            totalMessageCount: messages.totalCount,
            drafts: drafts.data,
            pending: pending.data,
          };
        });
      } else {
        return api.post('message/query', {
          page: 1,
          perPage: 1000,
          query: {
            messageType: 'OneTime',
            isTemplate: true,
            sortBy,
          }
        })
          .then((templates) => {
            return {
              type,
              officeTemplates: templates.data,
            };
          });
      }
    }).catch((error) => {
      popup('Error!', 'Failed to delete message.');
      console.error(error);
    }),
});

export const deleteSelected = ({ messages, type = 'message' }) => remoteAction({
  type: at.MESSAGES_DELETE_REMOTE_GET,
  action: () => Promise.all(R.map((message) => {
    return message.selected ?
      api.delete(`message/${message.id}`) : null;
  })(messages))
    .then(() => {
      if (type === 'message') {
        return Promise.all([
          api.post('message/query', {
            page: 1,
            perPage: 50,
            query: {
              messageType: 'OneTime',
              isDeleted: false,
              wasSent: true,
              sortBy,
            }
          }),
          api.post('message/query', {
            page: 1,
            perPage: 50,
            query: {
              messageType: 'OneTime',
              isDeleted: false,
              isEnabled: true,
              wasSent: false,
              scheduledAfter: morphTime(),
              sortBy,
            }
          }),
          api.post('message/query', {
            page: 1,
            perPage: 50,
            query: {
              messageType: 'OneTime',
              isEnabled: false,
              isDeleted: false,
              sortBy,
            }
          })])
          .then(([messages, drafts, pending]) => {
            return {
              messages: messages.data,
              drafts: drafts.data,
              pending: pending.data,
            };
          });
      } else {
        return api.post('message/query', {
          page: 1,
          perPage: 1000,
          query: {
            messageType: 'OneTime',
            isTemplate: true,
            sortBy,
          }
        }).then((templates) => {
          return {
            type,
            officeTemplates: templates.data,
          };
        });
      }
    }).catch((error) => {
      popup('Error!', 'Failed to delete messages.');
      console.error(error);
    }),
});

export const copyMessage = ({ message, tz, wasTemplate = false }) => (dispatch, getStore) => {
  const features = R.path(['login', 'features'], getStore());
  const m = convertEmail(message, features);
  loadingMessage(m, tz).then((msg) => {
    dispatch({
      type: at.NEW_MESSAGE_PATCH,
      data: {
        ...msg,
        templateId: wasTemplate ? message.id : undefined,
      },
    });
    return dispatch({
      type: at.MESSAGES_PATCH,
      data: {
        state: 'COPY',
      }
    });
  });
};

export const readMessage = (message) => (dispatch) => {
  if (message.unread) {
    return api.post(`sentmsg/read/${message.msgId}`, { hasRead: true, dummy: [] }).then(() => {
      dispatch(handleCountReadMessage());
      dispatch({
        type: at.MESSAGE_THREAD_READ_MESSAGE,
        data: message,
      });
    });
  }
};

export const handleCountReadMessage = () => (dispatch, getStore) => {
  return api.get('sentmsg/sms/unread').then((response) => {
    const reponseMessages = response.filter(message => !!message.client.firstName);
    const hasLeads = R.includes('Leads', R.path(['login', 'features'], getStore?.()));
    const unreadMessages = hasLeads ? reponseMessages : reponseMessages.filter(msg => !msg.client?.isLead);
    dispatch({ 
      type: at.MESSAGE_THREAD_PATCH,
      data: {
        unread: unreadMessages.length,
        unreadMessages,
      }
    });

    if (unreadMessages.length < response.length) {
      const removedClientsMsgs = response.filter(message => !message.client.firstName && !message.client.lastName);
  
      if (removedClientsMsgs.length) {
        return Promise.all(R.map((message) => {
          return api.post(`sentmsg/read/${message.msgId}`,
            { hasRead: true, dummy: [] });
        })(removedClientsMsgs));
      }
    }
  });
};

export const readAllMessages = (clientId) => (dispatch) => {
  return api.post(`sentmsg/read/client/${clientId}`).then(() => {
    dispatch(refresh());
    dispatch(handleCountReadMessage());
  });
};

export const readSelected = (
  messages,
  {
    query,
    filter,
    unread,
    page,
    contactType,
    perPage = 50
  },
) => (dispatch) => {
  const isLead = contactType ? (contactType === 'Lead') : undefined;
  const leadsAndClients = contactType === '' ? true : undefined;
  const unreadMessages = R.filter((({ unread, selected }) => unread && selected))(messages);
  return Promise.all(R.map((message) => {
    return api.post(`sentmsg/read/${message.msgId}`,
      { hasRead: true, dummy: [] });
  })(unreadMessages)).then(() => {
    return api.post('sentmsg/query/conversation', {
      page,
      perPage,
      query: {
        unread: unread ? [] : undefined,
        client: {
          [filter]: query,
          isLead,
          leadsAndClients
        }
      }
    }).then(({ data }) => {
      dispatch(handleCountReadMessage());
      dispatch({
        type: at.MESSAGES_READ_REMOTE_GET,
        data,
      });
    });
  });
};

export const unreadSelected = (
  messages,
  {
    query,
    filter,
    unread,
    contactType,
    perPage = 50,
  },
) => (
  dispatch,
) => {
  const selectedUnread = R.filter(({ selected, unread }) => {
    return selected && !unread;
  })(messages);

  return Promise.all(R.map((message) => {
    return api.post(`sentmsg/read/${message.msgId}`,
      { hasRead: false, dummy: [] });
  })(selectedUnread)).then(() => {
    const isLead = contactType ? (contactType === 'Lead') : undefined;
    const leadsAndClients = contactType === '' ? true : undefined;
    return api.post('sentmsg/query/conversation', {
      page: 1,
      perPage,
      query: {
        unread: unread ? [] : undefined,
        client: {
          [filter]: query,
          isLead,
          leadsAndClients
        }
      }
    }).then(({ data }) => {
      dispatch(handleCountReadMessage());
      const updated = R.map(R.prop('msgId'))(selectedUnread);
      dispatch({
        type: at.MESSAGES_READ_REMOTE_GET,
        data: R.map((msg) => R.includes(msg.msgId, updated) ? R.assoc('unread', true, msg) : msg)(data),
      });
    });
  }).catch((error) => {
    console.log(error);
  });
};


export const resetMessages = () => (dispatch) => {
  dispatch({
    type: at.MESSAGES_PATCH,
    data: {
      state: 'LIST',
    }
  });
};

export const messagesPatch = (prop, value) => (dispatch) => {
  if (prop === 'tab') {
    return dispatch({
      type: at.MESSAGES_PATCH,
      data: {
        [prop]: value,
        all: false,
      }
    });
  }
  return dispatch({
    type: at.MESSAGES_PATCH,
    data: {
      [prop]: value,
    }
  });
};

export const selectMessage = ({ type, index, value }) => ({
  type: at.MESSAGES_SELECT_PATCH,
  data: {
    type,
    index,
    value,
  }
});

export const selectAll = ({ type, value }) => (dispatch) =>
  dispatch({
    type: at.MESSAGES_SELECT_ALL_PATCH,
    data: {
      type,
      value,
    }
  });

export const gotoThread = ({ navigate, url, client }) => {
  navigate(url);
  if (client) {
    return {
      type: at.MESSAGE_THREAD_PATCH,
      data: {
        client,
      },
    };
  } else {
    return {
      type: 'nothing'
    };
  }
};

export const refresh = (tab, page) => (dispatch, getStore) => {
  const { messages, login } = getStore();
  const { features } = login;
  const hasLeads = R.includes('Leads', features);
  const { query, inboxPage, contactType, smsTab, perPage } = messages;
  const currentTab = tab || smsTab;

  if (currentTab === 'all') {
    dispatch(messagesPatch('unread', false));
    dispatch(searchInbox({
      query,
      page: page || inboxPage,
      unread: false,
      contactType: hasLeads ? contactType : 'Client',
      perPage
    }));
    return;
  }
  if (currentTab === 'unread') {
    dispatch(messagesPatch('unread', true));
    dispatch(searchInbox({
      query,
      page: page || inboxPage,
      unread: true,
      contactType: hasLeads ? contactType : 'Client',
      perPage
    }));
  }
  if (currentTab === 'archived') {
    dispatch(messagesPatch('archived', true));
    dispatch(searchInbox({
      query,
      page: page || inboxPage,
      archived: true,
      contactType: hasLeads ? contactType : 'Client',
      perPage
    }));
  }
};

export const searchInbox = ({
  page = 1, query, unread = undefined, contactType,
  archived = undefined, perPage = 50, isPagination = false
}) => remoteAction({
  type: at.MESSAGES_REMOTE_GET_SEARCH,
  action: () => Promise.all([
    api.post('sentmsg/query/conversation',
      {
        page,
        perPage,
        query: {
          unread: unread ? [] : undefined,
          archived: archived ? [] : undefined,
          conversation: [],
          messageType: 'ReplyMessage',
          client: {
            ...query,
            isLead: contactType ? (contactType === 'Lead') : undefined,
            leadsAndClients: contactType === '' ? true : undefined,
          }
        }
      }),
    queryScheduledMessages({
      wasSent: false, perPage: 1000,
    }),
    queryScheduledMessages({
      wasSent: true, perPage: 1000,
    }),
  ]).then(([data, scheduled, sentScheduled]) => {
    const messages = archived ? data.data.map(msg => ({ ...msg, archived: true })) : data.data;
    if (!messages.length && isPagination) {
      return {
        allLoaded: true,
        inboxPage: page > 1 ? page - 1 : 1,
        busy: false,
      };
    }
    return {
      inboxPage: page,
      totalInboxPage: data.totalPages,
      totalInboxCount: data.totalCount,
      inbox: R.sortBy(R.prop('createdAt'))(messages),
      allLoaded: messages.length < perPage,
      busy: false,
      scheduled,
      sentScheduled,
    };
  }),
});

const CancelToken = axios.CancelToken;
let cancel;
export const search = ({ type, filter, query, page, features, path, noFolder }) => {
  const searchables = filter === 'subject' ? {
    emailSubject: query,
    pushSubject: query,
  } : {
    [filter]: query
  };
  if (cancel)
    cancel('User aborted request.');
  const cancelObj = {
    cancelToken: new CancelToken((c) => {
      cancel = c;
    })
  };
  let pathQuery = query || R.isNil(path) ? undefined : { Exists: { isDirectDescendant: path } };
  if (noFolder) {
    pathQuery = R.isNil(path) ?
      undefined : { NotExists: { hasSubpath: path } };
  }
  return R.cond([
    [R.equals('template'), () => {
      return remoteAction({
        type: at.MESSAGES_REMOTE_GET_SEARCH,
        action: () => api.post('message/template/query', {
          page,
          perPage: 50,
          query: {
            messageType: 'OneTime',
            isDeleted: false,
            forFeature: getFeatureForTemplate(features),
            ...searchables,
            path: pathQuery,
            sortBy,
          }
        }, cancelObj).then((msgs) => {
          return ({
            templates: R.pipe(
              R.sortBy(R.prop('createdAt')),
              R.reverse
            )(msgs.data),
            templatePage: msgs.page,
            totalTemplatePages: msgs.totalPages,
            totalTemplateCount: msgs.totalCount,
            busy: false,
          });
        }),
      });
    }],
    [R.equals('officeTemplates'), () => {
      return remoteAction({
        type: at.MESSAGES_REMOTE_GET_SEARCH,
        action: () => api.post('message/query', {
          page,
          perPage: 50,
          query: {
            messageType: 'OneTime',
            isTemplate: true,
            isDeleted: false,
            ...searchables,
            path: pathQuery,
            sortBy,
          }
        }, cancelObj).then((msgs) => {
          return ({
            officeTemplates: R.pipe(
              R.sortBy(R.prop('createdAt')),
              R.reverse
            )(msgs.data),
            officeTemplatePage: msgs.page,
            totalOfficeTemplatePages: msgs.totalPages,
            totalOfficeTemplateCount: msgs.totalCount,
            busy: false,
          });
        }),
      });
    }],
    [R.T, () => {
      return remoteAction({
        type: at.MESSAGES_REMOTE_GET_SEARCH,
        action: () => api.post('message/query', {
          page,
          perPage: 50,
          query: {
            messageType: 'OneTime',
            isDeleted: false,
            isEnabled: type !== 'drafts',
            wasSent: type === 'messages',
            scheduledAfter: type === 'pending' ? morphTime() : undefined,
            ...searchables,
            path: pathQuery,
            sortBy,
          }
        }, cancelObj).then((msgs) => {
          return R.cond([
            [R.equals('messages'), R.always({
              messages: R.pipe(
                R.filter((message) => message.messageType.OneTime.wasSent && !message.isDeleted),
                R.map((message) => R.merge(message, message.messageType.OneTime)),
                R.sortBy(R.prop('createdAt')),
                R.reverse
              )(msgs.data),
              messagePage: msgs.page,
              totalMessagePages: msgs.totalPages,
              totalMessageCount: msgs.totalCount,
              busy: false,
            })],
            [R.equals('pending'), R.always({
              pending: R.pipe(
                R.filter((message) =>
                  message.isEnabled && !message.messageType.OneTime.wasSent && !message.isDeleted),
                R.map((message) => R.merge(message, message.messageType.OneTime)),
                R.sortBy(R.prop('createdAt')),
                R.reverse
              )(msgs.data),
              busy: false,
            })],
            [R.equals('drafts'), R.always({
              drafts: R.pipe(
                R.filter((msg) => !msg.isDeleted),
                R.map((message) => R.merge(message, message.messageType.OneTime)),
                R.sortBy(R.prop('createdAt')),
                R.reverse
              )(msgs.data),
              busy: false,
            })],
            [R.T, R.always(null)]
          ])(type);
        }),
      });
    }]
  ])(type);
};

export const archiveSelected = ({ messages, contactType, unread = undefined, archived = undefined, perPage = 50 }) => {
  const selected = R.filter(({
    selected,
  }) => selected)(messages);
  return remoteAction({
    type: at.INBOX_REMOTE_GET,
    action: () => Promise.all(R.map((message) => {
      return api.post(
        `sentmsg/archive/${message.msgId}`,
        { hasArchive: true, dummy: [] });
    })(selected)).then(() => {
      return Promise.all([
        api.post('sentmsg/query/conversation', {
          page: 1,
          perPage,
          query: {
            unread: unread ? [] : undefined,
            archived: archived ? [] : undefined,
            client: {
              isLead: contactType ? (contactType === 'Lead') : undefined,
              leadsAndClients: contactType === '' ? true : undefined,
            }
          }
        }),
        queryScheduledMessages({
          wasSent: false, perPage: 1000,
        }),
        queryScheduledMessages({
          wasSent: true, perPage: 1000,
        }),
      ]);
    }),
  });
};

export const archiveMessage = async (conversationId) => {
  return api.post(`sentmsg/archive/${conversationId}`, { hasArchive: true, dummy: [] });
};

export const unArchiveMessage = async (conversationId) => {
  return api.post(`sentmsg/archive/${conversationId}`, { hasArchive: false, dummy: [] });
};

export const handleReadMessage = async (conversationId, hasRead) => {
  return api.post(`sentmsg/read/${conversationId}`, { hasRead, dummy: [] });
};

export const unArchiveSelected = ({ messages, contactType, perPage = 50 }) => {
  const selected = R.filter(({
    selected,
  }) => selected)(messages);
  return remoteAction({
    type: at.INBOX_REMOTE_GET,
    action: () => Promise.all(R.map((message) => {
      return api.post(
        `sentmsg/archive/${message.msgId}`,
        { hasArchive: false, dummy: [] });
    })(selected)).then(() => {
      return Promise.all([
        api.post('sentmsg/query/conversation', {
          page: 1,
          perPage,
          query: {
            archived: [],
            client: {
              isLead: contactType ? (contactType === 'Lead') : undefined,
              leadsAndClients: contactType === '' ? true : undefined,
            }
          }
        }),
        queryScheduledMessages({
          wasSent: false, perPage: 1000,
        }),
        queryScheduledMessages({
          wasSent: true, perPage: 1000,
        }),
      ]);
    }).then(data => {
      const messages = data.data.map(msg => ({ ...msg, archived: true }));
      return { ...data, data: messages };
    }),
  });
};
