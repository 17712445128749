import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import axios from 'axios';
import {
  getUsers,
} from '../../../Admin/Users/users.service';
import {
  cond, equals, prepend,
} from 'ramda';
import { etd } from '../../../../services/utilities.js';

export const editOffice = (data = {}) => ({
  type: at.BUSINESS_PATCH_OFFICE,
  data
});

export const patch = (data = {}) => ({
  type: at.BUSINESS_PATCH,
  data
});


export const editOfficeInput = (prop, data) => editOffice({ [prop]: data });

export const editOfficeAddress = (data = {}) => ({
  type: at.BUSINESS_PATCH_OFFICE_ADDRESS,
  data
});

export const editOfficeAddressInput = (prop, data) => editOfficeAddress({ [prop]: data });

export const saveForms = ({ id, office, officeAddress, email, admin } = {}) => {
  const officeData = {
    ...office,
    phone: {
      country: officeAddress.country,
      number: office.phone,
    }
  };
  const requests = admin ? [
    api.put(`office/${id}`, officeData),
    api.put(`office/${id}/address`, officeAddress),
    axios.post(
      `${process.env.API_URL}/auth/change/office/${id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('sked-session')).access_token,
          'X-As-Office': undefined,
        }
      })
      .then(({ data }) => axios.put(`${process.env.API_URL}/user`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.access_token,
            'X-As-Office': undefined,
          }
        }
      ))
  ] : [
    api.put(`office/${id}`, officeData),
    api.put(`office/${id}/address`, officeAddress),
    api.put('user', { email })
  ];
  return remoteAction({
    type: at.OFFICE_REMOTE_PUT,
    successText: 'Business Information Saved',
    errorText: 'Failed to save office info.',
    action: () => Promise.all(requests)
      .then(([office, officeAddress]) => ({ office, officeAddress, email }))
      .catch((error) => {
        console.error(error);
        return error;
      })
  });
};

export const localGetUser = () => {
  return api.get('user');
};

export const getUser = (navigate) => (dispatch) =>
  localGetUser()
    .then((user) => {
      return dispatch({
        type: at.BUSINESS_PATCH,
        data: {
          email: user.email,
        }
      });
    })
    .catch((error) => {
      if (error.response) {
        console.error(error);
      } else {
        navigate('/offline');
      }
    });

export const getOffice = (id, navigate) => remoteAction({
  type: at.BUSINESS_REMOTE_GET,
  action: () => api.get(`office/${id}`).then((office) => {
    return api.get(`office/${id}/address`).then((address) => {
      return {
        office,
        address,
      };
    });
  }).catch((error) => {
    if (error.response) {
      console.error(error);
    } else {
      navigate('/offline');
    }
  })
});


export const getLoc = () => {
  return api.get('location');
};

export const getLocations = () => remoteAction({
  type: at.BUSINESS_REMOTE_GET_LOCATIONS,
  action: () => getLoc().catch((error) => {
    if (error.response) {
      console.error(error);
    }
  })
});

export const saveLocation = (idx, country, data) => {
  const locationData = {
    ...data,
    phone: {
      country,
      number: data.phone,
    }
  };
  if (data.id) {
    return remoteAction({
      type: at.BUSINESS_REMOTE_POST_LOCATIONS,
      successText: 'Location Information Saved',
      errorText: 'Failed to save location info.',
      action: () => api.post(`location/${data.id}`, locationData).then((location) => {
        return ({
          idx,
          location,
        });
      }).catch((error) => {
        if (error.response) {
          console.error(error);
        }
      }),
    });
  } else {
    const newLocationData = {
      ...locationData,
      phone: data.phone
    };
    return remoteAction({
      type: at.BUSINESS_REMOTE_POST_LOCATIONS,
      successText: 'Location Information Saved',
      errorText: 'Failed to save location info.',
      action: () => api.put('location', newLocationData).then((location) => {
        return ({
          idx,
          location,
        });
      }).catch((error) => {
        if (error.response) {
          console.error(error);
        }
      }),
    });
  }
};

export const removeLocation = (id, idx) => id === 0 ?
  ({
    type: at.BUSINESS_DELETE_LOCATIONS,
    data: {
      idx,
    },
  })
  :
  remoteAction({
    type: at.BUSINESS_REMOTE_DELETE_LOCATIONS,
    action: () => api.delete(`location/${id}`).then(() => {
      return {
        idx,
      };
    }).catch((error) => {
      if (error.response) {
        console.error(error);
      }
    })
  });

export const defaultAllToLocation = (locationId) => {
  return Promise.all([
    api.get('professional'),
    api.get('officeHours'),
    api.get('specialOfficeHours'),
  ]).then(([pros, hours, specialHours]) => {
    return Promise.all(
      pros.map((pro) => api.put(`professional/${pro.id}`, {
        ...pro,
        locationId: {
          Set: locationId,
        },
      })),
      hours.map((hour) => api.put(`officeHours/${hour.id}`, {
        ...hour,
        locationId,
      })),
      specialHours.map((hour) => api.put(`specialOfficeHours/${hour.id}`, {
        ...hour,
        locationId,
      }))
    );
  });
};

export const logoutUsers = ({ user, value }) => {
  return Promise.all([
    getUsers(true, user.id),
    api.get('office/users/office-admin-user'),
  ].map(etd)).then(([users, officeAdmin]) => {
    const allIds = users.data.map(({ userId }) => userId);
    const userIds = cond([
      [equals('all'), () => prepend(officeAdmin.data.id, allIds)],
      [equals('all-but-admin'), () => allIds],
      [equals('me'), () => [user.id]],
      [equals('not-me'), () => allIds],
    ])(value);
    return api.post('office/users/logout', { userIds });
  });
};
