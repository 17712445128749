import React from 'react';
import * as at from '../../actionTypes';
import {
  map,
  filter,
  includes,
  pipe,
  flatten,
  find,
  propEq,
  prop,
  groupBy,
  has,
  merge,
  concat,
  not,
  tail,
  sort,
  descend,
  prepend,
  head,
  isNil,
  propOr,
  sortBy,
  evolve,
  addIndex,
  omit
} from 'ramda';
import {
  LocalDate,
} from '@js-joda/core';
import { now } from '../../services/joda.js';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import { popup, popupWithCustoms } from '../../services/Popup.js';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';


const filterOldHours = (hours) => {
  const current = pipe(
    filter(
      (hour) =>
        now('date').isAfter(LocalDate.parse(hour.date))
        ||
        now('date').isEqual(LocalDate.parse(hour.date))
    ),
    sort(descend(prop('date'))),
    head
  )(hours);
  const future = pipe(
    filter(
      (hour) =>
        now('date').isBefore(LocalDate.parse(hour.date))),
    sort(descend(prop('date')))
  )(hours);
  return isNil(current) ?
    future :
    prepend(current, future);
};


const buildType = (id, types) => {
  const mType = find(propEq('id', id))(types);
  if (mType) {
    return mType;
  }
  return [];
};


const getTypesApi = (navigate) => api.get('appointmentType').then((allTypes) => {
  return api.get('professional').then((allProfessionals) => {
    return api.get('schedule').then((schedules) => {
      return api.get('officeHours').then((hours) => {
        const officeHours = pipe(
          filter((hour) => (
            now('date').isAfter(LocalDate.parse(hour.start)) ||
            now('date').isEqual(LocalDate.parse(hour.start)))),
          sort(descend(prop('start'))),
          head,
          propOr([], 'hours')
        )(hours);
        const professionals = filter(({ isHidden }) => !isHidden)(allProfessionals);
        const types = filter((type) => {
          const mPro = find(propEq('id', type.professionalId))(allProfessionals);
          if (mPro) {
            return !mPro.isHidden;
          }
          return false;
        })(allTypes);
        const ungroupedScheduledTypes = pipe(
          map((schedule) => schedule.appointmentType),
          flatten,
          map((id) => buildType(id, types)),
          flatten,
        )(schedules);
        const unscheduledTypes = pipe(
          filter((type) => not(includes(type, ungroupedScheduledTypes))),
          groupBy(prop('professionalId'))
        )(types);
        const scheduledTypes = groupBy(prop('professionalId'))(ungroupedScheduledTypes);
        const newScheduleList = pipe(
          map((schedule) => {
            const withPros = pipe(
              map((pro) => {
                if (has(pro.id, scheduledTypes)) {
                  const proTypes = prop(pro.id, scheduledTypes);
                  const types = filter((type) => includes(type.id, schedule.appointmentType))(proTypes);
                  if (types.length === 0)
                    return [];
                  return merge(pro, { types });
                }
                return [];
              }),
              flatten
            )(professionals);
            return merge(schedule, { professionals: withPros });
          }),
          sortBy(prop('order')),
          concat([{
            id: 0,
            name: 'Available Appointment Types',
            professionals: pipe(
              map((pro) => {
                if (has(pro.id, unscheduledTypes)) {
                  const knew = merge(pro, { types: prop(pro.id, unscheduledTypes) });
                  return knew;
                }
                return [];
              }),
              flatten
            )(professionals),
          }])
        )(schedules);
        return {
          schedules: newScheduleList,
          original: newScheduleList,
          professionals,
          officeHours,
          locationsHours: hours
        };
      });
    });
  });
}).catch((error) => {
  if (error.response) {
    console.error(error);
    popup('Error!', 'Unable to retrieve appointment types!');
  } else {
    if (navigate) {
      navigate('/offline');
    }
  }
});

const removeOldHours = pipe(
  filter((hour) => !now('date').isAfter(LocalDate.parse(hour.end))),
  sortBy(
    prop('start'))
);

export const getHours = ({ id, numberOfAppointments, slotLength }, tab = 'hours') => remoteAction({
  type: at.SKEDTYPE_REMOTE_GET_HOURS,
  action: () => Promise.all([
    api.get(`schedule/${id}/hours`),
    api.get(`schedule/${id}/hours/special`)
  ]).then(([hours, special]) => ({
    hours: filterOldHours(hours),
    specialHours: removeOldHours(special),
    numberOfAppointments,
    slotLength,
    id,
    tab,
  })),
});

export const saveHours = (hours, id, deleteHour) => remoteAction({
  type: at.SKEDTYPE_REMOTE_POST_HOURS,
  successText: 'Saved schedule hours!',
  action: () => deleteHour ? Promise.all([
    api.delete(`schedule/${id}/hours`, { data: { date: deleteHour } }),
    api.post('schedule/hours', merge(hours, { scheduleId: id }))
  ]) :
    api.post('schedule/hours', merge(hours, { scheduleId: id })),
});

const savingPopup = (f) => popupWithCustoms({
  title: 'Action Required!',
  customContent: () => {
    return (
      <div>
        You're about to update the max number of appointments per time slot.
        <br />
        This update will apply to each time slot for the current hours.
        <br />
        Continue?
      </div>
    );
  },
  customButtons: (t) => {
    const {
      close,
    } = t;
    return (
      <>
        <HeaderButton
          onClick={() => {
            close();
          }}
          borderSolid
          title='No'
        />
        <HeaderButton
          color='primary'
          onClick={() => {
            close();
            f();
          }}
          title="Yes"
        />
      </>
    );
  },
});

const mapIndexed = addIndex(map);
export const save = (schedules, showPopup) => {
  const savable = tail(schedules);
  const requests = mapIndexed((sked, idx) => {
    const typeIds = pipe(
      map((pro) => pro.types),
      flatten,
      map((type) => type.id)
    )(sked.professionals);
    if (sked.id) {
      return api.put(`schedule/${sked.id}`, {
        id: sked.id,
        name: sked.name,
        order: idx,
        numberOfAppointments: Number(sked.numberOfAppointments),
        slotLength: Number(sked.slotLength) === 0 ? 15 : Number(sked.slotLength),
        appointmentTypes: typeIds,
        room: sked.room === '' ? null : sked.room,
        roundAvailable: sked.roundAvailable
      }).then((schedule) => {
        if ((showPopup - 1) === idx) // since idx === 0 is the list available types
          savingPopup(
            () => api.get(`schedule/${sked.id}/hours`).then((hours) => {
              const hs = filterOldHours(hours);
              const current = head(hs);
              const updatedCurrent = evolve({
                hours: map(
                  map((w) => {
                    return merge(w, {
                      numberOfAppointments: Number(sked.numberOfAppointments),
                    });
                  })),
              }, current);
              api.post('schedule/hours', merge(updatedCurrent, { scheduleId: sked.id }));
            }));
        return schedule;
      });
    } else {
      return api.post('schedule', {
        name: sked.name,
        order: idx,
        numberOfAppointments: Number(sked.numberOfAppointments),
        slotLength: Number(sked.slotLength) === 0 ? 15 : Number(sked.slotLength),
        appointmentTypes: typeIds,
        room: sked.room === '' ? null : sked.room,
        roundAvailable: 'Up'
      });
    }
  })(savable);
  return remoteAction({
    type: at.SKEDTYPE_REMOTE_SAVE_SCHEDULE,
    action: () => Promise.all(requests)
      .then(() => getTypesApi())
      .catch((error) => {
        console.error(error);
      }),
    successText: 'Saved appointment type schedules!',
    errorText: 'Unable to save schedules!'
  });
};


export const getTypes = (navigate) => remoteAction({
  type: at.SKEDTYPE_REMOTE_GET_TYPES,
  action: () => getTypesApi(navigate),
});


export const move = (selecteds, sourceIndex, destIndex) => ({
  type: at.SKEDTYPE_MOVE,
  data: {
    selecteds,
    sourceIndex,
    destIndex,
  }
});


export const add = (name) => ({
  type: at.SKEDTYPE_ADD,
  data: {
    name,
  },
});

export const changeOrder = (index, to) => ({
  type: at.SKEDTYPE_SORT,
  data: {
    index,
    to,
  },
});


export const remove = (id) => remoteAction({
  type: at.SKEDTYPE_REMOTE_DELETE_SKED,
  successText: 'Deleted appointment type schedule!',
  action: () => api.delete(`schedule/${id}`, { data: undefined })
    .then(() => getTypesApi())
});


export const removeWithoutId = (index) => ({
  type: at.SKEDTYPE_REMOVE,
  data: {
    index,
  }
});

export const reset = () => ({
  type: at.SKEDTYPE_RESET,
});


export const skedPatch = (prop, value, index) => ({
  type: at.SKEDTYPE_SKED_PATCH,
  data: {
    prop,
    value,
    index,
  }
});


export const patch = (data) => ({
  type: at.SKEDTYPE_PATCH,
  data,
});

export const getScheduleLinks = scheduleId => {
  return api.get(`schedule/affected?affectedBy=${scheduleId}`);
};

export const saveNewScheduleLinks = (links) => {
  const linksToKeep = links.filter(({ isDeleted }) => !isDeleted);

  return Promise.all(linksToKeep.map(body =>
    api.post('schedule/affected', body)
  ));
};

export const deleteExistingScheduleLinks = (links) => {
  const scheduleLinks = links.map(link => ({
    scheduleId: link.scheduleId,
    affectedScheduleId: link.affectedScheduleId
  }));

  return Promise.all(scheduleLinks.map((body) =>
    api.delete('schedule/affected', { data: body })
  ));
};

export const saveExistingScheduleLinks = (links) => {
  return deleteExistingScheduleLinks(links).then(() => {
    return saveNewScheduleLinks(links);
  });
};

const saveRounding = (s) => api.put(`schedule/${s.id}`, omit(['id', 'appointmentType', 'professionals'], {
  ...s,
  appointmentTypes: s.appointmentType
}));


export const saveScheduleLinks = ({ newLinks, links, schedule, dispatch }) => {
  console.log('save schedule links', newLinks, links);
  return saveRounding(schedule).then(() => {
    return saveNewScheduleLinks(newLinks);
  }).then(() => {
    return saveExistingScheduleLinks(links);
  }).then(() => {
    dispatch({
      type: at.SKEDTYPE_PATCH_SCHEDULE,
      data: schedule
    });
  });
};

