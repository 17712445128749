import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import {
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Autocomplete,
  Button,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import api, { skedApi } from '../../../../services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';

import { checkEmailValidation } from '../../../../services/form-validation';
import { Lead } from '../../leads.reducer';
import * as actions from '../../leads.actions';
import { successSnackbar, errorSnackbar } from '../../../../components/Snackbar/snackbar.actions';
import Modal from '../../../../components/Modal/Modal.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import HeaderTabs from '../../../../components/HeaderTabs/HeaderTabs.component';
import { useSelector } from '../../../../reducers';

import { useStyles } from './addLead.styles';

type Tag = {
  tagId: number;
  tag: string;
}

type AddLeadProps = {
  open: boolean;
  firstName?: string;
  lastName?: string;
  phone?: string;
  setOpen: (open: boolean) => void;
  onSave?: () => void;
  allTags?: Tag[];
}

const init: Lead = { firstName: '', lastName: '', status: 'Active', birthday: '', email: '', phone: '', referrer: '' };

const AddLead = ({ open, setOpen, onSave, firstName = '', lastName = '', phone = '', allTags }: AddLeadProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [leads, setLeads] = useState<Lead[]>([{ ...init, firstName, lastName, phone }]);
  const [emailErrors, setEmailErrors] = useState<boolean[]>([]);
  const [appointmentType, setAppointmentType] = useState<number>();
  const [professionalId, setProfessionalId] = useState<number>();
  const [type, setType] = useState('MANUAL');
  const [tags, setTags] = useState<Tag[]>([]);
  const [csv, setCsv] = useState<File>();
  const [loading, setLoading] = useState(false);

  const {
    calendarTypes,
    referrals,
  } = useSelector(state => ({
    calendarTypes: state.calendar.types,
    referrals: state.leads.referrals,
  }));

  const getTypes = async () => {
    const types = await api.get('appointmentType');
    setAppointmentType(types[0]?.id);
    setProfessionalId(types[0]?.professionalId);
  };

  const handleSaveLeads = (leads: Lead[]) => {
    dispatch(actions.saveLeads(leads, appointmentType || calendarTypes[0]?.id));
  };

  const handleSave = () => {
    handleSaveLeads(leads.filter(lead => lead.firstName && lead.lastName));
    onSave?.();
    setLeads([init]);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    setTimeout(() => {
      const input = document.getElementById(`new-lead-${leads.length}`);
      input.focus();
    }, 250);
  };

  const handleAddLine = () => {
    setLeads([...leads, init]);
    handleFocus();
  };

  const handleRemoveLine = (line: number) => {
    const newLeads = R.remove(line, 1, leads);
    setLeads(newLeads);
  };

  const handleUpdate = (index: number, label: string, value: string, newLine?: boolean) => {
    const newLeads = R.update(index, { ...leads[index], [label]: value }, leads);
    if (newLine && leads.length - 1 === index) {
      setLeads([...newLeads, init]);
      handleFocus();
    } else {
      setLeads([...newLeads]);
    }
  };

  const updateBirthday = (index: number, value: string) => {
    const newLength = R.length(value);
    const originLength = R.length(leads[index].birthday);
    if (newLength < originLength) {
      return handleUpdate(index, 'birthday', value);
    }
    const last = R.takeLast(1, value);
    const lastNumber = Number(last);
    if (newLength > 10 || Object.is(NaN, lastNumber)) {
      return;
    }
    if (newLength === 3 || newLength === 6) {
      const withoutLast = R.dropLast(1, value);
      return handleUpdate(index, 'birthday', `${withoutLast}/${last}`);
    }
    handleUpdate(index, 'birthday', value);
  };

  const upload = async () => {
    const tagIds = tags.map(t => t.tagId.toString());
    const config = {
      headers: {
        /* 'content-type': 'multipart/form-data', */
        'Content-Type': csv.type,
        'Authorization': skedApi.defaults.headers.common.Authorization,
        'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
      },
    };
    const formData = new FormData();
    formData.append('clients', csv);
    try {
      setLoading(true);
      await api.put('/settings/genesis/default/professional', {
        professionalId,
      });
      await api.put('/settings/genesis/defaultAppointmentType', {
        appointmentTypeId: appointmentType || calendarTypes[0]?.id
      });
      const queryTags = tagIds.length ? `?tags=${tagIds.join('%2C')}` : '';
      await axios.post(`${process.env.API_URL}/client/leadcsv${queryTags}`, formData, config);
      setTags([]);
      setCsv(null);
      dispatch(successSnackbar('Leads Created'));
      dispatch(actions.getLeads({ page: 1 }));
      handleCancel();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      dispatch(errorSnackbar('Failed to parse CSV with error: ' + error.response.data));
    } finally {
      setLoading(false);
    }
  };

  const getDisabled = () => {
    if (type === 'MANUAL') {
      return leads[0].firstName.length < 1 || leads[0].lastName.length < 1 || emailErrors.find(error => error);
    }
    return !csv || loading;
  };

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {
    const errors = leads.map((lead) => lead.email.length > 0 && !checkEmailValidation(lead.email));
    setEmailErrors(errors);
  }, [leads]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const input = document.getElementById('new-lead-0');
        input?.focus();
      }, 250);
    }
  }, [open]);

  return (
    <Modal
      size='xs'
      maxWidth={1200}
      open={open}
      onClose={handleCancel}
      title='Add Leads'
      className='sked-test-add-leads-modal'
      buttons={[
        type === 'MANUAL' && 
          <HeaderButton
            borderSolid
            onClick={handleAddLine}
            Icon={AddIcon}
            iconStyle={{ marginRight: -8 }}
            title='Add'
            className='sked-test-add-leads-modal-button-add-line'
          />,
        <HeaderButton
          color='primary'
          disabled={getDisabled() || loading}
          onClick={(type === 'MANUAL' ? handleSave : upload)}
          title="Save"
          className='sked-test-add-leads-modal-button-save'
        />
      ]}
    >
      <div className={classes.content}>
        <Grid display="flex" alignItems="center">
          <Typography className={classes.importLabel}>Import Method: </Typography>
          <HeaderTabs
            value={type}
            setTab={setType}
            tabs={[
              { label: 'Manual', value: 'MANUAL', className: 'sked-test-leads-tab-manual' },
              { label: 'CSV', value: 'CSV', className: 'sked-test-leads-tab-svg' },
            ]}
          />
        </Grid>    
        {type === 'MANUAL' && (
          <>
            <p>
              To make this process as fast as possible, use the "Tab" key to jump from input field to input field. Once you reach the last field (Referral), a new row will appear and you can use the "Tab" key to jump down to it. When entering the birthday, the forward slashes will automatically fill. When adding in a phone number that is from a different country, make sure to add the country code .i.e +1 for the US.
            </p>
            {leads.map((lead, index) => (
              <>
                {index > 0 && <Grid marginTop={1.5} key={index} xs={12}><Divider /></Grid>}
                <Grid className={classes.container} marginTop={1} key={index}>
                  <Grid item sm={2} xs={6} minWidth={170} width="100%" maxWidth={300}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>First name</FormLabel>
                      <TextField
                        id={`new-lead-${index}`}
                        className={classes.input}
                        size='small'
                        placeholder='First name'
                        value={leads[index].firstName}
                        onChange={(event) => handleUpdate(index, 'firstName', event.target.value)}
                        variant='outlined'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2} xs={6} minWidth={170} width="100%" maxWidth={300}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Last name</FormLabel>
                      <TextField
                        variant='outlined'
                        size='small'
                        className={classes.input}
                        value={leads[index].lastName}
                        placeholder='Last name'
                        required
                        onChange={(event) => handleUpdate(index, 'lastName', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2} xs={6} minWidth={170} width="100%" maxWidth={300}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Birthday</FormLabel>
                      <TextField
                        variant='outlined'
                        size='small'
                        className={classes.input}
                        value={leads[index].birthday}
                        placeholder='MM/DD/YYYY'
                        onChange={(event) => updateBirthday(index, event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2} xs={6} minWidth={170} width="100%" maxWidth={300}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Email</FormLabel>
                      <TextField
                        variant='outlined'
                        size='small'
                        className={classes.input}
                        value={leads[index].email}
                        placeholder='Email'
                        type="email"
                        error={emailErrors[index]}
                        onChange={(event) => handleUpdate(index, 'email', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2} xs={6} minWidth={170} width="100%" maxWidth={300}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Phone</FormLabel>
                      <TextField
                        variant='outlined'
                        size='small'
                        className={classes.input}
                        value={leads[index].phone}
                        placeholder='Phone'
                        onChange={(event) => handleUpdate(index, 'phone', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2} xs={6} minWidth={170} width="100%" maxWidth={300}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Grid display="flex" alignItems="center" justifyContent="space-between">
                        <FormLabel className={classes.inputLabel}>Referral</FormLabel>
                        {index !== 0 && <IconButton
                          size="small"
                          title="Remove line"
                          style={{ padding: 3, marginTop: -3 }}
                          onClick={() => handleRemoveLine(index)}>
                          <DeleteIcon style={{ fontSize: 20 }} />
                        </IconButton>}
                      </Grid>
                      <Autocomplete
                        className={classes.select}
                        options={referrals}
                        getOptionLabel={(option) => option}
                        disableClearable
                        clearOnBlur={false}
                        classes={{ option: classes.comboOptions }}
                        value={leads[index].referrer}
                        onChange={(_event, newValue) => {
                          handleUpdate(index, 'referrer', newValue, true);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size='small'
                            className={classes.input}
                            value={leads[index].referrer}
                            placeholder="Referral"
                            onChange={(event) => handleUpdate(index, 'referrer', event.target.value)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ))}
          </>
        )}
        {type === 'CSV' && (
          <div>
            <p>
              In order to easily upload large amounts of leads from an outside source, you can use this CSV file upload. The format of the CSV file must be `firstname,lastname,email,phone,birthday(MM-DD-YYYY)` (i.e `John,Doe,example@sked.life,1234567890,07-15-1994`) and it must NOT contain the column names.
            </p>
            <FormControl className={classes.formTags}>
              <FormLabel className={classes.inputLabel}>Tags</FormLabel>
              <Autocomplete
                multiple
                className={classes.nofitifations}
                classes={{ option: classes.comboOptions }}
                options={allTags}
                getOptionLabel={(option) => option.tag}
                value={allTags.filter((el) => tags.includes(el))}
                onChange={(_event, newValue) => setTags(newValue as Tag[])}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    placeholder='Select tags'
                    size='small'
                    className={classes.input}
                  />
                )}
              />
            </FormControl>
            <Grid display="flex" alignItems="center" marginTop={2.5}>
              <Button
                variant='contained'
                color='primary'
                aria-label='Upload Attachment'
                component='label'
                disabled={loading}
              >
                Choose file
                <input type="file" hidden onChange={(e) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    console.log(e.target.result);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                  setCsv(e.target.files[0]);
                }} />
              </Button>
              <Grid marginLeft="15px" display="flex" alignItems="center">
                {csv && (
                  <>
                    <span>{csv.name}</span>
                    &nbsp;
                    <IconButton
                      title='Remove'
                      size='small'
                      onClick={() => setCsv(null)}
                    >
                      <DeleteIcon style={{ fontSize: 22 }} />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddLead;
