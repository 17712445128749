import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Popover,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

import { calcPositionY, calcTimeFromY } from '../services/sortAppointments';
import { calculateWeekPositions } from '../services/calendar.service.js';
import {
  LocalDate, ZoneId,
  LocalDateTime, ZonedDateTime
} from '@js-joda/core';
import { AppointmentEntry } from './AppointmentEntries.component';
import { BlockEntry } from './BlockEntries.jsx';
import { ReschedulePopUp } from './reschedule-popup.component.jsx';
import { pathOr } from 'ramda';

const convertUTCToZoned = (time, timezone) => {
  return ZonedDateTime
    .parse(time)
    .withZoneSameInstant(ZoneId.of(timezone));
};

const useStyles = makeStyles(() => ({
  calendarContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    overflowY: 'scroll',
    height: 'calc(100% - 60px)',
    userSelect: 'none'
  },
  '@media print': {
    calendarContainer: {
      height: 'auto !important'
    }
  },
  calendarOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
  },
  timeLabelColumn: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #AAA',
    boxSizing: 'border-box',
  },
  timeRows: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '&>div:nth-of-type(odd)': {
      backgroundColor: 'rgba(0,123,207, 0.2)'
    }
  },
  dowLabelContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  dowLabel: {
    paddingLeft: '2px',
    boxSizing: 'border-box',
    borderLeft: '2px solid #AAA'
  },
  dowLabelDate: {
    position: 'sticky',
    left: '1px',
    boxSizing: 'border-box',
    fontSize: 14,
  },
  timeSlot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100px',
    minHeight: '25px',
    height: '25px',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  timeLabelSlot: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflow: 'hidden',
    alignItems: 'center',
    transform: 'translateY(8px)',
  },
  timeRowLabel: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 13,
    textAlign: 'center',
    width: '28px',
    color: '#5d5252',
    lineHeight: 1.1,
  },
  appointment: {
    position: 'absolute',
    backgroundColor: 'red',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
    overflow: 'hidden'
  },
  appointmentStatus: {
    position: 'absolute',
    backgroundColor: 'red',
    bottom: 2,
    right: 2,
    height: 10,
    width: 10,
    borderRadius: 5,
    border: '1px solid'
  },
  appointmentTitle: {
    fontSize: '1.25rem',
    marginLeft: '2px'
  },
  '60min': {
    borderTop: '3px solid #AAA',
    fontWeight: 'bold',
  },
  '30min': {
    borderTop: '2px solid #AAA',
    fontWeight: 'bold'
  },
  '15min': {
    borderTop: '1px solid #AAA'
  },
  '5min': {
    borderTop: '0.5px solid #AAA'
  },
  currentTimeContainer: {
    position: 'absolute',
    margin: 0
  },
  currentTimeCircle: {
    display: 'block',
    margin: 0,
    width: '20px',
    maxWidth: '20px',
    height: '20px',
    maxHeight: '20px',
    borderRadius: '10px',
    backgroundColor: '#FFA000'
  },
  currentTimeLine: {
    position: 'absolute',
    borderTop: '2px solid #FFA000'
  }
}));


const _CalendarInternalWeek = ({
  state,
  store,
  possibleAppointments,
  selectedDate,
  viewAppointment,
  skedAppointment,
  reskedAppointment,
  selectedProfessional,
  selectedLocation,
  markAppointment,
}) => {
  const classes = useStyles();
  const calendarContainer = React.useRef(null);
  const labelRef = React.useRef(null);
  const dowLabelInnerContainer = React.useRef(null);

  const [apt, setApt] = React.useState(null);

  const [positions, setPositions] = React.useState({
    days: [{
      selectedSchedule: { id: -1, name: 'All' },
      sortedAppointments: [],
      startSlot: 0,
      date: '',
      dateString: 'Thurs, Jan 9',
      specialHours: [],
      scheduleHours: [],
      times: [],
      slots: Array.from(Array(10).keys()),
      dayStart: new Date().toISOString(),
      dayEnd: new Date().toISOString()
    }],
    timeLabels: [],
    width: 1,
    height: 1,
    maxSlots: 1
  });

  const [currentTime, setCurrentTime] = React.useState({
    date: new Date().getTime(),
    y: 0,
    width: 0,
    x: 0,
    show: false
  });

  const xPosToDayNum = (x) => {
    let i = 0;
    let dayStart = 0;
    while (x > dayStart) {
      const day = positions.days[i];
      dayStart = (day.startSlot + day.slots.length) * state.cellWidth;
      i = i + 1;
    }
    return i <= 0 ? 0 : i - 1;
  };

  const clickToSked = time => e => {
    e.preventDefault();
    const sl = calendarContainer.current.scrollLeft;
    const bb = calendarContainer.current.getBoundingClientRect();
    const clickX = e.clientX - bb.x + sl;
    const dayNum = xPosToDayNum(clickX);
    const day = positions.days[dayNum];
    const date = day.date;
    const zonedDateTime = ZonedDateTime.of(
      LocalDateTime.parse(`${date}T${time.time.toString()}`),
      ZoneId.of('Europe/Paris')
    );
    skedAppointment({ time: zonedDateTime });
    setTimeout(() => {
      // Hack to wait for the scheduler to open since it'll effect the
      // scroll position
      calendarContainer.current.scrollLeft = day.startSlot * state.cellWidth;
    }, 100);
  };

  const alreadyScrolled = React.useRef(false);

  React.useEffect(() => {
    const time = new Date().getTime();
    const date = LocalDate.now().toString();

    const currentDay = positions.days.find(day => day.date === date);

    let y = 0;
    let x = 0;
    let width = 0;

    if (currentDay) {
      // get day number of current day this week
      y = calcPositionY({
        startOfDay: new Date(currentDay.dayStart).getTime(),
        time,
        interval: store.timeIntervals,
        cellHeight: state.cellHeight
      });

      x = state.cellWidth * currentDay.startSlot;
      width = currentDay.slots.length * state.cellWidth;

      if (!alreadyScrolled.current && y > 1 && !state.loading) {
        calendarContainer.current.scrollLeft = x;
        calendarContainer.current.scrollTop = y;
        alreadyScrolled.current = true;
      }
    }

    setCurrentTime(s => ({
      ...s,
      y,
      x,
      width,
      show: !!currentDay
    }));

    //Only update every 60 seconds for performance reasons
    const timeout = setTimeout(() => {
      setCurrentTime(s => ({
        ...s,
        date: new Date().getTime()
      }));
    }, 60e3);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentTime.date, selectedDate, positions.days]);

  const [height, setHeight] = React.useState(500);

  const calendarHeight = React.useMemo(() => {
    let val = 15;
    if (dowLabelInnerContainer.current && !state.loading) {
      val = dowLabelInnerContainer.current?.clientHeight || 15;
    }
    /* Not sure why 100% wouldn't work here. */
    return `calc(100vh - ${val + (store.headerHeight || 54)}px)`;
  }, [dowLabelInnerContainer, state, store.headerHeight]);

  const updateHeight = () => {
    const bb = calendarContainer.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    setHeight(windowHeight - bb.y);
  };

  const recalculateAppointmentPositions = () => {
    // Optimization timer
    const date = selectedDate;

    const {
      cellHeight,
      cellWidth,
      scheduleId,
      appointments,
      officeHours,
      schedules,
      specialOfficeHours
    } = state;

    setPositions(calculateWeekPositions({
      professionalId: selectedProfessional,
      professionals: store.professionals.filter(({ id }) => id !== 0),
      appointmentTypes: store.proTypes,
      allAppointments: appointments.concat(possibleAppointments),
      timezone: store.tz,
      interval: store.timeIntervals,
      colors: store.colors,
      locationId: selectedLocation,
      cellWidth, cellHeight, scheduleId, officeHours, schedules,
      specialOfficeHours, date
    }));

    setTimeout(() => {
      updateHeight();
    }, 10);
  };

  React.useEffect(() => {
    recalculateAppointmentPositions();
  }, [
    state.appointments, state.timezone, state.officeHours,
    state.schedules, state.interval, state.scheduleId, possibleAppointments,
    state.cellWidth, state.cellHeight, selectedProfessional, selectedLocation]
  );

  React.useEffect(() => {
    updateHeight();
  }, []);

  React.useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    function scrollHandler() {
      const sl = calendarContainer.current.scrollLeft;
      const st = calendarContainer.current.scrollTop;
      labelRef.current.style.marginTop = String((st + state.cellHeight / 2) * -1) + 'px';
      dowLabelInnerContainer.current.scrollLeft = sl;
    }

    calendarContainer.current?.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('resize', handleResize);
      calendarContainer.current?.removeEventListener('scroll', scrollHandler);
    };
  });


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewSelectedApt = () => {
    handleClose();
    viewAppointment(apt);
  };

  const toggleSelectedAptArrival = () => {
    handleClose();
    if (apt.status.Arrived) {
      return markAppointment(apt, 'Scheduled');
    }
    return markAppointment(apt, 'Arrived');
  };

  const toggleSelectedAptMissed = () => {
    handleClose();
    if (apt.status.Missed) {
      return markAppointment(apt, 'Scheduled');
    }
    return markAppointment(apt, 'Missed');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  //Code for clicking and dragging an appointment
  //Could make this touch compatible by listening for touch events too

  const [openReskedPopup, setReskedPopup] = React.useState(false);
  const dragRef = React.useRef(null);
  const [dragApt, setDragApt] = React.useState(null);
  const [dragData, setDragData] = React.useState({
    time: '',
    topStart: 0,
    leftStart: 0
  });

  const handleDragStart = (apt, day) => (event) => {
    setDragApt(apt);

    const startX = event.clientX;
    const startY = event.clientY;

    dragRef.current = document.querySelector(`#apt-${apt.id}`);
    dragRef.current.style.zIndex = 10;

    const scrollStart = calendarContainer.current.scrollTop;
    const scrollXStart = calendarContainer.current.scrollLeft;
    const topStart = dragRef.current.style.top;
    const leftStart = dragRef.current.style.left;

    const getAptPos = (event) => {
      const cx = event.clientX;
      const cy = event.clientY;
      const dx = cx - startX;
      const dy = cy - startY;
      const scroll = calendarContainer.current.scrollTop;
      const scrolld = scroll - scrollStart;
      const scrollX = calendarContainer.current.scrollLeft;
      const scrollXD = scrollX - scrollXStart;
      const x = apt.draw.x + state.cellWidth * day.startSlot + dx + scrollXD;
      const y = apt.draw.y + dy + scrolld;
      return { x, y };
    };

    const dragAptHandler = (event) => {
      if (dragRef.current) {
        const p = getAptPos(event);
        // Skip react updating the DOM for performance reasons
        dragRef.current.style.top = p.y + 'px';
        dragRef.current.style.left = p.x + 'px';
      }
    };

    const finishDragHandler = (event) => {
      const p = getAptPos(event);
      const dayNum = xPosToDayNum(p.x);
      const newDay = positions.days[dayNum];
      const time = calcTimeFromY({
        startOfDay: new Date(newDay.dayStart).getTime(),
        interval: store.timeIntervals,
        cellHeight: state.cellHeight,
        y: p.y
      });

      //If it didn't change the time,
      //then open the context menu
      if (new Date(apt.time).getTime() === new Date(time).getTime()) {
        // Put it back where it was if it moved...
        dragRef.current.style.zIndex = 0;
        dragRef.current.style.left = leftStart;
        dragRef.current.style.top = topStart;

        //Open up menu to view appointment or arrive PM
        setApt(apt);
        setAnchorEl(dragRef.current);
        setDragApt(null);
      } else if (apt.status.Preview) {
        // Dragging a preview appointment
        console.log('calculated time', time);
        const zonedTime = convertUTCToZoned(time, store.tz);
        skedAppointment({ time: zonedTime });
      } else {
        setReskedPopup(true);
        setDragData({
          ...dragData,
          time,
          topStart,
          leftStart
        });
        //Snap appointment to where it should be - doesn't sort it though - that
        //happens after it's saved.
        dragRef.current.style.left = state.cellWidth * newDay.startSlot + 'px';
        dragRef.current.style.top = calcPositionY({
          startOfDay: new Date(newDay.dayStart).getTime(),
          interval: store.timeIntervals,
          cellHeight: state.cellHeight,
          time: new Date(time).getTime()
        }) + 'px';
      }

      calendarContainer.current?.removeEventListener('mouseup', finishDragHandler);
      calendarContainer.current?.removeEventListener('mousemove', dragAptHandler);
    };

    if (apt) {
      // Add listeners
      calendarContainer.current?.addEventListener('mousemove', dragAptHandler);
      calendarContainer.current?.addEventListener('mouseup', finishDragHandler);
    }
  };

  const closeReskedPopup = () => {
    dragRef.current.style.zIndex = 0;
    dragRef.current.style.top = dragData.topStart;
    dragRef.current.style.left = dragData.leftStart;
    setReskedPopup(false);
    setDragApt(null);
    setDragData({});
  };

  const confirmResked = () => {
    dragRef.current.style.zIndex = 0;
    reskedAppointment({
      ...dragApt,
      time: dragData.time
    });
    setDragApt(null);
    setReskedPopup(false);
  };

  return (
    <div className={classes.calendarHolder}>

      {/* Top Date Labels */}
      <div ref={dowLabelInnerContainer} className={classes.dowLabelContainer} style={{ marginLeft: '51px' }}>
        {positions.days.map((p, i) =>
          <div className={classes.dowLabel} style={{ minWidth: state.cellWidth * p.slots.length + 'px' }} key={p.date} id={'date-slot-' + i}>
            <div className={classes.dowLabelDate} style={{ width: state.cellWidth - 4 }}>
              {p.dateString}
            </div>
          </div>
        )}
        <div style={{ minWidth: '15px' }}></div>
      </div>

      <div className={classes.calendarOuterContainer} style={{ height: calendarHeight }}>
        {/* Sticky Left Time Labels */}
        <div
          style={{ maxWidth: '52px', minWidth: '52px', overflowY: 'hidden', height }}
          id={'slot-labels'}>
          <div className={classes.timeLabelColumn} ref={labelRef}>
            {positions.timeLabels.map((time, i) => (
              <div
                className={classes.timeLabelSlot}
                style={{ width: '52px', minHeight: `${state.cellHeight}px`, height: `${state.cellHeight}px` }}
                key={`slot-time-labels-${time.time}`}>
                <div className={classes.timeRowLabel}>
                  {(i !== 0 && (time.interval === '60min' || time.interval === '30min')) ?
                    time.display : <span>&nbsp;</span>}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={classes.calendarContainer} ref={calendarContainer}>
          {/* Time rows that alternate color  */}
          <div className={classes.timeRows} style={{ minHeight: positions.height, minWidth: positions.width }}>
            {positions.timeLabels.map((time) => (
              <div
                onClick={clickToSked(time)}
                className={classes.timeSlot + ' ' + classes[time.interval]}
                style={{ width: '100%', minHeight: `${state.cellHeight}px` }}
                key={`slot-time-labels-${time.time}`}>
              </div>
            ))}
          </div>

          {/* Column bars  */}
          {positions.days.map(day => day.slots.map((s, i) => <div
            className="vertical-line"
            key={`${day.date}-${i}`}
            style={{
              position: 'absolute',
              left: state.cellWidth * (day.startSlot + s),
              marginLeft: s === 0 ? '-2px' : '-1px',
              minWidth: s === 0 ? '2px' : '1px',
              minHeight: positions.height + 'px',
              backgroundColor: s === 0 ? '#777' : '#AAA',
            }}></div>
          ))}

          {/* Special Office Hours */}
          {positions.days.map((p, i) => p.specialHours.map((sh, j) => (
            <BlockEntry
              title="Special hours"
              key={`${i}-${j}-${p.date}${sh.display}`}
              id={`sh-${i}-${j}${p.date}-${sh.display}`}
              x={state.cellWidth * p.startSlot}
              y={sh.y}
              width={(state.cellWidth * p.slots.length - 3) + 'px'}
              height={sh.h}
              name={`Special Hours: ${sh.display}`} />
          )))}

          {/*  Schedule Hours */}
          {positions.days.map((p, i) => p.scheduleHours.map((sh, j) => (
            <BlockEntry
              key={`${i}-${j}${p.date}${sh.display}`}
              id={`sch-${i}-${j}${p.date}-${sh.display}`}
              title="Schedule Hours"
              x={state.cellWidth * p.startSlot}
              y={sh.y}
              width={(state.cellWidth * p.slots.length - 3) + 'px'}
              height={sh.h}
              name={sh.display} />
          )))}

          {positions.days.map(p => p.sortedAppointments.map(a =>
            <AppointmentEntry
              key={a.id}
              a={a}
              startSlot={p.startSlot}
              currentTimestamp={currentTime.date}
              cellWidth={state.cellWidth}
              handleDragStart={handleDragStart(a, p)}
              nameDisplay={state.nameDisplay}
              timezone={store.tz}
            />
          ))}

          {/* Current Time Line */}
          {currentTime.show &&
            <div
              className={classes.currentTimeLine}
              style={{
                top: currentTime.y - 1,
                left: currentTime.x,
                width: currentTime.width
              }}>
            </div>}

          {/* Current Time Circle */}
          {currentTime.show &&
            <p
              className={classes.currentTimeContainer}
              style={{ top: (currentTime.y - 10), left: currentTime.x - 20 }}>
              <span className={classes.currentTimeCircle}></span>
            </p>
          }
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav" aria-label="primary appointment actions">
          <ListItem button onClick={viewSelectedApt}>
            <ListItemText primary="View Appointment" />
          </ListItem>
          <ListItem button onClick={toggleSelectedAptArrival}>
            <ListItemText primary={apt && apt.status.Arrived ? 'Mark as Not Arrived' : 'Mark as Arrived'} />
          </ListItem>
          {pathOr('', ['office', 'ehrSystem'], store) === 'None' &&
            <ListItem button onClick={toggleSelectedAptMissed}>
              <ListItemText primary={
                apt && apt.status.Missed ? 'Mark as Scheduled' : 'Mark as Missed'
              } />
            </ListItem>}
        </List>
      </Popover>

      <ReschedulePopUp
        openReskedPopup={openReskedPopup}
        closeReskedPopup={closeReskedPopup}
        confirmResked={confirmResked}
        dragApt={dragApt}
        dragData={dragData}
        timezone={store.tz} />
    </div>
  );
};

export const CalendarInternalWeek = React.memo(_CalendarInternalWeek);
