import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Checkbox, Radio, RadioGroup, FormControl,
  FormControlLabel, Divider, TextField as RawTextField,
  FormHelperText, Paper, IconButton, Collapse, styled, Tooltip,
  Grid, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, CircularProgress, InputLabel, Input, Select,
  MenuItem, ListItemText,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import HelpButton from '../../../../components/Help/Help.component';
import { PopupTemplate } from '../../../../services/Popup.js';
import * as rawActions from './reviews.actions.js';
import {
  merge, cond, equals, pathOr, isEmpty, includes, isNil, pipe,
  propOr, has, zip, replace,
} from 'ramda';
import TypeSelector from '../../../../components/TypeSelector/type-selector.component';
import { ThreadEditor, Editor as HTMLEditor } from '../../../../components/Editor/editor.component';
import api from '../../../../services/api.js';
import { usePromise } from '../../../../services/promise.hook';
import './review.css';

const oauthSignIn = () => {
  const w = window.open(window.location, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes,popup=yes');
  api.post('office/google/oauth', {}).then(({ token }) => {
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth?';

    const params = {
      'client_id': '564816287254-q2g54summ0p5209354afqlalfuh4733r.apps.googleusercontent.com',
      'redirect_uri': `${process.env.API_URL}/google/callback`,
      'response_type': 'code',
      'scope': 'https://www.googleapis.com/auth/business.manage',
      'state': 'token=' + token,
      'access_type': 'offline'
    };

    let url = oauth2Endpoint;
    for (const p in params) {
      url = url + p + '=' + params[p] + '&';
    }
    w.location = url;
    w.focus();
  });
};

const getGoogleReviewStuff = () => api.get('officeReviewSettings/google/accounts').then((accounts) => {
  return Promise.all(accounts.map(({ id }) => {
    return api.get(`officeReviewSettings/google/${id}/locations`);
  })).then((locations) => {
    return zip(accounts, locations);
  });
});

const generateGoogleLink = ({ isGet = false, accountId, locationId }) => isGet ?
  api.get('/officeReviewSettings/google/review').then((reviews) => {
    const review = reviews.find((r) => r.accountId === accountId && r.locationId === locationId);
    if (review) {
      return review;
    }
    return {};
  })
  :
  api.post('/officeReviewSettings/google/review', {
    accountId,
    locationId,
  });

const GoogleReviewTabs = ({ accounts }) => {
  const [accountId, setAccountId] = React.useState(0);
  const [locations, setLocations] = React.useState([]);
  const [locationId, setLocationId] = React.useState(0);
  const [open, setOpen] = React.useState({
    list: true,
    popup: true,
  });

  const generationState = usePromise(generateGoogleLink, {});

  React.useEffect(() => {
    if (accounts && !isEmpty(accounts)) {
      const acc = accounts[0];
      const accId = pathOr(0, [0, 'id'], acc);
      setAccountId(accId);
      setLocations(acc[1]);
      const locId = pathOr(0, [1, 0, 'id'], acc);
      setLocationId(locId);
      generationState.invoke({ isGet: true, accountId: accId, locationId: locId });
    }
  }, [accounts]);

  const apiUrl = replace(/root-api/, 'api2', process.env.API_URL);
  const srcUrl = replace(/internal/, 'app2', (process.env.APP_URL + '/misc/loader.js'));

  const popupCode = `
<script src="${srcUrl}"
  data-review-token="${generationState.data.token}"
  data-review-api="${apiUrl}"
  data-review-type="popup"></script>
`;

  return (
    <div>
      <FormControl>
        <InputLabel shrink={true}>
          Account
        </InputLabel>
        <Select
          labelId="accounts"
          input={<Input />}
          value={accountId}
          onChange={e => setAccountId(e.target.value)}>
          {accounts.map(([{ id, accountName }]) => (
            <MenuItem key={id} value={id}>
              <ListItemText primary={accountName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl>
        <InputLabel shrink={true}>
          Location
        </InputLabel>
        <Select
          labelId="locations"
          input={<Input />}
          value={locationId}
          onChange={e => setLocationId(e.target.value)}>
          {locations.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      <div style={{
        display: 'flex',
        alignItem: 'center',
      }}>
        {generationState.loading &&
          <CircularProgress />
        }
        {accountId !== 0 && location !== 0 &&
          <Button
            variant='contained'
            disabled={generationState.loading}
            onClick={() => generationState.invoke({
              accountId,
              locationId,
            })}
          >
            {generationState.loading ? 'Loading...' : 'Generate'}
          </Button>}
      </div>
      {!generationState.loading && !isEmpty(generationState.data) &&
        <div>
          <br />
          <br />
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Review List</SectionTitle>
            <ExpandMore
              expand={open.list}
              onClick={() => {
                setOpen({
                  ...open,
                  list: !open.list,
                });
              }}
              aria-expanded={open.list}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.list}>
            <p>
              Place this script tag anywhere you'd like to put the Google review list:
            </p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{`
<script src="${srcUrl}"
    data-review-token="${generationState.data.token}"
    data-review-api="${apiUrl}"
    data-review-type="list"></script>

`}
            </pre>
            <br />
            <p>You can also customize the list by using the <code>::part</code> CSS pseudo-element.</p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{`/*
  You can add css to change the layout
*/
sked-review-list {
  height: 400px;
  width: 50%;
  overflow: scroll;
}
/*
You can change the styling inside the list too.
Use the developer tools of your browser to figure out
which parts are available for styling.
*/
sked-review-list::part(review-name) {
  font-size: large;
}`}
            </pre>
          </Collapse>

          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Review Popup</SectionTitle>
            <ExpandMore
              expand={open.popup}
              onClick={() => {
                setOpen({
                  ...open,
                  popup: !open.popup,
                });
              }}
              aria-expanded={open.popup}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.popup}>
            <p>
              Place this tag in the html body tag on the pages you'd like your the pop up to appear
            </p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{popupCode}
            </pre>
            <br />
            <p>You can also customize the list by using the <code>::part</code> CSS pseudo-element.</p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{`/*
You can change the styling inside the list too.
Use the developer tools of your browser to figure out
which parts are available for styling.
*/
sked-review-popup::part(review-name) {
  color: red;
}`}
            </pre>
          </Collapse>
        </div>}
    </div>
  );
};

export const GoogleIntegrationDialog = ({
  open,
  onClose,
}) => {
  const googleReviewState = usePromise(getGoogleReviewStuff, []);

  React.useEffect(() => {
    if (open) {
      googleReviewState.invoke();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Embeddable Google Reviews
        <HelpButton pageId="google-reviews" pageTitle='EmbeddableGoogleReviewsModal' />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Click the link below to connect your google account with SKED. Then click Generate and add the scripts to your website to display your google reviews.
        </DialogContentText>
        {googleReviewState.loading &&
          <>
            <CircularProgress />
            <br />
          </>}
        <Button
          onClick={oauthSignIn}
          disabled={
            pipe(propOr([], 'data'), has('token'))(googleReviewState)
            ||
            googleReviewState.loading
          }
          startIcon={<LaunchIcon />}
          style={{ marginBottom: '15px' }}
        >
          Enable Google Reviews
        </Button>
        {!googleReviewState.loading && !isEmpty(googleReviewState.data) &&
          <GoogleReviewTabs
            accounts={googleReviewState.data}
          />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ExpandMore = styled((props) => {
  const { ...other } = props;
  delete other.expand;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: '10px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TextField = withStyles({
  root: {
    width: 'auto',
    minWidth: '300px',
  },
})(RawTextField);

const FormHelperTextHtml = withStyles({
  root: {
    marginTop: '-40px',
  },
})(FormHelperText);

const useStyles = makeStyles((theme) => ({
  customWidth: {
    minWidth: '48%',

    [theme.breakpoints.down(1280)]: {
      minWidth: '42%'
    },

    [theme.breakpoints.down(1216)]: {
      minWidth: '40%'
    },
    [theme.breakpoints.down(1180)]: {
      minWidth: '36%'
    },
  }
}));


const mainPage = () => (
  <table width="100%">
    <tr>
      <td width="20%"></td>
      <td>
        <button className="button">
          Yes
        </button>
      </td>
      <td>
        <button className="button no-button">
          No
        </button>
      </td>
      <td width="20%"></td>
    </tr>
  </table>
);
const emailPage = () => (
  <a rel="noopener noreferrer" target="blank">
    <button type="submit">
      Rate Now
    </button>
  </a>
);
const noPage = () => (
  <form method="post" id="feedback-container">
    <div id="rating">
      <span id="star-1" className="star star-empty"></span>
      <span id="star-2" className="star star-empty"></span>
      <span id="star-3" className="star star-empty"></span>
      <span id="star-4" className="star star-empty"></span>
      <span id="star-5" className="star star-empty"></span>
    </div>
    <input value="5" name="rating" id="form-rating" type="hidden" />
    <textarea name="comment" id="feedback" placeholder="help us improve..." disabled></textarea>
    <input disabled="true" id="feedback-submit" type="submit" className="button" />
  </form>
);
const ExamplePage = ({
  header,
  body,
  imageUrl,
  page,
  isHTML = false,
}) => {
  return (
    <div className="settings-email-man-content">
      <img
        className="office-image"
        src={imageUrl} />
      <h1>
        {header}
      </h1>
      {isHTML ?
        <div dangerouslySetInnerHTML={{ __html: body }}>
        </div>
        :
        <p>
          {body}
        </p>}
      {cond([
        [equals('email'), emailPage],
        [equals('main'), mainPage],
        [equals('no'), noPage],
        [equals('end'), () => (<></>)],
      ])(page)}
      <div className="settings-powered-by">
        Powered by:
        <br />
        <img
          src="https://s3.amazonaws.com/sked-image/SKED_Large_Logo_Blue.png"
          alt="sked"
        />
      </div>
    </div>
  );
};

const SectionTitle = ({ children }) => <PageHeader variant="h6">{children}</PageHeader>;

const defaultRestriction = v => typeof v === 'string';

const Reviews = () => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const props = useSelector((state) => ({
    ...state.settingsReviews,
    office: pathOr(null, ['login', 'office'])(state),
    hasCustomReviews: includes('CustomReviews', pathOr([], ['login', 'features'], state)),
    hasGoogle: includes('EmbeddedGoogleReviews', pathOr([], ['login', 'features'], state)),
    ...actions,
    headerHeight: state.login.headerHeight,
  }));
  const [state, setState] = React.useState({
    image: null,
    display: null
  });

  React.useEffect(() => {
    props.initialize();
    return () => {
      props.unmount();
    };
  }, [props.office]);

  const handleInput = (field, restriction = defaultRestriction) => {
    return ((e) => {
      console.log(e);
      return restriction(e.target.value) ? props.editForm(field, e.target.value) : null;
    });
  };

  const handleFile = (e) => {
    e.persist();
    const reader = new FileReader();
    reader.onload = (e) => {
      setState(s => merge(s, { display: e.target.result }));
    };
    reader.readAsDataURL(e.target.files[0]);
    setState(s => merge(s, { image: e.target.files[0] }));
  };

  const classes = useStyles();

  const [open, setOpen] = React.useState({
    apptTypes: true,
    social: true,
    image: true,
    custom: false,
    request: true,
    google: false,
  });

  const {
    facebookUrl,
    forwardSetting,
    googleUrl,
    daysToResend,
    minutesToWait,
    isSaving,
    isEnabled,
    imageUrl,
    types,
    professionals,
    selectedTypes,
    mainPage,
    noPage,
    emailPage,
    smsMsg,
    endPage,
    hasCustomReviews,
  } = props;

  const [emoji, setEmoji] = React.useState(false);

  const defaultMain = rawActions.defaultMainPage(pathOr('our office', ['office', 'name'], props));
  const defaultSubject = rawActions.defaultEmailSubject(pathOr('our office', ['office', 'name'], props));
  const defaultSMS = rawActions.defaultSmsMsg(pathOr('our office', ['office', 'name'], props));

  return (
    <>
      <Header
        title='Review Settings'
        pageId='review-settings'
        rightIcons={[
          <HeaderButton borderSolid>
            <Tooltip
              title="Check the box to enable reviews"
              placement='bottom'
              arrow
            >
              <label>
                <Checkbox
                  checked={isEnabled}
                  style={{ paddingLeft: 0, paddingTop: 4, paddingBottom: 4 }}
                  onChange={(e) => props.editForm('isEnabled', e.target.checked)} />
                Enabled
              </label>
            </Tooltip>
          </HeaderButton>,
          <HeaderButton
            title='Save'
            color='primary'
            onClick={() => dispatch(props.save(state.image))}
            disabled={isSaving || (isEnabled && (forwardSetting === 'Google' ? isEmpty(googleUrl) : isEmpty(facebookUrl)))}
          />
        ]}
      />
      <div
        style={{
          padding: '20px',
          paddingBottom: '30px',
          overflowY: 'auto',
        }}>
        <PopupTemplate />
        <Paper style={{ padding: '20px', marginBottom: '20px' }}>
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Appointment Types</SectionTitle>
            <ExpandMore
              expand={open.apptTypes}
              onClick={() => {
                setOpen({
                  ...open,
                  apptTypes: !open.apptTypes,
                });
              }}
              aria-expanded={open.apptTypes}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.apptTypes}>
            <div>
              <i>Select which appointment types you'd like review notifications to be sent after.</i>
            </div>
            <div style={{ marginLeft: '-10px' }}>
              <TypeSelector
                types={types}
                professionals={professionals}
                selected={isNil(selectedTypes) ? null : selectedTypes}
                onChange={(ts) => {
                  props.editForm('selectedTypes', ts);
                  if (ts.length === types.length) {
                    props.editForm('some', 'All');
                  } else {
                    props.editForm('some', 'Some');
                  }
                }}
              />
            </div>
          </Collapse>
          <Divider style={{ margin: '20px 0' }} />
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Social Accounts</SectionTitle>
            <ExpandMore
              expand={open.social}
              onClick={() => {
                setOpen({
                  ...open,
                  social: !open.social,
                });
              }}
              aria-expanded={open.social}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.social}>
            <div>
              <i>Set up your account for receiving reviews from your clients.</i>
            </div>
            <FormControl component="fieldset">
              <RadioGroup
                value={forwardSetting}
                onChange={handleInput('forwardSetting')}>
                <FormControlLabel
                  value="Google"
                  control={<Radio />}
                  label="Google" />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other" />
              </RadioGroup>
            </FormControl>
            <br />
            {cond([
              [equals('Google'), () => (
                <div>
                  <TextField
                    value={isNil(googleUrl) ? '' : googleUrl}
                    onChange={handleInput('googleUrl')}
                    placeholder="https://search.google.com/local/writereview?placeid=MyPlaceId"
                    label="Google Reviews Url" />
                  <FormHelperText>
                    Click <a target="_blank" rel="noopener noreferrer" href="https://skedlife.zendesk.com/hc/en-us/articles/24615536706452-Google-Review-Link">
                      here
                    </a> to learn about how to get a Google link.
                  </FormHelperText>
                </div>)],
              [equals('Other'), () => (
                <div>
                  <TextField
                    value={isNil(facebookUrl) ? '' : facebookUrl}
                    onChange={handleInput('facebookUrl')}
                    placeholder="https://facebook.com/MyFacebookPage"
                    label="Other Page Url" />
                  <FormHelperText>
                    Click <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/help/434605260012677">here</a> to learn about how to get a Facebook link.
                    <br />
                    This can also be any other review service such as Yelp!
                  </FormHelperText>
                </div>)]
            ])(forwardSetting)}
          </Collapse>
          <Divider style={{ margin: '20px 0' }} />
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Office Image</SectionTitle>
            <ExpandMore
              expand={open.image}
              onClick={() => {
                setOpen({
                  ...open,
                  image: !open.image,
                });
              }}
              aria-expanded={open.image}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.image}>
            <div>
              <i style={{ marginTop: '-10px' }}>Upload your office logo.</i>
            </div>
            <Grid display="flex" alignItems="center">
              <Button
                variant='contained'
                color='primary'
                aria-label='Upload Attachment'
                component='label'
              >
                Choose file
                <input type="file" hidden onChange={handleFile} />
              </Button>
            </Grid>
            <br />
            <br />
            {state.display &&
              <div>
                Image to Upload:
                <br />
                <img width='400' src={state.display} />
              </div>}
            {imageUrl &&
              <div>
                Current Image:
                <br />
                <img width='400' src={imageUrl} />
              </div>}
          </Collapse>
          <Divider style={{ margin: '20px 0' }} />
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>When should the request send?</SectionTitle>

            <ExpandMore
              expand={open.request}
              onClick={() => {
                setOpen({
                  ...open,
                  request: !open.request,
                });
              }}
              aria-expanded={open.request}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.request}>
            <TextField
              style={{ width: '100px' }}
              type='number'
              value={minutesToWait}
              onChange={handleInput('minutesToWait', (val) => val > -1 && val < 171)}
              placeholder="15"
              label="Minutes to Wait"
            />
            <FormHelperText>
              Enter the number of minutes after an appointment has been arrived for the review request to be sent. Max limit is 170 minutes.
            </FormHelperText>
            <TextField
              style={{ width: '100px', marginTop: '20px' }}
              type='number'
              value={daysToResend}
              onChange={handleInput('daysToResend', (val) => val > 0)}
              placeholder="5"
              label="Days to resend"
            />
            <FormHelperText>
              Select the number of days before allowing another review request to be sent.
            </FormHelperText>
          </Collapse>
          {hasCustomReviews &&
            <>
              <Divider style={{ margin: '20px 0' }} />
              <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}>
                <SectionTitle>Customize Review Messages</SectionTitle>
                <ExpandMore
                  expand={open.custom}
                  onClick={() => {
                    setOpen({
                      ...open,
                      custom: !open.custom,
                    });
                  }}
                  aria-expanded={open.custom}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>
              <Collapse in={open.custom}>
                <SectionTitle>Custom Email Page</SectionTitle>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                  <div>
                    <div>
                      <ThreadEditor
                        keyId={0}
                        body={emailPage.subject}
                        messagesPatch={(b) => props.editForm('emailPage', merge(emailPage, { subject: b }))}
                        patch={(key, value) => setEmoji(value && 'emailPage.subject')}
                        emoji={emoji === 'emailPage.subject'}
                        label="Subject"
                        placeholder={defaultSubject}
                        defaultBody={defaultSubject}
                        emojiDirection='up-reviews'
                        nocharacter
                      />
                    </div>
                    <FormHelperText>
                      The subject for the email.
                    </FormHelperText>
                    <br />
                    <div>
                      <ThreadEditor
                        keyId={1}
                        body={emailPage.header}
                        messagesPatch={(b) => props.editForm('emailPage', merge(emailPage, { header: b }))}
                        patch={(key, value) => setEmoji(value && 'emailPage.header')}
                        emoji={emoji === 'emailPage.header'}
                        placeholder={rawActions.defaultEmailHeader}
                        defaultBody={rawActions.defaultEmailHeader}
                        label="Header"
                        emojiDirection='up-reviews'
                        nocharacter
                      />
                    </div>
                    <FormHelperText>
                      First sentence that shows in the email message.
                    </FormHelperText>
                    <br />
                    <div>
                      <HTMLEditor
                        keyId={2}
                        html={emailPage.body}
                        messagesPatch={(prop, b) => props.editForm('emailPage', merge(emailPage, { body: b }))}
                        patch={(key, value) => setEmoji(value && 'emailPage.body')}
                        emoji={emoji === 'emailPage.body'}
                        placeholder={rawActions.defaultEmailBody}
                        defaultBody={rawActions.defaultEmailBody}
                        label="Body"
                        emojiDirection='up-reviews'
                        isHTML
                        hideFrameToggle
                        hideErrors
                      />
                    </div>
                    <FormHelperTextHtml>
                      Second sentence in the email message under the header.
                    </FormHelperTextHtml>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    Example Email:
                    <br />
                    <ExamplePage
                      page='email'
                      header={emailPage.header || rawActions.defaultEmailHeader}
                      body={emailPage.body || rawActions.defaultEmailBody}
                      imageUrl={imageUrl}
                      isHTML
                    />
                  </div>
                </div>
                <Divider style={{ margin: '20px 0' }} />
                <SectionTitle>Custom SMS Text</SectionTitle>
                <div>
                  <ThreadEditor
                    keyId={3}
                    body={smsMsg}
                    messagesPatch={(b) => handleInput('smsMsg')({ target: { value: b } })}
                    patch={(key, value) => setEmoji(value && 'smsMsg')}
                    emoji={emoji === 'smsMsg'}
                    label="SMS Body"
                    placeholder={defaultSMS}
                    defaultBody={defaultSMS}
                    emojiDirection='up-reviews'
                    baseCharacterCount={52} /* "https://r.sked.life/8d7f0f36f6d65291ee38bd7c61fea050".length => 52 */
                  />
                </div>
                <FormHelperText>
                  This is the body of the SMS review message. The review link will use up 52 characters.
                </FormHelperText>
                <Divider style={{ margin: '20px 0' }} />
                <SectionTitle>Custom Main Page</SectionTitle>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                  <div className={classes.customWidth}>
                    <div>
                      <ThreadEditor
                        keyId={4}
                        body={mainPage}
                        messagesPatch={(b) => handleInput('mainPage')({ target: { value: b } })}
                        patch={(key, value) => setEmoji(value && 'mainPage')}
                        emoji={emoji === 'mainPage'}
                        label="Main Question"
                        placeholder={defaultMain}
                        defaultBody={defaultMain}
                        emojiDirection='up-reviews'
                        nocharacter
                      />
                    </div>
                    <FormHelperText>
                      This should be a 'yes' or 'no' style question.
                    </FormHelperText>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    Example Main Page:
                    <br />
                    <ExamplePage
                      page='main'
                      header={mainPage || defaultMain}
                      imageUrl={imageUrl}
                    />
                    {/* <img */}
                    {/*   width='400px' */}
                    {/*   src="/samples/main-page.png" /> */}
                  </div>
                </div>
                <Divider style={{ margin: '20px 0' }} />
                <SectionTitle>Custom "No" Response Page</SectionTitle>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                  <div>
                    <div>
                      <ThreadEditor
                        keyId={5}
                        body={noPage.header}
                        messagesPatch={(b) => props.editForm('noPage', merge(noPage, { header: b }))}
                        patch={(key, value) => setEmoji(value && 'noPage.header')}
                        emoji={emoji === 'noPage.header'}
                        label="Header"
                        placeholder={rawActions.defaultNoHeader}
                        defaultBody={rawActions.defaultNoHeader}
                        emojiDirection='up-reviews'
                        nocharacter
                      />
                    </div>
                    <FormHelperText>
                      This is the first sentence that will be displayed when a patient selects that they did not have a good experience.
                    </FormHelperText>
                    <br />
                    <div>
                      <HTMLEditor
                        keyId={6}
                        html={noPage.body}
                        messagesPatch={(prop, b) => props.editForm('noPage', merge(noPage, { body: b }))}
                        patch={(key, value) => setEmoji(value && 'noPage.body')}
                        emoji={emoji === 'noPage.body'}
                        label="Body"
                        placeholder={rawActions.defaultNoBody}
                        defaultBody={rawActions.defaultNoBody}
                        emojiDirection='up-reviews'
                        isHTML
                        hideFrameToggle
                        hideErrors
                      />
                    </div>
                    <FormHelperTextHtml>
                      This is the secondary sentence that will be displayed beneath the header.
                    </FormHelperTextHtml>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    Example "No" Page:
                    <br />
                    <ExamplePage
                      page='no'
                      header={noPage.header || rawActions.defaultNoHeader}
                      body={noPage.body || rawActions.defaultNoBody}
                      imageUrl={imageUrl}
                      isHTML
                    />
                    {/* <img */}
                    {/*   width='400px' */}
                    {/*   src="/samples/no-page.png" /> */}
                  </div>
                </div>
                <Divider style={{ margin: '20px 0' }} />
                <SectionTitle>Custom End Page</SectionTitle>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                  <div>
                    <div>
                      <ThreadEditor
                        keyId={7}
                        body={endPage.header}
                        messagesPatch={(b) => props.editForm('endPage', merge(endPage, { header: b }))}
                        patch={(key, value) => setEmoji(value && 'endPage.header')}
                        emoji={emoji === 'endPage.header'}
                        label="Header"
                        placeholder={rawActions.defaultEndHeader}
                        defaultBody={rawActions.defaultEndHeader}
                        emojiDirection='up-reviews'
                        nocharacter
                      />
                    </div>
                    <FormHelperText>
                      This is the first sentence that will be displayed after a patient gives their negative feedback.
                    </FormHelperText>
                    <br />
                    <div>
                      <HTMLEditor
                        keyId={8}
                        html={endPage.body}
                        messagesPatch={(prop, b) => props.editForm('endPage', merge(endPage, { body: b }))}
                        patch={(key, value) => setEmoji(value && 'endPage.body')}
                        emoji={emoji === 'endPage.body'}
                        label="Body"
                        placeholder={rawActions.defaultEndBody}
                        defaultBody={rawActions.defaultEndBody}
                        emojiDirection='up-reviews'
                        isHTML
                        hideFrameToggle
                        hideErrors
                      />
                    </div>
                    <FormHelperTextHtml>
                      This is the secondary sentence that will be displayed beneath the header.
                    </FormHelperTextHtml>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    Example End Page:
                    <br />
                    <ExamplePage
                      page='end'
                      header={endPage.header || rawActions.defaultEndHeader}
                      body={endPage.body || rawActions.defaultEndBody}
                      imageUrl={imageUrl}
                      isHTML
                    />
                    {/* <img */}
                    {/*   width='400px' */}
                    {/*   src="/samples/end-page.png" /> */}
                  </div>
                </div>
              </Collapse>
            </>}
        </Paper>
      </div>
    </>
  );
};

export default Reviews;
