import React, {
  useEffect, useState, useRef, useMemo
} from 'react';
import {
  TableContainer as MuiTableContainer, Table, TableContainerProps,
} from '@mui/material';
import { isNil } from 'ramda';
import { useSelector } from '../../reducers';
import { useStyles } from './tableContainer.styles';
import Pagination, { PaginationData } from '../Pagination/Pagination.component';
import { FOLDERS_WIDTH } from '../SidebarFolders/SidebarFolders.component';

interface Props extends TableContainerProps {
  noHover?: boolean;
  maxHeight?: number | string;
  checkHasScroll?: boolean;
  pagination?: boolean;
  paginationData?: PaginationData;
  scrollPaddingBottom?: number;
  hasFolders?: boolean;
}

export const TableContainer = ({
  children, noHover, maxHeight, className, checkHasScroll, hasFolders,
  pagination, paginationData = {} as PaginationData, scrollPaddingBottom = 0,
  ...props
}: Props) => {
  const classes = useStyles();
  const [tableWidth, setTableWidth] = useState(900);
  const [hasScroll, setHasScroll] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const { desktopOpen, isMobile, openedFolder } = useSelector(state => ({
    desktopOpen: state.login.desktopOpen,
    isMobile: state.login.isMobile,
    openedFolder: state.login.openedFolder,
  }));

  const handleWidth = () => {
    if (!hasFolders) {
      return;
    }
    const blueNavDiff = desktopOpen ? 240 : 0;
    const foldersNavDiff = (!isMobile && openedFolder) ? FOLDERS_WIDTH : 0;
    const size = window.innerWidth - blueNavDiff - foldersNavDiff;
    setTableWidth(window.innerWidth < 600 ? window.innerWidth : size);
  };

  const handleScroll = () => {
    setHasScroll(boxRef.current.scrollWidth > boxRef.current.clientWidth);
  };

  useEffect(() => {
    handleWidth();
  }, [desktopOpen, hasFolders, isMobile, openedFolder]);

  useEffect(() => {
    handleScroll();
  }, [boxRef.current?.scrollWidth, boxRef.current?.clientWidth, checkHasScroll]);

  useEffect(() => {
    function handleResize() {
      handleWidth();
    }

    if (hasFolders) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (hasFolders) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [desktopOpen, hasFolders, isMobile, openedFolder]);

  /* adjust the height of the table to stop at the pagination bar */
  const actualMaxHeight = useMemo(() => {
    if (pagination) {
      if (isNil(maxHeight)) {
        return 'calc(100% - 38px)';
      }
      if (typeof maxHeight === 'string') {
        return `calc(${maxHeight} - 38px)`;
      }
      return maxHeight - 40;
    }
    return maxHeight;
  }, [maxHeight, pagination]);

  return (
    <MuiTableContainer
      className={`${classes.root} ${className} ${noHover ? '' : classes.hover}`}
      {...props}
    >
      <div
        className={classes.scrollableContainer}
        style={{
          maxWidth: hasFolders ? tableWidth : '100%',
          maxHeight: actualMaxHeight,
          paddingBottom: scrollPaddingBottom,
        }}
        ref={boxRef}
      >
        <Table
          size="small"
          className={
            `${classes.table} ${hasScroll ? classes.border : ''} ${maxHeight ? classes.shadow : ''}`
          }>
          {children}
        </Table>
      </div>
      {pagination && <Pagination {...paginationData} />}
    </MuiTableContainer>
  );
};

export default TableContainer;
