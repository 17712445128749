import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import CopyIcon from '@mui/icons-material/FileCopy';
import { getInstaller, getEmail } from './installer.actions.jsx';
import { Card, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { infoSnackbar } from '../../../../components/Snackbar/snackbar.actions';
import { useSelector } from '../../../../reducers';

const Container = withStyles({
  root: {
    margin: 20
  },
})(Grid);

const Panel = withStyles({
  root: {
    padding: 15,

    '& h2': {
      fontSize: 16
    },
  },
})(Card);

const InstallerTemplate = ({
  url,
  email,
  admin,
}) => {
  const dispatch = useDispatch();

  const copy = () => {
    const copyText = document.getElementById('link');
    copyText.select();
    document.execCommand('copy');
    dispatch(infoSnackbar('Copied to clipboard'));
  };
  
  return (
    <>
      <Header title='Install SKED' />
      <Container>
        {!admin && <Navigate to='/dashboard' />}
        <Panel>
          <h2>Install SKED</h2>
          <br />
          <div>
          To get started with SKED, please follow the link in your welcome email to schedule an Installation/ Set up Appointment with SKED Support. Or contact SKED support directly at
            <a href={'mailto:info@sked.lilfe'}> info@sked.life.</a>
            <br />
            <br />
            <b>*When prompted for an email and password, use your login email ({email}) and password.</b>
            <br />
            <br />
            <b>**If you have heavily armored anti-virus software or firewalls, you may see a warning as your SKED Installer begins to download and install. Please click through them and allow the installer to download and follow the prompts through the installer.</b>
          </div>
          <br />
          <br />
          {/* <a href={url}>Download here!</a> */}
          <input id='link' value={url} style={{ width: '60%', marginRight: 5 }} />
          <button onClick={() => copy()} style={{ cursor: 'pointer' }}>
            <CopyIcon style={{ fontSize: 14 }} />
          </button>
        </Panel>
      </Container>
    </>
  );
};

const Installer = () => {
  const dispatch = useDispatch();

  const props = useSelector(s => ({
    url: s.installer.installer.url,
    email: s.installer.email,
    admin: s.login.admin,
  }));

  const getInit = () => {
    dispatch(getInstaller());
    dispatch(getEmail());
  };

  useEffect(() => {
    getInit();
  }, []);
  
  return (InstallerTemplate(props));
};

export default Installer;
