import React, { useMemo, useState } from 'react';
import * as R from 'ramda';
import {
  tzParse, tzParseFormat, ordinal
} from '../../../../services/joda';
import { MmsItem, MmsAttachment, AvailableFiles, Urls } from '../../routes/MessagesThread/message-chat.page';
import { Spacer } from '../../../../components/PageHeader';
import { Message } from '../../message-types';
import { useStyles } from './chatItem.styles';
import {
  Paper, Tooltip, Button, Chip,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import AroundTheClockIcon from '../../../../icons/AroundTheClock.icon';
import InboxTextBox, {
  SubmitType
} from '../InboxChat/InboxTextBox.component';

type ChatItemProps ={
  message: Message;
  tz: string;
  phis: Urls[];
  handleSubmit?: (st: SubmitType) => void;
}

const ChatItem = ({
  message, tz, phis, handleSubmit,
}: ChatItemProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [scheduledFor, setScheduledFor] = useState(message.created);
  const [warningAnchor, setWarningAnchor] = useState<boolean>(false);
  const toOffice = R.pathOr(false, ['to', 'ToOffice'])(message);
  const body = R.pathOr('', ['smsData', 'body'], message);
  const attachments = R.pathOr([], ['smsData', 'attachments'], message);

  React.useEffect(() => {
    setScheduledFor(message.created);
  }, [message]);

  const mmsAttachments: MmsAttachment[] = React.useMemo(() => {
    const msgPhi = R.pathOr([], ['smsData', 'phi'], message);
    return msgPhi.map((mp) => {
      const ms = phis.find((p) => p.phiId === mp.phiId);
      const f = mp.availableFiles.find(({ phiId }: AvailableFiles) => {
        return mp.phiId === phiId;
      });
      /* decode twice because sometimes the file names have things like %2520 in
         them and a single decodeURI won't do it all.
      */
      const fileName = f ? decodeURI(decodeURI(R.last(f.path.split('/'))))
        : decodeURI(mp.fileName);
      if (ms) {
        return {
          mms: ms,
          fileName,
        };
      }
      return {
        mms: null,
        fileName,
      };
    }).filter((p: MmsAttachment) => p.mms) || [];
  }, [message, phis]);

  const onlyOneEmoji = (str: string) => {
    // eslint-disable-next-line no-control-regex
    const hasUnicode = /[^\u0000-\u00ff]/.test(str);
    return hasUnicode && str.length === 2;
  };

  const ShowStatus = ({ status }: { status: string }) => {
    if (status === 'Pending' || status === 'Sent') {
      return <MoreHorizIcon fontSize="small" />;
    }
    if (status === 'Failed') {
      return <ErrorIcon color="warning" fontSize="small" />;
    }
    return null;
  };

  const isScheduled = useMemo(() => {
    return message.smsStatus === 'Scheduled' && scheduledFor;
  }, [message, scheduledFor]);

  const [created, raw] = useMemo(() => {
    const hasZ = scheduledFor.includes('Z');
    const date = hasZ ? scheduledFor : scheduledFor + 'Z';
    if (date && tz) {
      if (isScheduled && scheduledFor) {
        const day = tzParse(date, tz);
        const ord = ordinal(day.dayOfMonth());
        const raws = tzParseFormat(date, tz, 'yyyy-MM-dd\'T\'HH:mm');
        return [tzParseFormat(date, tz, `EE, MMM  d'${ord}', h:mm`) +
          tzParseFormat(date, tz, ' a').toLowerCase(),
        raws];
      }
      return [tzParseFormat(date, tz, 'h:mm a')?.toLowerCase(), ''];
    }
    return ['', ''];
  }, [scheduledFor, tz, isScheduled]);

  const fill = useMemo(() => {
    if (toOffice) {
      return '#f0f0f0';
    }
    if (isScheduled) {
      return '#D1D8DF';
    }
    return 'rgb(0, 170, 255)';
  }, [isScheduled, toOffice]);

  const background = useMemo(() => {
    if (isScheduled && edit) {
      return 'linear-gradient(45deg, rgb(250,250,250) 23%, rgba(241,241,244,1) 100%)';
    }
    if (toOffice || isScheduled) {
      return 'linear-gradient(45deg, rgba(238,238,238,1) 23%, rgba(241,241,244,1) 100%)';
    }
    return 'linear-gradient(90deg, rgb(0, 139, 209) 0%, rgb(0, 153, 230) 72%, rgb(0, 170, 255) 100%)';
  }, [toOffice, isScheduled, edit]);

  const editMessage = () => {
    setEdit(true);
  };

  const submit = (st: SubmitType) => {
    setEdit(false);
    if (st.scheduledFor) {
      setScheduledFor(st.scheduledFor + ':00');
    }
    handleSubmit({
      ...st,
      msgId: message.msgId,
    });
  };

  if (!body && attachments.length === 0 && mmsAttachments.length === 0) {
    return null;
  }

  return (
    <div className={classes.chatItemRoot + ' ' + `msg-${message.msgId}`}>
      <div className={classes.chatItemContainer}>
        {!toOffice && !edit && <Spacer />}
        <div
          className={classes.messageContainer}
          style={{
            alignItems: toOffice ? 'flex-start' : 'flex-end',
            flexGrow: edit ? 1 : 0,
          }}>
          <Paper
            key={message.msgId}
            elevation={0}
            style={{
              padding: '8px 17px',
              fontSize: 14,
              color: toOffice || isScheduled ? 'black' : 'white',
              background,
              maxWidth: edit ? 'unset' : 'max-content',
              width: edit ? 'calc(100% - 30px)' : 'unset',
              position: 'relative',
              borderRadius: 10,
              border: isScheduled ? 'solid 1px #D1D8DF' : undefined,
            }}
          >
            <div
              className={classes.messageContent}
              style={{
                fontSize: onlyOneEmoji(body) ? '32px' : 'auto',
              }}>
              {edit ?
                <InboxTextBox
                  handleSubmit={submit}
                  client={message?.client}
                  fullPage={false}
                  formRef={null}
                  body={body}
                  attachs={attachments}
                  scheduled={(isScheduled && raw) || ''}
                  mId={message.msgId}
                  onCancel={() => setEdit(false)}
                  state={'VIEW'}
                  pageWidth={null}
                  warningAnchorState={[warningAnchor, setWarningAnchor]}
                /> :
                body}
            </div>
            {!R.isEmpty(attachments) &&
              attachments.map(({ attachmentName, attachmentUrl }) => (
                <>
                  <a
                    style={{
                      color: toOffice || isScheduled ? 'unset' : 'white',
                      textDecoration: 'underline',
                    }}
                    href={attachmentUrl}
                    target='_blank'>
                    {attachmentName}
                  </a>
                  <br />
                </>
              ))}
            {mmsAttachments.map(({ mms, fileName }: MmsAttachment) => (
              <MmsItem mms={mms} fileName={fileName} />
            ))}
            <svg className={toOffice ? classes.leftTail : classes.tail} xmlns="http://www.w3.org/2000/svg" width="8.941" height="10.121" viewBox="0 0 8.941 10.121">
              <path d="M8.941,9.877C4.664,7.018,3.654,3.614,3.443,0V1.025A9.966,9.966,0,0,1,0,8.563,14.14,14.14,0,0,0,8.941,9.877" fill={fill} />
            </svg>
          </Paper>
          {!edit &&
            <Tooltip
              title={toOffice || isScheduled ? '' : message.smsStatus}
              arrow
              placement="left-end"
            >
              <div className={isScheduled ? classes.scheduledMessageTime : classes.messageTime}>
                {isScheduled &&
                <>
                  <Button
                    startIcon={<EditIcon style={{ color: '#4F4F55' }} />}
                    sx={{
                      height: 19,
                      color: '#616166'
                    }}
                    onClick={editMessage}
                  >
                    Edit
                  </Button>
                  <Chip
                    icon={<AroundTheClockIcon className={classes.scheduledIcon}/>}
                    label="Scheduled"
                    sx={{
                      height: 23,
                      margin: '0px 3px',
                    }}
                  />
                </>}
                <span className={classes.date}>
                  {created}
                </span>
                {!toOffice &&
                <ShowStatus status={message.smsStatus} />}
              </div>
            </Tooltip>}
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
