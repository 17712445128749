import { AnyAction } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { LocalDate } from '@js-joda/core';
import {
  now,
  format,
} from '../../services/joda';
import { Appointment } from '../Appointments/appointments.types';
import * as ACTION_TYPES from './leads.actionTypes';

export type Message = {
  msgId: number;
  client: Lead;
  created: string;
  emailData: object;
  from: object;
  service?: object;
  smsData: object;
  pushData?: object;
  smsStatus: string;
  to: object;
}

export type Lead = {
  id?: number;
  firstName: string;
  lastName: string;
  status?: string;
  phone?: string;
  email?: string;
  birthday?: string;
  createdSource?: object;
  referrer?: string;
  created?: string;
  allowEmail?: boolean;
  allowSMS?: boolean;
  blockSMS?: boolean;
  isLead?: boolean;
}

export type Otpout = {
  service: string;
  description: string;
  displayName: string;
  checked?: boolean;
}

type LeadsReducer = {
  busy: boolean;
  leads: Lead[];
  toEdit?: Lead,
  selectedMessage?: Message;
  messages: {
    data: Message[];
    page: number;
    perPage?: number;
    totalCount?: number;
    totalPages?: number;
  }
  optout?: {
    email?: boolean;
    sms?: boolean;
    optouts: string[];
  };
  optoutList: Otpout[];
  categories?: Otpout[];
  state: string;
  search: string;
  page: number;
  perPage: number;
  searchBy: string;
  totalCount: number;
  totalPages: number;
  status: string[];
  notifications: string[];
  appointments: Appointment[];
  aptBusy: boolean;
  msgBusy?: boolean;
  selectedAppointment: Appointment;
  startDate: string;
  endDate: string;
  referrals: string[];
}

const init: LeadsReducer = {
  busy: false,
  leads: [],
  optoutList: [],
  selectedMessage: {} as Message,
  messages: {
    data: [],
    page: 1,
  },
  state: '',
  search: '',
  page: 1,
  perPage: 25,
  searchBy: 'name',
  totalCount: 0,
  totalPages: 1,
  status: ['Active'],
  notifications: [],
  appointments: [],
  aptBusy: false,
  msgBusy: false,
  selectedAppointment: null,
  startDate: format(now('date'), 'yyyy-MM-dd'),
  endDate: format((now('date') as LocalDate).plusMonths(1), 'yyyy-MM-dd'),
  referrals: [],
};

export default (leads = init, action: AnyAction): LeadsReducer => {
  switch (action.type) {
    case ACTION_TYPES.LEADS_PATCH: {
      return R.merge(leads, {
        ...action.data,
      });
    }
    case ACTION_TYPES.LEADS_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(leads, {
          busy: true,
        });
      }
      if (action.state === 'RESPONSE') {
        return R.merge(leads, {
          leads: action.data.data as Lead[],
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          page: action.data.page,
          busy: false,
        });
      }
      if (action.state === 'ERROR') {
        return R.merge(leads, {
          busy: false,
        });
      }
      return leads;
    }
    case ACTION_TYPES.LEADS_REMOTE_MESSAGES: {
      if (action.state === 'REQUEST') {
        return R.merge(leads, {
          msgBusy: true,
        });
      }
      if (action.state === 'RESPONSE') {
        return R.merge(leads, {
          messages: {
            data: action.data.data as Message[],
            totalCount: action.data.totalCount,
            totalPages: action.data.totalPages,
            page: action.data.page,
          },
          msgBusy: false,
        });
      }
      if (action.state === 'ERROR') {
        return R.merge(leads, {
          msgBusy: false,
        });
      }
      return leads;
    }
    case ACTION_TYPES.LEADS_REMOTE_REMOVE: {
      return R.merge(leads, {
        busy: false,
        leads: leads.leads.filter(lead => lead.id !== action.data.id),
      });
    }
    case ACTION_TYPES.LEADS_REMOTE_OPTOUT_LIST: {
      return R.merge(leads, {
        busy: false,
        optoutList: action.data.optoutList,
      });
    }
    case ACTION_TYPES.LEADS_REMOTE_OPTOUT: {
      const optout = action.data.optout;
      const optoutList = leads.optoutList.map(category => ({ ...category, checked: !R.includes(category.service, optout?.optouts) }));
      return R.merge(leads, {
        busy: false,
        optout,
        optoutList,
        categories: optoutList,
      });
    }
    case ACTION_TYPES.LEADS_CATEGORIES_UPDATE: {
      const categories = action.data.categories;
      return R.merge(leads, { categories });
    }
    case ACTION_TYPES.LEADS_REFERRALS_SET: {
      return R.merge(leads, {
        referrals: action.data.referrals
      });
    }
    case ACTION_TYPES.LEADS_REMOTE_UPDATE: {
      return R.merge(leads, {
        busy: false,
        leads: leads.leads.map(lead => lead.id === action.data.lead.id ? action.data.lead : lead),
      });
    }
    case ACTION_TYPES.LEADS_BLOCK_NUMBER_SET: {
      return R.merge(leads, {
        leads: leads.leads.map(lead => lead.id === action.data.id ? { ...lead, blockSMS: action.data.blockSMS } : lead),
      });
    }
    case ACTION_TYPES.LEADS_REMOTE_APTS_GET:
      if (action.state === 'REQUEST') {
        return R.merge(leads, {
          aptBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(leads, {
          appointments: R.pathOr([], ['data', 'appointments'])(action),
          aptBusy: false,
        });
      }
      return leads;
    case ACTION_TYPES.LEADS_REMOTE_APT_GET:
      if (action.state === 'REQUEST') {
        return R.merge(leads, {
          state: 'VIEW_APT',
          aptBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(leads, {
          selectedAppointment: action.data.appointment,
          aptBusy: false,
        });
      }
      return leads;
    case ACTION_TYPES.LEADS_LOADING: {
      return R.merge(leads, {
        busy: action.data,
      });
    }
    default:
      return leads;
  }
};
