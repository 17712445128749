import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Tabs, Tab, Divider, TableHead, TableBody, Paper, Chip, Grid
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  Print as PrintIcon,
  PrintDisabled as PrintDisabledIcon,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  cond, always, equals, identity, T, propOr, has, map, filter,
  pipe, isNil, isEmpty, includes, pathOr, F, prop, path, ascend,
  last, find, propEq, sortWith,
} from 'ramda';
import { LocalTime, } from '@js-joda/core';
import {
  tzParseFormat,
  format,
} from '../services/joda.js';
import
ClientEditDialog
  from '../routes/Clients/components/client-dialog/client-dialog.component.jsx';
import
MessageDialog
  from '../routes/Messages/components/messageDialog.component.jsx';
import { caseType, pickColor, capitalize } from '../services/utilities.js';
import { PopupTemplate } from '../services/Popup.js';
import { AutomationsList } from '../routes/Automations/automations.page';
import * as recurringViewActions from '../routes/RecurringMessage/routes/RecurringView/recurring-view.actions';
import * as MessagesViewActions from '../routes/Messages/routes/MessagesView/messages-view.actions';
import * as automationsActions from '../routes/Automations/automations.actions';
import { Automation, DefaultList } from '../routes/Automations/automation-types';
import Header from './PageHeader/PageHeader.component';
import EditLeadDialog from '../routes/Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../routes/Leads/components/Convert/Convert.component';
import { Lead } from '../routes/Leads/leads.reducer';
import { TableContainer, TableRow, HeaderCell, BodyCell } from './CustomTable';
import { PaginationData } from './Pagination/Pagination.component';
import '../styles/print.css';
import HeaderButton from './HeaderButton/HeaderButton.component';
import HeaderTabs from './HeaderTabs/HeaderTabs.component';
import FilterSelect from './FilterSelect/FilterSelect.component';
import { MessageLogicType } from './AutomationBasedMessage/automation-message.actions';
import { useTitle } from '../services/useTitle';


const fixGrammar = cond([
  [equals('Missed'), always('after, if missed')],
  [equals('Arrived'), always('after, if arrived')],
  [T, always('before')]
]);

const SmallTab = withStyles({
  root: {
    width: 'auto',
  },
})(Tab);

export type Client = {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  isLead?: boolean;
}

const listClients = (client: Client | Lead, selectedClient: (client: Client | Lead) => void) => (
  <a
    key={client.id}
    onClick={() => selectedClient(client)}>
    {client.firstName + ' ' + client.lastName + ' ' + caseType(client)}
    <br />
  </a>
);

type ListTypesProps = {
  apptType: {
    color: string;
    internalName: string;
  };
  proName: string;
}

const ListTypes = ({ apptType, proName }: ListTypesProps) => (
  <div style={{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }}>
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '100px',
        backgroundColor: pickColor(apptType.color),
        marginRight: '5px',
      }}>
    </div>
    <div>
      {proName + ' - ' + apptType.internalName}
    </div>
  </div>
);

const fixLength = (str: string) => {
  if (str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

type Professional = {
  id: number;
  firstName?: string;
  lastName?: string;
  displayFirstName?: string;
  displayLastName?: string
}

type Tag = {
  tag: string;
  tagId: number;
}

type Attachment = {
  attachmentUrl: string;
  attachmentName: string;
  attachmentId: number;
}

export type Message = {
  email: {
    subject: string;
    attachmentIds: number[];
    attachments: Attachment[];
    html: string;
    body: string;
    addCalendarEvent?: boolean;
  },
  service: {
    SkedMessage?: {
      msgId: number;
      apptId: number;
    }
  };
  emailData?: {
    subject: string;
    attachmentIds: number[];
    attachments: Attachment[];
    html: string;
    body: string;
    addCalendarEvent?: boolean;
  };
  selectedClients?: Client[];
  selectedLeads?: Lead[];
  appt: { time: string };
  msg: Message;
  msgId: number;
  value: string;
  client?: Client;
  created: string;
  sendAt: string;
  messageName?: string;
  scheduledFor: string;
  startDate: string;
  endDate: string;
  beforeAfter: string;
  body?: string;
  isSMS?: boolean;
  isEmail?: boolean;
  isPush?: boolean;
  filter: string;
  subject?: string;
  index?: number;
  forFeature?: string;
  isDeleted: boolean;
  selected?: boolean;
  isTemplate: boolean;
  createdAt: string;
  campaignId: number;
  includeInactive: boolean;
  changeType: string;
  name: string;
  tags: Tag[];
  delay: number;
  source: string;
  isEnabled: boolean;
  campaign: DefaultList;
  automations?: Automation[];
  sms: {
    attachmentIds: number[];
    attachments: Attachment[];
    body: string;
  },
  smsData?: {
    attachmentIds: number[];
    attachments: Attachment[];
    body: string;
  },
  id: number;
  officeId: number;
  push: {
    subject: string;
    body: string;
  }
  pushData?: {
    subject: string;
    body: string;
  },
  folder?: {
    path: string;
  }
}

type MessageInfoProps = {
  message: Message;
  tz: string;
  totalCount: number;
  actions: typeof MessagesViewActions;
  types: { id: number }[];
  professionals: Professional[];
  features: string[];
  messageType: string;
  urlId?: string;
  isSmartReply?: boolean;
  automationData?: MessageLogicType;
}
export const MessageInfo = ({
  message,
  tz,
  totalCount,
  actions,
  types,
  professionals,
  features,
  messageType,
  urlId,
  isSmartReply = false,
  automationData,
}: MessageInfoProps) => {
  const [selectedLead, setSelectedLead] = useState<Lead>(null);
  const [leadToConvert, setLeadToConvert] = useState<Lead>(null);
  const displayTypes = pipe(
    propOr([], automationData ? 'appointmentTypes' : 'selectedTypes'),
    map((id) => {
      const apptType = find(propEq('id', id), types);
      const proId = propOr(0, 'professionalId', apptType);
      const pro: Professional = find(propEq('id', proId), professionals);
      return {
        pro,
        apptType
      };
    }),
    filter(({ pro, apptType }) => pro && apptType),
    map(({ apptType, pro }) => ({
      apptType,
      pro,
      proName: pro.displayFirstName + ' ' + pro.displayLastName
    })),
    sortWith([
      ascend(path(['pro', 'displayLastName'])),
      ascend(path(['apptType', 'internalName']))
    ])
  )(automationData ? automationData : message);

  const folder = React.useMemo(() => {
    return message.folder ?
      last(message.folder.path.split('.') || ['']).replace(/_/g, ' ')
      :
      null;
  }, [message]);

  return (
    <Paper style={{
      margin: '10px 20px',
      padding: 15,
    }}>
      <h4>Message Data</h4>
      <TableContainer>
        <TableBody>
          <TableRow>
            <BodyCell>Message Title</BodyCell>
            <BodyCell style={{ width: '500px' }}>{message.messageName}</BodyCell>
          </TableRow>
          {(includes('AdvancedAutomatedCampaigns', features) ||
              (includes('BasicAutomatedCampaigns', features) && messageType === 'Automation'))
              && !isSmartReply && !automationData && message?.campaign?.listName &&
              <TableRow>
                <BodyCell>Campaign</BodyCell>
                <BodyCell style={{ width: '500px' }}>
                  <Link to={`/campaign/${pathOr('', ['campaign', 'listId'], message)}`}>
                    {pathOr('', ['campaign', 'listName'], message)}
                  </Link>
                </BodyCell>
              </TableRow>
          }
          {folder && includes('Folders', features) &&
              <TableRow>
                <BodyCell>Folder</BodyCell>
                <BodyCell style={{ width: '500px' }}>
                  {folder}
                </BodyCell>
              </TableRow>}
          {!includes('NewMessaging', features) &&
              [<TableRow>
                <BodyCell>Subject</BodyCell>
                <BodyCell style={{ width: '500px' }}>
                  {cond([
                    [path(['email', 'subject']), () => message.email.subject],
                    [path(['push', 'subject']), () => message.push.subject],
                    [path(['sms', 'subject']), () => ''],
                    [T, () => message.subject],
                  ])(message)}
                </BodyCell>
              </TableRow>,
              <TableRow>
                <BodyCell>Body</BodyCell>
                <BodyCell style={{ width: '600px' }}>
                  <pre
                    style={{
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                      fontFamily: 'sans-serif',
                      backgroundColor: 'white',
                      padding: '0px',
                    }}>
                    {cond([
                      [path(['email', 'body']), () => message.email.body],
                      [path(['push', 'body']), () => message.push.body],
                      [path(['sms', 'body']), () => message.sms.body],
                      [T, () => message.body],
                    ])(message)}
                  </pre>
                </BodyCell>
              </TableRow>
              ]}
          <TableRow>
            <BodyCell>Email</BodyCell>
            <BodyCell>{message.isEmail ? 'Yes' : 'No'}</BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell>SMS</BodyCell>
            <BodyCell>{message.isSMS ? 'Yes' : 'No'}</BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell>Push</BodyCell>
            <BodyCell>{message.isPush ? 'Yes' : 'No'}</BodyCell>
          </TableRow>
          {automationData?.type === 'TimeBasedReminder' && (
            <>
              <TableRow key='2'>
                <BodyCell>Days {capitalize(automationData.tense)} Appointment</BodyCell>
                <BodyCell>
                  {automationData.amount}
                </BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Appointment Status</BodyCell>
                <BodyCell>
                  {automationData.status}
                </BodyCell>
              </TableRow>
              <TableRow key='3'>
                <BodyCell>Time to Send</BodyCell>
                <BodyCell>
                  {automationData.time &&
                      format(LocalTime.parse(automationData.time), 'h:mm a')}
                </BodyCell>
              </TableRow>
              <TableRow key='4'>
                <BodyCell>Sent to Inactive Clients?</BodyCell>
                <BodyCell>
                  {automationData.clientStatus !== 'Active' ? 'Yes' : 'No'}
                </BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Types</BodyCell>
                <BodyCell>
                  {displayTypes.map(({ apptType, proName }) =>
                    <ListTypes key={apptType.id} apptType={apptType} proName={proName} />
                  )}
                </BodyCell>
              </TableRow>
            </>
          )}
          {cond([
            [equals('OneTime'), () => {
              return [
                <TableRow key='1'>
                  <BodyCell>Schedule For</BodyCell>
                  <BodyCell>
                    {message.scheduledFor &&
                        message.scheduledFor !== 'now' &&
                        tzParseFormat(message.scheduledFor, tz, 'MM/dd/yyyy @ h:mm a')}
                    {message.scheduledFor &&
                        message.scheduledFor === 'now' &&
                        'Now'}
                  </BodyCell>
                </TableRow>,
                [cond([
                  [equals('Everyone'), () => ([
                    <TableRow key='2'>
                      <BodyCell>Sent to</BodyCell>
                      <BodyCell>
                        {'Everyone (' + totalCount + ')'}
                      </BodyCell>
                    </TableRow>
                  ])],
                  [equals('Clients'), () => ([
                    <TableRow key='2'>
                      <BodyCell>Sent to</BodyCell>
                      <BodyCell>
                        {pipe(
                          propOr([], 'selectedClients'),
                          map((client: Client) => listClients(client, client.isLead ? setSelectedLead : actions.selectClient))
                        )(message)}
                      </BodyCell>
                    </TableRow>
                  ])],
                  [equals('Leads'), () => ([
                    <TableRow key='2'>
                      <BodyCell>Sent to</BodyCell>
                      <BodyCell>
                        {[...message.selectedClients, ...message.selectedLeads].map((client) => (
                          listClients(client, client.isLead ? setSelectedLead : actions.selectClient)
                        ))}
                      </BodyCell>
                    </TableRow>
                  ])],
                  [equals('DateRange'), () => ([
                    <TableRow key='1'>
                      <BodyCell>Date Range</BodyCell>
                      <BodyCell>
                        {format(message.startDate, 'MM/dd/yyyy') +
                            ' - ' +
                            format(message.endDate, 'MM/dd/yyyy')}
                      </BodyCell>
                    </TableRow>,
                    <TableRow key='2'>
                      <BodyCell>Types</BodyCell>
                      <BodyCell>
                        {displayTypes.map(({ apptType, proName }) =>
                          <ListTypes key={apptType.id} apptType={apptType} proName={proName} />
                        )}
                      </BodyCell>
                    </TableRow>
                  ])],
                  [equals('ClientTag'), () => ([
                    <TableRow>
                      <BodyCell>Tag(s)</BodyCell>
                      <BodyCell>
                        {pipe(
                          propOr([], 'tags'),
                          map((tag: Tag) => {
                            return (
                              <div key={tag.tagId}>
                                {tag.tag}
                              </div>
                            );
                          })
                        )(message)}
                      </BodyCell>
                    </TableRow>
                  ])]
                ])(message.filter)
                ]
              ];
            }],
            [equals('Recurring'), () => {
              return [
                <TableRow key='1'>
                  <BodyCell>Sent To</BodyCell>
                  <BodyCell>
                    {message.value +
                        ' ' +
                        pathOr('', ['unit', 'name'])(message).toLowerCase() +
                        ' ' +
                        fixGrammar(message.beforeAfter)}
                  </BodyCell>
                </TableRow>,
                <TableRow key='2'>
                  <BodyCell>Types</BodyCell>
                  <BodyCell>
                    {displayTypes.map(({ apptType, proName }) =>
                      <ListTypes key={apptType.id} apptType={apptType} proName={proName} />
                    )}
                  </BodyCell>
                </TableRow>
              ];
            }],
            [equals('Recall'), () => {
              return [
                <TableRow key='2'>
                  <BodyCell>Days After Appointment</BodyCell>
                  <BodyCell>
                    {message.value}
                  </BodyCell>
                </TableRow>,
                <TableRow key='3'>
                  <BodyCell>Time to Send</BodyCell>
                  <BodyCell>
                    {message.sendAt &&
                        format(LocalTime.parse(message.sendAt), 'h:mm a')}
                  </BodyCell>
                </TableRow>,
                <TableRow key='4'>
                  <BodyCell>Sent to Inactive Clients?</BodyCell>
                  <BodyCell>
                    {message.includeInactive ? 'Yes' : 'No'}
                  </BodyCell>
                </TableRow>,
                <TableRow key='5'>
                  <BodyCell>Types</BodyCell>
                  <BodyCell>
                    {displayTypes.map(({ apptType, proName }) =>
                      <ListTypes key={apptType.id} apptType={apptType} proName={proName} />
                    )}
                  </BodyCell>
                </TableRow>,
              ];
            }],
            [equals('Birthday'), () => {
              return [
                <TableRow>
                  <BodyCell>Time to Send</BodyCell>
                  <BodyCell>
                    {message.sendAt &&
                        format(LocalTime.parse(message.sendAt), 'h:mm a')}
                  </BodyCell>
                </TableRow>
              ];
            }],
            [equals('ApptChange'), () => {
              return [
                <TableRow key='2'>
                  <BodyCell>Delay</BodyCell>
                  <BodyCell>{message.delay + ' Minutes'}</BodyCell>
                </TableRow>,
                <TableRow key='3'>
                  <BodyCell>Source(s)</BodyCell>
                  <BodyCell>
                    {message.source &&
                        pipe(
                          propOr([], 'source'),
                          map((label: string) => {
                            const l = label === 'Webmodule' ? 'New Patient Portal' : label;
                            return (
                              <div key={l}>
                                {l}
                                <br />
                              </div>
                            );
                          })
                        )(message)}
                  </BodyCell>
                </TableRow>,
                <TableRow key='4'>
                  <BodyCell>Change Type(s)</BodyCell>
                  <BodyCell>
                    {message.changeType &&
                        pipe(
                          propOr([], 'changeType'),
                          map((label: string) => {
                            const l = label === 'ChangeArrived' ? 'Arrived' : label;
                            return (
                              <div key={l}>
                                {l}
                                <br />
                              </div>
                            );
                          })
                        )(message)}
                  </BodyCell>
                </TableRow>,
                <TableRow key='5'>
                  <BodyCell>Types</BodyCell>
                  <BodyCell>
                    {displayTypes.map(({ apptType, proName }) =>
                      <ListTypes key={apptType.id} apptType={apptType} proName={proName} />
                    )}
                  </BodyCell>
                </TableRow>,
              ];
            }],
            // [T, always([])]
          ])(messageType)}
        </TableBody>
      </TableContainer>
      <EditLeadDialog
        open={!!selectedLead}
        close={() => setSelectedLead(null)}
        lead={selectedLead}
        convertClick={() => {
          setLeadToConvert(selectedLead);
          setSelectedLead(null);
        }}
        onUpdate={() => actions.loadMessage(urlId, tz)}
      />
      <ConvertLeadDialog
        open={!!leadToConvert}
        lead={leadToConvert}
        close={() => setLeadToConvert(null)}
        onConvert={() => {
          setLeadToConvert(null);
          actions.loadMessage(urlId, tz);
        }}
      />
    </Paper>
  );
};

type MessageContentProps = {
  message: Message;
  partTab: string;
  setPartTab: (value: string) => void;
}
export const MessageContent = ({
  message,
  partTab,
  setPartTab,
}: MessageContentProps) => (
  <Paper style={{
    margin: '10px 20px',
    padding: 15,
  }}>
    <h4>Message Content</h4>
    <Tabs
      value={partTab}
      onChange={(e, val) => setPartTab(val)}
      indicatorColor="primary"
      textColor="primary"
      centered>
      {message.isEmail && <SmallTab label="Email" value="email" />}
      {message.isSMS && <SmallTab label="SMS/MMS" value="sms" />}
      {message.isPush && <SmallTab label="Push" value="push" />}
    </Tabs>
    {message.email &&
      <div
        hidden={partTab !== 'email'}
        style={{
          margin: 10,
        }}>
        <p>
          <b>{message.email.subject}</b>
        </p>
        <Divider />
        {message.email.body &&
          [<p key='1'
            style={{ marginTop: '10px' }}>
            {message.email.body}
          </p>,
          <Divider key='2' />]}
        <div>
          <div style={{ width: '600px' }}>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                fontFamily: 'sans-serif',
                backgroundColor: 'white',
                padding: '0px',
              }}
              dangerouslySetInnerHTML={{ __html: message.email.html }}>
            </pre>
          </div>
        </div>
        {!isEmpty(message.email.attachments) &&
          <>
            <Divider />
            <div>
              {pipe(
                propOr([], 'attachments'),
                map((file: Attachment) => {
                  return (
                    [<a key={file.attachmentId} href={file.attachmentUrl} target='_blank'>
                      {file.attachmentName}
                    </a>,
                    <br />]
                  );
                })
              )(message.email)}
            </div>
          </>}
        {message.email.addCalendarEvent &&
          <i>This message contains a calendar event attachment.</i>}
      </div>}
    {message.sms &&
      <div
        hidden={partTab !== 'sms'}
        style={{
          margin: 10,
        }}>
        <div>
          <div style={{ width: '600px' }}>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                fontFamily: 'sans-serif',
                backgroundColor: 'white',
                padding: '0px',
              }}>
              {message.sms.body}
            </pre>
          </div>
        </div>
        {!isEmpty(message.sms.attachments) &&
          <>
            <Divider />
            <div>
              {pipe(
                propOr([], 'attachments'),
                map((file: Attachment) => {
                  return (
                    [<a key={file.attachmentId} href={file.attachmentUrl} target='_blank'>
                      {file.attachmentName}
                    </a>,
                    <br />]
                  );
                })
              )(message.sms)}
            </div>
          </>}
      </div>}
    {message.push &&
      <div
        hidden={partTab !== 'push'}
        style={{
          margin: 10,
        }}>
        <p>
          <b>{message.push.subject}</b>
        </p>
        <Divider />
        <div>
          <div style={{ width: '600px' }}>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                fontFamily: 'sans-serif',
                backgroundColor: 'white',
                padding: '0px',
              }}>
              {message.push.body}
            </pre>
          </div>
        </div>
      </div>}
  </Paper>
);

const messageItem = (message: Message, tz: string, select: (m: Message, client?: Client) => void, appt: { time: string } = null, showAppt = false) => {
  return ([
    prop('emailData', message) &&
    <TableRow
      style={{ cursor: 'pointer' }}
      onClick={() => select(message)}
      key={message.msgId + 'email'}>
      <BodyCell fixed nowrap>
        <a onClick={(event) => {
          event.stopPropagation();
          select(undefined, message.client);
        }}>
          {message.client.firstName + ' ' + message.client.lastName}
        </a>
      </BodyCell>
      <BodyCell>
        {message.client.phone}
      </BodyCell>
      <BodyCell>
        {fixLength(pathOr('', ['emailData', 'subject'])(message))}
      </BodyCell>
      <BodyCell>
        Email
      </BodyCell>
      <BodyCell nowrap>
        {tzParseFormat(message.created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
      </BodyCell>
      {showAppt &&
        <BodyCell nowrap>
          {appt && tzParseFormat(appt.time, tz, 'MM/dd/yyyy @ h:mm:ss a')}
        </BodyCell>}
    </TableRow>,
    prop('smsData', message) &&
    <TableRow
      style={{ cursor: 'pointer' }}
      onClick={() => select(message)}
      key={message.msgId + 'sms'}>
      <BodyCell fixed nowrap>
        <a onClick={(event) => {
          event.stopPropagation();
          select(undefined, message.client);
        }}>
          {message.client.firstName + ' ' + message.client.lastName}
        </a>
      </BodyCell>
      <BodyCell>
        {message.client.phone}
      </BodyCell>
      <BodyCell>

      </BodyCell>
      <BodyCell>
        SMS/MMS
      </BodyCell>
      <BodyCell nowrap>
        {tzParseFormat(message.created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
      </BodyCell>
      {showAppt &&
        <BodyCell nowrap>
          {appt && tzParseFormat(appt.time, tz, 'MM/dd/yyyy @ h:mm:ss a')}
        </BodyCell>}
    </TableRow>,
    prop('pushData', message) &&
    <TableRow
      style={{ cursor: 'pointer' }}
      onClick={() => select(message)}
      key={message.msgId + 'push'}>
      <BodyCell fixed nowrap>
        <a onClick={(event) => {
          event.stopPropagation();
          select(undefined, message.client);
        }}>
          {message.client.firstName + ' ' + message.client.lastName}
        </a>
      </BodyCell>
      <BodyCell>
        {message.client.phone}
      </BodyCell>
      <BodyCell>
        {fixLength(pathOr('', ['pushData', 'subject'])(message))}
      </BodyCell>
      <BodyCell>
        Push
      </BodyCell>
      <BodyCell nowrap>
        {tzParseFormat(message.created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
      </BodyCell>
      {showAppt &&
        <BodyCell nowrap>
          {appt && tzParseFormat(appt.time, tz, 'MM/dd/yyyy @ h:mm:ss a')}
        </BodyCell>}
    </TableRow>
  ]);
};

export interface MessagesListActions {
  selectMessage: (m: Message) => void;
  selectClient?: (c: Client) => void;
}

type messageListProps = {
  messages: Message[];
  tz: string;
  actions: MessagesListActions;
  reactivationSearch: boolean;
  maxHeight?: string | number;
  paginationData?: PaginationData;
}

export const MessageList = ({
  messages, tz, actions, reactivationSearch, paginationData, maxHeight,
}: messageListProps) => {
  return (
    <div>
      <TableContainer maxHeight={maxHeight} paginationData={paginationData} pagination={!!paginationData}>
        <TableHead>
          <TableRow>
            <HeaderCell>
              Client
            </HeaderCell>
            <HeaderCell>
              Phone
            </HeaderCell>
            <HeaderCell>
              Subject
            </HeaderCell>
            <HeaderCell>
              Sent As
            </HeaderCell>
            <HeaderCell>
              Sent Time
            </HeaderCell>
            {reactivationSearch &&
              <HeaderCell>
                Next Appt Time
              </HeaderCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {reactivationSearch ?
            map((message: Message) =>
              messageItem(
                message.msg,
                tz,
                (m, c) => c ? actions.selectClient(c) : actions.selectMessage({
                  ...m,
                  service: {
                    SkedMessage: {
                      msgId: m.service.SkedMessage.msgId,
                      apptId: pathOr(null, ['appt', 'id'], message),
                    },
                  },
                }),
                message.appt,
                true
              ))(messages)
            :
            map((message: Message) =>
              messageItem(message, tz, (m, c) => c ? actions.selectClient(c) : actions.selectMessage(m))
            )(messages)
          }
        </TableBody>
      </TableContainer>
    </div>
  );
};

const messagePrintItem = (msg: Message, appt: { time: string }, tz: string) => (
  <TableRow>
    <BodyCell>
      {msg.client.firstName + ' ' + msg.client.lastName}
    </BodyCell>
    <BodyCell>
      {msg.client.phone}
    </BodyCell>
    <BodyCell>
      {tzParseFormat(msg.created, tz, 'MM/dd/yyyy h:mm:ss a')}
    </BodyCell>
    <BodyCell>
      {appt && tzParseFormat(appt.time, tz, 'MM/dd/yyyy h:mm a')}
    </BodyCell>
  </TableRow>
);

type messagePrintProps = {
  messages: Message[];
  message: Message;
  tz: string;
}

const messagePrint = ({ messages, message, tz }: messagePrintProps) => (
  <div style={{ margin: '10px' }}>
    Message Title: <b>{message.messageName}</b>
    <br />
    Sent as:{message.email && ' Email'} {message.sms && ' SMS'} {message.push && ' Push'}
    <br />
    Days After Appointment: {message.value}
    <br />
    <br />
    <TableContainer>
      <TableHead>
        <TableRow>
          <HeaderCell>
              Client
          </HeaderCell>
          <HeaderCell>
              Phone
          </HeaderCell>
          <HeaderCell>
              Sent Time
          </HeaderCell>
          <HeaderCell>
              Next Appt Time
          </HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map((msg: Message) =>
          messagePrintItem(
            msg.msg,
            msg.appt,
            tz,
          ))(messages)}
      </TableBody>
    </TableContainer>
  </div>
);

type Actions = {
  loadMessage: typeof MessagesViewActions.loadMessage
  getMessages: typeof recurringViewActions.getMessages;
  updateAutomation: typeof automationsActions.updateAutomation;
  removeAutomation: typeof automationsActions.removeAutomation;
}

type ViewMessageProps = {
  messageType: string;
  busy: boolean;
  message: Message;
  state: string;
  tz: string;
  messages: Message[];
  page: number;
  perPage: number;
  urlId: string;
  totalCount: number;
  totalPages: number;
  selectedMessage: Message[];
  actions: Actions & typeof MessagesViewActions;
  types: { id: number }[];
  professionals: Professional[];
  features: string[];
  partTab: string;
  headerHeight?: number;
}

const niceName = (type: string) => cond([
  [equals('OneTime'), always('One Time')],
  [equals('Birthday'), identity],
  [equals('Recurring'), always('Reminder')],
  [equals('Recall'), always('Reactivation')],
  [equals('ApptChange'), always('Rapid')],
  [T, always('')],
])(type);

export default function ViewMessage({
  messageType, busy, message, state, tz, messages, page, perPage,
  totalCount, totalPages, selectedMessage, actions, types,
  professionals, features, partTab, headerHeight = 54
}: ViewMessageProps) {
  const routeNavigate = useNavigate();
  const location = useLocation();
  const { id: urlId } = useParams();
  useTitle('View ' + niceName(messageType) + ' Message');
  const isSmartReply = location &&
    location.pathname.indexOf('smart-reply') !== -1;
  const reactivationSearch =
    includes('ReactivationSearch', features) &&
    messageType === 'Recall';
  const [tab, setTab] = useState('messages');
  const [filter, setFilter] = useState('All');
  const [selectedPrintView, setSelectedPrintView] = React.useState('list');
  const hasFutureAppointment = cond([
    [equals('All'), always(undefined)],
    [equals('No Future Appointment'), F],
    [equals('Future Appointment'), T],
    [T, always(undefined)]
  ])(filter);

  const navigate = () => {
    routeNavigate('/' +
    cond([
      [equals('Recall'), always('Reactivation')],
      [equals('Automation'), () => isSmartReply ? 'smart-reply-old' : 'Automation'],
      [T, always(messageType)]
    ])(messageType).toLowerCase() +
    '/edit/' +
    urlId);
  };

  useEffect(() => {
    setTimeout(() =>
      actions.loadMessage(urlId, tz),
    isSmartReply ? 500 : 0,
    );
    actions.getMessages({
      id: urlId,
      reactivationSearch,
      hasFutureAppointment,
    });
    if (messageType === 'Recall') {
      setSelectedPrintView('list');
    } else {
      setSelectedPrintView(null);
    }
  }, []);

  useEffect(() => {
    actions.getMessages({
      id: urlId,
      reactivationSearch,
      hasFutureAppointment,
    });
  }, [filter]);

  return (
    <>
      <Header title='Message'
        leftIcons={[
          <Grid marginLeft="5px" />,
          <HeaderTabs
            value={tab}
            setTab={setTab}
            tabs={[
              { label: 'Message Info', value: 'messages' },
              { label: 'Sent Messages', value: 'sent' }
            ]}
          />,
          <Grid marginLeft="10px" />,
          (messageType === 'Recall' && tab === 'sent' &&
            reactivationSearch && selectedPrintView !== 'print') ?
            <FilterSelect
              items={['All', 'Future Appointment', 'No Future Appointment']}
              selected={filter}
              title='Filter'
              noneText='All'
              setSelected={setFilter}
              Icon={FilterListIcon}
            /> : <div />,
        ]}
        rightIcons={[
          (tab === 'sent' && reactivationSearch) && <Grid>
            {selectedPrintView === 'list' &&
            <HeaderButton
              onlyIcon
              Icon={PrintIcon}
              onClick={() => {
                setSelectedPrintView('print');
                setTimeout(() => {
                  new Promise((res) => {
                    res(window.print());
                  }).then(() => setSelectedPrintView('list'));
                }, 100);
              }}
            />}
            {selectedPrintView === 'print' &&
            <HeaderButton
              onlyIcon
              Icon={PrintDisabledIcon}
              onClick={() => setSelectedPrintView('list')}
            />}
          </Grid>,
          messageType !== 'OneTime' ?
            <HeaderButton
              title='Edit'
              Icon={EditIcon}
              onClick={navigate}
            /> : <div />
        ]}
        onlyIconsWidth={508}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 0,
          overflowY: 'auto',
          paddingBottom: 0,
        }}>
        <PopupTemplate />
        <ClientEditDialog
          open={state === 'CLIENT_SELECT'}
          onClose={actions.backToMessage}
          back={actions.back}
          from={'MESSAGE'}
          gotoClient={actions.back} />
        <MessageDialog
          open={state === 'MESSAGE_SELECT'}
          message={selectedMessage}
          onClose={actions.backToMessage}
          from={'MESSAGE'}
          tz={tz}
          back={() => actions.back()}
          gotoClient={actions.selectClientDialog} />
        <div hidden={tab !== 'messages'} style={{
          paddingBottom: '60px',
        }}>
          {busy && <div className="loader"></div>}
          {!busy && includes('NewMessaging', features) &&
            <MessageContent
              message={message}
              partTab={partTab}
              setPartTab={actions.patch}
            />}
          {!busy && <MessageInfo
            message={message}
            // state
            tz={tz}
            urlId={urlId}
            totalCount={totalCount}
            actions={actions}
            types={types}
            professionals={professionals}
            features={features}
            messageType={messageType}
            isSmartReply={isSmartReply}
          />}
          {!busy && (includes('AdvancedAutomatedCampaigns', features) ||
          includes('BasicAutomatedCampaigns', features)) &&
           messageType === 'Automation' && message && !isSmartReply &&
          <Paper style={{
            margin: '10px 20px',
            padding: 15,
          }}>
            <h4>Automations</h4>
            {isEmpty(message.automations) ?
              'There are no automations for this message.'
              :
              <AutomationsList
                automations={message.automations}
                defaultCampaign={message.campaign}
                onSave={(idx: number) => (automation: object) =>
                  actions.updateAutomation(idx, automation)}
                removeAutomation={actions.removeAutomation}
                isTemplate={undefined}
                cantSave={undefined}
                disabledActionsMessage={undefined}
              />}
          </Paper>}
          {!busy && includes('SmartReply', features) && messageType === 'Automation' &&
           message && isSmartReply &&
            <Paper style={{
              margin: '10px 20px',
              padding: 15,
            }}>
              <h4>Keywords</h4>
              {isEmpty(message.automations) || isNil(message.automations) ?
                'There are no keywords to trigger this message.'
                :
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                }}>
                  {message.automations?.map(({ trigger }) =>
                    has('MessageReceived', trigger) &&
                    trigger.MessageReceived.map((k: string) => (
                      <Chip style={{ fontSize: '14px', margin: '5px', }} label={k} />
                    )))}
                </div>}
            </Paper>
          }
        </div>
        <div hidden={tab !== 'sent'} style={{
          paddingBottom: '60px',
        }}>
          {busy && <div className="loader"></div>}
          {!busy && selectedPrintView !== 'print' &&
            <MessageList
              messages={messages}
              tz={tz}
              actions={actions}
              reactivationSearch={reactivationSearch}
              maxHeight={`calc(100vh - ${headerHeight}px - 54px)`}
              paginationData={{
                addPage: (v) => actions.getMessages({
                  id: urlId,
                  perPage,
                  page: page + v,
                  reactivationSearch,
                  hasFutureAppointment,
                }),
                currentLength: messages.length,
                page,
                totalCount,
                totalPages,
                perPage,
                setPerPage: (value) => {
                  actions.getMessages({
                    id: urlId,
                    perPage: value,
                    page,
                    reactivationSearch,
                    hasFutureAppointment,
                  });
                },
              }}
            />}
          {!busy && selectedPrintView === 'print' &&
            messagePrint({ messages, message, tz })}
        </div>
      </div>
    </>
  );
}
