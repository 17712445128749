import * as React from 'react';
import {
  Button, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, Typography, ClickAwayListener, TextField, Tooltip,
} from '@mui/material';
import { Spacer } from '../../../components/PageHeader';
import {
  ComplexWidget, ComplexWidgetConsentPapers, IndexAndConds,
} from '../intake-types';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { SignatureComponent } from './Signature.component';
import {
  updateSimpleWidget, deleteSectionChild
} from '../intake.context';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import { Editor } from '@tiptap/react';
import {
  TipTap
} from '../../../components/Editor/editor.component';
import QuestionLabel from './QuestionLabel.component';

interface ConsentProps {
  name: string;
  open: boolean;
  handleClose: () => void;
  handleSave: (body: string) => () => void;
  body: string;
}

const ConsentDialog = ({
  name = '', open = false, handleClose, body = '', handleSave
}: ConsentProps) => {
  const [state, setState] = React.useState(body);
  const editorRef = React.useRef<Editor>();
  React.useEffect(() => {
    setState(body);
  }, [open]);
  const handleUpdate = (value: string) => {
    setState(value);
  };
  const insert = (placeholder: string): void => {
    if (editorRef && editorRef.current) {
      const range = {
        from: editorRef.current.state.selection.ranges[0].$from.pos,
        to: editorRef.current.state.selection.ranges[0].$to.pos,
      };
      editorRef.current.commands.insertContentAt(range, placeholder, { updateSelection: true });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h6">
          {name}
        </Typography>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: '8px', top: '8px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <TipTap
            onChange={handleUpdate}
            content={state}
            setEditor={(editor: Editor) => editorRef.current = editor}
            width='73%'
            height={400}
            editorMenuIndex={1301}
          />
          <div>
            <h4 style={{ paddingLeft: '25px' }}>Placeholders</h4>
            <div style={{ paddingLeft: '25px', float: 'left' }}>
              <Tooltip title='If you want to include a placeholder to text box, type it in after the colon (:) inside the placeholder! (ex. {{fill-in-the-blank:First Name}})' arrow>
                <div>
                  <div>
                    <button type='button' onClick={() => insert('{{fill-in-the-blank:}}')}>Insert</button>
                    &nbsp;
                    Fill in the Blank
                  </div>
                </div>
              </Tooltip>
              <br />
              <Tooltip title='If you want to include a placeholder to text box, type it in after the colon (:) inside the placeholder! (ex. {{required-blank:First Name}})' arrow>
                <div>
                  <div>
                    <button type='button' onClick={() => insert('{{required-blank:}}')}>Insert</button>
                    &nbsp;
                    Required Fill in the Blank
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <SignatureComponent
          hasClose={false}
          id={0}
          required={true}
          data={{ type: 'Signature' }}
          forOffice={true}
          questionCond={null}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color="primary"
          style={{ marginRight: 16 }}
          onClick={handleSave(state)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConsentPapersProps {
  data: ComplexWidget,
  required: boolean;
  index: number;
  maxIndex: number;
  id: number;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

export const ConsentPapersComponent = ({
  id, data, index, maxIndex, forOffice, questionCond
}: ConsentPapersProps) => {
  const tdata = data.typeData as ComplexWidgetConsentPapers;
  const dispatch = useDispatch();
  const [name, setName] = React.useState(tdata.name);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const textboxRef = React.useRef<HTMLInputElement>();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const saveName = () => {
    if (name !== '') {
      setEdit(false);
      dispatch(updateSimpleWidget({
        id,
        width: 12,
        forOffice: false,
        type: 'ComplexWidget',
        typeData: {
          type: 'ConsentPapers',
          ...tdata,
          name
        },
      }));
    }
  };

  const saveBody = (b: string) => () => {
    handleClose();
    dispatch(updateSimpleWidget({
      id,
      width: 12,
      type: 'ComplexWidget',
      forOffice: false,
      typeData: {
        type: 'ConsentPapers',
        ...tdata,
        body: b,
      },
    }));
  };

  React.useEffect(() => {
    if (textboxRef.current) {
      textboxRef.current.focus();
      textboxRef.current.select();
    }
  }, [edit]);

  const handleDelete = () => {
    dispatch(deleteSectionChild(id, questionCond.index));
  };


  return (
    <div style={{ paddingBottom: '20px' }}>
      {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
      <div style={{ display: 'flex' }}>
        <div><strong>Consent Paper</strong></div>
        <Spacer />
        <OrderButtons
          index={index}
          sectionMaxIndex={maxIndex}
          data={data}
          id={id}
          questionIndex={questionCond.index}
          handleDelete={handleDelete}
        />
      </div>
      <ConsentDialog
        open={open}
        handleClose={handleClose}
        name={tdata.name}
        body={tdata.body}
        handleSave={saveBody}
      />
      <div
        key={tdata.name}
        style={{
          display: 'flex',
          paddingTop: '7.5px',
          paddingBottom: '7.5px',
          borderTop: '1px solid #ccc',
          alignItems: 'center'
        }}>
        {(tdata.name === '' || tdata.body === '') &&
          <div style={{ marginLeft: '20px', marginRight: '20px' }}>
            <WarningIcon style={{ color: '#ff9800' }} />
          </div>}
        {(tdata.name !== '' && tdata.body !== '') &&
          <div style={{ marginLeft: '20px', marginRight: '20px' }}>
            <CheckIcon style={{ color: 'green' }} />
          </div>}
        {edit &&
          <ClickAwayListener onClickAway={saveName}>
            <div style={{ width: '30%' }}>
              <TextField
                style={{ width: '100%' }}
                inputRef={textboxRef}
                InputLabelProps={{ shrink: true }}
                value={name}
                onChange={handleUpdate}
                type="text"
                label="Document Name"
                error={name === ''}
                helperText={name === '' ? 'Field cannot be blank!' : ''}
              />
            </div>
          </ClickAwayListener>}
        {!edit &&
          <Typography
            variant="h5"
            component="div"
            fontSize="20px"
            onClick={() => setEdit(true)}>
            {tdata.name}
          </Typography>}
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button
            onClick={handleClickOpen}
            variant='contained'
            color="primary"
            startIcon={<EditIcon />}>
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
};
