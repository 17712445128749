import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  TextField, CircularProgress, IconButton,
  Checkbox, TableHead, TableBody, Tooltip, Menu,
  MenuItem, ListItemText, FormControlLabel, ListItemIcon, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { PopupTemplate, popupWithCancel } from '../../services/Popup.js';
import {
  includes, pathOr, map, prop, isEmpty, append,
  without, find, equals, path, cond, has, always,
  identity, prepend, remove, pipe, propOr, T, update,
  ifElse, filter, not, any,
} from 'ramda';
import { PageHeader, Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import * as actions from './clientlists.actions';
import {
  tzParseFormat,
  localParseFormat,
  format,
} from '../../services/joda.js';
import {
  LocalDate,
} from '@js-joda/core';
import { usePromise } from '../../services/promise.hook';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { SearchClientsDialog } from './components/search-clients-dialog.component';
import { AutomationsList, AutomationDialog, triggers } from '../Automations/automations.page';
import { automationsPatch } from '../Automations/automations.actions';
import AutomationTemplateDialog from '../Templates/automation-template-dialog.component';
import { RECURRING_EDIT_RESET } from '../../actionTypes';
import { copyMessage } from '../RecurringMessage/recurring.actions.jsx';
import { Client } from '../Clients/clients.types';
import { RowContainerProps, ClientWithList, ClientListHistory } from './clientlist-types';
import { Automation, AutomationsState } from '../Automations/automation-types.js';
import { messagesPatch, reset } from '../Templates/routes/TemplatesEdit/templates-edit.actions';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import { backToClient, getCurrentClient } from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import Header from '../../components/PageHeader/PageHeader.component';
import Loading from '../../components/Loading/Loading.component';
import Container from '../../components/Container/Container.component';
import { TableContainer, TableRow, HeaderCell, BodyCell } from '../../components/CustomTable';
import Pagination from '../../components/Pagination/Pagination.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import FilterStartEndDate from '../../components/FilterStartEndDate/FilterStartEndDate.component';
import TriggerIcon from '../../icons/Trigger.icon';
import EnabledIcon from '../../icons/Enabled.icon';
import { useSelector } from '../../reducers';

const enabledOptions = [
  { value: null, name: 'Any' },
  { value: true, name: 'Only Enabled' },
  { value: false, name: 'Only Disabled' },
];

const useStyles = makeStyles(() => ({
  headerRow: {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabBodyContainer: {
    marginTop: '10px',
  },
  iconSpan: {
    marginRight: '5px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    color: 'red',
    margin: 'unset',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  noHorizontalMargins: {
    marginLeft: -20,
    marginRight: -20,
  },
  checkbox: {
    paddingLeft: 4,
    marginRight: 0,

    '& .MuiCheckbox-root': {
      padding: 2,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 4,
    }
  },
}));

const parseBirthday = (birthday: string) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

const RowContainer = ({
  clientWithList,
  tz,
  select,
  isSelected,
  onDelete,
  urlId,
  pageType,
}: RowContainerProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(isSelected);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const {
    client,
    created,
    optout,
  } = clientWithList;

  const {
    firstName,
    lastName,
    birthday,
    phone,
    email,
    id,
  } = client;

  const url = `${pageType === 'Campaign' ? '/campaign' : '/client-list'}/${urlId}`;

  return (
    <TableRow
      key={id}
      hasSelect
      checked={Boolean(selected)}
      setChecked={(value) => {
        setSelected(value);
        select(value);
      }}
    >
      <BodyCell>
        <ClientEditDialog
          open={open}
          from={'CAMPAIGNS'}
          onClose={() => setOpen(false)}
          back={() => dispatch(backToClient())}
          gotoClient={() => dispatch(backToClient())}
        />
        <Link
          to={url}
          onClick={() => {
            setOpen(true);
            dispatch(getCurrentClient(client));
          }}>
          {firstName} {lastName}
        </Link>
      </BodyCell>
      <BodyCell>
        {parseBirthday(birthday)}
      </BodyCell>
      <BodyCell>
        {phone}
      </BodyCell>
      <BodyCell>
        {email}
      </BodyCell>
      <BodyCell>
        {tzParseFormat(created, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        {optout && tzParseFormat(optout, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        <Tooltip arrow title='Remove Client' placement='top'>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

interface MiniAuto {
  id: number;
  name: string;
}

interface MessageRowContainerProps {
  message: any;
  tz: string;
  select: (s: boolean) => void;
  isSelected: boolean;
  onDelete: () => void;
  openAuto: (c: MiniAuto) => void;
  onCopy: () => void;
  template?: boolean;
  campaignId: number;
}

interface TypeUrl {
  type: JSX.Element | string;
  url: string;
}

interface ClientListHistoryRowContainerProps {
  change: ClientListHistory;
  tz: string;
}

const ClientListHistoryRowContainer = ({
  change,
  tz,
}: ClientListHistoryRowContainerProps) => {
  const {
    client,
  } = change;
  const clientName = `${client.firstName} ${client.lastName}`;
  return (
    <TableRow>
      <BodyCell>
        {clientName}
      </BodyCell>
      <BodyCell>
        {change.change}
      </BodyCell>
      <BodyCell>
        {change.source}
      </BodyCell>
      <BodyCell>
        {change.afterTotal}
      </BodyCell>
      <BodyCell>
        {localParseFormat(change.created, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
    </TableRow>
  );
};

const MessageRowContainer = ({
  message,
  tz,
  select,
  isSelected,
  onDelete,
  openAuto,
  onCopy,
  template,
  campaignId,
}: MessageRowContainerProps) => {
  const classes = useStyles({
    numberOfTabs: null,
  });
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const {
    messageType,
    createdAt,
    email,
    sms,
    push,
    name,
    id,
    isEnabled,
  } = message;

  const { type, url } = cond([
    [has<string>('Automation'), (): TypeUrl => ({
      type: (
        <a
          onClick={() => {
            openAuto({ id, name });
          }}>
          Automations
        </a>),
      url: template ? `/template/edit/${id}?campaignId=${campaignId}` : `/automation/view/${id}`
    }
    )],
    [has('OneTime'), () => ({
      type: 'One Time',
      url: `/messages/view/${id}`,
    })],
    [has('Recall'), () => ({
      type: 'Reactivation',
      url: `/reactivation/view/${id}`,
    })],
    [has('Birthday'), () => ({
      type: 'Birthday',
      url: `/birthday/view/${id}`,
    })],
    [has('Recurring'), () => ({
      type: 'Reminder',
      url: `/recurring/view/${id}`,
    })],
    [has('ApptChange'), () => ({
      type: 'Rapid',
      url: `/apptchange/view/${id}`,
    })],
  ])(messageType);
  const nameClick = () => {
    dispatch(messagesPatch('templateType', 'Automation'));
  };
  return (
    <TableRow
      key={id}
      hasSelect
      checked={Boolean(selected)}
      setChecked={(value) => {
        setSelected(value);
        select(value);
      }}
    >
      <BodyCell>
        <Link to={url} onClick={nameClick}>
          {name}
        </Link>
        &nbsp;
        <>
          ({type})
        </>
      </BodyCell>
      <BodyCell>
        <Checkbox
          checked={isEnabled}
          disabled
        />
      </BodyCell>
      <BodyCell>
        <span style={{ display: 'flex', width: '100%', height: '100%' }}>
          {sms &&
            <span className={classes.iconSpan}>
              <ChatBubbleIcon style={{ fontSize: '20px' }} />
            </span>}
          {email &&
            <span className={classes.iconSpan}>
              <EmailIcon style={{ fontSize: '20px' }} />
            </span>}
          {push &&
            <span className={classes.iconSpan}>
              <SmartphoneIcon style={{ fontSize: '20px' }} />
            </span>}
        </span>
      </BodyCell>
      <BodyCell>
        {tzParseFormat(createdAt, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        <Tooltip arrow title='Copy Message' placement='top'>
          <IconButton onClick={onCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Delete Message' placement='top'>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

interface CampaignProps {
  template?: boolean;
}

interface SearchClients {
  id?: number;
  perPage?: number;
  page?: number;
}

const defaultQuery = {
  data: [] as any[],
  totalPages: 1,
  totalCount: 0,
  page: 1,
  perPage: 25,
};

let clientTimeout: NodeJS.Timeout = null;
let messageTimeout: NodeJS.Timeout = null;
let historyTimeout: NodeJS.Timeout = null;

const Campaign = ({ template }: CampaignProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [messageQuery, setMessageQuery] = useState({
    name: '',
  });
  const [automationsQuery, setAutomationsQuery] = useState({
    enabled: null,
    trigger: '',
    hidden: false,
  });
  const today = LocalDate.now();
  const [historyQuery, setHistoryQuery] = useState<actions.Between>({
    start: format(today.minusMonths(1), 'yyyy-MM-dd'),
    end: format(today, 'yyyy-MM-dd'),
  });
  const [topHeight, setTopHeight] = React.useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [listId, setListId] = useState(0);
  const [state, setState] = useState('LIST');
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [tab, setTab] = useState('messages');
  const [time, setTime] = useState(0);
  const [auto, setAuto] = useState(null);
  const [makeNewAutomation] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const editRef = useRef(null);

  const clientListState = usePromise(actions.getClientList, {});
  const clientListClientsState = usePromise(actions.getClientListClients, { data: [] });
  const addClientsState = usePromise(actions.addClients, {});
  const removeClientsState = usePromise(actions.removeClients, []);
  const saveCampaignState = usePromise(actions.saveCampaign, {});
  const messagesState = usePromise(actions.getMessages, {});
  const automationsState = usePromise(actions.getAutomations, defaultQuery);
  const historyState = usePromise(actions.getHistory, defaultQuery);
  const deleteMessagesState = usePromise(actions.deleteMessages, [[]]);
  const createPathState = usePromise(actions.createPath, null);

  useTitle('Campaign');

  const {
    office, hasFeature, isAdmin, hasVital, creatingPath, headerHeight,
  } = useSelector((state) => ({
    office: state.login.office,
    hasFeature: includes('BasicAutomatedCampaigns', pathOr([], ['login', 'features'], state)),
    isAdvanced: includes('AdvancedAutomatedCampaigns', pathOr([], ['login', 'features'], state)),
    isAdmin: state.login.admin,
    hasVital: includes('VitalSigns', pathOr([], ['login', 'features'], state)),
    creatingPath: state.templateEdit.creatingPath,
    headerHeight: state.login.headerHeight,
  }));

  const classes = useStyles({ numberOfTabs: hasVital ? 4 : 3 });

  const clientListData = clientListClientsState.data.data;
  const messagesData = messagesState.data.data;
  const automationsData = automationsState.data.data;
  const historyData = historyState.data.data;

  const currentTabData = cond([
    [equals('messages'), () => messagesState.data],
    [equals('clients'), () => clientListClientsState.data],
    [equals('automations'), () => automationsState.data],
    [equals('history'), () => historyState.data],
  ])(tab);

  const currentPerPage: number = useMemo(() => {
    return propOr(25, 'perPage', currentTabData);
  }, [currentTabData]);


  const params = useParams();
  const urlId = params.id ? Number(params.id) : 0;

  const { page } = currentTabData;

  const emptyCampaignError = useMemo(() => {
    if (isFirstLoad || (clientListClientsState.loading && automationsState.loading) || template) {
      return null;
    }
    if (isEmpty(clientListData) && isEmpty(automationsData)) {
      return 'There are no clients and no automations in this campaign!';
    } else if (isEmpty(clientListData)) {
      const addsNewClients = automationsData && automationsData
        .find(({ actions }: Automation) => {
          return actions && actions.find((mAct) => {
            const action = has('action', mAct) ? mAct.action : mAct;
            return ifElse(
              has('ClientList'),
              pathOr(false, ['ClientList', 'change', 'Add']),
              always(false)
            )(action);
          });
        });
      if (!addsNewClients) {
        return 'There are no clients and no automations that add in new clients! In order for these messages to be send, you\'ll need to manually add clients or add an automation to add them in for you.';
      } else {
        return 'There are no clients in this campaign!';
      }
    } else if (isEmpty(automationsData)) {
      return 'There are no automations in this campaign!';
    }
    return null;
  }, [isFirstLoad, clientListData, automationsData]);

  const searchClients = ({ id, perPage = currentPerPage, page = 1 }: SearchClients) => {
    const splitUp = search.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
    const phone = splitUp[2] ? splitUp[2].trim() : undefined;
    const query = {
      client: {
        firstName,
        lastName,
        phone,
      },
      listId: id ? id : listId,
    };
    clearTimeout(clientTimeout);
    clientTimeout = setTimeout(() => {
      clientListClientsState.invoke({
        page,
        perPage,
        query,
      }).then((client) => {
        setIsFirstLoad(false);
        return client;
      });
    }, time);
  };

  const searchMessages = ({ perPage = currentPerPage, page = 1 }) => {
    clearTimeout(messageTimeout);
    messageTimeout = setTimeout(() => {
      messagesState.invoke({
        page,
        perPage,
        query: {
          campaignId: clientListState.data.campaign.id,
          ...messageQuery,
        },
        template,
      });
    }, time);
  };

  const searchHistory = ({ perPage = currentPerPage }) => {
    clearTimeout(historyTimeout);
    historyTimeout = setTimeout(() => {
      historyState.invoke({
        page: 1,
        perPage,
        query: {
          listId: clientListState.data.campaign.listId,
          between: {
            ...historyQuery,
          },
        }
      });
    }, time);
  };

  const exportHistory = () => {
    actions.exportHistory({
      page: 1,
      perPage: 1000,
      query: {
        listId: clientListState.data.campaign.listId,
        between: {
          ...historyQuery,
        },
      }
    }, clientListState.data.name + ' - export');
  };

  const searchAutomations = ({ id = false, perPage = currentPerPage }) => {
    const clientListId = id ? id : clientListState.data.id;
    automationsState.invoke({
      page,
      perPage,
      query: {
        ...automationsQuery,
        campaignId: clientListState.data.campaign.id,
        action: {
          clientListId,
        },
        filter: {
          client: {
            clientListId,
          },
        },
        trigger: {
          clientListId,
          trigger: cond([
            [equals('Appointment'), always('ApptUpdated')],
            [equals('Campaign'), always('ClientList')],
            [equals('Message Received'), always('MessageReceived')],
            [equals('Time'), always('Time')],
            [equals('Date & Time'), always('DateTime')],
            [T, always(undefined)]
          ])(automationsQuery.trigger)
        },
      },
      template,
    }).then((auto) => {
      setIsFirstLoad(false);
      return auto;
    });
  };

  const searchFunction = (props: { perPage?: number, page?: number }): void => {
    switch (tab) {
      case 'messages':
        return searchMessages(props);
      case 'clients':
        return searchClients(props);
      case 'automations':
        return searchAutomations(props);
      default:
        return;
    }
  };

  useEffect(() => {
    const params = useParams();
    const id = Number(params.id);
    if (id !== 0) {
      setListId(id);
      clientListState.invoke({ listId: id, template });
    }
    dispatch({
      type: RECURRING_EDIT_RESET,
    });
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (state === 'EDIT' && editRef.current) {
      editRef.current.focus();
      editRef.current.select();
    }
  }, [state]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchClients({ id: clientListState.data.id });
      searchMessages({});
      searchAutomations({ id: clientListState.data.id });
      searchHistory({});
      setTime(500);
    }
  }, [clientListState.data]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchClients({});
    }
  }, [search]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchMessages({});
    }
  }, [messageQuery]);

  useEffect(() => {
    if (!creatingPath && clientListState.data.campaign)
      searchMessages({});
  }, [creatingPath]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchAutomations({});
    }
  }, [automationsQuery]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchHistory({});
    }
  }, [historyQuery]);

  if (!hasFeature && !isAdmin) {
    return (
      <FeatureDisabled title="Campaign">
        <p>
          Tell me something about Campaign
        </p>
      </FeatureDisabled>
    );
  }

  const { campaign } = clientListState.data;

  const {
    displayName,
    description,
  } = campaign ? campaign : {
    displayName: undefined,
    description: undefined,
  };

  const handleTextChange = (value: string, type: string) => {
    cond([
      [equals('client'), () => {
        setSearch(value);
      }],
      [equals('message'), () => {
        setMessageQuery({ ...messageQuery, name: value });
      }],
    ])(type);
  };

  const saveClientList = () => {
    saveCampaignState.invoke({
      displayName: editName,
      description: editDescription,
      campaignId: campaign.id,
      template,
    }).then(() => {
      setState('LIST');
      clientListState.invoke({ listId, template, });
    });
  };

  const handledEditKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      saveClientList();
    }
  };

  const addPage = (num: number) => () => {
    searchFunction({ page: page + num });
  };

  const onPerPageChange = (value: number) => {
    searchFunction({ perPage: Number(value) });
  };

  const cantSave = () => (innerState: AutomationsState) => {
    const compareFunction = template ?
      has('campaign') :
      pipe(
        pathOr(0, ['campaign', 'listId']),
        equals(clientListState.data.id)
      );
    const {
      trigger,
      triggerData,
      filters,
      actions,
    } = innerState;
    const triggerHasList = trigger === 'Campaign' &&
      compareFunction(triggerData);
    const filterHasList = pipe(
      filter(({ filter, filterData }) => {
        return filter === 'client' &&
          (filterData.verb === 'is in' || filterData.verb === 'was added to') &&
          compareFunction(filterData);
      }),
      isEmpty,
      not
    )(filters);
    const actionHasList = pipe(
      filter(({ action, actionData }) => {
        return action === 'Campaign' &&
          has('change', actionData) &&
          compareFunction(actionData);
      }),
      isEmpty,
      not
    )(actions);
    return !any(identity, [triggerHasList, filterHasList, actionHasList]) &&
      'It is required that you use the current campaign in this automation!';
  };

  const saveAutomation = (idx: number) => (a: Automation) => {
    if (template)
      createPathState.invoke({
        id: a.id,
        campaignId: campaign.id,
        resourceType: 'Automation',
      });
    automationsState.setState({
      ...automationsState,
      data: {
        ...automationsState.data,
        data: update(idx, a, automationsData)
      }
    });
  };

  const clickCompose = () => {
    dispatch(automationsPatch({ automations: { data: [] } }));
    if (template) {
      dispatch(messagesPatch('templateType', 'Automation'));
    }
    navigate(`${template ? '/template/edit' : '/automation/edit'}/0?campaignId=${propOr(0, 'id', campaign)}`);
  };

  const tabs = [
    { label: 'Messages', value: 'messages' },
    { label: 'Clients', value: 'clients' },
    { label: 'Automations', value: 'automations' }
  ];

  if (hasVital) {
    tabs.push({ label: 'Client History', value: 'history' });
  }

  return (
    <>
      <Header title='Campaign'
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={setTab}
            tabs={tabs}
          />,
          <Grid marginRight="6px" />,
          tab === 'automations' ?
            <FilterSelect
              title='Enabled'
              Icon={EnabledIcon}
              items={enabledOptions.map(item => item.name)}
              selected={enabledOptions.find(item => item.value === automationsQuery.enabled).name}
              setSelected={(value) => {
                const item = enabledOptions.find(item => item.name === value);
                setAutomationsQuery({
                  ...automationsQuery,
                  enabled: item.value,
                });
              }}
            /> : <div />,
          tab === 'automations' ?
            <FilterSelect
              title='Trigger'
              noneText='All'
              Icon={TriggerIcon}
              items={triggers.map(item => item === '' ? 'All' : item)}
              selected={automationsQuery.trigger || 'All'}
              setSelected={(value) => {
                setAutomationsQuery({
                  ...automationsQuery,
                  trigger: value === 'All' ? '' : value
                });
              }}
            /> : <div />
        ]}
        rightIcons={
          cond([
            [equals('messages'), () => ([
              <FilterText
                value={messageQuery.name}
                headerTitle='Search'
                placeholder='Message Name'
                onChange={(value) => handleTextChange(value, 'message')}
              />,
              <HeaderButton
                onClick={clickCompose}
                Icon={AddIcon}
                title={`New Message ${template ? 'Template' : ''}`}
              />
            ])],
            [equals('clients'), () => ([
              <FilterText
                value={search}
                headerTitle='Search'
                placeholder='LastName FirstName'
                onChange={(value) => handleTextChange(value, 'client')}
              />,
              <HeaderButton
                disabled={template}
                Icon={AddIcon}
                onClick={() => setState('ADD')}
                title='Add Clients'
              />
            ])],
            [equals('automations'), () => ([
              <HeaderButton active={automationsQuery.hidden} type='hide' borderSolid>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={automationsQuery.hidden}
                      onChange={e => setAutomationsQuery({ ...automationsQuery, hidden: e.target.checked })}
                      name="acknowledge-checkbox"
                    />
                  }
                  label='Show Hidden'
                />
              </HeaderButton>,
              <HeaderButton
                disabled={Boolean(automationsData.find(({ id }) => id === 0))}
                Icon={AddIcon}
                onClick={(e) => {
                  if (isAdmin) {
                    setMenuAnchorEl(e.currentTarget);
                  } else {
                    setOpenTemplateDialog(true);
                  }
                }}
                title={`Add Automation ${template ? 'Template' : ''}`}
              />
            ])],
            [equals('history'), () => ([
              <FilterStartEndDate dateRange={historyQuery} setDateRange={setHistoryQuery} />,
              <HeaderButton title='Export History' Icon={ImportExportIcon} onClick={exportHistory} />
            ])]
          ])(tab)
        }
        smallTabsWidth={tab === 'automations' ? 980 : 660}
        onlyIconsWidth={tab === 'automations' ? 785 : 480}
        breakPoints={[
          { width: tab === 'automations' ? 547 : 380, mobileItems: [2, 3, 4] },
          // { width: 400, mobileItems: [0, 2, 3, 4] }
        ]}
      />
      <div style={{ padding: '20px', paddingTop: 0 }}>
        <div>
          <PopupTemplate />
          <SearchClientsDialog
            isOpen={state === 'ADD'}
            close={() => setState('LIST')}
            ehrSystem={office.ehrSystem}
            loading={addClientsState.loading}
            save={(clients: Client[]) => {
              const clientIds = clients.map(({ id }) => id);
              addClientsState.invoke({ listId, clientIds }).then(() => {
                searchClients({});
                setState('LIST');
              });
            }}
          />
          <AutomationDialog
            isOpen={state === 'VIEW_AUTO'}
            onClose={() => setState('LIST')}
            defaultCampaign={clientListState.data}
            message={auto}
            makeNewAutomation={makeNewAutomation}
            template={template}
            campaignId={clientListState.data?.campaign?.id}
          />
          <AutomationTemplateDialog
            open={openTemplateDialog}
            onClose={() => setOpenTemplateDialog(false)}
            initFolder='automations.Campaigns'
            onSelect={(auto) => {
              setOpenTemplateDialog(false);
              if (automationsData)
                automationsState.setState({
                  ...automationsState,
                  data: {
                    ...automationsState.data,
                    data: prepend({ ...auto, isEdit: true, id: 0, enabled: false }, automationsData),
                  },
                });
            }}
          />
          <Container getHeight={setTopHeight}>
            <Row
              className={classes.headerRow}
              style={{
                justifyContent: 'flex-start',
                marginBottom: 'unset',
              }}>
              <PageHeader align="left" variant="h5">
                {(displayName && state !== 'EDIT') && <b>{displayName}</b>}
                {(!displayName && state !== 'EDIT') && <b>Campaign</b>}
              </PageHeader>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
                {state === 'EDIT' &&
                  <TextField
                    inputRef={editRef}
                    value={editName}
                    label='Campaign Name'
                    placeholder='The Most Amazing Campaign'
                    onChange={(e) => setEditName(e.target.value)}
                    onKeyPress={handledEditKeyPress}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />}
                &nbsp;
                {state === 'EDIT' && !saveCampaignState.loading &&
                  <Tooltip arrow title='Save'>
                    <IconButton onClick={saveClientList}>
                      <SaveIcon fontSize={'small'} />
                    </IconButton >
                  </Tooltip >}
                {
                  state === 'EDIT' && !saveCampaignState.loading &&
                  <Tooltip arrow title='Cancel Edit'>
                    <IconButton onClick={() => setState('LIST')}>
                      <CancelIcon fontSize={'small'} />
                    </IconButton >
                  </Tooltip >}
                {
                  state === 'LIST' && !saveCampaignState.loading &&
                  <Tooltip arrow title='Edit List Name'>
                    <IconButton onClick={() => {
                      setEditName(displayName);
                      setEditDescription(description);
                      setState('EDIT');
                    }}>
                      <EditIcon fontSize={'small'} />
                    </IconButton >
                  </Tooltip >}
                {
                  (saveCampaignState.loading || clientListState.loading) &&
                  <CircularProgress size={20} />
                }
              </div >
            </Row >
            <Row className={classes.headerRow} style={{ alignItems: 'flex-start' }}>
              {(description && state !== 'EDIT') && description}
              {state === 'EDIT' &&
                <TextField
                  value={editDescription}
                  label='Description'
                  placeholder='Describe this campaign...'
                  onChange={(e) => setEditDescription(e.target.value)}
                  onKeyPress={handledEditKeyPress}
                  style={{ width: '350px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />}
            </Row>
            {
              emptyCampaignError &&
            <p className={classes.errorMessage}>{emptyCampaignError}</p>
            }
          </Container>
          <div className={classes.tabBodyContainer} hidden={tab !== 'history'}>
            {(clientListState.loading || historyState.loading) &&
            <CircularProgress />}
            {!historyState.loading &&
            <TableContainer
              className={classes.noHorizontalMargins}
              maxHeight={`calc(100vh - ${headerHeight}px - ${topHeight}px - 45px)`}
              pagination
              paginationData={{
                addPage: (value) => addPage(value)(),
                currentLength: currentTabData.data?.length,
                page: currentTabData.page,
                perPage: currentPerPage,
                setPerPage: onPerPageChange,
                totalCount: currentTabData.totalCount,
                totalPages: currentTabData.totalPages,
              }}
            >
              <TableHead>
                <TableRow>
                  <HeaderCell>
                    Client Name
                  </HeaderCell>
                  <HeaderCell>
                    Change
                  </HeaderCell>
                  <HeaderCell>
                    Source
                  </HeaderCell>
                  <HeaderCell>
                    Total
                  </HeaderCell>
                  <HeaderCell>
                    Created
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyData &&
                    historyData.map((h: ClientListHistory) => (
                      <ClientListHistoryRowContainer
                        key={h.client?.id + h.change + h.source}
                        change={h}
                        tz={office.timezone}
                      />
                    ))}
              </TableBody>
            </TableContainer>
            }
          </div>
          <div className={classes.tabBodyContainer} hidden={tab !== 'messages'}>
            {(clientListState.loading || messagesState.loading) &&
              <Loading loading />}
            {!messagesState.loading &&
              <TableContainer
                className={classes.noHorizontalMargins}
                maxHeight={`calc(100vh - ${headerHeight}px - ${topHeight}px - 45px)`}
                pagination
                paginationData={{
                  addPage: (value) => addPage(value)(),
                  currentLength: currentTabData.data?.length,
                  page: currentTabData.page,
                  perPage: currentPerPage,
                  setPerPage: onPerPageChange,
                  totalCount: currentTabData.totalCount,
                  totalPages: currentTabData.totalPages,
                }}
              >
                <TableHead>
                  <TableRow
                    hasSelect
                    noSelectHover
                    checked={messagesData?.length && selected.length === messagesData?.length}
                    someChecked={selected.length > 0 && selected.length < messagesData?.length}
                    setChecked={(checked) => {
                      setSelectAll(checked);
                      if (checked) {
                        setSelected(map(prop('id'), messagesData));
                      } else {
                        setSelected([]);
                      }
                    }}
                  >
                    <HeaderCell style={{ position: 'relative' }}>
                      {!isEmpty(selected) && (
                        <Grid position="absolute" display="flex" alignItems="center" width="80%" top={0} left={8} style={{ background: '#F5F5F5' }}>
                          <IconButton
                            onClick={() => {
                              popupWithCancel(
                                'Are you sure you want to delete?',
                                'This action cannot be undone!',
                                () => deleteMessagesState.invoke({
                                  ids: selected,
                                  template,
                                }).then(() => {
                                  searchMessages({});
                                  setSelected([]);
                                  setSelectAll(false);
                                }),
                                'danger'
                              );
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                        Name
                    </HeaderCell>
                    <HeaderCell>
                        Enabled
                    </HeaderCell>
                    <HeaderCell>
                        Send As
                    </HeaderCell>
                    <HeaderCell>
                        Created
                    </HeaderCell>
                    <HeaderCell>
                        Actions
                    </HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messagesData &&
                      messagesData.map((s: any) => (
                        <MessageRowContainer
                          key={s.id}
                          message={s}
                          tz={office.timezone || 'America/New_York'}
                          isSelected={find(equals(s.id), selected)}
                          template={template}
                          campaignId={clientListState.data.id}
                          select={(isSelected) => {
                            if (isSelected) {
                              setSelected(append(s.id, selected));
                            } else {
                              setSelected(without([s.id], selected));
                              if (selectAll) {
                                setSelectAll(false);
                              }
                            }
                          }}
                          onDelete={() => {
                            popupWithCancel(
                              'Are you sure you want to delete?',
                              'This action cannot be undone!',
                              () => deleteMessagesState.invoke({
                                ids: [s.id],
                                template,
                              }).then(() => {
                                searchMessages({});
                              }),
                              'danger'
                            );
                          }}
                          onCopy={() => {
                            dispatch(copyMessage('COPY_AUTOMATION', false, s));
                            navigate('/automation/edit/0');
                          }}
                          openAuto={(msg) => {
                            setAuto(msg);
                            setState('VIEW_AUTO');
                          }}
                        />
                      ))}
                </TableBody>
              </TableContainer>
            }
          </div>
          <div className={classes.tabBodyContainer} hidden={tab !== 'clients'}>
            {template && <div>You can't add clients to a campaign template.</div>}
            {!template && <>
              {(clientListState.loading || clientListClientsState.loading) &&
                <CircularProgress />}
              {!clientListClientsState.loading &&
                <TableContainer
                  className={classes.noHorizontalMargins}
                  maxHeight={`calc(100vh - ${headerHeight}px - ${topHeight}px - 45px)`}
                  pagination
                  paginationData={{
                    addPage: (value) => addPage(value)(),
                    currentLength: currentTabData.data?.length,
                    page: currentTabData.page,
                    perPage: currentPerPage,
                    setPerPage: onPerPageChange,
                    totalCount: currentTabData.totalCount,
                    totalPages: currentTabData.totalPages,
                  }}
                >
                  <TableHead>
                    <TableRow
                      hasSelect
                      noSelectHover
                      checked={clientListData.length && selected.length === clientListData?.length}
                      someChecked={selected.length > 0 && selected.length < clientListData?.length}
                      setChecked={(checked) => {
                        setSelectAll(checked);
                        if (checked) {
                          setSelected(map(path(['client', 'id']), clientListData));
                        } else {
                          setSelected([]);
                        }
                      }}
                    >
                      <HeaderCell style={{ position: 'relative' }}>
                        {!isEmpty(selected) && (
                          <Grid position="absolute" display="flex" alignItems="center" width="80%" top={0} left={8} style={{ background: '#F5F5F5' }}>
                            <IconButton
                              onClick={() => {
                                removeClientsState.invoke({
                                  clientIds: selected,
                                  listId,
                                }).then(() => {
                                  searchClients({});
                                  setSelected([]);
                                  setSelectAll(false);
                                });
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                          Name
                      </HeaderCell>
                      <HeaderCell>
                          Birthday
                      </HeaderCell>
                      <HeaderCell>
                          Phone
                      </HeaderCell>
                      <HeaderCell>
                          Email
                      </HeaderCell>
                      <HeaderCell>
                          Add to List
                      </HeaderCell>
                      <HeaderCell>
                          Opted Out
                      </HeaderCell>
                      <HeaderCell>
                          Actions
                      </HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      clientListData.map((s: ClientWithList) => (
                        <RowContainer
                          key={s.client.id}
                          clientWithList={s}
                          tz={office.timezone}
                          isSelected={find(equals(s.client.id), selected)}
                          urlId={urlId}
                          pageType={'Campaign'}
                          select={(isSelected) => {
                            if (isSelected) {
                              setSelected(append(s.client.id, selected));
                            } else {
                              setSelected(without([s.client.id], selected));
                              if (selectAll) {
                                setSelectAll(false);
                              }
                            }
                          }}
                          onDelete={() => {
                            removeClientsState.invoke({
                              clientIds: [s.client.id],
                              listId,
                            }).then(() => {
                              searchClients({});
                            });
                          }}
                        />
                      ))
                    }
                  </TableBody>
                </TableContainer>
              }
            </>
            }
          </div >
          <div
            className={`${classes.tabBodyContainer} ${classes.noHorizontalMargins}`}
            hidden={tab !== 'automations'}
            style={{
              overflowY: 'auto',
              maxHeight: `calc(100vh - ${headerHeight}px - ${topHeight}px - 90px)`,
            }}
          >
            {(clientListState.loading || automationsState.loading) &&
              <CircularProgress />}
            {!automationsState.loading &&
              <AutomationsList
                automations={automationsData}
                defaultCampaign={clientListState.data}
                isTemplate={template}
                onSave={saveAutomation}
                cantSave={cantSave}
                removeAutomation={(idx) => {
                  automationsState.setState({
                    ...automationsState,
                    data: {
                      ...automationsState.data,
                      data: remove(idx, 1, automationsData)
                    }
                  });
                }}
              />
            }
          </div>
        </div>
        <Menu
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          id="long-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            automationsState.setState({
              ...automationsState,
              data: {
                ...automationsState.data,
                data: prepend({
                  id: 0,
                  actions: [],
                  trigger: {
                    Dummy: []
                  },
                  enabled: false,
                  isEdit: true,
                  hidden: false,
                }, automationsData)
              }
            });
          }}>
            <ListItemIcon>
              <AddIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Create New' />
          </MenuItem>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            setOpenTemplateDialog(true);
          }}>
            <ListItemIcon>
              <FileCopyIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='From Template' />
          </MenuItem>
        </Menu>
      </div>
      {(tab === 'automations' && !clientListState.loading && !messagesState.loading) && (
        <Pagination
          addPage={(value) => addPage(value)()}
          currentLength={currentTabData.data?.length}
          page={currentTabData.page}
          perPage={currentPerPage}
          setPerPage={onPerPageChange}
          totalCount={currentTabData.totalCount}
          totalPages={currentTabData.totalPages}
        />
      )}
    </>
  );
};

export default Campaign;
