import React, { ComponentProps } from 'react';

const CheckedIcon = ({ color, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.00229 13.9998C3.22558 14.0287 -0.130698 10.8813 0.00391547 6.77094C0.131387 2.87866 3.36827 -0.016453 7.04887 7.03704e-05C10.7971 0.0168973 14.1046 3.15966 13.9975 7.20791C13.8992 10.9217 10.8173 14.0155 7.00229 13.9998ZM7.65447 8.41249C7.66255 8.36083 7.67348 8.31207 7.67737 8.26274C7.70826 7.87103 7.84651 7.53111 8.14074 7.25941C8.37834 7.04002 8.6134 6.81743 8.84103 6.58778C9.16908 6.25684 9.39246 5.87614 9.45054 5.39759C9.57692 4.35632 9.02089 3.49557 8.00823 3.22303C7.41397 3.06309 6.80877 3.06118 6.2075 3.17888C5.79423 3.25978 5.4273 3.44181 5.12221 3.74494C4.72491 4.13968 4.55422 4.62183 4.52268 5.16927C4.51558 5.29248 4.54394 5.32353 4.66798 5.32207C5.18261 5.31602 5.69741 5.31913 6.21206 5.32602C6.30474 5.32725 6.30453 5.27791 6.30983 5.21683C6.32639 5.02593 6.38788 4.85178 6.51409 4.70572C6.68911 4.50318 6.91418 4.44964 7.16942 4.49905C7.42654 4.54882 7.57582 4.71207 7.63311 4.96019C7.70138 5.25588 7.6889 5.55158 7.49671 5.79508C7.30617 6.03649 7.09731 6.26976 6.86576 6.47131C6.26895 6.99079 6.1643 7.6729 6.17131 8.41249H7.65447ZM6.93253 9.13359C6.89156 9.13359 6.85039 9.13097 6.80964 9.13398C6.38754 9.16522 6.03451 9.48097 5.9798 9.87512C5.92158 10.2946 6.14058 10.6786 6.54253 10.8102C6.7069 10.8639 6.89197 10.8847 7.06523 10.8755C7.63419 10.8453 8.00052 10.3729 7.89658 9.82766C7.81553 9.40249 7.43779 9.12907 6.93253 9.13359Z" fill={color || '#ABABAE'}/>
    </svg>

  );
};

export default CheckedIcon;
