import React from 'react';
import {
  Popover, Grid, Typography, IconButton, Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useStyles } from '../../../../components/Modal/modal.styles';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { TextField } from '../../../../components/components.component';
import { LocalDateTime, LocalTime } from '@js-joda/core';
import { format } from '../../../../services/joda.js';
import AirplaneClockIcon from '../../../../icons/AirplaneWithClock.icon';

export const useLocalStyles = makeStyles(() => ({
  vivabarneslaw: {
    marginBottom: 7,
  },
  quickButtons: {
    marginBottom: 7,
  },
  time: {
    color: '#ABABAE !important',
  },
}));

interface ScheduleModalProps {
  open: boolean;
  anchorEl: HTMLDivElement,
  onClose: () => void;
  onSave: (dt: string) => void;
  scheduledFor: string;
}

const now = LocalDateTime.now();
const nowish = format(now.plusHours(2), 'yyyy-MM-dd\'T\'HH:mm');
const nowdate = format(now, 'yyyy-MM-dd');
const ScheduleModal = ({
  open, anchorEl, onClose, scheduledFor, onSave
}: ScheduleModalProps) => {
  const classes = useStyles();
  const locals = useLocalStyles();
  const [datetime, setDateTime] = React.useState(nowish);
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setDateTime(scheduledFor || nowish);
    }
  }, [open, scheduledFor]);

  const save = () => {
    onSave(datetime);
    onClose();
  };

  const [date, time] = React.useMemo(() => {
    const [d, t] = datetime.split('T');
    const [h, m] = t.split(':');
    return [d, `${h}:${m}`];
  }, [datetime]);

  const quickTimes = React.useMemo(() => {
    if (datetime) {
      const tom = now.plusDays(1);
      const ftom = format(tom, 'yyyy-MM-dd');
      return [`${nowdate}T18:00`, `${ftom}T08:30`, `${ftom}T12:30`];
    }
    return [];
  }, [date]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateortime = e.target.value;
    console.log(dateortime);
    if (!dateortime) {
      setDateTime(`${date}T${time}`);
      setDisable(true);
      return;
    }
    if (dateortime.includes(':')) {
      setDateTime(`${date}T${dateortime}`);
    } else {
      setDateTime(`${dateortime}T${time}`);
    }
    setDisable(false);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{ paper: classes.paper }}
      PaperProps={{ style: { width: 325, } }}
    >
      <Grid paddingX={2} className={classes.header}>
        <Grid display="flex" alignItems="center">
          <Typography className={classes.title}>Schedule SMS</Typography>
        </Grid>
        <Grid display="flex" alignItems="center">
          <HeaderButton
            title='Send Later'
            onClick={save}
            color='primary'
            className='sked-test-scheduled-sms-modal-button-send-later'
            Icon={() => <AirplaneClockIcon color="white" />}
            disabled={disable}
          />
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={`${classes.closeButton} sked-test-inbox-scheduled-sms-button-close`}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        paddingX={2}
        paddingTop={2}
        marginBottom={1}
        overflow="auto"
      >
        <TextField
          fullWidth
          type='date'
          value={date}
          className={locals.vivabarneslaw}
          onChange={onChange}
        />
        <TextField
          fullWidth
          type='time'
          value={time}
          className={locals.vivabarneslaw}
          onChange={onChange}
        />
      </Grid>
      <Grid
        container
        spacing={1}
        paddingX={1}
        marginBottom={2}
        overflow="auto"
        justifyContent="center"
        height={68}
      >
        {quickTimes.map((qdatetime) => {
          const [qdate, qtime] = qdatetime.split('T');
          const day = qdate === nowdate ? 'Tonight' : 'Tomorrow';
          const jtime = LocalTime.parse(qtime);
          const nicetime = format(jtime, 'h:mm a');
          const selected = qdatetime === datetime;
          const disabled = day === 'Tonight' && LocalTime.now().isAfter(jtime);
          let color = selected ? '#24A0F6' : '#3F4346';
          if (disabled) {
            color = '#a7a7ab';
          }
          return (
            <Grid
              item
              key={qdatetime}
              onClick={() => !disabled && setDateTime(qdatetime)}
            >
              <Paper sx={{
                height: 50,
                width: 91,
                backgroundColor: selected ? '#ECF4FF' : '#f5f5f5',
                border: 'transparent',
                color,
                fontSize: 14,
                cursor: disabled ? 'default' : 'pointer',
                boxShadow: selected ? '0 0 0 1.5px rgba(36, 160, 246, 0.25)' : 'unset',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': disabled ? {} : {
                  backgroundColor: '#ECF4FF',
                  color: '#24A0F6',
                },
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}>
                  <b style={{ fontWeight: 500 }}>{day}</b>
                  <span className={locals.time}>
                    {nicetime}
                  </span>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Popover>
  );
};

export default ScheduleModal;
