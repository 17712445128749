import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePromise } from './../../services/promise.hook';
import api from '../../services/api.js';
import {
  IconButton, Tooltip, Menu, MenuItem, DialogContentText, TextField, Divider,
  CircularProgress, ListItemIcon, ListItemText, List, ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/MoreVert';
import { DriveFileMoveIcon, FolderOffIcon } from '../../icons/CustomIcons';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import {
  isNil, adjust, evolve, remove, isEmpty, pathOr, includes, dropLast
} from 'ramda';
import { Row } from '../PageHeader';
import Modal from '../Modal/Modal.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { successSnackbar, errorSnackbar } from '../Snackbar/snackbar.actions';

/*
   Path/Folders shorthand dictionary:
   Message: msg
   Templates: tmp
   Automations: au
   One-Time: ot
   Recurring: rc
   Reminder: rm
   Birthday: br
   Reactivation: rv
   Rapid: rp
   ClientList: cl
   Campaign: cmp

   Automation Based Messages end with fdr for folder:
   i.e.: ['msg', 'rcr', 'smart_reply', 'fdr']
*/

interface FoldersProps {
  path: string[];
  onClick: (path: string[]) => void;
  template?: boolean;
  deselect?: boolean;
}


export interface Folder {
  path: string[],
  /* created: string, */
  /* officeId: number, */
  resource: Resource;
  pathId: number,
}

interface FolderItemProps {
  folder: Folder;
  path?: string[];
  onClick: (folder: Folder) => void;
  update?: (name: string) => void;
  template?: boolean;
  disableMenu?: boolean;
  selectedId?: number,
}

interface FetchFolderType {
  hasSubpath: string[];
  template?: boolean;
  page?: number;
  perPage?: number;
}

interface FolderQueryType {
  data: Folder[],
  page: number,
  totalPages: number,
  totalCount: number,
}

interface DialogProps {
  open: boolean;
  onClose: (reload: string | boolean) => void;
  id?: number;
  template?: boolean
  folder?: Folder;
  recursive?: boolean
}

interface MoveToFolderProps {
  open: boolean;
  path: string[];
  messages: any[];
  template?: boolean;
  onMove: (path?: string[]) => void;
}

interface PathLabel {
  created: string;
  id: number;
  label: string;
}

interface BasicResource {
  [key: string]: number;
}

interface PathLabelResource {
  PathLabel: PathLabel | number,
}

export type Resource = BasicResource | PathLabelResource;

interface CreateFolder {
  id?: number;
  path: string[];
  template?: boolean; /* Not on the server type. */
  label: string;
}

interface DeleteFolder {
  path: string[];
  template?: boolean;
  recursive?: boolean;
}

interface MoveToFolderType {
  path: string[];
  msgIds?: number[];
  template?: boolean;
}

interface CurrentPath {
  [path: string]: string[];
}
export let currentPath: CurrentPath = {};

const fetchFolders = ({ hasSubpath, template, page, perPage = 10 }: FetchFolderType): Promise<FolderQueryType> => {
  const body = {
    page: page ? page : 1,
    perPage,
    query: {
      isDirectDescendant: hasSubpath,
    },
  };
  const url = template ? 'templates/paths/query' : 'paths/query';
  return api.post(url, body);
};

const addFolder = ({ template, label, path }: CreateFolder): Promise<void> => {
  const url = template ? 'templates/paths' : 'paths';
  const labelUrl = template ? 'templates/paths/label' : 'paths/label';
  return api.post(labelUrl, { label }).then((pathLabel: PathLabel) => {
    const data = {
      resource: {
        PathLabel: pathLabel.id,
      },
      path: path.concat(['pl_' + pathLabel.id]),
    };
    return api.post(url, data);
  });
};

const editFolder = ({ label, id, template }: CreateFolder): Promise<void> => {
  const labelUrl = template ? 'templates/paths/label' : 'paths/label';
  return api.put(labelUrl + `/${id}`, { label });
};

const deleteFolder = ({ path, template, recursive }: DeleteFolder): Promise<void> => {
  const pathString = path.join('.');
  const recurse = recursive ? '/recursive' : '';
  const url = template ?
    `templates/paths/path/${pathString}` : `paths/path/${pathString}` + recurse;
  return api.delete(url, { data: {} });
};

const moveToFolder = ({ path, msgIds, template }: MoveToFolderType): Promise<any[]> => {
  const url = template ? 'templates/paths' : 'paths';
  const stringPath = dropLast(1, path).join('.');
  return Promise.all(
    msgIds.map(async (id) => {
      const resource = {
        Message: id
      };
      if (currentPath[stringPath]) {
        const oldPath = currentPath[stringPath].concat(['msg_' + id]);
        const uriPath = oldPath.join('.');
        const pathsUrl = template ? `templates/paths/path/${uriPath}` : `paths/path/${uriPath}`;
        await api.delete(pathsUrl, { data: {} });
      }
      const newPath = path.concat(['msg_' + id]);
      const uriPath = newPath.join('.');
      const pathsUrl = template ? `templates/paths/path/${uriPath}` : `paths/path/${uriPath}`;
      try {
        const p = await api.get(pathsUrl).catch(() => undefined as string);
        if (p?.id) {
          return api.delete(pathsUrl, { data: {} }).then(() => {
            return api.post(url, { path: newPath, resource });
          });
        }
        return api.post(url, { path: newPath, resource });
      } catch (e) {
        return api.post(url, { path: newPath, resource });
      }
    }));
};

const removeFromFolder = ({ path, msgIds, template }: MoveToFolderType): Promise<any[]> => {
  return Promise.all(msgIds.map((id) => {
    const newPath = path.concat(['msg_' + id]);
    const uriPath = newPath.join('.');
    const pathsUrl = template ? `templates/paths/path/${uriPath}` : `paths/path/${uriPath}`;
    return api.delete(pathsUrl, { data: {} });
  }));
};


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gridColumnGap: 3,
    margin: '2px 0px',
    minHeight: '50px',
    width: '100%',
    maxHeight: '200px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '6px',
      },
    },
  },
  folderBox: {
    height: '38px',
    position: 'relative',
    padding: '0px 0px 0px 5px',
    /* backgroundColor: 'white', */
    border: '1px solid rgba(0, 0, 0, 0.40)',
    borderRadius: '5px',
    width: '200px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px',
    '&:hover': {
      border: '1px solid black',
    },
  },
  clickablePart: {
    display: 'flex',
    alignItems: 'center',
    width: '190px',
  },
  wordPart: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 14,
  },
  otherDivs: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitles: {
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  pathListItem: {
    cursor: 'pointer',
  },
  templatePathContainer: {
    margin: '0px 10px',
  },
}));

const defaultFolderQueryData: FolderQueryType = {
  data: [],
  totalCount: 0,
  totalPages: 1,
  page: 1,
};

export const RemoveFromFolder = ({ open, path, messages, template, onMove }: MoveToFolderProps) => {
  const dispatch = useDispatch();
  const {
    hasFeature,
    isAdmin
  } = useSelector((state: any) => ({
    hasFeature: includes('Folders', pathOr([], ['login', 'features'], state)),
    isAdmin: isEmpty(state.login.office) ? state.login.admin : false,
  }));
  const removeState = usePromise<MoveToFolderType, any[]>(removeFromFolder, null);
  const stringPath = path?.join('.');
  const handleClick = () => {
    removeState.invoke({
      path: currentPath[stringPath],
      msgIds: messages.filter(({ selected }: any) => selected).map((message: any) => message.id),
      template,
    }).then(() => {
      dispatch(successSnackbar('Successfully Removed Messages!'));
      onMove && onMove(currentPath[stringPath]);
    }).catch((e) => {
      console.log(e);
      dispatch(errorSnackbar('Failed to Remove Messages!'));
    });
  };
  if (!currentPath[stringPath]) {
    return <div></div>;
  }
  if (!hasFeature && !isAdmin) {
    return (
      <div />
    );
  }
  return (
    <div>
      <Tooltip title='Remove from Folder'>
        <IconButton
          onClick={handleClick}
          style={{
            display: open ? 'flex' : 'none',
          }}
        >
          <FolderOffIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const MoveToFolder = ({ open, path, messages, template, onMove }: MoveToFolderProps) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    hasFeature,
    isAdmin,
  } = useSelector((state: any) => ({
    hasFeature: includes('Folders', pathOr([], ['login', 'features'], state)),
    isAdmin: isEmpty(state.login.office) ? state.login.admin : false,
  }));
  const foldersState = usePromise<FetchFolderType, FolderQueryType>(fetchFolders, defaultFolderQueryData);
  const moveState = usePromise<MoveToFolderType, any[]>(moveToFolder, null);

  useEffect(() => {
    if (open || anchorEl) {
      foldersState.invoke({
        hasSubpath: path,
        template,
        perPage: 100,
      });
    }
  }, [open, anchorEl, path]);

  const handleFolderMove = (path: string[]) => () => {
    setAnchorEl(null);
    const curPath = currentPath[dropLast(1, path).join('.')];
    moveState.invoke({
      path,
      msgIds: messages.filter(({ selected }: any) => selected).map((message: any) => message.id),
      template,
    }).then(() => {
      dispatch(successSnackbar('Successfully Moved Messages!'));
      onMove(curPath);
    }).catch((e) => {
      console.log(e);
      if (e?.response?.status === 404) {
        dispatch(successSnackbar('Successfully Moved Messages!'));
        onMove(curPath);
      } else {
        dispatch(errorSnackbar('Failed to Moved Messages!'));
      }
    });
  };

  if (!hasFeature && !isAdmin) {
    return (
      <div />
    );
  }

  return (
    <div>
      <Tooltip title='Move to Folder'>
        <IconButton>
          {moveState.loading ?
            <CircularProgress size={20} />
            :
            <DriveFileMoveIcon onClick={(e) => setAnchorEl(e.currentTarget)} />}
        </IconButton>
      </Tooltip>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem disabled={true}>
          Select Folder
        </MenuItem>
        <Divider />
        {foldersState.data.data.map(({ resource, path, pathId }: Folder) => {
          if (typeof resource?.PathLabel === 'number')
            return;
          const name = resource?.PathLabel?.label;
          return (
            <MenuItem
              key={pathId}
              onClick={handleFolderMove(path)}>
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const DeleteDialog = ({ open, onClose, folder, template, recursive }: DialogProps) => {
  const deleteState = usePromise<DeleteFolder, void>(deleteFolder, null);
  const handleDelete = () => {
    deleteState.invoke({ path: folder.path, template, recursive }).then(() => onClose(''));
  };
  return (
    <Modal
      open={Boolean(open)}
      onClose={() => onClose(undefined)}
      title='Delete folder'
      className='sked-test-delete-folder-modal'
      size='xs'
      buttons={[
        <HeaderButton
          onClick={handleDelete}
          title='Delete'
          color='danger'
          className='sked-test-delete-folder-modal-button-delete'
        />
      ]}
    >
      <div>
        <DialogContentText>
        Are you sure you wish to delete this folder? This will NOT delete the messages within the folder. They will be placed into the "No Folder" category.
        </DialogContentText>
      </div>
    </Modal>
  );
};

export const AddDialog = ({ open, onClose, id, folder, template }: DialogProps) => {
  const {
    path,
    resource,
  } = folder;
  const label = typeof resource?.PathLabel === 'number' ? '' : resource?.PathLabel?.label;
  const [name, setName] = useState(label || '');
  const addState = usePromise<CreateFolder, void>(addFolder, null);
  const editState = usePromise<CreateFolder, void>(editFolder, null);
  const handleAdd = () => {
    if (id && typeof folder.resource.PathLabel !== 'number') {
      editState.invoke({
        id: folder.resource.PathLabel.id,
        path,
        template,
        label: name,
      }).then(() => {
        onClose(name);
        setName('');
      });
    } else {
      addState.invoke({
        path,
        template,
        label: name,
      }).then(() => {
        setName('');
        onClose(true);
      });
    }
  };
  useEffect(() => {
    if (label && open)
      setName(label);
  }, [label, open]);

  return (
    <Modal
      open={Boolean(open)}
      onClose={() => {
        setName('');
        onClose(undefined);
      }}
      maxWidth={500}
      title={id ? 'Edit folder' : 'New folder'}
      className={`sked-test-${id ? 'edit' : 'add'}-folder-modal`}
      buttons={[
        <HeaderButton
          title={id ? 'Update' : 'Create'}
          onClick={handleAdd}
          color="primary"
          disabled={name.trim() === ''}
          className={`sked-test-${id ? 'edit' : 'add'}-folder-modal-button-save`}
        />
      ]}
    >
      <div>
        <DialogContentText>
          Please enter a name for your folder.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          label="Name"
          type="text"
        />
        {addState.errorMessage && <p style={{ color: 'red' }}>{addState.errorMessage}</p>}
        {editState.errorMessage && <p style={{ color: 'red' }}>{editState.errorMessage}</p>}
      </div>
    </Modal>
  );
};

let justOpenedMenu = false;
const FolderItem = ({
  folder, onClick, update, template, disableMenu, selectedId,
}: FolderItemProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const boxRef = React.useRef(null);

  useEffect(() => {
    if (selectedId !== folder.pathId) {
      setIsSelected(false);
    }
  }, [selectedId]);

  const handleOnClick = () => {
    if (!justOpenedMenu) {
      !anchorEl && onClick(isSelected ? undefined : folder);
      setIsSelected(!isSelected);
    }
    justOpenedMenu = false;
  };


  const {
    folderBoxStyle,
    buttonStyle,
  } = React.useMemo(() => {
    if (isSelected) {
      return {
        folderBoxStyle: {
          backgroundColor: '#008BCF',
          color: 'white',
          border: '1px solid white',
        },
        buttonStyle: { color: 'white' },
        wordStyle: {
          width: '100px'
        }
      };
    }
    return {
      folderBoxStyle: {
        color: 'unset',
      },
      buttonStyle: { color: 'unset' },
      wordStyle: {
        width: 'unset',
      },
    };
  }, [isSelected]);

  const name = React.useMemo(() => {
    return pathOr('', ['resource', 'PathLabel', 'label'], folder);
  }, [folder]);

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    justOpenedMenu = true;
    setAnchorEl(e.currentTarget);
  };

  const onHoverAction = () => {
    setHideButton(false);
  };

  const offHoverAction = () => {
    setHideButton(true);
  };

  useEffect(() => {
    const boxCurrent = boxRef.current;
    boxCurrent.addEventListener('mouseover', onHoverAction);
    boxCurrent.addEventListener('mouseout', offHoverAction);
    return () => {
      boxCurrent?.removeEventListener('mouseover', onHoverAction);
      boxCurrent?.removeEventListener('mouseout', offHoverAction);
    };
  }, [boxRef?.current]);

  const shouldHideButton = React.useMemo(() => {
    return hideButton && !anchorEl;
  }, [hideButton, anchorEl]);

  const handleClose = (name: string = undefined) => {
    setIsDelete(false);
    setIsAdd(false);
    if (name === null) {
      update('');
      onClick(undefined);
      setIsSelected(!isSelected);
    } else if (name !== undefined) {
      update(name);
      !anchorEl && onClick(isSelected ? undefined : folder);
      setIsSelected(!isSelected);
    }
  };

  return (
    <>
      <DeleteDialog
        id={folder.pathId}
        open={isDelete}
        onClose={handleClose}
        template={template}
        folder={folder}
      />
      <AddDialog
        open={isAdd}
        onClose={handleClose}
        folder={folder}
        id={folder.pathId}
        template={template}
      />
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={(e: any) => {
          e.stopPropagation();
          setAnchorEl(null);
          setIsAdd(true);
        }}>
          Edit
        </MenuItem>
        <MenuItem onClick={(e: any) => {
          e.stopPropagation();
          setAnchorEl(null);
          setIsDelete(true);
        }}>
          Delete
        </MenuItem>
      </Menu>
      <div
        key={folder.pathId}
        className={classes.folderBox}
        style={{
          ...folderBoxStyle,
        }}
        ref={boxRef}
        onClick={handleOnClick}
        title={name}
      >
        <div className={classes.clickablePart}>
          <FolderIcon style={{
            fontSize: '18px',
            color: isSelected ? 'white' : '#929090',
            marginRight: '6px',
          }} />
          <div className={classes.wordPart} style={{ width: shouldHideButton ? 'unset' : '145px' }}>
            {name}
          </div>
        </div>
        {!disableMenu &&
        <IconButton
          onClick={handleMenuButtonClick}
          style={{ ...buttonStyle, zIndex: 10000, padding: 5, display: shouldHideButton ? 'none' : 'flex' }}
        >
          <MenuIcon fontSize='small' style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
        </IconButton>
        }
      </div>
    </>
  );
};

export const Folders = ({ path, onClick, template, deselect }: FoldersProps) => {
  const classes = useStyles();
  const [folders, setFolders] = useState<Folder[]>([]);
  const [selectedId, setSelectedId] = useState<number>(null);
  const foldersState = usePromise<FetchFolderType, FolderQueryType>(fetchFolders, defaultFolderQueryData);
  const [isAdd, setIsAdd] = useState(false);
  const [showingMore, setShowingMore] = useState(false);
  const {
    isAdmin,
    hasFeature,
  } = useSelector((state: any) => ({
    isAdmin: isEmpty(state.login.office) ? state.login.admin : false,
    hasFeature: includes('Folders', pathOr([], ['login', 'features'], state)),
  }));

  /* useMemo doesn't work with arrays */
  const stringPath = path.join('.');

  const hasSubpath = React.useMemo(() => {
    return path;
  }, [stringPath]);

  useEffect(() => {
    foldersState.invoke({
      hasSubpath,
      template,
    }).then(({ data }: FolderQueryType) => {
      setFolders(data);
    });
    return () => {
      currentPath = {};
    };
  }, [hasSubpath]);

  useEffect(() => {
    if (deselect)
      setSelectedId(null);
  }, [deselect]);

  const handleClose = (shouldReload = false) => {
    if (shouldReload) {
      foldersState.invoke({
        hasSubpath,
        template,
        page: 1,
        perPage: showingMore ? 100 : 10,
      }).then(({ data }: FolderQueryType) => {
        setFolders(data);
      });
    }
    setIsAdd(false);
  };

  const onShowMore = () => {
    foldersState.invoke({
      hasSubpath,
      template,
      page: 1,
      perPage: 100,
    }).then(({ data }: FolderQueryType) => {
      setFolders(data);
      setShowingMore(true);
    });
  };

  const onShowLess = () => {
    foldersState.invoke({
      hasSubpath,
      template,
      page: 1,
    }).then(({ data }: FolderQueryType) => {
      setFolders(data);
      setShowingMore(false);
    });
  };

  const showMore = React.useMemo(() => {
    return foldersState.data?.page < foldersState.data?.totalPages && !showingMore;
  }, [foldersState.data, showingMore]);

  const updateFolder = (idx: number) => (name: string) => {
    if (isNil(name)) {
      foldersState.invoke({
        hasSubpath,
        template,
      });
    } else {
      const newFolders = name === '' ?
        remove(idx, 1, folders)
        :
        adjust(idx, evolve({
          resource: ({ PathLabel }) => ({
            PathLabel: {
              ...PathLabel,
              label: name,
            }
          }),
        }), folders);
      setFolders(newFolders);
    }
  };

  if (!hasFeature && !isAdmin) {
    return (
      <div />
    );
  }

  return (
    <div style={{ marginLeft: '-3px', display: 'flex', alignItems: 'center' }}>
      {(isAdmin || !template) &&
        <Tooltip arrow title='Add Folder' placement='top'>
          <IconButton
            style={{
              float: 'left',
              margin: '4px 8px 4px 0px',
              padding: 4,
            }}
            onClick={() => setIsAdd(true)} >
            <AddIcon fontSize='large' />
          </IconButton>
        </Tooltip>}
      {(folders.length || showMore || showingMore || isAdd) && (
        <div className={classes.container}>
          <AddDialog
            open={isAdd}
            onClose={handleClose}
            folder={{
              path,
              pathId: 0,
              resource: {},
            }}
            template={template}
          />
          {folders.map((folder, idx) => {
            return (
              <FolderItem
                key={folder.pathId}
                folder={folder}
                onClick={(f) => {
                  if (f) {
                    onClick(f.path);
                    setSelectedId(f.pathId);
                    currentPath = {
                      ...currentPath,
                      [path.join('.')]: f.path,
                    };
                    return;
                  }
                  onClick(null);
                  setSelectedId(null);
                  currentPath = {
                    ...currentPath,
                    [path.join('.')]: undefined,
                  };
                }}
                path={path}
                template={template}
                update={updateFolder(idx)}
                selectedId={selectedId}
                disableMenu={!(isAdmin || !template)}
              />
            );
          })}
          {showMore &&
            <div
              className={classes.otherDivs}
              style={{
                justifyContent: 'flex-start',
                marginLeft: '5px',
              }}>
              <a onClick={onShowMore}>
                Show More...
              </a>
            </div>}
          {showingMore &&
            <div
              className={classes.otherDivs}
              style={{
                justifyContent: 'flex-start',
                marginLeft: '5px',
              }}>
              <a onClick={onShowLess}>
                Show Less...
              </a>
            </div>}
        </div>
      )}
    </div>
  );
};

interface FoldersListProps {
  paths: Folder[];
  selected: number;
  onClick: (pathId: number) => void;
  allowMutation?: boolean;
  updatePathList?: (idx: number, newPath?: Folder) => void;
  getPaths?: () => void;
}

export const FoldersList = ({
  paths,
  selected,
  onClick,
  allowMutation,
  updatePathList,
  getPaths,
}: FoldersListProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [edit, setEdit] = useState(null);
  const [deleteF, setDelete] = useState(null);
  const [selectedPath, setSelectedPath] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [add, setAdd] = useState(false);

  const openMenu = (path: Folder, idx: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedPath(path);
    setSelectedIdx(idx);
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const openEdit = (path: Folder) => () => {
    setEdit(path);
    setAnchorEl(null);
  };
  const openDelete = (path: Folder) => () => {
    setDelete(path);
    setAnchorEl(null);
  };
  const handleClose = (name: string) => {
    if (updatePathList) {
      if (name === '') {
        setDelete(null);
        updatePathList(selectedIdx, null);
      } else {
        setEdit(null);
        updatePathList(selectedIdx, {
          ...edit,
          resource: {
            PathLabel: {
              ...edit.resource.PathLabel,
              label: name,
            }
          }
        });
      }
    }
  };
  const newFolder = () => {
    setAdd(true);
  };
  return (
    <div className={classes.templatePathContainer}>
      <AddDialog
        open={add}
        onClose={(update) => {
          setAdd(false);
          if (update)
            getPaths();
        }}
        folder={{
          path: ['tmp', 'auto'],
          pathId: 0,
          resource: {
            PathLabel: 0
          },
        }}
        template
      />
      {deleteF &&
        <DeleteDialog
          id={deleteF.pathId}
          open={Boolean(deleteF)}
          onClose={handleClose}
          template
          recursive
          folder={deleteF}
        />}
      {edit &&
        <AddDialog
          open={Boolean(edit)}
          onClose={handleClose}
          folder={edit}
          id={edit.pathId}
          template
        />}
      <Row>
        <div style={{
          width: '10%',
          borderBottom: 'gray solid 1px',
        }}></div>
        &nbsp;
        <div className={classes.headerTitles}>
          Folders
        </div>
        &nbsp;
        <div style={{
          width: '100%',
          borderBottom: 'gray solid 1px',
        }}></div>
        <IconButton style={{ marginRight: '12px' }} onClick={newFolder}>
          <CreateNewFolderIcon />
        </IconButton>
      </Row>
      <List>
        {paths.map((path, idx) => {
          return (
            <ListItem
              key={path.pathId}
              className={classes.pathListItem}
              style={selected === path.pathId ? {
                backgroundColor: '#008BCF36',
              } : {}}
              onClick={() => onClick(path.pathId)}>
              <ListItemText>
                {pathOr('No Folder', ['resource', 'PathLabel', 'label'], path)}
              </ListItemText>
              {path.pathId !== 0 && allowMutation &&
                <IconButton onClick={openMenu(path, idx)}>
                  <MenuIcon style={{ fontSize: '18px' }} />
                </IconButton>}
            </ListItem>
          );
        })}
      </List>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        <MenuItem onClick={openEdit(selectedPath)}>
          <ListItemIcon>
            <EditIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={openDelete(selectedPath)}>
          <ListItemIcon>
            <DeleteIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </div>
  );
};
