import { configureStore } from '@reduxjs/toolkit';
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';

import login from './routes/Login/login.reducer';
import session from './routes/Login/session.reducer';
import dashboard from './routes/Dashboard/dashboard.reducer';
import snackbar from './components/Snackbar/snackbar.reducer';
import clients from './routes/Clients/clients.reducer';
import clientDialog from './routes/Clients/components/client-dialog/client-dialog.reducer.jsx';
import messages from './routes/Messages/messages.reducer';
import newMessage from './routes/Messages/routes/MessagesNew/messages-new.reducer.jsx';
import viewMessage from './routes/Messages/routes/MessagesView/messages-view.reducer.jsx';
import messageThread from './routes/Messages/routes/MessagesThread/messages-thread.reducer.jsx';
import recurring from './routes/RecurringMessage/recurring.reducer.jsx';
import editRecurring from './routes/RecurringMessage/routes/RecurringEdit/recurring-edit.reducer.jsx';
import viewRecurring from './routes/RecurringMessage/routes/RecurringView/recurring-view.reducer.jsx';
import templates from './routes/Templates/templates.reducer';
import templateEdit from './routes/Templates/routes/TemplatesEdit/templates-edit.reducer';
import pages from './services/pageReducer.js';
import officeHours from './routes/OfficeHours/NormalOfficeHours/officeHours.reducer.js';
import specialOfficeHours from './routes/OfficeHours/SpecialOfficeHours/specialOfficeHours.reducer.js';
import calendar from './routes/Calendar/calendar.reducer';
import schedule from './routes/Schedule/schedule.reducer';
import professionals from './routes/Professionals/professionals.reducer.jsx';
import registration from './routes/Registration/registration.reducer.jsx';
import business from './routes/Settings/routes/Business/business.reducer.js';
import subscription from './routes/Settings/routes/Subscription/subscription.reducer.js';
import installer from './routes/Settings/routes/Installer/installer.reducer.jsx';
import integration from './routes/Settings/routes/Integration/integration.reducer.jsx';
import webmodule from './routes/Settings/routes/Webmodule/webmodule.reducer.jsx';
import settingsApp from './routes/Settings/routes/App/app.reducer.jsx';
import settingsReviews from './routes/Settings/routes/Reviews/reviews.reducer.js';
import general from './routes/Settings/routes/General/general.reducer.jsx';
import admin from './routes/Admin/admin.reducer';
import reviews from './routes/Reviews/reviews.reducer.jsx';
import tags from './routes/Tags/tags.reducer.jsx';
import calendarv2 from './routes/CalendarV2/calendar.reducer';
import overviewReport from './routes/OverviewReport/overview.reducer.js';
import waiting from './routes/waiting/waiting.reducer.js';
import stopManagers from './routes/StopManagers/stop-managers.reducer.jsx';
import tutorial from './routes/Tutorial/tutorial.reducer.js';
import automations from './routes/Automations/automations.reducer.js';
import leads from './routes/Leads/leads.reducer';
import forms from './routes/IntakeForms/intake-forms.reducer';

const store = configureStore({
  reducer: {
    login,
    session,
    dashboard,
    snackbar,
    clients,
    clientDialog,
    messages,
    newMessage,
    viewMessage,
    recurring,
    editRecurring,
    viewRecurring,
    messageThread,
    templates,
    templateEdit,
    pages,
    officeHours,
    specialOfficeHours,
    calendar,
    schedule,
    professionals,
    registration,
    business,
    subscription,
    integration,
    installer,
    webmodule,
    general,
    admin,
    settingsApp,
    settingsReviews,
    reviews,
    tags,
    calendarv2,
    overviewReport,
    waiting,
    stopManagers,
    tutorial,
    automations,
    leads,
    forms,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type GetStore = () => RootState;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
