import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import { CircularProgress, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import EditHours from './components/edit-hours.component.jsx';
import Instructions from '../../components/Instructions/instructions.component';
import { useSelector } from '../../reducers';

import {
  editHoursSelector,
  editIdSelector,
  hoursSelector,
  specialHoursSelector,
  successSelector,
  errorSelector,
  officeHoursSelector,
  originalSelector,
  schedulesSelector,
  professionalsSelector,
  busySelector,
  editSlotLengthSelector,
  editNumberOfAppointmentsSelector,
  popupSelector,
  editScheduleSelector,
} from './schedule.selectors.jsx';
import DragContainer from './components/drag-container.component.jsx';

import './schedule.css';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import Header from '../../components/PageHeader/PageHeader.component';
import FilterText from '../../components/FilterText/FilterText.component';
import * as actionsRaw from './schedule.actions';
import * as businessActionRaw from '../Settings/routes/Business/business.actions';

import { useStyles } from './schedule.styles';

const instructions = 'All of your professionals appointment types will initially be listed in the left column, "Available Appointment Types." If you want to create a new schedule, simply enter a name and hit the "Add" button. You may also just press the "Add" button to create an untitled schedule. A new column will appear on the right of the list of available appointment types. Then drag the appointment types that you want in to the new schedule. If a schedule is off-screen, you can just drag the type near the edge of the browser and it will auto-scroll. You may also hold the shift key and scroll to scroll horizontally. If you decide that you no longer want a schedule, hit the X on the top right of the column. If you wish to change the title of a schedule, just click on the schedule title. \n \n Once you have saved your schedules with the save button located at the bottom left of each schedule, you can set the hours for each schedule by clicking on the set hours button at the bottom of each schedule.';

const ScheduleTemplate = ({
  busy,
  schedules,
  actions,
  businessAction,
  locationsHours,
  editHours,
  hours,
  editId,
  viewInstructions,
  editNumberOfAppointments,
  editSlotLength,
  nameEdit,
  officeHours,
  specialHours,
  office,
  features,
  updatedNumberOfAppointments,
  affected,
  scheduleToEdit,
  hoursTab,
  hasLinkedScheduleFeature,
  localPatch,
  nameRef,
  hasLocations,
  locations,
  headerHeight,
}) => {
  const classes = useStyles();
  const containerRef = useRef();

  const [height, setHeight] = useState(350);
  const [locHours, setLocHours] = useState([]);

  const onDragEnd = (selecteds, sourceIndex, destIndex) => {
    if (destIndex === null) {
      return;
    }
    if (sourceIndex === destIndex) {
      return;
    } else {
      actions.move(selecteds, sourceIndex, destIndex);
    }
  };

  useEffect(() => {
    const addSchedulesHeight = 5;
    if (containerRef.current) {
      setHeight(containerRef.current.getBoundingClientRect().height - addSchedulesHeight);
    }

    function handleResize() {
      setHeight(containerRef.current.getBoundingClientRect().height - addSchedulesHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef, schedules]);

  useEffect(() => {
    if (hasLocations) {
      businessAction.getLocations();
    }
  }, [hasLocations]);

  useEffect(() => {
    const items = locations.map(loc => {
      const hour = locationsHours.find(h => h.locationId === loc.id);
      return { ...loc, hours: hour?.hours };
    });
    setLocHours(items);
  }, [locations, locationsHours]);

  return (
    <>
      <Header
        title='Provider Schedule'
        pageId="schedules"
        rightIcons={[
          <Grid display="flex" marginRight={1}>
            {busy && <CircularProgress size={18} />}
          </Grid>,
          <FilterText
            Icon={AddIcon}
            title='Add Schedule'
            value=''
            placeholder='Add schedule'
            onSave={(value) => {
              const trimmed = value.trim();
              if (trimmed === '') {
                actions.add('Untitled');
              } else {
                actions.add(trimmed);
              }
              localPatch('name', '');
            }}
            noSearchIcon
            noReset
            saveTitle='Add'
          />
        ]}
      />
      <div className={classes.root} style={{ height: `calc(100vh - ${headerHeight + 55}px)` }}>
        {!editHours && <PopupTemplate />}
        <div className={classes.container} ref={containerRef}>
          <Instructions
            open={viewInstructions}
            message={instructions}
            onClose={() => localPatch('viewInstructions', false)}
          />
          <EditHours
            open={editHours}
            hours={hours}
            features={features}
            hasLinkedScheduleFeature={hasLinkedScheduleFeature}
            specialHours={specialHours}
            officeHours={officeHours}
            save={(newHours, deleteHour) => actions.saveHours(newHours, editId, deleteHour)}
            scheduleToEdit={scheduleToEdit}
            id={editId}
            defaultMax={editNumberOfAppointments}
            slotLength={editSlotLength}
            onClose={() => actions.patch({ editHours: false })}
            affected={affected}
            schedules={R.filter(({ id }) => id !== 0 && id !== editId)(schedules)}
            tab={hoursTab}
            hasLocations={hasLocations}
            locations={locHours}
            office={office}
          />
          <DragContainer
            hasLinkedScheduleFeature={hasLinkedScheduleFeature}
            nameEdit={nameEdit}
            nameRef={nameRef}
            onDragEnd={onDragEnd}
            localPatch={localPatch}
            schedules={schedules}
            busy={busy}
            save={actions.save}
            patch={actions.patch}
            getHours={actions.getHours}
            skedPatch={actions.skedPatch}
            removeWithoutId={actions.removeWithoutId}
            remove={actions.remove}
            move={actions.move}
            parentHeight={height}
            changeOrder={actions.changeOrder}
            displayRoom={office.ehrSystem === 'ChiroTouch'}
            updatedNumberOfAppointments={updatedNumberOfAppointments}
          />
        </div>
      </div>
    </>
  );
};

const Schedule = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: '',
    viewInstructions: false,
  });

  const actions = bindActionCreators(actionsRaw, dispatch);
  const businessAction = bindActionCreators(businessActionRaw, dispatch);

  const states = useSelector(s => ({
    busy: busySelector(s),
    professionals: professionalsSelector(s),
    schedules: schedulesSelector(s),
    original: originalSelector(s),
    officeHours: officeHoursSelector(s),
    error: errorSelector(s),
    success: successSelector(s),
    hours: hoursSelector(s),
    specialHours: specialHoursSelector(s),
    editId: editIdSelector(s),
    scheduleToEdit: editScheduleSelector(s),
    editHours: editHoursSelector(s),
    editNumberOfAppointments: editNumberOfAppointmentsSelector(s),
    editSlotLength: editSlotLengthSelector(s),
    office: R.pathOr(null, ['login', 'office'])(s),
    updatedNumberOfAppointments: popupSelector(s),
    hasFeature: R.intersection(['SkedApp', 'NPPortal', 'NPPortal2'], R.pathOr([], ['login', 'features'], s)).length > 0,
    features: s.login.features,
    hasLinkedScheduleFeature: R.includes('LinkedSchedule', R.pathOr([], ['login', 'features'], s)),
    affected: R.pathOr([], ['schedule', 'affected'])(s),
    hoursTab: R.pathOr([], ['schedule', 'hoursTab'])(s),
    locationsHours: R.pathOr([], ['schedule', 'locationsHours'])(s),
    hasLocations: R.pipe(
      R.pathOr([], ['login', 'features'])
      , R.includes('Locations')
    )(s),
    locations: R.pathOr([], ['business', 'locations'], s),
    headerHeight: s.login.headerHeight,
  }));

  const nameRef = React.createRef();

  const patch = (prop, data) => {
    setState(R.merge(state, { [prop]: data }));
  };

  useEffect(() => {
    window.document.title = 'Schedule';
  }, [states.hasFeature]);
  
  useEffect(() => {
    if (states.hasFeature && states.office?.id) {
      actions.getTypes(navigate);
    }
  }, [states.hasFeature, states.office?.id]);
  
  useEffect(() => {
    if (state.nameEdit !== undefined && nameRef.current !== null) {
      nameRef.current?.focus();
    }
  }, [state.nameEdit]);

  if (!states.hasFeature) {
    return (
      <FeatureDisabled title="SKED App Schedule">
        <p>Put your office scheduler into the pocket of your patients with a very user friendly simple app. </p>
      </FeatureDisabled>
    );
  }
  return (
    <ScheduleTemplate {...{
      ...props,
      ...states,
      ...state,
      localPatch: patch,
      nameRef,
      actions,
      businessAction,
    }} />
  );
};

export default Schedule;
