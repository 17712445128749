import * as R from 'ramda';
import * as at from '../../../../actionTypes';

/*
`status` can be 'OFFICES', 'DEFAULTS', or 'REGISTERED'. For unregistered
offices, the status will start as 'OFFICES'. Once an office is selected, it will
move to 'DEFAULTS' so they can pick the default pro and type. Once you've
selected the default types and pros, the office is registered and will therefore
have the status of 'REGISTERED'.
*/
const init = {
  proTypes: [],
  syncColors: false,
  groupBy: 'Pin',
  originalGroupBy: 'Pin',
  regMethod: 'Pin',
  busy: false,
  state: 'INT',
  pros: [],
  mappedPros: [],
  selectedProfessional: {},
  aptLength: 15,
  status: 'REGISTERED',
  defaultType: null,
  defaultPro: null,
  clinicId: null,
  arriveType: null,
  syncerVersion: null,
  syncerVersionAll: [],
  apiKey: '',
};

export default function reducer(integration = init, action) {
  switch (action.type) {
    case at.INTEGRATION_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(integration, { busy: true });
      } else if (action.state === 'RESPONSE') {
        return R.merge(integration, {
          arriveType: action.data.arriveType,
          syncColors: action.data.syncColors,
          offices: action.data.offices,
          status: action.data.status ? action.data.status : 'REGISTERED',
          defaultPro: action.data.defaultPro,
          proTypes: action.data.newList,
          groupBy: action.data.groupBy,
          originalGroupBy: action.data.groupBy,
          regMethod: action.data.registrationMethod,
          aptLength: action.data.blockLength,
          pros: action.data.pros,
          mappedPros: action.data.mappedPros,
          showMaps: action.data.mappedPros ? action.data.mappedPros.length !== 0 : false,
          clinicId: action.data.clinicId,
          syncerVersion: action.data.syncerVersion,
          syncerVersionAll: action.data.syncerVersionAll,
          busy: false,
          state: 'INT',
        });
      } else if (action.state === 'ERROR') {
        return R.merge(integration, { busy: false, state: 'INT' });
      }
      return integration;
    case at.INTEGRATION_REMOTE_PUT:
      if (action.state === 'REQUEST') {
        return R.merge(integration, { busy: true });
      } else if (action.state === 'RESPONSE') {
        return R.merge(integration, {
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(integration, { busy: false });
      }
      return integration;
    case at.INTEGRATION_REMOTE_POST:
      if (action.state === 'REQUEST') {
        return R.merge(integration, { busy: true, state: 'INT' });
      } else if (action.state === 'RESPONSE') {
        return R.merge(integration, {
          busy: false,
          state: 'UPDATED',
        });
      } else if (action.state === 'ERROR') {
        return R.merge(integration, { busy: false, state: 'INT' });
      }
      return integration;
    case at.INTEGRATION_REMOTE_PUT_GEN:
      if (action.state === 'REQUEST') {
        return R.merge(integration, { busy: true, state: 'INT' });
      } else if (action.state === 'RESPONSE') {
        return R.merge(integration, {
          busy: false,
          state: 'UPDATED',
          proTypes: R.map((type) => {
            if (type.id === action.data.id) {
              return R.merge(type, { firstName: action.data.firstName, lastName: action.data.lastName });
            }
            return type;
          })(integration.proTypes)
        });
      } else if (action.state === 'ERROR') {
        return R.merge(integration, { busy: false, state: 'INT' });
      }
      return integration;
    case at.INTEGRATION_MISC_PATCH:
      return R.merge(integration, action.data);
    case at.INTEGRATION_PATCH:
      return R.evolve({
        proTypes: (proTypes) => {
          const some = R.nth(action.data.index, proTypes);
          const newType = R.assoc(
            'default',
            action.data.type,
            some);
          return R.update(action.data.index, newType, proTypes);
        }
      }, integration);
    case at.INTEGRATION_MAPPED_PATCH: {
      const crit = R.pathEq(['fromPro', 'id'], action.data.mapped.fromPro.id);
      const isThere = R.find(crit, integration.mappedPros);
      const newMapped = isThere ? R.map((mapPro) => {
        if (R.pathOr(false, ['fromPro', 'id'])(mapPro) === R.pathOr(true, ['data', 'mapped', 'fromPro', 'id'])(action)) {
          return action.data.mapped;
        }
        return mapPro;
      })(integration.mappedPros) :
        R.append(action.data.mapped, integration.mappedPros);
      return R.merge(integration, {
        mappedPros:
          R.filter(({ toPro, fromPro }) =>
            toPro.id !== fromPro.id)(newMapped)
      });
    }
    case at.INTEGRATION_REMOTE_POST_SELECT_OFFICE: {
      return R.merge(integration, action.data);
    }
    default:
      return integration;
  }
}
