import * as at from '../../actionTypes';
import { remoteAction } from '../../services/actionService.js';
import api from '../../services/api.js';
import axios from 'axios';
import { loadingMessage } from './routes/RecurringEdit/recurring-edit.actions.jsx';
import * as R from 'ramda';
import { etd, convertEmail, getFeatureForTemplate } from '../../services/utilities.js';
import { getAutomationsByMsgId } from '../Automations/automations.actions';

const sortBy = [
  {
    field: 'Created',
    direction: 'Desc'
  },
];

const defaultSortBy = [
  {
    field: 'IsEnabled',
    direction: 'Desc'
  }, {
    field: 'Created',
    direction: 'Desc'
  },
];

const getAllMessages = (messageType, forFeature) => Promise.all(
  R.without([undefined], [
    api.post('message/query', {
      page: 1,
      perPage: 50,
      query: {
        sortBy: defaultSortBy,
        messageType,
        isDeleted: false,
      }
    }),
    api.post('message/template/query', {
      query: {
        messageType,
        isDeleted: false,
        forFeature,
      },
      page: 1,
      perPage: 1000,
    }),
    api.post('message/query', {
      page: 1,
      perPage: 1000,
      query: {
        sortBy: defaultSortBy,
        messageType,
        isDeleted: false,
        isTemplate: true,
      }
    }),
  ]).map(etd));

export const getRecurringMessages = (navigate, features) => remoteAction({
  type: at.RECURRING_REMOTE_GET,
  action: () => getAllMessages('Recurring', getFeatureForTemplate(features))
    .then(([
      recurrings,
      templates,
      officeTemplates,
    ]) => {
      return {
        recurrings,
        templates,
        officeTemplates,
      };
    }).catch((error) => {
      if (error.response) {
        console.error(error);
      } else {
        navigate('/offline');
      }
    }),
});

export const getRecallMessages = (navigate, features) => remoteAction({
  type: at.RECURRING_REMOTE_GET,
  action: () => getAllMessages('Recall', getFeatureForTemplate(features))
    .then(([
      recalls,
      templates,
      officeTemplates,
    ]) => {
      return {
        recalls,
        templates,
        officeTemplates,
      };
    }).catch((error) => {
      if (error.response) {
        console.error(error);
      } else {
        navigate('/offline');
      }
    }),
});

export const getBirthdayMessages = (navigate, features) => remoteAction({
  type: at.RECURRING_REMOTE_GET,
  action: () => getAllMessages('Birthday', getFeatureForTemplate(features))
    .then(([
      birthdays,
      templates,
      officeTemplates,
    ]) => {
      return {
        birthdays,
        templates,
        officeTemplates,
      };
    }).catch((error) => {
      if (error.response) {
        console.error(error);
      } else {
        navigate('/offline');
      }
    }),
});

export const getApptChangeMessages = (navigate, features) => remoteAction({
  type: at.RECURRING_REMOTE_GET,
  action: () => getAllMessages('ApptChange', getFeatureForTemplate(features))
    .then(([
      apptChanges,
      templates,
      officeTemplates,
    ]) => {
      return {
        apptChanges,
        templates,
        officeTemplates,
      };
    }).catch((error) => {
      if (error.response) {
        console.error(error);
      } else {
        navigate('/offline');
      }
    }),
});

export const getAutomationMessages = (features) => remoteAction({
  type: at.RECURRING_REMOTE_GET,
  action: () => getAllMessages('Automation', getFeatureForTemplate(features))
    .then(([
      recalls,
      templates,
      officeTemplates,
    ]) => {
      if (recalls.error) {
        throw { response: recalls.error };
      }
      /*
        XXX: this disaster code can be safely removed once everyone has been moved
        over to the new automation-based smart replies. This prevents showing automation
        based smart reply templates. Since there is no other way to determine it.
      */
      const valid = R.clone(templates);
      valid.data.data = [];
      return Promise.all(templates.data.data.map(({ id }) => {
        return Promise.all([
          api.get(`templates/paths/path/tmp.msg.rcr.time_based.msg_${id}`),
          api.get(`templates/paths/path/tmp.msg.rcr.smart_reply.msg_${id}`),
        ].map(etd));
      })).then((paths) => {
        paths.forEach(([tb, sm], idx) => {
          if (tb.error && sm.error) {
            valid.data.data.push(templates.data.data[idx]);
          }
        });
        return { recalls, templates: valid, officeTemplates };
      });
    }),
});

export const deleteMessage = ({ id, messageType }) => {
  return remoteAction({
    type: at.RECURRING_REMOTE_DELETE,
    action: () => api.delete(`message/${id}`).then(() => {
      if (messageType === 'recalls') {
        return api.post('automations/query', {
          page: 1,
          perPage: 100,
          query: {
            action: {
              msgId: id,
            },
          }
        }).then((data) => {
          return Promise.all(data.data.map((auto) =>
            api.delete(`automations/${auto.id}`))).then(() => {
            return {
              messageType,
              id,
            };
          });
        });
      }
      return {
        messageType,
        id,
      };
    }),
  });
};

export const deleteSelected = ({ messages, messageType }) => remoteAction({
  type: at.RECURRING_REMOTE_DELETE_MANY,
  action: () => Promise.all(R.map((message) =>
    message.selected ? api.delete(`message/${message.id}`) : null
  )(messages)).then(() => {
    return {
      messageType,
      ids: R.pipe(
        R.filter(R.prop('selected')),
        R.map(R.prop('id')),
      )(messages),
    };
  }),
});

export const copyMessage = (state, wasTemplate, message) => (dispatch, getStore) => {
  const features = R.path(['login', 'features'], getStore());
  const m = convertEmail(message, features);
  if (state === 'COPY_AUTOMATION') {
    getAutomationsByMsgId(message.id, wasTemplate, true);
  }
  loadingMessage({ ...m, wasTemplate }).then((msg) => {
    dispatch({
      type: at.RECURRING_EDIT_PATCH,
      data: {
        automations: [],
        ...msg,
        templateId: wasTemplate ? message.id : undefined,
        skedTemplate: !R.has('isTemplate', message),
      },
    });
    return dispatch({
      type: at.RECURRING_PATCH,
      data: {
        state,
      }
    });
  });
};

export const toggleEnable = (message, prop = 'messages') => (dispatch) => {
  const isAuto = R.has('Automation', message.messageType) &&
    !R.isEmpty(message.messageType.Automation);
  const data = {
    ...message,
    messageType: isAuto ?
      { Automation: [] } :
      message.messageType,
    campaignId: R.has('campaignId', message) && typeof message.campaignId === 'number' ?
      { Set: message.campaignId } :
      message.campaignId,
  };
  if (isAuto) {
    Promise.all(message.messageType.Automation.map((a) => {
      return api.put(`automations/toggle/${a.id}`, { enabled: message.isEnabled });
    }));
  }
  api.put(`message/${message.id}`, data);
  return dispatch({
    type: at.RECURRING_TOGGLE,
    data: {
      id: message.id,
      isEnabled: message.isEnabled,
      prop,
    }
  });
};


export const resetRecurring = () => (dispatch) =>
  dispatch({
    type: at.RECURRING_PATCH,
    data: {
      state: 'LIST',
    }
  });

export const messagesPatch = (prop, value) => (dispatch) =>
  dispatch({
    type: at.RECURRING_PATCH,
    data: {
      [prop]: value,
    }
  });

export const selectMessage = ({ type, index, value }) => (dispatch) =>
  dispatch({
    type: at.RECURRING_SELECT_PATCH,
    data: {
      type,
      index,
      value,
    }
  });

export const selectAll = ({ type, value }) => (dispatch) =>
  dispatch({
    type: at.RECURRING_SELECT_ALL_PATCH,
    data: {
      type,
      value,
    }
  });

const CancelToken = axios.CancelToken;
let cancel;
export const search = ({
  type,
  messageType,
  page,
  filter,
  query,
  features,
  path,
  noFolder,
}) => {
  if (cancel)
    cancel('User aborted request.');
  const cancelObj = {
    cancelToken: new CancelToken((c) => {
      cancel = c;
    })
  };
  const name = query;
  let pathQuery = query || R.isNil(path) ?
    undefined : { Exists: { isDirectDescendant: path } };
  if (noFolder) {
    pathQuery = R.isNil(path) ?
      undefined : { NotExists: { hasSubpath: path } };
  }
  return R.cond([
    [R.equals('template'), () => {
      return remoteAction({
        type: at.RECURRING_REMOTE_GET_SEARCH,
        action: () => api.post('message/template/query', {
          page,
          perPage: 50,
          query: {
            messageType,
            isDeleted: false,
            forFeature: getFeatureForTemplate(features),
            path: pathQuery,
            name,
          }
        }, cancelObj).then((data) => {
          return {
            templatePage: data.page,
            totalTemplatePages: data.totalPages,
            totalTemplateCount: data.totalCount,
            templates: data.data,
            busy: false,
          };
        }),
      });
    }],
    [R.equals('officeTemplate'), () => {
      return remoteAction({
        type: at.RECURRING_REMOTE_GET_SEARCH,
        action: () => api.post('message/query', {
          page,
          perPage: 50,
          query: {
            isDeleted: false,
            isTemplate: true,
            messageType,
            path: pathQuery,
            sortBy: [filter].concat(sortBy),
            name,
          }
        }, cancelObj).then((data) => {
          return {
            officeTemplatePage: data.page,
            totalOfficeTemplatePages: data.totalPages,
            totalOfficeTemplateCount: data.totalCount,
            officeTemplates: data.data,
            busy: false,
          };
        }),
      });
    }],
    [R.T, () => {
      return remoteAction({
        type: at.RECURRING_REMOTE_GET_SEARCH,
        action: () => api.post('message/query', {
          page,
          perPage: 50,
          query: {
            isDeleted: false,
            messageType,
            path: pathQuery,
            sortBy: [filter].concat(sortBy),
            name,
          }
        }, cancelObj).then((data) => {
          return R.cond([
            [R.equals('Recurring'), () => ({
              page: data.page,
              totalPages: data.totalPages,
              totalCount: data.totalCount,
              messages: data.data,
              busy: false,
            })],
            [R.equals('Birthday'), () => ({
              birthdayPage: data.page,
              birthdayTotalPages: data.totalPages,
              birthdayTotalCount: data.totalCount,
              birthdays: data.data,
              busy: false,
            })],
            [R.equals('Recall'), () => ({
              recalls: data.data,
              recallTotalCount: data.totalCount,
              recallTotalPages: data.totalPages,
              recallPage: data.page,
              busy: false,
            })],
            [R.equals('ApptChange'), () => ({
              apptChanges: data.data,
              apptChangesTotalCount: data.totalCount,
              apptChangesTotalPages: data.totalPages,
              apptChangesPage: data.page,
              busy: false,
            })],
            [R.equals('Automation'), () => ({
              recalls: data.data,
              recallTotalCount: data.totalCount,
              recallTotalPages: data.totalPages,
              recallPage: data.page,
              busy: false,
            })],
          ])(messageType);
        }),
      });
    }]
  ])(type);
};


export const getAutoresponderMessages = () => remoteAction({
  type: at.RECURRING_REMOTE_GET_AUTORESPONDER,
  action: () => api.get('autoresponder'),
});

export const updateAutoresponderMessage = (newMessage, index) => ({
  type: at.RECURRING_REMOTE_SAVE_AUTORESPONDER,
  data: {
    newMessage,
    index,
  }
});

export const autoresponderDeleteMessage = (id) => remoteAction({
  type: at.RECURRING_REMOTE_DELETE_AUTO,
  action: () => api.delete(`autoresponder/${id}`).then(() => {
    return {
      id
    };
  }),
});

export const autoresponderDeleteSelected = (messages) => remoteAction({
  type: at.RECURRING_REMOTE_DELETE_AUTO_MANY,
  action: () => Promise.all(R.map((message) =>
    message.selected ? api.delete(`autoresponder/${message.autoId}`) : null
  )(messages)).then(() => {
    return {
      autoIds: R.pipe(
        R.filter(R.prop('selected')),
        R.map(R.prop('autoId')),
      )(messages),
    };
  }),
});

export const addAutoresponderMessage = (data) => (dispatch) => {
  return api.post('autoresponder', data).then((newMessage) => {
    dispatch(updateAutoresponderMessage(R.assoc('isEditing', true, newMessage)));
  });
};

export const disableOtherAutoresponderMessages = (messages, ignoreId) => remoteAction({
  type: 'RECURRING_DO_NOTHING',
  action: () => Promise.all(R.pipe(
    R.filter(({ autoId, enabled }) => enabled && autoId !== ignoreId),
    R.map((msg) => api.put(`autoresponder/${msg.autoId}`, R.merge(msg, { enabled: false })))
  )(messages)),
});
