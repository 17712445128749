import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsActive';
import AddIcon from '@mui/icons-material/Add';
import { useTitle } from '../../services/useTitle';
import Header from '../../components/PageHeader/PageHeader.component';
import FilterTags from '../../components/FilterTags/FilterTags.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import FilterStatus from '../../components/FilterStatus/FilterStatus.component';
import FilterStartEndDate from '../../components/FilterStartEndDate/FilterStartEndDate.component';
import HeaderSearch from '../../components/HeaderSearch/HeaderSearch.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import ReferralIcon from '../../icons/Referral.icon';
import SourceIcon from '../../icons/Source.icon';
import api from '../../services/api';
import * as actions from './leads.actions';
import { useSelector } from '../../reducers';

import LeadTable from './components/Table/Table.component';
import AddLead from './components/AddLead/AddLead.component';

const SOURCES = [
  {
    id: 'all',
    name: 'All Sources'
  },
  {
    id: 'Admin',
    name: 'Admin',
  },
  {
    id: 'Sms',
    name: 'SMS'
  },
  {
    id: 'AnyNpp',
    name: 'New Patient Portal',
  },
];

const NOTIFICATIONS = ['Email On', 'Email Off', 'SMS On', 'SMS Off'];

type Tag = {
  tagId: number;
  tag: string;
}

let typeTimer: NodeJS.Timeout = null;

const Leads = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState<{ [x: string]: string }>();
  const [date, setDate] = useState({
    start: '',
    end: ''
  });
  const [source, setSource] = useState<string>('');
  const [referrer, setReferrer] = useState<string>('');
  const [allowEmail, setAllowEmail] = useState<boolean>(null);
  const [allowSMS, setAllowSMS] = useState<boolean>(null);
  const [tags, setTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [notifications, setNotifications] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchSelected, setSearchSelected] = useState('Name');
  const [width, setWidth] = useState(0);

  const { leads, busy, totalPages, totalCount } = useSelector((state) => state.leads);
  const headerHeight = useSelector((state) => state.login.headerHeight);

  useTitle('Leads');

  const handleAdd = () => {
    setOpen(true);
  };

  const handleSearch = (item: { [x: string]: string }) => {
    if (search) {
      setPage(1);
    }
    setSearch(item);
  };

  const getLeads = () => {
    dispatch(actions.getLeads({ page, perPage }));
  };
  const getOptoutList = () => {
    dispatch(actions.getOptoutList());
  };
  const getReferrers = () => {
    dispatch(actions.getReferrers());
  };

  const handleRemoveLead = (id: number) => {
    dispatch(actions.removeLead(id));
  };

  const handlePerPage = (newPage: number) => {
    setPerPage(newPage);
    setPage(1);
  };

  const handleNotificationsChange = (values: string[]) => {
    setNotifications(values);
  };

  useEffect(() => {
    if (notifications.includes(NOTIFICATIONS[0]) && !notifications.includes(NOTIFICATIONS[1])) {
      setAllowEmail(true);
    } else if (!notifications.includes(NOTIFICATIONS[0]) && notifications.includes(NOTIFICATIONS[1])) {
      setAllowEmail(false);
    } else {
      setAllowEmail(null);
    }
    if (notifications.includes(NOTIFICATIONS[2]) && !notifications.includes(NOTIFICATIONS[3])) {
      setAllowSMS(true);
    } else if (!notifications.includes(NOTIFICATIONS[2]) && notifications.includes(NOTIFICATIONS[3])) {
      setAllowSMS(false);
    } else {
      setAllowSMS(null);
    }
  }, [notifications]);

  useEffect(() => {
    const getTags = async () => {
      const response = await api.get('client/tag');
      setAllTags(response);
    };
    getTags();
  }, []);

  const handleFilter = (page: number) => {
    const delay = 500;
    if (typeTimer) {
      clearTimeout(typeTimer);
    }
    typeTimer = setTimeout(() => {
      const clientSource = { [source]: [] as string[] };
      const selectedTags = tags.length ? { And: tags.map(t => t.tagId) } : undefined;
      const query = {
        ...search,
        allowSMS,
        referrer: referrer ? referrer : undefined,
        allowEmail,
        created: (date.start && date.end) ? date : undefined,
        clientSource: (source.length && source !== 'all') ? clientSource : undefined,
        tags: selectedTags,
      };
      dispatch(actions.getLeads({ page, perPage, query }));
    }, delay);
  };

  const onChangeSelected = (value: string) => {
    if (searchText) {
      setSearchText('');
      handleSearch({ lastName: '' });
    }
    setSearchSelected(value);
  };

  const onChangeSearch = (value: string) => {
    setSearchText(value);
    const key = searchSelected === 'Name' ? 'lastName' : searchSelected.toLowerCase();
    if (searchSelected === 'Name') {
      const str = value;
      const splitUp = str.split(' ');
      const lastName = splitUp[0] ? splitUp[0].trim() : '';
      const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
      handleSearch({ lastName, firstName });
      return;
    }
    if (value === '') {
      handleSearch({ [key]: null });
      return;
    }
    handleSearch({ [key]: value });
  };

  const handleReset = () => {
    setTags([]);
    setSource('');
    setNotifications([]);
    setDate({ start: '', end: '' });
    setReferrer('');
    setSearchText('');
    setSearch({ lastName: '' });
    setSearchSelected('Name');
  };

  useEffect(() => {
    handleFilter(1);
    setPage(1);
  }, [search, allowEmail, allowSMS, source, perPage, date, referrer, tags]);

  useEffect(() => {
    handleFilter(page);
  }, [page]);

  useEffect(() => {
    getLeads();
    getOptoutList();
    getReferrers();
  }, []);

  const justIcon = useMemo(() => {
    return width < 1134;
  }, [width < 1134]);

  return (
    <>
      <Header
        title='Leads'
        pageId="leads"
        getWidth={setWidth}
        leftIcons={[
          <FilterTags
            selectedTags={tags}
            allTags={allTags}
            setSelectedTags={setTags}
          />,
          <FilterSelect
            title='Source'
            noneText='All Sources'
            Icon={SourceIcon}
            horizontalMenu='left'
            items={SOURCES.map(item => item.name)}
            selected={SOURCES.find(s => s.id === source)?.name || 'All Sources'}
            setSelected={(item) => {
              const selected = SOURCES.find(s => s.name === item)?.id;
              setSource(selected);
            }}
          />,
          <FilterStatus
            statuses={NOTIFICATIONS}
            selectedStatuses={notifications}
            setStatuses={handleNotificationsChange}
            title='Notifications'
            Icon={NotificationsIcon}
          />,
          <FilterStartEndDate
            dateRange={date}
            setDateRange={setDate}
            startEmpty
          />,
          <FilterText
            placeholder="Referral"
            value={referrer}
            onChange={setReferrer}
            title='Referral'
            Icon={ReferralIcon}
            horizontalMenu='left'
          />
        ]}
        rightIcons={[
          <Grid marginRight="5px">
            <HeaderSearch
              justIcon={justIcon}
              placeholder={searchSelected === 'Name' ? 'LastName FirstName' : searchSelected}
              value={searchText}
              onChange={onChangeSearch}
              options={[{ value: 'Name', label: 'Name' }, { value: 'Phone', label: 'Phone' }, { value: 'Email', label: 'Email' }]}
              selectedOption={searchSelected}
              setOption={onChangeSelected}
            />
          </Grid>,
          <HeaderButton Icon={AddIcon} title='Add Leads' marginRight={5} onClick={handleAdd} className='sked-test-leads-add-lead' />,
          <HeaderButton
            onClick={handleReset}
            title='Reset Filters'
            borderSolid
          />,
        ]}
        onlyIconsWidth={890}
        breakPoints={[
          {
            width: 526,
            mobileItems: [7]
          },
          {
            width: 440,
            mobileItems: [0, 1, 2, 3, 4, 7]
          },
        ]}
      />
      <div>
        <LeadTable
          busy={busy}
          leads={leads}
          remove={handleRemoveLead}
          setPage={setPage}
          page={page}
          totalPages={totalPages}
          perPage={perPage}
          totalCount={totalCount}
          setPerPage={handlePerPage}
          getLeads={getLeads}
          maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
        />
        <AddLead open={open} setOpen={setOpen} allTags={allTags} />
      </div>
    </>
  );
};

export default Leads;
