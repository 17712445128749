import React, { ReactElement, useRef, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
  Typography, Chip, Collapse, IconButton, Grid, Tooltip,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import HelpIcon from '@mui/icons-material/Help';

import SettingsIcon from '../../icons/Settings.icon';
import SwitchIcon from '../../icons/Switch.icon';
import RocketShipIcon from '../../icons/RocketShip.icon';

import * as loginActions from '../../routes/Login/login.actions';
import { Banner } from '../../routes/Admin/Banners/banners.types';
import { Office, SPARK_FEATURE } from '../../routes/Login/login.reducer';
import SettingsMega from '../SettingsMega/SettingsMega.component';
import { headers, navItems, NavItem, Header } from './drawerMenuItems';
import ChangeOffice from './ChangeOffice.component';
import { useStyles } from './drawerMenu.styles';

const nestNavItems = (items: NavItem[]) => headers.map(h => {
  const newItems = items.filter(({ header }) => {
    if (h.id !== header) {
      return false;
    }
    return true;
  });
  return { ...h, items: newItems };
});

const emptyArray: NavItem[] = [];
const emptyString: string[] = [];

const filterByFeatures = (
  features: string[], isSpark?: boolean, isAdmin?: boolean
) => (items: NavItem[] = emptyArray): NavItem[] => {
  return items.map(i => {
    const matches = R.intersection(i.features || emptyString, features);
    return {
      ...i,
      isEnabled: !i.features ? true : matches.length > 0,
      items: filterByFeatures(features, isSpark, isAdmin)(i.items)
    };
  }).filter(i => {
    if (!i.onlyShowFeatures) {
      return true;
    }
    const onlyMatch = R.intersection(i.onlyShowFeatures, features);
    return onlyMatch.length === i.onlyShowFeatures.length;
  }).filter(i => {
    if (!i.hideFeatures) {
      return true;
    }
    const hideMatch = R.intersection(i.hideFeatures, features);
    return hideMatch.length === 0;
  }).filter(i => {
    if (!i.hideSpark) {
      return true;
    }
    return !isSpark;
  }).filter(i => {
    if (i.isAdmin) {
      return isAdmin;
    }
    return true;
  });
};


const setActiveStatus = (pathname: string) => (items = emptyArray): NavItem[] => {
  return items.map(i => {
    const index = pathname.indexOf(i.path);
    return R.merge(i, {
      isActive: index === 0,
      items: setActiveStatus(pathname)(i.items)
    });
  });
};

type BadgeType = { inbox: number; reviews: number }
type BadgeKeys = keyof BadgeType;

const setBadges = (badges: BadgeType) => (items: NavItem[]) => {
  return items.map(i => {
    const badge = badges[i.id as BadgeKeys] || 0;
    return R.assoc('badge', badge, i);
  });
};

const filterHeaders = (headers: Header[]) => headers.filter(({ items }) => items.length > 0);

interface LinkProps extends React.HTMLAttributes<Element> {
  children?: ReactElement[] | ReactElement;
  href: string;
  selected?: boolean;
}

function ListItemLink({ href, ...props }: LinkProps) {
  if (href) {
    return <ListItem button href={'#' + href} component="a" {...props} />;
  }
  return <ListItem button {...props} />;
}

interface ActualDrawerProps {
  features: string[];
  badges: {inbox: number, reviews: number};
  isAdmin: boolean;
  agency: boolean;
}

const InnerActualDrawer = ({
  features, badges, isAdmin, agency,
}: ActualDrawerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openSettings, setOpenSettings] = useState(false);
  const settingsRef = useRef<HTMLParagraphElement>(null);

  const isSpark = useMemo(() => {
    return R.includes(SPARK_FEATURE, features);
  }, [features]);
  const sparkSKEDSignUp = useMemo(() => {
    return R.includes('SparkSKEDSignUp', features);
  }, [features]);

  const nestedNavItems = useMemo(() => {
    return R.pipe(
      filterByFeatures(features, isSpark, isAdmin),
      setActiveStatus(location.pathname),
      setBadges(badges),
      nestNavItems,
      filterHeaders
    )(navItems);
  }, [navItems, features, isSpark, isAdmin, location, badges]);

  const handleSettings = useCallback((isSettings: boolean) => () => {
    setOpenSettings(!!isSettings);
  }, []);

  const onCloseSettings = useCallback(() => {
    setOpenSettings(false);
  }, []);

  type keys = keyof NavItem;

  type OpenType = { [key in keys]?: boolean };

  const [open, setOpen] = React.useState<OpenType>(R.pipe(
    R.map(R.prop('items')),
    R.flatten,
    R.filter(R.pipe(R.prop('items'), R.isEmpty, R.not)),
    R.map(({ name }) => [name, false]),
    (props: [string, boolean][]) => R.fromPairs(props),
  )(nestedNavItems));

  const handleClick = useCallback((name: keys) => {
    setOpen({
      ...open,
      [name]: !open[name],
    });
  }, [open]);

  const resetSelectedOffice = useCallback(() => {
    dispatch(loginActions.resetSelectedOffice());
  }, []);

  const getClassTestName = useCallback((name: string, subName?: string) => {
    if (subName) {
      return `sked-test-sidenav-${getLowerCase(subName)}-${getLowerCase(name)}`;
    }
    return `sked-test-sidenav-${getLowerCase(name)}`;
  }, []);

  const handleHelp = useCallback(() => {
    window.open('https://skedlife.zendesk.com/hc/en-us?utm_source=admin&utm_content=bluesidenavlink', '_blank', 'noreferrer');
  }, []);

  const getLowerCase = useCallback((text: string) => {
    return text ? text.toLowerCase().replace(/ /g, '-') : '';
  }, []);

  return (
    <>
      <List className={classes.drawerList} style={{ paddingBottom: (isSpark && sparkSKEDSignUp) ? 147 : 102 }}>
        {nestedNavItems.map(({ items, display }) => [
          display && [
            <ListSubheader
              key={display}
              disableSticky
              classes={{ root: classes.listSubheaderRoot }}>
              <Typography
                classes={{ root: classes.listSubheaderTypography }}
                variant='body1'
              >
                {display}
              </Typography>
            </ListSubheader>,
          ],
          items.map(({ path, name, Icon, badge, isActive, isEnabled,
            invertedName, id, items, isNew, isBeta, subName, ImageName }) => {
            const classTestName = getClassTestName(name, subName);
            return (
              [
                <ListItemLink
                  key={name}
                  href={path}
                  onClick={() => (items && items.length > 0) ? handleClick(name as keys) : null}
                  className={`${classes.listItem} ${id === 'onboarding' ? classes.onboardItem : ''} ${!isEnabled ? classes.listItemDisabled : ''} ${classTestName}`}
                  selected={isActive}
                >
                  <Grid className={classes.itemContainer}>
                    <ListItemIcon className={classes.itemIcon}>
                      <Icon className={!isSpark && id === 'spark' ? classes.opacity : ''} color={(!isSpark && id === 'spark') ? '' : '#FAFAFA'} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant='body1'
                          style={{
                            marginBottom: (subName && name) ? -4 : 0
                          }}
                          className={`${invertedName ?
                            classes.name : classes.subName} ${isEnabled ?
                            '' : classes.listItemDisabled}`}>
                          {subName}
                        </Typography>}
                      secondary={
                        <span
                          style={{ display: 'flex', alignItems: 'center' }}>
                          {!ImageName &&
                          <span className={
                            `${invertedName ?
                              classes.subName : classes.name}`}>
                            {name}
                          </span>}
                          {ImageName &&
                          <ImageName
                            className={
                              `${classes.imageName} ${isSpark ?
                                '' : classes.opacity}`}
                            color={isSpark ? '#FAFAFA' : ''} />}
                          {isNew ?
                            <span className={classes.newLabel}>
                            New
                            </span> : null}
                          {isBeta ?
                            <span className={classes.betaLabel}>
                            Beta
                            </span> : null}
                        </span>}
                    />
                    <div style={{ flexGrow: 1 }}></div>
                    {items && items.length > 0 && (open[name as keys] ? <ExpandLess /> : <ExpandMore />)}
                    {Number(badge) > 0 && <Chip classes={{ root: classes.chipRoot }} size="small" label={badge} color="default" />}
                  </Grid>
                </ListItemLink>,
                (items && items.length > 0 ?
                  <Collapse key={name + '-collapose'} in={open[name as keys]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {items.map(({ path, name: itemName, isActive, isEnabled }) => (
                        <ListItemLink
                          key={itemName}
                          href={path}
                          className={`${classes.listSubItem} ${classTestName}-${getLowerCase(itemName)}`}
                          selected={isActive}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant='body1'
                                className={
                                  `${classes.subMenuText} ${!isEnabled ?
                                    classes.subMenuDisabled : ''}`}>
                                {itemName}
                              </Typography>
                            } />
                        </ListItemLink>
                      ))}
                    </List>
                  </Collapse> : null)
              ]);
          })
        ]
        )}
      </List>
      {(isSpark && sparkSKEDSignUp) && (
        <Grid className={classes.skedContainer}>
          <ListItemLink
            href="/sign-up"
            className={`${classes.listItem} ${classes.settingsItem} sked-test-menu-sked-button`}
            selected={location.pathname.includes('sign-up')}
          >
            <Grid className={classes.itemContainer}>
              <ListItemIcon className={classes.itemIcon}>
                <RocketShipIcon style={{ width: 26, height: 26 }} color='#FAFAFA' />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant='body1' className={classes.name}>Upgrade to SKED</Typography>}
              />
            </Grid>
          </ListItemLink>
        </Grid>
      )}
      <Grid
        className={`${classes.settingsContainer} ${(isSpark && sparkSKEDSignUp) ? classes.noBorderTop : ''}`}
      >
        <ListItemLink
          href={''}
          onClick={handleHelp}
          className={
            `${classes.listItem} ${classes.settingsItem} sked-test-help-center-button`
          }
        >
          <Grid className={classes.itemContainer}>
            <ListItemIcon className={classes.itemIcon}>
              <HelpIcon className={classes.helpIcon} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='body1' className={classes.name}>Help Center</Typography>}
            />
          </Grid>
        </ListItemLink>
        <Grid className={classes.settingsContent}>
          <ListItemLink
            href={''}
            onClick={handleSettings(true)}
            className={
              `${classes.listItem} ${classes.settingsItem} ${(!isAdmin && !agency) ? classes.borderRadius : ''} sked-test-settings-button`
            }
          >
            <Grid className={`${classes.itemContainer} ${openSettings ? classes.hover : ''} ${(!isAdmin && !agency) ? classes.hoverRadius : ''}`}>
              <ListItemIcon className={classes.itemIcon}>
                <SettingsIcon color='#FAFAFA' />
              </ListItemIcon>
              <ListItemText
                primary={<Typography ref={settingsRef} variant='body1' className={classes.name}>Settings</Typography>}
              />
            </Grid>
          </ListItemLink>
          {(isAdmin || agency) &&
          <Grid className={classes.switchOffice} onClick={resetSelectedOffice}>
            <Tooltip title="Switch Office" placement='top' arrow>
              <Grid display="flex" alignItems="center" justifyContent="center">
                <SwitchIcon />
              </Grid>
            </Tooltip>
          </Grid>
          }
        </Grid>
      </Grid>
      <SettingsMega
        anchorEl={settingsRef?.current}
        onClose={onCloseSettings}
        open={openSettings}
      />
    </>
  );
};

const ActualDrawer = React.memo(InnerActualDrawer, (prev, next) => {
  return R.equals(prev, next);
});

type DrawerMenuProps = {
  desktopOpen: boolean;
  handleDrawerToggle: () => void;
  setDesktopOpen: (value: boolean) => void;
  features: string[];
  badges: {inbox: number, reviews: number};
  office: Office;
  parentOfficeFeatures: string[];
  group: Office[];
  agency: boolean;
  banners: Banner[];
  isAdmin: boolean;
  mobileOpen: boolean;
}

const InnerDrawerMenu = ({
  mobileOpen, desktopOpen, handleDrawerToggle,
  setDesktopOpen, features, badges, office,
  parentOfficeFeatures, group, agency, banners, isAdmin,
}: DrawerMenuProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectOffice = useCallback((newOffice: Office) => {
    if (newOffice.id === office.id) {
      return;
    }
    dispatch(loginActions.selectOffice(newOffice, navigate, true));
  }, [office]);

  const hasMenu = useMemo(() => {
    return R.length(group) > 1 &&
      !R.includes('HideOfficeGroup', parentOfficeFeatures) && !agency;
  }, [group, parentOfficeFeatures, agency]);

  const container = useMemo(() => {
    return window !== undefined ? () => window.document.body : undefined;
  }, []);

  return (
    <nav className={classes.root}>
      {/* Mobile mode */}
      <Grid sx={{
        display: {
          xs: 'block',
          sm: 'none',
        },
      }}>
        <Drawer
          container={container}
          variant="temporary"
          // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaperMobile,
            root: classes.drawerRootMobile,
            docked: classes.drawerRootMobile
          }}
          ModalProps={{ keepMounted: true }}
        >
          <div className={`${classes.headerContainer} ${classes.headerContainerMobile}`}>
            <ChangeOffice
              selectOffice={selectOffice}
              office={office}
              group={group}
              hasMenu={hasMenu}
            />
          </div>
          <ActualDrawer
            features={features}
            badges={badges}
            isAdmin={isAdmin}
            agency={agency}
          />
        </Drawer>
      </Grid>
      {/* Desktop mode */}
      {desktopOpen && <Grid sx={{
        position: 'relative',
        display: {
          xs: 'none',
          sm: 'block',
        },
      }}>
        <div className={classes.headerRoot} style={{ backgroundColor: banners.length ? banners[0].backgroundColor : '#FFF' }}>
          <div
            className={classes.headerContainer}
            style={{
              paddingLeft: isAdmin ? 32 : 16,
              paddingRight: hasMenu ? 6 : 12 }}>
            <ChangeOffice
              selectOffice={selectOffice}
              office={office}
              group={group}
              hasMenu={hasMenu}
            />
          </div>
        </div>
        <Drawer
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
          anchor="left">
          <ActualDrawer
            features={features}
            badges={badges}
            isAdmin={isAdmin}
            agency={agency}
          />
        </Drawer>
        <IconButton
          color='inherit'
          className={
            `${classes.hideButton} sked-test-hide-side-nav-button`
          }
          onClick={() => setDesktopOpen(false)}
        >
          <ArrowBackIcon style={{ width: 17, height: 17 }} />
        </IconButton>
      </Grid>
      }
    </nav>
  );
};

const DrawerMenu = React.memo(InnerDrawerMenu, (prev, next) => {
  return R.equals(prev, next);
});

export default DrawerMenu;
