import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingRight: 15,
    paddingLeft: 15,
    flex: '1 1',

    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '6px',
      },
    },
  },
  inboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  formContainer: {
    position: 'sticky',
    bottom: 5,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px solid lightgray',
    borderRadius: 4,
    margin: 5,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
      paddingLeft: '5px',
    }
  },
  formless: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
      paddingLeft: '5px',
    }
  },
  cancelbutton: {
    backgroundColor: 'white',
  },
  inputArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',
    }
  },
  input: {
    width: '100%',

    '& .MuiInputBase-multiline': {
      padding: '12px',
      marginTop: '5px',
      backgroundColor: 'white',
    },
    '& textarea': {
      '&::-webkit-scrollbar': {
        width: '6px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(206, 206, 206)',
        borderRadius: ' 8px',

        '&:hover': {
          backgroundColor: 'gray',
          borderRadius: '8px',
        },
      },
    }
  },
  otherbuttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  sendButtons: {
    marginRight: 5,
  },
  dropdownButton: {
    padding: '1px 3px',
    fontSize: 14,
    textTransform: 'none',
    borderRadius: 5,
    background: '#65B67F',
    color: '#FFF',
    border: 'none',
    margin: 0,
    minWidth: '0px !important',

    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#65B67F'
    },

    '&:disabled': {
      filter: 'brightness(0.95)',
      color: '#FFF',
      background: '#65B67F',
      opacity: 0.5,
    }
  },
  dropdownButtonWithDt: {
    padding: '0px 4px 0px 8px',
    gap: 4,
    border: '1.5px solid #0000001A',
    borderLeft: 'none',
    fontSize: 14,
    textTransform: 'none',
    borderRadius: 5,
    background: 'white',
    color: '#616166',
    margin: 0,
    minWidth: '0px !important',
    fontWeight: 'lighter !important',

    '&:hover': {
      backgroundColor: '#a5a5a521'
    },

    '&:disabled': {
      filter: 'brightness(0.95)',
      color: 'unset',
    }
  },
  chevIcon: {
    margin: '0px 5px',
  },
  chevIconScheduled: {
    marginRight: 8,
    marginLeft: 2,
  },
  sendButton: {
    padding: '4px 12px',
    fontSize: 14,
    textTransform: 'none',
    borderRadius: 5,
    background: '#65B67F',
    color: '#FFF',
    border: 'none',
    margin: 0,
    minWidth: '0px !important',

    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#65B67F'
    },

    '&:disabled': {
      filter: 'brightness(0.95)',
      color: '#FFF',
      background: '#65B67F',
      opacity: 0.5,
    }
  },
  credits: {
    fontSize: 12,
    color: 'rgba(0,0,0,.5)',
    marginLeft: 5,
  },
  systemMessage: {
    width: '100%',
    textAlign: 'center',
    color: 'gray'
  },
  loader: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'left',

    '& span': {
      marginLeft: 8,
      fontSize: 16,
      color: 'gray',
      fontWeight: 450,
    }
  },
  avatar: {
    width: 32,
    height: 32,
    color: '#FFF',
    fontWeight: 500,
    fontSize: 12,
    marginRight: 5,
  },
  titleButton: {
    textTransform: 'none',
  },
  date: {
    fontSize: 12,
    color: 'rgba(0,0,0,.5)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: -10,
  },
  fileText: {
    height: '25px',
    lineHeight: '25px',
    overflow: 'hidden',
    paddingLeft: 5,
  },
  fileSize: {
    lineHeight: '25px',
    margin: 'unset',
  },
  fileClose: {
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 5,
    cursor: 'pointer',
    fontSize: 18,
  },
  warning: {
    maxWidth: '100% !important',
    marginBottom: -5,
    marginRight: 10,
    marginLeft: 10,
    height: 'min-content',
    padding: '5px 10px !important',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 'unset !important',
    borderBottomRightRadius: 'unset !important',
    '& p': {
      marginBottom: 'unset !important',
    },
  },
  quickreply: {
    height: 15,
  },
  delete: {
    fontSize: 16,
  },
}));
