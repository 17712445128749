import React from 'react';
import {
  MenuItem,
  Button,
  Paper,
  FormHelperText,
  Select
} from '@mui/material';
import { PopupTemplate } from '../../../../services/Popup.js';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import Loading from '../../../../components/Loading/Loading.component';
import { Grouping, Section } from './Grouping.component.jsx';

const appointmentLengthOptions = [
  { label: '5 minutes', value: 5 },
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 }
];

export const NoneIntegrationTemplate = ({
  busy,
  integrationMiscPatch,
  aptLength,
  saveEHRSettings,
}) => {
  const handleChange = e => {
    integrationMiscPatch('aptLength', e.target.value);
  };
  return (
    <>
      <Header title='Other Settings' />
      <div>
        <PopupTemplate />
        {busy && <Loading loading />}
        <div style={{ margin: '20px', marginBottom: '60px' }}>
          <Paper style={{ padding: '20px', marginTop: '10px' }}>
            <Section>
              <PageHeader variant='h6'>Scheduling Settings</PageHeader>
              <Select
                labelId="block-label"
                id="block"
                value={aptLength}
                onChange={handleChange}
                variant='standard'
              >
                {appointmentLengthOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>
                This setting will change the listed time intervals when scheduling or rescheduling from SKED and the size of the blocks on the calendar.
              </FormHelperText>
            </Section>
            <Button
              variant='contained'
              disabled={busy}
              onClick={() => saveEHRSettings({
                blockLength: aptLength,
              }, 'None')}>
              Save
            </Button>
          </Paper>
          <Grouping />
        </div>
      </div>
    </>
  );
};
