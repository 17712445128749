import React, { useEffect, useState } from 'react';
import {
  TextField, TableContainer, Table, TableBody, TableRow, TableCell,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Info from '@mui/icons-material/Info';
import { propOr, merge, equals, } from 'ramda';
import { PhotoshopPicker } from 'react-color';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import { setQueryParam } from '../../../services/utilities.js';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 234,
  },
});

const styles = {
  popup: {
    position: 'fixed',
    left: '17%',
    zIndex: '2',
    top: '25%'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
    marginLeft: '45%',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
};

const useStyles = makeStyles(() => ({
  pickerHeader: {
    '& > div': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: 5,
      paddingLeft: 5,
    }
  }
}));

const TypesTable = ({
  name,
  internalName,
  duration,
  canChange,
  canSee,
  canCancel,
  color,
  notAllowedMinutesBeforeClosing,
  partOfCarePlan,
  rescheduleCutoff,
  cancelCutoff,
  scheduleCutoff,
  ehr,
  hasScheduleCutoff,
  hasCutoff,
  state,
  updateType,
  updateTypeColor,
  close,
  openColor,
  selectTemp,
}) => {
  const classes = useStyles();
  const colorForeground = color !== null && color !== undefined ?
    { h: color.hue, s: color.saturation, l: color.lightness } :
    { h: 0, s: 0, l: 100 };
  const colorBackground = color !== null && color !== undefined ?
    'hsl(' + color.hue + ',' + color.saturation + '%,' + color.lightness + '%)' :
    'hsl(0,0,100)';

  return (
    <TableContainer>
      <Table size="small" style={{ overflowY: 'scroll' }}>
        <TableBody>
          {ehr === 'None' &&
            <TableRow key={0}>
              <TableCell><b>Internal Name:</b></TableCell>
              <TableCell>
                <TextField
                  value={internalName}
                  style={{ width: '100%' }}
                  onChange={(e) => updateType('internalName', e.target.value)}
                  label="Internal Name"
                />
              </TableCell>
            </TableRow>}
          <TableRow key={1}>
            <TableCell><b>Display Name:</b></TableCell>
            <TableCell>
              <TextField
                value={name}
                style={{ width: '100%' }}
                onChange={(e) => updateType('name', e.target.value)}
                label="Display Name"
              />
            </TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell><b>Duration:</b></TableCell>
            <TableCell>
              <TextField
                value={duration}
                type="number"
                style={{ width: '100%' }}
                onChange={(e) => updateType('duration', Number(e.target.value))}
                label="Duration"
              />
            </TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell><b>Enable Closing Limit:</b>
              &nbsp;
              <Tooltip
                title="Add a limit to how close a client can schedule to closing."
                placement="top"
                arrow>
                <Info style={{ fontSize: '12px' }} />
              </Tooltip>
            </TableCell>
            <TableCell>
              <input
                type='checkbox'
                style={{ marginLeft: '50%' }}
                checked={notAllowedMinutesBeforeClosing !== null ? 1 : 0}
                onChange={(e) => updateType('notAllowedMinutesBeforeClosing', e.target.checked ? 0 : null)} />
            </TableCell>
          </TableRow>
          {notAllowedMinutesBeforeClosing !== null && <TableRow key={4}>
            <TableCell><b>Closing Limit:</b>
              &nbsp;
              <Tooltip
                title="How many minutes before closing can a client schedule this appointment type? Set to 0 to allow scheduling at closing."
                placement="top"
                arrow>
                <Info style={{ fontSize: '12px' }} />
              </Tooltip>
            </TableCell>
            <TableCell style={{ width: '339.65px' }}>
              <input
                className="input-width"
                style={{ width: '35px' }}
                type="number"
                value={notAllowedMinutesBeforeClosing}
                onChange={(e) => updateType('notAllowedMinutesBeforeClosing', Number(e.target.value))} />
              &nbsp;
              minutes
            </TableCell>
          </TableRow>}
          <TableRow key={5}>
            <TableCell><b>Can Cancel:</b></TableCell>
            <TableCell>
              <input
                type='checkbox'
                style={{ marginLeft: '50%' }}
                checked={canCancel ? 1 : 0}
                onChange={(e) => updateType('canCancel', e.target.checked)} />
            </TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell><b>Can Reschedule:</b></TableCell>
            <TableCell>
              <input
                type='checkbox'
                style={{ marginLeft: '50%' }}
                checked={canChange ? 1 : 0}
                onChange={(e) => updateType('canChange', e.target.checked)} />
            </TableCell>
          </TableRow>
          <TableRow key={7}>
            <TableCell><b>Can See:</b></TableCell>
            <TableCell>
              <input
                type='checkbox'
                style={{ marginLeft: '50%' }}
                checked={canSee ? 1 : 0}
                onChange={(e) => updateType('canSee', e.target.checked)} />
            </TableCell>
          </TableRow>
          <TableRow key={8}>
            <TableCell>
              <b>Allow on Care Plan:</b>
              &nbsp;
              <CustomWidthTooltip
                title="With this checkbox enabled, this appointment code counts toward care plan visits."
                placement="top"
                arrow>
                <Info style={{ fontSize: '12px' }} />
              </CustomWidthTooltip>
            </TableCell>
            <TableCell>
              <input
                type='checkbox'
                style={{ marginLeft: '50%' }}
                checked={partOfCarePlan ? 1 : 0}
                onChange={(e) => updateType('partOfCarePlan', e.target.checked)} />
            </TableCell>
          </TableRow>
          {hasScheduleCutoff &&
            <>
              <TableRow key={14}>
                <TableCell><b>Enable Schedule Cutoff:</b>
                  &nbsp;
                  <Tooltip
                    title="Add a limit to how soon a client can schedule an appointment."
                    placement="top"
                    arrow>
                    <Info style={{ fontSize: '12px' }} />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <input
                    type='checkbox'
                    style={{ marginLeft: '50%' }}
                    checked={scheduleCutoff !== null ? 1 : 0}
                    onChange={(e) => updateType('scheduleCutoff', e.target.checked ? 0 : null)} />
                </TableCell>
              </TableRow>
              {scheduleCutoff !== null &&
                <TableRow key={10}>
                  <TableCell><b>Schedule Cutoff:</b>
                    &nbsp;
                    <Tooltip
                      title="How many minutes before the appointment time a client can reschedule."
                      placement="top"
                      arrow>
                      <Info style={{ fontSize: '12px' }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: '339.65px' }}>
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'days', scheduleCutoff)}
                      onChange={(e) => updateType('scheduleCutoff', merge(scheduleCutoff, { days: Number(e.target.value) }))} />
                    &nbsp;
                    days
                    &nbsp;
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'hours', scheduleCutoff)}
                      onChange={(e) => updateType('scheduleCutoff', merge(scheduleCutoff, { hours: Number(e.target.value) }))} />
                    &nbsp;
                    hours
                    &nbsp;
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'minutes', scheduleCutoff)}
                      onChange={(e) => updateType('scheduleCutoff', merge(scheduleCutoff, { minutes: Number(e.target.value) }))} />
                    &nbsp;
                    minutes
                  </TableCell>
                </TableRow>}
            </>}
          {hasCutoff &&
            <>
              <TableRow key={9}>
                <TableCell><b>Enable Reschedule Cutoff:</b>
                  &nbsp;
                  <Tooltip
                    title="Add a limit to how close to the appointment time a client can reschedule."
                    placement="top"
                    arrow>
                    <Info style={{ fontSize: '12px' }} />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <input
                    type='checkbox'
                    style={{ marginLeft: '50%' }}
                    checked={rescheduleCutoff !== null ? 1 : 0}
                    onChange={(e) => updateType('rescheduleCutoff', e.target.checked ? 0 : null)} />
                </TableCell>
              </TableRow>
              {rescheduleCutoff !== null &&
                <TableRow key={10}>
                  <TableCell><b>Reschedule Cutoff:</b>
                    &nbsp;
                    <Tooltip
                      title="How many minutes before the appointment time a client can reschedule."
                      placement="top"
                      arrow>
                      <Info style={{ fontSize: '12px' }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: '339.65px' }}>
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'days', rescheduleCutoff)}
                      onChange={(e) => updateType('rescheduleCutoff', merge(rescheduleCutoff, { days: Number(e.target.value) }))} />
                    &nbsp;
                    days
                    &nbsp;
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'hours', rescheduleCutoff)}
                      onChange={(e) => updateType('rescheduleCutoff', merge(rescheduleCutoff, { hours: Number(e.target.value) }))} />
                    &nbsp;
                    hours
                    &nbsp;
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'minutes', rescheduleCutoff)}
                      onChange={(e) => updateType('rescheduleCutoff', merge(rescheduleCutoff, { minutes: Number(e.target.value) }))} />
                    &nbsp;
                    minutes
                  </TableCell>
                </TableRow>}
              <TableRow key={11}>
                <TableCell><b>Enable Cancel Cutoff:</b>
                  &nbsp;
                  <Tooltip
                    title="Add a limit to how close to the appointment time a client can cancel."
                    placement="top"
                    arrow>
                    <Info style={{ fontSize: '12px' }} />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <input
                    type='checkbox'
                    style={{ marginLeft: '50%' }}
                    checked={cancelCutoff !== null ? 1 : 0}
                    onChange={(e) => updateType('cancelCutoff', e.target.checked ? 0 : null)} />
                </TableCell>
              </TableRow>
              {cancelCutoff !== null &&
                <TableRow key={12}>
                  <TableCell><b>Cancel Cutoff:</b>
                    &nbsp;
                    <Tooltip
                      title="How many minutes before the appointment time a client can cancel."
                      placement="top"
                      arrow>
                      <Info style={{ fontSize: '12px' }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: '339.65px' }}>
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'days', cancelCutoff)}
                      onChange={(e) => updateType('cancelCutoff', merge(cancelCutoff, { days: Number(e.target.value) }))} />
                    &nbsp;
                    days
                    &nbsp;
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'hours', cancelCutoff)}
                      onChange={(e) => updateType('cancelCutoff', merge(cancelCutoff, { hours: Number(e.target.value) }))} />
                    &nbsp;
                    hours
                    &nbsp;
                    <input
                      className="input-width"
                      style={{ width: '35px' }}
                      type="number"
                      value={propOr(0, 'minutes', cancelCutoff)}
                      onChange={(e) => updateType('cancelCutoff', merge(cancelCutoff, { minutes: Number(e.target.value) }))} />
                    &nbsp;
                    minutes
                  </TableCell>
                </TableRow>}
            </>}
          <TableRow key={13}>
            <TableCell><b>Color:</b></TableCell>
            <TableCell>
              <div style={styles.swatch} onClick={() => openColor(colorForeground)}>
                <div style={{ width: '36px', height: '14px', borderRadius: '2px', background: colorBackground }} />
              </div>
              {state.opened ?
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <PhotoshopPicker
                    header={'Color for ' + name}
                    color={state.temp}
                    className={classes.pickerHeader}
                    onChangeComplete={(color) => selectTemp(color.hsl)}
                    onCancel={() => close()}
                    onAccept={() => {
                      updateTypeColor(
                        'color',
                        {
                          hue: Math.round(Number(state.temp.h)),
                          saturation: Math.round(Number(100 * state.temp.s)),
                          lightness: Math.round(Number(100 * state.temp.l))
                        });
                    }}
                  />
                </div>
                :
                null
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EditType = ({
  open,
  type,
  onClose,
  save,
  doctor,
  ehr,
  hasCutoff,
  hasScheduleCutoff,
}) => {
  const [state, setState] = useState({ status: 'INIT', busy: false, type: {} });

  useEffect(() => {
    if (state.status === 'INIT' && open) {
      setQueryParam('apptType', type.id > 0 ? type.id : undefined);
      setState({ ...state, status: 'EDIT', type });
    } else if (!open && state.status !== 'INIT') {
      setQueryParam('apptType', undefined);
      setState({ ...state, status: 'INIT' });
    }
  }, [state.status, open]);

  const update = (data) => {
    setState(merge(state, data));
  };

  const updateType = (prop, value) => {
    setState({
      ...state,
      type: {
        ...state.type,
        [prop]: value,
      }
    });
  };

  const updateTypeColor = (prop, value) => {
    const type = merge(state.type, { [prop]: value });
    setState(merge(state, { type, opened: false }));
  };

  const selectTemp = (color) => {
    update({ temp: color });
  };

  const openColor = (color) => {
    update({ opened: true, temp: color });
  };

  const close = () => {
    update({ opened: false });
  };

  const handleClose = () => {
    close();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`Edit ${type.internalName} for ${doctor}`}
      className='sked-test-appt-types-edit-appt-modal'
      size='xs'
      maxWidth={2000}
      buttons={[
        <HeaderButton
          title='Save'
          color='primary'
          disabled={equals(state.type, type)}
          onClick={() => save(state.type)}
          className='sked-test-appt-types-edit-appt-modal-button-save'
        />
      ]}
    >
      <div>
        <TypesTable
          {...state.type}
          ehr={ehr}
          hasCutoff={hasCutoff}
          hasScheduleCutoff={hasScheduleCutoff}
          state={state}
          updateType={updateType}
          updateTypeColor={updateTypeColor}
          close={close}
          openColor={openColor}
          selectTemp={selectTemp}
        />
      </div>
    </Modal>
  );
};

export default EditType;
