import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ItemTypes } from './constants.jsx';
import { pickColor } from '../../../services/utilities.js';

import { useStyles } from './type-drag.styles';

export function TypeDrag({
  type,
  index = null,
  selected,
  handleSeletion = null,
  activeDrag = false,
  selectedTypes,
  setActiveDrag = null,
  clearSelection,
  activeIndex,
}) {
  const [_, dragRef, dragPreview] = useDrag({
    type: ItemTypes.TYPE,
    item: () => {
      const selecteds = selectedTypes;
      setActiveDrag(true);

      const exists = selecteds.find((item) => item.id === type.id);
      if (!exists) {
        handleSeletion(type, index);
        if (index !== activeIndex) {
          return { selecteds: [type], from: index };
        }
        selecteds.push(type);
      }
      return { selecteds, from: index };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      setActiveDrag(false);
      if (dropResult) {
        clearSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const classes = useStyles();

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  return (
    <div
      ref={dragRef}
      onClick={() => handleSeletion(type, index)}
      className={classes.root}
      style={{
        opacity: (activeDrag && selected) ? 0.5 : 1,
        backgroundColor: selected ? 'rgb(0, 139, 207)' : '#CECECE', // pickColor(type.color),
        borderWidth: selected ? 1 : 1,
        borderColor: selected ? 'rgb(0, 139, 207)' : 'rgb(191, 191, 191)',
        cursor: selected ? 'pointer' : 'default',
      }}>
      <div
        className={classes.circle}
        style={{
          backgroundColor: pickColor(type.color),
        }}>
      </div>
      <div
        key={type.id}
        className={classes.text}
      >
        {type.internalName + ' (' + type.name + ')'}
      </div>
    </div>
  );
}

export default TypeDrag;
