import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import axios, { Canceler } from 'axios';
import {
  MenuItem, TextField, Checkbox, Button, Menu, Select,
  FormControlLabel, ListItemText, Tooltip, InputLabel, FormControl,
  FormHelperText, ListItemIcon, Chip, Paper, Autocomplete,
  SelectChangeEvent,
} from '@mui/material';
import Error from '@mui/icons-material/Error';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Info from '@mui/icons-material/Info';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from '@mui/styles';
import * as R from 'ramda';
import queryString from 'query-string';
import {
  LocalDate, LocalDateTime, ZonedDateTime, ZoneId, ZoneOffset,
} from '@js-joda/core';
import {
  now, format,
} from '../../services/joda.js';
import {
  PopupTemplate, popupWithCancel, popupWithCustoms,
} from '../../services/Popup.js';
import api from '../../services/api.js';
import {
  canSend, attachEmailBody, etd,
} from '../../services/utilities.js';
import { Editor } from '../Editor/editor.component';
import { Placeholder } from '../Placeholders/Placeholders.component';
import OneLineEditor from '../Editor/components/OneLineEditor.component';
import TypeSelector from '../TypeSelector/type-selector.component';
import { AppointmentType } from '../../routes/Appointments/appointments.types';
import ClientSelector from '../ClientSelector/client-selector.component';
import { ClientListSearch } from '../../routes/Automations/components/client-list-search.component';
import { AutomationsList } from '../../routes/Automations/automations.page';
import AutomationTemplateDialog from '../../routes/Templates/automation-template-dialog.component';
import { usePromise } from '../../services/promise.hook';
import { Office } from '../../routes/Login/login.reducer';
import { CustomContentType, CustomButtonType } from '../../routes/Login/login.actions';
import { Email, Push, Sms, AttachmentType } from '../../routes/Templates/routes/TemplatesEdit/templates-edit.reducer';
import * as templatesEditActions from '../../routes/Templates/routes/TemplatesEdit/templates-edit.actions';
import * as messagesNewActions from '../../routes/Messages/routes/MessagesNew/messages-new.actions';
import * as recurringEditActions from '../../routes/RecurringMessage/routes/RecurringEdit/recurring-edit.actions';
import * as messagesActions from '../../routes/Messages/messages.actions';
import * as automationsActions from '../../routes/Automations/automations.actions';
import { Automation, DefaultList, GetFilter, Campaign } from '../../routes/Automations/automation-types';
import Header from '../PageHeader/PageHeader.component';
import SaveAsButton from '../SaveButton/SaveAsButton.component';
import { Lead } from '../../routes/Leads/leads.reducer';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import {
  fetchSettingsList
} from '../../routes/Settings/routes/NewPatientPortal/NewPatientPortal.actions.js';
import SendModal from './SendModal.component';

type Professional = {
  id: number;
  firstName: string;
  displayFirstName: string;
  lastName: string;
  displayLastName: string;
  types?: AppointmentType[];
  hidden?: boolean;
}
type Client = {
  id: number;
  firstName: string;
  lastName: string;
  allowSMS?: boolean;
  phone: string;
  birthday?: string;
  allowEmail?: boolean;
  email: string;
  index?: number;
}
type TagType = {
  tagId: number;
  tag?: string;
};
type PatchType = typeof messagesActions.messagesPatch;

const fetchForms = () => api.get('form')
  .then((forms) => R.sortBy(R.prop('name'), forms));

const LessMarginFormControlLabel = withStyles({
  root: {
    marginRight: '5px',
  },
})(FormControlLabel);

const Section = withStyles({
  root: {
    padding: '10px 20px 16px',
    marginBottom: 12,
    overflow: 'auto',
  }
})(Paper);

const singularUnits = [
  { name: 'Week', id: 1 },
  { name: 'Day', id: 2 },
  { name: 'Hour', id: 3 },
  { name: 'Minute', id: 4 },
];
const pluralUnits = [
  { name: 'Weeks', id: 1 },
  { name: 'Days', id: 2 },
  { name: 'Hours', id: 3 },
  { name: 'Minutes', id: 4 },
];

const unitItems = (unit: { id: number; name: string }, patch: PatchType, prop = 'unit') => (
  <MenuItem
    value={unit.id}
    onClick={() => patch(prop, unit)}>
    {unit.name}
  </MenuItem>
);

const LargerSelect = withStyles({
  standard: {
    width: '256px',
  },
})(Select);

const SmallerTextField = withStyles({
  root: {
    width: '130px',
  },
})(TextField);

const SelectButton = withStyles(() => ({
  contained: {
    backgroundColor: 'rgba(0, 0, 0, 0.12);',
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.36);',
    },
    '&:disabled': {
      backgroundColor: '#008BCF',
      color: 'white',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    }
  },
}))(Button);

export const clientPlaceholders: Placeholder[] = [
  { value: '{{client.firstName}} ', name: 'First Name' },
  { value: '{{client.lastName}} ', name: 'Last Name' },
  { value: '{{client.registerLink}} ', name: 'Registration Link', feature: 'SkedApp' },
  { value: '{{client.optoutLink}} ', name: 'Opt Out Link', feature: 'HtmlEmail', isHTML: true },
  { value: '{{client.optoutUrl}} ', name: 'Opt Out Url' },
];

export const officePlaceholders: Placeholder[] = [
  { value: '{{office.name}} ', name: 'Name' },
  { value: '{{office.shortCode}} ', name: 'Provider ID', feature: 'SkedApp' },
  { value: '{{office.email}} ', name: 'Email' },
  { value: '{{office.phone}} ', name: 'Phone' }
];

export const locationPlaceholders: Placeholder[] = [
  { value: '{{location.name}} ', name: 'Name' },
  { value: '{{location.phone}}', name: 'Phone' },
  { value: '{{location.email}}', name: 'Email' },
  { value: '{{location.address.street}}', name: 'Street' },
  // { value: "{{}location.address.street2}", name: 'Street2'}
  { value: '{{location.address.city}}', name: 'City' },
  { value: '{{location.address.state}}', name: 'State' },
  { value: '{{location.address.zipcode}}', name: 'Zipcode' },
];


export const apptPlaceholders: Placeholder[] = [
  { value: '{{appt.date}} ', name: 'Date' },
  { value: '{{appt.euroDate}} ', name: 'European Date', feature: 'EuropeanPlaceholders' },
  { value: '{{appt.time}} ', name: 'Time Start' },
  { value: '{{appt.euroTime}} ', name: 'European Time Start', feature: 'EuropeanPlaceholders' },
  { value: '{{appt.type}} ', name: 'Appointment Type' },
];

const sourceList = [
  {
    value: 'EHR', label: 'EHR', features: [
      'ApptChangeMessages',
      'ApptChangeMessagesPro',
      'ApptChangeMessagesAgency'
    ]
  },
  {
    value: 'Admin', label: 'Admin', features: [
      'ApptChangeMessages',
      'ApptChangeMessagesPro',
      'ApptChangeMessagesAgency'
    ]
  },
  { value: 'App', label: 'App', features: ['ApptChangeMessagesPro'] },
  {
    value: 'Webmodule', label: 'New Patient Portal', features: [
      'ApptChangeMessagesPro', 'ApptChangeMessagesAgency'
    ]
  }
];

const changeTypesList = [
  { value: 'Added', label: 'Added' },
  { value: 'ChangeArrived', label: 'Arrived' },
  { value: 'Rescheduled', label: 'Rescheduled' },
  { value: 'ChangeCanceled', label: 'Canceled' },
];

const characterLimit = 160;
const unicodeCharacterLimit = 70;
const creditsPopup = ({
  creditsAllowed,
  creditsUsed,
  credits,
  overCost,
  response,
}: {
  creditsAllowed: number;
  creditsUsed: number;
  credits: number;
  overCost: number;
  response: (value: boolean) => void;
}) => {
  const diff = (credits + creditsUsed) - creditsAllowed;
  const remaining = creditsAllowed - (credits + creditsUsed);
  const totalCost = (credits * (overCost / 100)).toFixed(2);
  if (creditsUsed > creditsAllowed) {
    popupWithCustoms({
      title: 'Message Credit Limit Already Reached!',
      className: 'sked-one-time-message-credits-message-reached-modal',
      size: 'md',
      customContent: (t: CustomContentType) => {
        const {
          setState,
          state,
        } = t;
        return (
          <div>
            You've already reached your limit of {creditsAllowed} credits (current credit amount is {creditsUsed}). Sending this message will cost you an extra ${totalCost} on your messaging bill.
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(state.accept)}
                  onChange={(e) => setState({ accept: e.target.checked })}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="I acknowledge the above and wish to continue"
            />
          </div>
        );
      },
      customButtons: (t: CustomButtonType) => {
        const {
          state,
          setState,
          close,
        } = t;
        return (
          <>
            <HeaderButton
              borderSolid
              onClick={() => {
                close();
                /* setState({accept: false}); */
                response(false);
              }}
              title="Back"
              className='sked-one-time-message-credits-message-reached-modal-button-back'
            />
            <HeaderButton
              color='primary'
              disabled={!state.accept}
              onClick={() => {
                close();
                setState({ accept: false });
                response(true);
              }}
              title="Continue"
              className='sked-one-time-message-credits-message-reached-modal-button-save'
            />
          </>
        );
      },
    });
  } else if (diff < 0) {
    popupWithCustoms({
      title: 'Large Number of Credits!',
      className: 'sked-one-time-message-large-number-credits-modal',
      size: 'md',
      customContent: (t: CustomContentType) => {
        const {
          setState,
          state,
        } = t;
        return (
          <div>
            Sending this message will use up {credits} credits out of your total of {creditsAllowed} credits, your remaining credits will be {remaining}.
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(state.accept)}
                  onChange={(e) => setState({ accept: e.target.checked })}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="I acknowledge the above and wish to continue"
            />
          </div>
        );
      },
      customButtons: (t: CustomButtonType) => {
        const {
          state,
          setState,
          close,
        } = t;
        return (
          <>
            <HeaderButton
              borderSolid
              onClick={() => {
                close();
                /* setState({accept: false}); */
                response(false);
              }}
              title="Back"
              className='sked-one-time-message-large-number-credits-modal-button-back'
            />
            <HeaderButton
              color='primary'
              disabled={!state.accept}
              onClick={() => {
                close();
                setState({ accept: false });
                response(true);
              }}
              title="Continue"
              className='sked-one-time-message-large-number-credits-modal-button-save'
            />
          </>
        );
      },
    });
  } else {
    const overageCost = (diff * (overCost / 100)).toFixed(2);
    popupWithCustoms({
      title: 'Message Credit Limit Reached!',
      size: 'md',
      className: 'sked-one-time-message-credit-limit-modal',
      customContent: (t: CustomContentType) => {
        const {
          setState,
          state,
        } = t;
        return (
          <div>
            Sending this message will use up {credits} credits, putting you over your limit of {creditsAllowed} by {diff} credits. This will cost you an extra ${totalCost} (${overageCost} due to overage) on your messaging bill.
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(state.accept)}
                  onChange={(e) => setState({ accept: e.target.checked })}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="I acknowledge the above and wish to continue"
            />
          </div>
        );
      },
      customButtons: (t: CustomButtonType) => {
        const {
          state,
          setState,
          close,
        } = t;
        return (
          <>
            <HeaderButton
              borderSolid
              onClick={() => {
                close();
                /* setState({accept: false}); */
                response(false);
              }}
              title='Back'
              className='sked-one-time-message-credit-limit-modal-button-back'
            />
            <HeaderButton
              color='primary'
              disabled={!state.accept}
              onClick={() => {
                close();
                setState({ accept: false });
                response(true);
              }}
              title="Continue"
              className='sked-one-time-message-credit-limit-modal-button-save'
            />
          </>
        );
      },
    });
  }
};

type DataAndTypesProps = {
  types: AppointmentType[];
  professionals: Professional[];
  selectedTypes: number[];
  startDate: string;
  endDate: string;
  patch: (val1: string, val2: string | number[] | LocalDate) => void;
}

const DateAndTypes = ({
  types,
  professionals,
  selectedTypes,
  startDate,
  endDate,
  patch,
}: DataAndTypesProps) => {
  /* const realTypes = React.useMemo(() => {
    return selectedTypes.map((sId) => {
      return types.find(({ id }) => id === sId);
    });
  }, [selectedTypes]); */
  console.log('this is the selecgtred type things: ', selectedTypes);
  return (
    <div className="editor" style={{ minHeight: '700px' }}>
      <TypeSelector
        types={types}
        professionals={professionals}
        selected={R.isNil(selectedTypes) ? null : selectedTypes}
        onChange={(ts: number[]) => {
          patch('selectedTypes', ts);
          if (ts.length === types.length) {
            patch('typeStatus', 'All');
          } else {
            patch('typeStatus', 'Some');
          }
        }}
      />
      <br />
        Date Range:
        &nbsp;
      <Tooltip
        title="If a client has multiple appointments in this range, they will only get this once!"
        placement="top"
        arrow>
        <Info style={{ fontSize: '12px' }} />
      </Tooltip>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '150px',
        justifyContent: 'space-evenly',
      }}>
        <SmallerTextField
          type='date'
          label='Start Date'
          value={format(startDate, 'yyyy-MM-dd')}
          error={!startDate}
          helperText='Required field'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => patch('startDate', LocalDate.parse(e.target.value))} />
          To
        <SmallerTextField
          type='date'
          label='End Date'
          value={format(endDate, 'yyyy-MM-dd')}
          error={!startDate}
          helperText='Required field'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => patch('endDate', LocalDate.parse(e.target.value))} />
      </div>
    </div>
  );
};

type ClientSearchProps = {
  busy: boolean;
  clients: {
    data: Client[] | Lead[];
    page?: number;
    totalCount?: number;
    totalPages?: number;
  };
  selectedClients: Client[] | Lead[];
  isLead?: boolean;
  getClients: typeof messagesNewActions.getClients | typeof messagesNewActions.getLeads;
  addClient: typeof recurringEditActions.addClient | typeof messagesNewActions.addLead;
  removeClient: typeof recurringEditActions.removeClient | typeof messagesNewActions.removeLead;
  clearSelected: typeof recurringEditActions.clearSelected;
}

const ClientSearch = ({
  busy,
  clients,
  selectedClients,
  getClients,
  addClient,
  removeClient,
  clearSelected,
  isLead,
}: ClientSearchProps) => {
  return (
    <div style={{
      minHeight: '500px',
      display: 'flex',
    }}>
      <ClientSelector
        title={`Unselected ${isLead ? 'Leads' : 'Clients'}`}
        isSearch={true}
        isLead={isLead}
        busy={busy}
        clients={clients}
        search={(d: object, status: string[]) => {
          getClients({
            query: {
              ...d,
              status,
            },
            perPage: 25,
            page: 1
          });
        }}
        arrowButtonPress={(d: { search: object; page: number }, status: string[]) => {
          getClients({
            query: {
              ...d.search,
              status,
            },
            perPage: 25,
            page: d.page,
          });
        }}
        click={(c: Client) => addClient(c)}
      />
      <div style={{ width: '44px', margin: '20px' }}></div>
      <ClientSelector
        title={isLead ? 'Selected Leads' : 'Selected Clients'}
        isLead={isLead}
        isSearch={false}
        busy={busy}
        clients={{ data: selectedClients }}
        click={(c: Client) => removeClient(c as { index: number })}
        clearButton={() => clearSelected(isLead ? 'selectedLeads' : 'selectedClients')}
      />
    </div>
  );
};


const CancelToken = axios.CancelToken;
let selectedClientCancel: Canceler;

type TagEditProps = {
  tags: TagType[];
  operator: string;
  hasLeads?: boolean;
  patch: PatchType;
  addTag: (tag: TagType) => void;
  deleteTag: (tag: TagType) => void;
}
type TagEditState = {
  allTags?: TagType[];
  clientSearch?: string;
  clients?: {
    data: Client[],
    page: number;
    totalCount: number;
    totalPages: number;
  },
  busy?: boolean;
}

type SelectQuery = {
  query?: {
    tags?: { tagId?: number };
  },
  page?: number;
  search?: string;
}
const TagEdit = (props: TagEditProps) => {
  const [inputValue, setInputValue] = useState('');
  const [state, setState] = useState<TagEditState>({
    allTags: [],
    clientSearch: '',
    clients: {
      data: [],
      page: 1,
      totalCount: 0,
      totalPages: 1,
    },
    busy: false,
  });

  const update = (data: TagEditState) => {
    setState(R.merge(state, data));
  };

  useEffect(() => {
    api.get('client/tag').then((tags) => {
      const allTags: TagType[] = R.sortBy(R.prop('tag'))(tags);
      update({ allTags });
    });
    if (!R.isEmpty(props.tags))
      getSelectedClients({
        query: {
          tags: {
            [props.operator]: R.map(R.prop('tagId'))(props.tags),
          },
        },
        page: 1,
        search: '',
      });
  }, []);

  useEffect(() => {
    if (R.isEmpty(props.tags)) {
      update({
        clients: {
          data: [],
          page: 1,
          totalCount: 0,
          totalPages: 1,
        }
      });
    }
  }, [props.tags]);

  const getSelectedClients = ({ query, page, search }: SelectQuery = { query: {}, page: 1, search: '' }) => {
    const empty = R.pipe(
      R.values,
      R.flatten,
      R.isEmpty
    )(query.tags);
    if (empty) {
      return null; // default set in `componentWillReceiveProps`
    } else {
      if (selectedClientCancel)
        selectedClientCancel('User aborted request.');
      update({ busy: true });
      return api.post('client/query', {
        perPage: 25,
        page,
        query: {
          ...query,
          sortBy: [{
            direction: 'Asc',
            field: 'LastName'
          }, {
            direction: 'Asc',
            field: 'FirstName'
          }],
          leadsAndClients: props.hasLeads ? true : undefined,
        },
      }, {
        cancelToken: new CancelToken((c) => {
          selectedClientCancel = c;
        })
      }).then((clientsPageData) => {
        update({
          clients: {
            ...clientsPageData,
            page,
          },
          clientSearch: search,
          busy: false,
        });
      }).catch((error) => {
        if (axios.isCancel(error)) {
          return;
        } else {
          update({ busy: false });
          console.log(error);
        }
      });
    }
  };

  const { clients, busy } = state;
  const { operator } = props;

  const filteredOptions = R.without(props.tags, state.allTags);

  return (
    <div
      style={{
        height: '510px',
      }}
    >
      <div style={{
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
      }}>
        <div>
          <label>
            Edit Tags:
          </label>
          <br />
          <Autocomplete
            disablePortal
            disableClearable
            id="combo-box-demo"
            options={filteredOptions}
            clearOnEscape
            getOptionLabel={(option: TagType) => option.tag}
            sx={{ width: 300 }}
            value={null}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
            renderInput={(params) => <TextField {...params} />}
            onChange={(_e, tag) => {
              setInputValue('');
              if (!R.includes(tag, props.tags)) {
                const tags = R.append(tag, props.tags);
                props.addTag(tag);
                getSelectedClients({
                  query: {
                    tags: {
                      [operator]: R.map(R.prop('tagId'))(tags),
                    },
                  },
                  page: 1,
                  search: '',
                });
              }
            }}
          />
          <br />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '5px',
          }}>
            <select value={operator} onChange={(e) => {
              props.patch('operator', e.target.value);
              getSelectedClients({
                query: {
                  tags: {
                    [e.target.value]: R.map(R.prop('tagId'))(props.tags),
                  },
                },
                page: 1,
                search: '',
              });
            }}>
              <option value='And'>And</option>
              <option value='Or'>Or</option>
            </select>
            <Tooltip
              title={
                <>
                  And: Sends message to anyone who is tagged by all selected tags. (i.e. tag1 and tag2)
                  <br />
                  Or: Sends message to anyone who is tagged by at least one of the selected tags. (i.e. tag1 or tag2)
                </>
              }
              placement="top"
              arrow>
              <Info style={{
                fontSize: '14px',
                marginLeft: '10px',
              }} />
            </Tooltip>
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '50%',
          maxHeight: '20px',
          paddingLeft: 5,
          paddingTop: 5,
        }}>
          {props.tags.map((tag, ind) => {
            return (
              <Chip
                key={ind}
                label={tag.tag}
                style={{
                  marginTop: 10,
                  marginLeft: 5,
                  textOverflow: 'ellipsis',
                  fontSize: 14
                }}
                onDelete={() => {
                  props.deleteTag(tag);
                  const tags = R.without([tag], props.tags);
                  getSelectedClients({
                    query: {
                      tags: {
                        [operator]: R.map(R.prop('tagId'))(tags),
                      },
                    },
                    page: 1,
                    search: '',
                  });
                }}
              />
            );
          })}
        </div>
        <ClientSelector
          title={`Message will be sent to: ${R.isEmpty(clients.data) ? 'No one' : ''}`}
          isSearch={true}
          busy={busy}
          clients={clients}
          search={(d: object) => {
            getSelectedClients({
              query: {
                ...d,
                tags: {
                  [operator]: R.map(R.prop('tagId'))(props.tags),
                },
              },
              page: 1,
            });
          }}
          click={() => console.log('do nothing')}
          arrowButtonPress={(d: { page: number, search: object }) => {
            getSelectedClients({
              query: {
                ...d.search,
                tags: {
                  [operator]: R.map(R.prop('tagId'))(props.tags),
                },
              },
              page: d.page,
            });
          }}
        />
      </div>
      <br />
    </div>
  );
};

const dateAndTypes = ({
  types,
  professionals,
  selectedTypes,
  value,
  unit,
  beforeAfter,
  patch,
}: {
  types: AppointmentType[];
  professionals: Professional[];
  selectedTypes: number[];
  value: number;
  unit: { id: number; name?: string };
  beforeAfter: string;
  patch: PatchType;
}) => {
  /*const realTypes = React.useMemo(() => {
    return selectedTypes.map((sId) => {
      return types.find(({ id }) => id === sId);
    });
  }, [selectedTypes]);*/
  return (
    <div className="editor" style={{ minHeight: '800px' }}>
      <TypeSelector
        types={types}
        professionals={professionals}
        selected={R.isNil(selectedTypes) ? null : selectedTypes}
        onChange={(ts: number[]) => {
          patch('selectedTypes', ts);
          if (ts.length === types.length) {
            patch('typeStatus', 'All');
          } else {
            patch('typeStatus', 'Some');
          }
        }}
      />
      <br />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '250px',
        justifyContent: 'space-evenly',
      }}>
        When:
        <TextField
          name="value"
          label="Number"
          type="number"
          value={value}
          onChange={(e) => patch('value', Number(e.target.value) < 1 ? 1 : Number(e.target.value))} />
        <TextField
          select
          value={unit.id}
          label="Unit">
          {value === 1 ?
            singularUnits.map((unit) => unitItems(unit, patch))
            :
            pluralUnits.map((unit) => unitItems(unit, patch))
          }
        </TextField>
        <div style={{ float: 'left' }}>
          <SelectButton
            variant='contained'
            disabled={beforeAfter === 'Before'}
            onClick={() => patch('beforeAfter', 'Before')}>
            Before
          </SelectButton>
          &nbsp;
          <SelectButton
            variant='contained'
            disabled={beforeAfter === 'Missed'}
            onClick={() => patch('beforeAfter', 'Missed')}>
            Missed
          </SelectButton>
          &nbsp;
          <SelectButton
            variant='contained'
            disabled={beforeAfter === 'Arrived'}
            onClick={() => patch('beforeAfter', 'Arrived')}>
            Arrived
          </SelectButton>
        </div>
      </div>
      <div style={{ width: '65%', paddingTop: '5px', paddingRight: '20px', float: 'right' }}>
      </div>
    </div>
  );
};

const typesSelection = ({
  types,
  professionals,
  selectedTypes,
  patch,
  includeInactive = null,
}: {
  types: AppointmentType[];
  professionals: Professional[];
  selectedTypes: number[];
  patch: PatchType;
  includeInactive?: boolean,
}) => {
  /*const realTypes = React.useMemo(() => {
    return selectedTypes.map((sId) => {
      return types.find(({ id }) => id === sId);
    });
  }, [selectedTypes]);*/
  return (
    <div className="editor" style={{ minHeight: '400px' }}>
      <TypeSelector
        types={types}
        professionals={professionals}
        selected={R.isNil(selectedTypes) ? null : selectedTypes}
        onChange={(ts: number[]) => {
          patch('selectedTypes', ts);
          if (ts.length === types.length) {
            patch('typeStatus', 'All');
          } else {
            patch('typeStatus', 'Some');
          }
        }}
      />
      {!R.isNil(includeInactive) &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <LessMarginFormControlLabel
            control={
              <Checkbox
                checked={includeInactive}
                onChange={(e) => patch('includeInactive', e.target.checked)}
                name="acknowledge-checkbox"
                color="primary"
              />
            }
            label="Send to inactive clients?"
          />
          <Tooltip
            title="By default reactivation messages will be sent to active clients only. Check this box to have the message sent to inactive clients."
            placement="top"
            arrow>
            <Info style={{ fontSize: '14px' }} />
          </Tooltip>
        </div>}
    </div>
  );
};

const sentSelection = ({
  value,
  sendAt,
  patch
}: {
  value?: number;
  sendAt: string;
  patch: PatchType;
}) => (
  <div>
    {!R.isNil(value) && [
      <br />,
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <TextField
          name="value"
          label="Days After Last Appointment"
          type="number"
          value={value}
          error={!value}
          helperText='Required Field'
          onChange={(e) => patch('value', Number(e.target.value) < 0 ? 0 : Number(e.target.value))} />
        &nbsp;
        &nbsp;
        <Tooltip
          title="How many days after the client's last appointment should this message be sent?"
          placement="top"
          arrow>
          <Info style={{
            fontSize: '14px',
            marginLeft: '10px',
          }} />
        </Tooltip>
      </div>,
      <br />,
      <br />
    ]}
    <br />
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <TextField
        name="sendAt"
        label="Send Time"
        type="time"
        value={sendAt}
        error={!sendAt}
        helperText='Required Field'
        onChange={(e) => patch('sendAt', e.target.value)} />
      &nbsp;
      &nbsp;
      <Tooltip
        title="At what time of day should this message be sent?"
        placement="top"
        arrow>
        <Info style={{
          fontSize: '14px',
          marginLeft: '10px',
        }} />
      </Tooltip>
    </div>
    <br />
  </div>
);


export const removePlaceholders = (features: string[], holder: Placeholder) => R.cond([
  [R.has('feature'), () => R.includes(holder.feature, features)],
  [R.T, R.T]
])(holder);

const showIcon = (isValid: boolean, hasError: boolean) => {
  if (hasError) {
    return (
      <Error style={{ color: '#fb4848' }} />
    );
  } else {
    return isValid ? <CheckCircle style={{
      color: '#10b910',
    }} /> : undefined;
  }
};

export const filterSources = ({ features, ehrSystem }: { features: string[]; ehrSystem: string }) => {
  return sourceList.filter(s => (
    // Stand alone offices don't have EHR
    R.intersection(features, s.features).length > 0 &&
    !(s.value === 'EHR' && ehrSystem === 'None')
  )).map(R.pick(['label', 'value']));
};

const changeSelection = ({
  delay,
  source,
  changeType,
  patch,
  ehrSystem,
  features
}: {
  delay: number;
  source: string[];
  changeType: string[];
  patch: PatchType;
  ehrSystem: string;
  features: string[];
}) => (
  <div>
    <br />
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <TextField
        name="delay"
        label="Minutes After Appointment Change"
        type="number"
        value={delay}
        onChange={(e) => patch('delay', Number(e.target.value) < 0 ? 0 : Number(e.target.value))} />
      &nbsp;
      &nbsp;
      <Tooltip
        title="How many minutes after the client's appointment change should this message go out?"
        placement="top"
        arrow>
        <Info style={{
          fontSize: '14px',
          marginLeft: '10px',
        }} />
      </Tooltip>
    </div>
    <br />
    <br />
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <FormControl>
        <InputLabel>Source of the Appointment Change</InputLabel>
        <LargerSelect
          value={source}
          multiple
          renderValue={(selected: string[]) => R.pipe(
            R.map((s) => R.pipe(
              R.find(R.propEq('value', s)),
              R.propOr('', 'label')
            )(sourceList)),
            R.join(', ')
          )(selected)}
          onChange={(e: SelectChangeEvent<string[]>) => patch('source', R.uniq(e.target.value as string[]))}
          error={R.isEmpty(source)}>
          {filterSources({ features, ehrSystem }).map(({ value, label }) =>
            <MenuItem key={value} value={value}>
              <Checkbox checked={R.includes(value, source)} />
              <ListItemText primary={label} />
            </MenuItem>)}
        </LargerSelect>
        {R.includes('EHR', source) && ehrSystem === 'PlatinumApi' && <FormHelperText>
          It can take up to 5 minutes for appointment changes to sync from your EHR system. This message will send {delay} minute{delay !== 1 ? 's' : ''} after SKED receives the appointment change.
        </FormHelperText>}
        {R.includes('EHR', source) && ehrSystem === 'ChiroTouch' && <FormHelperText>
          If your SKED/Chirotouch syncer is disconnected from SKED's server, then SKED will not see the change to the appointment until it is reconnected.
        </FormHelperText>}
      </FormControl>

    </div>
    <br />
    <br />
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <FormControl>
        <InputLabel id="changeType-label">Change Type</InputLabel>
        <LargerSelect
          labelId="changeType-label"
          id="changeselect"
          value={changeType}
          multiple
          renderValue={(selected: string[]) => R.pipe(
            R.map((s) => R.pipe(
              R.find(R.propEq('value', s)),
              R.propOr('', 'label')
            )(changeTypesList)),
            R.join(', ')
          )(selected)}
          onChange={(e: SelectChangeEvent<string>) => patch('changeType', e.target.value)}>
          {changeTypesList.map(({ value, label }) =>
            <MenuItem key={value} value={value}>
              <Checkbox checked={R.includes(value, changeType)} />
              <ListItemText primary={label} />
            </MenuItem>)}
        </LargerSelect>
      </FormControl>
      &nbsp;
      &nbsp;
      <Tooltip
        title="Send message when appointment status is created, arrived, rescheduled and/or canceled."
        placement="top"
        arrow>
        <Info style={{
          fontSize: '14px',
        }} />
      </Tooltip>
    </div>
    <br />
    <br />
  </div>
);

interface IWindow extends Window {
  tinymce?: {
    activeEditor: {
      execCommand: (v1: string, v2: boolean, v3: string) => void;
    }
  }
}

const optStyle = { color: 'red', textDecoration: 'underline' };
export const outputErrorOptions = (
  <>
    <Tooltip title='Add an opt out link!' placement='top' arrow>
      <span>
        <a
          style={optStyle}
          onClick={() => (window as IWindow).tinymce.activeEditor.execCommand('insertHTML', false, '<br/>{{client.optoutLink}}')}
        >
          link
        </a>
      </span>
    </Tooltip>
    /
    <Tooltip title='Add an opt out url!' placement='top' arrow>
      <span>
        <a
          style={optStyle}
          onClick={() => (window as IWindow).tinymce.activeEditor.execCommand('insertHTML', false, '<br/>{{client.optoutUrl}}')}
        >
          url
        </a>
      </span>
    </Tooltip>
  </>
);

export const createSMSData = (sms: Sms) => {
  return R.cond([
    [(s) => R.and(
      R.prop('subject', s),
      R.prop('body', s)
    ), () => {
      const attachmentIds = R.map(R.prop('attachmentId'))(sms.attachments || []);
      return ({
        body: sms.subject + '\n' + sms.body,
        attachmentIds,
        hasAttachments: R.isEmpty(attachmentIds) ? undefined : [] as string[],
      });
    }],
    [(s) => R.and(
      R.not(R.prop('subject', s)),
      R.pipe(R.prop('body'), R.isEmpty, R.not)(s),
    ), () => {
      const attachmentIds = R.map(R.prop('attachmentId'))(sms.attachments || []);
      return ({
        body: sms.body,
        attachmentIds,
        hasAttachments: R.isEmpty(attachmentIds) ? undefined : [] as string[],
      });
    }],
    [(s) => R.and(
      R.prop('subject', s),
      R.pipe(R.prop('body'), R.isEmpty)(s)
    ), () => {
      const attachmentIds = R.map(R.prop('attachmentId'))(sms.attachments);
      return ({
        body: sms.subject,
        attachmentIds,
        hasAttachments: R.isEmpty(attachmentIds) ? undefined : [] as string[],
      });
    }],
    [(s) => R.and(
      R.isEmpty(R.prop('body', s)),
      R.pipe(R.prop('attachments'), R.isEmpty, R.not)(s)
    ), () => {
      const attachmentIds = R.map(R.prop('attachmentId'))(sms.attachments);
      return ({
        body: ' ',
        attachmentIds,
        hasAttachments: R.isEmpty(attachmentIds) ? undefined : [] as string[],
      });
    }],
    [R.T, R.always(undefined)]
  ])(sms);
};

const TemplateKind = ({
  templateType,
  forFeature,
  features,
  patch,
  isSmartReply,
}: {
  templateType: string;
  forFeature: string;
  features: string[];
  patch: PatchType;
  isSmartReply: boolean;
}) => (
  <div>
    <div>
      <SelectButton
        variant='contained'
        disabled={templateType === 'OneTime'}
        onClick={() => patch({ templateType: 'OneTime' })}>
        One Time
      </SelectButton>
      &nbsp;
      <SelectButton
        variant='contained'
        disabled={templateType === 'Recurring'}
        onClick={() => patch({ templateType: 'Recurring' })}>
        Reminder
      </SelectButton>
      &nbsp;
      <SelectButton
        variant='contained'
        disabled={templateType === 'Recall'}
        onClick={() => patch({ templateType: 'Recall' })}>
        Reactivation
      </SelectButton>
      &nbsp;
      <SelectButton
        variant='contained'
        disabled={templateType === 'Birthday'}
        onClick={() => patch({ templateType: 'Birthday' })}>
        Birthday
      </SelectButton>
      &nbsp;
      <SelectButton
        variant='contained'
        disabled={templateType === 'ApptChange'}
        onClick={() => patch({ templateType: 'ApptChange' })}>
        Rapid
      </SelectButton>
      &nbsp;
      <SelectButton
        variant='contained'
        disabled={templateType === 'Automation'}
        onClick={() => patch({ templateType: 'Automation' })}>
        {isSmartReply ? 'Smart Reply' : 'Automation'}
      </SelectButton>
    </div>
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <LessMarginFormControlLabel
        control={
          <Checkbox
            checked={forFeature === 'HtmlEmail'}
            onChange={(e) => patch({
              forFeature: e.target.checked ? 'HtmlEmail' : 'NewMessaging',
              features: e.target.checked ? R.append('HtmlEmail', features) : R.without(['HtmlEmail'], features),
            })}
            name="acknowledge-checkbox"
            color="primary"
          />
        }
        label="Enable HTML Email?"
      />
      <Tooltip
        title="If HTML, the template will only show up for offices that have the HTML Email feature enabled."
        placement="top"
        arrow>
        <Info style={{ fontSize: '14px' }} />
      </Tooltip>
    </div>
    <i>IMPORTANT: You must make TWO separate templates for HTML and Plain Text Email!</i>
  </div>
);

const templateSettings = ({
  templateType,
  patch,
  value,
  unit,
  filter,
  beforeAfter,
  includeInactive = null,
  sendAt,
  delay,
  changeType,
  source,
  features,
}: {
  templateType: string;
  patch: PatchType;
  value: number;
  unit: { id: number; name: string };
  filter: string;
  beforeAfter: string;
  includeInactive: boolean;
  sendAt: string;
  delay: number;
  changeType: string[];
  source: string[];
  features: string[];
}) => {
  return R.cond([
    [R.equals('OneTime'), () => (
      <div>
        <SelectButton
          variant='contained'
          disabled={filter === 'DateRange'}
          onClick={() => patch('filter', 'DateRange')}>
          Appointment types
        </SelectButton>
        &nbsp;
        <SelectButton
          variant='contained'
          disabled={filter === 'Clients'}
          onClick={() => patch('filter', 'Clients')}>
          Clients
        </SelectButton>
        &nbsp;
        <SelectButton
          variant='contained'
          disabled={filter === 'Everyone'}
          onClick={() => patch('filter', 'Everyone')}>
          Everyone
        </SelectButton>
        {!R.isNil(includeInactive) &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <LessMarginFormControlLabel
              control={
                <Checkbox
                  checked={includeInactive}
                  onChange={(e) => patch('includeInactive', e.target.checked)}
                  name="acknowledge-checkbox"
                  color="primary"
                />
              }
              label="Send to inactive clients?"
            />
            <Tooltip
              title="By default messages will be sent to active clients only. Check this box to have the message sent to inactive clients."
              placement="top"
              arrow>
              <Info style={{ fontSize: '14px' }} />
            </Tooltip>
          </div>}
      </div>
    )],
    [R.equals('Recurring'), () => (
      <div className="editor">
        <div className="editor-left">
          When:
          <br />
          <TextField
            name="value"
            label="Number"
            type="number"
            value={value}
            onChange={(e) => patch('value', Number(e.target.value) < 1 ? 1 : Number(e.target.value))} />
          <br />
          <TextField
            select
            value={unit.id}
            label="Unit">
            {value === 1 ?
              singularUnits.map((unit) => unitItems(unit, patch, 'unit'))
              :
              pluralUnits.map((unit) => unitItems(unit, patch, 'unit'))
            }
          </TextField>
          <br />
          <div style={{ float: 'left' }}>
            <SelectButton
              variant='contained'
              disabled={beforeAfter === 'Before'}
              onClick={() => patch('beforeAfter', 'Before')}>
              Before
            </SelectButton>
            &nbsp;
            <SelectButton
              variant='contained'
              disabled={beforeAfter === 'Missed'}
              onClick={() => patch('beforeAfter', 'Missed')}>
              Missed
            </SelectButton>
            &nbsp;
            <SelectButton
              variant='contained'
              disabled={beforeAfter === 'Arrived'}
              onClick={() => patch('beforeAfter', 'Arrived')}>
              Arrived
            </SelectButton>
          </div>
        </div>
      </div>
    )],
    [R.equals('Recall'), () => (
      sentSelection({
        value,
        sendAt,
        patch,
      })
    )],
    [R.equals('Birthday'), () => (
      sentSelection({
        sendAt,
        patch,
      })
    )],
    [R.equals('ApptChange'), () => (
      changeSelection({
        delay,
        changeType,
        source,
        patch,
        /*
          Since we are making a generic template, we don't care what the EHR or
          the features are. So we allow all of them to be shown. When the office
          selects this template, it will remove the things they shouldn't be able
          to see. Note that in `templates-edit.component.jsx` we set `features` to
          all the imporant stuff.
        */
        ehrSystem: 'Template',
        features,
      })
    )],
  ])(templateType);
};

type MessageComposerProps = {
  features: string[];
  forFeature?: string;
  isSMS: boolean;
  isEmail: boolean;
  isPush: boolean;
  subject?: string;
  body?: string;
  email: Email;
  sms: Sms;
  push: Push;
  actions: Actions;
  placeholders: Placeholder[];
  setSMSError: (value: boolean) => void;
  setEmailError?: (value: boolean) => void;
  setPushError?: (value: boolean) => void;
  messageType?: string;
  key?: string;
  defaultButtonType?: string;
  setDefaultButtonType?: (str: string) => void;
  forceNonHtml?: boolean;
  editorMenuIndex?: number;
}

let emailTimeout: NodeJS.Timeout;
let pushTimeout: NodeJS.Timeout;
export const MessageComposer = ({
  features, forFeature = 'NewMessaging', isSMS, isEmail, isPush,
  subject = '', body = '', email, sms, push, actions, placeholders,
  setSMSError, setEmailError, setPushError, messageType, key = 'email-html-editor',
  defaultButtonType = 'sms', setDefaultButtonType, forceNonHtml = false,
  editorMenuIndex = 100,
}: MessageComposerProps) => {
  const [openMessageType, setOpenMessageType] = useState(defaultButtonType);
  const [emoji, setEmoji] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [pushInvalid, setPushInvalid] = useState(false);
  const [smsInvalid, setSmsInvalid] = useState(false);

  useEffect(() => {
    let hasError = false;
    if (R.includes('HtmlEmail', features)) {
      hasError = R.xor(
        R.isEmpty(email.subject),
        R.or(R.isEmpty(email.html?.split('>')[1]?.split('</')[0].trim() || ''), R.isNil(email.html))
      );
    } else {
      hasError = R.xor(R.isEmpty(email.subject), R.isEmpty(email.body));
    }

    if (emailTimeout) {
      clearTimeout(emailTimeout);
    }
    emailTimeout = setTimeout(() => {
      setEmailInvalid(hasError);
      setEmailError?.(hasError);
    }, 300);

    return () => {
      if (emailTimeout) {
        clearTimeout(emailTimeout);
      }
    };
  }, [email]);

  useEffect(() => {
    const hasError = R.xor(R.isEmpty(push.subject), R.isEmpty(push.body));
    if (pushTimeout) {
      clearTimeout(pushTimeout);
    }
    pushTimeout = setTimeout(() => {
      setPushInvalid(hasError);
      setPushError?.(hasError);
    }, 300);

    return () => {
      if (pushTimeout) {
        clearTimeout(pushTimeout);
      }
    };
  }, [push]);

  useEffect(() => {
    if (setDefaultButtonType) {
      setDefaultButtonType(openMessageType);
    }
  }, [openMessageType]);

  useEffect(() => {
    setOpenMessageType(defaultButtonType);
  }, [defaultButtonType]);

  const emailPlaceholders = useMemo(() => {
    return placeholders.map((p) => {
      if (p.title === 'Forms' || p.title === 'Patient Portals') {
        return ({
          ...p,
          placeholders: p.placeholders.map((hold: Placeholder) => {
            return ({
              ...hold,
              value: hold.value.replace('url', 'anchor'),
            });
          }),
        });
      }
      return p;
    });
  }, [placeholders]);

  const onError = (isError: boolean) => {
    if (openMessageType === 'email' && !emailInvalid && isError) {
      setEmailInvalid(true);
      setEmailError?.(true);
      return;
    }
    if (openMessageType === 'sms' && !smsInvalid && isError) {
      setSmsInvalid(true);
      return;
    }
    if (openMessageType === 'push' && !pushInvalid && isError) {
      setPushInvalid(true);
      setPushError?.(true);
      return;
    }
  };

  if (R.includes('NewMessaging', features)) {
    return (
      <div>
        {forFeature === 'OldMessaging' &&
          <i>IMPORTANT: This is an old template! You <b>need to edit the email portion</b> for offices to see the changes!</i>}
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '350px',
        }}>
          <SelectButton
            variant='contained'
            disabled={openMessageType === 'sms'}
            onClick={() => setOpenMessageType('sms')}
            className='sked-test-create-message-sms-button'
            startIcon={showIcon(isSMS && (!R.isEmpty(sms.body) || !!sms.attachments?.length), smsInvalid)}>
            {R.includes('MMS', features) ? 'SMS/MMS' : 'SMS'}
          </SelectButton>
          <SelectButton
            variant='contained'
            disabled={openMessageType === 'email'}
            onClick={() => setOpenMessageType('email')}
            className='sked-test-create-message-email-button'
            startIcon={showIcon((isEmail && !R.isEmpty(email.subject)), emailInvalid)}
            style={{
              marginLeft: '15px',
              marginRight: '15px',
            }}>
            Email
          </SelectButton>
          {R.includes('SkedApp', features) &&
            <SelectButton
              variant='contained'
              disabled={openMessageType === 'push'}
              onClick={() => setOpenMessageType('push')}
              className='sked-test-create-message-push-button'
              startIcon={showIcon(isPush, pushInvalid)}>
              Push/App
            </SelectButton>}
        </div>
        <br />
        {R.cond([
          [R.equals('email'), () =>
            R.includes('HtmlEmail', features) && !forceNonHtml ?
              <Editor
                key={key}
                subject={email.subject}
                html={email.html}
                body={email.body}
                shouldFrame={email.shouldFrame}
                isSMS={false}
                isHTML
                patch={(_p: string, v: boolean) => setEmoji(v)}
                emoji={emoji}
                messagesPatch={(prop: string, value: string) => {
                  const newObj = {
                    ...email,
                    [prop]: value
                  };
                  return actions.messageMassPatch({
                    email: newObj,
                    isEmail: !R.isEmpty(newObj.subject) &&
                      !R.isEmpty(newObj.html?.split('>')[1]?.split('</')[0].trim()),
                  });
                }}
                placeholders={emailPlaceholders}
                isUpload={true}
                uploadSizeLimit={10000000}
                attachments={email.attachments}
                uploadPatch={(a: AttachmentType[], type: string) => {
                  if (type === 'add') {
                    actions.messagesPatch('email', { ...email, attachments: R.concat(a, email.attachments) });
                  } else {
                    actions.messagesPatch('email', { ...email, attachments: R.without([a] as unknown as typeof email.attachments, email.attachments) });
                  }
                }}
                onError={onError}
                editorMenuIndex={editorMenuIndex}
              /> :
              <Editor
                key="email-text-editor"
                subject={email.subject}
                body={forceNonHtml ? email.html : email.body}
                isSMS={false}
                patch={(p: string, v: boolean) => setEmoji(v)}
                emoji={emoji}
                messagesPatch={(prop: string, value: string) => {
                  let e = {
                    ...email,
                  };
                  if (prop === 'subject') {
                    e = { ...e, [prop]: value };
                  } else {
                    e = { ...e, [forceNonHtml ? 'html' : prop]: value };
                  }
                  // Due to email.html being `null` we have to use `equals`
                  return actions.messageMassPatch({
                    email: e,
                    isEmail: R.pipe(
                      R.filter((a) => typeof a !== 'boolean'),
                      R.values,
                      R.all(R.either(R.equals(null), R.isEmpty)),
                      R.not
                    )(e),
                  });
                }}
                placeholders={placeholders}
                onError={onError}
              />],
          [R.equals('sms'), () =>
            <Editor
              key="sms-editor"
              body={sms.body}
              isSMS={true}
              patch={(p: string, v: boolean) => setEmoji(v)}
              emoji={emoji}
              messagesPatch={(prop: string, value: string) => {
                const newObj = {
                  ...sms,
                  [prop]: value
                };
                R.length(newObj.body) > 2048 ? setSMSError(true) : setSMSError(false);
                return actions.messageMassPatch({
                  sms: newObj,
                  isSMS: !R.all(R.isEmpty)(R.values(newObj)),
                });
              }}
              placeholders={placeholders}
              isUpload={R.includes('MMS', features)}
              uploadSizeLimit={1000000}
              attachments={sms.attachments}
              uploadPatch={(a: AttachmentType[], type: string) => {
                const sa = sms.attachments || [];
                if (type === 'add') {
                  actions.messagesPatch('sms', { ...sms, attachments: R.concat(a, sa) });
                } else {
                  actions.messagesPatch('sms', { ...sms, attachments: R.without([a] as unknown as typeof sa, sa) });
                }
              }}
              onError={onError}
            />],
          [R.equals('push'), () =>
            <Editor
              key="push-editor"
              subject={push.subject}
              body={push.body}
              isSMS={false}
              patch={(p: string, v: boolean) => setEmoji(v)}
              emoji={emoji}
              messagesPatch={(prop: string, value: string) => {
                const newObj = {
                  ...push,
                  [prop]: value
                };
                return actions.messageMassPatch({
                  push: newObj,
                  isPush: !R.all(R.isEmpty)(R.values(newObj)),
                });
              }}
              placeholders={placeholders}
              onError={onError}
            />],
          [R.T, R.always(null)]
        ])(openMessageType)}
        {messageType === 'ApptChange' && openMessageType === 'email' &&
          <FormControlLabel
            control={
              <Checkbox
                checked={email.addCalendarEvent}
                onChange={(e) => actions.messageMassPatch({ email: {
                  ...email,
                  addCalendarEvent: e.target.checked,
                } })}
                name="acknowledge-checkbox"
                color="primary"/>}
            label="Include a Calendar Event Attachment"
          />}
      </div>
    );
  } else {
    return (
      <div>
        <Editor
          key="old-editor"
          subject={isSMS && !(isEmail || isPush) ? undefined : subject}
          body={body}
          isSMS={isSMS}
          patch={(p: string, v: boolean) => setEmoji(v)}
          emoji={emoji}
          messagesPatch={(prop: string, value: string) => {
            const e = isEmail ? {
              ...email,
              [prop]: value,
            } : email;
            const s = isSMS ? {
              ...sms,
              [prop]: value,
            } : sms;
            const p = isPush ? {
              ...push,
              [prop]: value,
            } : push;
            R.length(s.body) > 2048 ? setSMSError(true) : setSMSError(false);
            return actions.messageMassPatch({
              email: e,
              sms: s,
              push: p,
              [prop]: value,
            });
          }}
          placeholders={placeholders}
        />
      </div>
    );
  }
};

type CampaignSelectionProps = {
  patch: PatchType;
  campaign: DefaultList;
}
const CampaignSelection = ({
  patch,
  campaign,
}: CampaignSelectionProps) => {
  return (
    <Section>
      <h4>
        Which campaign is this message for?
      </h4>
      <ClientListSearch
        clientLists={campaign}
        setClientLists={(c: Campaign) => patch('campaign', { listId: c.id, listName: c.name, id: c.campaign.id })}
        isCampaign
        clearClientLists={undefined}
        style={undefined} />
    </Section>
  );
};

type AutomationCreationProps = {
  campaign: DefaultList;
  state: string;
  automations: Automation[];
  addAutomation: typeof automationsActions.addLocalAutomation;
  removeAutomation: typeof automationsActions.removeAutomation;
  updateAutomation: typeof automationsActions.updateAutomation;
  addToCampaign: typeof templatesEditActions.addAutomationTemplateToCampaign;
  messageName: string;
  enabled?: boolean;
  template?: boolean;
}

const AutomationCreation = ({
  campaign,
  state,
  automations,
  addAutomation,
  removeAutomation,
  updateAutomation,
  addToCampaign,
  messageName,
  enabled,
  template,
}: AutomationCreationProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const {
    isAdmin
  } = useSelector((state: any) => ({
    isAdmin: state.login.admin,
  }));
  return (
    <Section>
      <AutomationTemplateDialog
        open={openTemplateDialog}
        onClose={() => setOpenTemplateDialog(false)}
        initFolder='automations.Messaging'
        onSelect={(auto: Automation) => {
          setOpenTemplateDialog(false);
          addAutomation({ ...auto, isEdit: true, id: 0, enabled: false });
        }}
      />
      <h4>
        Would you like to setup an automation for this message?
      </h4>
      <Button
        variant='outlined'
        onClick={(e) => {
          isAdmin ? setMenuAnchorEl(e.currentTarget) : setOpenTemplateDialog(true);
        }}
      >
        Add Automation
      </Button>
      <AutomationsList
        automations={automations}
        defaultCampaign={campaign}
        noSave
        disabledActions
        disabledActionsMessage={`send ${messageName}`}
        forceSave={state === 'SENT'}
        removeAutomation={removeAutomation}
        updateAutomation={updateAutomation}
        onSave={(idx: number) => (automation: { id: number }) => {
          if (template)
            addToCampaign(automation);
          updateAutomation(idx, automation);
        }}
        enabled={enabled}
        isTemplate={template}
      />
      <Menu
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        id="long-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}>
        <MenuItem onClick={() => {
          setMenuAnchorEl(null);
          addAutomation();
        }}>
          <ListItemIcon>
            <AddIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Create New' />
        </MenuItem>
        <MenuItem onClick={() => {
          setMenuAnchorEl(null);
          setOpenTemplateDialog(true);
        }}>
          <ListItemIcon>
            <FileCopyIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='From Template' />
        </MenuItem>
      </Menu>
    </Section>
  );
};

const saveAutomation = (auto: any): Promise<Automation> => {
  if (auto.isTemplate) {
    return auto.id ?
      api.put(`automations/template/${auto.id}`, auto) : api.post('automations/template', auto);
  } else {
    return auto.id ?
      api.put(`automations/${auto.id}`, auto) : api.post('automations', auto);
  }
};

interface SmartReplyProps {
  state: string;
  automations: Automation[],
  enabled: boolean;
  updateAutomation: typeof automationsActions.updateAutomation;
  addAutomation: typeof automationsActions.addLocalAutomation;
  isTemplate: boolean;
  addToPath?: typeof templatesEditActions.addAutomationTemplateToPath;
  templateId?: number;
  skedTemplate?: boolean;
}

const SmartReply = ({
  state, automations, enabled, updateAutomation, addAutomation, isTemplate,
  addToPath, templateId, skedTemplate,
}: SmartReplyProps) => {
  const {
    justSentMsgId, wasTemplate, status,
  } = useSelector((state: any) => ({
    justSentMsgId: isTemplate ?
      state.templateEdit.justSentMsgId :
      state.editRecurring.justSentMsgId,
    wasTemplate: state.editRecurring.wasTemplate,
    status: state.editRecurring.status,
  }));
  const [auto, setAuto] = useState<Automation>(null);
  const [keyword, setKeyword] = useState<string>('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const updateKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };
  const addKeyword = () => {
    setKeywords(keywords.concat([keyword]));
    setKeyword('');
  };
  const deleteKeyword = (idx: number) => () => {
    setKeywords(R.remove(idx, 1, keywords));
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addKeyword();
    }
  };
  useEffect(() => {
    /* If `keepOld` is true, you need to update the original automation and make a new one.
       This is used for making office templates.
    */
    if (state === 'SENT') {
      let SendMessage = isTemplate && skedTemplate ? [] : justSentMsgId;
      let keepOld = false;
      if (auto) {
        auto.actions.forEach((action) => {
          if (R.has('SendMessage', action) && !keepOld) {
            keepOld = action.SendMessage !== justSentMsgId && Boolean(templateId);
            if (R.has('msgId', action.SendMessage)) {
              SendMessage = action.SendMessage.msgId;
            } else {
              SendMessage = action.SendMessage;
            }
          }
        });
      }
      //      keepOld = !wasTemplate && Boolean(templateId);
      const actions = auto && (!skedTemplate || !templateId) ? auto.actions.map(() => ({
        SendMessage,
      })) : [{
        SendMessage,
      }];
      console.log(wasTemplate, isTemplate, templateId);
      if (wasTemplate) {
        saveAutomation({
          id: 0,
          actions: [{ SendMessage: { Id: justSentMsgId } }],
          isTemplate,
          delay: 0,
          deleteActions: [],
          filters: [],
          enabled: isTemplate ? false : enabled,
          trigger: {
            MessageReceived: keywords,
          },
          hidden: false,
        });
      } else {
        saveAutomation({
          id: auto ? auto.id : 0,
          actions,
          isTemplate,
          delay: 0,
          deleteActions: [],
          filters: [],
          enabled: isTemplate ? false : enabled,
          trigger: {
            MessageReceived: keywords,
          },
          hidden: false,
        }).then((newAuto) => {
          if (isTemplate) {
            addToPath(newAuto);
          }
        });
      }
      if (keepOld) {
        //auto && wasTemplate ? auto.id : 0,
        if (wasTemplate) {
          saveAutomation({
            id: auto ? auto.id : 0,
            actions,
            isTemplate,
            delay: 0,
            deleteActions: [],
            filters: [],
            enabled: wasTemplate ? false : enabled,
            trigger: {
              MessageReceived: keywords,
            },
            hidden: false,
          });
        } else {
          saveAutomation({
            id: 0,
            actions: [{ SendMessage: { Id: justSentMsgId } }],
            isTemplate,
            delay: 0,
            deleteActions: [],
            filters: [],
            enabled: wasTemplate ? false : enabled,
            trigger: {
              MessageReceived: keywords,
            },
            hidden: false,
          });
        }
      }
      if (auto && R.has('MessageReceived', auto.trigger)) {
        updateAutomation(0, {
          ...auto,
          trigger: {
            MessageReceived: keywords,
          }
        });
      } else {
        addAutomation({
          id: 0,
          actions: [{ SendMessage: { msgId: justSentMsgId, name: '' } }],
          delay: 0,
          deletedActions: [] as number[],
          enabled: isTemplate ? false : enabled,
          trigger: {
            MessageReceived: keywords,
          },
          hidden: false,
          filters: [] as GetFilter[],
          comment: '',
        } as Automation);
      }
      setKeywords([]);
      setKeyword('');
    } else {
      setKeywords([]);
      setKeyword('');
    }
  }, [state]);
  useEffect(() => {
    if (status === 'LOAD') {
      setKeywords([]);
      setKeyword('');
    }
  }, [status]);
  useEffect(() => {
    if (automations[0]) {
      const auto = automations[0];
      setAuto(auto);
      if (R.has('MessageReceived', auto.trigger))
        setKeywords(auto.trigger.MessageReceived);
    } else {
      setKeywords([]);
      setAuto(null);
    }
  }, [automations]);
  return (
    <Section>
      <h4>
        What keywords should trigger this message?
      </h4>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TextField
          label='Keyword'
          value={keyword}
          onChange={updateKeyword}
          onKeyPress={handleKeyPress}
          style={{ marginRight: '10px' }}
        />
        <Button variant='contained' onClick={addKeyword}>
          Add
        </Button>
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}>
        {keywords.map((k: string, idx: number) => (
          <Chip
            style={{ fontSize: '14px', margin: '5px' }}
            label={k}
            onDelete={deleteKeyword(idx)}
          />
        ))}
      </div>
    </Section>
  );
};

const goBack = (navigate: NavigateFunction, messageType: string) => {
  if (messageType === 'Template') {
    navigate('/templates');
  } else {
    navigate(-1);
  }
};

type PopupData = {
  creditsAllowed?: number;
  creditsUsed?: number;
  overCost?: number;
  clients: number;
}

// type Actions = typeof recurringEditActions | typeof messagesNewActions | typeof templatesEditActions;
export type Actions = {
  loadMessage?: typeof messagesNewActions.loadMessage;
  messageMassPatch?: (data: templatesEditActions.MessageMassPatch) => void | PatchType;
  getTypes?: typeof recurringEditActions.getTypes;
  getClients?: typeof messagesNewActions.getClients;
  getLeads?: typeof messagesNewActions.getLeads;
  addLead?: typeof messagesNewActions.addLead;
  removeLead?: typeof messagesNewActions.removeLead;
  messagesPatch?: (prop: string, data: any) => void | typeof messagesActions.messagesPatch;
  addCampaign?: typeof recurringEditActions.addCampaign;
  addClient?: typeof recurringEditActions.addClient;
  removeClient?: typeof recurringEditActions.removeClient;
  clearSelected?: typeof recurringEditActions.clearSelected;
  addAutomation?: typeof automationsActions.addLocalAutomation;
  addLocalAutomation?: typeof automationsActions.addLocalAutomation;
  removeAutomation?: typeof automationsActions.removeAutomation;
  updateAutomation?: typeof automationsActions.updateAutomation;
  sendMessage?: typeof recurringEditActions.sendMessage;
  sendDraftMessage?: typeof messagesNewActions.sendDraftMessage;
  saveMessage?: typeof recurringEditActions.saveMessage;
  updateAllAutomation?: typeof automationsActions.updateAllAutomation;
  getAutomationsByMsgId?: typeof automationsActions.getAutomationsByMsgId;
  automationsPatch?: typeof automationsActions.automationsPatch;
  saveTemplate?: typeof recurringEditActions.saveTemplate;
  addAutomationTemplateToCampaign?: typeof templatesEditActions.addAutomationTemplateToCampaign
  addAutomationTemplateToPath?: typeof templatesEditActions.addAutomationTemplateToPath
}

type CreateMessageProps = {
  busy?: boolean;
  filter: string;
  office?: Office;
  endDate?: string;
  startDate?: string;
  types?: AppointmentType[];
  professionals?: Professional[];
  clients?: {
    data: Client[];
    page?: number;
    totalCount?: number;
    totalPages?: number;
  };
  state: string;
  page?: number;
  isEmail: boolean;
  isSMS: boolean;
  isPush: boolean;
  messageName: string;
  subject: string;
  body: string;
  scheduledFor?: string;
  typeStatus?: string;
  selectedTypes?: number[];
  selectedClients?: Client[];
  selectedLeads?: Lead[];
  urlId: string;
  features: string[];
  actions: Actions;
  email: Email;
  sms: Sms;
  push: Push;
  tags?: { tagId: number }[];
  operator?: string;
  messageType: string;
  resetMessages: () => void;
  isCopied?: boolean;
  beforeAfter: string;
  unit: { id: number; name: string };
  value: number;
  isEnabled: boolean;
  sendAt: string;
  delay?: number;
  changeType: string[];
  source: string[];
  isTemplate?: boolean;
  includeInactive: boolean;
  status: string;
  templateType: string;
  forFeature: string;
  templateId?: number;
  skedTemplate?: boolean;
  campaign?: DefaultList;
  automations?: Automation[];
  headerHeight?: number;
  leads?: {
    data: Lead[];
    page?: number;
    totalCount?: number;
    totalPages?: number;
  }
  isSpark?: boolean; // used to place message into spark message path
}

export default function CreateMessage({
  busy, filter, office = {} as Office, endDate, startDate, types,
  professionals, clients, state, isEmail, isSMS, isPush, messageName,
  subject, body, scheduledFor, typeStatus, selectedTypes,
  selectedClients, urlId, features, actions, email, sms, push, tags,
  operator, messageType, resetMessages, isCopied, beforeAfter, unit,
  value, isEnabled, sendAt, delay, changeType, source, isTemplate,
  includeInactive, status, templateType, forFeature, selectedLeads,
  templateId, campaign, automations, skedTemplate,
  leads, isSpark = false,
}: CreateMessageProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCounting, setIsCounting] = useState(false);
  const [popup, setPopup] = useState(null);
  const [popupData, setPopupData] = useState<PopupData>({} as PopupData);
  const [SMSError, setSMSError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pushError, setPushError] = useState(false);
  const formsState = usePromise(fetchForms, []);
  const ppState = usePromise(fetchSettingsList, []);

  const { timezone } = office;

  const isSmartReply = React.useMemo(() => {
    return location.pathname.indexOf('smart-reply-old/edit') !== -1 ||
      location.search.indexOf('smart-reply') !== -1;
  }, [location]);

  const isAllEmpty = useMemo(() => {
    let emailEmpty = false;
    if (R.includes('HtmlEmail', features)) {
      emailEmpty = R.or(
        R.isEmpty(email.html?.split('>')[1]?.split('</')[0].trim() || ''),
        R.isNil(email.html)
      );
    } else {
      emailEmpty = R.isEmpty(email.body);
    }

    const hasMms = R.includes('MMS', features);
    const smsEmpty = hasMms ? (R.isEmpty(sms.body) && !sms.attachments.length) : R.isEmpty(sms.body);

    return emailEmpty && R.isEmpty(push.body) && smsEmpty;
  }, [sms, email, push, features]);

  const loadPortals = useMemo(() => {
    return R.includes('EPPLinkPlaceholders', features) &&
      (messageType === 'Automation' || messageType === 'Recall');
  }, [messageType, features]);

  useEffect(() => {
    R.cond([
      [R.equals('LOAD'), () => {
        resetMessages();
        // Since with the old messaging, it defaults to everything.
        if (!R.includes('NewMessaging', features) &&
          urlId === '0' &&
          !isCopied)
          actions.messageMassPatch({
            isEmail: true,
            isSMS: true,
            isPush: true,
          });
        formsState.invoke({});
        if (loadPortals) {
          ppState.invoke({});
        }
        if (urlId !== '0') {
          actions.messageMassPatch({ templateId: undefined });
          if (messageType === 'OneTime')
            actions.loadMessage(urlId, timezone, features);
          else
            actions.loadMessage(urlId, timezone);
          if (actions.getTypes)
            actions.getTypes();
          if (actions.getClients)
            actions.getClients({
              query: { lastName: '', status: ['Active'] },
              perPage: 25,
              page: 1
            });
          if (actions.getLeads)
            actions.getLeads({
              query: { lastName: '' },
              perPage: 25,
              page: 1
            });
          if (messageType === 'Automation' || templateType === 'Automation') {
            actions.getAutomationsByMsgId(Number(urlId), templateType === 'Automation');
          }
        } else {
          if (R.isEmpty(messageName)) {
            actions.messageMassPatch({ templateId: undefined });
          }
          if (actions.getTypes)
            actions.getTypes();
          if (actions.getClients)
            actions.getClients({
              query: { lastName: '', status: ['Active'] },
              perPage: 25,
              page: 1
            });
          if (actions.getLeads)
            actions.getLeads({
              query: { lastName: '' },
              perPage: 25,
              page: 1
            });
          if (messageType === 'Automation' && isSmartReply && templateId) {
            actions.getAutomationsByMsgId(templateId, skedTemplate, skedTemplate);
          } else if (messageType === 'Automation') {
            actions.automationsPatch({ automations: { data: [] } });
          }
        }
        actions.messagesPatch('status', 'EDIT');
      }],
      [R.equals('CHECK_ERRORS'), () => {
        const isEmailError = R.includes('HtmlEmail', features) ?
          R.xor(
            R.or(
              R.isEmpty(email.html?.split('>')[1]?.split('</')[0].trim() || ''),
              R.isNil(email.html)
            ),
            R.isEmpty(email.subject)
          )
          :
          R.xor(R.isEmpty(email.body), R.isEmpty(email.subject));
        const isPushError = R.xor(R.isEmpty(push.body), R.isEmpty(push.subject));
        R.filter(
          (prop: boolean) => R.identity(prop)
        )([isEmailError, isPushError]);
        actions.messagesPatch('status', 'EDIT');
      }],
      [R.T, R.always(null)]
    ])(status);
  }, [email, sms, push]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.campaignId && campaign?.id !== Number(parsed?.campaignId || 0)) {
      actions.addCampaign(Number(parsed.campaignId));
    }
  }, [location, campaign]);

  useEffect(() => {
    if (state === 'SENT') {
      goBack(navigate, messageType);
      actions.messagesPatch('state', 'CREATE');
      if (templateType === 'Automation' && R.isEmpty(automations))
        actions.addAutomationTemplateToCampaign(null);
      actions.messagesPatch('status', 'LOAD');
      actions.messagesPatch('templateId', undefined);
    }
  }, [state]);

  useEffect(() => {
    if (filter === 'Leads' && actions.getLeads) {
      actions.getLeads({
        query: { lastName: '' },
        perPage: 25,
        page: 1
      });
    }

    if (filter === 'Clients' && actions.getClients) {
      actions.getClients({
        query: { lastName: '', status: ['Active'] },
        perPage: 25,
        page: 1
      });
    }
  }, [filter]);

  useEffect(() => {
    return () => {
      if (actions.automationsPatch) {
        actions.automationsPatch({ automations: { data: [] } });
      }
    };
  }, []);

  const getOneTime = (prop: string) => {
    if (R.equals('DateRange')(prop)) {
      return {
        start: format(startDate, 'yyyy-MM-dd'),
        end: format(endDate, 'yyyy-MM-dd'),
        appointmentTypes: {
          [typeStatus]: typeStatus === 'All' ? [] : selectedTypes,
        }
      };
    }
    if (R.equals('Clients')(prop)) {
      return R.map((client: Client) => client.id)(selectedClients);
    }
    if (R.equals('Leads')(prop)) {
      return R.map((lead: Lead) => lead.id)(selectedLeads);
    }
    if (R.equals('ClientTag')(prop)) {
      return { [operator]: R.map((tag: { tagId: number }) => tag.tagId)(tags) };
    }
    return [];
  };

  const filterData = R.cond([
    [R.equals('OneTime'), () => getOneTime(filter)],
    [R.equals('Recurring'), () => R.cond([
      [R.equals('Some'), R.always(selectedTypes)],
      [R.T, R.always([])]
    ])(typeStatus)],
    [R.T, R.always(null)]
  ])(messageType);

  const smsData = createSMSData(sms);

  const getTemplateType = (prop: string): any => {
    switch (prop) {
      case 'OneTime': {
        return {
          includeInactive: false,
          receivers: filter,
        };
      }
      case 'Recurring': {
        return {
          searchParam: beforeAfter,
          time: {
            [R.head(unit.name.split('s'))]: value,
          },
        };
      }
      case 'Recall': {
        return {
          days: value,
          sendAt,
          includeInactive,
        };
      }
      case 'Birthday': {
        return { sendAt };
      }
      case 'ApptChange': {
        return {
          delay,
          source,
          changeType,
        };
      }
      default:
        return [];
    }
  };

  const getMessageType = (prop: string): any => {

    switch (prop) {
      case 'OneTime': {
        return {
          includeInactive: false,
          receivers: {
            [filter === 'Leads' ? 'Clients' : filter]: filterData,
          },
          scheduledFor: scheduledFor === 'now' ?
            (now('tz') as ZonedDateTime)
              .withZoneSameInstant(ZoneOffset.UTC)
              .toString()
            :
            LocalDateTime
              .parse(scheduledFor)
              .atZone(ZoneId.of(timezone))
              .withZoneSameInstant(ZoneOffset.UTC)
              .toString(),
        };
      }
      case 'Recurring': {
        return {
          searchParam: beforeAfter,
          time: {
            [R.head(unit.name.split('s'))]: value,
          },
          appointmentTypeIds: {
            [typeStatus]: filterData,
          },
        };
      }
      case 'Recall': {
        return {
          days: value,
          sendAt,
          appointmentTypes: selectedTypes,
          includeInactive,
        };
      }
      case 'Birthday': {
        return { sendAt };
      }
      case 'ApptChange': {
        return {
          delay,
          appointmentTypeIds: selectedTypes,
          source,
          changeType,
        };
      }
      default:
        return [];
    }
  };

  const message = {
    name: messageName,
    subject,
    body,
    email: !email.subject && !email.body &&
      (!email.html || R.isEmpty(email.html.split('>')[1]?.split('</')[0].trim())) ?
      undefined
      :
      R.merge(email, {
        attachmentIds: R.map(R.prop('attachmentId'))(email.attachments),
      }),
    sms: smsData,
    push: !push.subject && !push.body ? undefined : push,
    messageType: messageType === 'Template' ?
      {
        [templateType]: getTemplateType(templateType)
      }
      :
      {
        [messageType]: getMessageType(messageType),
      },
    forFeature,
    campaignId: R.ifElse(
      R.equals('0'),
      () => R.propOr(undefined, 'id', campaign),
      () => campaign ? {
        Set: R.propOr(undefined, 'id', campaign),
      } : {
        Unset: [] as string[],
      }
    )(urlId),
  };

  // checkReceivers :: () -> [Boolean, Maybe ErrorString]
  const checkReceivers = () => {
    return R.cond([
      [R.equals('OneTime'), () => {
        if (filter === 'DateRange') {
          const l = R.isEmpty(selectedTypes);
          return [
            !startDate || !endDate || l,
            l ? 'No appointment types have been selected!' : null,
          ];
        } else if (filter === 'Clients') {
          const l = R.isEmpty(selectedClients);
          return [
            l,
            l ? 'No clients have been selected!' : null,
          ];
        } else if (filter === 'Leads') {
          const l = R.isEmpty(selectedLeads);
          return [
            l,
            l ? 'No leads have been selected!' : null,
          ];
        } else if (filter === 'ClientTag') {
          const l = R.isEmpty(tags);
          return [
            l,
            l ? 'No Tags have been selected!' : null,
          ];
        }
        return [false, null];
      }],
      [R.equals('Recurring'), () => {
        const l = R.isEmpty(selectedTypes);
        return [
          !value || l,
          l ? 'No appointment types have been selected!' : null,
        ];
      }],
      [R.equals('Recall'), () => {
        const l = R.isEmpty(selectedTypes);
        return [
          !value || l || !sendAt,
          l ? 'No appointment types have been selected!' : null,
        ];
      }],
      [R.equals('Birthday'), () => {
        return [!sendAt, null];
      }],
      [R.equals('ApptChange'), () => {
        return [R.isEmpty(changeType) || R.isEmpty(source) || R.isEmpty(selectedTypes), ''];
      }],
      [R.T, R.always([false, null])]
    ])(messageType);
  };

  const clientHolders =
    R.filter((holder: Placeholder) => removePlaceholders(features, holder))(clientPlaceholders);
  const officeHolders =
    R.filter((holder) => removePlaceholders(features, holder))(officePlaceholders);
  const apptHolders =
    R.filter((holder) => removePlaceholders(features, holder))(apptPlaceholders);

  const newClientHolders = clientHolders.filter(holder => {
    if (filter === 'Leads') {
      return holder.name !== 'Registration Link';
    }
    return true;
  });

  const {
    officeTitle,
    lPlace,
  } = (R.includes('Locations', features) && !R.includes(messageType, ['OneTime', 'Birthday'])) ? {
    officeTitle: 'Business',
    lPlace: true,
  } : { officeTitle: 'Office', lPlace: false };

  const placeholders = useMemo(() => {
    return [
      { title: 'Client', placeholders: newClientHolders },
      { title: officeTitle, placeholders: officeHolders },
      lPlace && { title: 'Locations', placeholders: locationPlaceholders },
      (R.includes(messageType, ['Recurring', 'ApptChange'])
      ||
      R.includes(templateType, ['Recurring', 'ApptChange']))
    &&
    { title: 'Appointment', placeholders: apptHolders },
      !R.isEmpty(formsState.data) && R.includes('Forms', features) && !R.includes('SparkUI', features) &&
    {
      title: 'Forms', placeholders: formsState.data.map(({ name, id }) => ({
        value: `{{form.${id}.url}}`,
        name,
      }))
    },
      !R.isEmpty(ppState.data) &&
    {
      title: 'Patient Portals',
      placeholders: ppState.data.map(({ name, newPatientPortalSettingsId }: {
        name: string;
        newPatientPortalSettingsId: number;
      }) => ({
        value: `{{patientportal.${newPatientPortalSettingsId}.url}}`,
        name,
      }))
    },
    ];
  }, [officeTitle, messageType, templateType, formsState.data, ppState.data]);

  const receiver = checkReceivers();
  const [receiversError, receiversErrorMessage] = receiver;
  const contentError = canSend({
    message,
    messageName,
    body,
  });

  const lacksOptOutLink = !email.shouldFrame &&
    email.html &&
    !(R.includes('{{client.optoutLink}}', email.html) || R.includes('{{client.optoutUrl}}', email.html)) ?
    <p style={{ color: 'red' }}>
      Your email message doesn't have an opt out {outputErrorOptions}!
      <b>This message cannot not be sent without one!</b>
    </p>
    : null;

  const strLength = R.length(sms.body);
  // eslint-disable-next-line no-control-regex
  const hasUnicode = /[^\u0000-\u00ff]/.test(sms.body);
  const limit = hasUnicode ? unicodeCharacterLimit : characterLimit;
  const assumedCredits = Math.ceil(strLength / limit);
  let credits = assumedCredits < 11 ? assumedCredits : 3;
  if (!R.isEmpty(sms.attachments)) {
    credits = 3;
  }
  const sentAsText =
    ` This message will be sent as:
${isEmail ? '- Email\n' : ''}${isSMS ? '- SMS\n' : ''}${isPush && R.includes('SkedApp', features) ? '- Push' : ''}`;

  const disableSave = useMemo(() => {
    return isCounting || !!receiversError || contentError || SMSError
    || !!lacksOptOutLink || emailError || pushError || isAllEmpty;
  }, [isCounting, receiversError, contentError, SMSError, lacksOptOutLink, emailError, pushError, isAllEmpty]);

  const handleSave = () => {
    const newFilter = filter === 'Leads' ? 'Clients' : filter;
    R.cond([
      [R.equals('OneTime'), async () => {
        setIsCounting(true);
        const clients = await api.post('message/count', { [newFilter]: filterData });
        Promise.all([
          api.post('message/count', { [newFilter]: filterData }),
          api.get('subscription/v2/spark'),
          api.get('subscription/v2/sked'),
        ].map(etd)).then(([cli, spark, sked]) => {
          const clients = cli?.data || 'some';
          const planData = spark?.data || sked?.data;
          const { msgPlan = {}, basePlan = {} } = planData?.plan || {};
          setIsCounting(false);
          const {
            creditsAllowed = 99999999,
            creditsUsed = 0,
            overCost = 0,
          } = msgPlan ? msgPlan : {};
          const totalCredits = credits * clients;
          const validPlans = ['SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise', 'SkedAgency'];
          if (R.includes(basePlan.plan, validPlans) &&
          isSMS &&
          (((creditsUsed + totalCredits) > creditsAllowed)
            ||
            totalCredits >= 1000)) {
            setPopup('credits');
            setPopupData({
              creditsAllowed,
              creditsUsed,
              overCost,
              clients,
            });
          } else {
            setPopupData({
              clients,
            });
            setPopup('client');
          }
        }).catch((e) => {
          console.log(e);
          setIsCounting(false);
          setPopupData({
            clients,
          });
          setPopup('client');
        });
      }],
      [R.includes(R.__, ['Recurring', 'Recall', 'Birthday', 'ApptChange', 'Template', 'Automation']), () => {
        isEnabled ?
          popupWithCancel(
            'Are you sure you want to save?',
            'This message is enabled with means that it could be sent!' + sentAsText,
            () => actions.sendMessage({ message: attachEmailBody(message), id: urlId, isSpark })) :
          actions.saveMessage(attachEmailBody(message), urlId, isSpark);
      }],
      [R.T, R.always(null)]
    ])(messageType);
  };

  return (
    <>
      <Header
        title={`${Number(urlId) === 0 ? 'New ' : 'Edit '} ${R.cond([
          [R.equals('Recall'), R.always('Reactivation')],
          [R.equals('ApptChange'), R.always('Rapid')],
          [R.equals('OneTime'), R.always('One Time')],
          [R.equals('Recurring'), R.always('Appt-Time Reminder')],
          [R.equals('Automation'), () => isSmartReply ? 'Smart Reply' : 'Automation'],
          [R.T, R.always(messageType)]
        ])(messageType)}${messageType !== 'Recurring' ? ' Message' : ''} ${messageType === 'Automation' && campaign ? `(${campaign.listName})` : ''}`
        }
        rightIcons={[
          isEnabled !== undefined &&
          <HeaderButton borderSolid>
            <label>
              <Checkbox
                name="enabled"
                style={{ paddingLeft: 0, paddingTop: 4, paddingBottom: 4 }}
                checked={isEnabled}
                onChange={() => {
                  actions.messagesPatch('isEnabled', !isEnabled);
                }} />
              Enabled
            </label>
          </HeaderButton>,
          templateId &&
          <Tooltip arrow title='Update existing template'>
            <div>
              <HeaderButton
                title='Update'
                borderSolid
                onClick={() => actions.saveTemplate(attachEmailBody(message), templateId, true)}
              />
            </div>
          </Tooltip>,
          <SaveAsButton
            onClick={handleSave}
            disabled={disableSave}
            title={messageType === 'OneTime' ? 'Send' : 'Save'}
            disableOptions={!messageName.trim()}
            className={`sked-test-create-message-${messageType === 'OneTime' ? 'send' : 'save'}-button`}
            saveAsOptions={[
              messageType === 'OneTime' &&
              {
                onClick: () => actions.saveMessage(attachEmailBody(message), urlId),
                label: 'Save as draft',
                className: 'sked-test-create-message-save-as-draft',
              },
              {
                onClick: () => actions.saveTemplate(attachEmailBody(message), urlId, isTemplate),
                label: 'Save as template',
                className: 'sked-test-create-message-save-as-template',
              },
            ]}
          />
        ]}
      />
      <div style={{
        padding: '20px',
        paddingTop: 0,
        overflowY: 'auto',
      }}>
        <PopupTemplate />
        <Section style={{ marginTop: '10px' }}>
          {messageType === 'Template' ?
            <h4>
            What should this template's title be?
            &nbsp;
              <Tooltip
                title="Used so you can easily reference your templates!"
                placement="top"
                arrow>
                <Info style={{ fontSize: '14px' }} />
              </Tooltip>
            </h4>
            :
            <h4>
            What should this message's title be?
            &nbsp;
              <Tooltip
                title="Used so you can easily reference your messages!"
                placement="top"
                arrow>
                <Info style={{
                  fontSize: '14px',
                }} />
              </Tooltip>
            </h4>
          }
          <OneLineEditor
            title='Message Name'
            name={messageName}
            messagesPatch={(d: string) => actions.messagesPatch('messageName', d)}
            required
          />
        </Section>

        {R.cond([
          [R.equals('OneTime'), () => ([
            <Section className="section">
              <h4>
              Who should receive this message?
              </h4>
              <SelectButton
                variant='contained'
                disabled={filter === 'DateRange'}
                className='sked-test-one-time-message-appointment-types-button'
                onClick={() => actions.messagesPatch('filter', 'DateRange')}>
              Appointment Types
              </SelectButton>
            &nbsp;
              <SelectButton
                variant='contained'
                disabled={filter === 'Clients'}
                className='sked-test-one-time-message-clients-button'
                onClick={() => actions.messagesPatch('filter', 'Clients')}>
              Clients
              </SelectButton>
            &nbsp;
              {R.includes('Leads', features) &&
              <>
                <SelectButton
                  variant='contained'
                  disabled={filter === 'Leads'}
                  className='sked-test-one-time-message-leads-button'
                  onClick={() => actions.messagesPatch('filter', 'Leads')}>
                  Leads
                </SelectButton>
                &nbsp;
              </>
              }
              {
                R.includes('Campaigns', features) &&
              <SelectButton
                variant='contained'
                disabled={filter === 'ClientTag'}
                className='sked-test-one-time-message-tags-button'
                onClick={() => actions.messagesPatch('filter', 'ClientTag')}>
                Tags
              </SelectButton>
              }
            &nbsp;
              {R.includes('MessageEveryone', features) &&
              <SelectButton
                variant='contained'
                disabled={filter === 'Everyone'}
                className='sked-test-one-time-message-everyone-button'
                onClick={() => actions.messagesPatch('filter', 'Everyone')}>
                Everyone
              </SelectButton>}
              <br />
              <br />
              {filter === 'DateRange' &&
                <DateAndTypes
                  startDate={startDate}
                  endDate={endDate}
                  types={types}
                  professionals={professionals}
                  selectedTypes={selectedTypes}
                  // selectedPro={selectedPro}
                  patch={actions.messagesPatch}
                // addType={actions.addType}
                // addAllTypes={actions.addAllTypes}
                // removeType={actions.removeType}
                // selectPro={actions.selectPro}
                // clearSelected={actions.clearSelected}
                />
              }
              {filter === 'Clients' &&
                <ClientSearch
                  busy={busy}
                  clients={clients}
                  selectedClients={selectedClients}
                  // query={query}
                  // searchBy={searchBy}
                  // page={page}
                  getClients={actions.getClients}
                  // searchPatch={actions.messagesPatch}
                  addClient={actions.addClient}
                  removeClient={actions.removeClient}
                  clearSelected={actions.clearSelected}
                />
              }
              {filter === 'Leads' &&
              <ClientSearch
                busy={busy}
                clients={leads}
                selectedClients={selectedLeads}
                // query={query}
                // searchBy={searchBy}
                // page={page}
                getClients={actions.getLeads}
                // searchPatch={actions.messagesPatch}
                addClient={actions.addLead}
                removeClient={actions.removeLead}
                clearSelected={actions.clearSelected}
                isLead
              />
              }
              {filter === 'ClientTag' &&
              <TagEdit
                tags={tags}
                operator={operator}
                addTag={(tag) => actions.messagesPatch('tags', R.prepend(tag, tags))}
                deleteTag={(tag) => actions.messagesPatch('tags', R.without([tag], tags))}
                patch={actions.messagesPatch as typeof messagesActions.messagesPatch}
                hasLeads={R.includes('Leads', features)}
              />
              }
              {filter === 'Everyone' &&
                <div>All clients in your system will receive this message! <b>Use with caution.</b></div>
              }
            </Section>,
            <Section>
              <h4>When should this message be sent?</h4>
              <SelectButton
                variant='contained'
                disabled={scheduledFor === 'now'}
                onClick={() => actions.messagesPatch('scheduledFor', 'now')}>
              Immediately
              </SelectButton>
            &nbsp;
              <SelectButton
                variant='contained'
                disabled={scheduledFor !== 'now'}
                onClick={() => actions.messagesPatch('scheduledFor', now('datetime').toString())}>
              Scheduled
              </SelectButton>
              <br />
              <br />
              {
                scheduledFor === 'now' &&
                <div>Your message will be sent immediately.</div>
              }
              {
                scheduledFor !== 'now' &&
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <SmallerTextField
                    type='date'
                    label="Date"
                    value={format(LocalDateTime.parse(scheduledFor), 'yyyy-MM-dd')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const v = e.target.value;
                      if (v) {
                        actions.messagesPatch(
                          'scheduledFor',
                          LocalDateTime.parse(
                            v + 'T' + scheduledFor.split('T')[1]
                          ).toString());
                      }
                    }} />
                  &nbsp;
                  &nbsp;
                  <SmallerTextField
                    label='Time'
                    type='time'
                    defaultValue={format(LocalDateTime.parse(scheduledFor), 'HH:mm')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const v = e.target.value;
                      if (v)
                        actions.messagesPatch(
                          'scheduledFor',
                          LocalDateTime.parse(
                            scheduledFor.split('T')[0] + 'T' + v + ':00'
                          ).toString());
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }} />
                </div>
              }
            </Section>
          ])],
          [R.equals('Recurring'), () => ([
            <Section>
              <h4>Who should receive this message?</h4>
              {dateAndTypes({
                value,
                unit,
                beforeAfter,
                // timezone,
                types,
                professionals,
                selectedTypes,
                // selectedPro,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
              // addType: actions.addType,
              // addAllTypes: actions.addAllTypes,
              // removeType: actions.removeType,
              // selectPro: actions.selectPro,
              // clearSelected: actions.clearSelected,
              })}
            </Section>
          ])],
          [R.equals('Recall'), () => ([
            <Section>
              <h4>Who should receive this message?</h4>
              {typesSelection({
                types,
                professionals,
                selectedTypes,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
                includeInactive,
              })}
            </Section>,

            <Section>
              <h4>When should this message be sent?</h4>
              {sentSelection({
                value,
                sendAt,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
              })}
            </Section>
          ])],
          [R.equals('Birthday'), () => ([
            <Section>
              <h4>When should this message be sent?</h4>
              {sentSelection({
                sendAt,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
              })}
            </Section>
          ])],
          [R.equals('ApptChange'), () => ([
            <Section>
              <h4>Who should receive this message?</h4>
              {typesSelection({
                types,
                professionals,
                selectedTypes,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
              })}
            </Section>,
            <Section>
              <h4>When should this message be sent?</h4>
              {changeSelection({
                delay,
                changeType,
                source,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
                ehrSystem: office.ehrSystem,
                features,
              // plan: office.plan
              })}
            </Section>
          ])],
          [R.equals('Template'), () => ([
            <Section>
              <h4>What kind of template is this?</h4>
              <TemplateKind
                templateType={templateType}
                forFeature={forFeature}
                features={features}
                patch={actions.messageMassPatch as PatchType}
                isSmartReply={isSmartReply}
              />
            </Section>,
            templateType === 'Automation' && isSmartReply &&
              <SmartReply
                state={state}
                automations={automations}
                enabled={false}
                updateAutomation={actions.updateAutomation}
                addAutomation={actions.addLocalAutomation}
                isTemplate={messageType === 'Template'}
                addToPath={actions.addAutomationTemplateToPath}
                skedTemplate={skedTemplate}
              />,
            templateType !== 'Automation' &&
            <Section className="section">
              <h4>Who should receive this?</h4>
              {templateSettings({
                templateType,
                value,
                // value2,
                unit,
                // unit2,
                filter,
                // timezone,
                patch: actions.messagesPatch as typeof messagesActions.messagesPatch,
                beforeAfter,
                includeInactive,
                sendAt,
                delay,
                changeType,
                source,
                features,
              })}
            </Section>
          ])],
          [R.T, () => ([<div />])]
        ])(messageType)
        }

        {
          (R.includes('AdvancedAutomatedCampaigns', features) ||
          R.includes('BasicAutomatedCampaigns', features)) &&
          messageType === 'Automation' &&
          !isSmartReply &&
        <>
          {templateType !== 'Automation' &&
            <CampaignSelection
              patch={actions.messagesPatch as typeof messagesActions.messagesPatch}
              campaign={campaign}
            />}
          <AutomationCreation
            campaign={campaign}
            state={state}
            automations={automations}
            addAutomation={(a) => actions.addLocalAutomation(R.merge(a, { campaign }))}
            removeAutomation={actions.removeAutomation}
            updateAutomation={actions.updateAutomation}
            addToCampaign={actions.addAutomationTemplateToCampaign}
            messageName={messageName}
            enabled={isEnabled}
          />
        </>
        }

        {messageType === 'Automation' && isSmartReply &&
        <SmartReply
          state={state}
          automations={automations}
          enabled={isEnabled}
          updateAutomation={actions.updateAutomation}
          addAutomation={actions.addLocalAutomation}
          isTemplate={templateType === 'Automation'}
          templateId={templateId}
          skedTemplate={skedTemplate}
        />
        }

        {
          !R.includes('NewMessaging', features) &&
        <Section
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h4>How should this message be sent?</h4>
          <label>
            <Checkbox
              name="Email"
              checked={isEmail}
              onChange={() => {
                const nextV = !isEmail;
                const next = nextV ? {
                  subject,
                  body,
                  html: null,
                  attachments: [],
                } as Email : {
                  subject: '',
                  body: '',
                  attachments: [],
                } as Email;
                actions.messageMassPatch({
                  isEmail: nextV,
                  email: next,

                });
              }} />
            Email
          </label>
          <label>
            <Checkbox
              name="SMS"
              checked={isSMS}
              onChange={() => {
                const nextV = !isSMS;
                const next = nextV ? {
                  subject,
                  body,
                  attachments: [],
                } as Sms : {
                  subject: '',
                  body: '',
                  attachments: [],
                } as Sms;
                actions.messageMassPatch({
                  isSMS: nextV,
                  sms: next,
                });
              }} />
            SMS
          </label>
          {R.includes('SkedApp', features) &&
            <label>
              <Checkbox
                name="Push Notification"
                checked={isPush}
                onChange={() => {
                  const nextV = !isPush;
                  const next = nextV ? {
                    subject,
                    body,
                  } : {
                    subject: '',
                    body: '',
                  };
                  actions.messageMassPatch({
                    isPush: nextV,
                    push: next,
                  });
                }} />
              Push Notification
            </label>}
        </Section>
        }

        <Section style={{ marginBottom: '60px' }}>
          <h4>Compose Message</h4>
          <div style={{
            color: '#008BCF',
            fontSize: '14px'
          }}>
          Select SMS, Email, and Push to create a different message for each method.
          </div>
          <br />
          <MessageComposer
            features={features}
            forFeature={forFeature}
            isSMS={isSMS}
            isEmail={isEmail}
            isPush={isPush}
            subject={subject}
            body={body}
            email={email}
            sms={sms}
            push={push}
            actions={actions}
            placeholders={placeholders}
            setSMSError={setSMSError}
            setEmailError={setEmailError}
            setPushError={setPushError}
            messageType={messageType}
            forceNonHtml={isSpark && Boolean(templateId)}
          />
          {R.isEmpty(message.name) &&
          <>
            <a
              style={{ color: 'red', marginBottom: '0px' }}
              onClick={() => window.scrollTo(0, 0)}>
              A message name is required!
            </a>
            <br />
          </>}
          {receiversError &&
          <a
            style={{ color: 'red' }}
            onClick={() => window.scrollTo(0, 250)}>
            {receiversErrorMessage}
          </a>}
          {SMSError &&
          <p style={{ color: 'red' }}>Your SMS message is over the 2048 character limit. <b>This message cannot not be sent!</b></p>}
          {assumedCredits > 10 &&
          <p style={{ color: 'red' }}>
            Since this message is over 10 credits, <b>it will be sent as an MMS (3 credits).</b>
          </p>}
          {lacksOptOutLink}
          {R.cond([
            [R.equals('credits'), () => {
              const {
                creditsAllowed,
                creditsUsed,
                overCost,
                clients,
              } = popupData;
              const totalCredits = credits * clients;
              creditsPopup({
                // message: attachEmailBody(message),
                // actions,
                // id: urlId,
                creditsAllowed,
                creditsUsed,
                credits: totalCredits,
                overCost,
                response: (r) => {
                  if (r) {
                    return setPopup('client');
                  } else {
                    return setPopup(null);
                  }
                },
              });
            }],
            [R.equals('client'), () => {
              const {
                clients
              } = popupData;
              const filterName = filter === 'Leads' ? 'lead' : 'client';
              return <SendModal
                open={popup === 'client'}
                onClose={() => setPopup(null)}
                clients={clients}
                sentTo={filterName}
                isEmail={isEmail}
                isSMS={isSMS}
                isPush={isPush && R.includes('SkedApp', features)}
                scheduledFor={scheduledFor}
                timezone={timezone}
                confirm={() => {
                  urlId === '0' ?
                    actions.sendMessage({ message: attachEmailBody(message), id: undefined }) :
                    actions.sendDraftMessage({ message: attachEmailBody(message), id: urlId });
                }}
              />;
            }],
            [R.T, R.always(null)]
          ])(popup)}
        </Section>
      </div>
    </>
  );
}
