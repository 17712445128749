import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  popup: {
    position: 'fixed',
    left: '60%',
    zIndex: '2',
    top: '7.5%'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  margin: {
    margin: '0px'
  },
  cutoff: {
    color: '#ABABAE',
  },
  checkbox: {
    marginRight: 3,

    '& svg': {
      width: '15px !important',
      height: '15px !important',
    }
  },
}));
