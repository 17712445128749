import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Chip,
  Divider, Tooltip, FormControl, IconButton, Select, MenuItem,
  InputLabel, TextField, Grid,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import ThumbsUpIcon from '@mui/icons-material/ThumbUp';
import { LocalDate } from '@js-joda/core';
import * as R from 'ramda';
import * as rawActions from './dashboard.actions';
import Appointments from '../Appointments/Appointments.component';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import { PopupTemplate } from '../../services/Popup.js';
import queryString from 'query-string';
import { ContactSupport } from '../../components/feature-disabled.component';
import { Chart } from './components/chart.component.jsx';

import Header from '../../components/PageHeader/PageHeader.component';
import { PageHeader } from '../../components/PageHeader';
import Loading from '../../components/Loading/Loading.component';
import { tzParseFormat } from '../../services/joda.js';

import { useTitle } from '../../services/useTitle';
import { getLocations } from '../Settings/routes/Business/business.actions.js';

const ThumbsUpIconColored = withStyles({
  colorPrimary: {
    color: 'green',
  },
})(ThumbsUpIcon);

const BookingStatusStandard = ({ status = '' }) => {
  const fontSize = 16;
  if (status === 'Confirmed') {
    return <Tooltip placement="top" arrow title="Confirmed">
      <CheckBoxIcon style={{ fontSize }} className="success" />
    </Tooltip>;
  }
  if (status === 'Pending') {
    return (
      <Tooltip arrow title="Pending" placement="top">
        <MoreHorizIcon style={{ fontSize }} className="pending" />
      </Tooltip>
    );
  }
  if (status === 'OverScheduled') {
    return (
      <Tooltip placement="top" arrow title="Over Scheduled Error.  A reschedule is needed.">
        <CloseIcon style={{ fontSize }} className="error" />
      </Tooltip>
    );
  }
  return (
    <Tooltip placement="top" arrow title="Unknown Error.  Contact support for help.">
      <CloseIcon style={{ fontSize }} className="error" />
    </Tooltip>
  );
};

const Confirmed = ({ confirmed, timezone }) => {
  if (!confirmed) {
    return null;
  }
  const time = tzParseFormat(confirmed, timezone, 'yyyy-MM-dd h:mm a');
  return (
    <Tooltip arrow placement='top' title={'Confirmed: ' + time}>
      <ThumbsUpIconColored
        fontSize='small'
        color='primary'
        style={{ fontSize: 17 }}
      />
    </Tooltip>
  );
};

const DashboardListItemStandard = ({
  onClick,
  client,
  timeAt,
  changedAt,
  status,
  confirmed,
  timezone,
}) => (
  <>
    <ListItem className="dashboard-item" alignItems="flex-start" button onClick={onClick}>
      <Grid display="flex" justifyContent="space-between" width="100%">
        <div className='client'>{client}</div>
        <div className='status'>
          {status && <BookingStatusStandard status={status} />}
          {confirmed &&
            <>
              &nbsp;
              <Confirmed confirmed={confirmed} timezone={timezone} />
            </>
          }
        </div>

      </Grid>
      <div className='time'>{timeAt}</div>
      <div className='changed'>{changedAt}</div>
    </ListItem>
    <Divider />
  </>
);
const DashboardStandardList = ({
  className,
  error,
  data,
  title,
  failedMessage,
  emptyMessage,
  select,
  classes,
  loading,
  timezone,
}) => {
  return (
    <Paper className={classes.listContainer + ' ' + className} >
      <List>
        <ListItem>
          <ListItemText primary={title} />
          <ListItemSecondaryAction>
            <Chip size="small" label={<strong>{data || R.isEmpty(data) ? data.length : 0}</strong>} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />

      <List classes={{ root: classes.listRoot }}>
        {loading && <ListItem><div>Loading...</div></ListItem>}
        {error && <ListItem><div>{failedMessage}</div></ListItem>}
        {error && <ListItem><div>Please refresh the page.</div></ListItem>}

        {!loading && data && data.length === 0 && <ListItem>
          <div>{emptyMessage}</div>
        </ListItem>}

        {!loading && data && data.length > 0 && data.map(({
          data, client, timeAt, changedAgo, status
        }) => {
          return (
            <DashboardListItemStandard
              key={data.id}
              onClick={() => select(data)}
              client={client}
              timeAt={timeAt}
              changedAt={changedAgo}
              status={status}
              timezone={timezone}
              confirmed={data.appointment && data.appointment.confirmed}
            />
          );
        })}
      </List>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  listRoot: {
    height: '500px',
    overflowY: 'auto'
  },
  listContainer: {
    margin: '5px',
    width: '100%',
    height: '100%',
  },
  dashboard: {
    padding: '20px',
    overflowY: 'auto',

    '& svg.success': {
      color: theme.palette.success.main
    },
    '& svg.pending': {
      color: theme.palette.warning.main
    },
    '& svg.error': {
      color: theme.palette.error.main
    },
    '& .MuiChip-root': {
      fontSize: '10px'
    },
    '& .new-users .MuiChip-root': {
      backgroundColor: theme.palette.success.main,
      color: '#FFF'
    },
    '& .portal-appts .MuiChip-root': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF'
    },
    '& .new-appts .MuiChip-root': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF'
    },
    '& .resked-appts .MuiChip-root': {
      backgroundColor: theme.palette.warning.main,
      color: '#FFF'
    },
    '& .canceled-appts .MuiChip-root': {
      backgroundColor: theme.palette.error.main,
      color: '#FFF'
    },
    '& .dashboard-item': {
      flexDirection: 'column'
    },
    '& .dashboard-item .status': {
      display: 'flex',
      marginTop: '4px',
      marginLeft: '4px',
    },
    '& .dashboard-item .time': {
      color: 'rgba(0,0,0,0.5)',
      fontSize: '13px',
    },
    '& .dashboard-item .changed': {
      color: 'rgba(0,0,0,0.5)',
      fontSize: '13px',
      fontStyle: 'italic'
    },
  },
  dashboardHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    padding: '10px'
  },
  dashboardListContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-5px',
    marginRight: '-5px'
  },
  listControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '20px',
  },
  image: {
    maxWidth: '100%',
    height: 'auto'
  },
  date: {
    width: '160px',
  }
}));


const helpEHRs = ['Genesis', 'PlatinumApi'];
const localSyncerEHRs = ['ChiroTouch', 'Platinum'];

const DashboardTemplate = ({
  state, pageId, actions, newUsers, logsBusy, webmodule, newApts,
  resked, canceled, selectedAppointment, busy, aptBusy, graph, tz,
  year, classes, selectedDate, selectDate, refreshLogs,
  update, hasLocations, locations, selectLocation, locationId, 
}) => {
  
  return (
    <>
      <Header title='Monthly App Usage' fixed pageId={pageId} />
      <div className={classes.dashboard}>
        <PopupTemplate />
        {state === 'DASH' && <PopupTemplate />}
        {!busy ?
          <div>
            <Appointments
              open={state === 'DASH_APT'}
              onClose={actions.backToDash}
              appointment={selectedAppointment}
              busy={aptBusy}
              from={'DASH'}
              tz={tz}
              gotoClient={actions.selectClientDialog}
              back={() => actions.back()}
            />
            <ClientEditDialog
              open={state === 'CLIENT_SELECT'}
              from={'DASH'}
              onClose={actions.backToDash}
              back={actions.back}
              gotoClient={actions.back}
            />

            <Chart graph={graph} update={update} year={year} />
            <div className={classes.listControls}>
              <PageHeader align="left" variant="h5">
                App and Portal Updates
              </PageHeader>
              <div style={{ flexGrow: 1 }}></div>
              <IconButton disabled={logsBusy} onClick={refreshLogs}>
                <SyncIcon className={logsBusy ? 'sked-spin-l' : ''} />
              </IconButton>
              {hasLocations && !R.isEmpty(locations) &&
                <FormControl variant="standard" size='small' style={{ marginRight: '10px' }}>
                  <InputLabel shrink={true} id="locations-label">
                    Locations
                  </InputLabel>
                  <Select
                    labelId="locations-label"
                    value={locationId}
                    style={{ fontSize: '14px' }}
                    onChange={e => selectLocation(e.target.value)}
                    renderValue={(v) => {
                      const loc = locations.find(({ id }) => id === v);
                      return loc.name;
                    }}
                  >
                    {locations.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        <ListItemText primary={name} style={{ fontSize: '14px' }} />
                      </MenuItem>))}
                  </Select>
                </FormControl>}
              <FormControl>
                <TextField
                  className={classes.date}
                  value={LocalDate.parse(selectedDate)}
                  onChange={(event) => selectDate(event.target.value)}
                  type='date'
                  variant='standard'
                />
              </FormControl>
            </div>
            <div className={classes.dashboardListContainer}>
              <DashboardStandardList
                classes={classes}
                className='new-users'
                loading={logsBusy}
                title="New Users"
                error={newUsers.error}
                data={newUsers}
                failedMessage="Error: Failed to get new users."
                emptyMessage="No new users"
                select={actions.selectClient}
                timezone={tz}
              />

              <DashboardStandardList
                classes={classes}
                loading={logsBusy}
                className='new-appts'
                title="New Appts."
                error={newApts.error}
                data={newApts}
                failedMessage="Error: Failed to get appointments."
                emptyMessage="No new appointments"
                select={apt => actions.selectAppointment(apt.appointment, apt.appointment.client)}
                timezone={tz}
              />

              <DashboardStandardList
                classes={classes}
                loading={logsBusy}
                className='resked-appts'
                title="Rescheduled"
                error={resked.error}
                data={resked}
                failedMessage="Error: Failed to get appointments."
                emptyMessage="No rescheduled appointments"
                select={apt => actions.selectAppointment(apt.appointment, apt.appointment.client)}
                timezone={tz}
              />

              <DashboardStandardList
                classes={classes}
                loading={logsBusy}
                title="Canceled"
                className='canceled-appts'
                error={canceled.error}
                data={canceled}
                failedMessage="Error: Failed to get appointments."
                emptyMessage="No canceled appointments"
                select={apt => actions.selectAppointment(apt.appointment, apt.appointment.client)}
                timezone={tz}
              />

              <DashboardStandardList
                classes={classes}
                loading={logsBusy}
                title="Portal Appts."
                className='portal-appts'
                error={webmodule.error}
                data={webmodule.data}
                failedMessage="Error: Failed to get webmodule data."
                emptyMessage="No new appointments"
                select={apt => actions.selectAppointment(apt.appointment, apt.appointment.client)}
                timezone={tz}
              />
            </div>
          </div>
          :
          <Loading loading />
        }
      </div>
    </>
  );
};

const getStartDate = () => {
  return LocalDate.now().minusDays(3).toString();
};

const DashboardFunctional = () => {
  const location = useLocation();
  useTitle('App and Portal Usage');
  const [state, setState] = React.useState({
    year: LocalDate.now().year(),
    selectedDate: getStartDate(),
    locationId: 0,
  });
  const update = data => setState(s => R.merge(s, data));
  const selectDate = newDate => {
    if (!newDate || newDate instanceof Error) {
      return;
    }
    update({ selectedDate: newDate.toString() });
  };
  const selectLocation = (id) => {
    update({ locationId: id });
  };
  const classes = useStyles();

  const store = useSelector((state) => {
    const locations = R.pathOr([], ['business', 'locations'], state);
    const hasLocations = R.pipe(
      R.pathOr([], ['login', 'features']),
      R.includes('Locations')
    )(state);
    return ({
      ...state.dashboard,
      ehrSystem: state.login.office.ehrSystem,
      tz: state.login.office.timezone,
      office: state.login.office,
      isEnabled: R.includes('SkedApp', R.pathOr([], ['login', 'features'], state))
        ||
        R.includes('NPPortal2', R.pathOr([], ['login', 'features'], state)),
      locations: hasLocations && !R.isEmpty(locations) ?
        R.prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
    });
  });

  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const refreshLogs = () => {
    dispatch(rawActions.getGraph(state.year));
    dispatch(rawActions.getLogs(state.selectedDate, store.tz, state.locationId));
  };

  React.useEffect(() => {
    if (!store.isEnabled) {
      console.log('dashboard not enabled');
      return;
    }
    const query = queryString.parse(location.search);
    if (R.has('apt', query)) {
      dispatch(rawActions.getApt(R.prop('apt', query)));
    }
    dispatch(rawActions.getGraph(state.year));
  }, [dispatch, store.office, state.year]);

  React.useEffect(() => {
    if (!store.isEnabled) {
      console.log('dashboard not enabled');
      return;
    }
    dispatch(rawActions.getLogs(state.selectedDate, store.tz, state.locationId));
    dispatch(getLocations());
  }, [dispatch, state.selectedDate, store.tz, state.locationId]);

  const pageId = R.cond([
    [R.includes(R.__, helpEHRs), R.always('dashboard-genesis-platinum')],
    [R.includes(R.__, localSyncerEHRs), R.always('dashboard-ct-platinum')],
    [R.equals('None'), R.always('dashboard-standalone')],
    [R.T, R.always(null)]
  ])(store.ehrSystem);

  if (!store.isEnabled) {
    return (
      <>
        <Header title='Monthly App Usage' fixed pageId={pageId} />
        <div className={classes.dashboard}>
          <div>
            <p>
              The SKED dashboard shows you real time statistics of patients using the app to reschedule, cancel or book new appointments. See the preview image below.
            </p>
            <ContactSupport />
          </div>
          <img src="/samples/dashboard-screenshot.png" className={classes.image} />
        </div>
      </>
    );
  }

  return DashboardTemplate({
    ...state,
    ...store,
    refreshLogs,
    actions,
    selectDate,
    location,
    classes,
    update,
    selectLocation,
    pageId,
  });
};

export default DashboardFunctional;
