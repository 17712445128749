import * as at from '../../../../actionTypes';
import api, { skedApi } from '../../../../services/api.js';
import { popup } from '../../../../services/Popup.js';
import { remoteAction } from '../../../../services/actionService.js';
import {
  LocalDateTime,
} from '@js-joda/core';
import { etd } from '../../../../services/utilities.js';
import * as R from 'ramda';

const creditLimitPlans = [
  'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise', 'SkedBestest', 'SkedGenesisCash'
];
const justCreditPlans = ['Sked', 'SkedJr', 'SkedMsg'];

export const getPlan = () => remoteAction({
  type: at.BILLING_REMOTE_GET,
  action: () => api.get('subscription').then(({
    basePlan, msgPlan, customerId,
  }) => {
    return {
      busy: false,
      plan: basePlan,
      msgPlan,
      showLimit: R.includes(basePlan.plan, creditLimitPlans),
      showCredits: R.includes(basePlan.plan, justCreditPlans),
      customerId,
    };
  }).catch((error) => {
    console.error(error);
    if (error === 'rejected') {
      popup?.('Warning!', 'Subscription has been canceled! Contact SKED Support (info@sked.life) for help!');
    } else {
      popup?.('Error!', 'No subscription found! Contact SKED Support (info@sked.life) for help!');
    }
    // return error;
  }),
});

const YEARLY_POLICY = '2023-product-contract-yearly';
const MONTHLY_POLICY = '2023-product-contract-monthly';

const getPolicy = (name) => {
  return api.get(`office/policy/${name}`);
};

export const getCardInfo = (navigate) => remoteAction({
  type: at.BILLING_REMOTE_GET,
  action: () => Promise.all([
    api.get('subscription/v2/sked'),
    api.get('subscription/v2/spark'),
    api.get('subscription/usage'),
    api.get('subscription/upcoming'),
    getPolicy(MONTHLY_POLICY),
    getPolicy(YEARLY_POLICY),
    api.post('subscription/v2/spark/stripe', {}),
    api.post('subscription/v2/sked/stripe', {}),
  ].map(etd)).then(([
    subSked, subSpark, allUsage, up, monthlyAgreement, yearlyAgreement, spark, sked
  ]) => {
    const sub = subSpark.data ? subSpark : subSked;
    const { cardInfo, plan, paymentAction } = R.propOr({}, 'data')(sub);
    const { basePlan, msgPlan } = plan || { basePlan: undefined, msgPlan: undefined };
    const upcoming = R.propOr({}, 'data')(up);
    const usage = R.pipe(
      R.propOr([], 'data'),
      R.groupBy(({ date }) => {
        return LocalDateTime.parse(R.dropLast(1, date)).year();
      }),
      R.map((val) => {
        return R.pipe(
          R.groupBy(({ date }) =>
            LocalDateTime.parse(R.dropLast(1, date)).month()),
          R.map((vals) => {
            return R.reduce((acc, value) => {
              return {
                usage: acc.usage + value.usage,
                amountPaid: acc.amountPaid + value.amountPaid,
              };
            }, { usage: 0, amountPaid: 0 }, vals);
          })
        )(val);
      })
    )(allUsage);
    const base = R.propOr([], 'plan', basePlan);
    return {
      card: cardInfo,
      plan: basePlan,
      msgPlan,
      busy: false,
      earliestYear: Number(R.pipe(R.keys, R.sort((a, b) => a > b), R.head)(usage)),
      usage,
      upcoming,
      showLimit: R.includes(base, creditLimitPlans),
      showCredits: R.includes(base, justCreditPlans),
      monthlyAgreement,
      yearlyAgreement,
      sparkStripeUrl: R.pathOr(null, ['data', 'url'], spark),
      skedStripeUrl: R.pathOr(null, ['data', 'url'], sked),
      paymentAction,
    };
  }).catch((error) => {
    console.log(error);
    if (error.response) {
      console.error(error);
      popup('Error!', 'Failed to get card information.');
    } else {
      navigate('/offline');
    }
  }),
});

export const billingPatch = ({ type, data }) => ({
  type,
  data,
});

export const uploadCard = (tokenId) => remoteAction({
  type: at.BILLING_REMOTE_GET,
  errorText: 'Failed to update card information.',
  successText: 'Business Information Saved',
  action: () => api.put('subscription', { tokenId })
    .then(({ cardInfo, basePlan, msgPlan }) => {
      return {
        card: cardInfo,
        plan: basePlan,
        msgPlan,
        busy: false,
        cardForm: false,
        state: 'INFO',
      };
    })
    .catch((error) => {
      console.error(error);
    }),
});

export const updateCustomerId = (data) => {
  return skedApi({
    method: 'PUT',
    url: 'subscription/update-customer',
    data,
    headers: {
      'X-As-Office': null
    }
  });
};


export const verifyDeposits = ({
  dep1, dep2, isSpark,
}) => {
  const s = isSpark ? 'spark' : 'sked';
  const amount1 = dep1.includes('.') ? dep1.split('.')[1] : dep1;
  const amount2 = dep2.includes('.') ? dep2.split('.')[1] : dep2;
  return api.post(`/subscription/v2/${s}/verify_microdeposits`, {
    amount1, amount2,
  });
};
