import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as rawActions from './recurring.actions.jsx';
import { reset } from './routes/RecurringEdit/recurring-edit.actions.jsx';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import { BasicMessageTable } from './components/messages-table.component.jsx';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { useTitle } from '../../services/useTitle.js';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: 0,
    overflowY: 'auto',
  },
  headerRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
}));

const MessagesTemplate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    all,
    tz,
    query,
    filter,
    office,
    autoresponder,
    hasFeature,
    headerHeight,
  } = useSelector((state) => ({
    ...state.recurring,
    tz: R.pathOr(null, ['login', 'office', 'timezone'])(state),
    office: R.pathOr(null, ['login', 'office'])(state),
    hasFeature: R.includes('Autoresponder', R.pathOr([], ['login', 'features'], state)),
    headerHeight: state.login.headerHeight,
  }));

  useTitle('Auto-Responder Messages');

  React.useEffect(() => {
    actions.getAutoresponderMessages();
    dispatch(reset());
  }, [office]);

  if (!hasFeature) {
    return (
      <FeatureDisabled title="Auto-Responder Messages">
        <p>Don't want to reply to all the messages? Setup an autoresponder to take care of it for you!</p>
      </FeatureDisabled>
    );
  }

  return (
    <>
      <Header
        title='Auto-Responder Messages'
        pageId="autoresponder"
        rightIcons={[
          <HeaderButton
            title='New Message'
            className='sked-test-autoresponder-compose'
            Icon={AddIcon}
            onClick={() => actions.addAutoresponderMessage({
              body: '',
              enabled: false,
              onlyWhenClosed: false,
            })}
          />
        ]}
        onlyIconsWidth={520}
      />
      <div className={classes.root}>
        <PopupTemplate />
        <BasicMessageTable
          busy={false}
          messages={autoresponder}
          all={all}
          tz={tz}
          selectAll={R.pipe(R.assoc('type', 'autoresponder'), actions.selectAll)}
          selectMessage={R.pipe(R.assoc('type', 'autoresponder'), actions.selectMessage)}
          copyMessage={(msg) => {
            actions.addAutoresponderMessage(R.assoc('enabled', false, msg));
          }}
          deleteMessage={actions.autoresponderDeleteMessage}
          deleteSelected={actions.autoresponderDeleteSelected}
          toggle={(m) => actions.toggleEnable(m, 'autoresponder')}
          query={query}
          filter={filter}
          messagesPatch={actions.messagesPatch}
          hideSearch
          error=''
          updateAutoresponderMessage={(newMessage, index) => {
            if (newMessage.enabled) {
              actions.disableOtherAutoresponderMessages(autoresponder, newMessage.autoId);
            }
            actions.updateAutoresponderMessage(newMessage, index);
          }}
          headerHeight={headerHeight}
        />
      </div>
    </>
  );
};

export default MessagesTemplate;
