import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Button, IconButton, CircularProgress, TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as actions from '../clientlists.actions';
import { Campaign, EditMessage, PushEdit, Message } from '../clientlist-types';
import { Automation, GetAction, GetFilter } from '../../Automations/automation-types';
import { usePromise } from '../../../services/promise.hook';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer, Row } from '../../../components/PageHeader';
import {
  includes, remove, update, cond, T, pathOr, propOr, has
} from 'ramda';
import { AutomationsList } from '../../Automations/automations.page';
import { saveAutomation } from '../../Automations/automations.actions';
import {
  MessageComposer,
  clientPlaceholders,
  officePlaceholders,
  removePlaceholders,
} from '../../../components/CreateMessage/CreateMessage.component';
import OneLineEditor from '../../../components/Editor/components/OneLineEditor.component';
import { loadingMessage } from '../../RecurringMessage/routes/RecurringEdit/recurring-edit.actions.jsx';
import { makeMessage } from '../../Automations/components/message-dialog.component';
import api from '../../../services/api.js';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonRow: {
    width: '100%',
  },
}));

interface WizardProps {
  open: boolean;
  onClose: () => void;
  save: (listId: number) => void;
  template: Campaign;
}

interface MessagePage {
  type: 'Message';
  data: Message;
  comment: string;
}

interface AutomationPage {
  type: 'Automation';
  data: Automation;
  comment: string;
}

interface CampaignPage {
  type: 'Campaign';
  data: Campaign;
  comment: string;
}

type Page = CampaignPage | AutomationPage | MessagePage;

interface EditCampaignProps {
  campaign: Campaign;
  updateCampaign: (newCampaign: Campaign) => void;
}

const EditCampaign = ({ campaign, updateCampaign }: EditCampaignProps) => {
  const [name, setName] = useState(campaign?.displayName);
  const [description, setDescription] = useState(campaign?.description);
  useEffect(() => {
    updateCampaign({
      ...campaign,
      displayName: name,
      description,
    });
  }, [name, description]);
  return (
    <>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        value={name}
        onChange={e => setName(e.target.value)}
        label="Name"
        type="text"
        required
        error={name.trim() === ''}
        helperText={name.trim() === '' && 'Required Field'}
      />
      <br />
      <TextField
        margin="dense"
        id="description"
        value={description}
        onChange={e => setDescription(e.target.value)}
        label="Description"
        type="text"
      />
    </>
  );
};

interface SMSEdit {
  body: string;
  attachments: [],
}

interface EditMessageProps {
  message: Message;
  onUpdate: (message: Message) => void;
  forceUpdate: boolean;
  template: Campaign;
  automations: Automation[];
  removeAutomation: (idx: number, autoId: number) => void;
  updateAutomation: (idx: number, auto: Automation) => void;
}

interface BigEditMessage {
  messageName: string;
  email: any;
  sms: SMSEdit;
  push: PushEdit;
  body: string;
  subject: string;
  isEmail: boolean;
  isPush: boolean;
  isSMS: boolean;
}

const EditMessage = ({
  message, onUpdate, forceUpdate, template, automations,
  removeAutomation, updateAutomation,
}: EditMessageProps) => {
  const { features } = useSelector((state) => ({
    features: pathOr([], ['login', 'features'])(state),
  }));
  const [state, setState] = useState<EditMessage>({
    name: '',
    email: {
      subject: '',
      html: null,
      attachments: [],
      shouldFrame: true,
    },
    sms: {
      body: '',
      attachments: [],
    },
    push: {
      subject: '',
      body: '',
      attachments: [],
    }
  });
  const setSMSError = React.useState(false)[1];
  const defaultEmail = {
    subject: '',
    body: '',
    html: includes('HtmlEmail', features) ? '' : null,
    shouldFrame: true,
    attachments: [] as any[],
  };
  const defaultSms = {
    body: '',
    attachments: [] as any[],
  };
  const defaultPush = {
    subject: '',
    body: '',
    attachments: [] as any[],
  };
  const clientHolders =
    clientPlaceholders.filter((holder) => removePlaceholders(features, holder));
  const officeHolders =
    officePlaceholders.filter((holder) => removePlaceholders(features, holder));
  const placeholders = [
    { title: 'Client', placeholders: clientHolders },
    { title: 'Office', placeholders: officeHolders },
    // (includes('Appointment', trigger))
    //   &&
    //   {title: 'Appointment', placeholders: apptPlaceholders}
  ];

  useEffect(() => {
    if (message.id)
      loadingMessage(message, 'edit').then((m: BigEditMessage) => {
        const email = m.email.html ? {
          subject: m.email.subject,
          html: m.email.html,
          attachments: m.email.attachments,
          shouldFrame: m.email.shouldFrame,
        } : {
          subject: m.email.subject,
          body: m.email.body,
          attachments: m.email.attachments,
        };
        setState({
          email,
          sms: m.sms,
          push: m.push,
          name: m.messageName,
        });
      });
  }, [message]);

  useEffect(() => {
    if (forceUpdate) {
      const temp = makeMessage(state) as unknown as Message;
      const msg = {
        ...message,
        ...temp,
        messageType: {
          Automation: [] as [],
        },
        email: temp?.email ? {
          subject: temp.email?.subject,
          attachmentIds: temp.email?.attachmentIds,
          body: temp.email?.body ? {
            PlainText: temp.email?.body,
          } : {
            HTML: {
              html: temp.email?.html,
              shouldFrame: temp?.email?.shouldFrame,
            },
          },
        } : null,
        sms: temp?.sms ? {
          attachmentIds: temp.sms?.attachmentIds,
          body: temp.sms?.body,
        } : null,
        push: temp?.push ? {
          attachmentIds: temp.push?.attachmentIds,
          body: temp.push?.body,
          subject: temp.push?.subject,
        } : null,
      };
      onUpdate(msg as Message);
    }
  }, [forceUpdate]);

  return (
    <div>
      <h4>Message Name</h4>
      <OneLineEditor
        title='Message Name'
        name={state.name}
        messagesPatch={(d: string) => setState({ ...state, name: d })}
        required
      />
      <AutomationsList
        automations={automations}
        noSave
        hideCancel
        saveIsUpdate
        defaultCampaign={{
          name: template?.displayName,
          id: template?.id,
        }}
        removeAutomation={removeAutomation}
        updateAutomation={updateAutomation}
        enabled={true}
        onSave={() => () => null}
        forceSave={forceUpdate}
        disabledActions
        disabledActionsMessage={`send ${state.name}`}
      />
      <h4>Compose Message</h4>
      <MessageComposer
        features={features}
        isSMS={Boolean(pathOr(false, ['sms', 'body'], state))}
        isEmail={Boolean(pathOr(false, ['email', 'subject'], state))}
        isPush={Boolean(pathOr(false, ['push', 'body'], state))}
        email={propOr(defaultEmail, 'email', state)}
        sms={propOr(defaultSms, 'sms', state)}
        push={propOr(defaultPush, 'push', state)}
        actions={{
          messageMassPatch: (data: any) => {
            setState({ ...state, ...data });
          },
          messagesPatch: (prop: string, data: any) => {
            setState({ ...state, [prop]: data });
          }
        }}
        placeholders={placeholders}
        setSMSError={setSMSError}
        messageType='Automation'
      />
    </div>
  );
};

interface ErrorObj {
  type: 'Campaign' | 'Message',
  error: string,
}

const defaultQuery = {
  data: [] as any[],
  totalPages: 1,
  totalCount: 0,
  page: 1,
  perPage: 25,
};
const CampaignWizard = ({
  open,
  onClose,
  save,
  template,
}: WizardProps) => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<Page[]>([]);
  const [state, setState] = useState('CURRENT');
  const [saving, setSaving] = useState(false);
  const [templateState, setTemplateState] = useState(template);
  const [error, setError] = useState<ErrorObj>(null);
  const classes = useStyles();
  const messagesState = usePromise(actions.getMessages, defaultQuery);
  const automationsState = usePromise(actions.getAutomations, defaultQuery);
  const messageAutomationsState = usePromise(actions.getMessageAutomations, []);

  useEffect(() => {
    if (template && open) {
      setTemplateState(template);
      messagesState.invoke({
        page: 1,
        perPage: 100,
        query: {
          campaignId: template.id,
          name: '',
        },
        template: true,
      }).then(({ data }: { data: Message[] }) => {
        messageAutomationsState.invoke({
          msgs: data.map(({ id }: Message) => id),
          campaignId: template.listId,
        });
      });
      automationsState.invoke({
        page: 1,
        perPage: 100,
        query: {
          campaignId: template.id,
          action: null,
          filter: null,
          trigger: null,
          enabled: null,
          hidden: false,
        },
        template: true,
      });
    }
  }, [open, template]);

  const loading = useMemo(() => {
    return messagesState.loading || automationsState.loading;
  }, [messagesState.loading, automationsState.loading]);

  useEffect(() => {
    const firstPage: CampaignPage = {
      type: 'Campaign',
      data: templateState,
      comment: 'This wizard will walk you through creating your campaign from this template. Let\'s start by editing the name and description for this campaign.',
    };
    const autos: AutomationPage[] = automationsState.data.data.map((auto: Automation) => ({
      type: 'Automation',
      data: auto,
      comment: auto.comment,
    }));
    const msgs: MessagePage[] = messagesState.data.data.map((msg: Message) => ({
      type: 'Message',
      data: msg,
      comment: 'Please edit this message.',
    }));
    setPages([firstPage, ...autos, ...msgs]);
  }, [templateState, messagesState.data, automationsState.data, messageAutomationsState.data]);

  const saveIt = async () => {
    setState('CURRENT');
    setSaving(true);
    let finalCampaign = { id: 0, listId: 0 };
    if (pages[0].type === 'Campaign') {
      try {
        finalCampaign = await actions.addClientList({
          ...pages[0].data,
          name: pages[0].data.displayName,
          isCampaign: true,
          template: false,
        });
      } catch (e) {
        setError({
          type: 'Campaign',
          error: e.message,
        });
        setSaving(false);
        return;
      }
    }
    const remaingingPages = pages.slice(1);
    try {
      await Promise.all(remaingingPages.map(({ type, data }) => {
        if (type === 'Automation') {
          let trigger = data.trigger;
          const actions = data.actions.map((action: GetAction) => {
            if (has('ClientList', action)) {
              return ({
                ClientList: {
                  ...action.ClientList,
                  listId: finalCampaign.listId,
                },
              });
            }
            return action;
          });
          const filters = data.filters.map((filt: GetFilter) => {
            if (has('Appointment', filt.predicate)) {
              if (has('ClientList', filt.predicate.Appointment)) {
                return ({
                  isTrue: true,
                  predicate: {
                    Appointment: {
                      ...filt.predicate.Appointment,
                      ClientList: {
                        ...filt.predicate.Appointment.ClientList,
                        listId: finalCampaign.listId,
                      }
                    },
                  },
                });
              }
            }
            if (has('Client', filt.predicate)) {
              if (has('ClientList', filt.predicate.Client)) {
                return ({
                  isTrue: true,
                  predicate: {
                    Client: {
                      ...filt.predicate.Client,
                      ClientList: {
                        ...filt.predicate.Client.ClientList,
                        listId: finalCampaign.listId,
                      }
                    },
                  },
                });
              }
            }
            return filt;
          });
          if (has('ClientList', data.trigger)) {
            trigger = ({
              ClientList: {
                ...data.trigger.ClientList,
                listId: finalCampaign.listId,
              },
            });
          }
          const autoWithCampaign = {
            ...data,
            trigger,
            actions,
            filters,
            deleteActions: [] as number[],
            isTemplate: false,
            id: 0,
            enabled: true,
          };
          return saveAutomation(autoWithCampaign);
        }
        return api.post('message', {
          ...data,
          isEnabled: true,
          campaignId: finalCampaign.id,
        }).then((msg: any) => {
          const innerAutomations = messageAutomationsState.data.find(({ msgId }) => data.id === msgId)?.automations;
          return Promise.all(innerAutomations.map((automation: Automation) => {
            let trigger = automation.trigger;
            const actions = automation.actions.map((action: GetAction) => {
              if (has('ClientList', action)) {
                return ({
                  ClientList: {
                    ...action.ClientList,
                    listId: finalCampaign.listId,
                  },
                });
              }
              if (has('SendMessage', action)) {
                return ({
                  SendMessage: { Id: msg.id },
                });
              }
              return action;
            });
            const filters = automation.filters ? automation.filters.map((filt: GetFilter) => {
              if (has('Appointment', filt.predicate)) {
                if (has('ClientList', filt.predicate.Appointment)) {
                  return ({
                    isTrue: true,
                    predicate: {
                      Appointment: {
                        ...filt.predicate.Appointment,
                        ClientList: {
                          ...filt.predicate.Appointment.ClientList,
                          listId: finalCampaign.listId,
                        }
                      },
                    },
                  });
                }
              }
              if (has('Client', filt.predicate)) {
                if (has('ClientList', filt.predicate.Client)) {
                  return ({
                    isTrue: true,
                    predicate: {
                      Client: {
                        ...filt.predicate.Client,
                        ClientList: {
                          ...filt.predicate.Client.ClientList,
                          listId: finalCampaign.listId,
                        }
                      },
                    },
                  });
                }
              }
              return filt;
            }) : [];
            if (has('ClientList', automation.trigger)) {
              trigger = ({
                ClientList: {
                  ...automation.trigger.ClientList,
                  listId: finalCampaign.listId,
                },
              });
            }
            const auto = {
              ...automation,
              trigger,
              actions,
              filters,
              deleteActions: [] as number[],
              isTemplate: false,
              id: 0,
              enabled: true,
            };
            return saveAutomation(auto);
          }));
        });
      }));
    } catch (e) {
      setError({
        type: 'Message',
        error: e.message,
      });
      setSaving(false);
      return;
    }
    await api.post('paths', {
      path: `campaigns.cmp_${finalCampaign.listId}.tmp_${template.id}`.split('.'),
      resource: {
        ClientList: finalCampaign.listId,
      },
    });
    setSaving(false);
    save(finalCampaign.listId);
  };

  useEffect(() => {
    cond([
      [includes('PREVIOUS'), () => setPage(page - 1)],
      [includes('NEXT'), () => setPage(page + 1)],
      [includes('SAVE'), (): any => undefined],
      [T, () => setState('CURRENT')]
    ])(state);
  }, [state]);

  useEffect(() => {
    setState('CURRENT');
  }, [page]);

  useEffect(() => {
    if (includes('SAVE', state)) {
      saveIt();
      return;
    }
  }, [pages]);

  const prevPage = () => {
    setState(`${currentPage?.type}_${currentPage?.data?.id}_PREVIOUS`);
  };

  const nextPage = () => {
    setState(`${currentPage?.type}_${currentPage?.data?.id}_NEXT`);
  };

  const handleSave = async () => {
    setState(`${currentPage?.type}_${currentPage?.data?.id}_SAVE`);
  };

  const currentPage = useMemo(() => pages[page], [page, pages]);

  const updateAutomation = (_: number, auto: Automation) => {
    const idx = automationsState.data.data.findIndex(({ id }: Automation) => auto.id === id);
    automationsState.setState({
      ...automationsState,
      data: {
        ...automationsState.data,
        data: update(idx, auto, automationsState.data.data),
      },
    });
  };

  const removeAutomation = (_: number, autoId: number) => {
    const idx = automationsState.data.data.findIndex(({ id }: Automation) => autoId === id);
    automationsState.setState({
      ...automationsState,
      data: {
        ...automationsState.data,
        data: remove(idx, 1, automationsState.data.data),
      },
    });
  };

  const updateMessage = (msg: Message) => {
    const idx = messagesState.data.data.findIndex(({ id }: Message) => msg.id === id);
    messagesState.setState({
      ...messagesState,
      data: {
        ...messagesState.data,
        data: update(idx, msg, messagesState.data.data),
      },
    });
  };

  const updateMessageAutomation = (innerIndex: number, auto: Automation) => {
    const outerIndex = messageAutomationsState.data.findIndex(({ msgId }) => currentPage.data.id === msgId);
    const current = messageAutomationsState.data[outerIndex];
    const newCurrent = {
      ...current,
      automations: update(innerIndex, auto, current.automations),
    };
    messageAutomationsState.setState({
      ...messageAutomationsState,
      data: update(outerIndex, newCurrent, messageAutomationsState.data),
    });
  };

  const forceSave = useMemo(() => includes(`${currentPage?.data?.id}`, state), [state]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Create Campaign Wizard
        {loading || saving && <CircularProgress />}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!loading && currentPage &&
          <DialogContentText>
            {currentPage.comment}
          </DialogContentText>}
        {currentPage?.type === 'Campaign' && currentPage?.data &&
          <EditCampaign
            campaign={currentPage.data}
            updateCampaign={(c) => {
              setTemplateState(c);
            }}
          />}
        {currentPage?.type === 'Automation' && currentPage?.data &&
          <AutomationsList
            automations={[{
              ...currentPage.data,
              isEdit: true,
            }]}
            noSave
            hideCancel
            saveIsUpdate
            defaultCampaign={{
              name: templateState?.displayName,
              id: templateState?.id,
            }}
            removeAutomation={removeAutomation}
            updateAutomation={updateAutomation}
            enabled={true}
            onSave={() => () => null}
            forceSave={forceSave && includes('Automation', state)}
          />}
        {currentPage?.type === 'Message' && currentPage?.data &&
          <EditMessage
            template={templateState}
            message={currentPage.data}
            forceUpdate={forceSave && includes('Message', state)}
            onUpdate={updateMessage}
            automations={messageAutomationsState.data.find(({ msgId }) => currentPage.data.id === msgId)?.automations}
            removeAutomation={() => null}
            updateAutomation={updateMessageAutomation}
          />}
      </DialogContent>
      <DialogActions>
        <Row className={classes.buttonRow}>
          <Button
            onClick={prevPage}
            color="primary"
            disabled={page === 0 || saving}
            startIcon={<ChevronLeftIcon />}>
            Previous
          </Button>
          <Spacer />
          {error && error.error}
          <Spacer />
          {pages.length - 1 === page ?
            <Button
              onClick={handleSave}
              disabled={saving}
              color="primary">
              Create
            </Button>
            :
            <Button
              onClick={nextPage}
              color="primary"
              endIcon={<ChevronRightIcon />}>
              Next
            </Button>}
        </Row>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignWizard;
