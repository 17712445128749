import React, { useState, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import * as R from 'ramda';
import { useSelector } from './reducers';
import { useStyles } from './styles/app.styles';
import Header from './components/Header/header.component';
import DrawerMenu from './components/DrawerMenu/DrawerMenu.component';
import { patch } from './routes/Login/login.actions';
const OfficeNotes = React.lazy(() => import('./components/office-notes.component'));
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Loading = () => (
  <div>
    <div className="loader"></div>
  </div>
);

const PrivateRoute = () => {
  const {
    isAdmin,
    desktopOpen,
    authStatus,
    features,
    badges,
    parentOfficeFeatures,
    group,
    banners,
    office,
    selected,
    status,
    agency,
    user,
  } = useSelector((state) => ({
    isAdmin: R.pathOr(false, ['login', 'admin'], state),
    desktopOpen: R.pathOr(true, ['login', 'desktopOpen'], state),
    authStatus: state.session.status,
    features: R.pathOr([], ['login', 'features'], state),
    office: state.login.office,
    badges: {
      // Make sure the keys here match the id in navItems
      // See setBadges function for why
      inbox: R.pathOr(0, ['messageThread', 'unread'])(state),
      reviews: R.pathOr(0, ['reviews', 'newReviews'])(state)
    },
    parentOfficeFeatures: R.pathOr([], ['login', 'parentOfficeFeatures'])(state),
    group: state.login.group,
    banners: state.login.banners,
    selected: state.login.selected,
    status: state.login.status,
    agency: state.login.agency,
    user: state.login.user,
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);

  const setDesktopOpen = (value: boolean) => {
    dispatch(patch({ desktopOpen: value }));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [notesOpen, setNotesOpen] = useState(false);

  if (R.equals(authStatus, 'NOT_CHECKED') || R.equals(authStatus, 'CHECKING')) {
    return <Loading />;
  }

  if (!R.equals(authStatus, 'AUTHENTICATED')) {
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      {(desktopOpen || mobileOpen) ? (
        <DrawerMenu
          handleDrawerToggle={handleDrawerToggle}
          desktopOpen={desktopOpen}
          mobileOpen={mobileOpen}
          setDesktopOpen={setDesktopOpen}
          features={features}
          badges={badges}
          parentOfficeFeatures={parentOfficeFeatures}
          group={group}
          banners={banners}
          isAdmin={isAdmin}
          office={office}
          agency={agency} />
      ) : null}
      <main className={'sked-content ' + classes.main} style={{ marginLeft: (!desktopOpen ? '0' : '') }}>
        {isAdmin &&
      <div
        className={classes.key}>
        <div
          id={notesOpen ? 'notes-main-open' : 'notes-main'}
          style={{
            top: '0px',
            backgroundColor: 'white',
            cursor: notesOpen ? 'unset' : 'pointer',
          }}
          onClick={notesOpen ? undefined : () => {
            setNotesOpen(true);
          }}>
          <OfficeNotes isOpen={notesOpen} close={() => setNotesOpen(false)} />
        </div>
      </div>}
        <Header
          handleDrawerToggle={handleDrawerToggle}
          desktopOpen={desktopOpen}
          setDesktopOpen={setDesktopOpen}
          classes={classes}
          selected={selected}
          status={status}
          office={office}
          agency={agency}
          user={user}
          admin={isAdmin}
        />
        <div id='monkey' className={classes.contentScrollContainer} style={{ maxWidth: (!desktopOpen ? '100vw' : '') }}>
          <div id='monkey-inner' className={classes.contentInner}>
            <Suspense fallback={<Loading />}>
              <DndProvider backend={HTML5Backend}>
                <Outlet />
              </DndProvider>
            </Suspense>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

//This will have to be async
export default PrivateRoute;
