import React from 'react';

import * as R from 'ramda';
import { TableRow } from '@mui/material';
import { Appointment, StatusObject } from '../../routes/Appointments/appointments.types';

import { tzParseFormat } from '../../services/joda';
import { pickColor } from '../../services/utilities';
import { BodyCell } from '../../components/CustomTable';

export type Professional = {
  id: number;
  firstName: string;
  lastName: string;
}

type AptItemProps = {
  apt: Appointment;
  tz: string;
  viewApt: (appt: Appointment) => void;
  professionals: Professional[];
  isLead?: boolean;
  confirm?: () => void;
}

export const statuses = ['Scheduled', 'Missed', 'Arrived', 'Rescheduled', 'Canceled', 'Deleted'];

const AptItem = ({ apt, tz, viewApt, professionals, isLead, confirm }: AptItemProps) => {
  const pro = professionals.find(R.propEq('id', apt.appointmentType.professionalId));

  const decipherStatus = (status: StatusObject): string => R.pipe(
    R.filter((stat: string) => R.has(stat)(status)),
    R.head
  )(statuses) as string;

  return (
    <TableRow key={apt.id}>
      <BodyCell>
        <a onClick={() => viewApt(apt)}>
          {tzParseFormat(apt.time, tz, 'MM/dd/yyyy hh:mm a')}
        </a>
      </BodyCell>
      <BodyCell>
        {decipherStatus(apt.status)}
      </BodyCell>
      <BodyCell>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '100px',
              backgroundColor: pickColor(apt.appointmentType.color),
              marginRight: '5px',
            }}>
          </div>
          {apt.appointmentType.internalName} {pro && 'with'} {pro && pro.firstName} {pro && pro.lastName}
        </div>
      </BodyCell>
      {isLead && (
        <BodyCell>
          {R.includes(decipherStatus(apt.status), ['Scheduled', 'Rescheduled']) && <button type='button' onClick={confirm}>Confirm</button>}
        </BodyCell>
      )}
    </TableRow>
  );
};

export default AptItem;
