import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';
import {
  TextField, Divider, Button, Checkbox, FormControlLabel,
  Paper, FormHelperText, FormControl, InputLabel, MenuItem, Select,
  CircularProgress, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PopupTemplate } from '../../../../services/Popup.js';
import * as actions from './app.actions.jsx';
import TypeSelector from '../../../../components/TypeSelector/type-selector.component';
import { PageHeader, Div } from '../../../../components/PageHeader';
import { getData as getIntegrationData, saveMisc } from '../Integration/integration.actions.jsx';
import { FeatureDisabled } from '../../../../components/feature-disabled.component';
import { useTitle } from '../../../../services/useTitle.js';
import { usePromise } from '../../../../services/promise.hook';
import Header from '../../../../components/PageHeader/PageHeader.component';

const Section = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingBottom: '10px',
  }
})(Div);

const WideSelect = withStyles({
  root: {
    width: '100px'
  }
})(Select);

const platRegMethodOptions = [
  'FileNumber',
  'Barcode'
];

const chiroRegMethodOptions = [
  'ChiroPin',
  'ChiroAccountNumber'
];

const cutoffContactSettingMethods = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Call',
    label: 'Call',
  },
  {
    value: 'Text',
    label: 'Text',
  },
  {
    value: 'CallAndText',
    label: 'Call and Text',
  }
];


const PlatiumRegistrationMethod = ({
  state,
  update
}) => {
  return (
    <FormControl style={{ minWidth: '170px' }}>
      <InputLabel id="reg-method-label">Registration Method</InputLabel>
      <Select
        value={state.registrationMethod}
        onChange={e => update('registrationMethod', e.target.value)}
        labelId="reg-method-label"
        id="reg-method">
        {platRegMethodOptions.map(value => (
          <MenuItem value={value}>{value}</MenuItem>
        ))}
      </Select>
      <FormHelperText>
        This is the number app users will need when registering for the app manually. <br />
        FileNumber: The Member ID is the client's file number in Platinum.<br />
        Barcode: The Member ID is the client's barcode number used to check in.
      </FormHelperText>
    </FormControl>
  );
};

const CTRegistrationMethod = ({
  state,
  update
}) => {
  return (
    <FormControl style={{ minWidth: '170px' }}>
      <InputLabel id="reg-method-label">Registration Method</InputLabel>
      <Select
        value={state.registrationMethod}
        onChange={e => update('registrationMethod', e.target.value)}
        labelId="reg-method-label"
        id="reg-method">
        {chiroRegMethodOptions.map(value => (
          <MenuItem value={value}>{value}</MenuItem>
        ))}
      </Select>
      <FormHelperText>
        This is the number app users will need when registering for the app manually.
      </FormHelperText>
    </FormControl>
  );
};

const DisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000'
    }
  }
})(TextField);

const GenesisRegistionMethod = () => {
  return (
    <FormControl style={{ minWidth: '170px' }}>
      <DisabledTextField
        disabled={true}
        label="Registration Method"
        value="Barcode"
      />
      <FormHelperText>
        This is the number app users will need when registering for the app manually.  It's the Genesis barcode number.
      </FormHelperText>
    </FormControl>
  );
};


const ChiroHdRegistrationMethod = () => {
  return (
    <FormControl style={{ minWidth: '170px' }}>
      <DisabledTextField
        disabled={true}
        label="Registration Method"
        value="ChiroHD ID"
      />
      <FormHelperText>
        This is the number app users will need when registering for the app manually.
      </FormHelperText>
    </FormControl>
  );
};

const RegistrationSettings = ({ shortId, ehrSystem, integration, saveMisc }) => {
  const [state, setState] = React.useState({
    registrationMethod: integration.regMethod
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const update = (prop, value = '') => setState(s => R.assoc(prop, value, s));

  React.useEffect(() => {
    update('registrationMethod', integration.regMethod);
  }, [integration.regMethod]);

  return (
    <Paper style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}>
      <PageHeader variant="h6" style={{ marginBottom: '20px' }}>
        Registration Settings
      </PageHeader>

      {integration.busy ? <CircularProgress /> : <div>
        {ehrSystem !== 'None' &&
          <Section>
            <DisabledTextField
              disabled={true}
              label="Service Provider ID"
              value={shortId}
            />
            <FormHelperText>
              This is your unique number which will connect patients to your office. App users will need this number when registering for the app manually.
            </FormHelperText>
          </Section>}

        {(ehrSystem === 'PlatinumApi' || ehrSystem === 'Platinum') && <Section>
          <PlatiumRegistrationMethod update={update} state={state} />
        </Section>}

        {(ehrSystem === 'PlatinumApi' || ehrSystem === 'Platinum') && <Button
          onClick={() => saveMisc(state, 'Platinum')}
          style={{ marginRight: '30px' }}
          variant='contained'>
          Save
        </Button>}

        {ehrSystem === 'ChiroTouch' && <Section>
          <CTRegistrationMethod update={update} state={state} />
        </Section>}

        {ehrSystem === 'ChiroTouch' && <Button
          onClick={() => saveMisc(state, 'ChiroTouch')}
          style={{ marginRight: '30px' }}
          variant='contained'>
          Save
        </Button>}

        {ehrSystem === 'Genesis' && <Section>
          <GenesisRegistionMethod />
        </Section>}

        {ehrSystem === 'ChiroHD' && <Section>
          <ChiroHdRegistrationMethod />
        </Section>}

        {ehrSystem === 'None' && <Section>
          <FormHelperText>
            Your members can register with the "Client Registration Link".  You can send it as a one time message to an individual client or set it up as a recurring message after they've arrived to their first appointment.  Use the blue side menu to create a one time message or recurring message.
          </FormHelperText>
        </Section>}

        {/* TODO: When support has a video we can embed, then add the embeded video in the dialog and uncomment this.
        <Button onClick={handleClickOpen} startIcon={<PlayArrowIcon />}> Learn More</Button>
        */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">SKED App User Registration Video</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Video...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </div>}
    </Paper>
  );
};

const LimitsSettings = ({
  rescheduleLimit,
  futureScheduleLimit,
  allowSameDayScheduling,
  cutoffContactSetting,
  missedApptRemainInAppTime,
  patch,
  limitsPatch,
  hasMissedApptRemainInAppTime,
  hasCutoffContact,
}) => {
  const onRescheduleLimitChange = e => {
    if (e.target.value === '') {
      return limitsPatch('rescheduleLimit', true);
    } else if (e.target.value >= 0) {
      return limitsPatch('rescheduleLimit', e.target.value);
    } else {
      return null;
    }
  };

  const onFutureScheduleLimitChange = e => {
    if (e.target.value === '') {
      return limitsPatch('futureScheduleLimit', true);
    } else if (e.target.value >= 0) {
      return limitsPatch('futureScheduleLimit', e.target.value);
    } else {
      return null;
    }
  };

  const onMissedApptChange = e => {
    if (e.target.value === '') {
      return patch('missedApptRemainInAppTime', true);
    } else if (e.target.value >= 0) {
      return patch('missedApptRemainInAppTime', e.target.value);
    } else {
      return null;
    }
  };

  return (
    <div>
      {hasCutoffContact &&
        <Section>
          <PageHeader variant="h6">
            Cutoff Contact Method
          </PageHeader>
          <FormHelperText>
            When a client attempts to perform an action when there are limitations in place for the assigned appointment types it will display these methods to contact the office.
          </FormHelperText>
          <div style={{ marginBottom: '10px' }}>
          </div>
          <FormControl style={{ minWidth: 256 }}>
            <InputLabel>Contact Method</InputLabel>
            <WideSelect
              value={cutoffContactSetting}
              onChange={e => limitsPatch('cutoffContactSetting', e.target.value)}
              labelId="reg-method-label"
              id="reg-method">
              {cutoffContactSettingMethods.map(({ value, label }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </WideSelect>
          </FormControl>
        </Section>}
      <Divider />
      <Section>
        <PageHeader variant="h6">
          Allow Same-Day Scheduling
        </PageHeader>
        <FormHelperText>
          Enabling this will allow your practice members to schedule same day appointments, disabling will not show available times for today’s date.
        </FormHelperText>
        <div style={{ marginBottom: '10px' }}>
        </div>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              checked={allowSameDayScheduling}
              onChange={() => limitsPatch('allowSameDayScheduling', !allowSameDayScheduling)} />
          }
        />
      </Section>
      <Divider />
      <Section>
        <PageHeader variant="h6">
          Reschedule Limit
        </PageHeader>
        <FormHelperText>
          Enabling and setting this will determine how many times a patient can reschedule an appointment.
        </FormHelperText>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              checked={rescheduleLimit}
              onChange={() => limitsPatch('rescheduleLimit', !rescheduleLimit)} />
          }
        />
        <br />
        {rescheduleLimit &&
          <TextField
            label="Number of reschedules"
            type="number"
            value={rescheduleLimit}
            onChange={onRescheduleLimitChange} />
        }
      </Section>
      <Divider />
      <Section style={{ marginBottom: '20px' }}>
        <PageHeader variant="h6">
          Future Scheduling Limit
        </PageHeader>
        <FormHelperText>
          Enabling and setting this will determine how far in the future a patient can schedule an appointment.
        </FormHelperText>
        <FormControlLabel
          label="Enabled"
          control={<Checkbox
            checked={futureScheduleLimit}
            onChange={() => limitsPatch('futureScheduleLimit', !futureScheduleLimit)} />}
        />
        <br />
        {futureScheduleLimit &&
          <TextField
            label="Days"
            type="number"
            value={futureScheduleLimit}
            onChange={onFutureScheduleLimitChange}
          />}
      </Section>
      <Divider />
      {hasMissedApptRemainInAppTime &&
        <Section>
          <PageHeader variant="h6">
            Show Missed Appointments Limit
          </PageHeader>
          <FormHelperText>
            Enabling and setting this will determine how long after an appointment is missed it remains in the app. While the appointment is shown, it still can be rescheduled.
          </FormHelperText>
          <FormControlLabel
            label="Enabled"
            control={
              <Checkbox
                checked={missedApptRemainInAppTime}
                onChange={() => patch(
                  'missedApptRemainInAppTime',
                  !missedApptRemainInAppTime
                )} />
            }
          />
          <br />
          {missedApptRemainInAppTime &&
            <TextField
              label="Minutes After Appointment"
              type="number"
              value={missedApptRemainInAppTime}
              onChange={onMissedApptChange} />
          }
        </Section>}
    </div>
  );
};

export const AppSettings = ({
  integration, busy, save, setHideNameForPro, limits,
  limitsPatch, missedApptRemainInAppTime, saveLimits, office,
  getOfficeSettings, getIntegrationData, saveMisc, hasFeature, appPatch,
  hasMissedApptRemainInAppTime, hasCutoffContact,
}) => {
  const navigate = useNavigate();
  useTitle('App Settings');

  const [selectedTypes, setSelectedTypes] = React.useState(null);
  const typesState = usePromise(actions.getTypesApi, {
    types: [], professionals: [], selectedTypes: null,
  });

  React.useEffect(() => {
    typesState.invoke(navigate).then((ts) => {
      if (ts) {
        setSelectedTypes(ts.selectedTypes.filter(R.identity));
      }
    });
    getOfficeSettings();
    getIntegrationData({ ehrSystem: office.ehrSystem, navigate });
  }, [office]);

  if (!hasFeature) {
    return (
      <FeatureDisabled title="SKED App">
        <p>Put your office scheduler into the pocket of your patients with a very user friendly simple app. </p>
      </FeatureDisabled>
    );
  }

  return (
    <>
      <Header
        title='Mobile App Settings'
        pageId={R.cond([
          [R.equals('None'), R.always('app-tab-none')],
          [R.equals('Genesis'), R.always('app-tab-genesis')],
          [R.equals('ChiroTouch'), R.always('app-tab-ct')],
          [R.equals('ChiroHD'), R.always('app-tab-chd')],
          [R.T, R.always('app-tab-plat')],
        ])(office.ehrSystem)}
        rightIcons={[
          busy && <CircularProgress size={18} />
        ]}
      />
      <div
        style={{
          padding: '20px',
          paddingTop: 0,
          paddingBottom: 25,
          overflowY: 'auto',
        }}>
        <PopupTemplate />
        {busy && <CircularProgress />}
        {!busy && (
          <div>
            <RegistrationSettings
              integration={integration}
              saveMisc={saveMisc}
              shortId={office.shortId}
              ehrSystem={office.ehrSystem} />

            <Paper style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}>
              <PageHeader variant="h6">
                Mobile Appointment Type Settings
              </PageHeader>
              <FormHelperText style={{ marginBottom: '20px' }}>
                The appointment types that app users can create for themselves in the app.
              </FormHelperText>
              <TypeSelector
                types={typesState.data.types}
                professionals={typesState.data.professionals}
                selected={R.isNil(selectedTypes) ? null : selectedTypes}
                onChange={setSelectedTypes}
                ProComponent={(p) => (
                  <div>
                    <label>
                      Hide Name:
                      <input
                        type='checkbox'
                        defaultChecked={p.nameHidden}
                        onChange={() => setHideNameForPro(p, !p.nameHidden)}
                      />
                    </label>
                  </div>
                )}

              />
              <Button
                style={{ marginTop: 10 }}
                variant='contained'
                onClick={() => save(selectedTypes)}>
                Save
              </Button>
            </Paper>
            <Paper style={{ padding: '20px' }}>
              <PageHeader variant="h6">
                App Limitations
              </PageHeader>
              {limits &&
                <LimitsSettings
                  {...limits}
                  missedApptRemainInAppTime={missedApptRemainInAppTime}
                  patch={appPatch}
                  limitsPatch={limitsPatch}
                  hasMissedApptRemainInAppTime={hasMissedApptRemainInAppTime}
                  hasCutoffContact={hasCutoffContact}
                />}
              <Button
                variant='contained'
                onClick={() => saveLimits(limits, missedApptRemainInAppTime)}>
                Save
              </Button>
            </Paper>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...R.propOr({}, 'webmodule')(state),
  ...R.propOr({}, 'settingsApp')(state),
  office: R.pathOr({}, ['login', 'office'])(state),
  integration: R.pathOr({}, ['integration'])(state),
  hasFeature: R.includes('SkedApp', R.pathOr([], ['login', 'features'], state)),
  hasMissedApptRemainInAppTime: R.includes('MissedApptRemainInAppTime', R.pathOr([], ['login', 'features'], state)),
  hasCutoffContact: R.includes('CutoffContact', R.pathOr([], ['login', 'features'], state)),
});

const mapDispatchToProps = dispatch => R.merge(
  bindActionCreators(actions, dispatch),
  {
    getIntegrationData: ({ navigate, ehrSystem }) => getIntegrationData({ dispatch, navigate, ehrSystem }),
    saveMisc: (data, system) => dispatch(saveMisc(data, system))
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppSettings);
