import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ViewMessage from '../../components/AutomationBasedMessage/view-message.component';
import { includes } from 'ramda';


const ViewMessagePage = () => {
  const params = useParams();
  const location = useLocation();
  const msgId = params?.id ? Number(params.id) : 0;
  const type = includes('time-based', location.pathname) ? 'time-based' : 'smart-reply';

  return (
    <ViewMessage
      messageId={msgId}
      messageType={'AutomationBaseMessage'}
      type={type}
    />
  );
};

export default ViewMessagePage;
