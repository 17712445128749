import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  pathOr, isEmpty, append, without, keys, cond,
  always, has, T, prop, pathEq, concat,
  pipe, sortBy, flatten, map, compose, head, split,
  not, filter, propOr,
} from 'ramda';
import api, { skedApi } from '../../services/api.js';
import axios from 'axios';
import {
  Checkbox, Button, Paper, TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody,
} from '@mui/material';
import {
  ArrowForward,
  ChatBubble as ChatBubbleIcon,
  Smartphone as SmartphoneIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import { usePromise } from '../../services/promise.hook';
import { useCheckIsAdmin } from '../../services/checkIsAdmin.hook';
import { useSelector } from '../../reducers';
import { Office } from '../Login/login.reducer';
import { Message, MessageType, Receivers } from '../Templates/templates.reducer';
import {
  getPath, guessMessageType, automationParser, automationConverter,
} from '../../components/AutomationBasedMessage/automation-message.actions';
import { successSnackbar, errorSnackbar } from '../../components/Snackbar/snackbar.actions';

type SelectedMessages = {
  office?: Office;
  messages: Message[];
}

let selectedMessages: SelectedMessages = {
  messages: [],
};

type MessageData = {
  data: Message[];
}
type MessageTemplateData = {
  data: Message[];
}

const headers = (oid: number) => ({
  Authorization: skedApi.defaults.headers.common.Authorization,
  ['Content-Type']: skedApi.defaults.headers.common['Content-Type'],
  'X-As-Office': oid
});

const getOffice = (ids: number[]): Promise<Office[]> => Promise.all(ids.map((id) => api.get(`office/${id}`)));
const getOfficeWithMessages = (id: number) => {
  skedApi.defaults.headers.common['X-As-Office'] = id;
  return Promise.all([
    api.get('office/me'),
    api.post('message/query', {
      page: 1,
      perPage: 3000,
      query: {
        isDeleted: false,
        sortBy: [{
          field: 'Created',
          direction: 'Desc'
        }],
        path: { NotExists: { hasSubpath: ['spark'] } },
      }
    }),
    api.post('message/query', {
      page: 1,
      perPage: 3000,
      query: {
        isDeleted: false,
        isTemplate: true,
        sortBy: [{
          field: 'Created',
          direction: 'Desc'
        }],
        path: { NotExists: { hasSubpath: ['spark'] } },
      }
    })
  ]).then(([office, messages, templates]: [Office, MessageData, MessageTemplateData]) => {
    delete skedApi.defaults.headers.common['X-As-Office'];
    const filtered = messages.data.filter((msg: Message) => {
      if (msg.messageType.OneTime) {
        return !msg.messageType.OneTime.wasSent;
      }
      return true;
    });
    selectedMessages.messages = concat(filtered, templates.data);
    const allMessages = pipe(
      map(
        sortBy<Message>(
          compose(
            (props: (keyof MessageType)[]) => head(props),
            keys,
            prop('messageType')
          )
        )
      ),
      flatten,
    )([filtered, templates.data]);

    return { office, messages: allMessages };
  });
};

type MessageItemProps = {
  message: Message;
  check: (isChecked: boolean, message: Message) => void;
  all: boolean;
}

const MessageItem = ({
  message,
  check,
  all,
}: MessageItemProps) => {
  const [selected, setSelect] = useState(true);
  useEffect(() => {
    setSelect(all);
  }, [all]);
  return (
    <TableRow key={message.id}>
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={(e) => {
            const c = e.target.checked;
            setSelect(c);
            check(c, message);
          }}
        />
      </TableCell>
      <TableCell>
        {message.name}
      </TableCell>
      <TableCell>
        {keys(message.messageType)[0]}
      </TableCell>
      <TableCell>
        <Checkbox
          name="template"
          checked={message.isTemplate}
          disabled={true}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name="enabled"
          checked={message.isEnabled}
          disabled={true}
        />
      </TableCell>
      <TableCell>
        <span style={{ display: 'flex', width: '100%', height: '100%' }}>
          {message.sms &&
            <span style={{ marginRight: '5px', height: '100%', display: 'flex', alignItems: 'center' }}>
              <ChatBubbleIcon style={{ fontSize: '20px' }} />
            </span>}
          {message.email &&
            <span style={{ marginRight: '5px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
              <EmailIcon style={{ fontSize: '20px' }} />
            </span>}
          {message.push &&
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <SmartphoneIcon style={{ fontSize: '20px' }} />
            </span>}
        </span>
      </TableCell>
    </TableRow>
  );
};

let oldTimer: NodeJS.Timer;
let newTimer: NodeJS.Timer;
const TransferMessages = () => {
  const dispatch = useDispatch();
  const [oldOfficeId, setOldOfficeId] = useState('');
  const [newOfficeId, setNewOfficeId] = useState('');
  const [all, setAll] = useState(true);
  const [busy, setBusy] = useState(false);

  const newOfficeState = usePromise(getOffice, []);
  const oldOfficeState = usePromise(getOfficeWithMessages, {} as SelectedMessages);

  const admin = useSelector(state => state.login.admin);
  const sessionStatus = useSelector(state => state.session.status);

  const getOfficeData = (isOld: boolean) => {
    if (isOld) {
      oldOfficeState.invoke(Number(oldOfficeId));
    } else {
      const ids = pipe(
        split(' '),
        filter(compose(not, isEmpty)),
        map((s) => Number(s))
      )(newOfficeId);
      newOfficeState.invoke(ids);
    }
  };

  useCheckIsAdmin(admin, sessionStatus);

  useEffect(() => {
    if (oldTimer) {
      clearTimeout(oldTimer);
    }
    if (oldOfficeId !== '') {
      oldTimer = setTimeout(() => getOfficeData(true), 200);
    }
  }, [oldOfficeId]);

  useEffect(() => {
    if (newTimer) {
      clearTimeout(newTimer);
    }
    if (newOfficeId !== '') {
      newTimer = setTimeout(() => getOfficeData(false), 200);
    }
  }, [newOfficeId]);

  useEffect(() => {
    if (all) {
      selectedMessages = oldOfficeState.data;
    } else {
      selectedMessages = { messages: [] };
    }
  }, [all]);

  const checkMessage = (isChecked: boolean, message: Message) => {
    if (isChecked) {
      selectedMessages.messages = append(message, selectedMessages.messages);
    } else {
      selectedMessages.messages = without([message], selectedMessages.messages);
    }
    console.log(selectedMessages);
  };

  const transfer = () => {
    const ids = pipe(
      split(' '),
      filter(compose(not, isEmpty)),
      map((s) => Number(s))
    )(newOfficeId);

    setBusy(true);
    Promise.all(ids.map((oid) => {
      const reqs = selectedMessages.messages.map(({
        email,
        sms,
        push,
        name,
        messageType,
        isDeleted,
        isTemplate,
        id,
      }) => {
        const newMessageType = cond([
          [has('OneTime'), always({
            OneTime: {
              ...messageType.OneTime,
              wasSent: undefined,
              receivers: cond([
                [has('DateRange'), always<Receivers>({
                  DateRange: {
                    appointmentTypes: { Some: [] },
                    start: pathOr('', ['OneTime', 'receivers', 'DateRange', 'start'], messageType),
                    end: pathOr('', ['OneTime', 'receivers', 'DateRange', 'end'], messageType),
                  }
                })],
                [has('Clients'), always<Receivers>({
                  Clients: [],
                })],
                [has('ClientTag'), always<Receivers>({
                  ClientTag: {
                    [keys(pathOr('Or', ['OneTime', 'receivers', 'ClientTag'], messageType))[0]]: []
                  },
                })],
                [T, always({
                  Everyone: []
                })]
              ])(pathOr('Clients', ['OneTime', 'receivers'], messageType))
            }
          })],
          [has('Automation'), always<MessageType>({
            Automation: [],
          })],
          [has('ApptChange'), always<MessageType>({
            ApptChange: {
              ...messageType.ApptChange,
              appointmentTypeIds: [],
            }
          })],
          [has('Recurring'), always<MessageType>({
            Recurring: {
              ...messageType.Recurring,
              appointmentTypeIds: { Some: [] },
            }
          })],
          [has('Recall'), always<MessageType>({
            Recall: {
              ...messageType.Recall,
              appointmentTypes: [],
            }
          })],
          [T, always<MessageType>({
            Birthday: {
              ...messageType.Birthday,
            }
          })]
        ])(messageType);

        const data = {
          email,
          sms,
          push,
          name,
          messageType: newMessageType,
          isDeleted,
          isTemplate,
          isEnabled: false,
        };
        skedApi.defaults.headers.common['X-As-Office'] = oldOfficeId;
        return axios.post(
          `${process.env.AUTH_URL}/message`,
          data,
          {
            headers: headers(oid),
          }
        ).then((mdata) => {
          const nMsg = mdata.data as Message;
          if (has('Automation', nMsg.messageType)) {
            return api.post('automations/query', {
              page: 1,
              perPage: 1,
              query: {
                action: {
                  msgId: id,
                },
              },
            }).then(({ data }) => {
              const auto = data[0];
              const amt = guessMessageType(auto);
              const path = getPath(amt);
              const pauto = automationParser(auto);
              const cauto = automationConverter(pauto, nMsg);
              const fullPath = `${path}.msg_${nMsg.id}`; // .split('.');
              skedApi.defaults.headers.common['X-As-Office'] = oid;
              return Promise.all([
                api.post('automations', cauto),
                api.post('paths', {
                  path: fullPath,
                  resource: {
                    Message: nMsg.id,
                  },
                })
              ]);
            });
          }
        });
      });

      return Promise.all(reqs);
    })).then(() => {
      dispatch(successSnackbar('Transferred Messages'));
      setBusy(false);
    }).catch((e) => {
      console.log(e);
      dispatch(errorSnackbar('Failed to transfer messages. Check network monitor for more information.'));
      setBusy(false);
    });
  };

  return (
    <div style={{
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        display: 'flex',
        width: '80%',
        justifyContent: 'space-evenly',
      }}>
        <Paper
          style={{
            padding: '10px',
            height: 'min-content',
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }}>
            <div>
              Old Office:
              &nbsp;
              <input
                value={oldOfficeId}
                placeholder='ID'
                type='text'
                style={{ width: '30px' }}
                onChange={(e) => {
                  setOldOfficeId(e.target.value);
                }}
                onKeyPress={(e) => e.key === 'Enter' ? getOfficeData(true) : null}
              />
              <br />
              {cond([
                [prop('loading'), always('Loading...')],
                [prop('errorMessage'), cond([
                  [pathEq(['error', 'response', 'status'], 401), always('Error 401: Go back to \'Select Office\' and try again.')],
                  [pathEq(['error', 'response', 'status'], 403), always('Error 403: Why are you here?')],
                  [pathEq(['error', 'response', 'status'], 404), always('Office not found.')],
                  [T, (o) => always(`Error ${pathOr('unknown', ['error', 'response', 'status'], o)}, you better do something about this...`)(o)]
                ])],
                [T, pathOr(null, ['data', 'office', 'name'])]
              ])(oldOfficeState)}
            </div>
            &nbsp;
            <ArrowForward />
            &nbsp;
            <div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                New Office(s):
                &nbsp;
                <textarea
                  rows={1}
                  value={newOfficeId}
                  placeholder='ID'
                  // type='text'
                  style={{
                    height: '18px',
                  }}
                  onChange={(e) => {
                    setNewOfficeId(e.target.value);
                  }}
                  onKeyPress={(e) => e.key === 'Enter' ? getOfficeData(false) : null}
                />
              </div>
              <div>
                {cond([
                  [prop('loading'), always('Loading...')],
                  [prop('errorMessage'), cond([
                    [pathEq(['error', 'response', 'status'], 401), always('Error 401: Go back to \'Select Office\' and try again.')],
                    [pathEq(['error', 'response', 'status'], 403), always('Error 403: Why are you here?')],
                    [pathEq(['error', 'response', 'status'], 404), always('Office not found.')],
                    [T, (o) => always(`Error ${pathOr('unknown', ['error', 'response', 'status'], o)}, you better do something about this...`)(o)]
                  ])],
                ])(newOfficeState)}
                {pipe(propOr([], 'data'), map(({ name }) => (
                  <div>
                    {name}
                  </div>
                )))(newOfficeState)}

              </div>
            </div>
          </div>
          {!isEmpty(pathOr([], ['data', 'messages'], oldOfficeState)) &&
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >
                      <Checkbox
                        checked={all}
                        onChange={(e) => {
                          setAll(e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Type
                    </TableCell>
                    <TableCell>
                      Template
                    </TableCell>
                    <TableCell>
                      Enabled
                    </TableCell>
                    <TableCell>
                      Send Via
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pathOr([], ['data', 'messages'], oldOfficeState).map((msg: Message) => {
                    return (
                      <MessageItem
                        key={msg.id}
                        message={msg}
                        check={checkMessage}
                        all={all}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>}
          <br />
          {!isEmpty(pathOr([], ['data', 'messages'], oldOfficeState)) &&
            <div style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              padding: 10,
              boxShadow: '0px 0px 18px -2px',
            }}>
              <i>NOTE: Open the network monitor to see how it's going. It may take some time.</i>
              <br />
              <Button
                variant="contained"
                color="error"
                disabled={busy}
                onClick={transfer}
              >
                Transfer
              </Button>
            </div>
          }
        </Paper>
      </div>
    </div>
  );
};

export default TransferMessages;
