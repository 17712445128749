import React, { useState, useEffect } from 'react';
import { NavLink, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { pipe, includes, isEmpty } from 'ramda';
import {
  Paper,
  TextField as TextFieldSmall,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import LoopIcon from '@mui/icons-material/Loop';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { PopupTemplate, popup } from '../../services/Popup.js';
import queryString from 'query-string';
import * as loginActions from './login.actions';
import { useSelector } from '../../reducers';
import Logo from '../../images/logo-blue.png';
import LocalSession from '../../services/local-session';
import {
  requiredValidation,
  updateValue,
  getValue,
  submitForm,
  initForm,
  getFirstErrorOrDefault,
  validateField,
  getFormObject
} from '../../services/form-validation.js';
import { getNewToken } from '../../services/api';
import { useStyles } from './login.styles';
import {
  SESSION_CHECKING, SESSION_CREATE_ERROR, SESSION_CREATE_SUCCESS
} from './login.actionTypes';

const TextField = withStyles({
  root: {
    width: '100%',
  },
})(TextFieldSmall);

const formObject = {
  email: {
    default: '',
    validation: [
      requiredValidation('An email is required to login')
    ]
  },
  password: {
    default: '',
    validation: [
      requiredValidation('A password is required to login')
    ]
  },
  rememberMe: {
    default: false,
  }
};

type LoginRedirectProps = {
  admin: boolean;
  professionals: string[];
  features: string[];
  redirect: string;
  gotoGettingStarted?: boolean;
}

const LoginRedirect = ({
  admin,
  professionals,
  features,
  redirect,
  gotoGettingStarted
}: LoginRedirectProps) => {
  if (admin) {
    return <Navigate to='/settings/select-office' />;
  } else if (redirect !== '') {
    return <Navigate to={redirect} />;
  } else if (gotoGettingStarted) {
    return <Navigate to='/getting-started' />;
  } else if (professionals.length === 0) {
    return <Navigate to='/settings/client-installer' />;
  } else if (!includes('SkedApp', features) &&
    includes('Calendar', features)) {
    return <Navigate to='/calendar' />;
  } else if (!includes('SkedApp', features) &&
    includes('NewCalendar', features)) {
    return <Navigate to='/calendar-v2' />;
  } else if (!includes('SkedApp', features) &&
    !includes('NewCalendar', features) &&
    !includes('Calendar', features)) {
    return <Navigate to='/inbox' />;
  } else if (!isEmpty(features)) {
    return <Navigate to='/dashboard' />;
  } else {
    return null;
  }
};

const updateCb = (e: React.ChangeEvent<HTMLInputElement>) => e.target.value;

const Login = () => {
  const {
    features,
    admin,
    agency,
    professionals,
    session,
    loading,
    gotoGettingStarted,
  } = useSelector((state) => ({
    session: state.session,
    admin: state.login.admin,
    agency: state.login.agency,
    professionals: state.professionals.professionals,
    loading: state.session.status === 'CHECKING',
    features: R.pathOr([], ['login', 'features'])(state),
    gotoGettingStarted: R.ifElse(
      R.pipe(R.pathOr([], ['login', 'features']), R.includes('NewOfficeTutorial')),
      R.pipe(
        R.pathOr([], ['tutorial', 'filterData']),
        R.map(R.prop('steps')),
        R.flatten,
        R.map(R.prop('isComplete')),
        R.any(R.isNil)
      ),
      R.always(false)
    )(state),
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const actions = bindActionCreators(loginActions, dispatch);
  const classes = useStyles();

  const [form, setForm] = useState(initForm(formObject));
  const [jwt, setJwt] = useState(null);
  const {
    pathname,
    search,
  } = React.useMemo(() => {
    return R.pathOr({ pathname: '', search: '' }, ['state', 'from'], location);
  }, [location]);
  const [isZendesk, redirectUri] = React.useMemo(() => {
    let queries = queryString.parse(location.search);
    const yes = location.pathname === '/sso/zendesk';
    if (yes && isEmpty(queries)) {
      const splitter = window.location.href.split('/?');
      const afterDomain = splitter[1];
      const queryParams = afterDomain?.split('#/');
      if (queryParams) {
        queries = queryString.parse(queryParams[0]);
      }
    }
    return [
      yes,
      queries.return_to as string
    ];
  }, [location]);
  const [rediect] = useState(pathname + search);
  type UpdateCb = (e: React.ChangeEvent<HTMLInputElement>) => string | boolean;

  const update = (prop = '', cb: UpdateCb = updateCb) => (e: React.ChangeEvent<HTMLInputElement>) => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const inputRef = React.useRef<HTMLInputElement>();
  const formRef = React.useRef<HTMLFormElement>();

  const handleOnSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);
    if (isZendesk) {
      dispatch({ type: SESSION_CHECKING });
      const jwt = await loginActions.loginAndRedirect(data, redirectUri, popup);
      setJwt(jwt);
      dispatch({ type: SESSION_CREATE_SUCCESS });
    } else {
      actions.createSession(data, navigate, popup);
    }
  };

  useEffect(() => {
    console.log('Redirecting to: ', redirectUri);
    if (inputRef.current && formRef.current && jwt) {
      inputRef.current.value = jwt;
      formRef.current?.submit();
    }
  }, [jwt]);

  useEffect(() => {
    if (isZendesk) {
      const oldSession = LocalSession.getSession();
      dispatch({ type: SESSION_CHECKING });
      getNewToken(oldSession?.refresh_token).then((session: loginActions.Session) => {
        loginActions.generateJwt(
          'zendesk', session.access_token
        ).then((j) => {
          setJwt(j);
          dispatch({ type: SESSION_CREATE_SUCCESS });
        });
      }).catch(() => {
        dispatch({ type: SESSION_CREATE_ERROR });
      });
    }
  }, [isZendesk]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.office) {
      actions.createSessionFromRefresh(query.office, navigate);
    }
    const squery = queryString.parse(location.state?.from?.search);
    if (squery.jwt) {
      actions.createSession({
        genesis_token: squery.jwt as string,
        email: '',
        password: ''
      }, navigate, popup);
    }
  }, []);

  return R.pathEq(['status'], 'AUTHENTICATED', session) ?
    (<LoginRedirect
      admin={admin || agency}
      professionals={professionals}
      features={features}
      redirect={rediect}
      gotoGettingStarted={gotoGettingStarted}
    />) :
    (
      <div className={classes.root}>
        <PopupTemplate />
        <Paper className={classes.paper}>
          <form onSubmit={handleOnSubmit}>
            <div className={classes.logoContainer}>
              <img height="30" src={Logo} />
            </div>
            <div className={classes.formControl}>
              <TextField
                autoFocus
                fullWidth
                id="login-email"
                label="Email"
                type="email"
                variant='standard'
                helperText={getFirstErrorOrDefault('email', form)}
                error={!form.email.isValid}
                value={getValue('email', form)}
                onChange={update('email')}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="login-password"
                label="Password"
                type="password"
                variant='standard'
                helperText={getFirstErrorOrDefault('password', form)}
                error={!form.password.isValid}
                value={getValue('password', form)}
                onChange={update('password')}
              />
            </div>
            <div className={classes.formControl}>
              <FormControlLabel
                control={<Checkbox
                  checked={!!getValue('rememberMe', form)}
                  onChange={update('rememberMe', e => e.target.checked)}
                  name="rememberMe" />}
                label="Remember me"
              />
            </div>
            <Button
              variant="contained"
              type="submit"
              classes={{ text: classes.buttonLabel }} // TODO - BEFORE WAS label
              disabled={loading}
              fullWidth
              startIcon={loading ? <LoopIcon /> : null}
              color="primary">
              {loading ? 'Loading...' : 'Sign in'}
            </Button>
            <Button
              type="button"
              variant="text"
              component={NavLink}
              fullWidth
              to="/forgot-password"
              classes={{ text: classes.buttonLabel }} // TODO - BEFORE WAS label
            >
              Forgot your password?
            </Button>
          </form>
        </Paper>
        <form ref={formRef} action={`https://skedlife.zendesk.com/access/jwt?return_to=${redirectUri}`} method="post">
          <input ref={inputRef} type="hidden" name="jwt"></input>
        </form>
      </div>
    );
};

export default Login;
