import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  pipe, includes, pathOr, assoc, without, prepend, evolve,
} from 'ramda';
import {
  Button, Paper, TextField, FormHelperText,
  FormControlLabel, Checkbox, Divider,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import LoopIcon from '@mui/icons-material/Loop';
import { PageHeader, Div } from '../../../../components/PageHeader';
import {
  getFirstErrorOrDefault, getFormObject, getValue, initForm,
  requiredValidation, submitForm, updateValue, validateField
} from '../../../../services/form-validation.js';
import api from '../../../../services/api.js';
import { usePromise } from '../../../../services/promise.hook';
import { claPatch, saveCLA, getCLASettings } from './cla.actions.jsx';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { successSnackbar, errorSnackbar } from '../../../../components/Snackbar/snackbar.actions';
import { useSelector } from '../../../../reducers';

const Section = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingBottom: '10px',
  }
})(Div);

const savePassword = ({ email, password }) => {
  return api.put('cla/auth', { email, password });
};

const updateCb = e => e.target.value;

const formObject = {
  email: {
    default: '',
    validation: [
      requiredValidation('An email is required to integrate CLA')
    ]
  },
  password: {
    default: '',
    validation: [
      requiredValidation('A password is required to integrate CLA')
    ]
  },
  rememberMe: {
    default: false,
  }
};

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
}));

const CLASettings = ({
  patch,
  cla
}) => {
  const onCheck = (prop) => (e) => {
    if (e.target.checked) {
      patch('cla', evolve({ allowedScans: without([prop]) }, cla));
    } else {
      patch('cla', evolve({ allowedScans: prepend(prop) }, cla));
    }
  };
  const {
    allowedScans = [],
    newPatientCreate,
  } = cla;
  return (
    <div>
      <Section>
        <PageHeader variant="h6">
          Allow New Patient Creation
        </PageHeader>
        <FormHelperText>
          Enabling this will automatically add new patients into CLA
        </FormHelperText>
        <div style={{ marginBottom: '10px' }}>
        </div>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              checked={newPatientCreate}
              onChange={(e) =>
                patch('cla', assoc('newPatientCreate', e.target.checked, cla))}
            />}
        />
      </Section>
      <Divider />
      <Section>
        <PageHeader variant="h6">
          Allow CORESCORE
        </PageHeader>
        <FormHelperText>
          Enabling this will display the CORESCORE in the app.
        </FormHelperText>
        <div style={{ marginBottom: '10px' }}>
        </div>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              checked={!includes('CoreScore', allowedScans)}
              onChange={onCheck('CoreScore')} />}
        />
      </Section>
      <Divider />
      <Section>
        <PageHeader variant="h6">
          Allow Exam Report
        </PageHeader>
        <FormHelperText>
          Enabling this will allow clients to view their exam report in the app.
        </FormHelperText>
        <div style={{ marginBottom: '10px' }}>
        </div>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              checked={!includes('ExamReport', allowedScans)}
              onChange={onCheck('ExamReport')} />}
        />
      </Section>
      <Divider />
      <Section>
        <PageHeader variant="h6">
          Allow Scans
        </PageHeader>
        <FormHelperText>
          Enabling these will allow the respective scan to be viewed in the app.
        </FormHelperText>
        <div style={{ marginBottom: '10px' }}>
        </div>
        <FormControlLabel
          label="EMG"
          control={
            <Checkbox
              checked={!includes('EMG', allowedScans)}
              onChange={onCheck('EMG')} />}
        />
        <FormControlLabel
          label="HRV"
          control={
            <Checkbox
              checked={!includes('HRV', allowedScans)}
              onChange={onCheck('HRV')} />}
        />
        <FormControlLabel
          label="Thermal"
          control={
            <Checkbox
              checked={!includes('Thermal', allowedScans)}
              onChange={onCheck('Thermal')} />}
        />
        <FormControlLabel
          label="Algo"
          control={
            <Checkbox
              checked={!includes('Algo', allowedScans)}
              onChange={onCheck('Algo')} />}
        />
        <FormControlLabel
          label="ROM"
          control={
            <Checkbox
              checked={!includes('ROM', allowedScans)}
              onChange={onCheck('ROM')} />}
        />
      </Section>
    </div>
  );
};

const CLASettingsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    busy, hasFeature, cla,
  } = useSelector((state) => ({
    hasFeature: includes('SkedApp', pathOr([], ['login', 'features'], state)),
    cla: pathOr({}, ['settingsApp', 'cla'])(state),
    busy: pathOr({}, ['settingsApp', 'busy'])(state),
  }));

  useEffect(() => {
    dispatch(getCLASettings());
  }, []);

  const savedState = usePromise(savePassword, {});

  const [form, setForm] = useState(initForm(formObject));

  const update = (prop = '', cb = updateCb) => e => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const handleOnSubmit = e => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);

    savedState.invoke(data).then(() => {
      dispatch(successSnackbar('Saved CLA email and password. The password has been cleared from the input to keep your account secure.'));
      setForm(updateValue('password', ''));
    }).catch(() => {
      dispatch(errorSnackbar('Failed to save email and password'));
    });

  };
  return (
    <>
      <Header title='CLA Integration' pageId='cla-integration' />
      <div
        style={{
          padding: '20px',
          overflowY: 'auto',
        }}>
        <Paper style={{ padding: '20px' }}>
          <form onSubmit={handleOnSubmit}>
            <div className={classes.formControl}>
              <FormHelperText>
                Enter the email and password of your CLA instance so SKED can integrate with CLA.  This will let your patients see their CLA scans in the SKED app.
              </FormHelperText>
            </div>
            <div className={classes.formControl}>
              <TextField
                autoFocus
                fullWidth
                id="login-email"
                label="Email"
                type="email"
                helperText={getFirstErrorOrDefault('email', form)}
                error={!form.email.isValid}
                value={getValue('email', form)}
                onChange={update('email')}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="login-password"
                label="Password"
                type="password"
                helperText={getFirstErrorOrDefault('password', form)}
                error={!form.password.isValid}
                value={getValue('password', form)}
                onChange={update('password')}
              />
            </div>
            <Button
              variant="contained"
              type="submit"
              disabled={savedState.loading}
              startIcon={savedState.loading ? <LoopIcon /> : null}
              color="primary">
              {savedState.loading ? 'Loading...' : 'Save'}
            </Button>
            {savedState.errorMessage && <div>Error: {savedState.errorMessage}.  The email and password may be incorrect or SKED may not be able to reach CLA's servers.</div>}
          </form>
        </Paper>
        {hasFeature &&
          <Paper style={{
            padding: '20px',
            marginTop: '20px'
          }}>
            <PageHeader variant="h6">
              CLA Reports
            </PageHeader>
            <CLASettings cla={cla} patch={(p, v) => dispatch(claPatch(p, v))} />
            {busy ?
              <div className='loader' />
              :
              <Button
                variant='contained'
                onClick={() => dispatch(saveCLA(cla))}>
                Save
              </Button>}
          </Paper>}
      </div>
    </>
  );
};

export default CLASettingsPage;
