import * as React from 'react';
import {
  Button,
  TextField
} from '@mui/material';
import { useAnswerContext, useIntakeContextAnswer } from '../answers.context';
import { ComplexWidgetSignature } from '../intake-types';
import { DrawData, SignatureAnswer } from '../answer-types';
import { type, has } from 'ramda';
import SignaturePad from 'signature_pad';

interface SignatureProps {
  id?: number;
  data: ComplexWidgetSignature;
  required: boolean;
  noCtx?: boolean;
  isPrivate?: boolean;
}

const canvasContainer = {
  position: 'relative',
  flex: 1,
  marginBottom: '15px',
  marginTop: 8,
  borderRadius: 4,
} as React.CSSProperties;

const canvasStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.02) inset',
  border: '1px solid #BFBFBF',
} as React.CSSProperties;

const bodyStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '200px',
} as React.CSSProperties;

const defaultAnswer: SignatureAnswer = {
  id: 0,
  type: 'Signature',
  value: null,
};

export const SignatureComponent = ({ id, required, noCtx, isPrivate }: SignatureProps) => {
  let disabled = !noCtx;
  if (isPrivate) {
    disabled = false;
  }
  const ctx = useAnswerContext();
  const [showType, setShowType] = React.useState(false);
  const [pic, setPic] = noCtx ? [{ url: 'https://sked-v2.s3.amazonaws.com/documents/Dr._Sked+Guy_cocosign.png' }] :
    useIntakeContextAnswer<SignatureAnswer>(id, defaultAnswer);
  const [newData, setNewData] = React.useState<DrawData>(pic as DrawData);
  const drawData = React.useRef([]);
  const sigRef = React.useRef(null);
  const sigPad = React.useRef(null);

  function resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = sigRef.current;
    sigRef.current.width = canvas.offsetWidth * ratio;
    sigRef.current.height = canvas.offsetHeight * ratio;
    sigRef.current.getContext('2d').scale(ratio, ratio);
    sigPad.current.fromData(sigPad.current.toData());
    //sigPad.current.clear();
  }

  console.log(pic);

  const isUrl = Boolean(typeof pic !== 'string' && has('url', pic));

  React.useEffect(() => {
    if (typeof pic === 'string' && !isUrl) {
      setShowType(true);
    } else {
      setShowType(false);
      if (sigPad.current && sigRef.current) {
        resizeCanvas();
      }
    }
  }, [pic]);

  React.useEffect(() => {
    if (showType === false) {
      if (sigRef.current && !isUrl) {
        sigPad.current = new SignaturePad(sigRef.current, {
          backgroundColor: 'rgb(255, 255, 255)'
        });
        sigPad.current.addEventListener('endStroke', () => {
          const img = sigRef.current;
          const data = sigPad.current.toData();
          const last = {
            ...data[data.length - 1],
            width: img.width
          };
          drawData.current = drawData.current.concat([last]);
          /* setNewData(drawData.current); */
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          const canvas = sigRef.current;
          const cache = isUrl && typeof pic !== 'string' ? pic : {};
          setNewData({
            ...cache,
            data: drawData.current,
            width: canvas.offsetWidth * ratio,
            height: canvas.offsetHeight * ratio,
          });
        });
        window.addEventListener('resize', resizeCanvas);
        if (pic && typeof pic === 'object' && type(pic) !== 'Array') {
          sigPad.current.fromData(pic.data);
        }
        resizeCanvas();
      }
      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }
    if (isUrl) {
      setPic({
        url: '',
        data: [],
      });
    }
  }, [showType, isUrl]);

  const switchSigType = () => {
    if (showType) {
      setPic({
        data: [],
      });
    } else {
      setPic('');
    }
    setShowType(!showType);
  };

  const handleTypedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPic(e.target.value);
  };

  const clear = () => {
    sigPad.current?.clear();
    drawData.current = [];
    setShowType(false);
    setNewData({ data: [] });
    return setPic({
      data: [],
    });
  };

  React.useEffect(() => {
    if (!isUrl && newData && !noCtx) {
      console.log(isUrl, pic, newData);
      setPic(newData);
    }
  }, [newData]);

  const sty = {
    ...bodyStyle,
  };

  return (
    <div style={sty}>
      {showType && !isUrl ?
        <div style={{ paddingBottom: '15px' }}>
          <TextField
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              style: {
                lineHeight: '1.4375em',
              },
            }}
            required={required}
            label="Please type your name"
            value={typeof pic === 'string' ? pic : ''}
            onChange={handleTypedChange}
            onBlur={() => ctx.autoSave()}
            disabled={disabled}
          />
        </div> :
        <div style={canvasContainer}>
          {isUrl ?
            <img style={canvasStyle} src={typeof pic !== 'string' && pic.url} /> :
            <canvas style={{
              ...canvasStyle,
              display: disabled ? 'none' : 'unset',
            }}
            ref={sigRef}
            />}
        </div>
      }

      {!disabled &&
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ marginRight: '10px' }}
            onClick={switchSigType}
            variant="contained">
            {showType ? 'Sign Signature' : 'Type Signature'}
          </Button>
          {typeof pic !== 'string' &&
            <Button
              onClick={clear}
              variant="contained">
              Clear
            </Button>}
        </div>}
    </div>
  );
};
