import React, { useState } from 'react';
import { makeStars } from './reviews.component.jsx';
import {
  tzParseFormat,
} from '../../services/joda.js';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import {
  TableContainer,
  TableBody,
  TableRow,
  BodyCell,
} from '../../components/CustomTable';
import Modal from '../../components/Modal/Modal.component';


const selectClient = (client, from, action, setState) => {
  if (from === 'CLIENT') {
    return action();
  }
  action(client);
  return setState('CLIENT');
};


const table = (review, tz, openedFrom, gotoClient, setState) => {
  const client = review.client;
  const exp = review.feedback.ExperienceBadFeedback;
  return (
    <TableContainer noHover>
      <TableBody>
        <TableRow>
          <BodyCell><b>Client:</b></BodyCell>
          <BodyCell>
            <a onClick={() => selectClient(client, openedFrom, gotoClient, setState)}>
              {client.firstName} {client.lastName}
            </a></BodyCell>
        </TableRow>
        <TableRow>
          <BodyCell><b>Experience:</b></BodyCell>
          <BodyCell>Bad</BodyCell>
        </TableRow>
        <TableRow>
          <BodyCell><b>Rating:</b></BodyCell>
          <BodyCell>{makeStars(exp.rating)}</BodyCell>
        </TableRow>
        <TableRow>
          <BodyCell><b>Comment:</b></BodyCell>
          <BodyCell>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                fontFamily: 'sans-serif',
                backgroundColor: 'white',
                padding: '0px',
              }}>
              {exp.comment}</pre>
          </BodyCell>
        </TableRow>
        <TableRow>
          <BodyCell><b>Created:</b></BodyCell>
          <BodyCell>{tzParseFormat(review.created, tz, 'MM/dd/yyyy h:mm a')}</BodyCell>
        </TableRow>
      </TableBody>
    </TableContainer>
  );
};

function ViewReview({
  open,
  onClose,
  review,
  tz,
  openedFrom,
  back,
  gotoClient,
  from,
}) {
  const [state, setState] = useState('REVIEW');
  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Review'
      className='sked-test-view-review-modal'
    >
      <div>
        <ClientEditDialog
          open={state === 'CLIENT'}
          gotoClient={gotoClient}
          from={from}
          back={back}
          onClose={() => setState('REVIEW')} />
        {review ?
          table(
            review,
            tz,
            openedFrom,
            openedFrom === 'CLIENT' ? back : gotoClient,
            setState
          )
          :
          null}
      </div>
    </Modal>
  );
}

export default ViewReview;
