import React, { useState, useMemo, useEffect } from 'react';
import {
  MenuItem, Button, TextField, Select, Dialog, DialogTitle,
  DialogContent, SelectChangeEvent, FormControlLabel, Checkbox, CheckboxProps,
  Paper, IconButton, Tooltip, Autocomplete, autocompleteClasses, Box,
  InputAdornment,
} from '@mui/material';
import {
  createTheme, ThemeProvider,
} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  ConditionalIcon, PencilIcon, WarningIcon
} from '../../../icons/CustomIcons';
import ClockIcon from '../../../icons/Clock.icon';
import HelpButton from '../../../components/Help/Help.component';
import DeleteIcon from '../../../icons/Delete.icon';
import * as ctx from '../intake.context';
import {
  Conditional, Target, Value, NumberFilter, TextFilter, DateFilter, MultiFilter,
  TimeUnit, defaultTimeUnit, IDateFilter,
} from '../ServerTypes';
import {
  FormChild, Section, SectionChild, ComplexWidget, SimpleWidget
} from '../intake-types';
import {
  keys, isEmpty, has, values, update, remove, append, type, drop,
  splitAt, adjust, flatten, last, includes, identity, any, all,
  cond, always, equals, symmetricDifference,
} from 'ramda';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import { useSelector } from '../../../reducers';
import { useDispatch } from 'react-redux';

const filterMap = (checker: (a: any) => boolean, mapper: (a: any) => any, list: any[]) =>
  list.reduce(
    (acc, current) => {
      const mapped = mapper(current);
      return checker(mapped) ? acc.push(mapped) && acc : acc;
    },
    []
  );

/*
  NOTE:
  The index that is used for the questionIndex, target Question, Section,
  and Page are all incremented. Meaning, that the first question or section
  is called Section/Question/Page 1.
*/


const autocompleteTheme = (type: 'options' | 'questions') =>
  createTheme({
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#EEF5FF',
            fontSize: '14px',
            borderRadius: '6px',
            color: '#008BCF',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: 'inherit',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: '35px',
            paddingTop: '5px !important',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            marginBottom: '15px',
          },
          tag: {
            margin: 'unset',
            marginRight: '2px',
          },
          input: {
            font: 'unset',
            fontSize: '14px',
          },
          inputRoot: {
            font: 'unset',
          },
          option: {
            fontSize: '13px',
          },
          endAdornment: {
            position: 'absolute',
            top: 'unset',
            fontSize: '25px',
          },
          popupIndicator: {
            fontSize: '25px',
          },
          clearIndicator: {
            fontSize: '14px',
          },
          listbox: {
            option: {
              '&[aria-checked=true]': {
                backgroundColor: 'blue',
              },
            },
          },
        },
        defaultProps: type === 'questions' ? {
          renderOption: (
            props: React.HTMLAttributes<HTMLLIElement>,
            option: ComplexWidget | SimpleWidget
          ) => (
            <Box
              sx={{
                borderRadius: '8px',
                margin: '5px',
                [`&.${autocompleteClasses.option}`]: {
                  padding: '8px',
                },
              }}
              component="li"
              {...props}
            >
              {option.label}
            </Box>
          ),
        } : {},
      },
    },
  });


const SmallerCheckbox = ({ checked, onChange, disabled }: CheckboxProps) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      style={{ padding: '5px' }}
      sx={{
        '& .MuiSvgIcon-root': { fontSize: '15px' },
        '& .MuiTypography-root': { fontSize: '12px' }
      }}
    />
  );
};

const WarningIc = () => {
  return (
    <WarningIcon style={{
      fontSize: '15px',
      color: 'rgba(0, 0, 0, 0.38)',
      marginRight: '5px',
    }} />
  );
};

const WarningToolTip = ({
  title, length, children
}: {
  title: string, length: number, children: any
}) => {
  return (
    <Tooltip
      arrow
      placement={length > 20 ? 'top' : 'top-end'}
      title={title}>
      {children}
    </Tooltip>
  );
};


const takenText = (target: string, ind: number, question: SimpleWidget | ComplexWidget) => {
  if (question.type === 'SimpleWidget') {
    return `This ${target} is already being conditionally shown by question #${ind} "${question.label}".`;
  }
  return `This ${target} is already being conditionally shown by question #${ind}.`;
};

interface ConditionalLogicDialogProps {
  open: boolean;
  onClose: () => void;
}

interface ValueSelector {
  label: string;
  value: string;
  optValue?: string;
}

const textFilters: ValueSelector[] = [{
  label: 'Is',
  value: 'EqualTo',
}, {
  label: 'Is not',
  value: 'NotEqualTo',
}, {
  label: 'Contains',
  value: 'Contains',
}, {
  label: 'Includes any of',
  value: 'IsOneOf',
}, {
  label: 'Is empty',
  value: 'IsEmpty',
}, {
  label: 'Is not empty',
  value: 'IsNotEmpty',
}];
const boolFilters: ValueSelector[] = [{
  label: 'Is',
  value: 'empty',
}];
const numberFilters: ValueSelector[] = [{
  label: 'Is',
  value: 'EqualTo',
}, {
  label: 'Is not',
  value: 'NotEqualTo',
}, {
  label: 'Is greater than',
  value: 'GreaterThan',
}, {
  label: 'Is less than',
  value: 'LessThan',
}, {
  label: 'Is greater than or equal to',
  value: 'GreaterThanOrEqualTo',
}, {
  label: 'Is less than or equal to',
  value: 'LessThanOrEqualTo',
}, {
  label: 'Is between',
  value: 'IsBetween',
}];
const dateFilters: ValueSelector[] = [{
  label: 'Is',
  value: 'EqualTo',
}, {
  label: 'Is not',
  value: 'NotEqualTo',
}, {
  label: 'Is before',
  value: 'IsBefore',
}, {
  label: 'Is after',
  value: 'IsAfter',
}, {
  label: 'Is between',
  value: 'IsBetween',
}, {
  label: 'Is empty',
  value: 'IsEmpty',
}, {
  label: 'Is not empty',
  value: 'IsNotEmpty',
}];/*, {
  label: 'Past',
  value: 'IsExactlyAgo',
}, {
  label: 'More Than in Past',
  value: 'IsMoreThanAgo',
}, {
  label: 'Less Than in Past',
  value: 'IsLessThanAgo',
}, {
  label: 'More Than and Less Than in Past',
  value: 'IsMoreThanAndLessThanAgo',
}, {
  label: 'Future',
  value: 'IsExactlyInFuture',
}, {
  label: 'More Than in Future',
  value: 'IsMoreThanInFuture',
}, {
  label: 'Less Than in Future',
  value: 'IsLessThanInFuture',
}, {
  label: 'More Than and Less Than in Future',
  value: 'IsMoreThanAndLessThanInFuture',
}];*/
const multiFilters: ValueSelector[] = [{
  label: 'Is',
  value: 'EqualTo',
}, {
  label: 'Has any of',
  value: 'HasAnyOf',
}, {
  label: 'Has all of',
  value: 'HasAllOf',
}, {
  label: 'Has none of',
  value: 'HasNoneOf',
}, {
  label: 'Is empty',
  value: 'IsEmpty',
}, {
  label: 'Is not empty',
  value: 'IsNotEmpty',
}];


const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#fafafa',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottom: 'solid lightgray 1px',
    paddingRight: '3px',
  },
  dialogContent: {
    margin: '0px 10px',
    minHeight: '400px',
    padding: '20px 8px',
    '& p': {
      marginBottom: '10px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  newButton: {
    fontSize: '12px',
  },
  questionSelect: {
    width: '100%',
    height: '32px',
    marginBottom: '6px',
  },
  autocompleteSelect: {
    width: '100%',
    height: '32px',
  },
  condItems: {
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '5px',
    paddingTop: '5px',
    paddingBottom: '5px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '10px',
    border: '1px solid #D8D8D891',
  },
  condItemButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateTimeFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '-10px',
    marginBottom: '-10px',
    height: '50px',
    position: 'relative',
    zIndex: 1,
  },
  dateTimeFieldsDirection: {
    width: '40%',
    marginRight: '10px',
    marginBottom: '0px',
    marginTop: '4px',
  },
  dateTimeFieldsLabel: {
    width: '20%',
    maxWidth: '70px',
    marginRight: '10px',
    marginTop: '25px',
    textAlign: 'center',
    '& b': {
      fontSize: '12px',
    },
    '& span': {
      fontSize: '14px',
      color: '#5C5C5C',
      marginTop: '3px',
    },
  },
  todayHelper: {
    position: 'absolute',
    top: '17px',
    left: '60px',
    color: '#a0a0a0',
    zIndex: -1,
    fontSize: '14px',
  },
  warningCheckbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  grayedOut: {
    marginLeft: 'unset',
    color: 'rgba(0, 0, 0, 0.38)',
    width: 'fit-content',
  },
  trash: {
    fontSize: '16px',
    color: '#474a54'
  },
}));

const useEasyAgeStyles = makeStyles(() => ({
  root: {
    padding: 10,
    backgroundColor: '#edf7fe',
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'column',
    width: 475,
    marginTop: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3696df',
    fontSize: 15,
    width: 147,
  },
  body: {
    fontSize: 14,
    margin: '10px 0px',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  button: {
    fontSize: '12px',
  },
}));

const numberInputSx = {
  '.MuiInputBase-input': {
    padding: '16.5px 7px 16.5px 14px',
  },
};

interface EasyAgeProps {
  defaults: DateFilter,
  onChange: (prop: keyof IDateFilter, t: TimeUnit) => void;
  between: boolean;
  betweenChange: (t: TimeUnit[]) => void;
  reset: boolean;
  unreset: () => void;
}

type Comparitor = 'Above' | 'Below' | 'Between';

const comparitors: Comparitor[] = ['Above', 'Below', 'Between'];

interface KeyV {
  key: string;
  v: TimeUnit | TimeUnit[]
}

/* To test if someone is younger than 18 years, their birthday would
   need to be AFTER the LAST 18 years, which translates to:
   DateFilter, IsBefore, -18 years
   Older than 18:
   DateFilter, IsAfter, -18 years
   Between 13 and 18:
   DateFilter, IsBetween, -13 years, -18 years
*/
const EasyAge = ({
  defaults, onChange, between, betweenChange, reset, unreset,
}: EasyAgeProps) => {
  const classes = useEasyAgeStyles();
  const [comparitor, setComparitor] = useState<Comparitor>('Above');
  const [age, setAge] = useState(18);
  const [age2, setAge2] = useState(18);
  const [isBetween, setIsBetween] = useState(between);

  useEffect(() => {
    setComparitor(
      cond([
        [has('IsBefore'), always('Above' as Comparitor)],
        [has('IsAfter'), always('Below' as Comparitor)],
        [has('IsBetween'), always('Between' as Comparitor)],
      ])(defaults)
    );
    const value: TimeUnit | TimeUnit[] = defaults[keys(defaults)[0]] as TimeUnit | TimeUnit[];
    if (has('IsBetween', defaults)) {
      const y1 = Math.abs((value as TimeUnit[])[0].years);
      const y2 = Math.abs((value as TimeUnit[])[1].years);
      setAge(reset ? 12 : y1);
      setAge2(reset ? 18 : y2);
    } else {
      const y = Math.abs((value as TimeUnit).years);
      setAge(reset ? 18 : y);
    }
    unreset();
  }, [defaults]);

  useEffect(() => {
    setIsBetween(comparitor === 'Between');
  }, [comparitor]);

  useEffect(() => {
    setIsBetween(between);
  }, [between]);

  const disabled = useMemo(() => {
    const { key, v }: KeyV = cond([
      [has('IsBefore'), (): KeyV => ({
        key: 'Above' as Comparitor, v: defaults.IsBefore
      })],
      [has('IsAfter'), (): KeyV => ({
        key: 'Below' as Comparitor, v: defaults.IsAfter
      })],
      [has('IsBetween'), (): KeyV => ({
        key: 'Between' as Comparitor, v: defaults.IsBetween
      })],
    ])(defaults);
    if (key !== comparitor) {
      return false;
    }
    if (key === 'Between') {
      return age === Math.abs((v as TimeUnit[])[0].years) &&
        age2 === Math.abs((v as TimeUnit[])[1].years);
    }
    return age === Math.abs((v as TimeUnit).years);
  }, [comparitor, age, age2, defaults]);

  const handleApply = () => {
    if (isBetween) {
      betweenChange([{
        ...defaultTimeUnit,
        years: age * -1,
      }, {
        ...defaultTimeUnit,
        years: age2 * -1,
      }]);
    } else {
      if (comparitor === 'Below') {
        onChange('IsAfter' as keyof IDateFilter, {
          ...defaultTimeUnit,
          years: age * -1,
        });
      } else {
        onChange('IsBefore' as keyof IDateFilter, {
          ...defaultTimeUnit,
          years: age * -1,
        });
      }
    }
  };

  const changeComparitor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComparitor(e.target.value as Comparitor);
  };

  const changeAge = (fn: (n: number) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    fn(Number(e.target.value));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <ClockIcon />
        Filtering by age?
      </div>
      <div className={classes.body}>
        If you are trying to filter by age, edit and apply the fields below to make setup simpler.
      </div>
      <div className={classes.action} style={{ width: isBetween ? 420 : 320 }}>
        <Select
          variant="outlined"
          value={comparitor}
          style={{
            width: isBetween ? 108 : 100, backgroundColor: 'white', height: 32,
          }}
          onChange={changeComparitor}>
          {comparitors.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          variant="outlined"
          type='number'
          value={Math.abs(age)}
          style={{
            backgroundColor: 'white', height: 32, width: 50
          }}
          InputProps={{
            style: {
              backgroundColor: 'white', height: 32, width: 60
            },
            sx: numberInputSx,
          }}
          onChange={changeAge(setAge)}
        />
        {!isBetween && <div style={{ marginLeft: 8 }}>years old</div>}
        {isBetween &&
            <>
              <div style={{ marginLeft: 8 }}>and</div>
              <TextField
                variant="outlined"
                type='number'
                value={Math.abs(age2)}
                style={{
                  backgroundColor: 'white', height: 32, width: 50
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'white', height: 32, width: 60
                  },
                  sx: numberInputSx,
                }}
                onChange={changeAge(setAge2)}
              />
              <div style={{ marginLeft: 8 }}>years old</div>
            </>}
        <Button
          variant="contained"
          color="primary"
          disabled={age < 0 || disabled}
          onClick={handleApply}
          className={classes.button}
        >
            Apply
        </Button>
      </div>
    </div>
  );
};

interface Props {
  onChange: (t: keyof Target, n: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  conditionals: Conditional[];
  children: JSX.Element[];
  questions: (SimpleWidget | ComplexWidget)[];
  query: string;
}

interface SectionProps extends Props {
  selectedSections: Target[];
  origSections: Target[];
  section: Section;
  inSection: number;
  index: number;
}

interface QuestionProps {
  selectedQuestions: Target[];
  origQuestions: Target[];
  onChange: (t: keyof Target, n: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  conditionals: Conditional[];
  question: SectionChild;
  questions: (SimpleWidget | ComplexWidget)[];
  query: string;
  questionIndex: number;
  index: number;
}


let sind = 0;
let qind = 0;

const QuestionItemInner = ({
  selectedQuestions, origQuestions, onChange, conditionals, question,
  query, questions, questionIndex, index,
}: QuestionProps) => {
  const classes = useStyles();
  if (question.type === 'SimpleWidget' ||
                        question.type === 'ComplexWidget') {
    const label = question.type === 'SimpleWidget' ?
      question.label :
      question.typeData.type;
    const qchecked = useMemo(() => {
      return selectedQuestions.find(({ Question }: Target) => Question === index);
    }, [selectedQuestions]);
    const qtake = useMemo(() => {
      return !origQuestions.find(({ Question }: Target) => Question === index) &&
                            conditionals.find(({ target }: Conditional) => target.find(({ Question }: Target) => Question === index));
    }, [origQuestions, conditionals]);
    const matchIndex = label.toLowerCase().indexOf(query.toLowerCase());
    if (matchIndex < 0) {
      return null;
    }
    const l: (string | string[])[] = splitAt(matchIndex, label);
    const sp: (string | string[])[] = adjust(1, (s: string): string[] => splitAt(query.length, s), l);
    const main: string[] = flatten(sp);
    if (qtake && !qchecked) {
      return (
        <WarningToolTip
          length={label.length}
          title={takenText('question', qtake.questionIndex, questions[qtake.questionIndex])}>
          <FormControlLabel
            key={'Question ' + index}
            id={'Question ' + index}
            className={classes.grayedOut}
            control={
              <div className={classes.warningCheckbox}>
                <SmallerCheckbox
                  disabled
                />
                <WarningIc />
              </div>}
            label={'#' + index + ' "' + label + '"'}
          />
        </WarningToolTip>
      );
    }
    return (
      <FormControlLabel
        key={'Question ' + index}
        id={'Question ' + index}
        style={{ marginLeft: 'unset' }}
        control={
          <SmallerCheckbox
            checked={Boolean(qchecked)}
            disabled={index === questionIndex}
            onChange={onChange('Question', index)}
          />}
        label={
          <div>
            {'#' + index + ' "'}
            {main[0]}
            <div style={{
              display: 'inline',
              backgroundColor: 'yellow'
            }}>
              {main[1]}
            </div>
            {main[2] !== undefined && main[2] + '"'}
          </div>
        }
      />
    );
  }
  return <></>;
};

const QuestionItem = React.memo(QuestionItemInner, (prev, next) => {
  const diff = symmetricDifference(prev.selectedQuestions, next.selectedQuestions);
  const questionHasChanged = diff.filter(({ Question }) => {
    return Question === prev.index;
  });
  return isEmpty(questionHasChanged) && prev.onChange === next.onChange;
});

const SectionItemInner = ({
  onChange, conditionals, children, selectedSections, origSections,
  questions, section, inSection, index,
}: SectionProps) => {
  const classes = useStyles();
  const schecked =
                  selectedSections.find(({ Section }: Target) => Section === index);
  const stake = !origSections.find(({ Section }: Target) => Section === index) && conditionals
    .find(({ target }: Conditional) =>
      target.find(({ Section }: Target) =>
        Section === index
      ));

  return (
    <div
      key={'Section' + section.id}
      style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {stake && !schecked ?
        <WarningToolTip
          length={' Section'.length}
          title={takenText('section', stake.questionIndex, questions[stake.questionIndex])}>
          <FormControlLabel
            className={classes.grayedOut}
            control={
              <div className={classes.warningCheckbox}>
                <SmallerCheckbox
                  disabled
                />
                <WarningIc />
              </div>}
            label={'#' + index + ' Section'}
          />
        </WarningToolTip>
        :
        <FormControlLabel
          style={{ marginLeft: 'unset' }}
          control={
            <SmallerCheckbox
              checked={Boolean(schecked)}
              disabled={inSection === index}
              onChange={onChange('Section', index)}
            />}
          label={'#' + index + ' Section'}
        />}
      <div id={'Section' + index} style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {children}
      </div>
    </div>
  );
};

const SectionItem = React.memo(SectionItemInner, (prev, next) => {
  const diff = symmetricDifference(prev.selectedSections, next.selectedSections);
  const sectionHasChanged = diff.filter(({ Section }) => {
    return Section === prev.index;
  });
  return isEmpty(sectionHasChanged) && prev.onChange === next.onChange;
});

interface PageProps extends Props {
  selectedPages: Target[];
  origPages: Target[];
  pageInd: number;
  inPage: number;
}

const PageItem = ({
  onChange, conditionals, selectedPages, origPages, children, pageInd,
  questions, inPage,
}: PageProps) => {
  const classes = useStyles();
  const pind = pageInd + 1;
  const pchecked =
            selectedPages.find(({ Page }: Target) => Page === pind);
  const ptake = !origPages.find(({ Page }: Target) => Page === pind) &&
            conditionals.find(({ target }: Conditional) => target.find(({ Page }: Target) => Page === pind));
  return (
    <div
      key={'Page' + pind}
      id={'Page' + pind}
    >
      {ptake && !pchecked ?
        <WarningToolTip
          length={' Page'.length}
          title={takenText('page', ptake.questionIndex, questions[ptake.questionIndex])}>
          <FormControlLabel
            className={classes.grayedOut}
            control={
              <div className={classes.warningCheckbox}>
                <SmallerCheckbox
                  disabled
                />
                <WarningIc />
              </div>}
            label={'#' + pind + ' Page'}
          />
        </WarningToolTip>
        :
        <FormControlLabel
          style={{ marginLeft: 'unset' }}
          control={
            <SmallerCheckbox
              checked={Boolean(pchecked)}
              disabled={pind === 1 || inPage === pind}
              onChange={onChange('Page', pind)}
            />}
          label={'#' + pind + ' Page'}
        />}
      {children}
    </div>
  );
};

interface TabulatedListProps {
  conditionals: Conditional[];
  questionIndex: number;
  targets: Target[];
  onChange: (t: keyof Target, n: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

let search: Section[][][] = [];
const TabulatedListInner = ({
  conditionals, questionIndex, targets, onChange
}: TabulatedListProps) => {
  const classes = useStyles();
  const form = useSelector((state) => {
    return state.forms.revision;
  });
  const [pages, setPages] = useState<Section[][]>([]);
  const [questions, setQuestions] = useState<(SimpleWidget | ComplexWidget)[]>([]);
  const [query, setQuery] = useState<string>('');

  const [
    origPages,
    origSections,
    origQuestions
  ] = useMemo(() => {
    const p = targets.filter((t: Target) => has('Page', t));
    const s = targets.filter((t: Target) => has('Section', t));
    const q = targets.filter((t: Target) => has('Question', t));
    return [p, s, q];
  }, []);

  const [
    selectedPages,
    selectedSections,
    selectedQuestions
  ] = useMemo(() => {
    const p = targets.filter((t: Target) => has('Page', t));
    const s = targets.filter((t: Target) => has('Section', t));
    const q = targets.filter((t: Target) => has('Question', t));
    qind = 0;
    return [p, s, q];
  }, [targets]);

  console.log(selectedQuestions, targets);

  useEffect(() => {
    const universe: Section[][] = [];
    let currentPage: Section[] = [];
    const currentQuestions: (SimpleWidget | ComplexWidget)[] = [null];
    form.children.forEach((formChild: FormChild) => {
      if (formChild.type === 'Section') {
        const fc = {
          ...formChild,
          children: formChild.children.filter((quest) => {
            const isQuest = quest.type === 'SimpleWidget' || quest.type === 'ComplexWidget';
            if (isQuest) {
              currentQuestions.push(quest);
            }
            return isQuest;
          }),
        };
        currentPage.push(fc);
      } else if (formChild.type === 'PageBreak') {
        universe.push(currentPage);
        currentPage = [];
      }
    });
    if (!isEmpty(currentPage)) {
      universe.push(currentPage);
    }
    setPages(universe);
    setQuestions(currentQuestions);
  }, [form]);

  qind = 0;
  sind = 0;

  const [inPage, inSection] = useMemo(() => {
    let pg = 0;
    let sect = -1;
    let qig = 0;
    let sig = 0;
    pages.every((sections: Section[], pi: number) => {
      sections.every((section: Section) => {
        sig = sig + 1;
        section.children.every(() => {
          qig = qig + 1;
          if (qig === questionIndex) {
            pg = pi;
            sect = sig;
            return false;
          }
          return true;
        });

        if (pg > 0 && sect > 0) {
          return false;
        }
        return true;
      });

      if (pg > 0 && sect > 0) {
        return false;
      }
      return true;
    });
    return [pg, sect];
  }, [pages, questionIndex]);

  const showablePages: Section[][] = useMemo(() => {
    if (isEmpty(query)) {
      search = [];
      return pages;
    }
    const elm = query.length - 1;
    const previousSearch: Section[][] = elm > 0 ? search[elm - 1] : pages;
    const currentSearch = filterMap(
      (sections: Section[]) => {
        return !isEmpty(sections);
      },
      (sections: Section[]) => {
        return filterMap(
          (section: Section) => {
            return !isEmpty(section.children);
          },
          (section: Section) => {
            return {
              ...section,
              children: section.children.filter((question: SectionChild) => {
                if (question.type === 'SimpleWidget' ||
                      question.type === 'ComplexWidget') {
                  const label = question.type === 'SimpleWidget' ?
                    question.label :
                    question.typeData.type;
                  return label.toLowerCase().indexOf(query.toLowerCase()) > 0;
                }
                return false;
              }),
            };
          },
          sections
        );
      },
      previousSearch
    );
    search.push(currentSearch);
    return currentSearch;
  }, [query, pages]);

  /* TODO: wrap this in a component  */
  const [focus, setFocus] = useState(false);

  return (
    <div style={{
      borderRadius: '4px',
      border: 'solid rgba(0, 0, 0, 0.23) 1px',
    }}>
      <TextField
        type='text'
        size='small'
        variant='standard'
        value={query}
        className={classes.questionSelect}
        style={{
          marginBottom: 'unset',
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        sx={{
          borderRadius: 1,
          borderBottom: focus ? 2 : 1,
          borderColor: focus ? '#37AEFD' : 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: focus ? '#37AEFD' : 'rgba(0,0,0,0.87)',
          },
        }}
        InputProps={{
          className: classes.questionSelect,
          style: {
            paddingLeft: '5px',
            paddingRight: '5px',
            marginBottom: 'unset',
          },
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon style={{
                fontSize: '20px',
                color: '#555',
                marginRight: '-5px',
              }}/>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setQuery('')}>
                <ClearIcon sx={{ fontSize: '14px' }} />
              </IconButton>
            </InputAdornment>
          )
        }}
        onChange={(e) => setQuery(e.target.value)}
        placeholder='Search target question'
      />
      <div style={{
        height: '300px',
        overflowY: 'scroll',
      }}>
        {(showablePages || pages).map((sections: Section[], pd: number) => {
          return (
            <PageItem
              onChange={onChange}
              pageInd={pd}
              conditionals={conditionals}
              origPages={origPages}
              selectedPages={selectedPages}
              query={query}
              inPage={inPage}
              questions={questions}>
              {sections.map((section: Section) => {
                sind = sind + 1;
                return (
                  <SectionItem
                    onChange={onChange}
                    conditionals={conditionals}
                    origSections={origSections}
                    selectedSections={selectedSections}
                    query={query}
                    inSection={inSection}
                    questions={questions}
                    section={section}
                    index={sind}
                  >
                    {section.children.map((question: SectionChild) => {
                      if (question.type === 'SimpleWidget' ||
                          question.type === 'ComplexWidget') {
                        qind = qind + 1;
                        return (
                          <QuestionItem
                            onChange={onChange}
                            conditionals={conditionals}
                            origQuestions={origQuestions}
                            selectedQuestions={selectedQuestions}
                            query={query}
                            questionIndex={questionIndex}
                            questions={questions}
                            question={question}
                            index={qind}
                          />
                        );
                      }
                      return <></>;
                    })}
                  </SectionItem>
                );
              })}
            </PageItem>
          );
        })}
      </div>
    </div>
  );
};

const TabulatedList = React.memo(TabulatedListInner, (prev, next) => {
  return equals(prev.targets, next.targets) &&
  equals(prev.questionIndex, next.questionIndex) &&
  equals(prev.conditionals, next.conditionals) &&
  prev.onChange === next.onChange;
});

interface FakeTarget {
  value: any;
}

interface FakeElement {
  target: FakeTarget;
}

const getComparitor = (value: Value): {value: number | number[] | string | boolean | string[], compare: string} => {
  const prop = keys(value)[0];
  switch (prop) {
    case 'TextFilter': {
      const dig: keyof TextFilter = keys(value.TextFilter)[0];
      const filt = textFilters.find((t) => t.value === dig);
      const { label } = filt ? filt : { label: '' };
      return { compare: label.toUpperCase(), value: value.TextFilter[dig] };
    }
    case 'BoolFilter':
      return { compare: 'EQUALS', value: value.BoolFilter };
    case 'NumberFilter': {
      const dig: keyof NumberFilter = keys(value.NumberFilter)[0];
      const { label } = numberFilters.find((t) => t.value === dig);
      return { compare: label.toUpperCase(), value: value.NumberFilter[dig] };
    }
    case 'DateFilter': {
      const dig: keyof DateFilter = keys(value.DateFilter)[0];
      const opt = dateFilters.find((t) =>
        t?.value === dig || t.optValue === dig
      );
      let label;
      if (opt) {
        label = `${opt.label}`;
      } else {
        label = 'little bit a chicken fried';
      }
      const d = value.DateFilter[dig];
      switch (dig) {
        case 'IsEmpty':
        case 'IsNotEmpty': {
          return { compare: label.toUpperCase(), value: null };
        }
        case 'EqualTo':
        case 'NotEqualTo': {
          if (typeof d === 'string') {
            const [year, month, day] = d.split('-');
            return { compare: label.toUpperCase(), value: `${Number(month)}-${Number(day)}-${year}` };
          }
          break;
        }
        case 'IsBetween': {
          if (type(d) === 'Array' && typeof d !== 'string') {
            const de0: TimeUnit = (d as unknown as TimeUnit[])[0];
            const de1: TimeUnit = (d as unknown as TimeUnit[])[1];
            const dir0 = any((d) => d < 0, values(de0));
            const dir1 = any((d) => d < 0, values(de1));
            const isToday0 = all((d) => d === 0, values(de0));
            const isToday1 = all((d) => d === 0, values(de1));
            const value0 = isToday0 ? 'today' : (dir0 ? 'the last ' : 'the next ') +
              (de0.days !== 0 ? `${Math.abs(de0.days)} days` : '') +
              (de0.months !== 0 ? `${Math.abs(de0.months)} months` : '') +
              (de0.years !== 0 ? `${Math.abs(de0.years)} years` : '');
            const value1 = isToday1 ? 'today' : (dir1 ? 'the last ' : 'the next ') +
              (de1.days !== 0 ? `${Math.abs(de1.days)} days` : '') +
              (de1.months !== 0 ? `${Math.abs(de1.months)} months` : '') +
              (de1.years !== 0 ? `${Math.abs(de1.years)} years` : '');
            return { compare: label.toUpperCase(), value: [value0, value1] };
          }
          break;
        }
        case 'IsBefore':
        case 'IsAfter': {
          const de: TimeUnit = d as unknown as TimeUnit;
          const dir = any((d) => d < 0, values(de));
          const isToday = all((d) => d === 0, values(de));
          const value = isToday ? 'today' : (dir ? 'the last ' : 'the next ') +
            (de.days !== 0 ? `${Math.abs(de.days)} days` : '') +
            (de.days !== 0 && (de.months !== 0 || de.years !== 0) ? ', ' : '') +
            (de.months !== 0 ? `${Math.abs(de.months)} months` : '') +
            (de.months !== 0 && de.years !== 0 ? ', ' : '') +
            (de.years !== 0 ? `${Math.abs(de.years)} years` : '');
          return { compare: label.toUpperCase(), value };
        }
      }
      break;
    }
    case 'MultiFilter': {
      const dig: keyof MultiFilter = keys(value.MultiFilter)[0];
      const { label } = multiFilters.find((t) => t.value === dig);
      return { compare: label.toUpperCase(), value: value.MultiFilter[dig] };
    }
  }
};


const trueOrFalse = (v: boolean) => {
  return v ? 'True' : 'False';
};

const DateTimeUnitFields = ({
  value, onChange, onDirectionChange,
}: {
  value: TimeUnit,
  onChange: (t: TimeUnit) => void,
  onDirectionChange: (d: string) => void,
}) => {
  const classes = useStyles();
  const [today, setToday] = useState(false);

  const direction = useMemo(() => {
    if (value) {
      if (value.hidden === 0) {
        setToday(true);
        return 'Today';
      }
      const dir = any((i: number) => i < 0, values(value));
      setToday(false);
      return dir ? 'The last' : 'The next';
    }
    setToday(false);
    return 'The last';
  }, [value]);

  const localOnChange = (prop: keyof TimeUnit) => (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const num = Number(e.target.value);
      if (num >= 0) {
        onChange({
          ...value,
          [prop]: direction === 'The last' ? -1 * num : num,
        });
      }
    } catch (e) {
      // do nothing
      console.log(e);
    }
  };
  const changeDirection = (e: SelectChangeEvent<string>) => {
    const v = e.target.value;
    if (v === 'Today') {
      setToday(true);
      onDirectionChange(v);
      onChange({
        years: 0,
        months: 0,
        days: 0,
        hidden: 0,
      });
    } else {
      setToday(false);
      onDirectionChange(v);
    }
  };

  return (
    <div className={classes.dateTimeFieldsContainer}>
      <Select
        variant="outlined"
        value={direction}
        className={
          classes.questionSelect + ' ' + classes.dateTimeFieldsDirection
        }
        style={today ? {
          width: '100%',
          maxWidth: '360px',
          marginRight: '0px',
        } : {
          maxWidth: '120px'
        }}
        onChange={changeDirection}>
        {['The last', 'The next', 'Today'].map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          );
        })}
      </Select>
      {today && <div className={classes.todayHelper}>(When patient is filling out form)</div>}
      {!today &&
        <>
          <div className={classes.dateTimeFieldsLabel}>
            <TextField
              variant="outlined"
              type='number'
              value={Math.abs(value.years)}
              className={classes.questionSelect}
              style={{
                marginBottom: 'unset',
              }}
              InputProps={{
                className: classes.questionSelect,
                style: {
                  color: value.years === 0 ? '#9a9b9b' : 'unset',
                },
                sx: numberInputSx,
              }}
              onChange={localOnChange('years')}
            />
            <br/>
            <span>Years</span>
          </div>
          <div className={classes.dateTimeFieldsLabel}>
            <TextField
              variant="outlined"
              type='number'
              value={Math.abs(value.months)}
              className={classes.questionSelect}
              style={{
                marginBottom: 'unset',
              }}
              InputProps={{
                className: classes.questionSelect,
                style: {
                  color: value.months === 0 ? '#9a9b9b' : 'unset',
                },
                sx: numberInputSx,
              }}
              onChange={localOnChange('months')}
            />
            <br/>
            <span>Months</span>
          </div>
          <div
            className={classes.dateTimeFieldsLabel}
            style={{ marginRight: 'unset' }}>
            <TextField
              variant="outlined"
              type='number'
              value={Math.abs(value.days)}
              className={classes.questionSelect}
              style={{
                marginBottom: 'unset',
              }}
              InputProps={{
                className: classes.questionSelect,
                style: {
                  color: value.days === 0 ? '#9a9b9b' : 'unset',
                },
                sx: numberInputSx,
              }}
              onChange={localOnChange('days')}
            />
            <br/>
            <span>Days</span>
          </div>
        </>}
    </div>
  );
};

const ConditionalLogicDialog = (props: ConditionalLogicDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState<'LIST' | 'EDIT'>('LIST');
  const [cond, setCond] = useState<Conditional>(null);
  const [index, setIndex] = useState<number>(null);
  const [questionFilters, setQuestionFilters] = useState<ValueSelector[]>(textFilters);
  const [Question, setQuestion] = useState<SimpleWidget>(null);
  const [deleteModal, setDeleteModal] = useState<number>(null);
  const [resetEasy, setResetEasy] = useState<boolean>(false);
  const { questions, conditionals } = useSelector((state) => {
    return ({
      questions: ctx.getAllQuestions(state.forms.revision),
      conditionals: state.forms.revision.conditionals,
    });
  });

  console.log(conditionals);

  const editConditional = (c: Conditional, idx: number) => {
    if (c) {
      /* To make sure that the the next/the last/today dropdown shows
         the "today" option correctly, we have to set the `hidden` prop.
      */
      const valueKey: keyof DateFilter = keys(c.value.DateFilter || {})[0];
      if (includes(valueKey, ['IsBefore', 'IsAfter'])) {
        const timeUnit: TimeUnit = c.value.DateFilter[valueKey] as unknown as TimeUnit;
        const isToday = all((d) => d === 0, values(timeUnit));
        setCond({
          ...c,
          value: {
            DateFilter: {
              [valueKey]: {
                ...timeUnit,
                hidden: isToday ? 0 : undefined,
              },
            },
          },
        });
      } else if (valueKey === 'IsBetween') {
        const timeUnit: TimeUnit[] = c.value.DateFilter[valueKey] as unknown as TimeUnit[];
        const isToday0 = all((d) => d === 0, values(timeUnit[0]));
        const isToday1 = all((d) => d === 0, values(timeUnit[1]));
        setCond({
          ...c,
          value: {
            DateFilter: {
              [valueKey]: [{
                ...timeUnit[0],
                hidden: isToday0 ? 0 : undefined,
              }, {
                ...timeUnit[1],
                hidden: isToday1 ? 0 : undefined,
              }],
            },
          },
        });
      } else {
        setCond(c);
      }
      setIndex(idx);
      const q = questions[c.questionIndex];
      if (q.type === 'SimpleWidget' &&
         (q.questionType.type === 'Checkbox' ||
          q.questionType.type === 'Radio' ||
          q.questionType.type === 'Dropdown')) {
        setQuestion(q);
      }
    } else {
      setCond({
        conditionalId: 0,
        revId: 0,
        questionIndex: 0,
        value: { TextFilter: {
          EqualTo: '',
        } },
        target: [],
      });
      setIndex(-1);
    }
    setState('EDIT');
  };

  const selectQuestion = (event: React.SyntheticEvent<Element, Event>, question: SimpleWidget | ComplexWidget) => {
    const questionIndex = questions.findIndex(({ id }) => id === question?.id);
    if (question && question.type === 'SimpleWidget') {
      if (question.questionType.type === 'Number') {
        setQuestionFilters(numberFilters);
        setQuestion(null);
        setCond({
          ...cond,
          questionIndex,
          value: {
            NumberFilter: { EqualTo: 0 },
          },
        });
      } else if (question.questionType.type === 'CheckboxSingle') {
        setQuestionFilters(boolFilters);
        setQuestion(null);
        setCond({
          ...cond,
          questionIndex,
          value: {
            BoolFilter: true,
          },
        });
      } else if (question.questionType.type === 'Checkbox') {
        setQuestionFilters(multiFilters);
        setQuestion(question);
        setCond({
          ...cond,
          questionIndex,
          value: {
            MultiFilter: {
              EqualTo: '',
            },
          },
        });
      } else if (question.questionType.type === 'Date') {
        setQuestionFilters(dateFilters);
        setQuestion(null);
        setCond({
          ...cond,
          questionIndex,
          value: {
            DateFilter: { EqualTo: '' },
          },
        });
      } else if (question.questionType.type === 'Radio' || question.questionType.type === 'Dropdown') {
        setQuestionFilters(textFilters);
        setQuestion(question);
        setCond({
          ...cond,
          questionIndex,
          value: {
            TextFilter: {
              EqualTo: '',
            },
          },
        });
      } else {
        setQuestionFilters(textFilters);
        setQuestion(null);
        setCond({
          ...cond,
          questionIndex,
          value: {
            TextFilter: {
              EqualTo: '',
            },
          },
        });
      }
    }
  };

  const isEmpt = useMemo(() => {
    return isEmpty(conditionals);
  }, [conditionals]);

  const value = useMemo(() => {
    const prop = keys(cond?.value || {})[0];
    if (prop === 'TextFilter') {
      setQuestionFilters(textFilters);
      return keys(cond.value.TextFilter)[0];
    } else if (prop === 'BoolFilter') {
      setQuestionFilters(boolFilters);
      return 'empty';
    } else if (prop === 'NumberFilter') {
      setQuestionFilters(numberFilters);
      return keys(cond.value.NumberFilter)[0];
    } else if (prop === 'DateFilter') {
      setQuestionFilters(dateFilters);
      return keys(cond.value.DateFilter)[0];
    } else if (prop === 'MultiFilter') {
      setQuestionFilters(multiFilters);
      return keys(cond.value.MultiFilter)[0];
    }
    return null;
  }, [cond]);

  const selectValue = (e: SelectChangeEvent<string>) => {
    const v = e.target.value;
    const prop = keys(cond?.value || {})[0];
    if (prop === 'NumberFilter') {
      switch (v) {
        case 'EqualTo':
          setCond({
            ...cond,
            value: {
              NumberFilter: {
                EqualTo: 0,
              },
            }
          });
          break;
        case 'LessThan':
          setCond({
            ...cond,
            value: {
              NumberFilter: {
                LessThan: 0,
              },
            }
          });
          break;
        case 'GreaterThan':
          setCond({
            ...cond,
            value: {
              NumberFilter: {
                GreaterThan: 0,
              },
            }
          });
          break;
        case 'LessThanOrEqualTo':
          setCond({
            ...cond,
            value: {
              NumberFilter: {
                LessThanOrEqualTo: 0,
              },
            }
          });
          break;
        case 'GreaterThanOrEqualTo':
          setCond({
            ...cond,
            value: {
              NumberFilter: {
                GreaterThanOrEqualTo: 0,
              },
            }
          });
          break;
        case 'IsBetween':
          setCond({
            ...cond,
            value: {
              NumberFilter: {
                IsBetween: [0, 0],
              },
            }
          });
          break;
      }
    } else if (prop === 'TextFilter') {
      switch (v) {
        case 'EqualTo':
          setCond({
            ...cond,
            value: {
              TextFilter: {
                EqualTo: '',
              },
            }
          });
          break;
        case 'NotEqualTo':
          setCond({
            ...cond,
            value: {
              TextFilter: {
                NotEqualTo: '',
              },
            }
          });
          break;
        case 'Contains':
          setCond({
            ...cond,
            value: {
              TextFilter: {
                Contains: '',
              },
            }
          });
          break;
        case 'IsOneOf':
          setCond({
            ...cond,
            value: {
              TextFilter: {
                IsOneOf: [],
              },
            }
          });
          break;
        case 'IsEmpty':
          setCond({
            ...cond,
            value: {
              TextFilter: {
                IsEmpty: [] as [],
              },
            }
          });
          break;
        case 'IsNotEmpty':
          setCond({
            ...cond,
            value: {
              TextFilter: {
                IsNotEmpty: [] as [],
              },
            }
          });
          break;
      }
    } else if (prop === 'DateFilter') {
      switch (v) {
        case 'EqualTo':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                EqualTo: '',
              },
            }
          });
          break;
        case 'NotEqualTo':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                NotEqualTo: '',
              },
            }
          });
          break;
        case 'IsBefore':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                IsBefore: defaultTimeUnit,
              },
            }
          });
          setResetEasy(true);
          break;
        case 'IsAfter':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                IsAfter: defaultTimeUnit,
              },
            }
          });
          setResetEasy(true);
          break;
        case 'IsBetween':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                IsBetween: [defaultTimeUnit, defaultTimeUnit],
              },
            }
          });
          setResetEasy(true);
          break;
        case 'IsEmpty':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                IsEmpty: [],
              },
            }
          });
          break;
        case 'IsNotEmpty':
          setCond({
            ...cond,
            value: {
              DateFilter: {
                IsNotEmpty: [],
              },
            }
          });
          break;
      }
    } else if (prop === 'MultiFilter') {
      switch (v) {
        case 'EqualTo':
          setCond({
            ...cond,
            value: {
              MultiFilter: {
                EqualTo: '',
              },
            }
          });
          break;
        case 'HasAnyOf':
          setCond({
            ...cond,
            value: {
              MultiFilter: {
                HasAnyOf: [],
              },
            }
          });
          break;
        case 'HasAllOf':
          setCond({
            ...cond,
            value: {
              MultiFilter: {
                HasAllOf: [],
              },
            }
          });
          break;
        case 'HasNoneOf':
          setCond({
            ...cond,
            value: {
              MultiFilter: {
                HasNoneOf: [],
              },
            }
          });
          break;
        case 'IsEmpty':
          setCond({
            ...cond,
            value: {
              MultiFilter: {
                IsEmpty: [],
              },
            }
          });
          break;
        case 'IsNotEmpty':
          setCond({
            ...cond,
            value: {
              MultiFilter: {
                IsNotEmpty: [],
              },
            }
          });
          break;
      }
    }
  };

  const selectFilterData = (fn: (a: any) => any) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> |
        SelectChangeEvent<string> | FakeElement
    ) => {
      const v = e.target.value;
      const prop = keys(cond?.value || {})[0];
      if (prop === 'NumberFilter') {
        const value = keys(cond.value.NumberFilter)[0];
        switch (value) {
          case 'EqualTo':
            setCond({
              ...cond,
              value: {
                NumberFilter: {
                  EqualTo: Number(v),
                },
              }
            });
            break;
          case 'LessThan':
            setCond({
              ...cond,
              value: {
                NumberFilter: {
                  LessThan: Number(v),
                },
              }
            });
            break;
          case 'GreaterThan':
            setCond({
              ...cond,
              value: {
                NumberFilter: {
                  GreaterThan: Number(v),
                },
              }
            });
            break;
          case 'LessThanOrEqualTo':
            setCond({
              ...cond,
              value: {
                NumberFilter: {
                  LessThanOrEqualTo: Number(v),
                },
              }
            });
            break;
          case 'GreaterThanOrEqualTo':
            setCond({
              ...cond,
              value: {
                NumberFilter: {
                  GreaterThanOrEqualTo: Number(v),
                },
              }
            });
            break;
          case 'IsBetween':
            setCond({
              ...cond,
              value: {
                NumberFilter: {
                  IsBetween: fn(Number(v)),
                },
              }
            });
            break;
        }
      } else if (prop === 'TextFilter') {
        const value: keyof TextFilter = keys(cond.value.TextFilter)[0];
        switch (value) {
          case 'EqualTo':
            setCond({
              ...cond,
              value: {
                TextFilter: {
                  EqualTo: v,
                },
              }
            });
            break;
          case 'NotEqualTo':
            setCond({
              ...cond,
              value: {
                TextFilter: {
                  NotEqualTo: v,
                },
              }
            });
            break;
          case 'Contains':
            setCond({
              ...cond,
              value: {
                TextFilter: {
                  Contains: v,
                },
              }
            });
            break;
          case 'IsOneOf':
            setCond({
              ...cond,
              value: {
                TextFilter: {
                  IsOneOf: fn(v),
                },
              }
            });
            break;
          case 'IsEmpty':
            setCond({
              ...cond,
              value: {
                TextFilter: {
                  IsEmpty: [] as [],
                },
              }
            });
            break;
          case 'IsNotEmpty':
            setCond({
              ...cond,
              value: {
                TextFilter: {
                  IsNotEmpty: [] as [],
                },
              }
            });
            break;
        }
      } else if (prop === 'BoolFilter') {
        setCond({
          ...cond,
          value: {
            BoolFilter: v === 'True',
          }
        });
      } else if (prop === 'DateFilter') {
        const value: keyof DateFilter = keys(cond.value.DateFilter)[0];
        switch (value) {
          case 'EqualTo':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  EqualTo: v,
                },
              }
            });
            break;
          case 'NotEqualTo':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  NotEqualTo: v,
                },
              }
            });
            break;
          case 'IsBefore':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  IsBefore: v,
                },
              }
            });
            break;
          case 'IsAfter':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  IsAfter: v,
                },
              }
            });
            break;
          case 'IsBetween':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  IsBetween: fn(v),
                },
              }
            });
            break;
          case 'IsEmpty':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  IsEmpty: [] as [],
                },
              }
            });
            break;
          case 'IsNotEmpty':
            setCond({
              ...cond,
              value: {
                DateFilter: {
                  IsNotEmpty: [] as [],
                },
              }
            });
            break;
        }
      } else if (prop === 'MultiFilter') {
        const value: keyof MultiFilter = keys(cond.value.MultiFilter)[0];
        switch (value) {
          case 'EqualTo':
            setCond({
              ...cond,
              value: {
                MultiFilter: {
                  EqualTo: v,
                },
              }
            });
            break;
          case 'HasAnyOf':
            setCond({
              ...cond,
              value: {
                MultiFilter: {
                  HasAnyOf: fn(v),
                },
              }
            });
            break;
          case 'HasAllOf':
            setCond({
              ...cond,
              value: {
                MultiFilter: {
                  HasAllOf: fn(v),
                },
              }
            });
            break;
          case 'HasNoneOf':
            setCond({
              ...cond,
              value: {
                MultiFilter: {
                  HasNoneOf: fn(v),
                },
              }
            });
            break;
          case 'IsEmpty':
            setCond({
              ...cond,
              value: {
                MultiFilter: {
                  IsEmpty: [] as [],
                },
              }
            });
            break;
          case 'IsNotEmpty':
            setCond({
              ...cond,
              value: {
                MultiFilter: {
                  IsNotEmpty: [] as [],
                },
              }
            });
            break;
        }
      }
    };

  const selectTarget = React.useCallback((targetType: keyof Target, id: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        switch (targetType) {
          case 'Page':
            setCond({
              ...cond,
              target: append({ Page: id }, cond.target),
            });
            break;
          case 'Section':
            setCond({
              ...cond,
              target: append({ Section: id }, cond.target),
            });
            break;
          case 'Question':
            setCond({
              ...cond,
              target: append({ Question: id }, cond.target),
            });
            break;
        }
      } else {
        const ind = cond.target.findIndex((t) => t[targetType] === id);
        if (ind !== null) {
          setCond({
            ...cond,
            target: remove(ind, 1, cond.target),
          });
        }
      }
    }, [cond]);

  const saveConditional = () => {
    if (has('DateFilter', cond.value) && cond.value.DateFilter.IsBetween) {
      const formatted = {
        ...cond,
        value: {
          DateFilter: {
            IsBetween: cond.value.DateFilter.IsBetween.sort((t0, t1) =>
              (t0.years - t1.years) + (t0.months - t1.months) + (t0.days - t1.days)),
          },
        },
      };
      dispatch(ctx.setConditional(formatted, index));
    } else {
      dispatch(ctx.setConditional(cond, index));
    }
    setState('LIST');
    setCond(null);
    setIndex(null);
  };

  const removeConditional = (idx: number) => {
    dispatch(ctx.removeConditional(idx));
  };

  const checkForEmpty = () => {
    return !cond.questionIndex || isEmpty(cond.target) || !values(cond.value)[0];
  };

  const changeDirection = (idx: number) => (s: string) => {
    const key = keys(cond.value.DateFilter)[0];
    const hidden = s === 'The last' ? -1 : 1;
    if (idx !== null) {
      const orig: TimeUnit[] = cond.value.DateFilter[key as keyof DateFilter] as unknown as TimeUnit[];
      const opp: TimeUnit = orig[idx] as TimeUnit;
      Object.keys(orig[idx]).forEach((key: keyof TimeUnit) => {
        opp[key] = -1 * opp[key];
      });
      setCond({
        ...cond,
        value: {
          DateFilter: {
            [key]: update(
              idx, {
                ...opp,
                hidden,
              },
              orig
            ),
          },
        },
      });
    } else {
      const orig: TimeUnit = cond.value.DateFilter[key as keyof DateFilter] as unknown as TimeUnit;
      const opp: TimeUnit = orig as TimeUnit;
      Object.keys(orig).forEach((key: keyof TimeUnit) => {
        opp[key] = -1 * opp[key];
      });
      setCond({
        ...cond,
        value: {
          DateFilter: {
            [key]: {
              ...opp,
              hidden,
            },
          },
        },
      });
    }
  };

  const openDeleteModal = (idx: number) => {
    setDeleteModal(idx);
  };

  const smaller = useMemo(() => {
    if (cond)
      return (
        has('BoolFilter', cond.value) ||
        has('DateFilter', cond.value) ||
        has('NumberFilter', cond.value)
      );
    return false;
  }, [cond?.value]);

  return (
    <Dialog {...props}
      maxWidth='md'
      fullWidth
      onClose={() => {
        setCond(null);
        setIndex(null);
        setState('LIST');
        props.onClose();
      }}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.title}>
          <ConditionalIcon />
          Conditional Logic
          <HelpButton pageId='conditionals' pageTitle='ConditionalsModal' />
        </div>
        <div className={classes.title}>
          <Button
            className={
              classes.newButton + ' ' +
              `sked-test-form-builder-conditional-${state === 'LIST' ? 'new-logic' : 'save'}-button`
            }
            variant="contained"
            color="primary"
            disabled={state === 'LIST' ? false : checkForEmpty()}
            onClick={state === 'LIST' ? () => editConditional(null, null) : () => saveConditional()}
          >
            {state === 'LIST' ? 'Add New Logic' : 'Save'}
          </Button>
          <IconButton
            className={
              classes.newButton + ' ' + 'sked-test-form-builder-conditional-close-button'
            }
            style={{ marginLeft: '5px' }}
            onClick={() => {
              setState('LIST');
              setIndex(null);
              setCond(null);
              if (state === 'LIST') {
                props.onClose();
              }
            }}>
            <ClearIcon sx={{ fontSize: '18px' }}/>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Modal
          open={deleteModal !== null}
          onClose={() => setDeleteModal(null)}
          title='Delete Conditional Logic?'
          maxWidth={500}
          className='sked-test-form-builder-conditional-delete-modal'
          buttons={[
            <HeaderButton
              title='Yes, Delete'
              onClick={() => {
                removeConditional(deleteModal);
                setDeleteModal(null);
              }}
              color="danger"
              className='sked-test-form-builder-action-conditional-yes-delete'
            />
          ]}
        >
          This will not be saved until the whole form is saved, but this cannot be undone except for discarding all form changes since last save.
        </Modal>
        {state === 'LIST' && isEmpt &&
          <div style={{ marginTop: 10 }}>
            No conditional logic has been set in this form.
          </div>}
        {state === 'LIST' && !isEmpt &&
          (conditionals || []).map((cd, idx) => {
            const {
              questionIndex, value, target, conditionalId
            } = cd;
            const comp = getComparitor(value);
            let valued = comp.value;
            if (typeof valued === 'boolean') {
              valued = trueOrFalse(valued);
            } else if (type(valued) === 'Array') {
              valued = (valued as number[]).join(', ');
            }
            const pages = target
              .filter((t: Target) => has('Page', t))
              .reduce((acc: string, cur: Target) => {
                if (acc === '') {
                  return cur.Page;
                }
                return acc + ', ' + cur.Page;
              }, '');
            const sections = target
              .filter((t: Target) => has('Section', t))
              .reduce((acc: string, cur: Target) => {
                if (acc === '') {
                  return cur.Section;
                }
                return acc + ', ' + cur.Section;
              }, '');
            const qs = target
              .filter((t: Target) => has('Question', t))
              .reduce((acc: string, cur: Target) => {
                if (acc === '') {
                  return cur.Question;
                }
                return acc + ', ' + cur.Question;
              }, '');
            return (
              <Paper key={conditionalId} className={classes.condItems}>
                <div>
                IF answer to question #{questionIndex} {last(comp.compare) !== 'S' || includes('HAS', comp.compare) && 'is '}
                  {comp.compare}{includes('EMPTY', comp.compare) && ', '}
                  {!includes('EMPTY', comp.compare) &&
                    <div style={{ display: 'inline' }}>
                    &nbsp;
                      <div style={{ display: 'inline', marginRight: '1px' }}>[</div>
                      {valued}
                      <div style={{ display: 'inline', marginLeft: '1px' }}>]</div>,&nbsp;
                    </div>}
                THEN show
                  {!isEmpty(pages) &&
                    <div style={{ display: 'inline' }}>
                      &nbsp;page(s)&nbsp;
                      <div style={{ display: 'inline', marginRight: '1px' }}>[</div>
                      {pages}
                      <div style={{ display: 'inline', marginLeft: '1px' }}>]</div>
                    </div>}
                  {!isEmpty(pages) && !isEmpty(sections) && ','}
                  {!isEmpty(sections) &&
                    <div style={{ display: 'inline' }}>
                      &nbsp;section(s)&nbsp;
                      <div style={{ display: 'inline', marginRight: '1px' }}>[</div>
                      {sections}
                      <div style={{ display: 'inline', marginLeft: '1px' }}>]</div>
                    </div>}
                  {(!isEmpty(pages) || !isEmpty(sections)) && !isEmpty(qs) && ','}
                  {!isEmpty(qs) &&
                    <div style={{ display: 'inline' }}>
                      &nbsp;question(s)&nbsp;
                      <div style={{ display: 'inline', marginRight: '1px' }}>[</div>
                      {qs}
                      <div style={{ display: 'inline', marginLeft: '1px' }}>]</div>
                    </div>}
                </div>
                <div className={classes.condItemButtons}>
                  <IconButton onClick={() => editConditional(cd, idx)}>
                    <PencilIcon style={{ fontSize: '16px', color: '#474a54' }} />
                  </IconButton>
                  <IconButton onClick={() => openDeleteModal(idx)}>
                    <DeleteIcon className={classes.trash} />
                  </IconButton>
                </div>
              </Paper>
            );
          })}
        {state === 'EDIT' &&
          <div>
            <p><b style={{ fontSize: 14 }}>IF</b> answer to this question:</p>
            <ThemeProvider theme={autocompleteTheme('questions')}>
              <Autocomplete
                disablePortal
                id="autocomplete-questions"
                size='small'
                options={drop(1, questions)}
                value={questions[cond.questionIndex]}
                getOptionDisabled={(question: SimpleWidget | ComplexWidget) => {
                  return (question.type === 'ComplexWidget' ||
                  Boolean(cond?.target.find((t) => {
                    if (t.Question) {
                      return t.Question === Number(drop(1, question.label.split(' ')[0]));
                    }
                    return false;
                  })));
                }}
                onChange={selectQuestion}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Pick a Question"
                    />
                  );
                }}
              />
            </ThemeProvider>
            <p><b>Condition</b></p>
            <Select
              variant="outlined"
              value={value}
              renderValue={(v) => {
                const filt = questionFilters.find((f) => {
                  return v === f?.value || v === f?.optValue;
                });
                return filt?.label;
              }}
              className={classes.questionSelect}
              style={ smaller ? { width: '360px' } : {}}
              onChange={selectValue}>
              {questionFilters.map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
            {smaller && <br />}
            {has('BoolFilter', cond.value) &&
             <Select
               variant="outlined"
               value={cond.value.BoolFilter ? 'True' : 'False'}
               className={classes.questionSelect}
               style={{ width: '360px' }}
               onChange={selectFilterData(null)}>
               {['True', 'False'].map((value) => {
                 return (
                   <MenuItem key={value} value={value}>
                     {value}
                   </MenuItem>
                 );
               })}
             </Select>}
            {has('TextFilter', cond.value) &&
             (has('EqualTo', cond.value.TextFilter) ||
              has('NotEqualTo', cond.value.TextFilter) ||
              has('IsOneOf', cond.value.TextFilter)) &&
              (Question &&
               (Question.questionType.type === 'Radio' ||
                Question.questionType.type === 'Dropdown')
              ) &&
            <ThemeProvider theme={autocompleteTheme('options')}>
              <Autocomplete
                disablePortal
                id="autocomplete-questions"
                size='small'
                multiple={has('IsOneOf', cond.value.TextFilter)}
                options={Question.questionType.options}
                value={values(cond.value.TextFilter)[0]}
                getOptionLabel={(opt: string) => opt}
                disableCloseOnSelect={
                  has('IsOneOf', cond.value.TextFilter)
                }
                ChipProps={{
                  deleteIcon: (
                    <ClearIcon style={{ color: '#008BCF' }} />
                  ),
                }}
                onChange={(e: React.SyntheticEvent<Element, Event>, str: string | string[]) => {
                  if (has('IsOneOf', cond.value.TextFilter)) {
                    selectFilterData(identity)({ target: { value: str } } as FakeElement);
                  } else {
                    selectFilterData(null)({ target: { value: str } } as FakeElement);
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Anything you want"
                    />
                  );
                }}
              />
            </ThemeProvider>}
            {has('TextFilter', cond.value) &&
             !has('IsEmpty', cond.value.TextFilter) &&
             !has('IsNotEmpty', cond.value.TextFilter) &&
             !has('IsOneOf', cond.value.TextFilter) &&
             (!Question ||
              ((Question.questionType.type === 'Radio' ||
                Question.questionType.type === 'Dropdown') &&
               has('Contains', cond.value.TextFilter))) &&
               <TextField
                 variant="outlined"
                 value={values(cond.value.TextFilter)[0]}
                 className={classes.questionSelect}
                 placeholder='Anything you wish'
                 InputProps={{
                   className: classes.questionSelect,
                 }}
                 onChange={selectFilterData(null)}
               />}
            {has('TextFilter', cond.value) &&
             !has('IsEmpty', cond.value.TextFilter) &&
             !has('IsNotEmpty', cond.value.TextFilter) &&
             has('IsOneOf', cond.value.TextFilter) &&
             !Question &&
              <ThemeProvider theme={autocompleteTheme('options')}>
                <Autocomplete
                  disablePortal
                  id="autocomplete-questions"
                  size='small'
                  multiple
                  options={[]}
                  freeSolo
                  value={values(cond.value.TextFilter)[0] as string[]}
                  getOptionLabel={(opt: string) => opt}
                  disableCloseOnSelect
                  sx={{
                    '.MuiInputBase-root': {
                      height: 'fit-content',
                      rowGap: '4px',
                    },
                  }}
                  ChipProps={{
                    deleteIcon: (
                      <ClearIcon style={{ color: '#008BCF' }} />
                    ),
                  }}
                  onChange={(e: React.SyntheticEvent<Element, Event>, str: string[]) => {
                    selectFilterData(identity)({ target: { value: str } } as FakeElement);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Type something and press Enter to add it."
                      />
                    );
                  }}
                />
              </ThemeProvider>}
            {has('NumberFilter', cond.value) &&
              !has('IsBetween', cond.value.NumberFilter) &&
               <TextField
                 variant="outlined"
                 type='number'
                 value={values(cond.value.NumberFilter)[0]}
                 className={classes.questionSelect}
                 style={{ width: '360px' }}
                 InputProps={{
                   className: classes.questionSelect,
                   sx: numberInputSx,
                 }}
                 onChange={selectFilterData(null)}
               />}
            {has('NumberFilter', cond.value) &&
              has('IsBetween', cond.value.NumberFilter) &&
               <>
                 <TextField
                   variant="outlined"
                   type='number'
                   value={cond.value.NumberFilter.IsBetween[0]}
                   className={classes.questionSelect}
                   style={{ width: '360px' }}
                   InputProps={{
                     className: classes.questionSelect,
                     sx: numberInputSx,
                   }}
                   onChange={selectFilterData((v: number) => {
                     return update(0, v, cond.value.NumberFilter.IsBetween);
                   })}
                 />
                 <br />
               </>}
            {has('NumberFilter', cond.value) &&
              has('IsBetween', cond.value.NumberFilter) &&
               <TextField
                 variant="outlined"
                 type='number'
                 value={cond.value.NumberFilter.IsBetween[1]}
                 className={classes.questionSelect}
                 style={{ width: '360px' }}
                 InputProps={{
                   className: classes.questionSelect,
                   sx: numberInputSx,
                 }}
                 onChange={selectFilterData((v: number) => {
                   return update(1, v, cond.value.NumberFilter.IsBetween);
                 })}
               />}
            {has('DateFilter', cond.value) &&
             keys(cond.value.DateFilter)[0] !== 'IsEmpty' &&
             keys(cond.value.DateFilter)[0] !== 'IsNotEmpty' &&
             (keys(cond.value.DateFilter)[0] === 'EqualTo' ||
              keys(cond.value.DateFilter)[0] === 'NotEqualTo') &&
               <TextField
                 variant="outlined"
                 type='date'
                 value={values(cond.value.DateFilter)[0]}
                 className={classes.questionSelect}
                 style={{ width: '360px' }}
                 InputProps={{
                   className: classes.questionSelect,
                 }}
                 onChange={selectFilterData(null)}
               />}
            {has('DateFilter', cond.value) &&
             keys(cond.value.DateFilter)[0] !== 'IsEmpty' &&
             keys(cond.value.DateFilter)[0] !== 'IsNotEmpty' &&
             keys(cond.value.DateFilter)[0] !== 'EqualTo' &&
             keys(cond.value.DateFilter)[0] !== 'NotEqualTo' &&
             !has('IsBetween', cond.value.DateFilter) &&
               <>
                 <DateTimeUnitFields
                   value={values(cond.value.DateFilter)[0] as unknown as TimeUnit}
                   onChange={(t: TimeUnit) => {
                     selectFilterData(null)({ target: { value: t } });
                   }}
                   onDirectionChange={changeDirection(null)}
                 />
                 <br/>
               </>}
            {has('DateFilter', cond.value) &&
             has('IsBetween', cond.value.DateFilter) &&
               <>
                 {[0, 1].map((n) => {
                   return (
                     <>
                       <DateTimeUnitFields
                         value={cond.value.DateFilter.IsBetween[n]}
                         onChange={(t: TimeUnit) => {
                           selectFilterData((v: TimeUnit) => {
                             return update(n, v, cond.value.DateFilter.IsBetween);
                           })({ target: { value: t } });
                         }}
                         onDirectionChange={changeDirection(n)}
                       />
                       {n === 0 &&
                        cond.value.DateFilter.IsBetween[n]?.hidden !== 0 &&
                         <>
                           <br/>
                           <div style={{ height: '6px' }}/>
                         </>}
                     </>
                   );
                 })}
                 <br/>
               </>}
            {has('MultiFilter', cond.value) &&
             !has('IsEmpty', cond.value.MultiFilter) &&
             !has('IsNotEmpty', cond.value.MultiFilter) &&
              (Question &&
               Question.questionType.type === 'Checkbox'
              ) &&
            <ThemeProvider theme={autocompleteTheme('options')}>
              <Autocomplete
                disablePortal
                id="autocomplete-questions"
                size='small'
                multiple={includes('Has', keys(cond.value.MultiFilter)[0])}
                options={Question.questionType.options}
                value={values(cond.value.MultiFilter)[0]}
                getOptionLabel={(opt: string) => opt}
                disableCloseOnSelect={includes('Has', keys(cond.value.MultiFilter)[0])}
                ChipProps={{
                  deleteIcon: (
                    <ClearIcon style={{ color: '#008BCF' }} />
                  ),
                }}
                onChange={(e: React.SyntheticEvent<Element, Event>, str: string | string[]) => {
                  if (has('EqualTo', cond.value.MultiFilter)) {
                    selectFilterData(null)({ target: { value: str } } as FakeElement);
                  } else {
                    selectFilterData(identity)({ target: { value: str } } as FakeElement);
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Anything you want"
                    />
                  );
                }}
              />
            </ThemeProvider>}
            {(has('DateFilter', cond.value) &&
             (has('IsBefore', cond.value.DateFilter) ||
              has('IsAfter', cond.value.DateFilter)) ||
              has('IsBetween', cond.value.DateFilter)) &&
              <EasyAge
                defaults={cond.value.DateFilter}
                between={has('IsBetween', cond.value.DateFilter)}
                reset={resetEasy}
                unreset={() => setResetEasy(false)}
                onChange={(prop: keyof IDateFilter, t: TimeUnit) => {
                  setCond({
                    ...cond,
                    value: {
                      DateFilter: {
                        [prop]: t
                      },
                    },
                  });
                }}
                betweenChange={(t: TimeUnit[]) => {
                  setCond({
                    ...cond,
                    value: {
                      DateFilter: {
                        IsBetween: t,
                      },
                    },
                  });
                }}
              />}
            <p><b>Show</b></p>
            <div>
              <TabulatedList
                targets={cond.target}
                questionIndex={cond.questionIndex}
                conditionals={conditionals}
                onChange={selectTarget}
              />
            </div>
          </div>}
      </DialogContent>
    </Dialog>
  );
};

export default ConditionalLogicDialog;
