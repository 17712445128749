import * as at from '../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  professionals: [],
  schedules: [],
  original: [],
  officeHours: {},
  error: {},
  success: false,
  hours: [],
  locationsHours: [],
  specialHours: [],
  editId: 0,
  editHours: false,
  editNumberOfAppointments: 0,
  editSlotLength: 0,
  updatedNumberOfAppointments: 0,
  affected: [],
  hoursTab: 'hours',
};

export default (skedtype = init, action) => {
  switch (action.type) {
    case at.SKEDTYPE_PATCH_SCHEDULE: {
      const index = skedtype.schedules.findIndex(R.propEq('id', action.data.id));
      const lens = R.compose(
        R.lensProp('schedules'),
        R.lensIndex(index)
      );
      return R.over(lens, R.mergeLeft(action.data), skedtype);
    }
    case at.SKEDTYPE_PATCH:
      return R.merge(skedtype, action.data);
    case at.SKEDTYPE_REMOTE_GET_TYPES:
      if (action.state === 'REQUEST') {
        return R.merge(skedtype, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(skedtype, {
          schedules: action.data.schedules,
          professionals: action.data.professionals,
          original: action.data.original,
          officeHours: action.data.officeHours,
          locationsHours: action.data.locationsHours,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(skedtype, {
          busy: false,
          error: action.data,
        });
      }
      return skedtype;
    case at.SKEDTYPE_REMOTE_SAVE_SCHEDULE: {
      if (action.state === 'REQUEST') {
        return R.merge(skedtype, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(skedtype, {
          busy: false,
          schedules: action.data.schedules,
          professionals: action.data.professionals,
          original: action.data.original,
          officeHours: action.data.officeHours,
          success: true,
          updatedNumberOfAppointments: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(skedtype, {
          busy: false,
          success: false,
        });
      }
      return skedtype;
    }
    case at.SKEDTYPE_REMOTE_GET_HOURS: {
      if (action.state === 'REQUEST') {
        return R.merge(skedtype, {
          gettingHours: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(skedtype, {
          gettingHours: false,
          hours: action.data.hours,
          specialHours: action.data.specialHours,
          editId: action.data.id,
          editNumberOfAppointments: action.data.numberOfAppointments,
          editSlotLength: action.data.slotLength,
          editHours: true,
          affected: action.data.affected,
          hoursTab: action.data.tab,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(skedtype, {
          gettingHours: false,
        });
      }
      return skedtype;
    }
    case at.SKEDTYPE_REMOTE_POST_HOURS: {
      if (action.state === 'REQUEST') {
        return R.merge(skedtype, {
          gettingHours: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(skedtype, {
          gettingHours: false,
          editHours: false,
          success: true,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(skedtype, {
          gettingHours: false,
        });
      }
      return skedtype;
    }
    case at.SKEDTYPE_MOVE: {
      const source = R.pipe(
        R.nth(action.data.sourceIndex),
        R.evolve({
          professionals: (pros) => {
            return R.pipe(
              R.map((pro) => {
                const foundTypesList = [];
                action.data.selecteds.forEach(type => {
                  if (pro.id === type.professionalId) {
                    const foundType = R.find(({ id }) => id === type.id)(pro.types);
                    if (foundType) {
                      foundTypesList.push(foundType);
                    }
                  }
                });
                if (foundTypesList.length === 0) {
                  return pro;
                }
                const newTypes = pro.types.filter(proItem => !foundTypesList.find(item => item.id === proItem.id));
                if (newTypes.length === 0) {
                  return [];
                }
                return R.merge(pro, {
                  types: newTypes
                });

              }),
              R.flatten
            )(pros);
          }
        })
      )(skedtype.schedules);
      const dest = R.pipe(
        R.nth(action.data.destIndex),
        R.evolve({
          professionals: (pros) => {
            let newProfessionals = pros;
            action.data.selecteds.forEach(type => {
              const hasPro = R.find(R.propEq('id', type.professionalId))(newProfessionals);
              if (hasPro) {
                newProfessionals = R.map((pro) => {
                  if (pro.id === type.professionalId) {
                    return R.merge(pro, { types: R.concat(pro.types, [type]) });
                  }
                  return pro;
                })(newProfessionals);
              } else {
                const pro = R.find(R.propEq('id', type.professionalId))(skedtype.professionals);
                const withType = R.merge(pro, { types: [type] });
                newProfessionals = R.concat(newProfessionals, [withType]);
              }
            });
            return newProfessionals;
          }
        })
      )(skedtype.schedules);
      const schedules = R.pipe(
        R.update(action.data.sourceIndex, source),
        R.update(action.data.destIndex, dest)
      )(skedtype.schedules);
      return R.merge(skedtype, { schedules });
    }
    case at.SKEDTYPE_ADD: {
      const skeds = R.insert(1, {
        name: action.data.name,
        slotLength: 15,
        numberOfAppointments: 15,
        professionals: [],
      }, skedtype.schedules);
      return R.merge(skedtype, { schedules: skeds });
    }
    case at.SKEDTYPE_SORT: {
      const skeds = R.move(action.data.index, action.data.to, skedtype.schedules);
      return R.merge(skedtype, { schedules: skeds });
    }
    case at.SKEDTYPE_REMOVE: {
      const professionals = R.pipe(
        R.nth(action.data.index),
        R.prop('professionals')
      )(skedtype.schedules);
      const findNewPros = (pros) =>
        R.filter((pro) => !R.any(R.propEq('id', pro.id))(pros))(professionals);
      const available = R.pipe(
        R.nth(0),
        R.evolve({
          professionals: (pros) => R.pipe(
            R.map((pro) => {
              const hasPro = R.find(R.propEq('id', pro.id))(professionals);
              if (hasPro) {
                return R.merge(pro, { types: R.concat(pro.types, hasPro.types) });
              }
              return pro;
            }),
            R.concat(findNewPros(pros))
          )(pros)
        })
      )(skedtype.schedules);
      const schedules = R.pipe(
        R.update(0, available),
        R.remove(action.data.index, 1)
      )(skedtype.schedules);
      return R.merge(skedtype, { schedules });
    }
    case at.SKEDTYPE_REMOTE_DELETE_SKED: {
      if (action.state === 'REQUEST') {
        return R.merge(skedtype, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(skedtype, {
          busy: false,
          schedules: action.data.schedules,
          professionals: action.data.professionals,
          original: action.data.original,
          success: true,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(skedtype, {
          busy: false,
          success: false,
        });
      }
      return skedtype;
    }
    case at.SKEDTYPE_RESET: {
      return R.merge(skedtype, { schedules: skedtype.original, deleted: [] });
    }
    case at.SKEDTYPE_SKED_PATCH: {
      return R.evolve({
        schedules: R.adjust(
          action.data.index,
          R.merge(R.__, { [action.data.prop]: action.data.value })),
        updatedNumberOfAppointments: () =>
          action.data.prop === 'numberOfAppointments' && action.data.index,
      }, skedtype);
    }
    default:
      return skedtype;
  }
};
