import React from 'react';
import {
  isNil, propOr, pathOr, path, sortBy, has,
} from 'ramda';
import { LocalDate, ZonedDateTime } from '@js-joda/core';
import {
  Grid, Checkbox, Typography, Tooltip, Chip
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ReplyIcon from '@mui/icons-material/Reply';
import MmsIcon from '@mui/icons-material/Mms';
import AroundTheClockIcon from '../../../../icons/AroundTheClock.icon';
import {
  tzParseFormat, tzParse, untilVerbose, now,
} from '../../../../services/joda';
import { caseType } from '../../../../services/utilities';
import { usePromise } from '../../../../services/promise.hook';
import api from '../../../../services/api';
import Avatar from '../../../../components/Avatar/Avatar.component';
import { Client } from '../../../Clients/clients.types';
import { useStyles } from './inboxListItem.styles';
import { Message } from '../../message-types';

const SmChip = withStyles({
  label: {
    paddingRight: 7,
    color: '#7C7C80',
  },
})(Chip);


const ScheduledTag = ({ time }: {time: string}) => (
  <SmChip
    icon={<AroundTheClockIcon />}
    label={time}
    sx={{
      height: 17,
      margin: '0px 3px',
    }}
  />
);

type InboxItemProps = {
  message: Message;
  scheduled: Message[];
  sentScheduled: Message[];
  tz: string;
  selectMessage: (value: boolean) => void;
  goto: (client: Client) => void;
}

const getMessage = ({ id }: { id: number }) => api.get(`sentmsg/${id}`);

const sentToClient = (clientId: number, updated?: string) => (msg: Message) => {
  if (has('OneTime', msg.messageType)) {
    return msg.messageType.OneTime.receivers?.Clients.includes(clientId)
      &&
      (updated ? msg.messageType.OneTime.scheduledFor > updated : true);
  }
  return false;
};

const InboxItem = ({
  message, tz, selectMessage, goto, scheduled, sentScheduled,
}: InboxItemProps) => {
  const classes = useStyles();
  const messageState = usePromise(getMessage, null);

  const getName = (client: Client) => {
    const name = `${client?.firstName} ${client?.lastName}`;
    if (name.trim().length === 0) {
      return client.metadata.caseType;
    }
    return name;
  };

  const getDateTime = (date: string) => {
    if (date && tz) {
      const msgDate = ZonedDateTime.parse(date)?.toLocalDate();
      if (LocalDate.now().equals(msgDate)) {
        return tzParseFormat(date, tz, 'h:mm a');
      }
      return tzParseFormat(date, tz, 'MM/dd/yyyy');
    }
    return '';
  };

  React.useEffect(() => {
    if (message.message === '' || isNil(message.message)) {
      messageState.invoke({ id: message.msgId });
    }
  }, [message]);

  const hasMMS = React.useMemo(() => {
    return pathOr(false, ['data', 'smsData', 'phi', 0], messageState)
      ||
      pathOr(false, ['data', 'smsData', 'attachmentIds', 0], messageState);
  }, [messageState.data]);

  const mscheduled = React.useMemo(() => {
    const clientsScheduled = scheduled.filter(sentToClient(message.clientId));
    const pt = ['messageType', 'OneTime', 'scheduledFor'];
    const ordered = sortBy(
      path(pt),
      clientsScheduled || []
    );
    const dt = path(pt, ordered[0]);
    if (dt) {
      const tzdt = tzParse(dt, tz);
      const rn = now('tz', tz);
      return untilVerbose(rn, tzdt, true);
    }
    return null;
  }, [scheduled]);

  const msentScheduled = React.useMemo(() => {
    const clientsScheduled = sentScheduled.filter(sentToClient(message.clientId, message.updatedAt));
    return clientsScheduled[0]?.sms?.body;
  }, [sentScheduled]);

  const unread = React.useMemo(() => {
    return (message.unread || ((message?.unreadCount || 0) > 0));
  }, [message]);

  return (
    <Grid
      className={`${classes.root} ${message.selected ? classes.selected : ''}`}
      display="flex"
      alignItems="center"
      paddingY={1}
      paddingLeft={0}
      paddingRight="11px"
      marginBottom={0}
    >
      <Checkbox
        className={classes.checkbox}
        checked={Boolean(message.selected)}
        onChange={(event) => selectMessage(event.target.checked)}
      />
      <Avatar name={getName(message.client)} className={classes.avatar} isLead={message.client?.isLead} />
      <Grid flex={1} className={classes.textContainer} onClick={() => goto(message.client)}>
        <Grid display="flex" alignItems="center">
          <Typography className={classes.title} style={{
            fontWeight: unread ? 600 : 'unset',
          }}>
            {message.from === 'Off' &&
              <Tooltip title="You sent this message" placement="top" arrow>
                <ReplyIcon
                  className={classes.replyIcon}
                  style={{ color: unread ? 'unset' : '#7C7C80' }}
                />
              </Tooltip>}
            {propOr(null, 'firstName')(message.client) + ' '}
            {propOr(null, 'lastName')(message.client) + ' '}
            {caseType(message.client)}
          </Typography>
          {unread &&
            <span className={classes.new} />}
        </Grid>
        {hasMMS && !messageState.loading ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {mscheduled && <ScheduledTag time={mscheduled}/>}
            <MmsIcon style={{ fontSize: '14px', opacity: 0.8, marginRight: '5px' }} />
            <i className={classes.message}>Multimedia Message</i>
          </div>
          : (
            <Typography className={classes.message}>
              {mscheduled && <ScheduledTag time={mscheduled}/>}
              {msentScheduled || message.message}
            </Typography>
          )
        }
      </Grid>
      <Typography marginLeft={0.5} className={classes.date} onClick={() => goto(message.client)}>
        {getDateTime(message.updatedAt)}
      </Typography>
    </Grid>
  );
};

export default InboxItem;
