import React, { useState } from 'react';
import {
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';

import Modal from '../Modal/Modal.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import RoundExclamationIcon from '../../icons/RoundExclamation.icon';
import { GlobalSettingsDialog } from '../../routes/IntakeForms/intake-list.page';

import { useStyles } from './lockModal.styles';

type Props = {
  open: boolean;
  close: () => void;
  confirm: () => void;
}

const LockModal = ({ open, close, confirm }: Props) => {
  const classes = useStyles();
  const [openPin, setOpenPin] = useState(false);

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={'Are you sure you want to continue?'}
      maxWidth={600}
      className='sked-test-covert-lead-modal'
      buttons={[
        <HeaderButton
          title={'Continue'}
          color='primary'
          onClick={confirm}
          className='sked-test-lock-modal-confirm'
        />
      ]}
    >
      <Grid>
        <DialogContentText>
          SKED will be locked while filling out the form submission and will require your pin to log back in.
        </DialogContentText>
        <Grid className={classes.warning}>
          <Grid display="flex" alignItems="center" marginBottom={1}>
            <RoundExclamationIcon color='#DE3B45' />
            <Typography className={classes.title}>
              <span>Warning: </span>Don’t get locked out!
            </Typography>
          </Grid>
          <Typography className={classes.text}>
          View or edit your pin by going to Form Builder page {'>'} Settings gear icon at the top right. Pins are unique to each SKED user. 
          </Typography>
          <Grid marginTop={1}>
            <HeaderButton
              title='View Pin'
              borderSolid
              onClick={() => setOpenPin(true)}
              color='lightgray'
            />
          </Grid>
        </Grid>
      </Grid>
      <GlobalSettingsDialog open={openPin} onClose={() => setOpenPin(false)} />
    </Modal>
  );
};

export default LockModal;
