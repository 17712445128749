import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './messages-new.css';
import * as rawActions from './messages-new.actions';
import * as R from 'ramda';
import { resetMessages } from '../../messages.actions';
import CreateMessage from '../../../../components/CreateMessage/CreateMessage.component';

const MessagesNew = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const actions = bindActionCreators(rawActions, dispatch);
  const props = useSelector((state) => ({
    ...state.newMessage,
    office: R.pathOr({}, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    isCopied: R.pathOr(false, ['messages', 'state'])(state) === 'COPY',
  }));

  return (
    <CreateMessage
      {...props}
      messageType='OneTime'
      urlId={id}
      actions={actions}
      resetMessages={() => dispatch(resetMessages())}
      location={location}
    />
  );
};

export default MessagesNew;
