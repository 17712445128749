import React, {
  useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/PageHeader/PageHeader.component';
import * as R from 'ramda';
import {
  Paper as PaperRaw, TextField, Button, Tooltip,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { usePromise } from '../../../../services/promise.hook';
import * as at from '../../../../actionTypes';
import {
  getCardInfo, billingPatch, uploadCard, updateCustomerId,
  verifyDeposits,
} from './subscription.actions.js';
import { PopupTemplate } from '../../../../services/Popup.js';
import { Line } from 'react-chartjs-2';
import { now } from '../../../../services/joda.js';
import { useSelector } from '../../../../reducers';
import { successSnackbar, errorSnackbar, infoSnackbar } from '../../../../components/Snackbar/snackbar.actions';
import SparkLogo from '../../../../icons/SparkLogo.icon';
import { SPARK_FEATURE } from '../../../Login/login.reducer';
import Logo from '../../../../images/logo-blue.png';
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const Paper = withStyles({
  root: {
    padding: '20px'
  },
})(PaperRaw);

const displayMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];

const usageChart = (data, year) => {
  const yearData = R.propOr({}, year, data);
  const chartData = {
    labels: displayMonths,
    datasets: [
      {
        label: 'Credits',
        data: months.map((month) => {
          const noUsage = { usage: 0, amountPaid: 0 };
          return R.prop('usage', R.propOr(noUsage, month, yearData));
        }),
        backgroundColor: 'rgba(59,148,195,0.4)',
        tension: 0.5,
        fill: true,
      },
      {
        label: 'Payment (in dollars)',
        data: months.map((month) => {
          const noUsage = { usage: 0, amountPaid: 0 };
          const raw = R.prop('amountPaid', R.propOr(noUsage, month, yearData));
          return raw / 100;
        }),
        backgroundColor: 'rgba(180, 0, 0, 0.4)',
        tension: 0.5,
        fill: true,
      },
    ]
  };
  return (
    <div className="chart">
      <Line data={chartData} height={420} options={{
        maintainAspectRatio: false,
        animation: false,
      }} />
    </div>
  );
};

const CustomerId = ({ office, customerId, billingPatch }) => {
  const dispatch = useDispatch();
  const [id, setId] = useState(customerId || '');
  const saveState = usePromise(updateCustomerId, null);

  useEffect(() => setId(customerId || ''), [customerId]);

  const save = () => {
    saveState.invoke({ officeId: office.id, customerId: id.trim() }).then(() => {
      billingPatch({
        type: at.BILLING_PATCH,
        data: {
          customerId: id.trim(),
        },
      });
      dispatch(successSnackbar('Saved Customer ID!'));
    }).catch(() => {
      dispatch(errorSnackbar('Failed to Save Customer ID!'));
    });
  };

  return (
    <div>
      <h3 style={{ marginLeft: '0px' }}>Stripe Customer ID</h3>
      <TextField
        label='Customer ID'
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <br />
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <Button
          variant='contained'
          style={{ marginTop: '20px', }}
          disabled={saveState.loading || id.trim() === ''}
          onClick={save}
        >
          {saveState.loading ? 'Loading...' : 'Save'}
        </Button>
        {saveState.loading && <CircularProgress />}
        {saveState.errorMessage &&
          <p style={{ color: 'red' }}>{saveState.errorMessage}</p>}
      </div>
    </div>
  );
};

const SubscriptionTemplate = ({
  busy, plan, billingPatch, msgPlan, usage, earliestYear,
  upcoming, showLimit, showCredits, year, update, admin, office,
  customerId, sparkStripeUrl, isSpark, skedStripeUrl, paymentAction,
}) => {
  const dispatch = useDispatch();
  const [dep1, setDep1] = useState('0');
  const [dep2, setDep2] = useState('0');

  const verifyState = usePromise(verifyDeposits, null);

  const verifyDepositsLocal = () => {
    verifyState.invoke({ dep1, dep2, isSpark }).then((data) => {
      if (data === null) {
        dispatch(successSnackbar('Business Information Saved!'));
        return;
      }
      if (data === 'Verified') {
        dispatch(infoSnackbar('Bank Account Verified!'));
        return;
      }
    });
  };

  return (
    <>
      <Header title='Billing Information' pageId='billing-page' />
      <div
        style={{
          padding: '0px 10px 25px',
          overflowY: 'auto',
        }}>
        <PopupTemplate />
        {busy ?
          <div className="loader"></div>
          :
          <div>
            {(sparkStripeUrl || skedStripeUrl) &&
            <>
              {verifyState.data !== 'Verified' && paymentAction &&
                <Paper className="content-inner panel-margin" style={{
                  height: 'unset',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <h3 style={{ marginLeft: 0, marginBottom: 0 }}>
                    ACTION REQUIRED
                  </h3>
                  <p style={{ marginTop: 'unset' }}>
                    Please input the two micro deposit amounts that Stripe has deposited into the
                    bank account supplied during registration.
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                    <TextField
                      label='Deposit Amount #1'
                      value={dep1}
                      type="number"
                      onChange={(e) => setDep1(e.target.value)}
                      inputProps={{
                        step: 0.01,
                        min: 0,
                      }}
                    />
                    <TextField
                      label='Deposit Amount #2'
                      value={dep2}
                      type="number"
                      onChange={(e) => setDep2(e.target.value)}
                      inputProps={{
                        step: 0.01,
                        min: 0,
                      }}
                    />
                  </div>
                  <Button
                    variant='contained'
                    onClick={verifyDepositsLocal}
                    disabled={verifyState.loading}
                    style={{ width: 'min-content', marginTop: 10 }}
                  >
                    {verifyState.loading ? 'Loading...' : 'Verify'}
                  </Button>
                  {verifyState.data === 'NotVerified' &&
                  <p style={{ margin: 'unset', color: 'red' }}>
                    It appears that the amounts input don't match. Remember, you can't divide by zero!
                  </p>}
                  {verifyState.errorMessage &&
                  <p style={{ margin: 'unset', color: 'red' }}>
                    It appears that there has been an error trying to verify the transactions.
                    Please contact SKED support!
                  </p>}
                </Paper>}
              <Paper className="content-inner panel-margin" style={{
                height: 'unset',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  {sparkStripeUrl && isSpark && <SparkLogo style={{ height: '2em', width: 'fit-content' }} />}
                  {skedStripeUrl && !isSpark && <img src={Logo} style={{ height: '2em', }} />}
                  <h2 style={{ marginLeft: 7 }}>Billing</h2>
                </div>
                <p style={{ marginTop: 'unset' }}>
                  Manage your payment method, billing information, and current plan here. You can also see your invoice history.
                </p>
                <div style={{ display: 'flex', gap: 10 }}>
                  {sparkStripeUrl &&
                  <a href={sparkStripeUrl} target='_blank'>
                    <Button variant='contained'>
                      Manage Spark Billing
                    </Button>
                  </a>}
                  {skedStripeUrl &&
                  <a href={skedStripeUrl} target='_blank'>
                    <Button variant='contained'>
                      Manage SKED Billing
                    </Button>
                  </a>}
                </div>
              </Paper>
            </>}
            {msgPlan && !R.isEmpty(upcoming) &&
            <Paper className="content-inner panel-margin" style={{
              height: 'unset',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <h3 style={{ marginLeft: '0px' }}>Current Message Credit Usage</h3>
              <label style={{
                fontSize: '16px',
              }}>
                Current Messaging Bill: <b>${R.ifElse(
                  R.lt(0),
                  R.identity,
                  R.always(0)
                )((upcoming.amountDue - plan.cost) / 100)}</b>
              </label>
              <br />
              {showCredits &&
                <label style={{
                  fontSize: '16px',
                }}>
                  Credits: <b>{upcoming.usage}</b>
                </label>
              }
              {showLimit &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  height: '50px',
                }}>
                  <Tooltip
                    arrow
                    placement='top'
                    title={'Credits: ' + upcoming.usage}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: (upcoming.usage / upcoming.creditsAllowed) * 100 + '%',
                        backgroundColor: '#008BCF',
                        border: 'black 1px solid',
                      }}
                    >
                    </div>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement='top'
                    title={'Credit Limit: ' + upcoming.creditsAllowed}
                  >
                    <div
                      style={{
                        width: 100 - (upcoming.usage / upcoming.creditsAllowed * 100) + '%',
                        height: '100%',
                        backgroundColor: 'lightgray',
                        border: 'black 1px solid',
                        zIndex: 0,
                      }}
                    >
                    </div>
                  </Tooltip>
                </div>}
            </Paper>}
            {msgPlan && !R.isEmpty(usage) &&
            <Paper className="content-inner panel-margin" style={{
              height: 'unset',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <h3 style={{ marginLeft: '0px' }}>Previous Message Credit Usage</h3>
              <div
                style={{
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: '300px',
                  fontSize: '20px',
                }}>
                <Button
                  disabled={year === earliestYear}
                  onClick={() => update({ year: year - 1 })}>
                  <ArrowLeftIcon />
                </Button>
                {year}
                <Button
                  disabled={year === now('date').year()}
                  onClick={() => update({ year: year + 1 })}>
                  <ArrowRightIcon />
                </Button>
              </div>
              {usageChart(usage, year)}
              <i>Pro Tip: Click on the blue credit box to see the past payments easier.</i>
            </Paper>
            }
            {admin &&
            <Paper className="content-inner panel-margin" style={{ height: 'unset' }}>
              <CustomerId
                office={office}
                customerId={customerId}
                billingPatch={billingPatch}
              />
            </Paper>}
          </div>
        }
        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          {!isSpark &&
          <i>
            <a href="https://www.cognitoforms.com/SKED2/OptoutPaymentEmails" target="_blank">SKED - Opt-Out of Payment Emails</a>
          </i> }
          <br />
          <i>
            {!isSpark && <a href="https://s3.amazonaws.com/sked-v2/licenses+/SKED+-+Subscription+Agreement+-+03-25.pdf" target="_blank">SKED - Subscription Agreement</a>}
            {sparkStripeUrl &&
           <>
             <br/>
             <a href="https://sked-v2.s3.amazonaws.com/documents/2024-06-spark-patients-terms-of-service.pdf" target="_blank">Spark - Terms of Service</a>
           </>}
          </i>
        </div>
      </div>
    </>
  );
};

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    year: now('date').year(),
  });

  const globalProps = useSelector((state) => ({
    ...state.subscription,
    officeAddress: state.login.officeAddress,
    office: state.login.office,
    admin: state.login.admin,
    isSpark: R.includes(SPARK_FEATURE, state.login.features),
  }));

  const update = (data) => {
    setState({ ...state, ...data });
  };

  const props = {
    ...globalProps,
    getCardInfo: (nav) => dispatch(getCardInfo(nav)),
    billingPatch: (data) => dispatch(billingPatch(data)),
    uploadCard: (id) => dispatch(uploadCard(id)),
    ...state,
    update,
  };

  useEffect(() => {
    props.getCardInfo(navigate);
  }, []);

  useEffect(() => {
    props.getCardInfo(navigate);
  }, [props.office]);

  return (
    <SubscriptionTemplate {...props} />
  );
};

export default Subscription;
