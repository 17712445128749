import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Checkbox, FormControlLabel, Tabs, Tab, DialogActions, TextField, Paper,
  Button, Typography, CircularProgress,
} from '@mui/material';
import TaskIcon from '@mui/icons-material/TaskAlt';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { PopupTemplate, popup, popupWithCancel, popupWithCustoms } from '../../services/Popup.js';
import { useCheckIsAdmin } from '../../services/checkIsAdmin.hook';
import * as adminActions from './admin.actions';
import api from '../../services/api.js';
import { useSelector } from '../../reducers';
import { CustomContentType, CustomButtonType } from '../Login/login.actions';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';

const ResetUser = () => {
  const dispatch = useDispatch();
  const [emailOrId, setEmailOrId] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [facebook, setFacebook] = useState(false);
  const [google, setGoogle] = useState(false);
  const [apple, setApple] = useState(false);
  const [disabling, setDisabling] = useState<string>(null);
  const [disabled, setDisabled] = useState(false);
  const [tab, setTab] = useState('client');

  const {
    client,
    state,
    lState,
    admin,
  } = useSelector((state) => ({
    state: state.admin.state,
    client: state.admin.client,
    lState: state.session.status,
    admin: state.login.admin
  }));

  const actions = bindActionCreators(adminActions, dispatch);

  useEffect(() => {
    if (state === 'INPUT') {
      setNewEmail('');
      setFacebook(false);
      setGoogle(false);
      setApple(false);
    }
  }, [state]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailOrId(event.target.value);
  };

  const handleNewEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value);
  };

  const handleDefaultEmail = () => {
    setNewEmail('client+' + R.propOr(null, 'id')(client) + '@sked.life');
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      actions.getUserClient(emailOrId);
    }
  };

  useCheckIsAdmin(admin, lState);

  const disableOffice = async (officeId: number) => {
    setDisabling('Charging flux capacitors...');
    await new Promise((res) => {
      setTimeout(res, 1000);
    });
    setDisabling('Disabling all messages...');
    await adminActions.disableAllMessages(officeId);
    setDisabling('Disabling all auto responders...');
    await adminActions.disableAllAutoresponders(officeId);
    setDisabling('Disappearing all appointment types...');
    await adminActions.disableAllApptTypes(officeId);
    setDisabling('Zeroing all schedules...');
    await adminActions.disableAllSchedules(officeId);
    setDisabling('Disabling reviews...');
    await adminActions.disableAllReviews(officeId);
    setDisabling('Informing the deep state of your actions...');
    await new Promise((res) => {
      setTimeout(res, 1000);
    });
    setDisabling('Disabling geofence, appointment changes notifications, & email SMS notifications...');
    await adminActions.disableAllGeneralSettings(officeId);
    setDisabling('Destroying all New Patient Portals...');
    await adminActions.disableAllNPPs(officeId);
    setDisabling('Disabling Legacy Web Module...');
    await adminActions.disableLWM(officeId);
    setDisabling('Disabling all office users...');
    await adminActions.disableAllOfficeUsers(officeId);
    setDisabling('Cleaning out intake manifolds...');
    await new Promise((res) => {
      setTimeout(res, 1000);
    });
    setDisabling('Complete!');
    setDisabled(true);
    await new Promise((res) => {
      setTimeout(res, 10000);
    });
    setDisabling('Why are you still here? Go away!');
  };

  return (
    <div>
      {lState === 'NOT_AUTHENTICATED' && <Navigate to="/login" />}
      <h3 style={{ marginBottom: 'unset', height: '20px' }}>
        <div className="title">Reset User</div>
      </h3>
      <PopupTemplate />
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Paper style={{
          height: 'auto',
          width: 'auto',
          padding: '10px',
        }}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, val) => setTab(val)}
            value={tab}
            centered>
            <Tab label="Reset User" className="tab" value='client' />
            <Tab label="Reset Office Client Users" className="tab" value='office' />
            <Tab label="Disable Office" className="tab" value='disable' />
          </Tabs>
          <div
            hidden={tab !== 'client'}
            style={{ marginTop: '10px' }}
          >
            {R.cond([
              [R.equals('INPUT'), () => (
                <div>
                  <TextField
                    label='User Email or User ID'
                    placeholder="jonny-boy@example.com"
                    value={emailOrId}
                    id="id"
                    onChange={handleEmailChange}
                    onKeyPress={onKeyPress}
                  />
                  <br />
                  <br />
                  <Button
                    variant='contained'
                    disabled={emailOrId === ''}
                    onClick={() => actions.getUserClient(emailOrId)}>
                    Find User
                  </Button>
                </div>
              )],
              [R.equals('SHOW'), () => (
                <div style={{ marginTop: '10px' }}>
                  <label>
                    Client:
                    <ul>
                      <li>Client ID: {R.propOr(null, 'id')(client)}</li>
                      <li>First Name: {R.propOr(null, 'firstName')(client)}</li>
                      <li>Last Name: {R.propOr(null, 'lastName')(client)}</li>
                      <li>Office: {R.propOr(null, 'name')(client)}</li>
                    </ul>
                  </label>
                  <br />
                  <TextField
                    label='New Email'
                    placeholder="kevy@example.com"
                    value={newEmail}
                    id="id"
                    onChange={handleNewEmailChange} />
                  {R.any(R.identity, [client.facebook, client.google, client.apple]) &&
                    <>
                      <br />
                      <label>
                        Check the logins you wish to reset:
                        {client.facebook &&
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={facebook}
                                  onChange={(e) => setFacebook(e.target.checked)}
                                  name="acknowledge-checkbox"
                                  color="primary"
                                />
                              }
                              label='Facebook' />
                            <br />
                          </>}
                        {client.google &&
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={google}
                                  onChange={(e) => setGoogle(e.target.checked)}
                                  name="acknowledge-checkbox"
                                  color="primary"
                                />
                              }
                              label='Google' />
                            <br />
                          </>}
                        {client.apple &&
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={apple}
                                  onChange={(e) => setApple(e.target.checked)}
                                  name="acknowledge-checkbox"
                                  color="primary"
                                />
                              }
                              label='Apple' />
                            <br />
                          </>}
                      </label>
                    </>}
                  <br />
                  <br />
                  <Button
                    variant='contained'
                    onClick={() =>
                      popupWithCancel(
                        'Verification',
                        'Are you sure you want to reset this client\'s user?',
                        () => actions.resetUser({
                          emailOrId,
                          newEmail,
                          facebook,
                          google,
                          apple,
                        }))}>
                    Reset User
                  </Button>
                  &nbsp;
                  <Button
                    variant='outlined'
                    disabled={newEmail !== ''}
                    onClick={handleDefaultEmail}>
                    Default Email
                  </Button>
                  &nbsp;
                  <Button
                    type="button"
                    style={{ float: 'right' }}
                    variant="contained"
                    onClick={() => actions.adminPatch({ state: 'INPUT' })}>
                    Cancel
                  </Button>
                </div>
              )],
              [R.T, () => (<div></div>)]
            ])(state)}
          </div>
          <div
            hidden={tab !== 'office'}
            style={{ marginTop: '10px' }}
          >
            <div>
              <TextField
                label='Office ID'
                placeholder="123"
                value={emailOrId}
                id="id"
                onChange={handleEmailChange}
                onKeyPress={onKeyPress}
              />
              <br />
              <br />
              <Button
                variant='contained'
                onClick={() =>
                  api.get(`office/${emailOrId}`).then(({ name }) => {
                    popupWithCustoms({
                      title: 'Verification',
                      customContent: (t: CustomContentType) => {
                        const {
                          setState,
                          state,
                        } = t;
                        return (
                          <div>
                            Are you sure you want to reset ALL of <i>{name}</i>'s users? If this is wrong, you will be punished severely!
                            <br />
                            <br />
                            <label>
                              <input
                                type='checkbox'
                                checked={Boolean(state.accept)}
                                onChange={(e) => setState({ accept: e.target.checked })}
                              />
                              &nbsp;
                              I accept these terms and the potential consequences
                            </label>
                          </div>
                        );
                      },
                      customButtons: (t: CustomButtonType) => {
                        const { state, close, } = t;
                        return (
                          <>
                            <HeaderButton
                              borderSolid
                              onClick={() => {
                                close();
                              }}
                              title="Cancel"
                            />
                            <HeaderButton
                              color='primary'
                              disabled={!state.accept}
                              onClick={() => {
                                close();
                                actions.resetOfficeUsers({
                                  emailOrId,
                                });
                              }}
                              title="Accept"
                            />
                          </>
                        );
                      },
                    });
                  }).catch((e) => {
                    if (e.response.status === 404) {
                      popup('Error!', 'This office doesn\'t exist!');
                    } else if (e.response.status === 400) {
                      popup('Error!', e.response.data);
                    }
                  })}>
                Reset All
              </Button>
            </div>
          </div>
          <div
            hidden={tab !== 'disable'}
            style={{ marginTop: '10px' }}
          >
            {!disabling ?
              <div>
                <TextField
                  label='Office ID'
                  placeholder="123"
                  value={emailOrId}
                  id="id"
                  onChange={handleEmailChange}
                  onKeyPress={onKeyPress}
                />
                <br />
                <br />
                <Button
                  variant='contained'
                  onClick={() =>
                    api.get(`office/${emailOrId}`).then(({ name }) => {
                      popupWithCustoms({
                        title: 'Verification',
                        customContent: (t: CustomContentType) => {
                          const {
                            setState,
                            state,
                          } = t;
                          return (
                            <div>
                            Are you sure you want to disable ALL of <i>{name}</i>'s features? If you aren't sure,
                              <a onClick={() => setState({ ...state, michael: true })}> click here for advice</a>.
                              {state.michael &&
                             <>
                               <br />
                               <br />
                               <div style={{
                                 display: 'flex',
                                 width: '100%',
                                 justifyContent: 'center',
                               }}>
                                 <img src="https://sked-v2.s3.amazonaws.com/videos/stop-it-get-some-help.gif" width='150px' height='150px'/>
                               </div>
                             </>}
                              <br />
                              <br />
                              <label>
                                <input
                                  type='checkbox'
                                  checked={Boolean(state.accept)}
                                  onChange={(e) => setState({ accept: e.target.checked })}
                                />
                              &nbsp;
                              I accept these terms and the potential consequences
                              </label>
                            </div>
                          );
                        },
                        customButtons: (t: CustomButtonType) => {
                          const { state, close, } = t;
                          return (
                            <DialogActions>
                              <Button
                                variant='contained'
                                disabled={!state.accept}
                                onClick={() => {
                                  close();
                                  if (typeof emailOrId === 'string') {
                                    disableOffice(Number(emailOrId));
                                  }
                                }}>
                              Accept
                              </Button>
                              <Button
                                onClick={() => {
                                  close();
                                }}>
                              Cancel
                              </Button>
                            </DialogActions>
                          );
                        },
                      });
                    }).catch((e) => {
                      if (e.response.status === 404) {
                        popup('Error!', 'This office doesn\'t exist!');
                      } else if (e.response.status === 400) {
                        popup('Error!', e.response.data);
                      }
                    })}>
                Disable Office
                </Button>
              </div>
              :
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '15px',
                marginBottom: '10px',
                maxWidth: '487px',
              }}>
                <Typography component='h2' style={{ fontSize: '16px' }}>
                  {disabling}
                </Typography>
                {!disabled ?
                  <CircularProgress />
                  :
                  <TaskIcon color='success' />}
              </div>}
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ResetUser;
