import React, { useMemo, useState } from 'react';

import { Button, Grid, Popover } from '@mui/material';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useStyles } from './headerTabs.styles';

type SubItem = {
  value: string;
  label: string;
  className?: string;
}

type Item = {
  value: string;
  label: string;
  className?: string;
  subItems?: SubItem[];
}

type Props = {
  tabs: Item[];
  value: string;
  setTab: (value: string) => void;
  setSubtab?: (value: string) => void;
  subtabValue?: string;
  isSmall?: boolean;
}

const HeaderTabs = ({ tabs, value, setTab, subtabValue, setSubtab, isSmall }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (tab: Item) => {
    setTab(tab.value);
    setAnchorEl(null);
  };
  
  const handleSelectSub = (tab: SubItem) => {
    setSubtab(tab.value);
    setAnchorEl(null);
  };

  const subItems = useMemo(() => {
    if (!value || !tabs.length) {
      return [];
    }
    let items: SubItem[] = [];
    tabs.forEach(tab => {
      if (tab?.subItems?.length && value === tab?.value) {
        items = tab.subItems;
        return;
      }
    });
    return items;
  }, [tabs.length, value]);

  const activeTab = useMemo(() => {
    const item = tabs.find(item => item?.value === value);
    if (item?.subItems?.length) {
      return item.subItems.find(item => item?.value === subtabValue);
    }
    return item;
  }, [tabs, value, subtabValue]);

  if (isSmall) {
    return (
      <Grid className={classes.root}>
        <Button
          onClick={(event) => setAnchorEl(event.currentTarget)}
          variant={'outlined'}
          endIcon={<ArrowDownIcon className={`${classes.icon} ${anchorEl ? classes.rotateRight : classes.rotateDown}`} />}
          className={`${classes.customButtom} ${classes.first}
            ${classes.last} ${activeTab?.className}`}
          color="primary"
        >
          {activeTab.label}
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grid className={classes.grid} display="flex" alignItems="center" maxWidth={550} flexWrap="wrap">
            {tabs.map((tab) => {
              if (tab) {
                return (
                  <Grid
                    key={tab.value}
                    className={`${classes.item} ${tab.subItems?.length ? classes.noHover : '' }`}
                    onClick={() => handleSelect(tab)}
                  >
                    {tab.label}
                    {!!tab.subItems?.length && (
                      <Grid className={classes.itemContainer}>
                        {tab.subItems?.map((sub) => (
                          <Grid key={sub.value} className={classes.item} onClick={() => handleSelectSub(sub)}>
                            {sub.label}
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </Popover>
      </Grid>
    );
  }

  return (
    <Grid className={classes.root}>
      {tabs.map((tab, index) => {
        if (tab) {
          return (
            <Button
              key={tab.value}
              onClick={() => setTab(tab.value)}
              variant={'outlined'}
              className={`${value === tab.value ? classes.selected : ''}
                ${classes.customButtom} ${index === 0 ? classes.first : ''}
                ${index === tabs.length - 1 ? classes.last : ''} ${tab.className}`}
              color="primary"
            >
              {tab.label}
            </Button>
          );
        }
        return null;
      })}
      {subItems.map((tab, index) => {
        if (tab) {
          return (
            <Button
              key={tab.value}
              onClick={() => setSubtab?.(tab.value)}
              variant={'outlined'}
              className={`${subtabValue === tab.value ? classes.selected : ''}
                ${classes.customButtom} ${index === 0 ? classes.subFirst : ''}
                ${index === tabs.length - 1 ? classes.last : ''} ${tab.className}`}
              color="primary"
            >
              {tab.label}
            </Button>
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default HeaderTabs;
