import React, { useState } from 'react';
import {
  pipe, path, sortBy,
  keys, without, length,
  reverse, isEmpty, assoc,
  all, values, not
} from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  TextField,
  FormHelperText,
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoopIcon from '@mui/icons-material/Loop';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  getFirstErrorOrDefault, getFormObject, getValue, initForm,
  requiredValidation, submitForm, updateValue, validateField
} from '../../../services/form-validation.js';
import api from '../../../services/api.js';
import { usePromise } from '../../../services/promise.hook';
import { useTypeahead } from '../../../services/typeahead.hook.js';
import { tzParseFormat } from '../../../services/joda.js';
import { birthdayFormatter } from '../../../services/utilities.js';
import { Client } from '../clients.types';
import { successSnackbar } from '../../../components/Snackbar/snackbar.actions';

const saveLink = ({ clientId, patientId }: { clientId: number, patientId: number }) => {
  return api.put(`client/${clientId}/cla/match`, { patientId });
};

const getLink = (clientId: number) => {
  return api.get(`client/${clientId}/cla/match`);
};

const getExams = (clientId: number) => {
  return api.get(`client/${clientId}/cla/exam`);
};

type Match = {
  clientId: number;
  patientId: number;
}

const getMatches = (data: { patientId: number }) => {
  return api.post('cla/patient/search', data).then((matches: Match[]) => {
    /* Sort these by number of non-nil keys. */
    return pipe(
      sortBy((match: Match) => {
        if (match.patientId === data.patientId) {
          return 100;
        } else {
          return pipe(
            keys,
            without([null]),
            length
          )(match);
        }
      }),
      (params: Match[]) => reverse(params),
    )(matches);
  });
};

const updateCb = (e: { target: { value: string } }) => e.target.value;

const formObject = {
  patientId: {
    default: '',
    validation: [
      requiredValidation('A CLA patient id is required')
    ]
  }
};

const useStyles2 = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

type ExamCardProps = {
  tz: string;
  clientId: number;
  examId: number;
  coreScore: string;
  created: string;
  reportType: string;
  emgScanId: number;
  hrvScanId: number;
  thermalScanId: number;
}

const ExamCard = ({
  tz,
  clientId,
  examId,
  coreScore,
  created,
  reportType,
  emgScanId,
  hrvScanId,
  thermalScanId
}: ExamCardProps) => {
  const classes = useStyles2();

  const getPDF = (url: string) => () => api.get(url).then(s3 => {
    window.open(s3.url, '_blank');
  });

  return (
    <Card className={classes.root}>
      <CardContent>
        {coreScore && <Typography className={classes.title} color="textSecondary" gutterBottom>
          CORESCORE: {reportType}
        </Typography>}
        {coreScore && <Typography variant="h5" component="h2">
          {coreScore}
        </Typography>}
        <Typography className={classes.pos} color="textSecondary">
          {tzParseFormat(created, tz, 'dd, MMM YYYY')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={getPDF(`/client/${clientId}/cla/exam/report/${examId}`)}>Report</Button>
        {emgScanId && <Button size="small" onClick={getPDF(`/client/${clientId}/cla/scan/${emgScanId}`)}>EMG</Button>}
        {hrvScanId && <Button size="small" onClick={getPDF(`/client/${clientId}/cla/scan/${hrvScanId}`)}>HRV</Button>}
        {thermalScanId && <Button size="small" onClick={getPDF(`/client/${clientId}/cla/scan/${thermalScanId}`)}>Thermal</Button>}
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  match: {
    border: '1px solid lightgray',
    borderRadius: '5px',
    width: 'min-content',
    height: '79px',
    padding: '5px',
    cursor: 'pointer',
    color: 'black',
    backgroundColor: 'lightgray',
    '&:hover': {
      border: '1px solid #008BCF',
      backgroundColor: '#008BCF',
      color: 'white',
    },
  },
  helperContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

interface Patient extends Client {
  patientId: number;
}

const CLAClient = ({ client }: { client: Client}) => {
  const {
    firstName,
    lastName,
    id,
  } = client;
  const clientId = id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const tz: string = useSelector(path(['login', 'office', 'timezone']));

  const savedState = usePromise(saveLink, {});
  const examsState = usePromise(getExams, []);
  const linkState = usePromise(getLink, {});
  const matchState = useTypeahead(getMatches, [], { firstName, lastName } as unknown as string);

  const [showLinkSettings, setShowLinkSettings] = React.useState(false);

  const [nameSearch, setNameSearch] = useState({ firstName, lastName });
  const [form, setForm] = useState(initForm(formObject));

  React.useEffect(() => {
    // Need this to get it going
    matchState.invoke({ firstName, lastName });
    linkState.invoke(clientId).then(({ patientId }) => {
      setForm(updateValue('patientId', patientId));
      examsState.invoke(clientId);
      matchState.invoke({ firstName, lastName, patientId });
    }).catch((e) => {
      console.log(e);
      setShowLinkSettings(true);
      matchState.invoke({ firstName, lastName, patientId: null });
    });
  }, []);

  const update = (prop = '', cb = updateCb) => (e: React.ChangeEvent<HTMLInputElement>) => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);

    savedState.invoke({ ...data, clientId }).then(() => {
      examsState.invoke(clientId);
      linkState.setState(s => ({ ...s, data }));
      dispatch(successSnackbar('Saved the CLA patient id'));
      setShowLinkSettings(false);
    });

  };

  const handleSearchChange = (prop: string, str: string) => {
    const newNameSearch = assoc(prop, str, nameSearch);
    setNameSearch(newNameSearch as typeof nameSearch);
    matchState.invoke({
      firstName: newNameSearch.firstName,
      lastName: newNameSearch.lastName,
      patientId: getValue('patientId', form),
    });
  };

  const selectMatch = (patientId: number) => {
    setForm(updateValue('patientId', patientId));
  };

  return (
    <div style={{ margin: '0px 20px 20px 20px' }}>
      <div className={classes.helperContainer}>
        <div className={classes.formControl}>
          {!linkState.data.patientId && <div>This client is not linked to CLA.</div>}
          <FormHelperText style={{ width: '87%' }}>
            Use the Search fields to find potential CLA patient matches, or enter the CLA patient ID to link a CLA patient with a SKED client. Be sure to hit Save so the accounts will be linked.
          </FormHelperText>
        </div>
        <IconButton
          style={{ alignSelf: 'flex-start' }}
          onClick={() => setShowLinkSettings(!showLinkSettings)}>
          <SettingsIcon />
        </IconButton>
      </div>
      {showLinkSettings &&
        <form onSubmit={handleOnSubmit}>
          <div className={classes.formControl}>
            <TextField
              fullWidth
              id="cla-patient-id"
              label="Patient ID"
              type="text"
              helperText={getFirstErrorOrDefault('patientId', form)}
              error={!form.patientId.isValid}
              value={getValue('patientId', form)}
              onChange={update('patientId')}
            />
          </div>
          <div style={{
            display: 'flex',
          }}>
            <Button
              variant="contained"
              type="submit"
              disabled={savedState.loading}
              startIcon={savedState.loading ? <LoopIcon /> : null}
              color="primary">
              {savedState.loading ? 'Loading...' : 'Save'}
            </Button>
          </div>
          {savedState.errorMessage && <div>Error: {savedState.errorMessage}.</div>}
          <Divider style={{ margin: '20px 0' }} />
          {<div>
            <TextField
              name="firstNameSearch"
              label="Search First Name"
              value={nameSearch.firstName}
              onChange={(e) => handleSearchChange('firstName', e.target.value)} />
            &nbsp;
            <TextField
              name="lastNameSearch"
              label="Search Last Name"
              value={nameSearch.lastName}
              onChange={(e) => handleSearchChange('lastName', e.target.value)} />
          </div>}
          {matchState.loading && pipe(values, all(isEmpty), not)(nameSearch) &&
            <>
              <br />
              <CircularProgress />
            </>}
          {!matchState.loading && isEmpty(matchState.errorMessage) &&
            <>
              {!isEmpty(matchState.data) &&
                <FormHelperText>
                  Here are some suggested matches for this patient from the CLA system. Click on the correct account to match them, and then hit Save.
                </FormHelperText>}
              <br />
              <Grid container spacing={1}>
                {isEmpty(matchState.data) && !matchState.loading &&
                  <p style={{ marginLeft: '4px', marginTop: '20px' }}>No matches found.</p>}
                {!isEmpty(matchState.data) && !matchState.loading &&
                  matchState.data.map((p: Patient) => {
                    const selected = getValue('patientId', form) === p.patientId;
                    const label =
                      p.firstName + ' ' + p.lastName +
                      (p.birthday ? ` | ${birthdayFormatter(p.birthday)}` : '') +
                      (p.email ? ` | ${p.email}` : '') +
                      (p.phone ? ` | ${p.phone}` : '');
                    return (
                      <Grid key={p.patientId} item xs={12}>
                        <div
                          style={{
                            width: 'fit-content',
                            backgroundColor: selected ? '#008BCF' : '#CECECE',
                            color: 'black',
                            cursor: 'pointer',
                            border: '1px solid rgb(191, 191, 191)',
                            borderRadius: '50px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                          onClick={() => selectMatch(p.patientId)}>
                          <div
                            key={p.patientId}
                            style={{
                              padding: '5px 10px 5px 10px',
                              width: 'fit-content',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              color: selected ? 'white' : 'black',
                            }}>
                            {label}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
              <Divider style={{ margin: '20px 0' }} />
            </>}
        </form>}

      {!showLinkSettings && examsState.errorMessage && <div>Error getting exams: {examsState.errorMessage}.</div>}
      {!showLinkSettings && (examsState.loading || linkState.loading) ? <CircularProgress /> : null}

      {!showLinkSettings && examsState.data.length === 0 && <div>No exams for this patient. </div>}
      {!showLinkSettings && examsState.data.map((exam: ExamCardProps) => <ExamCard key={exam.examId} {...exam} tz={tz} clientId={clientId} />)}
    </div>);
};

export default CLAClient;
