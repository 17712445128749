import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import
CreateAutomationMessage
  from '../../components/AutomationBasedMessage/create-automation-message.component';
import { includes } from 'ramda';
import queryString from 'query-string';


const TimeBasedMessageEdit = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const msgId = params?.id ? Number(params.id) : 0;
  const type = includes('template', location.pathname) ? 'template' : 'message';
  const query = queryString.parse(location.search);
  const copying = Number(query?.copying || 0);
  const fromTemplate = Number(query?.fromSkedTemplate || 0);
  return (
    <>
      <CreateAutomationMessage
        messageId={msgId}
        copying={copying}
        filter={'Clients'}
        messageType={type}
        automationMessageType={'TimeBasedReminder'}
        includeHeader
        goBack={() => navigate(-1)}
        fromTemplate={fromTemplate}
      />
    </>
  );
};

export default TimeBasedMessageEdit;
