import React from 'react';
// import { Routes, Route } from 'react-router-dom';
import { OverviewPage } from './Routes/Overview/overview.page.jsx';

const TutorialsRoutes = () => {
  return <OverviewPage />;
  // const match = useRouteMatch();
  // return (
  //   <Routes>
  //     <Route path={match.url} element={OverviewPage} />
  //   </Routes>
  // );
};

export default TutorialsRoutes;
