import React, { useState, useEffect, useRef } from 'react';
// import './appointments.scss';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  Tooltip,
  TableHead,
  TableBody,
  TableRow,
  Grid,
} from '@mui/material';
import {
  HighlightOff as ClearIcon,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import api from '../../../services/api.js';
import axios from 'axios';
import { birthdayFormatter, smartSearch, outputLocation, caseType } from '../../../services/utilities.js';
import {
  isEmpty,
  includes,
  pathOr
} from 'ramda';
import AddLead from '../../../routes/Leads/components/AddLead/AddLead.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import { useSelector } from '../../../reducers';

const CancelToken = axios.CancelToken;
let cancel;

const getClients = ({
  firstName,
  lastName,
  phone,
  page = 1,
  isLead,
  leadsAndClients,
}, status, setClients, setBusy, setTotalCount, setTotalPages) => {
  const body = {
    page,
    perPage: 25,
    query: {
      lastName,
      firstName,
      phone,
      status: [status],
      sortBy: isLead ? [{
        direction: 'Desc',
        field: 'Created'
      }] : [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }],
      isLead,
      leadsAndClients,
    },
  };
  setBusy(true);
  if (cancel)
    cancel('User aborted request');
  return api.post(
    'client/query',
    body,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      })
    })
    .then((d) => {
      const {
        data,
        totalCount,
        totalPages,
      } = d;
      setBusy(false);
      setClients(data);
      setTotalCount(totalCount);
      setTotalPages(totalPages);
    }).catch((error) => {
      if (!cancel)
        setBusy(false);
      console.log(error);
    });
};

const clientItem = (member, close, goto, useNewChat) => {
  return (
    <TableRow key={member.id}>
      <BodyCell>
        <a onClick={() => {
          const url = (useNewChat ? 'inbox/chat/' : 'inbox/thread/') + member.id;
          goto({
            url,
            client: member,
          });
          close();
        }}>
          {member.firstName} {member.lastName} {caseType(member)}
        </a>
      </BodyCell>
      <BodyCell>
        {birthdayFormatter(member.birthday)}
      </BodyCell>
      <BodyCell>
        {member.phone}
      </BodyCell>
    </TableRow>
  );
};

const SEARCH_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'phone', label: 'Phone Number' },
];

export default function FindClient({
  open,
  onClose,
  goto,
  isLead,
  leadsAndClients,
}) {
  const timeoutRef = useRef();
  const [search, setSearch] = useState('');
  const [searchBy, setSearchBy] = useState('name');
  const [status, setStatus] = useState('Active');
  const [clients, setClients] = useState([]);
  const [busy, setBusy] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [addLead, setAddLead] = useState(false);

  const { useNewChat } = useSelector(state => ({
    useNewChat: includes('NewSMSThreads', pathOr([], ['login', 'features'], state)),
  }));

  const getLeadProps = () => {
    if (searchBy === 'phone') {
      return {
        phone: search
      };
    }
    const splitUp = search.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
    return {
      firstName,
      lastName,
    };
  };

  const handleSaveLead = () => {
    const output = smartSearch(search, isLead, searchBy === 'phone', leadsAndClients);
    getClients(
      output,
      status,
      setClients,
      setBusy,
      setTotalCount,
      setTotalPages
    );
  };

  useEffect(() => {
    const de = {
      firstName: '',
      lastName: '',
      page,
      isLead,
      leadsAndClients,
    };
    getClients(
      de,
      status,
      setClients,
      setBusy,
      setTotalCount,
      setTotalPages
    );
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={isLead ? 'Find Lead' : 'Find Client'}
      className='sked-test-sms-inbox-find-client-modal'
    >
      <div>
        <div>
          <div style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}>
            <FormControl style={{ width: '150px' }}>
              <InputLabel id="filter-label">Search By</InputLabel>
              <MUISelect
                labelId="filter-label"
                id="filter-select"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              >
                {SEARCH_OPTIONS.map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
              </MUISelect>
            </FormControl>
            &nbsp; &nbsp;
            &nbsp;
            <TextField
              id="search"
              label="Search"
              placeholder={searchBy === 'name' ? 'LastName FirstName' : 'Phone'}
              value={search}
              onChange={(e) => {
                const s = e.target.value;
                setSearch(s);
                setPage(1);
                const output = smartSearch(s, isLead, searchBy === 'phone', leadsAndClients);
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                  getClients(
                    output,
                    status,
                    setClients,
                    setBusy,
                    setTotalCount,
                    setTotalPages
                  );
                }, 200);
              }}
              InputProps={{
                endAdornment: (search.length > 0) && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        setSearch('');
                        setPage(1);
                        getClients({
                          firstName: '',
                          lastName: '',
                          isLead,
                          leadsAndClients,
                        }, status, setClients, setBusy, setTotalCount, setTotalPages);
                      }}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip
              title={searchBy === 'name' ? 'Search format: lastName firstName (i.e. smith john)' : 'full phone number'}
              placement="top"
              arrow
            >
              <InfoIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </div>
          {!isLead && (
            <>
              <br />
              <label>
                Inactive:
                <input
                  checked={status === 'Inactive'}
                  type='checkbox'
                  onChange={(e) => {
                    const c = e.target.checked ? 'Inactive' : 'Active';
                    setStatus(c);
                    const output = smartSearch(search, isLead, searchBy === 'phone', leadsAndClients);
                    getClients(
                      output,
                      c,
                      setClients,
                      setBusy,
                      setTotalCount,
                      setTotalPages
                    );
                  }}
                />
              </label>
            </>
          )}
        </div>
        <br />
        <div style={{ minHeight: '120px' }}>
          {busy && <div className='loader'></div>}
          {!busy && !isEmpty(clients) &&
            <Grid marginX={-2}>
              <TableContainer>
                <TableHead>
                  <TableRow>
                    <HeaderCell>
                      Name
                    </HeaderCell>
                    <HeaderCell>
                      Birthday
                    </HeaderCell>
                    <HeaderCell>
                      Phone
                    </HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client) => clientItem(client, onClose, goto, useNewChat))}
                </TableBody>
              </TableContainer>
            </Grid>
          }
          {isEmpty(clients) && isLead && !!search && (
            <>
              <br />
              <p>{`No results found. Would you like to add "${search}" as a new lead?`}</p>
              <br />
              <Button variant='contained' onClick={() => setAddLead(true)}>Add Lead</Button>
            </>
          )}
          {!isEmpty(clients) &&
            <div
              style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                {outputLocation(page, 25, totalCount, clients.length)}
              </div>
              &nbsp;
              <IconButton
                disabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                  const output = smartSearch(search, isLead, searchBy === 'phone', leadsAndClients);
                  getClients({
                    ...output,
                    page: page - 1,
                  }, status, setClients, setBusy, setTotalCount, setTotalPages);
                }}
              >
                <ArrowLeftIcon fontSize='small' />
              </IconButton>
              <IconButton
                disabled={page === totalPages}
                onClick={() => {
                  setPage(page + 1);
                  const output = smartSearch(search, isLead, searchBy === 'phone', leadsAndClients);
                  getClients({
                    ...output,
                    page: page + 1,
                  }, status, setClients, setBusy, setTotalCount, setTotalPages);
                }}
              >
                <ArrowRightIcon fontSize='small' />
              </IconButton>
            </div>}
        </div>
        {addLead && <AddLead open={addLead} setOpen={setAddLead} {...getLeadProps()} onSave={handleSaveLead} />}
      </div>
    </Modal>
  );
}
