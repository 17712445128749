import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableHead, TableRow, TableBody, MenuItem, TextField, FormControl,
  InputLabel, Select, Input, ListItemText, InputAdornment, Menu, Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import { usePromise } from '../../../../../services/promise.hook';
import {
  tzParseFormat,
} from '../../../../../services/joda.js';
import Search from '@mui/icons-material/Search';
import OpenInNew from '@mui/icons-material/OpenInNew';
import {
  fetchSubmissions, QuerySubmissionResponse, QuerySubmission, SubmissionQ,
  makeNewSub, CreateSubmission, fetchFormList, makeNewOneTimeToken,
  getSettingsForm, saveSettings, GlobalSettings
} from '../../../../IntakeForms/intake.service';
import { checkPinAndLock } from '../../../../IntakeForms/lock.component';
import { Row, Spacer } from '../../../../../components/PageHeader';
import { TableContainer, HeaderCell, BodyCell } from '../../../../../components/CustomTable';
import LockModal from '../../../../../components/LockModal/LockModal.component';
import {
  cond, isNil, identity, always, T
} from 'ramda';
import { Form, Submission } from '../../../../IntakeForms/ServerTypes';
import { patch } from '../../../../Login/login.actions';
import { encryptKey } from '../../../../../services/utilities';

const isCompleteOptions = ['Any', 'Submitted', 'In Progress'];

interface ClientFormListProps {
  client: any; /* TODO: Client Type  */
  tz: string
  smallModal?: boolean;
  open: boolean;
}

let timeout: any = null;
const ClientFormList = ({
  client, tz, smallModal, open,
}: ClientFormListProps) => {
  const dispatch = useDispatch();
  const {
    id,
  } = client;
  const [query, setQuery] = useState<SubmissionQ>({ clientId: id });
  const [anchorEl, setAnchorEl] = useState(null);
  const [oneTimeToken, setOneTimeToken] = useState(null);
  const [locked, setLocked] = useState<string>(null);
  const [w, setWindow] = useState<Window>(null);
  const [subId, setSubId] = useState<number>(null);
  const [lockModal, setLockModal] = React.useState(false);
  const formsState = usePromise<void, Form[]>(fetchFormList, []);
  const newSubState = usePromise<CreateSubmission, Submission>(makeNewSub, null);
  const newOneTimeState = usePromise<number, string>(makeNewOneTimeToken, null);
  const listState = usePromise<QuerySubmission, QuerySubmissionResponse>(fetchSubmissions, {
    page: 1,
    totalPages: 1,
    totalCount: 0,
    data: [],
  });
  const getSettingsState = usePromise<void, GlobalSettings>(getSettingsForm, null);
  const saveState = usePromise<GlobalSettings, GlobalSettings>(saveSettings, null);

  const checkFinish = (sub: Submission, reload = false) => {
    setSubId(sub.subId);
    if (getSettingsState.data?.pin) {
      setLockModal(true);
      if (reload) {
        listState.invoke({ page: 1, perPage: 50, query });
      }
    } else {
      openInOfficeForm(sub.subId);
    }
  };

  useEffect(() => {
    formsState.invoke();
    getSettingsState.invoke();
  }, [id]);

  useEffect(() => {
    if (open) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        listState.invoke({ page: 1, perPage: 50, query });
      }, 500);
    }
  }, [query.isSubmitted, query.appointment, query.submitted, query.formName, open]);

  useEffect(() => {
    if (oneTimeToken) {
      const url = `${process.env.PORTAL_URL}/intake-office?token=${oneTimeToken}&id=${subId}`;
      console.log(url);
      w.location = url;
      w.focus();
    }
  }, [oneTimeToken]);

  const onChangedComplete = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery({
      ...query,
      isSubmitted: value === isCompleteOptions[0] ? undefined : value === isCompleteOptions[1],
    });
  };


  const onApptDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    if (date) {
      setQuery({
        ...query,
        appointment: {
          after: date + 'T00:00:00Z',
          before: date + 'T23:59:59Z',
        },
      });
    } else {
      setQuery({
        ...query,
        appointment: undefined,
      });
    }
  };

  const onSubDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    if (date) {
      setQuery({
        ...query,
        submitted: date + 'T00:00:00Z',
      });
    } else {
      setQuery({
        ...query,
        submitted: undefined,
      });
    }
  };

  const isComplete = useMemo(() => {
    return cond([
      [isNil, always(isCompleteOptions[0])],
      [identity<boolean>, always(isCompleteOptions[1])],
      [T, always(isCompleteOptions[2])]
    ])(query.isSubmitted);
  }, [query.isSubmitted]);

  const apptDate = useMemo(() => {
    return query.appointment ? query.appointment.after.split('T')[0] : '';
  }, [query.appointment]);

  const subDate = useMemo(() => {
    return query.submitted ? query.submitted.split('T')[0] : '';
  }, [query.submitted]);

  const openInOfficeForm = (id?: number) => {
    checkPinAndLock({
      subId: id || subId,
      openWindow: true,
      saveState,
      newOneTimeState,
      setOneTimeToken,
      setLocked,
      w,
      setWindow,
      getSettingsState,
    });
  };

  const table = () => {
    return (
      <TableContainer style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <HeaderCell>
                Form
            </HeaderCell>
            <HeaderCell>
                Status
            </HeaderCell>
            <HeaderCell>
                Created
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listState.data.data.map(sub => {
            return (
              <TableRow>
                <BodyCell>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/form-submission/${sub.subId}`}>
                    {sub.form.name}
                    <OpenInNew style={{ fontSize: '14px', marginLeft: 2, width: 14, height: 14 }} />
                  </Link>
                </BodyCell>
                <BodyCell>
                  {sub.submitted ?
                    'Submitted (' + tzParseFormat(sub.submitted, tz, 'M/d/yyyy @ h:mm a') + ')'
                    :
                    'In Progress'}
                </BodyCell>
                <BodyCell>
                  {tzParseFormat(sub.created, tz, 'M/d/yyyy @ h:mm a')}
                </BodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    );
  };

  const formItemClick = (formId: number) => () => {
    newSubState.invoke({ formId, clientId: id }).then((sub) => {
      setAnchorEl(null);
      checkFinish(sub, true);
    });
  };

  useEffect(() => {
    if (locked) {
      encryptKey(locked);
      dispatch(patch({ locked: true }));
    }
  }, [locked]);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '20px' }}>
        <Row style={{
          marginTop: '10px',
          flexDirection: smallModal ? 'column' : 'row',
          alignItems: smallModal ? 'flex-start' : 'center',
        }}>
          <TextField
            type='date'
            name="apptDate"
            label="Appointment Date"
            value={apptDate}
            onChange={onApptDateChange}
            style={{ width: '135px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;
          <TextField
            type='date'
            name="subDate"
            label="Submission Date"
            value={subDate}
            onChange={onSubDateChange}
            style={{ width: '135px', marginLeft: 5, marginRight: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;
          <FormControl style={{ minWidth: '100px', marginRight: 5 }}>
            <InputLabel id="completed-label">Status</InputLabel>
            <Select
              labelId="completed"
              id="completed"
              value={isComplete}
              onChange={onChangedComplete}
              renderValue={(s) => s}
              input={<Input />}
            >
              {isCompleteOptions.map((t) => (
                <MenuItem key={t} value={t}>
                  <ListItemText primary={t} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          &nbsp;
          <TextField
            name="formName"
            label="Form Name"
            placeholder={'New Patient Form'}
            value={query.formName}
            onChange={(e) => setQuery({
              ...query,
              formName: e.target.value,
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ fontSize: '16px' }} />
                </InputAdornment>
              ),
            }}
          />
          <Spacer />
          <Button
            variant='contained'
            style={{ fontSize: 12, marginTop: smallModal ? 15 : 0, marginBottom: smallModal ? 8 : 0 }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
            disabled={newSubState.loading}
            startIcon={<OpenInNew style={{ fontSize: 16 }} />}>
            {newSubState.loading ? 'Loading...' : 'New submission'}
          </Button>

        </Row>
        <div>
          {listState.loading && <div className="loader" />}
          {!listState.loading && table()}
        </div>
      </div>
      <LockModal
        open={lockModal}
        close={() => setLockModal(false)}
        confirm={() => openInOfficeForm()}
      />
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        id="forms-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {formsState.data.map(({ id, name }: Form) => {
          return (
            <MenuItem
              key={id}
              onClick={formItemClick(id)}>
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ClientFormList;
