import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import {
  Paper,
  TextField,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Button,
} from '@mui/material';

import * as R from 'ramda';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { usePromise } from '../../../services/promise.hook';

import { TableContainer, HeaderCell, BodyCell } from '../../../components/CustomTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog.component';
import SelectOffices from '../../../components/SelectOffices/selectOffices.component';
import Loading from '../../../components/Loading/Loading.component';

import { successSnackbar, errorSnackbar, warningSnackbar } from '../../../components/Snackbar/snackbar.actions';

import {
  getUsers,
  getUserOffices,
  getFreeOffices,
  saveOffices,
  saveAgencyUser,
  saveOfficeUser,
  checkEmailIsTaken,
  deleteUser,
  editUser,
} from './users.service';

import { User, Office } from './users.types';

import { useStyles } from './users.styles';

type LoginState = {
  login: {
    officeAdmin: boolean;
    agency: boolean;
    admin: boolean;
    user: {
      id: number;
      email: string;
      userType: {
        UserAgency: {
          agencyId: number;
        }
      }
    };
  }
}

type UserPageProps = {
  isAdminSettings?: boolean;
  showNew?: boolean;
  setShowNew?: (value: boolean) => void;
}

const UsersPage = ({ isAdminSettings, showNew, setShowNew }: UserPageProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const agencyId = useSelector((state: LoginState) => state.login.user.userType?.UserAgency?.agencyId);
  const loggedUser = useSelector((state: LoginState) => state.login.user);

  const officeAdmin = useSelector((state: LoginState) => state.login.officeAdmin);
  const agency = useSelector((state: LoginState) => state.login.agency);
  const admin = useSelector((state: LoginState) => state.login.admin);

  const isOffice = isAdminSettings;

  const getAllUsers = () => {
    return getUsers(isOffice, loggedUser?.id);
  };

  const users = usePromise(getAllUsers, []);
  const selectedOffices = usePromise(getUserOffices, []);
  const offices = usePromise(getFreeOffices, { page: 1, offices: [], totalCount: 15, totalPages: 15 });

  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openOffices, setOpenOffices] = useState<User>({} as User);
  const [idToRemove, setIdToRemove] = useState(0);
  const [userEdit, setUserEdit] = useState<User>(null);
  const [name, setName] = useState('');
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [email, setEmail] = useState('');
  const [idsToAdd, setIdsToAdd] = useState<number[]>([]);
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);

  const handleCancel = () => {
    setIsNew(false);
    setEmail('');
    setName('');
  };

  const checkEmail = async (email: string) => {
    const emailValid = R.isNil(email) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    if (!emailValid) {
      setLoading(false);
      dispatch(warningSnackbar('Invalid Email'));
      return false;
    }
    const emailIsTaken = await checkEmailIsTaken(email);
    if (emailIsTaken) {
      setLoading(false);
      dispatch(warningSnackbar('Email is already being used'));
      return false;
    }
    return true;
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const emailIsValid = await checkEmail(email);
    if (!emailIsValid) return;

    let user: User;

    if (isOffice) {
      user = await saveOfficeUser(email, name);
    } else {
      user = await saveAgencyUser(email, name, agencyId);
    }

    if (!user) {
      dispatch(errorSnackbar('Error saving user'));
    } else {
      dispatch(successSnackbar('User created'));
    }

    if (user) {
      users.setState({
        ...users,
        data: R.sortBy(R.prop('name'), [...users.data, user]),
      });
    }
    setLoading(false);
    handleCancel();
  };


  const handleRemove = (id: number) => {
    setIdToRemove(id);
  };

  const confirmRemove = async () => {
    setIdToRemove(0);
    setLoading(true);
    const result = await deleteUser(idToRemove, isOffice);
    setLoading(false);
    if (result) {
      dispatch(successSnackbar('User deleted'));
    } else {
      dispatch(errorSnackbar('Failed to delete'));
    }
    users.invoke(loggedUser?.id);
  };

  const cancelRemove = () => {
    setIdToRemove(0);
  };

  const handleEdit = (user: User) => {
    setUserEdit(user);
    setNewName(user.name);
    setNewEmail(user.email);
  };

  const handleConfirmEdit = async () => {
    if (newName.length < 2) {
      dispatch(warningSnackbar('Invalid Name'));
      return;
    }
    setLoading(true);

    if (userEdit.email !== newEmail) {
      const emailIsValid = await checkEmail(newEmail);
      if (!emailIsValid) return;
    }
    const result = await editUser(userEdit.userId, newName, newEmail || undefined);
    setLoading(false);
    if (result) {
      dispatch(successSnackbar('User updated'));
    } else {
      dispatch(errorSnackbar('Failed to update'));
    }
    await users.invoke({});
    handleCancel();
  };

  const handleCancelEdit = () => {
    setUserEdit(null);
    setNewName('');
    setNewEmail('');
  };

  const cancelOpenOffices = () => {
    setOpenOffices({} as User);
    setIdsToAdd([]);
    setIdsToRemove([]);
  };

  const handleSaveOffices = async () => {
    setOpenOffices({} as User);
    setLoading(true);
    const result = await saveOffices(idsToAdd, idsToRemove, openOffices.userId);
    setLoading(false);
    if (result) {
      dispatch(successSnackbar('User updated'));
    } else {
      dispatch(errorSnackbar('Failed to update'));
    }
    cancelOpenOffices();
  };

  const handleOpenOffices = (user: User) => {
    selectedOffices.invoke(user.userId);
    offices.invoke({});
    setOpenOffices(user);
  };

  const searchOffices = async (search?: string) => {
    offices.invoke({ search });
  };

  const getMoreOffices = async (search?: string) => {
    const page = offices.data.page + 1;
    if (page > offices.data.totalPages || loadingMore) {
      return;
    }
    setLoadingMore(true);
    const result = await getFreeOffices({ page, search });
    offices.setState({
      ...offices,
      data: {
        offices: [...offices.data.offices, ...result.offices],
        page: result.page,
        totalCount: result.totalCount,
        totalPages: result.totalPages
      }
    });
    setLoadingMore(false);
  };

  const handleCreate = () => {
    setIsNew(true);
  };

  const handleAddOffice = (office: Office) => {
    selectedOffices.setState({
      ...selectedOffices,
      data: [...selectedOffices.data, office],
    });
    if (!R.includes(office.id, idsToAdd) && !R.includes(office.id, idsToRemove)) {
      setIdsToAdd([...idsToAdd, office.id]);
    }
    setIdsToRemove(idsToRemove.filter(item => item !== office.id));
  };

  const handleRemoveOffice = (office: Office) => {
    const newData = selectedOffices.data.filter(item => item.id !== office.id);
    selectedOffices.setState({
      ...selectedOffices,
      data: newData,
    });

    if (!R.includes(office.id, idsToRemove) && !R.includes(office.id, idsToAdd)) {
      setIdsToRemove([...idsToRemove, office.id]);
      offices.setState({
        ...offices,
        data: {
          ...offices.data,
          offices: R.sortBy(R.prop('name'), [...offices.data.offices, office]),
        }
      });
    }
    setIdsToAdd(idsToAdd.filter(item => item !== office.id));
  };

  useEffect(() => {
    users.invoke(loggedUser?.id);
  }, [loggedUser?.id]);

  useEffect(() => {
    if (showNew) {
      setIsNew(true);
      setTimeout(() => {
        setShowNew(false);
      }, 100);
    }
  }, [showNew]);

  if (!admin && !officeAdmin && !agency) {
    return <Navigate to='/' />;
  }

  return (
    <Paper className={isAdminSettings ? classes.settingsRoot : classes.root}>
      {!isAdminSettings && (
        <div className={classes.header}>
          <h3>Manage Users</h3>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={handleCreate}
          >
            Create
          </Button>
        </div>
      )}  
      <form role='form' onSubmit={handleSave}>
        <TableContainer style={{ marginRight: isAdminSettings ? -10 : 0, marginLeft: isAdminSettings ? -10 : 0 }}>
          <TableHead>
            <TableRow>
              <HeaderCell className={isAdminSettings ? classes.width30 : classes.columnName}>Name</HeaderCell>
              <HeaderCell>Login Email</HeaderCell>
              {!isOffice && <HeaderCell className={classes.smallColumn} align="center">Offices</HeaderCell>}
              <HeaderCell className={classes.smallColumn} align="center">Actions</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody className={isAdminSettings ? classes.boxShadow : ''}>
            {isNew && (
              <TableRow>
                <BodyCell className={classes.firstColumn}>
                  <TextField
                    label=''
                    value={name}
                    required
                    placeholder="Name"
                    onChange={(event) => setName(event.target.value)}
                  />
                </BodyCell>
                <BodyCell>
                  <TextField
                    label=''
                    value={email}
                    required
                    type="email"
                    placeholder="Email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </BodyCell>
                {!isOffice && (
                  <BodyCell align="center">
                    <IconButton
                      title='offices'
                      size='medium'
                      onClick={() => null}
                    >
                      <ApartmentIcon />
                    </IconButton>
                  </BodyCell>
                )}
                <BodyCell align="center" style={{ paddingRight: 5 }}>
                  <div className={classes.row}>
                    <IconButton
                      title='save'
                      color='default'
                      type="submit"
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      title='Cancel'
                      onClick={handleCancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </BodyCell>
              </TableRow>
            )}
            {
              users.data.map((user) => (
                <TableRow key={user.userId}>
                  <BodyCell className={classes.firstColumn}>
                    {user.userId === userEdit?.userId ? (
                      <TextField
                        label=''
                        value={newName}
                        required
                        placeholder="Name"
                        onChange={(event) => setNewName(event.target.value)}
                      />
                    ) : user.name}
                  </BodyCell>
                  <BodyCell>
                    {(user.userId === userEdit?.userId) ? (
                      <TextField
                        label=''
                        value={newEmail}
                        required
                        type="email"
                        placeholder="Email"
                        onChange={(event) => setNewEmail(event.target.value)}
                      />
                    ) : user.email}
                  </BodyCell>
                  {!isOffice && (
                    <BodyCell align="center">
                      <IconButton
                        title='offices'
                        size='medium'
                        onClick={() => handleOpenOffices(user)}
                      >
                        <ApartmentIcon />
                      </IconButton>
                    </BodyCell>
                  )}
                  <BodyCell align='center' style={{ paddingRight: 5 }}>
                    {userEdit?.userId === user.userId ? (
                      <div className={classes.row}>
                        <IconButton
                          title='save'
                          color='default'
                          onClick={handleConfirmEdit}
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          title='Cancel'
                          onClick={handleCancelEdit}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div className={classes.row}>
                        <IconButton
                          title='Edit'
                          color='default'
                          size='medium'
                          onClick={() => handleEdit(user)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          title='Remove'
                          color='error'
                          disabled={user.userId === loggedUser?.id}
                          onClick={() => handleRemove(user.userId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </BodyCell>
                </TableRow>
              ))
            }
          </TableBody>
        </TableContainer>
      </form>
      <ConfirmationDialog
        title="Remove User"
        text="Are you sure you want to remove this user?"
        open={!!idToRemove}
        cancel={cancelRemove}
        confirm={confirmRemove}
      />

      <SelectOffices
        open={!!openOffices.userId}
        title={`Offices Assigned to: ${openOffices.name || openOffices.email || ''}`}
        save={handleSaveOffices}
        cancel={cancelOpenOffices}
        onAdd={handleAddOffice}
        onRemove={handleRemoveOffice}
        selectedItems={selectedOffices.data}
        offices={offices.data.offices}
        getMore={getMoreOffices}
        searchOffices={searchOffices}
        loadingOffices={offices.loading}
        loadingMore={!offices.loading && loadingMore}
        loadingSelected={selectedOffices.loading}
      />
      <Loading loading={users.loading || loading} />
    </Paper>
  );
};

export default UsersPage;
