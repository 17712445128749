import React from 'react';
import * as R from 'ramda';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import * as at from '../../actionTypes';
import LocalSession from '../../services/local-session';
import { remoteAction } from '../../services/actionService.js';
import { patch as businessPatch } from '../Settings/routes/Business/business.actions';
import hb from 'honeybadger-js';
import api, {
  postAuth,
  setSessionHeaders,
  clearSessionHeaders,
  clearOfficeHeader,
  getNewToken,
  setOfficeHeader,
} from '../../services/api';
import { checkForProfessionals } from '../Professionals/professionals.actions.jsx';
import { getPlan } from '../Settings/routes/Subscription/subscription.actions.js';
import { getVerifiedEmail } from '../Settings/routes/General/general.actions.jsx';
import { messageEvent, messageThreadPatch } from '../Messages/routes/MessagesThread/messages-thread.actions.jsx';
import { messagesPatch } from '../Messages/messages.actions';
import { popupWithCustoms } from '../../services/Popup.js';
import { getFilterDataElse } from '../Tutorial/tutorial.actions.js';
import { RootState } from '../../reducers';
import { Office, USER_TYPES } from './login.reducer';
import axios from 'axios';
import { Banner } from '../../routes/Admin/Banners/banners.types';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';

export type PatchData = {
  office?: Office;
  locked?: boolean;
  status?: string;
  banners?: Banner[];
  theme?: 'sked' | 'spark';
  headerHeight?: number;
  sparkTab?: string;
  pageHeaderHeight?: number;
  query?: string;
  system?: string;
  desktopOpen?: boolean;
  openedFolder?: boolean;
  isMobile?: boolean;
}

export const patch = (data: PatchData) => ({
  type: at.LOGIN_PATCH,
  data,
});

export const resetSelectedOffice = () => (dispatch: Dispatch) => {
  if (messageEvent)
    messageEvent.close();

  clearOfficeHeader();
  dispatch({
    type: at.TUTORIALS_PATCH_FILTER_DATA,
    data: []
  });
  return dispatch({
    type: at.LOGIN_PATCH,
    data: {
      selected: false,
      tax: null,
    }
  });
};

type GetStore = () => RootState

let timer: number;
export const selectOffice = (office: Office, navigate: NavigateFunction, reload = false) => (dispatch: Dispatch, getStore: GetStore) => {
  setOfficeHeader(office.id);
  hb.setContext({
    office_id: office.id
  });
  dispatch({
    type: at.LOGIN_PATCH,
    data: {
      office,
      selected: true,
      query: '',
    }
  });
  if (reload) {
    window.location.reload();
  }
  if (timer)
    clearTimeout(timer);
  getFeatures()(dispatch);
  getPlan()(dispatch);
  getSettings()(dispatch);
  getEHRSettings(office.ehrSystem)(dispatch);
  getOfficeGroup(office.id)(dispatch);
  getVerifiedEmail()(dispatch);
  dispatch(businessPatch({ locations: [] }));
  messagesPatch('contactType', '')(dispatch);
  messagesPatch('inbox', [])(dispatch);
  dispatch(messageThreadPatch({
    clientId: null,
    conversationId: null,
    client: {},
    messages: [],
  }));
  return getStatus(navigate)(dispatch, getStore);
};

export type FilterOfficesByNameData = {
  query: string;
  system: string;
}
export const filterOfficesByName = (data: FilterOfficesByNameData) => ({
  type: at.OFFICE_FILTER_NAME,
  data,
});

export type FilterOfficesBySystemData = {
  query: string;
}
export const filterOfficesBySystem = (data: FilterOfficesBySystemData) => ({
  type: at.OFFICE_FILTER_SYSTEM,
  data,
});

export type ResyncType = {
  id: number;
  name: string;
  body?: {
    resyncClient: boolean;
    resyncAppts: boolean;
    resyncFromDays: number;
  };
}

export const resync = ({ id, name, body = null }: ResyncType) => {
  if (body && (body.resyncClient || body.resyncAppts)) {
    return remoteAction({
      type: at.OFFICE_RESYNC,
      action: () => api.post(`office/${id}/ResetHash/individual`, body).then(() => 'Re-Sync of ' + name + ' initiated.'),
      successText: 'Re-Sync of ' + name + ' initiated.',
    });
  }
  return remoteAction({
    type: at.OFFICE_RESYNC,
    action: () => api.post(`office/${id}/ResetHash`).then(() => 'Re-Sync of ' + name + ' initiated.'),
    successText: 'Re-Sync of ' + name + ' initiated.',
  });
};

export type IdNameType = {
  id: number;
  name: string;
}

export const checkDeleted = ({ id, name }: IdNameType) => remoteAction({
  type: at.OFFICE_RESYNC,
  action: () => api.post(`office/${id}/CheckDeleted`).then(() => {
    return 'Checking the deleted appointments of ' + name + ' initiated.';
  }),
  successText: 'Checking the deleted appointments of ' + name + ' initiated.',
});

export const destroyServer = ({ id, name }: IdNameType) => remoteAction({
  type: at.OFFICE_RESYNC,
  action: () => api.delete(`settings/platinum/${id}/syncer/destroy`, { data: {} }).then(() => {
    return 'Destroyed platinum server for ' + name + '.';
  }),
  successText: 'Destroyed platinum server for ' + name + '.',
});

export const rebuildServer = ({ id, name }: IdNameType) => remoteAction({
  type: at.OFFICE_RESYNC,
  action: () => api.delete(`settings/platinum/${id}/syncer/rebuild`, { data: {} }).then(() => {
    return 'Rebuild of platinum server for ' + name + ' initiated.';
  }),
  successText: 'Rebuild of platinum server for ' + name + ' initiated.',
});

export const refreshSelectedOffice = (navigate?: NavigateFunction) => (dispatch: Dispatch, getStore: GetStore) =>
  api.get('office').then((office) => {
    dispatch({
      type: at.LOGIN_PATCH,
      data: {
        office,
        selected: true,
      }
    });
    getFeatures()(dispatch);
    getPlan()(dispatch);
    getVerifiedEmail()(dispatch);
    return getStatus(navigate)(dispatch, getStore);
  });

export const getOffices = (agency = false) => (dispatch: Dispatch, getStore: GetStore) => {
  dispatch({
    type: at.LOGIN_PATCH,
    data: {
      busy: true,
    }
  });
  return api.get(agency ? 'office' : 'office/officeWithSpark')
    .then((offs) => {
      const offices = agency ? offs : offs.map((
        { isSpark, office }: { isSpark: boolean, office: Office }
      ) => ({
        isSpark,
        ...office,
      }));
      const store = getStore();
      const { query, system } = store.login;
      let shownOffices = [];
      if (store || system) {
        shownOffices = R.filter((office: Office) => {
          const searchBy = office.name.toLowerCase() + ' ' + String(office.id);
          const ehrSystem = R.prop('ehrSystem')(office);
          if (searchBy.indexOf(query) !== -1) {
            if (system) {
              return ehrSystem === system;
            }
            return true;
          }
          return false;
        })(offices);
      } else {
        shownOffices = offices;
      }
      return dispatch({
        type: at.LOGIN_GET,
        data: {
          offices,
          shownOffices,
          busy: false,
        }
      });
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

// I think this is only called when you login
// or when a page is refreshed
// NOT when an office is switched
// Important for the hiding groups switching feature
const getOffice = (userType: string) => remoteAction({
  type: at.OFFICE_REMOTE_GET,
  action: () => api.get('office').then(
    ([office]) => Promise.all([
      api.get(`office/${office.id}/address`),
      api.get('/office/features'),
      api.get('/office/settings'),
      R.cond([
        [R.equals('None'), () => api.get('/settings/none')],
        [R.equals('Platinum'), () => api.get('/settings/platinum')],
        [R.T, R.always(undefined)]
      ])(office.ehrSystem),
      api.get(`/office/group/${office.id}`),
    ]).then(([
      officeAddress,
      features,
      settings,
      ehrSettings,
      group
    ]) => {
      hb.setContext({
        office_id: office.id
      });
      return {
        office,
        userType,
        officeAddress,
        admin: userType === 'Admin',
        features,
        // This part is needed to enable office switching
        // for offices in a group where some offices have the
        // groups switching hidden
        parentOfficeFeatures: features,
        settings,
        ehrSettings,
        group,
      };
    }
    ))
});

export const getFeatures = () => remoteAction({
  type: at.OFFICE_REMOTE_GET_FEATURES,
  action: () => api.get('/office/features')
});

export const getSettings = () => remoteAction({
  type: at.OFFICE_REMOTE_GET_SETTINGS,
  action: () => api.get('/office/settings')
});

export const getEHRSettings = (ehr: string) => remoteAction({
  type: at.OFFICE_REMOTE_GET_EHR_SETTINGS,
  action: () => R.cond([
    [R.equals('None'), () => api.get('/settings/none')],
    [R.equals('Platinum'), () => api.get('/settings/platinum')],
    [R.T, () => new Promise<void>((res) => res())]
  ])(ehr)
});

export const getOfficeGroup = (id: number) => remoteAction({
  type: at.OFFICE_REMOTE_GET_OFFICE_GROUP,
  action: () => api.get(`/office/group/${id}`)
});

//TODO: this is the wrong endpoint, Jonny needs to make an endpoint we can hit for this
//      so this is a stand in for now
const getUser = () => remoteAction({
  type: at.USER_REMOTE_GET,
  action: () => api.get('user')
});

type StateType = {
  accept?: boolean;
  michael?: boolean;
}

export type CustomContentType = {
  setState: (state: StateType) => void;
  state: StateType;
}

export type CustomButtonType = {
  close: () => void;
  setState?: (state: StateType) => void;
  state: StateType;
}

// type UsageType = {
//   usage: number;
//   date: string;
//   amountPaid: number;
// }
const checkPolicies = (navigate: NavigateFunction) => (dispatch: Dispatch, getStore: GetStore) => {
  api.get('office/policy/cancellation-2020')
    .then(({ policy }) => {
      if (policy) {
        return null;
      } else {
        return setTimeout(() => {
          popupWithCustoms({
            title: 'SKED Policy Update',
            customContent: (t: CustomContentType) => {
              const {
                setState,
                state,
              } = t;
              return (
                <div>
                  There is a new update to the <a href='https://sked-v2.s3.amazonaws.com/documents/sked-cancellation-policy-2020.pdf' target='_blank' > SKED Cancellation Policy </a>!
                  < br />
                  To accept, please check the box below and click "Accept"
                  < br />
                  <br />
                  <label >
                    <input
                      type='checkbox'
                      checked={Boolean(state.accept)}
                      onChange={(e) => setState({ accept: e.target.checked })}
                    />
                    &nbsp;
                    I have read and accept the terms
                  </label>
                </div>
              );
            },
            customButtons: (t: CustomButtonType) => {
              const { state, close, } = t;
              return (
                <>
                  <HeaderButton
                    borderSolid
                    onClick={() => {
                      close();
                    }}
                    title="Cancel"
                  />
                  <HeaderButton
                    color='primary'
                    disabled={!state.accept
                    }
                    onClick={() => {
                      close();
                      api.post('office/policy', { document: 'cancellation-2020' });
                    }}
                    title='Accept'
                  />
                </>
              );
            },
          });
        }, 1000);
      }
    }).catch(() => {
      return null as string;
    });

  // const plan = R.pathOr(null, ['subscription', 'plan'], getStore());

  // if (plan && !R.includes(plan.plan, ['SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise',])) {
  //   api.get('office/policy/forced-new-plan-update-by-2022').then(({ policy }) => {
  //     if (!policy) {
  //       api.get('subscription/usage').then((usage: UsageType[]) => {
  //         const averageUsage = Number(R.pipe(R.map<UsageType, number>(R.propOr(0, 'usage')), R.reduce(R.add, 0))(usage)) / R.length(usage);
  //         const newPlan = averageUsage < 5000 ? 'Standard' : 'PRO';
  //         return popupWithCustoms({
  //           title: '{Action Required} Must upgrade to one of our 2 new plans by 12/31/21!',
  //           size: 'md',
  //           customContent: (t: CustomContentType) => {
  //             const {
  //               setState,
  //               state,
  //             } = t;
  //             return (
  //               <div>
  //                 We previously notified you that your SKED subscription will transition to one of our 2 new plans on 12 / 31 / 21.
  //                 <br />
  //                 <br />
  //                 <b > By acknowledging this message, you agree to email < a href="mailto:info@sked.life" > info@sked.life</a> specifying that you want to upgrade to Standard or PRO</b >
  //                 <br />
  //                 <br />
  //                 If you do not notify us of your upgrade choice, we'll handle it for you! There is nothing else you need to do. On 1/3/21, SKED will automatically upgrade you to our {newPlan} level and you can start taking advantage of the new features. If you would like to upgrade sooner, simply email us and let us know.
  //                 <br />
  //                 <br />
  //                 To acknowledge, please check the box below and click "Acknowledge"
  //                 <br />
  //                 <br />
  //                 <label >
  //                   <input
  //                     type='checkbox'
  //                     checked={Boolean(state.accept)
  //                     }
  //                     onChange={(e) => setState({ accept: e.target.checked })}
  //                   />
  //                   &nbsp;
  //                   I have read and acknowledge this upgrade
  //                 </label>
  //               </div>
  //             );
  //           },
  //           customButtons: (t: CustomButtonType) => {
  //             const { state, close, } = t;
  //             return (
  //               <>
  //                 <HeaderButton
  //                   borderSolid
  //                   onClick={() => {
  //                     close();
  //                   }}
  //                   title='Cancel'
  //                 />
  //                 <HeaderButton
  //                   color='primary'
  //                   disabled={!state.accept
  //                   }
  //                   onClick={() => {
  //                     close();
  //                     api.post('office/policy', { document: 'forced-new-plan-update-by-2022' });
  //                   }}
  //                   title='Acknowledge'
  //                 />
  //               </>
  //             );
  //           },
  //         });
  //       });
  //     }
  //   }).catch(() => {
  //     return null;
  //   });
  // }

  const taxTimeout = () => setTimeout(() => {
    const hasTCR = R.pipe(
      R.pathOr(null, ['login', 'features']),
      R.includes('TCRPopup')
    )(getStore());
    if (hasTCR) {
      popupWithCustoms({
        title: 'SKED SMS Compliance Requirement',
        size: 'md',
        customContent: () => {
          return (
            <div>
              <b>You are required to complete this by 2 / 9 / 23 or a $15 fee will be charged to your office.</b>
              <br />
              Due to updated SMS Messaging compliance regulations, SKED is now required to submit your office’s tax information to ensure deliverability.Please update this information ASAP to ensure SMS messages are delivered successfully.
            </div>
          );
        },
        customButtons: (t: CustomButtonType) => {
          const { close } = t;
          return (
            <>
              <HeaderButton
                onClick={() => {
                  close();
                }}
                borderSolid
                title='Later'
              />
              <HeaderButton
                color='primary'
                onClick={() => {
                  if (R.includes('sms-number', location.href)) {
                    close();
                  } else {
                    navigate('/settings/sms-number');
                  }
                }}
                title='Go to Tax page'
              />
            </>
          );
        },
      });
    }
  }, 1000);

  api.get('tcr/tax').then((tax) => {
    dispatch({
      type: at.LOGIN_PATCH,
      data: {
        tax,
      }
    });
    if (tax.ein) {
      return null;
    } else {
      return taxTimeout();
    }
  }).catch((error) => {
    if (error.response.status === 404) {
      dispatch({
        type: at.LOGIN_PATCH,
        data: {
          tax: { ein: false },
        }
      });
      return taxTimeout();
    }
    return null;
  });
};

export type Session = {
  status: string;
  access_token: string;
  refresh_token: string;
  expireDate: string,
  officeId?: number;
}

const setupSession = (session: Session, navigate: NavigateFunction) => async (dispatch: Dispatch, getStore: GetStore) => {
  const payload = R.pipe(
    R.split('.'),
    R.nth(1),
    atob,
    JSON.parse
  )(session.access_token);
  LocalSession.setSession(session);

  hb.setContext({
    user_id: payload.user,
    user_type: payload.userType
  });
  setSessionHeaders(session.access_token);
  if (payload.userType === 'Admin') {
    if (session.officeId !== undefined) {
      setOfficeHeader(session.officeId);
      return Promise.all([
        getOffice('Admin')(dispatch),
        refreshSelectedOffice()(dispatch, getStore)
      ]).then(() => {
        dispatch({ type: at.STATUS_UPDATE, data: { selected: true, admin: true, locked: false } });
        return dispatch({ type: at.SESSION_CREATE_SUCCESS, session });
      });
    }
    dispatch({ type: at.STATUS_UPDATE, data: { admin: true, locked: false } });
    return dispatch({ type: at.SESSION_CREATE_SUCCESS, session });
  }
  if (session.officeId !== undefined) {
    setOfficeHeader(session.officeId);
  }
  const user = await api.get('/user');
  if (payload.userType === USER_TYPES.AGENCY) {
    const agencyAdmin = user.userType.UserAgency.agentType === USER_TYPES.AGENCY_ADMIN;
    dispatch({ type: at.STATUS_UPDATE, data: { agencyAdmin, user, agency: true } });
    return dispatch({ type: at.SESSION_CREATE_SUCCESS, session });
  }
  if (payload.userType === 'Admin') {
    dispatch({ type: at.STATUS_UPDATE, data: { admin: true, user } });
    return dispatch({ type: at.SESSION_CREATE_SUCCESS, session });
  }
  if (payload.userType === USER_TYPES.AGENCY) {
    const agencyAdmin = user.userType.UserAgency.agentType === USER_TYPES.AGENCY_ADMIN;
    dispatch({ type: at.STATUS_UPDATE, data: { agencyAdmin, user, agency: true } });
    return dispatch({ type: at.SESSION_CREATE_SUCCESS, session });
  }
  const officeAdmin = payload.userType === USER_TYPES.OFFICE;
  dispatch({ type: at.STATUS_UPDATE, data: { user, officeAdmin } });
  return Promise.all([
    getOffice(payload.userType)(dispatch),
    getUser()(dispatch),
    getPlan()(dispatch),
    getVerifiedEmail()(dispatch),
  ]).then(() => {
    getStatus(navigate)(dispatch, getStore);
    checkPolicies(navigate)(dispatch, getStore);
    dispatch({ type: at.LOGIN_PATCH, data: { locked: false } });
    return dispatch({ type: at.SESSION_CREATE_SUCCESS, session });
  }).catch((error) => {
    console.log(error);
    return destroySession()(dispatch);
  });
};

export const getUserData = () => (dispatch: Dispatch) => {
  api.get('/user').then(user => {
    dispatch({ type: at.STATUS_UPDATE, data: { user } });
  });
};

type CreateSession = {
  email: string;
  password: string;
  genesis_token?: string;
}
type PopupType = (title: string, description: string) => void;

// Called at login
export const createSession =
 ({ email, password, genesis_token }: CreateSession, navigate: NavigateFunction, popup: PopupType) =>
   (dispatch: Dispatch, getStore: GetStore) => {
     dispatch({ type: at.SESSION_CHECKING });
     return postAuth({
       username: email,
       password,
       genesis_token,
       grant_type: genesis_token ? 'genesis_token' : 'password'
     }).then(async ({ data }: { data: Session }) => {
       const office = R.pipe(
         R.split('.'),
         R.nth(1),
         atob,
         JSON.parse
       )(data.access_token);

       const { userType } = office;
       if (userType !== 'Office' && userType !== 'Admin' && userType !== USER_TYPES.AGENCY && userType !== USER_TYPES.OFFICE_PEON) {
         const error = { message: 'client login attempt' };
         popup('Error!', 'Only offices can use the dashboard!');
         dispatch({ type: at.SESSION_CREATE_ERROR, error });
         return;
       }

       if (userType === 'Admin' && process.env.REACT_IS_ROOT === 'false') {
         const error = { message: 'admin login attempt outside of VPN' };
         popup('Error!', 'Admin users need to use the internal admin site on the VPN. Redirecting in 5 seconds...');
         setTimeout(() => {
           window.location.replace(process.env.ADMIN_APP_URL);
         }, 5e3);
         dispatch({ type: at.SESSION_CREATE_ERROR, error });
         return;
       }

       hb.setContext({
         user_id: office.user,
         user_email: email,
         user_type: office.userType
       });

       // dispatch(setupSession(data));
       LocalSession.setSession(data);
       setSessionHeaders(data.access_token);

       const user = await api.get('/user');

       if (userType === 'Admin') {
         dispatch({ type: at.STATUS_UPDATE, data: { admin: true, user } });
         return dispatch({ type: at.SESSION_CREATE_SUCCESS, session: data });
       }
       if (office.userType === 'Admin') {
         dispatch({ type: at.STATUS_UPDATE, data: { admin: true, locked: false } });
         dispatch({ type: at.SESSION_CREATE_SUCCESS, session: data });
         return;
       }

       if (userType === USER_TYPES.AGENCY) {
         const agencyAdmin = user.userType.UserAgency.agentType === USER_TYPES.AGENCY_ADMIN;
         dispatch({ type: at.STATUS_UPDATE, data: { agencyAdmin, user, agency: true } });
         return dispatch({ type: at.SESSION_CREATE_SUCCESS, session: data });
       }

       const officeAdmin = userType === USER_TYPES.OFFICE;
       dispatch({ type: at.STATUS_UPDATE, data: { user, officeAdmin } });

       return Promise.all([
         getPlan()(dispatch),
         getOffice(userType)(dispatch),
         checkForProfessionals()(dispatch),
         getVerifiedEmail()(dispatch),
       ]).then(() => {
         //]).then(([plan]) => {
         //Don't let the user log in if they don't have an active plan
         //but let sked support team through
         /* TODO: Agency users can't log in with this.  This needs a backend fix
      if(office.userType !== 'Admin' && !plan) {
        console.log('should not let user in');
        return dispatch(destroySession());
      }
      */
         getFilterDataElse()(dispatch, getStore).then(() => {
           getStatus(navigate)(dispatch, getStore); // gotta make sure we have the office in the store
           checkPolicies(navigate)(dispatch, getStore);
           dispatch({ type: at.SESSION_CREATE_SUCCESS, session: data });
         }).catch(() => {
           getStatus(navigate)(dispatch, getStore); // gotta make sure we have the office in the store
           checkPolicies(navigate)(dispatch, getStore);
           dispatch({ type: at.SESSION_CREATE_SUCCESS, session: data });
         });
       }).catch(() => {
         // console.log(error);
         return destroySession()(dispatch);
       });
     }).catch(error => {
       console.error(error.name);
       if (error.response) {
         R.cond([
           [R.equals(400), () => popup('Error!', 'Invalid character found username or password!')],
           [R.equals(403), () => popup('Error!', 'Incorrect username or password!')],
           [R.equals(502), () => popup('Error!', 'Unable to connect to the SKED server! Either the SKED server is down or your network is disconnected! Please check your network and try again!')],
           [R.equals(500), () => popup('Error!', 'The SKED server had an error! Try again later!')]
         ])(error.response.status);
       } else {
         popup('Error!', 'Unable to connect to the SKED server! Either the SKED server is down or your network is disconnected! Please check your network and try again!');
       }
       clearSessionHeaders();
       LocalSession.destroySession();
       dispatch({ type: at.SESSION_CREATE_ERROR, error });
     });
   };

export const destroySession = () => (dispatch: Dispatch) => {
  LocalSession.destroySession();
  clearSessionHeaders();
  if (messageEvent) {
    messageEvent.close();
  }
  dispatch({ type: at.LOGIN_RESET });
  dispatch({ type: at.SESSION_DESTROY });
  hb.resetContext();
};

export const generateJwt = (
  ssoType: 'zendesk', accessToken: string
): Promise<string> => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return axios.get(
    `${process.env.API_URL}/sso/${ssoType}`,
    config
  ).then(({ data }) => data);
};

export const createSessionFromRefresh = (refresh_token: string | string[], navigate: NavigateFunction) => (dispatch: Dispatch, getStore: GetStore) => {
  LocalSession.setSession({
    refresh_token,
  });
  checkUser(navigate, false)(dispatch, getStore);
};

//Called when try to access a private route
export const checkUser = (
  navigate: NavigateFunction, isSSO: boolean
) => (dispatch: Dispatch, getStore: GetStore) => {
  if (isSSO) {
    return;
  }
  const status = R.path(['session', 'status'], getStore());
  if (status === 'AUTHENTICATED') {
    return;
  }

  const oldSession = LocalSession.getSession();
  dispatch({ type: at.SESSION_CHECKING });
  if (oldSession === 'LOCKED') {
    dispatch(patch({ locked: true }));
  }
  if (oldSession && oldSession.refresh_token) {
    return getNewToken(oldSession.refresh_token).then((session: Session) => {
      return setupSession(R.assoc('officeId', oldSession.officeId, session), navigate)(dispatch, getStore);
    }).catch(() => {
      return dispatch({ type: at.SESSION_LOAD_ERROR });
    });
  }
  return dispatch({ type: at.SESSION_LOAD_ERROR });
};

export const getStatus = (navigate: NavigateFunction) => (dispatch: Dispatch, getStore: GetStore) => {
  const system = R.pathOr(null, ['login', 'office', 'ehrSystem'])(getStore());
  if (R.includes(system, ['ChiroTouch', 'Platinum'])) {
    api.get('status/current')
      .then((data) => {
        dispatch({
          type: at.STATUS_UPDATE,
          data: {
            status: data.status,
          }
        });
        timer = window.setTimeout(() => getStatus(navigate)(dispatch, getStore), 60e3 * 5);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            dispatch({
              type: at.STATUS_UPDATE,
              data: {
                status: 'Not Installed',
              }
            });
          }
        } else {
          console.log('offline');
          navigate('/offline');
        }
        timer = window.setTimeout(() => getStatus(navigate)(dispatch, getStore), 60e3 * 5);
      });
  } else if (system === null) {
    timer = window.setTimeout(() => getStatus(navigate)(dispatch, getStore), 1000);
  }
};

export const loginAndRedirect = (
  { email, password }: CreateSession,
  redirectUri: string,
  popup: PopupType
): Promise<string> => {
  return postAuth({
    username: email,
    password,
    grant_type: 'password'
  }).then(async ({ data }: { data: Session }) => {
    const office = R.pipe(
      R.split('.'),
      R.nth(1),
      atob,
      JSON.parse
    )(data.access_token);

    const { userType } = office;
    if (userType !== 'Office' && userType !== USER_TYPES.OFFICE_PEON) {
      popup('Error!', 'Only offices can use login to Zendesk!');
      return null;
    }

    const jwt = await generateJwt('zendesk', data.access_token);
    return jwt;
  }).catch((error) => {
    if (error.response) {
      R.cond([
        [R.equals(400), () => popup('Error!', 'Invalid character found username or password!')],
        [R.equals(403), () => popup('Error!', 'Incorrect username or password!')],
        [R.equals(502), () => popup('Error!', 'Unable to connect to the SKED server! Either the SKED server is down or your network is disconnected! Please check your network and try again!')],
        [R.equals(500), () => popup('Error!', 'The SKED server had an error! Try again later!')]
      ])(error.response.status);
    } else {
      popup('Error!', 'Unable to connect to the SKED server! Either the SKED server is down or your network is disconnected! Please check your network and try again!');
    }
    return null as string;
  });
};
