import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DialogContentText, CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Modal from '../../../components/Modal/Modal.component';
import api from '../../../services/api.js';
import { usePromise } from '../../../services/promise.hook';
import { MessageTable } from '../../RecurringMessage/components/messages-table.component.jsx';
import { copyMessage } from '../../RecurringMessage/recurring.actions';

type LoginState = {
  login: {
    office: {
      tz: string;
    },
  }
};

export const sparkTemplateMsgPath = 'tmp.msg.rcr.spark';

export const getTemplates = () => {
  return api.post('message/template/query', {
    page: 1,
    perPage: 1000,
    query: {
      path: {
        Exists: {
          isDirectDescendant: sparkTemplateMsgPath.split('.'),
        },
      },
    },
  });
};

interface MessageTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  selectTemplate: () => void;
}

const useStyles = makeStyles(() => ({
  modalContainer: {
    height: 800,
    width: 'calc(100% - 20px)',
    margin: '0px 10px',
  },
}));

const MessageTemplateDialog = ({
  open, onClose, selectTemplate
}: MessageTemplateDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const templatesState = usePromise(getTemplates, {
    page: 1, data: [], totalPages: 1, totalCount: 0,
  });

  useEffect(() => {
    if (open)
      templatesState.invoke({});
  }, [open]);

  const { tz } = useSelector((s: LoginState) => ({
    tz: s.login.office.tz,
  }));

  const {
    page,
    totalPages,
    totalCount,
    data,
  } = templatesState.data;

  return (
    <Modal
      title="SKED Automation Templates"
      size="md"
      open={open}
      onClose={onClose}
      className='sked-test-automation-templates-modal'
      buttons={[
        templatesState.loading &&
          <CircularProgress size={20} />
      ]}
    >
      <div className={classes.modalContainer}>
        <DialogContentText>
          Please select a template and be nice about it!
        </DialogContentText>
        {templatesState.loading &&
          <CircularProgress />}
        <MessageTable
          messageType='Automation'
          busy={templatesState.loading}
          url={'/automation/edit/0'}
          messages={data}
          tz={tz}
          pageName={'sked-templates'}
          error=''
          page={page}
          totalPages={totalPages}
          totalCount={totalCount}
          query={''}
          copyMessage={(msg: any) => {
            dispatch(copyMessage('COPY_AUTOMATION', true, msg));
            selectTemplate();
            onClose();
          }}
          filter={{
            value: 'IsEnabledAsc',
            field: 'IsEnabled',
            direction: 'Desc'
          }}
          heightDiff={0}
        />
      </div>
    </Modal>
  );
};

export default MessageTemplateDialog;
