import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: 200,
  },
  title: {
    margin: 0,
    padding: 0,
    fontWeight: 400,
    fontSize: 20,
  },
  tdName: {
    width: '55%'
  },
  tdId: {
    width: 50,
  },
  row: {
    '&:hover td': {
      backgroundColor: '#f5f5f5',
    }
  },
  active: {
    background: '#f5f5f5',
      
    '& td': {
      backgroundColor: '#f5f5f5 !important',
    },
  },
}));
