import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as rawActions from './messages.actions';
import { patch as loginPatch } from '../Login/login.actions';
import { IconButton, Tooltip, Grid } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';
import { reset } from './routes/MessagesNew/messages-new.actions.js';
import { PopupTemplate } from '../../services/Popup.js';
import { TemplatesTable, MessagesTable } from './components/message-table.component.jsx';
import { useTitle } from '../../services/useTitle';
import FolderIcon from '../../icons/Folder.icon';
import Sidebarfolders from '../../components/SidebarFolders/SidebarFolders.component';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';

const OneTimeMessagesTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    busy,
    all,
    messages,
    messagePage,
    totalMessagePages,
    totalMessageCount,
    pending,
    drafts,
    filter,
    page,
    query,
    state,
    tab,
    tz,
    templates,
    office,
    officeTemplates,
    features,
    templatePage,
    totalTemplatePages,
    totalTemplateCount,
    officeTemplatePage,
    totalOfficeTemplatePages,
    totalOfficeTemplateCount,
    headerHeight,
    openedFolder,
    noFolder,
    isMobile,
  } = useSelector((state) => ({
    ...state.messages,
    tz: R.pathOr(null, ['login', 'office', 'timezone'])(state),
    office: R.pathOr(null, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    headerHeight: state.login.headerHeight,
    openedFolder: state.login.openedFolder,
    isMobile: state.login.isMobile,
  }));

  const [search, setSearch] = React.useState({
    messages: '',
    pending: '',
    drafts: '',
    template: '',
    officeTemplates: ''
  });
  const [templateTab, setTemplateTab] = React.useState('your');
  const [folderHeight, setFolderHeight] = React.useState(0);
  const [selected, setSelected] = React.useState({
    messages: 0,
    sked: 0,
    your: 0,
  });
  const ref = React.useRef(null);

  useTitle('One Time Messages');

  const handleChange = (value) => {
    const type = (tab === 'template' && templateTab === 'your') ? 'officeTemplates' : tab;
    if (!(tab === 'template' && templateTab === 'sked')) {
      actions.selectAll({ type, value: false });
    }
    actions.messagesPatch('query', value);
    actions.messagesPatch('path', null);
    setSearch({ ...search, [type]: value });
    actions.search({ type, query: value, page: messagePage, filter, features: type === 'template' ? features : undefined });
  };

  const tableMaxHeight = `calc(100vh - ${headerHeight + folderHeight}px - 45px)`;

  const setSelectedId = (t, id) => {
    setSelected({ ...selected, [t]: id });
  };

  React.useEffect(() => {
    actions.getMessages(navigate, features);
    dispatch(reset());
  }, [office]);

  React.useEffect(() => {
    if (state === 'COPY') {
      navigate('/messages/new/0');
    }
  }, [state]);

  React.useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setFolderHeight?.(ref.current.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  const showFolder = React.useMemo(() => {
    return tab === 'messages' || (tab === 'template' && templateTab === 'your')
    || (tab === 'template' && templateTab === 'sked');
  }, [tab, templateTab]);
  
  const selectedId = React.useMemo(() => {
    if (tab === 'template') {
      return selected[templateTab] || 0;
    }
    return selected[tab] || 0;
  }, [tab, templateTab, selected]);

  if (!R.includes('OneTimeMessages', features)) {
    return (
      <FeatureDisabled title="One Time Messaging">
        <p>
          Blast off messages into the void of the internet with this _epic_ and cool feature!
        </p>
      </FeatureDisabled>
    );
  }

  return (
    <>
      <Header
        title='One Time Messages'
        pageId="one-time-messages"
        leftIcons={[
          ((!openedFolder || isMobile) && showFolder) ?
            <HeaderButton
              Icon={FolderIcon}
              onClick={() => dispatch(loginPatch({ openedFolder: true }))}
              title='Folders'
              onlyIcon
              marginRight={5}
              padding={7}
              active={!!selectedId}
              type='filter'
            /> : <div />,
          <HeaderTabs
            value={tab}
            setTab={(value) => actions.messagesPatch('tab', value)}
            subtabValue={templateTab}
            setSubtab={(value) => setTemplateTab(value)}
            tabs={[
              { label: 'Outbox', value: 'messages', className: 'sked-test-one-time-messages-outbox' },
              { label: 'Pending', value: 'pending', className: 'sked-test-one-time-messages-pending' },
              { label: 'Drafts', value: 'drafts', className: 'sked-test-one-time-messages-drafts' },
              {
                label: 'Templates',
                value: 'template',
                className: 'sked-test-one-time-messages-templates',
                subItems: [
                  { label: 'My Templates', value: 'your' },
                  { label: 'SKED Templates', value: 'sked' }
                ]
              },
            ]}
          />,
        ]}
        rightIcons={[
          <Tooltip placement="bottom" arrow title="Refresh">
            <IconButton className={busy ? 'sked-spin' : ''} onClick={() => actions.getMessages(navigate, features)}>
              <CachedIcon style={{ width: 17, height: 17 }} />
            </IconButton>
          </Tooltip>,
          <FilterText
            value={search[(tab === 'template' && templateTab === 'your') ? 'officeTemplates' : tab]}
            headerTitle='Search'
            onChange={handleChange}
            helperText='Search all messages'
          />,
          <HeaderButton
            title='New Message'
            className='sked-test-one-time-messages-compose'
            Icon={AddIcon}
            onClick={() => navigate('/messages/new/0')}
          />
        ]}
        smallTabsWidth={tab === 'template' ? 1138 : 864}
        onlyIconsWidth={650}
        breakPoints={[
          {
            width: 560,
            mobileItems: [2, 3, 4]
          },
          {
            width: 474,
            mobileItems: [1, 2, 3, 4]
          },
        ]}
      />
      <Grid display="flex">
        <PopupTemplate />
        <Grid>
          {tab === 'messages' && (
            <Sidebarfolders
              path={['msg', 'ot', 'outbox']}
              deselect={Boolean(query)}
              open={openedFolder}
              onClose={() => dispatch(loginPatch({ openedFolder: false }))}
              selectedId={selectedId}
              setSelectedId={(v) => setSelectedId(tab, v)}
              noFolder={noFolder}
              onClick={(path, noFolder = false) => {
                actions.messagesPatch('noFolder', noFolder);
                actions.selectAll({
                  type: 'messages',
                  value: false,
                });
                actions.search({
                  type: 'messages',
                  filter,
                  path,
                  page: 1,
                  noFolder,
                });
              }}
            />
          )}
          {tab === 'template' && templateTab === 'sked' && (
            <Sidebarfolders
              template
              path={['tmp', 'msg', 'ot']}
              deselect={Boolean(query)}
              open={openedFolder}
              onClose={() => dispatch(loginPatch({ openedFolder: false }))}
              selectedId={selectedId}
              setSelectedId={(v) => setSelectedId(templateTab, v)}
              noFolder={noFolder}
              onClick={(path, noFolder = false) => {
                actions.messagesPatch('noFolder', noFolder);
                actions.search({
                  type: 'template',
                  features,
                  filter,
                  path,
                  page: 1,
                  noFolder,
                });
              }}
            />
          )}
          {tab === 'template' && templateTab === 'your' && (
            <Sidebarfolders
              path={['msg', 'ot', 'tmp']}
              deselect={Boolean(query)}
              open={openedFolder}
              onClose={() => dispatch(loginPatch({ openedFolder: false }))}
              selectedId={selectedId}
              setSelectedId={(v) => setSelectedId(templateTab, v)}
              noFolder={noFolder}
              onClick={(path, noFolder = false) => {
                actions.messagesPatch('noFolder', noFolder);
                actions.selectAll({
                  type: 'officeTemplates',
                  value: false,
                });
                actions.search({
                  type: 'officeTemplates',
                  filter,
                  path,
                  page: 1,
                  noFolder,
                });
              }}
            />
          )}
        </Grid>
        <Grid flex={1}>
          <div hidden={tab !== 'messages'}>
            <MessagesTable
              busy={busy}
              messages={messages}
              url={'/messages/view/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'messages'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'messages'), actions.selectMessage)}
              copyMessage={actions.copyMessage}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              query={query}
              filter={filter}
              search={R.pipe(R.assoc('type', 'messages'), R.assoc('filter', filter), actions.search)}
              messagesPatch={actions.messagesPatch}
              pageName={'messages'}
              page={messagePage}
              totalPages={totalMessagePages}
              totalCount={totalMessageCount}
              path={['msg', 'ot', 'outbox']}
              maxHeight={tableMaxHeight}
            />
          </div>
          <div hidden={tab !== 'pending'}>
            <MessagesTable
              busy={busy}
              messages={pending}
              url={'/messages/new/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'pending'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'pending'), actions.selectMessage)}
              copyMessage={actions.copyMessage}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              query={query}
              filter={filter}
              search={R.pipe(R.assoc('type', 'pending'), R.assoc('filter', filter), R.assoc('page', page), actions.search)}
              messagesPatch={actions.messagesPatch}
              pageName={'pending'}
              maxHeight={tableMaxHeight}
            />
          </div>
          <div hidden={tab !== 'drafts'}>
            <MessagesTable
              busy={busy}
              messages={drafts}
              url={'/messages/new/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'drafts'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'drafts'), actions.selectMessage)}
              copyMessage={actions.copyMessage}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              query={query}
              filter={filter}
              search={R.pipe(R.assoc('type', 'drafts'), R.assoc('filter', filter), R.assoc('page', page), actions.search)}
              messagesPatch={actions.messagesPatch}
              pageName={'drafts'}
              error='Error: Failed to get drafts'
              maxHeight={tableMaxHeight}
            />
          </div>
          <div hidden={tab !== 'template'}>
            <TemplatesTable
              tab={templateTab}
              setTab={setTemplateTab}
              SkedTemplatesTable={() =>
                <MessagesTable
                  busy={busy}
                  messages={templates}
                  tz={tz}
                  copyMessage={actions.copyMessage}
                  pageName={'sked-templates'}
                  error='Error: Failed to get templates'
                  page={templatePage}
                  totalPages={totalTemplatePages}
                  totalCount={totalTemplateCount}
                  query={query}
                  filter={filter}
  
                  messagesPatch={actions.messagesPatch}
                  search={R.pipe(
                    R.assoc('type', 'template'),
                    R.assoc('filter', filter),
                    R.assoc('features', features),
                    actions.search
                  )}
                  maxHeight={tableMaxHeight}
                />
              }
              OfficeTemplateTable={() =>
                <MessagesTable
                  busy={busy}
                  messages={officeTemplates}
                  all={all}
                  tz={tz}
                  selectAll={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectAll)}
                  selectMessage={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectMessage)}
                  copyMessage={(d) => actions.copyMessage({ ...d, wasTemplate: true })}
                  deleteMessage={(d) => actions.deleteMessage(R.merge(d, { type: 'template' }))}
                  deleteSelected={(d) => actions.deleteSelected(R.merge(d, { type: 'template' }))}
                  pageName={'templates'}
                  // search={({ query }) => actions.localSearch('officeTemplates', query)}
                  error='Error: Failed to get templates'
                  page={officeTemplatePage}
                  totalPages={totalOfficeTemplatePages}
                  totalCount={totalOfficeTemplateCount}
                  messagesPatch={actions.messagesPatch}
                  query={query}
                  filter={filter}
                  search={R.pipe(
                    R.assoc('type', 'officeTemplates'),
                    R.assoc('filter', filter),
                    actions.search
                  )}
                  path={['msg', 'ot', 'tmp']}
                  maxHeight={tableMaxHeight}
                />
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OneTimeMessagesTemplate;
