import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  CircularProgress, TableHead, TableRow, TableBody,
  IconButton, Tooltip, Dialog, DialogActions, DialogContent,
  DialogTitle, Button, FormControlLabel, Checkbox, Grid
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNew from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import Archive from '@mui/icons-material/Archive';
import Unarchive from '@mui/icons-material/Unarchive';
import { makeStyles } from '@mui/styles';
import { UploadIcon } from '../../icons/CustomIcons';
import CloseIcon from '@mui/icons-material/Close';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component';
import * as clientActions from '../Clients/clients.actions';
import { Spacer } from '../../components/PageHeader';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import HeaderSearch from '../../components/HeaderSearch/HeaderSearch.component';
import FilterDate from '../../components/FilterDate/FilterDate.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import { TableContainer, HeaderCell, BodyCell } from '../../components/CustomTable';
import FormStatusIcon from '../../icons/FormStatus.icon';
import CreatedDateIcon from '../../icons/CreatedDate.icon';
import SubmissionDateIcon from '../../icons/SubmissionDate.icon';
import { successSnackbar, errorSnackbar, infoSnackbar } from '../../components/Snackbar/snackbar.actions';
import {
  fetchSubmissions, fetchSubmission, QuerySubmissionResponse, QuerySubmission, SubmissionQ,
  pushPdfToEhr, generatePdf, PdfGen, initPdfListener, closePdfEvents, SubmissionResponse,
  archiveSub,
} from './intake.service';
import {
  includes, pathOr, path, cond, identity, isNil, always, T, pipe, isEmpty,
  prepend, last, filter,
} from 'ramda';
import { usePromise } from './../../services/promise.hook';
import Loading from '../../components/Loading/Loading.component';
import {
  tzParseFormat,
  format,
} from '../../services/joda.js';
import {
  LocalDateTime,
} from '@js-joda/core';
import { useTitle } from '../../services/useTitle';
import { Submission } from './ServerTypes';
import SubmissionDialog from './submission-dialog.component';
import { getLocations } from '../Settings/routes/Business/business.actions.js';
import { useSelector } from '../../reducers';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    paddingBottom: 20,
  },
  pageControlsPadding: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
  },
  pageArrows: {
    marginLeft: '10px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  enlargedImageContainer: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  enlargedButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  checkbox: {
    paddingLeft: 4,
    marginRight: 0,

    '& .MuiCheckbox-root': {
      padding: 2,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 4,
    }
  }
}));

interface SubmissionsRowProps {
  sub: Submission;
  timezone: string;
  ehrSystem: string;
  hasPdf: boolean;
  phi: PdfGen;
  archived: boolean;
  onClick?: () => void;
}

const isCompleteOptions = ['Any', 'Submitted', 'In Progress', 'Sent'];

interface FilesDialogProps {
  submission: Submission;
  open: boolean;
  onClose: () => void;
}

const FilesDialog = ({ open, submission, onClose }: FilesDialogProps) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [enlarge, setEnlarge] = useState(null);
  const subState = usePromise<number, SubmissionResponse>(fetchSubmission, {
    revId: 0, answers: [], isComplete: false, uploaded: null, updated: null,
    officeNotes: [], revision: null, submitted: null,
  });
  useEffect(() => {
    if (open) {
      subState.invoke(submission.subId).then(({ answers }) => {
        setFiles(answers.filter(({ type }) => type === 'DocumentUpload'));
      });
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={onClose}>
      <DialogTitle>Uploaded Files</DialogTitle>
      <DialogContent>
        {enlarge &&
          <div
            className={classes.enlargedImageContainer}
            onClick={() => setEnlarge(null)}>
            <div style={{ position: 'relative' }}>
              <IconButton
                className={classes.enlargedButton}
                onClick={() => setEnlarge(null)}>
                <CloseIcon />
              </IconButton>
              <img src={enlarge} alt='Not an image file.' />
            </div>
          </div>}
        {subState.loading && <Loading loading />}
        {!subState.loading && isEmpty(files) ?
          <p>No files were uplaoded with this submission.</p>
          :
          <TableContainer>
            <TableHead>
              <TableRow>
                <HeaderCell>
                    File Name
                </HeaderCell>
                <HeaderCell>
                    Content
                </HeaderCell>
                <HeaderCell>
                    Actions
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map(({ value }) => {
                return value.map((url: string) => {
                  const fileName = decodeURIComponent(
                    decodeURIComponent(
                      decodeURI(
                        last(url.split('?')[0].split('/')).split(/_(.*)/s)[1])));
                  return (
                    <TableRow>
                      <BodyCell>
                        {fileName}
                      </BodyCell>
                      <BodyCell>
                        <div
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => setEnlarge(url)}
                        >
                          <img src={url} width='200px' alt='Not an image file.' />
                        </div>
                      </BodyCell>
                      <BodyCell>
                        <a href={url} download={fileName}>
                          <Button>Download</Button>
                        </a>
                      </BodyCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </TableContainer>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};


let w: Window;

const SubmissionRow = ({
  sub, timezone, ehrSystem, hasPdf, phi, archived, onClick,
}: SubmissionsRowProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generateState = usePromise<number, void>(generatePdf, null);
  const ehrState = usePromise<number, void>(pushPdfToEhr, null);
  const archiveState = usePromise<Submission, boolean>(archiveSub, null);
  const [loading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<'upload' | 'download'>(null);
  const [open, setOpen] = useState(false);
  const [uploaded, setUploaded] = useState(null);

  useEffect(() => {
    archiveState.setState({
      ...archiveState,
      data: null,
    });
  }, [sub]);

  useEffect(() => {
    if (phi && phi.submissionId === sub.subId) {
      if (action === 'download') {
        w.location = phi.url;
        w.focus();
        setLoading(false);
      } else
        ehrState.invoke(phi.phiId).then(() => {
          dispatch(successSnackbar('Successfully pushed PDF to EHR!'));
          setLoading(false);
          setUploaded(LocalDateTime.now());
        }).catch(() => {
          dispatch(errorSnackbar('Failed to pushe PDF to EHR'));
          setLoading(false);
        });
    }
    setAction(null);
  }, [phi]);

  const handleClick = (act: 'upload' | 'download') => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAction(act);
    setLoading(true);
    if (act === 'download') {
      w = window.open(`${process.env.APP_URL}/#/loading`, '_blank');
      return;
    }
    generateState.invoke(sub.subId)
      .then(() => {
        dispatch(successSnackbar('Successfully uploaded to EHR!'));
      }).catch(() => {
        setAction(null);
        dispatch(successSnackbar('Failed to upload to EHR!'));
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleArchive = (e: React.MouseEvent<HTMLButtonElement>) => {
    archiveState.invoke(sub).then((archived) => {
      dispatch(infoSnackbar(archived ? 'Archived' : 'Unarchived' + ' Submission'));
    });
    e.stopPropagation();
  };

  return (
    <>
      <FilesDialog
        open={open}
        onClose={() => setOpen(false)}
        submission={sub}
      />
      <TableRow
        key={sub.subId}
        style={{
          cursor: 'pointer',
          display: isNil(archiveState.data) ? undefined : 'none',
        }}
        onClick={() => navigate(`/form-submission/${sub.subId}`)}>
        <BodyCell fixed>
          <a onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}>
            {sub.client.firstName + ' ' + sub.client.lastName}
          </a>
        </BodyCell>
        <BodyCell minWidth={130}>
          {sub.form.name}
        </BodyCell>
        <BodyCell nowrap>
          {tzParseFormat(sub.created, timezone, 'MM-dd-yyyy h:mm a')}
        </BodyCell>
        <BodyCell nowrap>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {sub.submitted &&
              `Submitted (${tzParseFormat(sub.submitted, timezone, 'MM-dd-yyyy h:mm a')})`}
            {sub.started && !sub.submitted &&
              'In Progress'}
            {!sub.started && !sub.submitted &&
              'Sent'}
            {sub.uploaded &&
              <Tooltip title={`Uploaded on ${tzParseFormat(sub.uploaded || uploaded, timezone, 'MM-dd-yyyy h:mm a')}`} arrow>
                <CheckIcon style={{ color: 'green' }} />
              </Tooltip>}
            {uploaded &&
              <Tooltip title={`Uploaded on ${format(uploaded, 'MM-dd-yyyy h:mm a')}`} arrow>
                <CheckIcon style={{ color: 'green' }} />
              </Tooltip>}
          </div>
        </BodyCell>
        <BodyCell nowrap>
          {hasPdf &&
            <>
              {!includes(ehrSystem, ['None', 'ChiroHD']) &&
                !ehrState.loading && !generateState.loading && !loading &&
                <Tooltip arrow title='Upload to EHR'>
                  <IconButton onClick={handleClick('upload')}>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>}
              {(generateState.loading || ehrState.loading || loading) ?
                <CircularProgress size={18} style={{ marginRight: 5 }} />
                :
                <Tooltip arrow title='View PDF'>
                  <IconButton onClick={handleClick('download')}>
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
              }
            </>}
          {archiveState.loading ?
            <CircularProgress size={18} />
            :
            <Tooltip arrow title={archived ? 'Unarchive' : 'Archive'}>
              <IconButton onClick={handleArchive}>
                {archived ? <Unarchive /> : <Archive />}
              </IconButton>
            </Tooltip>}
          <Spacer />
        </BodyCell>
      </TableRow>
    </>
  );
};

let timeout: NodeJS.Timeout = null;
const IntakeSubmissions = () => {
  useTitle('Form Submissions');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [query, setQuery] = useState<SubmissionQ>({});
  const [client, setClient] = useState<string>('');
  const [phi, setPhi] = useState<PdfGen>(null);
  const [newSubmission, setNewSubmission] = useState(false);
  const [width, setWidth] = useState(0);
  const listState = usePromise<QuerySubmission, QuerySubmissionResponse>(fetchSubmissions, {
    page: 1,
    totalPages: 1,
    totalCount: 0,
    data: []
  });
  const [search, setSearch] = useState('client');
  const store = useSelector((state) => {
    const locations = pathOr([], ['business', 'locations'], state);
    const hasLocations = pipe(
      pathOr([], ['login', 'features']),
      includes('Locations')
    )(state);
    return ({
      isEnabled: includes('Forms', pathOr([], ['login', 'features'], state)),
      timezone: path<string>(['login', 'office', 'timezone'], state),
      locations: hasLocations && !isEmpty(locations) ?
        prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
      headerHeight: state.login.headerHeight,
      ehrSystem: path<string>(['login', 'office', 'ehrSystem'], state),
      hasPdf: includes('FormsPdf', pathOr([], ['login', 'features'], state)),
      clientsState: state.clients.state,
    });
  });

  useEffect(() => {
    dispatch(getLocations());
    if (store.hasPdf) {
      if (!initPdfListener(setPhi, 'FormPdfCreated')) {
        setTimeout(() => {
          initPdfListener(setPhi, 'FormPdfCreated');
        }, 500);
      }
    }
    return () => {
      closePdfEvents();
    };
  }, []);

  const handleClientName = (str: string) => {
    setClient(str);
    const splitUp = str.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
    setQuery({
      ...query,
      client: {
        firstName,
        lastName,
      },
    });
  };

  const refresh = () => {
    const noAppt = filter((i: any) => !isNil(i), query?.appointment || {});
    const noClient = filter((i: any) => !isNil(i), query?.client || {});
    const noEmptyQuery = {
      ...query,
      appointment: isEmpty(noAppt) ? undefined : noAppt,
      client: isEmpty(noClient) ? undefined : noClient,
    };
    listState.invoke({ page, perPage, query: noEmptyQuery });
  };

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      refresh();
    }, 500);
  }, [
    query, perPage, page,
  ]);


  const handleComplete = (value: string) => {
    if (value === isCompleteOptions[3]) {
      setQuery({
        ...query,
        started: false,
        isSubmitted: false,
      });
    } else {
      setQuery({
        ...query,
        isSubmitted: value === isCompleteOptions[0] ? undefined : value === isCompleteOptions[1],
        started: value === isCompleteOptions[2] ? true : undefined,
      });
    }
  };

  const isComplete = useMemo(() => {
    if (query.started === false) {
      return isCompleteOptions[3];
    }
    return cond([
      [isNil, always(isCompleteOptions[0])],
      [identity<boolean>, always(isCompleteOptions[1])],
      [T, always(isCompleteOptions[2])]
    ])(query.isSubmitted);
  }, [query.isSubmitted, query.started]);

  const apptDate = useMemo(() => {
    return query.appointment?.after ? query.appointment.after.split('T')[0] : '';
  }, [query.appointment]);

  const subDate = useMemo(() => {
    return query.submitted ? query.submitted.split('T')[0] : '';
  }, [query.submitted]);

  const createdDate = useMemo(() => {
    return query.created ? query.created.split('T')[0] : '';
  }, [query.created]);

  const handleApptDateChange = (date: string) => {
    if (date) {
      setQuery({
        ...query,
        appointment: {
          ...query.appointment,
          after: date + 'T00:00:00Z',
          before: date + 'T23:59:59Z',
        },
      });
    } else {
      setQuery({
        ...query,
        appointment: {
          ...query.appointment,
          after: undefined,
          before: undefined,
        },
      });
    }
  };

  const handleSubDateChange = (date: string) => {
    if (date) {
      setQuery({
        ...query,
        submitted: date + 'T00:00:00Z',
      });
    } else {
      setQuery({
        ...query,
        submitted: undefined,
      });
    }
  };

  const handleCreatedDateChange = (date: string) => {
    if (date) {
      setQuery({
        ...query,
        created: date + 'T00:00:00Z',
      });
    } else {
      setQuery({
        ...query,
        created: undefined,
      });
    }
  };

  const onCheckbox = () => {
    setQuery({
      ...query,
      archived: !query.archived,
    });
  };

  const addPage = (num: number) => {
    setPage(page + num);
  };

  const handleNewSubmission = () => {
    setNewSubmission(true);
  };

  const justIcon = useMemo(() => {
    return width < 1478;
  }, [width < 1478]);

  if (!store.isEnabled) {
    return (
      <>
        <Header title='Form Submissions' />
        <div className={classes.root}>
          <div style={{ padding: '10px 20px' }}>
            <p>
            The new patient portal has been completely rewritten to be fully customizable and easier to use!
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header
        title='Form Submissions'
        pageId='forms-submissions'
        getWidth={setWidth}
        leftIcons={[
          <FilterDate date={apptDate} setDate={handleApptDateChange} title='Appt. Date' />,
          <FilterDate date={subDate} Icon={SubmissionDateIcon} setDate={handleSubDateChange} title='Submission Date' />,
          <FilterDate date={createdDate} Icon={CreatedDateIcon} setDate={handleCreatedDateChange} title='Created Date' />,
          <FilterSelect
            items={isCompleteOptions}
            selected={isComplete}
            setSelected={handleComplete}
            Icon={FormStatusIcon}
            title='Form Status'
          />,
        ]}
        rightIcons={[
          <HeaderButton active={query.archived} type='hide' borderSolid>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={query.archived}
                  onChange={onCheckbox}
                  name="acknowledge-checkbox"
                />
              }
              label="Show Only Archived" />
          </HeaderButton>,
          <Grid marginRight="5px">
            <HeaderSearch
              justIcon={justIcon}
              placeholder={search === 'form' ? 'Form name' : 'LastName FirstName'}
              value={search === 'form' ? (query.formName || '') : client}
              onChange={(value) => {
                if (search === 'form') {
                  setQuery({
                    ...query,
                    formName: value,
                  });
                } else {
                  handleClientName(value);
                }
              }}
              options={[
                { value: 'client', label: 'Client Name' },
                { value: 'form', label: 'Form Name' },
              ]}
              selectedOption={search}
              setOption={(value) => setSearch(value)}
            />
          </Grid>,
          <HeaderButton className='sked-test-submissions-new-submission' title='New submission' onClick={handleNewSubmission} Icon={AddIcon} />
        ]}
        onlyIconsWidth={1190}
        breakPoints={[
          {
            width: 760,
            mobileItems: [0, 1, 2, 3]
          },
          {
            width: 546,
            mobileItems: [0, 1, 2, 3, 4]
          },
        ]}
      />
      <div
        className='page-root'
        style={{ overflowY: 'unset' }}
      >
        <div className={classes.root}>
          <SubmissionDialog
            isOpen={newSubmission}
            close={() => setNewSubmission(false)}
            refresh={refresh}
          />
          <ClientEditDialog
            open={store.clientsState === 'CLIENT_SELECT'}
            from={'CLIENTS'}
            onClose={() => dispatch(clientActions.backToList())}
            back={() => dispatch(clientActions.back())}
            gotoClient={() => dispatch(clientActions.back())}
          />
          {listState.loading && <Loading loading vh={50} />}
          {listState.errorMessage && <div>{listState.errorMessage}</div>}
          {listState.data.data.length > 0 &&
        <TableContainer
          maxHeight={`calc(100vh - ${store.headerHeight}px - 45px)`}
          pagination
          scrollPaddingBottom={20}
          paginationData={{
            addPage,
            currentLength: listState.data.data.length,
            page,
            perPage,
            totalCount: listState.data.totalCount,
            totalPages: listState.data.totalPages,
            setPerPage,
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell fixed>
                Client
              </HeaderCell>
              <HeaderCell>
                Form
              </HeaderCell>
              <HeaderCell>
                Created Date
              </HeaderCell>
              <HeaderCell>
                Status
              </HeaderCell>
              <HeaderCell>
                Actions
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listState.data.data.map((sub) => (
              <SubmissionRow
                sub={sub}
                phi={phi}
                archived={query.archived}
                onClick={() => dispatch(clientActions.selectClient(sub.client))}
                {...store}
              />
            ))}
          </TableBody>
        </TableContainer>}
        </div>
      </div>
    </>
  );
};

export default IntakeSubmissions;
