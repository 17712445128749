import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, NavigateFunction, useNavigate } from 'react-router-dom';
import {
  TextField, Checkbox, Select, InputAdornment, FormControl,
  InputLabel, IconButton, Menu, MenuItem, Tooltip, Grid, TableBody,
  TableRow, TableHead, Box
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import CachedIcon from '@mui/icons-material/Cached';
import Search from '@mui/icons-material/Search';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import * as R from 'ramda';
import OfficeGroups from './officeGroups.component';
import * as loginActions from '../../../Login/login.actions';
import { Office } from '../../../Login/login.reducer';
import { useSelector } from '../../../../reducers';
import OfficeFeatures from '../../../Admin/office-features.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';
import Container from '../../../../components/Container/Container.component';
import { useStyles } from './selectOffice.styes';
import SparkIcon from '../../../../icons/Spark.icon';

const searchOptions = [
  { value: '', label: 'All' },
  { value: 'ChiroTouch', label: 'ChiroTouch' },
  { value: 'ChiroHD', label: 'ChiroHD' },
  { value: 'Genesis', label: 'Genesis' },
  { value: 'None', label: 'None' },
  { value: 'Platinum', label: 'Platinum' },
  { value: 'PlatinumApi', label: 'Platinum API' },
];

type OfficeItemProps = {
  office: Office;
  admin: boolean;
  selectOffice: (office: Office) => void;
  resync: (event: React.MouseEvent<HTMLButtonElement>) => void;
  checkDeleted: (obj: object) => void;
  openGroupDialog: (obj: object) => void;
  openFeaturesDialog: (obj: object) => void;
  destroyServer: (obj: object) => void;
  rebuildServer: (obj: object) => void;
  index?: number;
  active?: number;
  setActive?: (n: number) => void;
}

const OfficeItem = ({
  office,
  admin,
  selectOffice,
  resync,
  checkDeleted,
  openGroupDialog,
  openFeaturesDialog,
  destroyServer,
  rebuildServer,
  active,
  index,
  setActive,
}: OfficeItemProps) => {
  const classes = useStyles();
  return (
    <TableRow
      key={office.id}
      onMouseEnter={() => setActive(-1)}
      className={`${active === index ? classes.active : ''} select-office-active-${index}`}
    >
      <BodyCell className="td-name">
        {office.id}
      </BodyCell>
      <BodyCell className="td-name">
        <a
          style={office.plan === 'SkedUnsubscribed' ? { color: 'gray' } : {}}
          onClick={() => selectOffice(office)}>
          {office.name}
        </a>
      </BodyCell>
      <BodyCell className="td-name">
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
        }}>
          <div style={{
            marginRight: 3,
          }}>
            {office.plan}
          </div>
          {office.isSpark && <SparkIcon />}
        </div>
      </BodyCell>
      <BodyCell className="td-name">
        {office.ehrSystem}
      </BodyCell>
      {admin && (
        <>
          <BodyCell className="td-name">
            <IconButton size="small" disabled={office.ehrSystem === 'None'} onClick={resync}>
              <CachedIcon fontSize='large' />
            </IconButton>
          </BodyCell>
          <BodyCell className="td-name">
            <IconButton size="small" disabled={office.ehrSystem === 'None'} onClick={() => checkDeleted({})}>
              <CachedIcon fontSize='large' />
            </IconButton>
          </BodyCell>
          <BodyCell className="td-name">
            <Tooltip arrow title='Destroy Server'>
              <IconButton
                size="small"
                disabled={office.ehrSystem !== 'PlatinumApi'}
                onClick={() => destroyServer({})}>
                <DeleteIcon fontSize='large' />
              </IconButton>
            </Tooltip>
            &nbsp;
            &nbsp;
            <Tooltip arrow title='Rebuild Server'>
              <IconButton
                size="small"
                disabled={office.ehrSystem !== 'PlatinumApi'}
                onClick={() => rebuildServer({})}>
                <BuildIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </BodyCell>
          <BodyCell className="td-name">
            <IconButton size="small" onClick={() => openGroupDialog({})}>
              <GroupIcon fontSize='large' />
            </IconButton>
          </BodyCell>
          <BodyCell className="td-name">
            <IconButton size="small" onClick={() => openFeaturesDialog({})}>
              <ShoppingCart fontSize='large' />
            </IconButton>
          </BodyCell>
        </>
      )}
    </TableRow>
  );
};

let timeout: NodeJS.Timeout;

function SelectOffice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [onlyActive, setOnlyActive] = useState(true);
  const [localState, setState] = useState('LIST');
  const [office, setOffice] = useState<Office>({} as Office);
  const [height, setHeight] = useState(97);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(-1);
  const searchEl = useRef(null);

  const {
    offices,
    allOffices,
    admin,
    agency,
    selected,
    state,
    system,
    query,
    resyncLoading,
  } = useSelector((state) => ({
    offices: R.sortBy(R.prop('name'))(state.login.shownOffices),
    allOffices: state.login.offices,
    admin: state.login.admin,
    agency: state.login.agency,
    selected: state.login.selected,
    state: state.session.status,
    system: state.login.system,
    query: state.login.query,
    resyncLoading: state.login.resyncLoading
  }));

  const [localQuery, setLocalQuery] = useState(query);

  const getOffices = () => dispatch(loginActions.getOffices(agency));
  const selectOffice = (office: Office, nav: NavigateFunction) => dispatch(loginActions.selectOffice(office, nav));
  const filterOfficesByName = (e: loginActions.FilterOfficesByNameData) => dispatch(loginActions.filterOfficesByName(e));
  const resync = (e: loginActions.ResyncType) => dispatch(loginActions.resync(e));
  const checkDeleted = (e: loginActions.IdNameType) => dispatch(loginActions.checkDeleted(e));
  const patch = (e: loginActions.PatchData) => dispatch(loginActions.patch(e));
  const destroyServer = (e: loginActions.IdNameType) => dispatch(loginActions.destroyServer(e));
  const rebuildServer = (e: loginActions.IdNameType) => dispatch(loginActions.rebuildServer(e));

  useEffect(() => {
    patch({ office: {} as Office, query: '', system: '' });
    getOffices();
    searchEl.current.focus();
    setLocalQuery('');
  }, []);

  const filteredOffices = React.useMemo(() => {
    // let o = offices;
    // if (offices.length > 50) {
    //   o = R.take(50, offices);
    // }
    return offices.filter(({ plan }) => {
      return onlyActive ? plan !== 'SkedUnsubscribed' : plan === 'SkedUnsubscribed';
    });
  }, [offices, onlyActive]);

  const [menu, setMenu] = React.useState({
    anchorEl: null,
    id: null,
    name: null
  });

  const handleResyncMenuOpen = ({ id, name, event }: { id: number, name: string, event: React.MouseEvent<HTMLButtonElement> }) => {
    setMenu({ anchorEl: event.currentTarget, id, name });
  };

  const handleClose = () => {
    setMenu({ anchorEl: null, id: null, name: null });
  };

  const resyncWithOptions = ({ resyncClient = false, resyncAppts = false, resyncAll = false }) => {
    const resyncAllBody = { resyncClient: true, resyncAppts: true, resyncFromDays };
    const body = resyncAll ? resyncAllBody : { resyncClient, resyncAppts, resyncFromDays };
    return resync({ id: menu.id, name: menu.name, body });
  };

  const [resyncFromDays, setResyncFromDays] = React.useState(30);

  React.useEffect(() => {
    if (!resyncLoading)
      setMenu({ anchorEl: null, id: null, name: null });
  }, [resyncLoading]);

  const paginatedItems = useMemo(() => {
    const startIndex = (page - 1) * perPage;
    return filteredOffices.slice(startIndex, startIndex + perPage);
  }, [filteredOffices, page, perPage]);

  useEffect(() => {
    filterOfficesByName({ query, system });
  }, [query, system]);

  const handleScroll = (ac: number) => {
    if (paginatedItems?.length > 8) {
      const items = document.getElementsByClassName(`select-office-active-${ac}`);
      if (items.length) {
        items[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const handleKeyPress = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      if (active >= 1) {
        event.preventDefault();
        setActive(active - 1);
        handleScroll(active - 1);
      }
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if ((active + 1) < paginatedItems.length) {
        setActive(active + 1);
        handleScroll(active + 1);
      }
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      const off = paginatedItems[active];
      if (off) {
        selectOffice(off, navigate);
      }
    }
  }, [active, paginatedItems]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Grid className={classes.root}>
      {state === 'NOT_AUTHENTICATED' && <Navigate to="/login" />}
      {(!admin && !agency) && <Navigate to='/' />}
      <OfficeGroups
        open={localState === 'OFFICE_GROUP'}
        office={office}
        offices={allOffices}
        onClose={() => {
          setState('LIST');
          setOffice({} as Office);
        }}
      />
      <OfficeFeatures
        open={localState === 'OFFICE_FEATURES'}
        office={office}
        onClose={() => {
          setState('LIST');
          setOffice({} as Office);
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={menu.anchorEl}
        keepMounted
        open={Boolean(menu.anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={resyncLoading} onClick={() => resyncWithOptions({ resyncAll: true })}>Resync All</MenuItem>
        <MenuItem disabled={resyncLoading} onClick={() => resyncWithOptions({ resyncAppts: true })}>Resync Appointments</MenuItem>
        <MenuItem disabled={resyncLoading} onClick={() => resyncWithOptions({ resyncClient: true })}>Resync Clients</MenuItem>
        {admin &&
            <Box>
              <TextField
                label="Resync from X days ago"
                value={resyncFromDays}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                onChange={(e) => {
                  setResyncFromDays(Number(e.target.value));
                }}
              />
            </Box>
        }
      </Menu>
      <Container getHeight={setHeight} padding={2} paddingBottom={1}>
        {admin && <h2 className={classes.title}>Offices</h2>}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {admin ? (
            <div>
              <FormControl style={{ width: '300px' }}>
                <InputLabel id="filter-label">EHR System</InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter-select"
                  value={system}
                  onChange={(e) => {
                    filterOfficesByName({ system: e.target.value as string, query });
                    setActive(0);
                    setPage(1);
                  }}
                >
                  {searchOptions.map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
                </Select>
              </FormControl>
              <label style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 8,
                marginLeft: -5,
              }}>
                <Checkbox
                  checked={!onlyActive}
                  onChange={(e) => {
                    setActive(0);
                    setOnlyActive(!e.target.checked);
                    setPage(1);
                  }}
                />
                Show Only Unsubscribed
              </label>
            </div>
          ) : (
            <h2 className={classes.title}>Offices</h2>
          )}

          <div className="search page">
            <TextField
              inputRef={searchEl}
              name="client-search"
              label="Search"
              variant='standard'
              value={localQuery}
              autoComplete="off"
              onChange={(e) => {
                setActive(0);
                const query = e.target.value;
                setLocalQuery(query);
                if (timeout) {
                  clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                  filterOfficesByName({
                    query,
                    system,
                  });
                  setPage(1);
                }, 200);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ fontSize: '16px' }} />
                  </InputAdornment>
                ),
              }} />
          </div>
        </div>
      </Container>
      {!selected ?
        <TableContainer
          maxHeight={`calc(100vh - ${height}px - 54px)`}
          pagination
          scrollPaddingBottom={20}
          paginationData={{
            currentLength: paginatedItems.length,
            page,
            setPerPage: (v) => {
              setActive(-1);
              setPerPage(v);
              setPage(1);
            },
            perPage,
            addPage: (value) => setPage(page + value),
            totalCount: filteredOffices.length,
            totalPages: Math.ceil(filteredOffices.length / perPage),
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell className={!admin ? classes.tdId : ''}>
                ID
              </HeaderCell>
              <HeaderCell className={!admin ? classes.tdName : ''}>
                Name
              </HeaderCell>
              <HeaderCell>
                Plan
              </HeaderCell>
              <HeaderCell>
                EHR System
              </HeaderCell>
              {admin && (
                <>
                  <HeaderCell>
                    Re-Sync
                  </HeaderCell>
                  <HeaderCell>
                    CheckDeleted
                  </HeaderCell>
                  <HeaderCell>
                    Platinum Syncer
                  </HeaderCell>
                  <HeaderCell>
                    Groups
                  </HeaderCell>
                  <HeaderCell>
                    Features
                  </HeaderCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map((office, index) => {
              return (
                <OfficeItem
                  key={office.id}
                  office={office}
                  admin={admin}
                  index={index}
                  active={active}
                  setActive={setActive}
                  selectOffice={(off) => selectOffice(off, navigate)}
                  // openMenu={handleResyncMenuOpen}
                  resync={(event) =>
                    ['PlatinumApi', 'ChiroHD'].some(ehr => office.ehrSystem === ehr) ?
                      handleResyncMenuOpen({ id: office.id, name: office.name, event }) :
                      resync({ id: office.id, name: office.name })
                  }
                  checkDeleted={R.pipe(
                    R.assoc('id', office.id),
                    R.assoc('name', office.name),
                    checkDeleted
                  )}
                  openGroupDialog={() => {
                    setOffice(office);
                    setState('OFFICE_GROUP');
                  }}
                  openFeaturesDialog={() => {
                    setOffice(office);
                    setState('OFFICE_FEATURES');
                  }}
                  destroyServer={R.pipe(
                    R.assoc('id', office.id),
                    R.assoc('name', office.name),
                    destroyServer
                  )}
                  rebuildServer={R.pipe(
                    R.assoc('id', office.id),
                    R.assoc('name', office.name),
                    rebuildServer
                  )}
                />
              );
            })}
          </TableBody>
        </TableContainer>
        : <Navigate to="/" />
      }
    </Grid>
  );
}

export default SelectOffice;
