import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '240px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    '@media print': {
      marginLeft: 0,
    },
  },
  contentScrollContainer: {
    maxWidth: 'calc(100vw - 240px)',
    maxHeight: 'calc(100vh - 55px)',
    overflow: 'clip',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
    },
  },
  '@media print': {
    contentScrollContainer: {
      maxHeight: '100%'
    }
  },
  contentInner: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    minWidth: '100%'
  },
  oldMain: {
    marginLeft: 150,
  },
  menuButton: {
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  desktopMenuButton: {
    marginRight: '5px',
    marginLeft: '-15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  key: {
    '& #notes-main': {
      position: 'absolute',
      left: '-255px',
      overflow: 'hidden',
      transition: '0.3s',
      padding: '20px',
      width: '0px',
      textDecoration: 'none',
      fontSize: '20px',
      borderRadius: '0px 5px 5px 0px',
      zIndex: theme.zIndex.drawer,
      float: 'right',
      height: '14px',
    },
    '& #notes-main-open': {
      right: 0,
      bottom: 0,
      width: '575px',
      height: '460px',
      border: '1px solid #525252 !important',
      padding: 0,
      position: 'absolute',
      left: '-255px',
      overflow: 'hidden',
      transition: '0.3s',
      textDecoration: 'none',
      fontSize: '20px',
      borderRadius: '0px 5px 5px 0px',
      zIndex: theme.zIndex.drawer,
      float: 'right',
    }
  },

  lockedDialog: {
    top: 0,
    left: 0,
    backgroundColor: '#f7f9fc',
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
  },
  lockedContainer: {
    height: '200px',
    maxWidth: '520px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '40px',
  },
}));
