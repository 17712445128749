import React, { useEffect, useState } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  merge, isNil, pipe, find, propEq, dissoc, any, values, propOr,
  includes, last, append, isEmpty,
} from 'ramda';
import {
  Button, Divider, Paper, Tooltip, IconButton,
  TextField, FormGroup, Select, MenuItem, CircularProgress, TableRow,
  TableBody, Grid,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as businessActions from '../Business/business.actions.js';
import { PopupTemplate, popupWithCancel } from '../../../../services/Popup.js';
import { countries, timezones } from '../../../../services/globals.js';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import HeaderTabs from '../../../../components/HeaderTabs/HeaderTabs.component';
import { TableContainer, BodyCell } from '../../../../components/CustomTable';
import { successSnackbar } from '../../../../components/Snackbar/snackbar.actions';
import { useSelector } from '../../../../reducers';
import '../Business/business.css';

const WideTextField = withStyles({
  root: {
    width: '100%',
    marginBottom: '10px',
  },
})(TextField);

const useStyles = makeStyles(() => ({
  paper: {
    padding: '10px',
    marginTop: '10px',
  },
  tab: {
    marginTop: '10px',
  },
  cancel: {
    marginLeft: 10
  },
  scroolContainer: {
    overflowX: 'auto',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      height: '0px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '6px',
      },
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        height: '3px',
        scrollbarWidth: 'thin',
      },
    }
  }
}));

interface OfficeAddressForm {
  type: 'Address';
  street: string;
  street2: string;
  city: string;
  zipCode: string;
  state: string;
  country?: string;
}

interface OfficeForm {
  type: 'Form';
  id: number;
  name: string;
  phone: string;
  email: string;
  address: OfficeAddressForm;
  timezone?: string;
}

interface DisplayEditBusinessProps {
  officeForm: OfficeForm;
  officeAddressForm: OfficeAddressForm;
  admin: boolean;
  handleClickSave: (office: OfficeForm, address: OfficeAddressForm) => void;
  edit?: boolean;
  removeLocation: (id?: number) => void;
  business: OfficeForm;
  businessAddress: OfficeAddressForm;
}

const defaultAddress: OfficeAddressForm = {
  type: 'Address',
  street: '',
  street2: '',
  city: '',
  zipCode: '',
  state: '',
};

const defaultOffice: OfficeForm = {
  type: 'Form',
  id: 0,
  name: '',
  phone: '',
  email: '',
  address: defaultAddress,
};

const DisplayEditBusiness = ({
  officeForm,
  officeAddressForm,
  admin,
  handleClickSave,
  edit = false,
  removeLocation,
  business,
  businessAddress,
}: DisplayEditBusinessProps) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(edit);
  const [office, setOffice] = useState<OfficeForm>(defaultOffice);
  const [address, setAddress] = useState<OfficeAddressForm>(defaultAddress);
  useEffect(() => {
    if (isEdit) {
      setOffice(officeForm);
      setAddress(officeAddressForm);
    }
  }, [isEdit]);
  const editOffice = (data: any) => {
    setOffice(merge(office, data));
  };
  const editOfficeAddress = (data: any) => {
    setAddress(merge(address, data));
  };
  const autoFill = () => {
    setOffice({
      ...business,
      id: office.id,
      timezone: undefined,
    });
    setAddress({
      ...businessAddress,
      country: undefined,
      street2: '',
    });
  };
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: isEdit ? 'flex-end' : 'space-between',
      }}>
        {isEdit ?
          <Button
            variant='contained'
            onClick={autoFill}>
            Auto-fill from business page
          </Button>
          :
          <p style={{ marginLeft: '10px', marginBottom: '20px', color: 'gray' }}>
            The location name, phone number, and address are displayed in the app.
          </p>}
        <div className="search">
          {!isEdit &&
            <IconButton
              style={{ marginRight: '10px' }}
              onClick={() => setIsEdit(true)}>
              <EditIcon />
            </IconButton>
          }
          {!isEdit && location &&
            <IconButton
              style={{
                marginRight: '10px',
              }}
              onClick={() => {
                removeLocation();
              }}>
              <TrashIcon />
            </IconButton>
          }
        </div>
      </div>
      <Paper className={classes.paper}>
        {isEdit ?
          <FormGroup>
            <legend style={{
              marginBottom: '10px',
            }}>Edit Location Information</legend>
            <WideTextField
              value={office.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOffice({ name: e.target.value })}
              placeholder={'Location Name'}
              label={'Location Name'} />
            <WideTextField
              value={office.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOffice({ email: e.target.value })}
              placeholder="Email"
              label={'Email'} />
            <WideTextField
              value={office.phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOffice({ phone: e.target.value })}
              placeholder="Phone Number"
              label="Phone Number" />
            {!isNil(address.country) &&
              <>
                <label>Country:</label>
                <Select
                  style={{ width: '100%' }}
                  value={find(propEq('value', address.country))(countries)}
                  onChange={e => editOfficeAddress({ country: e.target.value })}>
                  {countries.map((c) => (
                    <MenuItem key={c.value}>{c.label}</MenuItem>
                  ))}
                </Select>
              </>}
            <br />
            {admin && !isNil(office.timezone) &&
              <div>
                <label>Timezone:</label>
                <Select
                  style={{ zIndex: 100 }}
                  value={find(propEq('value', office.timezone))(timezones)}
                  onChange={e => editOffice({ timezone: e.target.value })}>
                  {timezones
                    .filter(({ country }) => country === address.country)
                    .map((tz) => (
                      <MenuItem key={tz.value}>{tz.label}</MenuItem>
                    ))}
                </Select>
                <br />
              </div>}
            <WideTextField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOfficeAddress({ street: e.target.value })}
              value={address.street}
              placeholder="Mailing Address"
              label="Mailing Address" />
            <WideTextField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOfficeAddress({ city: e.target.value })}
              value={address.city}
              placeholder="City"
              label="City" />
            <WideTextField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOfficeAddress({ state: e.target.value })}
              value={address.state}
              placeholder="State"
              label="State" />
            <WideTextField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => editOfficeAddress({ zipCode: e.target.value })}
              value={address.zipCode}
              placeholder="Zip"
              label="Zip" />
            <br />
            <Divider />
            <br />
            <div>
              <Button
                variant='contained'
                disabled={pipe(merge(dissoc('street2', address)), values, any(isEmpty))(office)}
                onClick={() => {
                  handleClickSave(office, address);
                  setIsEdit(false);
                }}>
                Save
              </Button>
              <Button
                onClick={() => {
                  setIsEdit(false);
                  if (removeLocation && office.id === 0)
                    removeLocation(office.id);
                }}
                variant="outlined"
                className={classes.cancel}
              >
                Cancel
              </Button>
            </div>
          </FormGroup>
          :
          <TableContainer>
            <TableBody>
              <TableRow>
                <BodyCell>Location Name</BodyCell>
                <BodyCell>{officeForm.name}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Email</BodyCell>
                <BodyCell>{officeForm.email}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Phone Number</BodyCell>
                <BodyCell>{officeForm.phone}</BodyCell>
              </TableRow>
              {!isNil(officeAddressForm.country) &&
                <TableRow>
                  <BodyCell>Country</BodyCell>
                  <BodyCell>{officeAddressForm.country}</BodyCell>
                </TableRow>}
              <TableRow>
                <BodyCell>Mailing Address</BodyCell>
                <BodyCell>{officeAddressForm.street}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>City</BodyCell>
                <BodyCell>{officeAddressForm.city}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>State</BodyCell>
                <BodyCell>{officeAddressForm.state}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Zip</BodyCell>
                <BodyCell>{officeAddressForm.zipCode}</BodyCell>
              </TableRow>
              {!isNil(officeForm.timezone) &&
                <TableRow>
                  <BodyCell>Timezone</BodyCell>
                  <BodyCell>{pipe(
                    find(propEq('value', officeForm.timezone)),
                    propOr(officeForm.timezone, 'label')
                  )(timezones) as string}</BodyCell>
                </TableRow>}
            </TableBody>
          </TableContainer>
        }
      </Paper>
    </>
  );
};

const Locations = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tab, setTab] = useState(null);
  const [saved, setSaved] = useState(false);
  const [tabsSize, setTabsSize] = useState(100);
  const [width, setWidth] = useState(480);
  const boxRef = React.useRef(null);

  const dispatch = useDispatch();
  const {
    getUser,
    getOffice,
    patch,
    getLocations,
    saveLocation,
    removeLocation,
  } = bindActionCreators(businessActions, dispatch);

  const {
    office,
    officeAddress,
    admin,
    locations,
    features,
  } = useSelector((state) => ({
    ...state.login,
    ...state.business,
  }));

  const busy = useSelector((state) => state.business.busy);

  const hasLocations = includes('Locations', features);
  useEffect(() => {
    getUser(navigate);
    getOffice(office.id, navigate);
    if (hasLocations)
      getLocations();
  }, []);

  useEffect(() => {
    getUser(navigate);
    getOffice(office.id, navigate);
  }, [office]);

  const unsavedNewLocation = React.useMemo(() => {
    return Boolean(find(propEq('id', 0), locations));
  }, [locations]);

  useEffect(() => {
    if (saved) {
      const nextTab = pipe(
        last,
        propOr(0, 'id'),
      )(locations);
      setTab(nextTab);
      setSaved(false);
    } else if (unsavedNewLocation) {
      setTab(0);
    } else if (!isEmpty(locations) && !tab) {
      const nextTab = locations[0].id || 0;
      setTab(nextTab);
    }

    let total = 0;
    locations.forEach((loc: OfficeForm) => {
      const size = loc.name ? loc.name.length : 'Unnamed Location'.length;
      const result = (size * 7.36) + 14 + 14 + 2;
      total += result;
    });
    setTabsSize(total);
  }, [locations]);

  useEffect(() => {
    if (tab === 0 && boxRef.current) {
      boxRef.current.scrollLeft += 500;
    }
  }, [tab]);

  const onMouseWheel = (e: { deltaY: number }) => {
    if (e.deltaY > 0 && boxRef?.current) {
      boxRef.current.scrollLeft += 100;
    } else {
      boxRef.current.scrollLeft -= 100;
    }
  };

  useEffect(() => {
    boxRef.current?.addEventListener('wheel', onMouseWheel);
    return () => {
      boxRef.current?.removeEventListener('wheel', onMouseWheel);
    };
  }, [boxRef?.current]);

  const onlyIconsWidth = tabsSize + 103 + 30 + 140 + 20;

  return (
    <>
      <Header
        title='Locations'
        pageId='locations'
        getWidth={setWidth}
        leftIcons={[
          <div ref={boxRef} style={{ maxWidth: width - 103 - 30 - 40 - 45 }} className={classes.scroolContainer}>
            <HeaderTabs
              tabs={locations.map((location: OfficeForm) => ({
                label: isEmpty(location.name) ? 'Unnamed Location' : location.name,
                value: location.id
              }))}
              value={tab}
              setTab={setTab}
              isSmall={onlyIconsWidth >= width}
            />
          </div>
        ]}
        rightIcons={[
          <Grid display="flex" marginRight="5px">
            {busy && <CircularProgress size={18} />}
          </Grid>,
          unsavedNewLocation ?
            <Tooltip
              title='Save or delete the new location before adding another!'
              placement='bottom'
              arrow
            >
              <div>
                <HeaderButton
                  title='New Location'
                  Icon={AddIcon}
                  onlyIcon={onlyIconsWidth >= width}
                  disabled
                />
              </div>
            </Tooltip>
            :
            <HeaderButton
              title='New Location'
              Icon={AddIcon}
              onClick={() => {
                patch({
                  locations: append(defaultOffice, locations)
                });
              }}
            />
        ]}
        onlyIconsWidth={onlyIconsWidth}
      />
      <div style={{ padding: 20, overflowY: 'auto' }}>
        <PopupTemplate />
        {
          locations.map((location: OfficeForm, idx: number) => {
            return (
              <div hidden={tab !== location.id} className={classes.tab}>
                <DisplayEditBusiness
                  key={location.id}
                  officeForm={location}
                  officeAddressForm={location.address}
                  admin={admin}
                  handleClickSave={(office, address) => {
                    setSaved(office.id === 0);
                    saveLocation(idx, officeAddress.country, {
                      ...office,
                      address,
                    });
                  }}
                  edit={location.id === 0}
                  removeLocation={() => {
                    const msg = 'This action cannot be undone!';
                    const msgLast = 'Are you sure you want to remove this location? Doing so will default all existing professionals and hours to the business page information. If you make a new location, you will need to manually add those professionals or hours to it. This action cannot be undone!';
                    popupWithCancel(
                      'Remove location',
                      locations.length === 1 ? msgLast : msg,
                      () => {
                        removeLocation(location.id, idx);
                        setSaved(true);
                        if (locations.length === 1) {
                          businessActions.defaultAllToLocation(locations[0].id).then(() => {
                            dispatch(successSnackbar('Professionals, office hours, and special hours updated!'));
                          });
                        }
                      },
                      'danger'
                    );
                  }}
                  business={office}
                  businessAddress={officeAddress}
                />
              </div>
            );
          })
        }
      </div>
    </>
  );
};

export default Locations;
