import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as rawActions from './recurring-view.actions.jsx';
import { pipe, pathOr, dissoc } from 'ramda';

import ViewMessage from '../../../../components/view-message.component';

function AutomatedMessagesView({ type }) {
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const props = useSelector((state) => ({
    ...state.viewRecurring,
    ...pipe(pathOr({}, ['login', 'office']), dissoc('email'))(state),
    office: pathOr({}, ['login', 'office'])(state),
    features: pathOr([], ['login', 'features'])(state),
    tz: pathOr({}, ['login', 'office', 'timezone'])(state),
    plan: pathOr('Sked', ['subscription', 'plan', 'plan'])(state),
    automations: pathOr([], ['automations', 'automations', 'data'])(state),
    headerHeight: state.login.headerHeight,
  }));

  const { id } = useParams();

  return (
    <ViewMessage
      {...props}
      messageType={type}
      urlId={id}
      actions={actions}
    />
  );
}
export default AutomatedMessagesView;
