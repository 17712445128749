import React from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../services/useTitle.js';
import { makeStyles } from '@mui/styles';
import Blocks from './components/blocks.component.jsx';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    paddingBottom: '50px'
  },
}));

function BlocksPage() {
  useTitle('Schedule Blocks Page');
  const { id } = useParams();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Blocks id={id} />
    </div>
  );
}

export default BlocksPage;
