import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCheckIsAdmin = (admin: boolean, sessionStatus: string) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!admin && sessionStatus === 'AUTHENTICATED') {
      navigate('/settings/select-office');
    }
  }, [admin, sessionStatus]);
};
