import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  IconButton, Tooltip, Grid,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import * as loginActions from '../../routes/Login/login.actions';

import {
  includes, equals, cond, T
} from 'ramda';
import './header.css';

import DircardDialog from '../DiscardDialog/DiscardDialog.component';
import BannerComponent from './banners.component';
import { Office, User } from '../../routes/Login/login.reducer';

import { useStyles } from './header.styles';

const discardList = ['messages/new/', 'recurring/edit/', 'birthday/edit/', 'reactivation/edit/', 'apptchange/edit/', 'smart-reply-old/edit/'];

const newStyle = {
  backgroundColor: '#FFF',
  color: 'rgb(117,117,117)'
};

type HeaderProps = {
  desktopOpen: boolean;
  setDesktopOpen: (open: boolean) => void;
  handleDrawerToggle: () => void;
  classes?: {
    menuButton?: string;
    desktopMenuButton?: string;
  };
  selected: boolean;
  status: string;
  office: Office;
  agency: boolean;
  user: User;
  admin: boolean;
}

const InnerHeader = ({
  handleDrawerToggle, desktopOpen, setDesktopOpen, classes,
  selected, status, office, agency, user, admin,
}: HeaderProps) => {
  const headerClasses = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const [discardOpen, setDiscardOpen] = React.useState(false);
  const getUserData = () => dispatch(loginActions.getUserData());

  const handleBack = () => {
    const pathname = location.pathname;
    for (let i = 0; i < discardList.length; i++) {
      if (pathname.includes(discardList[i])) {
        setDiscardOpen(true);
        return;
      }
    }
    navigate(-1);
  };

  const handleDiscard = () => {
    setDiscardOpen(false);
    navigate(-1);
  };

  useEffect(() => {
    if (!user.id) {
      getUserData();
    }
  }, [user]);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      dispatch(loginActions.patch({ headerHeight: ref.current?.clientHeight || 54 }));
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <Grid>
      <BannerComponent />
      <header key="header" style={newStyle} className={headerClasses.root}>
        {(!selected && (admin || agency)) && <Navigate to="/settings/select-office" />}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.menuButton}
          onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
        {!desktopOpen && <IconButton
          color="inherit"
          aria-label="open drawer"
          className={classes.desktopMenuButton}
          onClick={() => setDesktopOpen(true)}>
          <ArrowForwardIosIcon className={headerClasses.arrowIcon} />
        </IconButton>}
        <Grid display="flex" alignItems="center">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon className={headerClasses.navigateIcon} />
          </IconButton>
          <IconButton onClick={() => navigate(+1)}>
            <ArrowForwardIcon className={headerClasses.navigateIcon} />
          </IconButton>
        </Grid>
        <div style={{ flexGrow: 1 }}></div>
        <div className="header-right">
          {!includes(office.ehrSystem, ['Genesis', 'None', 'PlatinumApi', 'ChiroHD']) &&
            <h1 style={{ fontSize: 14 }}>
              {status}
              {cond([
                [equals('Connected'), () => (
                  <Tooltip
                    title="Your SKED server is alive and well!"
                    placement="bottom-start"
                    arrow>
                    <CheckCircleIcon
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '20px',
                        color: 'green',
                        marginLeft: '5px',
                        marginBottom: 2
                      }}
                    />
                  </Tooltip>
                )],
                [equals('Disconnected'), () => (
                  <Tooltip
                    title="Your SKED server is down! Contact us at info@sked.life for support."
                    placement="bottom-start"
                    arrow>
                    <ReportProblemIcon
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '20px',
                        color: 'red',
                        marginLeft: '5px',
                        marginBottom: 2
                      }}
                    />
                  </Tooltip>
                )],
                [equals('Not Installed'), () => (
                  <Tooltip
                    title="Your SKED server is down! Contact us at info@sked.life for support."
                    placement="bottom-start"
                    arrow>
                    <ReportProblemIcon
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '20px',
                        color: 'red',
                        marginLeft: '5px',
                        marginBottom: 2
                      }}
                    />
                  </Tooltip>
                )],
                [T, () => (<span />)]
              ])(status)}
            </h1>}
        </div>
        <DircardDialog
          open={discardOpen}
          onCancel={() => setDiscardOpen(false)}
          onDiscard={handleDiscard}
          title='Discard changes?'
          text="You haven't saved your changes. Are you sure you want to leave?"
        />
      </header>
    </Grid>
  );
};

const Header = React.memo(InnerHeader, (prev, next) => {
  return equals(prev, next);
});

export default Header;
