import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Grid, Popover, TextField, useMediaQuery, ButtonGroup, Button,
  IconButton, Typography,
} from '@mui/material';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { usePromise } from '../../../../services/promise.hook';
import {
  uploadAttachments, pollForStatus, deleteScheduledMessage,
} from '../../routes/MessagesThread/messages-thread.actions';
import { Client } from '../../../Clients/clients.types';
import { useSelector } from '../../../../reducers';
import {
  propOr, includes, map, reduce, forEach, remove, pathOr,
  isEmpty, dropLast,
} from 'ramda';
import { useStyles } from './inboxChat.styles';
import {
  PopupTemplate, popupWithCancel,
} from '../../../../services/Popup.js';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CloseIcon from '@mui/icons-material/Close';
import ChevronIcon from '../../../../icons/Chevron.icon';
import PaperClipIcon from '../../../../icons/PaperClip.icon';
import DeleteIcon from '../../../../icons/Delete.icon';
import PaperAirplaneIcon from '../../../../icons/Airplane.icon';
import ScheduleModal from './ScheduleModal.component';
import QuickReplyIcon from '../../../../icons/QuickReply.icon';
import { getFileSize } from '../../../../components/Editor/editor.component';
import { uploadSizeLimit } from '../../routes/MessagesThread/message-chat.page';
import { LocalDateTime } from '@js-joda/core';
import {
  tzParse, ordinal, format, now
} from '../../../../services/joda';
import { useDispatch } from 'react-redux';
import { Tooltip, } from '../../../../components/components.component';
import { Spacer } from '../../../../components/PageHeader';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import {
  replacePlaceholders
} from '../../../Settings/routes/QuickReplies/quick-replies.actions';
import {
  officePlaceholders, locationPlaceholders, apptPlaceholders,
} from '../../../../components/CreateMessage/CreateMessage.component';
import {
  clientPlaceholders, proPlaceholders
} from '../../../Settings/routes/QuickReplies/quick-replies.page';
import Placeholders, {
  Placeholder
} from '../../../../components/Placeholders/Placeholders.component';
import { Location } from '../../../../components/hours';
import {
  getLoc,
} from '../../../Settings/routes/Business/business.actions.js';
import {
  getPros,
} from '../../../../components/AutomationBasedMessage/automation-message.actions';
import {
  getAppts,
} from '../../../Clients/components/client-dialog/client-dialog.actions';
import QuickReply from '../../components/quick-reply.component';
import
WarningRectangle
  from '../../../../components/WarningRectangle/WarningRectangle.component';

const characterLimit = 160;
const unicodeCharacterLimit = 70;

const countCredits = (str: string) => {
  // eslint-disable-next-line no-control-regex
  const hasUnicode = /[^\u0000-\u00ff]/.test(str);
  const limit = hasUnicode ? unicodeCharacterLimit : characterLimit;
  const strLength = str ? str.length : 0;
  const segment = Math.ceil(strLength / limit);
  return {
    strLength,
    limit,
    segment
  };
};


export interface SubmitType {
  attachments: any[];
  text: string;
  scheduledFor: string;
  msgId?: number;
  usedQr?: number;
}

interface InboxTextBoxProps {
  handleSubmit: (tt: SubmitType) => void;
  client: Client;
  fullPage: boolean;
  formRef: { current: HTMLFormElement },
  body?: string;
  scheduled?: string;
  attachs?: any[];
  onCancel?: () => void;
  mId?: number;
  onAttach?: (n: number) => void;
  state: string;
  warningAnchorState?: [boolean, (newValue: boolean) => void];
  pageWidth: number;
}

const InboxTextBox = ({
  client, handleSubmit, fullPage, formRef, body = '', scheduled = '',
  attachs = [], onCancel, mId, onAttach, state, warningAnchorState = [null, null],
  pageWidth,
}: InboxTextBoxProps) => {
  const [warningAnchor, setWarningAnchor] = warningAnchorState;
  const classes = useStyles();
  const dispatch = useDispatch();
  const textFieldRef = useRef(null);
  const sendButtonRef = useRef(null);

  const showEmoji = useMediaQuery('(min-width:600px)');

  const [attachments, setAttachments] = useState([]);
  const [prev, setPrev] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [scheduleAnchor, setScheduleAnchor] = useState(null);
  const [scheduledFor, setScheduledFor] = useState('');
  const [text, setText] = useState('');
  const [msgId, setMsgId] = useState(null);
  const [openQR, setOpenQR] = useState<HTMLButtonElement>(null);
  const [usedQr, setUsedQr] = useState(null);

  const locState = usePromise<void, Location[]>(getLoc, []);
  const proState = usePromise(getPros, []);
  const apptState = usePromise(getAppts, {
    data: [],
  });

  const {
    isOptedOutTwoWay, createBusy, tz, hasScheduledSms,
    newlySentMessage, hasQuickReply, features, office,
  } = useSelector(state => ({
    features: pathOr([], ['login', 'features'], state),
    hasScheduledSms: includes(
      'ScheduledSms',
      pathOr([], ['login', 'features'], state)
    ),
    hasQuickReply: includes(
      'SmsQuickReply',
      pathOr([], ['login', 'features'], state)
    ),
    isOptedOutTwoWay: includes(
      'TwoWayMessages',
      pathOr([], ['messageThread', 'clientOptOut', 'optouts'], state)
    ),
    createBusy: state.messageThread.createBusy,
    tz: pathOr('', ['login', 'office', 'timezone'])(state),
    office: pathOr(null, ['login', 'office'])(state),
    newlySentMessage: state.messageThread.newlySentMessage,
  }));

  useEffect(() => {
    setText(body);
    setScheduledFor(scheduled);
    setMsgId(mId);
    /* when editting a scheduled msg with attachs, the attach
       isn't inside it an object it _is_ the obj */
    const at = attachs.map((a) => ({ attachment: a, progress: 100, file: undefined as File }));
    setAttachments(at);
    setPrev(at); // prevent attempting re-upload
  }, [body, scheduled, mId]);

  useEffect(() => {
    if (prev.length < attachments.length) {
      uploadAttachments(attachments, setAttachments);
      setPrev(attachments);
    }
    onAttach?.(attachments.length);
  }, [attachments]);

  useEffect(() => {
    if (newlySentMessage) {
      console.log('this is the data from quick message send', newlySentMessage);
      dispatch(pollForStatus({
        msgId: newlySentMessage.msg.msgId,
        tmpId: newlySentMessage.tmpId,
      }));
      setText('');
      setAttachments([]);
      setPrev([]);
    }
  }, [newlySentMessage]);

  const characterCount = useMemo(() => {
    const { strLength, limit, segment } = countCredits(text);
    return `${strLength}/${limit} | Credits: ${segment}`;
  }, [text]);

  const insertPlaceholder = (body: string, placeholder: string) => {
    const splitAt = (index: number, index2: number) => (x: string) => [x.slice(0, index), x.slice(index2)];
    const ctl = textFieldRef.current;
    const startPos = ctl.selectionStart;
    const endPos = ctl.selectionEnd;
    const splitBody = splitAt(startPos, endPos)(body);
    ctl.focus();
    const newBody = splitBody[0] + placeholder + splitBody[1];
    return newBody;
  };

  const handleEmojiSelect = (value: string) => {
    setText(insertPlaceholder(text, value));
    handleClose();
  };

  const insertPlaceholderValue = (v: string) => {
    const value = replacePlaceholders({
      body: v, locations: locState.data, office, client,
      appointments: apptState.data.data,
      professionals: proState.data, forreal: true,
    });
    setText(insertPlaceholder(text, value));
    setTimeout(() => {
      textFieldRef.current.focus();
    }, 500);
    if (value.includes('____')) {
      setWarningAnchor(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (ind: number) => () => {
    setAttachments(remove(ind, 1, attachments));
    setPrev(remove(ind, 1, prev));
  };

  const disableSend = useMemo(() => {
    return createBusy || (attachments.length === 0 && text.length === 0);
  }, [createBusy, attachments, text]);

  const openScheduleModal = () => {
    setScheduleAnchor(sendButtonRef.current);
  };

  const closeScheduleModal = () => {
    setScheduleAnchor(null);
  };

  const onDelete = (justQuit = false) => () => {
    if (msgId && !justQuit) {
      popupWithCancel(
        'Are you sure you want to delete?',
        'This will be permanently deleted and cannot be undone.',
        () => {
          dispatch(deleteScheduledMessage(msgId, client));
          setScheduledFor('');
          setText('');
          setMsgId(null);
        },
        'danger',
        'Delete'
      );
    } else {
      setScheduledFor('');
      setText('');
      setMsgId(null);
    }
  };

  const formattedScheduledFor = useMemo(() => {
    if (scheduledFor) {
      const tDate = scheduledFor.includes('Z') ? scheduledFor : scheduledFor + 'Z';
      const lDate = scheduledFor.includes('Z') ? dropLast(1, scheduledFor) : scheduledFor;
      const day = tzParse(tDate, tz);
      const ord = ordinal(day.dayOfMonth());
      return format(LocalDateTime.parse(lDate), `MMM d'${ord}' h:mma`);
    }
    return '';
  }, [scheduledFor]);

  const submit = (dt?: string) => {
    let sf = dt ? dt : scheduledFor;
    if (dt === 'now') {
      sf = undefined;
      dispatch(deleteScheduledMessage(msgId, client));
      setMsgId(null);
    }
    handleSubmit({
      ...submitValues,
      scheduledFor: sf,
    });
    setText('');
    setAttachments([]);
    setScheduledFor('');
    setPrev([]);
    setWarningAnchor(false);
  };

  const saveScheduledFor = (dt: string) => {
    setScheduledFor(dt);
    if (!isEmpty(text)) {
      submit(dt);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      files
    } = e.target;
    const size: number = reduce((a, c) => {
      return a + c.size;
    }, 0, files as unknown as Blob[]);
    if (size < uploadSizeLimit) {
      forEach((f) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
      }, files as unknown as Blob[]);
      const filesArray = map((f) => ({
        file: f,
        progress: 0,
      }))(files as unknown as File[]);
      setAttachments(attachments.concat(filesArray));
    } else {
      console.log('too big');
      alert('Error! MMS messages cannot contain attachments greater than 1.5MB!');
    }
  };

  const handleTextChange = (value: string) => {
    setText(value);
  };

  const submitValues = useMemo(() => {
    return {
      attachments,
      text,
      msgId,
      scheduledFor,
      usedQr
    };
  }, [attachments, text, msgId, scheduledFor, usedQr]);

  const noSms = useMemo(() => {
    return (!client.allowSMS || isOptedOutTwoWay);
  }, [client, isOptedOutTwoWay]);

  const openQuickReply = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenQR(e.currentTarget);
  };

  const closeQuickReply = () => {
    setOpenQR(null);
  };

  const acceptQuickReply = (body: string, id: number, fire: boolean, warning: boolean) => {
    setText(body);
    setOpenQR(null);
    setUsedQr(id);
    setWarningAnchor(warning);
    if (fire) {
      handleSubmit({
        scheduledFor: undefined,
        attachments,
        text: body,
        msgId,
        usedQr: id
      });
    }
  };

  const missingPlaceholders = React.useMemo(() => {
    return warningAnchor && text.includes('____');
  }, [warningAnchor, text]);

  const placeholders = React.useMemo(() => {
    const filterByFeatures = (placeholders: Placeholder[]) => {
      return placeholders.filter(({ feature }) => {
        if (feature) {
          return features.includes(feature);
        }
        return true;
      });
    };

    return [
      { title: 'Next Appointment', placeholders: filterByFeatures(apptPlaceholders), },
      { title: 'Client', placeholders: filterByFeatures(clientPlaceholders), },
      features.includes('Locations') &&
        { title: 'Location', placeholders: filterByFeatures(locationPlaceholders), },
      { title: 'Office', placeholders: filterByFeatures(officePlaceholders), },
      { title: 'Professional', placeholders: filterByFeatures(proPlaceholders), },
    ];
  }, [features]);

  useEffect(() => {
    if (warningAnchor && text === '')
      setWarningAnchor(false);
    if (!text.includes('____')) {
      setWarningAnchor(false);
    }
  }, [text]);

  useEffect(() => {
    if (client?.id && state === 'VIEW') {
      proState.invoke({});
      apptState.invoke({
        page: 1, perPage: 1, query: {
          clientId: client.id,
          after: format(now('tz', tz), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          status: ['Scheduled'],
        } });
      if (features.includes('Locations'))
        locState.invoke();
    }
  }, [client, features, state]);

  useEffect(() => {
    return () => {
      setWarningAnchor(false);
    };
  }, []);

  return (
    <>
      <PopupTemplate />
      <Grid display="flex" alignItems="center" flexDirection="column">
        {attachments.map((f, ind) => {
          const {
            file,
            progress,
            attachment,
          } = f;
          const url = propOr(false, 'attachmentUrl')(attachment);
          return (
            <div
              key={ind}
              style={{
                backgroundColor: 'lightgray',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '5px',
                borderRadius: '5px',
                marginBottom: '5px',
                width: '95%',
              }}>
              <div style={{
                display: 'flex',
              }}>
                <div className={classes.fileText}>
                  {url ?
                    <a href={url as string} target='_blank'>
                      {file ? file.name : attachment.attachmentName}</a>
                    :
                    <p style={{
                      margin: 'unset',
                    }}>
                      {file ? file.name : attachment.attachmentName} </p>}
                </div>
                &nbsp;
                {file && <p className={classes.fileSize}>{ '(' + getFileSize(file.size, true) + ')' }</p>}
              </div>
              {progress !== 100 ?
                <div style={{
                  width: '100px',
                  height: '10px',
                  backgroundColor: '#b3b3b3',
                }}>
                  <div style={{
                    width: progress + 'px',
                    height: '100%',
                    backgroundColor: '#008BCF',
                  }}></div>
                </div> :
                <CloseIcon className={classes.fileClose} onClick={handleDelete(ind)} />
              }
            </div>
          );
        })}
      </Grid>
      <Grid>
        <WarningRectangle
          open={warningAnchor}
          type={'warning'}
          onClose={() => setWarningAnchor(false)}
          title={'Client does not have a future appointment. Some placeholders weren\'t inserted.'}
          className={classes.warning}
        />
        <form
          ref={formRef}
          noValidate
          autoComplete="off"
          className={onCancel ? classes.formless : classes.form}
          style={{ marginRight: fullPage ? 10 : 0, marginLeft: fullPage ? 10 : 0 }}
          onSubmit={(event) => {
            event.preventDefault();
            submit();
          }}
        >
          <div className={classes.inputArea}>
            <TextField
              inputRef={textFieldRef}
              placeholder={client.firstName && noSms ? (client.firstName + ' disabled SMS notifications. 😢') : ''}
              value={text}
              multiline
              maxRows={4}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
                  e.preventDefault();
                  submit();
                }
              }}
              onChange={(event) => handleTextChange(event.target.value)}
              disabled={createBusy || !client.allowSMS || isOptedOutTwoWay}
              id="sms-chat-input"
              variant="outlined"
              className={classes.input}
            />
          </div>
          <Grid display="flex" alignItems="center" marginTop={0.5} style={{
            flexWrap: 'wrap', rowGap: 5,
          }}>
            <ButtonGroup
              variant='contained'
              className={classes.sendButtons}
              ref={sendButtonRef}
              sx={{
                boxShadow: 'unset',
              }}
            >
              {missingPlaceholders ?
                <Tooltip arrow title='Remove the missing placeholders to send. "____"'>
                  <span>
                    <Button
                      disabled={true}
                      aria-label='send'
                      className={
                        `${classes.sendButton} sked-test-sms-inbox-send-button`
                      }
                      style={{ height: 29 }}
                      type='submit'>
                      {msgId ? 'Save' : 'Send'}
                    </Button>
                  </span>
                </Tooltip> :
                <Button
                  disabled={disableSend}
                  aria-label='send'
                  className={
                    `${classes.sendButton} sked-test-sms-inbox-send-button`
                  }
                  type='submit'>
                  {msgId ? 'Save' : 'Send'}
                </Button>}
              {hasScheduledSms && !scheduledFor &&
                <Tooltip arrow title='Send Later'>
                  <Button
                    size="small"
                    aria-haspopup="menu"
                    onClick={openScheduleModal}
                    disabled={disableSend}
                    className={
                      `${classes.dropdownButton} sked-test-sms-inbox-dropdown-button`
                    }
                  >
                    <ChevronIcon color='white' className={classes.chevIcon} />
                  </Button>
                </Tooltip>}
              {hasScheduledSms && scheduledFor &&
                <Button
                  endIcon={<ChevronIcon color='#616166' className={classes.chevIconScheduled} />}
                  aria-haspopup="menu"
                  onClick={openScheduleModal}
                  className={
                    `${classes.dropdownButtonWithDt} sked-test-sms-inbox-dropdown-with-dt-button`
                  }
                >
                  {formattedScheduledFor}
                </Button>}
            </ButtonGroup>
            {hasScheduledSms && scheduledFor &&
                <Tooltip arrow title='Delete Scheduled SMS'>
                  <IconButton
                    onClick={onDelete()}>
                    <DeleteIcon className={classes.delete} />
                  </IconButton>
                </Tooltip>
            }
            {showEmoji && (
              <>
                <IconButton
                  type='button'
                  disabled={!client.allowSMS || isOptedOutTwoWay}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  onMouseDown={(event) => event.preventDefault()}
                  className='sked-test-sms-inbox-emoji-icon'
                >
                  <InsertEmoticonIcon />
                </IconButton>
                <input
                  accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.vcf"
                  style={{ display: 'none' }}
                  id="chat-attachment"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="chat-attachment">
                  <IconButton
                    component="span"
                    aria-label="Upload Attachment"
                    disabled={!client.allowSMS || isOptedOutTwoWay}
                    className='sked-test-sms-inbox-attachment-icon'
                  >
                    <PaperClipIcon />
                  </IconButton>
                </label>
              </>
            )}
            {hasQuickReply &&
                <>
                  <Placeholders
                    placeholders={placeholders}
                    insertFunction={insertPlaceholderValue}
                    isHTML={false}
                    isSMS={true}
                  />
                  <div>
                    <IconButton
                      aria-label="SMS Quick Reply"
                      disabled={!client.allowSMS}
                      className='sked-test-sms-inbox-quick-reply-icon'
                      onClick={openQuickReply}
                      style={{ padding: '15px 8px' }}
                    >
                      <QuickReplyIcon className={classes.quickreply} />
                    </IconButton>
                  </div>
                </>}
            <Typography className={classes.credits}>
              {characterCount}
            </Typography>
            <Spacer/>
            {onCancel &&
              <div className={classes.otherbuttons}>
                <HeaderButton
                  title='Send Now'
                  Icon={() =>
                    <PaperAirplaneIcon color={
                      disableSend ? 'rgba(0, 0, 0, 0.26)' : '#FFF'
                    } />
                  }
                  onClick={() => {
                    setScheduledFor('');
                    submit('now');
                  }}
                  active
                  type='archiveChat'
                  iconStyle={{ color: '#FFF' }}
                  disabled={disableSend}
                />
                <HeaderButton
                  title='Cancel'
                  onClick={onCancel}
                  type='hide'
                  disabled={disableSend}
                  className={classes.cancelbutton}
                  borderSolid={true}
                />
              </div>}
          </Grid>
        </form>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Picker native={true} title="Emoji Picker" onSelect={(event) => handleEmojiSelect((event as unknown as { native: string }).native)} />
        </Popover>
      </Grid>
      <ScheduleModal
        open={Boolean(scheduleAnchor)}
        anchorEl={scheduleAnchor}
        onClose={closeScheduleModal}
        onSave={saveScheduledFor}
        scheduledFor={scheduledFor}
      />
      <QuickReply
        open={Boolean(openQR)}
        onClose={closeQuickReply}
        onClick={acceptQuickReply}
        anchorEl={openQR}
        client={client}
        pageWidth={pageWidth}
      />
    </>
  );
};

export default InboxTextBox;
