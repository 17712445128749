import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useLocation, useParams, useBlocker } from 'react-router-dom';
import {
  CircularProgress, Typography, MenuItem, Grid,
  Button, ListItemText, ListItemIcon, ClickAwayListener,
  TextField, Select, FormControl, InputLabel, IconButton, Divider
} from '@mui/material';
import {
  Menu,
} from '../../components/components.component';
import { makeStyles } from '@mui/styles';
import SquareIcon from '@mui/icons-material/CropSquare';
import TitleIcon from '@mui/icons-material/Title';
import SubjectIcon from '@mui/icons-material/Subject';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTitle } from '../../services/useTitle';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { ConditionalIcon } from '../../icons/CustomIcons';
import CopyIcon from '../../icons/Copy.icon';
import PasteIcon from '../../icons/Paste.icon';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { isEmpty, isNil, last, } from 'ramda';
import { IntakeForm, FormChild } from './intake-types';
import { FormChildComponent } from './components/FormChild.component';
import ConditionalLogicDialog from './components/conditionals.component';

import * as actions from './intake.service';
import * as ctx from './intake.context';

import { Row, Spacer } from '../../components/PageHeader';

import { usePromise } from './../../services/promise.hook';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import SaveButton from '../../components/SaveButton/SaveButton.component';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../reducers';
import { CrForm, Form } from './ServerTypes';
import { SettingsDialog } from './intake-list.page';
import Delete from '@mui/icons-material/Delete';
import { TipTap } from '../../components/Editor/editor.component';
import {
  successSnackbar
} from '../../components/Snackbar/snackbar.actions';


interface SectionEditProps {
  data: Form;
  onSave: (form: CrForm) => void;
}

const urlBase = process.env.PORTAL_URL + '/';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    padding: '20px',
    paddingBottom: '100px',
    fontSize: 14,

    '& h1': {
      fontSize: 25,
    },
    '& h2': {
      fontSize: 20,
    },
  },
  title: {
    fontSize: '36px !important',
  },
  section: {
    fontSize: 20,
  },
  paper: {
    padding: '20px',
    marginBottom: '20px'
  },
  formControl: {
    margin: '10px 0'
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  input: {
    display: 'none',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    borderTop: 'solid lightgray 1px',
  },
  buttonSpacer: {
    flexGrow: 1,
  },
  '@media screen and (min-width: 1300px)': {
    buttonSpacer: {
      flexGrow: 1,
    },
  },
  '@media screen and (max-width: 1300px)': {
    buttonSpacer: {
      flexGrow: 0.75,
    },
  },
  '@media screen and (max-width: 912px)': {
    buttonSpacer: {
      flexGrow: 0.5,
    },
  },
  '@media screen and (max-width: 730px)': {
    buttonSpacer: {
      flexGrow: 0,
    },
  },
  infoDiv: {
    '& p': {
      all: 'revert',
    },
  },
  copy: {
    height: 20,
    width: 20,
    marginLeft: 2,
  },
}));

const CopyPasteHeaderButton = ({ onlyIcon }: { onlyIcon?: boolean }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [hasPaste, setHasPaste] = useState(null);

  const revision = useSelector(state => state.forms.revision.children);

  const openMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const copy = useCallback(() => {
    localStorage.setItem('sked-forms-copy', JSON.stringify({
      id: 'Revision',
      data: revision
    }));
    setAnchorEl(null);
    dispatch(successSnackbar('Entire form copied'));
  }, [revision]);

  const paste = useCallback((type: 'top' | 'bottom') => () => {
    const ls = JSON.parse(localStorage.getItem('sked-forms-copy'));
    dispatch(actions.formsPatch({
      busy: true,
    }));
    if (ls.id === 'Revision') {
      dispatch(actions.pasteEntireForm({
        data: ls.data,
        type,
      }));
    } else if (type === 'bottom') {
      const lastChild = last(revision);
      const ct = lastChild.type === 'PageBreak' ? 'page' : 'section';
      const index = ctx.getIndex(revision, ct, lastChild.id);
      dispatch(ctx.addFormChildAfter(ls.id, lastChild.id, index, ls.data));
    } else {
      const firstChild = revision[0];
      const ct = firstChild.type === 'PageBreak' ? 'page' : 'section';
      const index = ctx.getIndex(revision, ct, firstChild.id);
      dispatch(ctx.addFormChildBefore(ls.id, firstChild.id, index, ls.data));
    }
    setAnchorEl(null);
    dispatch(successSnackbar('Entire form pasted'));
  }, [revision]);

  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('sked-forms-copy'));
    if (ls) {
      setHasPaste(ls.id !== 'Question');
    }
  }, [anchorEl]);

  return (
    <>
      <HeaderButton
        onClick={openMenu}
        Icon={CopyIcon}
        title='Copy/Paste'
        className='sked-test-form-builder-copypaste-button'
        onlyIcon={onlyIcon}
      />
      <Menu
        id="simple-menu-main"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={copy}>
          <ListItemIcon>
            <CopyIcon className={classes.copy} />
          </ListItemIcon>
          <ListItemText
            primary='Copy entire form'
          />
        </MenuItem>
        <MenuItem disabled={!hasPaste} onClick={paste('top')}>
          <ListItemIcon>
            <PasteIcon className={classes.copy} />
          </ListItemIcon>
          <ListItemText
            primary='Paste at the top'
          />
        </MenuItem>
        <MenuItem disabled={!hasPaste} onClick={paste('bottom')}>
          <ListItemIcon>
            <PasteIcon className={classes.copy} />
          </ListItemIcon>
          <ListItemText
            primary='Paste at the bottom'
          />
        </MenuItem>
      </Menu>
    </>
  );
};

const EditFormComponent = ({ data, onSave }: SectionEditProps) => {
  const [state, setState] = useState<CrForm>({
    name: data.name.trim(),
    description: data.description,
    sendNotification: !!data.sendNotification,
    id: data.id,
  });

  const handleChange = (prop: keyof CrForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setState(s => ({ ...s, [prop]: name }));
  };
  return (
    <div>
      <ClickAwayListener onClickAway={() => {
        if (state.name.trim() !== '') {
          onSave({
            ...state,
            name: state.name.trim(),
            description: state.description.trim()
          });
        }
      }}>
        <div>
          <TextField
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
            value={state.name}
            onChange={handleChange('name')}
            error={state.name.trim() === ''}
            helperText={state.name.trim() === '' && 'This cannot be empty!'}
            type="text"
            label="Form Name"
          />
          <br />
          <br />
          <TextField
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
            value={state.description}
            onChange={handleChange('description')}
            type="text"
            label="Form Description"
            multiline
            minRows={4}
            variant="outlined"
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

interface UploadLogoProps {
  data: IntakeForm;
}

const alignmentOptions = [
  'Left', 'Center', 'Right',
];

const UploadLogo = ({ data }: UploadLogoProps) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const attachmentButtonRef = React.useRef(null);
  const uploadImageState = usePromise<File, string>(actions.uploadImage, null);
  useEffect(() => {
    if (file)
      uploadImageState.invoke(file).then((logoUrl: string) => {
        dispatch(ctx.editRevision({ logoUrl }));
      });
  }, [file]);
  const updateLogoAlignment = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(ctx.editRevision({ logoAlignment: e.target.value }));
  };
  return (
    <div style={{
      display: 'flex',
      marginTop: '10px',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}>
      <input
        accept="image"
        style={{ display: 'none' }}
        id="contained-button-logo-file"
        type="file"
        ref={attachmentButtonRef}
        disabled={uploadImageState.loading}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const {
            files
          } = e.target;
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          setFile(files[0]);
          attachmentButtonRef.current.value = null;
        }}
      />
      <label htmlFor="contained-button-logo-file" style={{ width: '130px' }}>
        <Button
          variant='contained'
          color="primary"
          component="span"
          className='sked-test-form-builder-edit-upload-logo'
          aria-label="Upload Logo">
          Upload logo
        </Button>
      </label>
      {
        data.logoUrl &&
        <IconButton
          className='sked-test-form-builder-logo-delete-button'
          onClick={() => dispatch(ctx.editRevision({ logoUrl: null }))}>
          <Delete />
        </IconButton>
      }
      {uploadImageState.loading && <CircularProgress />}
      {data.logoUrl &&
        <FormControl>
          <InputLabel>Logo alignment</InputLabel>
          <Select
            labelId='select-alignment-label'
            value={data.logoAlignment ? data.logoAlignment : 'Center'}
            onChange={updateLogoAlignment}
            label='Logo Alignment'
            style={{ width: '150px' }}
            className='sked-test-form-builder-logo-alignment'
          >
            {alignmentOptions.map((value) => (
              <MenuItem
                className={`sked-test-form-builder-logo-alignment-${value}`}
                value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}
    </div>
  );
};

interface HeaderEditProps {
  data: IntakeForm;
  onSave: (text: string) => void;
}

const InfoEditComponent = ({ data, onSave }: HeaderEditProps) => {
  const [state, setState] = useState({ info: data.info });
  return (
    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
      <div>
        <TipTap
          content={state.info}
          onChange={(body: string) => setState(s => ({ ...s, info: body }))}
          width='100%'
          height={200}
        />
        <br />
        <Button
          onClick={() => onSave(state.info)}
          className='sked-test-form-builder-edit-add-office-information-save'
          variant="contained"
          color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

const InfoComponent = ({ data }: UploadLogoProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  const saveHeader = (text: string) => {
    setEdit(false);
    dispatch(ctx.editRevision({ info: text }));
  };

  if (edit) {
    return <InfoEditComponent data={data} onSave={saveHeader} />;
  }

  if (data.info) {
    return (
      <div>
        <Row>
          <div style={{ fontSize: '18px' }}>
            Office Information:
          </div>
          <Spacer />
          <IconButton onClick={() => dispatch(ctx.editRevision({ info: null }))}>
            <Delete />
          </IconButton>
        </Row>
        <br />
        <div
          onClick={() => setEdit(true)}
          className={
            classes.infoDiv + ' ' +
            'sked-test-form-builder-edit-add-office-information-body'
          }
          dangerouslySetInnerHTML={{ __html: data.info }}
        >
        </div>
      </div>
    );
  }
  return (
    <Button
      variant='contained'
      onClick={() => setEdit(true)}
      style={{ marginTop: '5px' }}
      className='sked-test-form-builder-edit-add-office-information'
    >
      Add office information
    </Button>
  );
};


const LocalHeader = ({ id }: {id: number}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    revision, previewId, busy, editHistoryValue, savedOn,
  } = useSelector(state => ({
    data: state.forms.revision,
    ...state.forms,
  }));
  const [conditionalLogic, setConditionalLogic] = useState(false);

  const { undoEnabled, redoEnabled, currentIndex, } = useMemo(() => {
    const { currentIndex, history } = editHistoryValue;
    return ({
      undoEnabled: currentIndex > 0,
      redoEnabled: currentIndex < history.length - 1,
      currentIndex,
    });
  }, [editHistoryValue]);

  const canPreview = useMemo(() => {
    return currentIndex === savedOn;
  }, [currentIndex, savedOn]);

  useTitle('Form Edit');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdd = (type: FormChild['type']) => {
    handleClose();
    dispatch(ctx.appendFormChild(type));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUndo = () => {
    handleClose();
    dispatch(ctx.undo());
  };

  const handleRedo = () => {
    handleClose();
    dispatch(ctx.redo());
  };

  const openInNewTab = React.useCallback(() => {
    window.open(`${urlBase}intake-preview?previewId=${previewId}`, '_blank', 'noreferrer');
  }, [previewId]);

  const handleSaveButton = () => {
    dispatch(ctx.save(id, revision));
  };

  const openConditionalLogic = () => {
    setConditionalLogic(true);
  };

  return (
    <>
      <Header
        title='Form Builder'
        pageId="forms-edit"
        leftIcons={[
          <HeaderButton
            title='Undo'
            Icon={UndoIcon}
            disabled={!undoEnabled}
            onClick={handleUndo}
            onlyIcon
            className='sked-test-form-builder-undo-button'
          />,
          <HeaderButton
            title='Redo'
            Icon={RedoIcon}
            disabled={!redoEnabled}
            onClick={handleRedo}
            onlyIcon
            className='sked-test-form-builder-redo-button'
          />,
        ]}
        rightIcons={[
          <Grid display="flex" marginRight={1}>
            {busy && <CircularProgress size={18} />}
          </Grid>,
          <HeaderButton
            title='Add'
            Icon={AddIcon}
            onClick={handleClick}
            className='sked-test-form-builder-add-button'
          />,
          <CopyPasteHeaderButton />,
          <HeaderButton
            onClick={openConditionalLogic}
            Icon={ConditionalIcon}
            title='Rules'
            className='sked-test-form-builder-rules-button'
          />,
          <HeaderButton
            title='Preview'
            onClick={openInNewTab}
            Icon={OpenInNew}
            disabled={!canPreview || busy}
            className='sked-test-form-builder-preview-button'
          />,
          <Grid marginLeft="5px" />,
          <SaveButton
            title='Save'
            onClick={handleSaveButton}
            disabled={canPreview || busy}
            className='sked-test-form-builder-save-button'
          />,
        ]}
        onlyIconsWidth={690}
        breakPoints={[{
          width: 460,
          mobileItems: [0, 1, 2, 3, 4, 5, 6],
        }]}
      />
      <Menu
        id="simple-menu-main"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleAdd('Section')}>
          <ListItemIcon>
            <SquareIcon />
          </ListItemIcon>
          <ListItemText primary='Section' />
        </MenuItem>
        <MenuItem onClick={() => handleAdd('PrivateSection')}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary='Private Section' />
        </MenuItem>
        <MenuItem onClick={() => handleAdd('Header')}>
          <ListItemIcon>
            <TitleIcon />
          </ListItemIcon>
          <ListItemText primary='Header' />
        </MenuItem>
        <MenuItem onClick={() => handleAdd('Instruction')}>
          <ListItemIcon>
            <SubjectIcon />
          </ListItemIcon>
          <ListItemText primary='Instruction' />
        </MenuItem>
        <MenuItem onClick={() => handleAdd('PageBreak')}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary='Page Break' />
        </MenuItem>
      </Menu>
      <ConditionalLogicDialog
        open={conditionalLogic}
        onClose={() => setConditionalLogic(false)}
      />
    </>
  );
};

const message = 'You have unsaved changes, are you sure you want to navigate away?';
let w: any;

const IntakeEditComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loc = useLocation();
  //   const pdfState = usePromise<actions.GenerateBlank, string>(actions.generateBlankPdf, null);
  const [editForm, setEditForm] = React.useState<boolean>(false);
  const [shouldBlock, setShouldBlock] = React.useState<boolean>(false);
  const [openSettingsDialog, setOpenSettingsDialog] = React.useState<boolean>(false);
  const [phi, setPhi] = React.useState<actions.PdfGen>(null);
  const [dInd, setDInd] = React.useState(0);
  const { id } = useParams();

  const {
    revision, form, busy, savedOn, editHistoryValue,
    errorMessage, maxIndex, newChild, banners, getBusy,
  } = useSelector(state => ({
    data: state.forms.revision,
    banners: state.login.banners.length,
    ...state.forms,
  }));

  useTitle('Form Edit');

  useEffect(() => {
    dispatch(actions.getForm(Number(id)));
    dispatch(actions.fetchLatestFormRevision(Number(id)));
    actions.initPdfListener(setPhi, 'FormBlankPdfCreated');
    return () => {
      window.onbeforeunload = null;
      setShouldBlock(false);
      actions.closePdfEvents();
    };
  }, []);

  useEffect(() => {
    if (dInd < (revision?.children || []).length)
      setTimeout(() => {
        setDInd(dInd + 5);
      }, 50);
    else if (newChild) {
      setTimeout(() => {
        const newguy = document.getElementById(newChild);
        newguy?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(actions.formsPatch({
          newChild: null,
        }));
      }, 500);
    }
  }, [dInd, revision, newChild]);

  useEffect(() => {
    if (phi && phi.revId === revision.id) {
      w.location = phi.url;
      w.focus();
    }
  }, [phi]);

  const canPreview = useMemo(() => {
    return editHistoryValue.currentIndex === savedOn;
  }, [editHistoryValue.currentIndex, savedOn]);

  useEffect(() => {
    if (canPreview) {
      window.onbeforeunload = null;
      setShouldBlock(false);
      if (!location.href.includes(loc.pathname)) {
        location.href = location.href.split('/#')[0] + '/#' + loc.pathname;
      }
    } else {
      window.onbeforeunload = () => {
        return true;
      };
      setShouldBlock(true);
    }
  }, [canPreview]);

  const handleSaveForm = (f: CrForm): void => {
    actions.saveForm(f).then((ff) => {
      dispatch(actions.savedForm(ff));
      setEditForm(false);
    });
  };

  const closeSettings = () => {
    setOpenSettingsDialog(false);
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock &&
      currentLocation.pathname !== nextLocation.pathname
  );

  if (blocker.state === 'blocked') {
    const confirmLeave = window.confirm(message);
    if (confirmLeave) {
      blocker.proceed();
    } else {
      blocker.reset();
    }
  }

  return (
    <>
      <LocalHeader id={Number(id)}/>
      {getBusy ? (
        <Grid padding={2} display="flex" alignItems="center" justifyContent="center" width="100%">
          <CircularProgress />
        </Grid>
      ) : (
        <div
          id="intake-form-holster"
          className={classes.root}
          style={{
            overflowAnchor: 'none',
            height: `calc(100% - ${banners * 56 + 60}px)`,
          }}>
          <SettingsDialog
            open={openSettingsDialog}
            form={form}
            onClose={closeSettings}
          />
          {errorMessage &&
          <div>{errorMessage}</div>
          }
          {!editForm &&
            <>
              {(form?.name || '').trim() === '' ?
                <IconButton onClick={() => setEditForm(true)}>
                  <EditIcon />
                </IconButton>
                :
                <Typography
                  variant="h3"
                  component="h2"
                  className={classes.title}
                  onClick={() => setEditForm(true)}>
                  {form?.name}
                </Typography>}
              <Typography
                variant="body1"
                component="p" gutterBottom
                onClick={() => setEditForm(true)}>
                {form?.description}
              </Typography>
              <Divider />
              {revision.logoUrl !== '' && !isNil(revision.logoUrl) &&
                <img
                  style={{
                    maxWidth: '75%',
                    marginTop: '5px'
                  }}
                  src={revision.logoUrl}
                />}
            </>}
          {
            editForm &&
            <EditFormComponent
              data={{
                ...form,
                sendNotification: form?.sendNotification,
                id: Number(id),
              }}
              onSave={handleSaveForm}
            />
          }
          <UploadLogo
            data={{
              ...revision,
              id: Number(id),
            }}
          />
          <br />
          <InfoComponent data={revision} />
          <br />
          <br />
          {
            isEmpty(revision.children) && !busy &&
            <Typography variant='h3' component='h3' style={{ marginLeft: 'unset' }}>
              Press the 'add' button above to continue customizing your form.
            </Typography>
          }
          {busy && isEmpty(revision.children) &&
          <CircularProgress />
          }
          {(revision?.children || [])
            .map((child, i) => {
              if (i > dInd && i < maxIndex)
                return null;
              return (
                <FormChildComponent
                  key={child.id}
                  child={child}
                  index={i}
                  revision={revision}
                />
              );
            })
          }
        </div>
      )}
    </>
  );
};

export default IntakeEditComponent;
