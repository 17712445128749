import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const AroundTheClockIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79551 1.03159C3.96604 1.35623 3.23642 1.83443 2.58253 2.48432C2.47717 2.37829 2.37391 2.27444 2.27213 2.17207C2.03436 1.93294 1.80471 1.70196 1.57547 1.4706C1.37792 1.27122 1.15022 1.19446 0.884941 1.31568C0.631048 1.4317 0.499681 1.63075 0.500527 1.91689C0.501565 2.26794 0.501066 2.61899 0.500568 2.97005C0.49984 3.48241 0.499113 3.99477 0.503161 4.5071C0.508568 5.19137 1.04671 5.72525 1.73302 5.72986C2.59659 5.73566 3.46028 5.73508 4.32382 5.72739C4.44412 5.72632 4.57993 5.69507 4.68106 5.633C5.03797 5.41395 5.08391 4.9945 4.78237 4.68151C4.54648 4.43667 4.30263 4.19948 4.05559 3.95917C3.9472 3.85374 3.8382 3.7477 3.72898 3.64017C4.87727 2.55625 6.2103 2.08628 7.72994 2.29445C10.5607 2.68221 12.3916 5.34336 11.7167 8.10896C11.2707 9.93682 10.0824 11.1413 8.25943 11.5938C6.41796 12.0509 4.81512 11.5219 3.52731 10.1157C2.8938 9.42396 2.54515 8.59091 2.38191 7.67158C2.31203 7.27803 1.9782 7.01356 1.5947 7.00784C1.21582 7.00218 0.855506 7.26117 0.797851 7.65258C0.762425 7.89309 0.809733 8.15456 0.861423 8.3978C1.54237 11.6021 4.75823 13.8249 7.99797 13.3377C11.5861 12.7982 13.9951 9.50914 13.4134 5.94408C12.7633 1.96047 8.55328 -0.439102 4.79551 1.03159ZM6.28877 5.73617C6.28889 5.84259 6.28901 5.94901 6.28902 6.05544C6.28902 6.15738 6.28854 6.25935 6.28806 6.36131C6.28676 6.63965 6.28545 6.918 6.29393 7.19605C6.29668 7.28595 6.33861 7.3953 6.40029 7.4593h6C6.76949 7.84283 7.1457 8.21985 7.52744 8.5909C7.91145 8.96414 8.40113 8.97179 8.74331 8.62167C9.07907 8.27811 9.06787 7.7998 8.70839 7.42315C8.6284 7.33933 8.54525 7.25835 8.46213 7.17741C8.33313 7.05177 8.2042 6.92621 8.08724 6.79021C8.00344 6.69276 7.93771 6.54694 7.93277 6.4204C7.91468 5.95634 7.90823 5.49035 7.92995 5.02661C7.95735 4.44161 7.51809 4.1079 7.10006 4.10748C6.62313 4.10701 6.29171 4.48253 6.2893 5.01607C6.28822 5.2561 6.28849 5.49613 6.28877 5.73617Z" fill={color || '#7C7C80'}/>
    </svg>
  );
};

export default AroundTheClockIcon;
