import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import * as R from 'ramda';

export const patch = (prop, value) => (dispatch) =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      [prop]: value,
    }
  });

export const getTypesApi = (navigate) =>
  api.get('appointmentType').then((types) => {
    return api.get('professional').then((professionals) => {
      return api.get('settings/webmodule').then((settings) => {
        // const types = R.pipe(
        //   R.filter(({isHidden}) => !isHidden),
        //   R.map(
        //     (professional) => {
        //       return {
        //         id: professional.id,
        //         name: professional.displayFirstName + ' ' + professional.displayLastName,
        //         isHidden: professional.isHidden,
        //         types: R.pipe(
        //           R.filter(
        //             (type) =>
        //               type.professionalId === professional.id),
        //           R.map(R.assoc('proName', professional.displayFirstName + ' ' + professional.displayLastName))
        //         )(rawTypes),
        //       };
        //     }))(professionals);
        // const selectedTypes = R.pipe(
        //   R.map((id) => {
        //     return R.map((pro) => {
        //       return R.filter((type) => {
        //         return type.id === id;
        //       })(pro.types);
        //     })(types);
        //   }),
        //   R.flatten
        // )(settings.appointmentTypes);
        const selectedType = R.find(R.propEq('id', R.head(settings.newClient)))(types);
        const selectedNewAptPro = R.find(R.propEq('id', R.propOr(false, 'professionalId', selectedType)))(professionals);
        return ({
          types,
          professionals,
          officeKey: settings.key,
          selectedTypes: settings.appointmentTypes,
          selectedNewAptPro,
          selectedType: selectedType === undefined ? {} : selectedType,
          includeNewClient: !R.isEmpty(settings.newClient),
          selectedNewTypes: settings.newClient,
          scheduleNewPatient: settings.scheduleNewPatient,
          bookingRedirectUrl: settings.bookingRedirectUrl === null ? '' : settings.bookingRedirectUrl,
          sameDayNewPatientScheduling: settings.sameDayNewPatientScheduling,
          sameDayExistingPatientScheduling: settings.sameDayExistingPatientScheduling,
        });
      });
    });
  }).catch((error) => {
    if (error.response) {
      console.error(error);
    } else {
      navigate?.('/offline');
    }
  });

export const getTypes = (navigate) => remoteAction({
  type: at.WEBMODULE_REMOTE_GET_TYPES,
  action: () => getTypesApi(navigate),
});

export const addType = ({ type }) => (dispatch) =>
  dispatch({
    type: at.WEBMODULE_ADD_TYPE,
    data: {
      type
    }
  });

export const addAllTypes = ({ pros, type }) => (dispatch) => {
  if (type.name === 'All') {
    return dispatch({
      type: at.WEBMODULE_ADD_TYPES,
      data: {
        selectedTypes: R.pipe(R.map((pro) => R.tail(pro.types)), R.flatten)(R.tail(pros)),
        typeStatus: 'All',
      }
    });
  }
  return dispatch({
    type: at.WEBMODULE_ADD_TYPES,
    data: {
      selectedTypes: R.pipe(
        R.map(
          ({ types }) =>
            R.filter(
              (typ) =>
                typ.name === type.name)(types)),
        R.flatten
      )(R.tail(pros)),
      typeStatus: 'Some',
    }
  });
};

export const selectPro = (pro) => (dispatch) =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      selectedPro: pro,
    }
  });

export const selectNewAptPro = (pro) => (dispatch) =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      selectedNewAptPro: pro,
    }
  });

export const selectType = ({ type }) => (dispatch) =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      selectedType: type,
    }
  });


export const removeType = ({ index }) => (dispatch) => {
  return dispatch({
    type: at.WEBMODULE_REMOVE_TYPE,
    data: {
      index
    }
  });
};

export const clearSelected = (prop) => (dispatch) =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      [prop]: []
    }
  });


export const save = ({
  selectedTypes,
  newClient,
  include,
  scheduleNewPatient,
  bookingRedirectUrl,
  sameDayExistingPatientScheduling,
  sameDayNewPatientScheduling,
}) => {
  const trimmedUrl = bookingRedirectUrl.trim();
  return remoteAction({
    type: at.WEBMODULE_SAVE,
    successText: 'Information saved!',
    action: () => api.put('settings/webmodule', {
      appointmentTypes: selectedTypes,
      newClient: include ? newClient : [],
      scheduleNewPatient: include ? scheduleNewPatient : false,
      bookingRedirectUrl: R.isEmpty(trimmedUrl) ? null : trimmedUrl,
      sameDayExistingPatientScheduling,
      sameDayNewPatientScheduling,
    })
  });
};
