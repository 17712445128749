import React from 'react';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import api from '../../services/api.js';
import { includes } from 'ramda';
import { CustomTooltip, useStyles } from './help.styles';

const getHelpText = (id: string) => api.get(`help/${id}`);

type Props = {
  pageId: string;
  pageTitle: string;
}

let w: Window;

const Help = ({ pageId, pageTitle }: Props) => {
  const classes = useStyles();
  const [_page, query] = location.href.split('/#/')[1]?.split('?') || [null, null];
  const seenit = query && includes('gettingStarted=true', query);

  const [flashing, setFlashing] = React.useState(seenit);

  const title = pageTitle ? pageTitle : document.title;
  
  const className = 'sked-test-help-button-' + (pageTitle ? pageTitle.toLowerCase().replace(/ /g, '-') : pageId);

  const handleOpen = () => {
    w = window.open('https://skedlife.zendesk.com/hc/en-us/', '_blank');
    
    getHelpText(pageId).then(result => {
      if (result?.html_url) {
        w.location = `${result?.html_url}?utm_source=admin&utm_term=${title?.replace(/ /g, '')}&utm_content=pagequestionicon`;
        w.focus();
      }
    });
  };
  return flashing ? (
    <div className={classes.helpButton}>
      <CustomTooltip
        title={'⭐ Click here to learn about this feature! Once you\'ve finished setting up this page, go back to the \'Getting Started\' tab to continue.'}
        placement='right'
        arrow
        open
      >
        <IconButton
          className={className}
          onClick={() => {
            handleOpen();
            setFlashing(false);
          }}>
          <HelpIcon className={classes.icon} />
        </IconButton>
      </CustomTooltip>
    </div>
  ) : (
    <IconButton
      className={className}
      onClick={handleOpen}>
      <HelpIcon className={classes.icon} />
    </IconButton>
  );
};

export default Help;