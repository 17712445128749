import React, { useEffect, useState } from 'react';
import {
  IconButton, Menu, MenuItem, DialogContentText, TextField
} from '@mui/material';
import MenuIcon from '@mui/icons-material/MoreVert';
import FolderIcon from '@mui/icons-material/Folder';
import * as R from 'ramda';
import api from '../../services/api.js';
import { usePromise } from './../../services/promise.hook';
import FolderOpenedIcon from '../../icons/Folder.icon';
import Modal from '../Modal/Modal.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { useStyles } from './folderItem.styles';

export interface Folder {
  path: string[],
  /* created: string, */
  /* officeId: number, */
  resource: Resource;
  pathId: number,
}

interface PathLabel {
  created: string;
  id: number;
  label: string;
}

interface BasicResource {
  [key: string]: number;
}

interface PathLabelResource {
  PathLabel: PathLabel | number,
}

export type Resource = BasicResource | PathLabelResource;

interface DialogProps {
  open: boolean;
  onClose: (reload: string | boolean) => void;
  id?: number;
  template?: boolean
  folder?: Folder;
  recursive?: boolean
}

interface CreateFolder {
  id?: number;
  path: string[];
  template?: boolean; /* Not on the server type. */
  label: string;
}

interface DeleteFolder {
  path: string[];
  template?: boolean;
  recursive?: boolean;
}


const addFolder = ({ template, label, path }: CreateFolder): Promise<void> => {
  const url = template ? 'templates/paths' : 'paths';
  const labelUrl = template ? 'templates/paths/label' : 'paths/label';
  return api.post(labelUrl, { label }).then((pathLabel: PathLabel) => {
    const data = {
      resource: {
        PathLabel: pathLabel.id,
      },
      path: path.concat(['pl_' + pathLabel.id]),
    };
    return api.post(url, data);
  });
};

const editFolder = ({ label, id, template }: CreateFolder): Promise<void> => {
  const labelUrl = template ? 'templates/paths/label' : 'paths/label';
  return api.put(labelUrl + `/${id}`, { label });
};

const deleteFolder = ({ path, template, recursive }: DeleteFolder): Promise<void> => {
  const pathString = path.join('.');
  const recurse = recursive ? '/recursive' : '';
  const url = template ?
    `templates/paths/path/${pathString}` : `paths/path/${pathString}` + recurse;
  return api.delete(url, { data: {} });
};

export const DeleteDialog = ({ open, onClose, folder, template, recursive }: DialogProps) => {
  const deleteState = usePromise<DeleteFolder, void>(deleteFolder, null);
  const handleDelete = () => {
    deleteState.invoke({ path: folder.path, template, recursive }).then(() => onClose(''));
  };
  return (
    <Modal
      open={Boolean(open)}
      onClose={() => onClose(undefined)}
      title='Delete folder'
      className='sked-test-delete-folder-modal'
      size='xs'
      buttons={[
        <HeaderButton
          onClick={handleDelete}
          title='Delete'
          color='danger'
          className='sked-test-delete-folder-modal-button-delete'
        />
      ]}
    >
      <div>
        <DialogContentText>
        Are you sure you wish to delete this folder? This will NOT delete the messages within the folder. They will be placed into the "Unassigned" category.
        </DialogContentText>
      </div>
    </Modal>
  );
};

export const AddDialog = ({ open, onClose, id, folder, template }: DialogProps) => {
  const {
    path,
    resource,
  } = folder;
  const label = typeof resource?.PathLabel === 'number' ? '' : resource?.PathLabel?.label;
  const [name, setName] = useState(label || '');
  const addState = usePromise<CreateFolder, void>(addFolder, null);
  const editState = usePromise<CreateFolder, void>(editFolder, null);
  const handleAdd = () => {
    if (id && typeof folder.resource.PathLabel !== 'number') {
      editState.invoke({
        id: folder.resource.PathLabel.id,
        path,
        template,
        label: name,
      }).then(() => {
        onClose(name);
        setName('');
      });
    } else {
      addState.invoke({
        path,
        template,
        label: name,
      }).then(() => {
        setName('');
        onClose(true);
      });
    }
  };
  useEffect(() => {
    if (label && open)
      setName(label);
  }, [label, open]);

  return (
    <Modal
      open={Boolean(open)}
      onClose={() => {
        setName('');
        onClose(undefined);
      }}
      maxWidth={500}
      title={id ? 'Edit folder' : 'New folder'}
      className={`sked-test-${id ? 'edit' : 'add'}-folder-modal`}
      buttons={[
        <HeaderButton
          title={id ? 'Update' : 'Create'}
          onClick={handleAdd}
          color="primary"
          disabled={name.trim() === ''}
          className={`sked-test-${id ? 'edit' : 'add'}-folder-modal-button-save`}
        />
      ]}
    >
      <div>
        <DialogContentText>
          Please enter a name for your folder.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          type="text"
        />
        {addState.errorMessage && <p style={{ color: 'red' }}>{addState.errorMessage}</p>}
        {editState.errorMessage && <p style={{ color: 'red' }}>{editState.errorMessage}</p>}
      </div>
    </Modal>
  );
};

let buttonPressTimer: NodeJS.Timeout;

interface FolderItemProps {
  folder: Folder;
  path?: string[];
  onClick: (folder: Folder) => void;
  update?: (name: string) => void;
  template?: boolean;
  disableMenu?: boolean;
  selectedId?: number,
}

export const FolderItem = ({
  folder, onClick, update, template, disableMenu, selectedId,
}: FolderItemProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const boxRef = React.useRef(null);
  const btnRef = React.useRef(null);

  useEffect(() => {
    // if (selectedId !== folder.pathId) {
    //   setIsSelected(false);
    // } else if (selectedId === 0 && folder.pathId === 0) {
    //   setIsSelected(true);
    // }
    setIsSelected(selectedId === folder.pathId);
  }, [selectedId]);

  const handleOnClick = () => {
    if (!anchorEl) {
      onClick(isSelected ? undefined : folder);
    }
    // if (selectedId === 0 && isSelected) {
    //   return;
    // }
    // setIsSelected(!isSelected);
  };

  const name = React.useMemo(() => {
    return R.pathOr('', ['resource', 'PathLabel', 'label'], folder);
  }, [folder]);

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onHoverAction = () => {
    setHideButton(false);
  };

  const offHoverAction = () => {
    setHideButton(true);
  };

  useEffect(() => {
    boxRef.current?.addEventListener('mouseover', onHoverAction);
    boxRef.current?.addEventListener('mouseout', offHoverAction);
    return () => {
      boxRef.current?.removeEventListener('mouseover', onHoverAction);
      boxRef.current?.removeEventListener('mouseout', offHoverAction);
    };
  }, [boxRef?.current]);

  const shouldHideButton = React.useMemo(() => {
    return hideButton && !anchorEl;
  }, [hideButton, anchorEl]);

  const afterDelete = (param: string) => {
    setIsDelete(false);
    setIsAdd(false);
    if (param !== undefined) {
      update('');
      if (isSelected) {
        onClick(undefined);
      }
    }
  };

  const handleClose = (name: string = undefined) => {
    setIsDelete(false);
    setIsAdd(false);
    if (name === null) {
      update('');
      onClick(undefined);
      setIsSelected(!isSelected);
    } else if (name !== undefined) {
      update(name);
      !anchorEl && onClick(isSelected ? undefined : folder);
      setIsSelected(!isSelected);
    }
  };

  const handleButtonPress = () => {
    if (disableMenu) {
      return;
    }
    buttonPressTimer = setTimeout(() => {
      if (btnRef.current) {
        btnRef.current.click();
      }
    }, 500);
  };
  
  const handleButtonRelease = () => {
    clearTimeout(buttonPressTimer);
  };

  return (
    <>
      <DeleteDialog
        id={folder.pathId}
        open={isDelete}
        onClose={afterDelete}
        template={template}
        folder={folder}
      />
      <AddDialog
        open={isAdd}
        onClose={handleClose}
        folder={folder}
        id={folder.pathId}
        template={template}
      />
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
          setIsAdd(true);
        }}>
          Rename
        </MenuItem>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
          setIsDelete(true);
        }}>
          Delete
        </MenuItem>
      </Menu>
      <div
        key={folder.pathId}
        className={`${classes.folderBox} ${isSelected ? classes.opened : ''}`}
        ref={boxRef}
        onClick={handleOnClick}
        title={name}
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
      >
        <div className={classes.clickablePart}>
          {isSelected ?
            <FolderOpenedIcon className={classes.folderIcon} />
            : <FolderIcon className={classes.folderIcon} />
          }
          <div className={classes.wordPart}>
            {name}
          </div>
        </div>
        {!disableMenu &&
          <IconButton
            ref={btnRef}
            onClick={handleMenuButtonClick}
            className={`${classes.icon} ${classes.moreButton}`}
            style={{ display: shouldHideButton ? 'none' : 'flex' }}
          >
            <MenuIcon style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </IconButton>
        }
      </div>
    </>
  );
};
