import { AnyAction } from '@reduxjs/toolkit';
import * as actionTypes from './login.actionTypes';
import * as R from 'ramda';


type SessionReducer = {
  status: string;
  access_token: string;
  refresh_token: string;
  expireDate?: Date;
};

const init: SessionReducer = {
  status: 'NOT_CHECKED',
  access_token: '',
  refresh_token: '',
  expireDate: null,
};

export default (state = init, action: AnyAction): SessionReducer => {
  switch (action.type) {

    case actionTypes.SESSION_CHECKING:
      return R.merge(state, {
        status: 'CHECKING',
      });

    case actionTypes.SESSION_LOAD_SUCCESS:
    case actionTypes.SESSION_CREATE_SUCCESS: {
      const expireDate = R.pipe(
        R.path(['session', 'access_token']),
        R.split('.'),
        R.nth(1),
        atob,
        s => JSON.parse(s),
        R.prop('exp'),
        d => new Date(Number(d) * 1000)
      )(action);

      return R.merge(state, {
        access_token: R.path(['session', 'access_token'], action),
        refresh_token: R.path(['session', 'refresh_token'], action),
        status: 'AUTHENTICATED',
        expireDate,
      });
    }

    case actionTypes.SESSION_CREATE_ERROR:
      return R.merge(state, { status: 'ERROR' });

    case actionTypes.SESSION_LOAD_ERROR:
    case actionTypes.SESSION_DESTROY:
      return R.merge(state, {
        access_token: null,
        refresh_token: null,
        status: 'NOT_AUTHENTICATED',
      });

    default:
      return state;
  }
};
