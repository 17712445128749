import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Tooltip,
  TableRow,
  TableBody,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Error from '@mui/icons-material/Error';
import {
  tzParseFormat,
} from '../../../services/joda.js';
import { caseType, goto, setQueryParam } from '../../../services/utilities.js';
import api from '../../../services/api.js';
import * as R from 'ramda';
import Appointments from '../../Appointments/Appointments.component';
import ClientEditDialog from '../../Clients/components/client-dialog/client-dialog.component.jsx';
import EditLeadDialog from '../../Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../../Leads/components/Convert/Convert.component';
import { TableContainer, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderTabs from '../../../components/HeaderTabs/HeaderTabs.component';

const MessageDialog = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    state: 'MESSAGE',
    apt: null,
    messageType: null,
    getMsg: true,
    tab: 'sms',
    smsAttachments: [],
    officeAttachments: [],
    selectedLead: null,
    leadToConvert: null,
  });

  const gotoAction = (data) => {
    dispatch(goto({ ...data, navigate }));
  };

  useEffect(() => {
    const tab = R.cond([
      [R.prop('emailData'), R.always('email')],
      [R.prop('smsData'), R.always('sms')],
      [R.prop('pushData'), R.always('push')],
      [R.T, R.always('sms')],
    ])(props.message);
    if (props.openedFrom === 'CLIENT') {
      setQueryParam('msg2', props.message.msgId);
      setState({
        ...state,
        tab,
      });
    } else {
      setQueryParam('msg1', props.message.msgId);
      setState({
        ...state,
        tab,
      });
    }
    
    const id = R.pathOr(false, ['service', 'SkedMessage', 'msgId'])(props.message);
    const attachmentIds = [
      R.pathOr([], ['smsData', 'attachmentIds'], props.message),
      R.pathOr([], ['emailData', 'attachmentIds'], props.message)
    ];
    const messageRequest = id ? api.get(`message/${id}`) : [];
    const requests = R.pipe(
      R.map((aIds) => R.isEmpty(aIds) ? null :
        api.post('attachment', {
          attachmentIds: aIds,
        })),
      R.without([null]),
      R.concat([messageRequest])
    )(attachmentIds);
    if (state.getMsg && id)
      Promise.all(requests)
        .then(([message, smsAttachments, emailAttachments]) => {
          setState({
            ...state,
            getMsg: false,
            messageType: R.cond([
              [R.has('OneTime'), R.always('OneTime')],
              [R.has('Recurring'), R.always('Recurring')],
              [R.has('Recall'), R.always('Recall')],
              [R.has('Birthday'), R.always('Birthday')],
              [R.has('ApptChange'), R.always('ApptChange')],
              [R.T, R.always(null)],
            ])(message.messageType),
            tab: R.cond([
              [R.prop('email'), R.always('email')],
              [R.prop('sms'), R.always('sms')],
              [R.prop('push'), R.always('push')],
              [R.T, R.always('sms')],
            ])(message),
            smsAttachments: R.propOr([], 'officeAttachments', smsAttachments),
            emailAttachments: R.propOr([], 'officeAttachments', emailAttachments),
          });
        }).catch(() => {
          setState({
            ...state,
            getMsg: false,
          });
          console.log('failed to get message!');
        });
  }, [props.message]);

  const changeState = (st, apt = null) => {
    setState({
      ...state,
      state: st,
      apt,
    });
  };

  const selectClient = (client, from, action) => {
    if (client.isLead) {
      setState({ ...state, selectedLead: client });
      return;
    }
    if (from === 'CLIENT') {
      return action();
    }
    action(client);
    return changeState('CLIENT');
  };

  const getApt = (id, client) => {
    api.get(`appointment/${id}`).then((apt) => {
      api.get(`appointmentType/${apt.appointmentTypeId}`)
        .then((data) => {
          const aptWithType = R.assoc('appointmentType', data)(apt);
          return api.get(`appointment/${id}/log`)
            .then((data) => {
              changeState(
                'APT',
                R.pipe(
                  R.assoc('aptData',
                    R.reverse(R.sortBy(R.prop('changedAt'))(data))),
                  R.assoc('client', client)
                )(aptWithType));
            });
        });
    }).catch((error) => {
      console.error(error);
    });
  };

  const table = (message, data, tz, openedFrom, gotoClient, from, goto, status = undefined) => {
    const client = message.client;
    const created = message.created;
    const {
      msgId,
      apptId,
    } = R.cond([
      [R.has('SkedMessage'), R.prop('SkedMessage')],
      [R.T, R.always({
        msgId: null,
        apptId: null,
      })]
    ])(message.service);
    const {
      subject,
      body,
      html,
      attachments,
    } = data;
    const gotoObj = R.cond([
      [R.equals('OneTime'), R.always({
        url: '/messages/view/' + msgId,
        location: from,
      })],
      [R.equals('Recurring'), R.always({
        url: '/recurring/view/' + msgId,
        location: from,
      })],
      [R.equals('Recall'), R.always({
        url: '/reactivation/view/' + msgId,
        location: from,
      })],
      [R.equals('Birthday'), R.always({
        url: '/birthday/view/' + msgId,
        location: from,
      })],
      [R.equals('ApptChange'), R.always({
        url: '/apptchange/view/' + msgId,
        location: from,
      })],
      [R.equals('ApptChanges'), R.always({
        url: '/apptchanges/view/' + msgId,
        location: from,
      })],
      [R.T, R.always(null)]
    ])(state.messageType);
    const smsStatus = R.cond([
      [R.equals('Failed'), R.always(<Error style={{ color: 'red' }} />)],
      [R.equals('Pending'), R.always(<MoreHoriz />)],
      [R.equals('Sent'), R.always(<Check style={{ color: 'green' }} />)],
      [R.equals('Delivered'), R.always(<Check style={{ color: 'green' }} />)],
      [R.T, R.always(null)],
    ])(status);
    return (
      <TableContainer noHover>
        <TableBody>
          <TableRow>
            <BodyCell><b>Subject:</b></BodyCell>
            <BodyCell>{subject}</BodyCell>
          </TableRow>
          {body &&
            <TableRow>
              <BodyCell><b>Body:</b></BodyCell>
              <BodyCell>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    fontFamily: 'sans-serif',
                    backgroundColor: 'white',
                    padding: '0px',
                  }}>
                  {body}
                </pre>
              </BodyCell>
            </TableRow>}
          {html &&
            <TableRow>
              <BodyCell><b>Body:</b></BodyCell>
              <BodyCell sx={{
                '& p': {
                  wordWrap: 'break-word',
                  whiteSpace: 'normal !important',
                },
              }}>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    fontFamily: 'sans-serif',
                    backgroundColor: 'white',
                    padding: '0px',
                  }}
                  dangerouslySetInnerHTML={{ __html: html }}>
                </pre>
              </BodyCell>
            </TableRow>}
          <TableRow>
            <BodyCell><b>Client:</b></BodyCell>
            <BodyCell>
              <a onClick={() => {
                if (props.openedFrom === 'CLIENT') {
                  setQueryParam('msg2', undefined);
                }
                selectClient(client, openedFrom, gotoClient);
              }}>
                {R.propOr(null, 'firstName')(client) + ' '}
                {R.propOr(null, 'lastName')(client) + ' '}
                {caseType(client)}
              </a>
            </BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell><b>Sent:</b></BodyCell>
            <BodyCell>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {tzParseFormat(created, tz, 'MM/dd/yyyy @ h:mm:ss a')}
                {smsStatus &&
                  <Tooltip
                    title={status}
                    placement='top'
                    arrow>
                    {smsStatus}
                  </Tooltip>}
              </div>
            </BodyCell>
          </TableRow>
          {!R.isEmpty(attachments) && !R.isNil(attachments) &&
            <TableRow>
              <BodyCell><b>Attachments:</b></BodyCell>
              <BodyCell>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  {
                    <>
                      <div>
                        {R.map((file) => {
                          return (
                            [<a key={file.attachmentId} href={file.attachmentUrl} target='_blank'>
                              {file.attachmentName}
                            </a>,
                            <br />]
                          );
                        }, attachments)}
                      </div>
                    </>}
                </div>
              </BodyCell>
            </TableRow>}
          {gotoObj &&
            <TableRow>
              <BodyCell>
              </BodyCell>
              <BodyCell>
                <a onClick={() => goto(gotoObj)}>
                  Original Message
                </a>
                {apptId &&
                  <>
                    <br />
                    <a onClick={() => getApt(apptId, client)}>
                      Appointment
                    </a>
                  </>}
              </BodyCell>
            </TableRow>}
        </TableBody>
      </TableContainer>
    );
  };

  const email = R.propOr(null, 'emailData', props.message);
  const sms = R.propOr(null, 'smsData', props.message);
  const push = R.propOr(null, 'pushData', props.message);

  return (
    <Modal
      title='Message'
      open={props.open}
      onClose={() => {
        if (props.openedFrom === 'CLIENT') {
          setQueryParam('msg2', undefined);
        } else {
          setQueryParam('msg1', undefined);
        }
        props.onClose();
      }}
      leftComponents={[
        <HeaderTabs
          setTab={(tab) => setState(R.merge(state, { tab }))}
          value={state.tab}
          tabs={[
            email ? { label: 'Email', value: 'email' } : null,
            sms ? { label: 'SMS/MMS', value: 'sms' } : null,
            push ? { label: 'Push', value: 'push' } : null,
          ]}
        />
      ]}>
      <div>
        <ClientEditDialog
          open={state.state === 'CLIENT'}
          gotoClient={props.gotoClient}
          from={props.from}
          back={props.back}
          onClose={() => changeState('MESSAGE')} />
        <EditLeadDialog
          open={!!state.selectedLead}
          close={() => setState({ selectedLead: null })}
          lead={state.selectedLead}
          convertClick={() => {
            setState({ leadToConvert: state.selectedLead });
            setState({ selectedLead: null });
          }}
          onUpdate={() => props.onClose()}
        />
        <ConvertLeadDialog
          open={!!state.leadToConvert}
          lead={state.leadToConvert}
          close={() => setState({ leadToConvert: null })}
          onConvert={() => {
            setState({ leadToConvert: null });
            props.onClose();
          }}
        />
        <Appointments
          open={state.state === 'APT'}
          gotoClient={props.gotoClient}
          appointment={state.apt}
          from={props.from}
          openedFrom={props.openedFrom === 'CLIENT' ? 'CLIENT_MSG' : null}
          tz={props.tz}
          back={props.back}
          onClose={() => changeState('MESSAGE')} />
        <div>
          <div hidden={state.tab !== 'email'}>
            {email &&
                table(
                  props.message,
                  R.assoc('attachments', state.emailAttachments, email),
                  props.tz,
                  props.openedFrom,
                  props.openedFrom === 'CLIENT' ? props.back : props.gotoClient,
                  props.from,
                  gotoAction
                )
            }
          </div>
          <div hidden={state.tab !== 'sms'}>
            {sms &&
                table(
                  props.message,
                  R.assoc('attachments', state.smsAttachments, sms),
                  props.tz,
                  props.openedFrom,
                  props.openedFrom === 'CLIENT' ? props.back : props.gotoClient,
                  props.from,
                  gotoAction,
                  R.pathOr(undefined, ['message', 'smsStatus'])(props)
                )
            }
          </div>
          <div hidden={state.tab !== 'push'}>
            {push &&
                table(
                  props.message,
                  push,
                  props.tz,
                  props.openedFrom,
                  props.openedFrom === 'CLIENT' ? props.back : props.gotoClient,
                  props.from,
                  gotoAction
                )
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MessageDialog;
