import './client-dialog.css';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import {
  Button, Checkbox, Divider, Tabs, Tab, TextField, Tooltip,
  Chip, Select, FormControl, MenuItem, InputLabel, TableBody,
  TableHead, TableRow, IconButton, Grid,
} from '@mui/material';
import { LocalDate, ZonedDateTime, ZoneId } from '@js-joda/core';
import Smartphone from '@mui/icons-material/Smartphone';
import TrashIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { withStyles } from '@mui/styles';
import * as R from 'ramda';
import * as actions from './client-dialog.actions.jsx';
import {
  tzParseFormat,
  format,
  now,
} from '../../../../services/joda.js';
import
MessageDialog
  from '../../../Messages/components/messageDialog.component.jsx';
import Appointments from '../../../Appointments/Appointments.component';
import { PopupTemplate } from '../../../../services/Popup.js';
import { pickColor, caseType, setQueryParam } from '../../../../services/utilities.js';
import Group from './components/group.component.jsx';
import InboxChat from '../../../Messages/Inbox/InboxChat/InboxChat.component';
import More from './components/more.component.jsx';
import { SchedulerDialog } from '../../../CalendarV2/components/scheduler-dialog.component.js';
import HelpButton from '../../../../components/Help/Help.component';
import { getProfessionals } from '../../../Professionals/professionals.actions.jsx';
import StopManagers from './components/stop-managers.component.jsx';
import ClientFormList from './components/client-forms-list.component';
import SKEDJail from './components/app-settings.component.jsx';
import MessageTab from '../../../../components/MessageTab/MessageTab.component';
import SimpleNotes from '../../../../components/SimpleNotes/simple-notes.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';
import Modal from '../../../../components/Modal/Modal.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import ModalTabs from '../../../../components/ModalTabs/ModalTabs.component';
import { SPARK_FEATURE } from '../../../Login/login.reducer';

import EditTags from '../../../../components/EditTags/EditTags.component';


const SmallerTextField = withStyles({
  root: {
    width: '200px',
  },
})(TextField);

const TinyTextField = withStyles({
  root: {
    width: '112px',
  },
})(TextField);

const WhateverTab = withStyles({
  root: {
    minWidth: 'unset',
  },
})(Tab);

const parseBirthday = (birthday) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

const options = [
  { value: 'All', label: 'All' },
  { value: 'Scheduled', label: 'Scheduled' },
  { value: 'Rescheduled', label: 'Rescheduled' },
  { value: 'Missed', label: 'Missed' },
  { value: 'Arrived', label: 'Arrived' },
  { value: 'Canceled', label: 'Canceled' },
  { value: 'Deleted', label: 'Deleted' },
];

const singularFrequencies = [
  'Week',
  'Month',
];

const pluralFrequencies = [
  'Weeks',
  'Months',
];

const values = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  'Every 2',
  'Every 3',
  'Every 4',
  'Every 5',
  'Every 6',
  'Every Other',
  'Custom',
];

const defaultItems = [
  { name: 'Client Updates', features: ['ClientUpdates'] },
  { name: 'CLA Reports', features: ['CLA'] },
  //  { name: 'SKED Jail', features: ['Jail'] },
  { name: 'Timeline', features: ['Timeline'] },
];

const displayError = (error) => (
  <div>
    {'Unable to display data: ' + error.message}
  </div>
);

const statuses = ['Scheduled', 'Missed', 'Arrived', 'Rescheduled', 'Canceled', 'Deleted'];
const decipherStatus = (status) => R.pipe(
  R.filter((stat) => R.has(stat)(status)),
  R.head
)(statuses);

const aptItem = (apt, tz, viewApt, professionals) => {
  const pro = professionals.find(R.propEq('id', apt.appointmentType.professionalId));
  return (
    <TableRow key={apt.id}>
      <BodyCell>
        <a onClick={() => viewApt(apt)}>
          {tzParseFormat(apt.time, tz, 'MM/dd/yyyy hh:mm a')}
        </a>
      </BodyCell>
      <BodyCell>
        {decipherStatus(apt.status)}
      </BodyCell>
      <BodyCell>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '100px',
              backgroundColor: pickColor(apt.appointmentType.color),
              marginRight: '5px',
            }}>
          </div>
          {apt.appointmentType.internalName} {pro && 'with'} {pro && pro.firstName} {pro && pro.lastName}
        </div>
      </BodyCell>
    </TableRow>
  );
};

const AptsTable = ({ apts, tz, viewApt, professionals }) => {
  const orderedApts = R.sortBy(R.prop('time'))(apts);
  return (
    <Grid marginX={-2}>
      <TableContainer>
        <TableHead>
          <TableRow>
            <HeaderCell className="td-name">
              Appointment
            </HeaderCell>
            <HeaderCell className="td-notifications">Status</HeaderCell>
            <HeaderCell className="td-platinum-id">Type</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {R.has('error')(apts) ? displayError(R.prop('error')(apts)) :
            R.map((apt) =>
              aptItem(apt, tz, viewApt, professionals)
            )(orderedApts)}
        </TableBody>
      </TableContainer>
    </Grid>
  );
};

const CareplanTableItem = ({ freq, idx, actions, patch }) => {
  const { startDate, number, unit } = freq;
  const isCustom = R.find(R.equals(String(number)), values);
  const [custom, setCustom] = React.useState(!isCustom);
  const isNumber = !Number.isNaN(parseInt(number));
  let frequencies = singularFrequencies;
  let unitValue = unit;
  if (!isNumber && !custom) {
    const num = Number(R.last(number));
    frequencies = Number.isNaN(num) ? singularFrequencies : pluralFrequencies;
    if (unit.indexOf('s') === -1)
      unitValue = unit + 's';
  }
  const isError =
    typeof number === 'string' &&
    ((number.indexOf('Every') !== 0 && Number.isNaN(parseInt(number)))
      ||
      (number.split(' ')[1] !== undefined &&
        Number.isNaN(parseInt(number.split(' ')[1]))));
  return (
    <TableRow key={idx}>
      <BodyCell>
        <div className="datepicker startPicker">
          <SmallerTextField
            type='date'
            value={startDate}
            onChange={(e) => {
              const d = e.target.value;
              patch('changed', true);
              if (!d || d instanceof Error) {
                return;
              } else {
                actions.freqPatch(idx, { startDate: d });
              }
            }} />
        </div>
      </BodyCell>
      <BodyCell>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <select
            value={custom ? 'Custom' : number}
            style={{ marginRight: '5px' }}
            onChange={(e) => {
              const v = e.target.value;
              if (v === 'Custom') {
                setCustom(true);
              } else {
                patch('changed', true);
                actions.freqPatch(idx, { number: e.target.value });
                setCustom(false);
              }
            }}>
            {R.map((f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))(values)}
          </select>
          {custom &&
            <TinyTextField
              placeholder='i.e. Every 5 or 6'
              value={number}
              style={{ marginRight: '5px' }}
              onChange={(e) => {
                patch('changed', true);
                actions.freqPatch(idx, { number: e.target.value });
              }}
              error={isError}
              helperText={isError && 'Form: \'Every x\' or \'x\''}
            />}
          {isNumber &&
            <div> per </div>}
          <select
            value={unitValue}
            style={{ marginLeft: '5px' }}
            onChange={(e) => {
              patch('changed', true);
              actions.freqPatch(idx, { unit: e.target.value });
            }}>
            {R.map((f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))(frequencies)}
          </select>
        </div>
      </BodyCell>
      <BodyCell>
        <IconButton
          size="small"
          onClick={() => {
            patch('changed', true);
            actions.removeFreq(idx);
          }}>
          <TrashIcon />
        </IconButton>
      </BodyCell>
    </TableRow>
  );
};

const simpleStyle = {
  missingFreqs:
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25%'
  }
};

const currentFreq = (current) => {
  const isNumber = !Number.isNaN(parseInt(current.number));
  let currentUnit = current.unit;
  if (!isNumber) {
    const num = Number(R.last(current.number));
    currentUnit = Number.isNaN(num) ? current.unit : current.unit + 's';
  }

  return (
    <div style={{ display: 'flex', marginTop: '10px' }}>
      Current Frequency: &nbsp;
      {current.number + (isNumber ? ' per ' : ' ') + currentUnit}
    </div>
  );
};

const careplanSettings = (freqs, current, { showOldFreqs, changed, open }, patch, clientId, actions) => {
  const mapIndexed = R.addIndex(R.map);
  const showableFreqs = showOldFreqs ? freqs : R.pipe(
    R.filter(
      (freq) => {
        return !now('date').isAfter(LocalDate.parse(freq.startDate)) || format(now('date'), 'yyyy-MM-dd') === freq.startDate;
      })
  )(freqs);
  return (
    <div id='careplan' className="scroll-content">
      <div style={{ height: open ? '20vh' : '40vh' }}>
        {current && currentFreq(current)}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ marginLeft: '5px' }}>
            <Button
              variant='contained'
              onClick={() => {
                patch('changed', true);
                actions.addFreq();
              }}>
              Add
            </Button>
            &nbsp;
            {changed &&
              <Button
                variant='contained'
                onClick={() => {
                  patch('changed', false);
                  actions.saveFreq(clientId, freqs);
                }}>
                Save
              </Button>}
          </div>
          <div>
            <Button variant='contained' onClick={() => actions.orderFreqs()}>
              Order
            </Button>
            <label style={{
              marginLeft: '5px',
            }}>
              <input
                type="checkbox"
                checked={showOldFreqs}
                onChange={() => patch('showOldFreqs', !showOldFreqs)}
              />
              Show Previous
            </label>
          </div>
        </div>
        {R.isEmpty(freqs) &&
          <div style={simpleStyle.missingFreqs}>No frequencies! Click on "Add" to add one!</div>}
        {R.isEmpty(showableFreqs) && !R.isEmpty(freqs) &&
          <div style={simpleStyle.missingFreqs}>No current frequencies!</div>}
        {!R.isEmpty(showableFreqs) &&
          <TableContainer noHover>
            <TableRow>
              <HeaderCell>
                Start Date
              </HeaderCell>
              <HeaderCell>
                Frequency
              </HeaderCell>
              <HeaderCell>

              </HeaderCell>
            </TableRow>
            <TableBody>
              {mapIndexed((freq, idx) =>
                <CareplanTableItem
                  freq={freq}
                  idx={idx}
                  actions={actions}
                  freqs={freqs}
                  patch={patch}
                />, showableFreqs)}
            </TableBody>
          </TableContainer>}
      </div>
    </div>
  );
};

const ClientEditDialog = props => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    open: false,
    emoji: false,
    showOldFreqs: true,
    changed: false,
    state: 'SHOW_APTS',
    anchorEl: null,
    menuOpen: false,
    messagesTab: 'Message Thread',
  });

  const [isSchedulerOpen, setIsSchedulerOpen] = React.useState(false);
  const [selectedTime, setSeletedTime] = React.useState({ time: null });
  const [smallModal, setSmallModal] = React.useState(false);
  const [messageTab, setMessageTab] = React.useState('Message Thread');
  const [notesTab, setNotesTab] = React.useState('Notes');
  const [moreTab, setMoreTab] = React.useState('CLA Reports');

  const closeScheduler = () => {
    setIsSchedulerOpen(false);
    setSeletedTime({ time: null });
    props.actions.getAppointments(
      props.client.id,
      props.after,
      props.before,
      props.page,
      props.filter
    );
  };

  const openScheduler = () => {
    const coeff = 1000 * 60 * props.timeIntervals;
    const date = new Date();
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    const isoTime = rounded.toISOString();
    const time = ZonedDateTime
      .parse(isoTime)
      .withZoneSameInstant(ZoneId.of(props.tz));
    setSeletedTime({ time });
    setIsSchedulerOpen(true);
  };

  const update = data => {
    setState(s => R.merge(s, data));
  };

  const patch = (prop, data) => {
    setState(s => R.merge(s, { [prop]: data }));
  };

  const handleToggle = (val) => {
    patch('open', val);
  };

  const features = props.features;

  React.useEffect(() => {
    if (props.client && props.client.id) {
      setQueryParam('cli', props.client.id);
      props.getProfessionals(navigate, props.ehrSystem);
    }
  }, [props.client]);

  React.useEffect(() => {
    if (props.open) {
      props.actions.clientPatch({ tab: 'apts' });
    }
  }, [props.open]);

  React.useEffect(() => {
    if (props.tab === 'generalinfo' && !smallModal) {
      props.actions.clientPatch({ tab: 'apts' });
    }
  }, [smallModal, props.tab]);

  const filteredItems = React.useMemo(() => {
    if (!features?.length) {
      return [];
    }
    return defaultItems.filter(i =>
      R.intersection(i.features, features)?.length > 0 || R.isEmpty(i.features));
  }, [features]);

  React.useEffect(() => {
    const firstItem = defaultItems[0];
    if (firstItem) {
      setMoreTab(firstItem.name);
    }
  }, [filteredItems]);

  const middleColumn = () => {
    if (state.state === 'EDIT_TAGS' && R.includes('Campaigns', props.features)) {
      return (
        <EditTags
          tags={props.tags}
          patch={(data) => patch(data)}
          deleteTag={(tag) => props.actions.deleteTag(props.client.id, tag)}
          addTag={(tag) => props.actions.addTag(props.client.id, tag)}
          onClose={() => patch('state', 'SHOW_APTS')}
        />
      );
    } else if (R.includes('Campaigns', props.features)) {
      return (
        <div
          className={smallModal ? '' : 'client-dialog-left'}>
          <div>
            <div style={{ marginBottom: 10 }}>
              <label>
                Notifications:
              </label>
            </div>
            <label>
              <Checkbox
                name="Email"
                checked={R.pathOr(false, ['client', 'optout', 'email'])(props)}
                onChange={() => {
                  if (props.client.email !== '')
                    props.actions.clientClientPatch(
                      props.client.id,
                      'allowEmail',
                      !props.client.optout.email);
                }} />
              Email
            </label>
            <br />
            <label>
              <Checkbox
                name="SMS"
                checked={R.pathOr(false, ['client', 'optout', 'sms'])(props)}
                onChange={() => {
                  if (props.client.phone !== '')
                    props.actions.clientClientPatch(
                      props.client.id,
                      'allowSMS',
                      !props.client.optout.sms);
                }} />
              SMS
            </label>
          </div>
          <br />
          <div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <label>
                Tags:
              </label>
              {!R.includes('Automations', props.features) &&
                <Tooltip title="Edit Client's Tags">
                  <BorderColorIcon
                    style={{
                      marginRight: '15px',
                      color: '#008BCF',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      patch('state', 'EDIT_TAGS');
                    }}
                  />
                </Tooltip>
              }
            </div>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              maxHeight: '240px',
              overflowY: 'auto',
            }}>
              {props.tags.map((tag) => (
                <Chip style={{ fontSize: 12, marginTop: 8, marginRight: 8 }} key={tag.tag} label={tag.tag} />
              )
              )}
            </div>
          </div>
          <br />
        </div>
      );
    } else {
      return (
        <div
          className='client-dialog-left'
          style={{
            overflowY: 'scroll'
          }}
        >
          <div>
            <div style={{ marginBottom: 10 }}>
              <label>
                Notifications:
              </label>
            </div>
            <label>
              <Checkbox
                name="Email"
                checked={R.pathOr(false, ['client', 'optout', 'email'])(props)}
                onChange={() => {
                  if (props.client.email !== '')
                    props.actions.clientClientPatch(
                      props.client.id,
                      'allowEmail',
                      !props.client.optout.email);
                }} />
              Email
            </label>
            <br />
            <label>
              <Checkbox
                name="SMS"
                checked={R.pathOr(false, ['client', 'optout', 'sms'])(props)}
                onChange={() => {
                  if (props.client.phone !== '')
                    props.actions.clientClientPatch(
                      props.client.id,
                      'allowSMS',
                      !props.client.optout.sms);
                }} />
              SMS
            </label>
          </div>
        </div>
      );
    }
  };

  const professional = R.pipe(
    R.propOr({}, 'professional'),
    R.has('error')
  )(props.client) ?
    displayError(R.path(['professional', 'error'])(props.client)) :
    R.pathOr('', ['professional', 'data', 'displayFirstName'])(props.client) +
    ' ' + R.pathOr('', ['professional', 'data', 'displayLastName'])(props.client);

  const registrationMethods = R.reduce((acc, item) => {
    if (item) {
      return R.isEmpty(acc) ? item : acc + ', ' + item;
    } else {
      return acc;
    }
  }, '')(props.app ? [
    props.app.user,
    props.app.facebook && 'Facebook',
    props.app.google && 'Google',
    props.app.apple && 'Apple',
  ] : []);

  const optionBlacklist = React.useMemo(() => {
    return [
      !R.includes('OneTimeMessages', props.features) && 'OneTimeMessages',
      !R.includes('Reviews', props.features) && 'Reviews',
      !R.includes('FrontDesk', props.features) && 'GotoRoom',
    ].filter(R.identity);
  }, [props.features]);

  const getDisplayName = (displayName) => {
    if (displayName === 'Appointment Reminders') {
      return 'Automated Messages';
    }
    if (displayName === 'Office Notifications') {
      return 'One Time Messages';
    }
    return displayName;
  };

  const isSpark = R.includes(SPARK_FEATURE, props.features);

  return (
    <Modal
      open={props.open}
      size="md"
      className='sked-test-view-client-modal'
      onClose={() => {
        setQueryParam('cli', undefined);
        props.onClose();
      }}
      title={R.pathOr('', ['client', 'firstName'], props) +
        ' ' +
        R.pathOr('', ['client', 'lastName'], props) +
        ' ' +
        caseType(props.client)}
      leftComponents={[
        props.app && R.any(R.identity, R.values(props.app)) &&
        <Tooltip
          title={registrationMethods}
          placement='top'
          arrow
        >
          <Smartphone />
        </Tooltip>
      ]}
      getWidth={(value) => setSmallModal(value < 760)}
      buttons={[
        <HelpButton
          pageId={props.ehrSystem === 'None' ? 'clients-dialog-sas' : 'clients-dialog'}
          pageTitle="ClientsModal"
        />,
        <>&nbsp;</>,
        <HeaderButton
          title='Back'
          borderSolid
          className='sked-test-view-client-modal-button-back'
          onClick={() => {
            setQueryParam('cli', undefined);
            update({
              open: false,
              emoji: false,
              showOldFreqs: true,
              changed: false,
            });
            props.onClose();
          }}
        />
      ]}
    >
      <div>
        <Appointments
          open={props.state === 'VIEW_APT'}
          appointment={props.selectedAppointment}
          tz={props.tz}
          from={props.from}
          openedFrom={'CLIENT'}
          busy={props.aptBusy}
          onClose={props.back}
          back={props.back}
          cancel={() =>
            props.actions.getAppointments(
              props.client.id,
              props.after,
              props.before,
              props.page,
              props.filter,
              'Appointment Canceled'
            )}
          resked={(apt) => {
            props.actions.clientPatch({ selectedAppointment: apt });
            props.actions.getAppointments(
              props.client.id,
              props.after,
              props.before,
              props.page,
              props.filter
            );
          }}
          gotoClient={props.back} />
        <MessageDialog
          open={props.state === 'VIEW_MESSAGE'}
          message={props.selectedMessage}
          onClose={props.back}
          tz={props.tz}
          openedFrom={'CLIENT'}
          from={props.from}
          back={props.back}
          gotoClient={props.back} />

        <SchedulerDialog
          isOpen={isSchedulerOpen}
          firstStep='addAppointments'
          close={closeScheduler}
          defaultClients={[props.client]}
          selectedTime={selectedTime} />
        <PopupTemplate />
        {props.busy && <div className="loader"></div>}
        {!props.busy && smallModal && (
          <Grid marginBottom={1}>
            <ModalTabs
              tabs={[
                { label: 'General info', value: 'generalinfo' },
                R.includes('SimpleNotes', features) ? {
                  label: 'Notes', value: 'notes', subTabs: {
                    tabs: [
                      { label: 'Notes', value: 'Notes' },
                      { label: 'Archived Notes', value: 'Archived Notes' },
                    ],
                    value: notesTab,
                    setTab: setNotesTab,
                  }
                } : null,
                R.includes('CarePlan', features) ?
                  { label: 'Care plan', value: 'careplan' } : null,
                { label: 'Appointments', value: 'apts' },
                { label: 'Group', value: 'group' },
                (R.includes('Forms', features) && !isSpark) ?
                  { label: 'Forms', value: 'forms' } : null,
                R.includes('StopManager', features) ?
                  { label: 'Alerts', value: 'stops' } : null,
                {
                  label: 'Messages', value: 'msgs', subTabs: {
                    tabs: [
                      { label: 'Message Thread', value: 'Message Thread' },
                      { label: 'Sent Message History', value: 'Sent Message History' },
                    ],
                    value: messageTab,
                    setTab: setMessageTab,
                  }
                },
                R.includes('Jail', features) ? { label: 'Jail', value: 'jail' } : null,
                {
                  label: 'More', value: 'more', subTabs: {
                    tabs: filteredItems.map(item => ({ label: item.name, value: item.name })),
                    value: moreTab,
                    setTab: setMoreTab
                  }
                },
              ]}
              value={props.tab}
              setTab={(tab) => props.actions.clientPatch({ tab })}
            />
          </Grid>
        )}
        {!props.busy &&
          <div className="client-dialog">
            {((state.open && !smallModal) || (smallModal && props.tab === 'generalinfo')) &&
              <div style={{ display: 'flex', flexDirection: smallModal ? 'column' : 'row' }}>
                <div className={smallModal ? '' : 'client-dialog-right'}>
                  <div style={{ marginBottom: 8 }}>
                    <label style={{ fontSize: 14 }}>
                      Professional:
                    </label>
                    <br />
                    {professional}
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <label style={{ fontSize: 14 }}>
                      Birthday:
                    </label>
                    <br />
                    {props.client.birthday &&
                      parseBirthday(props.client.birthday)}
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <label style={{ fontSize: 14 }}>
                      Phone:
                    </label>
                    <br />
                    {props.client.phone}
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <label style={{ fontSize: 14 }}>
                      Email:
                    </label>
                    <br />
                    {props.client.email}
                  </div>
                  {props.client.referrer && /* props.client.referrer !== 'None' && */
                    <div style={{ marginBottom: 8 }}>
                      <label style={{ fontSize: 14 }}>
                        Referrer:
                      </label>
                      <br />
                      {props.client.referrer}
                      <br />
                    </div>}
                  {props.app && !R.isEmpty(registrationMethods) &&
                    <div style={{ marginBottom: 8 }}>
                      <label style={{ fontSize: 14 }}>
                        App Registration:
                      </label>
                      <p style={{
                        overflowWrap: 'break-word',
                        margin: 0,
                        marginRight: '5px',
                        marginBottom: 4,
                      }}>
                        {registrationMethods}
                      </p>
                    </div>}
                </div>
                {middleColumn()}
                <div className={smallModal ? '' : 'client-dialog-right'}>
                  <div style={{ float: 'left' }}>
                    <label>
                      Categories:
                    </label>
                  </div>
                  {!smallModal && (
                    <div style={{ float: 'right' }}>
                      <div style={{ cursor: 'pointer' }} onClick={() => handleToggle(false)}>
                        <u>See less...</u>
                      </div>
                    </div>
                  )}
                  <br />
                  {R.pipe(
                    R.filter(({ service }) => {
                      return !R.includes(service, optionBlacklist);
                    }),
                    R.map(({ description, service, displayName }) => {
                      const opt = !R.includes(service, R.pathOr([], ['client', 'optout', 'optouts'])(props));
                      return (
                        <Tooltip
                          key={service}
                          arrow
                          placement='bottom'
                          title={description}
                          disableInteractive
                        >
                          <div
                            key={service}
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            <Checkbox
                              name={displayName}
                              checked={opt}
                              onChange={() => {
                                props.actions.updateOptout(
                                  props.client.id,
                                  service,
                                  !opt);
                              }} />
                            {getDisplayName(displayName)}
                          </div>
                        </Tooltip>
                      );
                    }))(props.optoutList)}
                  <br />
                </div>
              </div>}
            {!state.open && !smallModal &&
              <div>
                <ul style={{
                  float: 'left',
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: '-40px'
                }}>
                  <li style={{ display: 'inline' }}>
                    {professional}
                  </li>
                  <li style={{ display: 'inline', marginLeft: '20px', marginRight: '20px' }}>{props.client.phone}</li>
                  <li style={{ display: 'inline' }}>{props.client.email}</li>
                </ul>
                <div style={{ margin: '10px', float: 'right' }}>
                  <div style={{ cursor: 'pointer' }} onClick={() => handleToggle(true)}>
                    <u>Show more...</u>
                  </div>
                </div>
              </div>}
            {!smallModal && (
              <>
                <Divider />
                <Tabs
                  indicatorColor="primary"
                  scrollButtons="auto"
                  variant="scrollable"
                  textColor="primary"
                  onChange={(e, val) => props.actions.clientPatch({ tab: val })}
                  value={props.tab}>
                  {R.includes('SimpleNotes', features) &&
                    <WhateverTab
                      label="Notes"
                      className="tab sked-test-client-dialog-notes"
                      value='notes'
                    />}
                  {R.includes('CarePlan', features) &&
                    <WhateverTab
                      label="Care plan"
                      className="tab sked-test-client-dialog-care-plan"
                      value='careplan'
                    />}
                  {/*R.includes('Timeline', features) &&
                      <WhateverTab label="Timeline" className="tab" value='timeline' />*/}
                  <WhateverTab
                    label="Appointments"
                    className="tab sked-test-client-dialog-appoiuntments"
                    value='apts'
                  />
                  <WhateverTab
                    label="Group"
                    className="tab sked-test-client-dialog-group"
                    value='group'
                  />
                  {R.includes('Forms', features) && !isSpark &&
                    <WhateverTab
                      label="Forms"
                      className="tab sked-test-client-dialog-forms"
                      value='forms'
                    />}
                  {R.includes('StopManager', features) &&
                    <WhateverTab
                      label="Alerts"
                      className="tab sked-test-client-dialog-alerts"
                      value='stops'
                    />}
                  <WhateverTab
                    label="Messages"
                    className="tab sked-test-client-dialog-messages"
                    value='msgs'
                  />
                  {/* <WhateverTab label="Message Thread" className="tab" value='msg-thread' /> */}
                  {R.includes('Jail', features) &&
                    <WhateverTab
                      label="Jail"
                      className="tab sked-test-client-dialog-jail"
                      value='jail'
                    />}
                  <WhateverTab
                    label="More"
                    className="tab sked-test-client-dialog-more"
                    value='more'
                  />
                </Tabs>
              </>
            )}
            <div hidden={props.tab !== 'notes'}>
              <SimpleNotes
                client={props.client}
                tz={props.tz}
                open={props.tab === 'notes'}
                tab={smallModal ? notesTab : undefined}
                setTab={smallModal ? setNotesTab : undefined}
              />
            </div>
            <div hidden={props.tab !== 'jail'}>
              <SKEDJail
                client={props.client}
                isCheckIn={R.includes('VWRoom', features)}
              />
            </div>
            <div hidden={props.tab !== 'careplan'}>
              {careplanSettings(
                props.frequencies,
                props.currentFreq,
                state,
                patch,
                props.client.id,
                props.actions
              )}
            </div>
            <div hidden={props.tab !== 'apts'}>
              <div
                className="scroll-content"
                style={{ minHeight: state.open ? '30vh' : '40vh' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: smallModal ? 'column' : 'row',
                    justifyContent: 'space-between'
                  }}>
                  <div style={{ alignSelf: smallModal ? 'flex-start' : 'center' }}>
                    {(R.includes('Calendar', props.features) ||
                      R.includes('NewCalendar', props.features)) &&
                      <Button
                        variant='contained'
                        className='sked-test-client-dialog-appointments-add-button'
                        onClick={() => openScheduler()}>
                        Add
                      </Button>}
                  </div>
                  <div className="datepicker startPicker">
                    <SmallerTextField
                      label="Start Date"
                      type='date'
                      value={props.startDate}
                      onChange={(e) => {
                        const v = e.target.value;
                        if (v)
                          props.actions.updateRange(
                            props.client,
                            v,
                            props.endDate,
                            props.page,
                            props.filter);
                      }
                      } />
                  </div>
                  <div
                    style={{ marginLeft: '5px' }}
                    className="datepicker startPicker">
                    <SmallerTextField
                      label="End Date"
                      type='date'
                      value={props.endDate}
                      onChange={
                        (e) => {
                          const v = e.target.value;
                          if (v)
                            props.actions.updateRange(
                              props.client,
                              props.startDate,
                              v,
                              props.page,
                              props.filter);
                        }
                      } />
                  </div>
                  <FormControl
                    style={{
                      marginTop: 10,
                      minWidth: 132,
                      maxWidth: smallModal ? 208 : 350,
                      marginBottom: smallModal ? 15 : 0,
                    }}>
                    <InputLabel id="select-status">Status</InputLabel>
                    <Select
                      labelId="select-status"
                      label="Status"
                      value={props.filter}
                      onChange={(event) => {
                        props.actions.changeFilter(
                          props.client,
                          props.startDate,
                          props.endDate,
                          props.page,
                          { value: event.target.value }
                        );
                      }}
                    >
                      {options.map(opt =>
                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                {props.aptsBusy ?
                  <div style={{ paddingTop: '60px' }}><div className="loader"></div></div> :
                  <AptsTable
                    apts={props.appointments}
                    client={props.client}
                    aptState={props.aptState}
                    tz={props.tz}
                    professionals={props.professionals}
                    viewApt={props.actions.viewApt}
                  />
                }
              </div>
            </div>
            <div hidden={props.tab !== 'group'}>
              <Group
                group={props.group}
                groupId={props.groupId}
                client={props.client}
                open={state.open} // determines the size of the content
                selectClient={(cli) => {
                  props.actions.getCurrentClient(
                    cli,
                    ZonedDateTime.parse(props.startDate + 'T00:00:00.000Z'),
                    ZonedDateTime.parse(props.endDate + 'T23:59:59.999Z'),
                    1,
                    props.filter
                  );
                }}
                allowEdit={props.ehrSystem === 'None'}
                updateGroup={({ group, groupId }) => props.actions.clientPatch({ group, groupId })}
              />
            </div>
            <div hidden={props.tab !== 'forms'}>
              <Grid marginX={-2} className="scroll-content" style={{ minHeight: state.open ? '30vh' : '40vh' }}>
                <ClientFormList
                  client={props.client}
                  tz={props.tz}
                  smallModal={smallModal}
                  open={props.tab === 'forms'}
                />
              </Grid>
            </div>
            <div hidden={props.tab !== 'stops'}>
              <Grid marginX={-2} className="scroll-content" style={{ minHeight: state.open ? '30vh' : '40vh' }}>
                <StopManagers
                  client={props.client}
                  tz={props.tz}
                />
              </Grid>
            </div>
            <div hidden={props.tab !== 'msgs'}>
              <div>
                <MessageTab
                  features={props.features}
                  messages={props.messages}
                  msgBusy={props.msgBusy}
                  client={props.client}
                  tz={props.tz}
                  actions={props.actions}
                  messagePage={props.messagePage}
                  tab={smallModal ? messageTab : undefined}
                  setTab={smallModal ? setMessageTab : undefined}
                />
              </div>
            </div>
            {props.tab === 'msg-thread' &&
              <div>
                <InboxChat
                  clientId={props.client.id}
                  fullPage={false}
                  heightMod={270}
                />
              </div>}
            {props.tab === 'more' &&
              <Grid marginX={smallModal ? -2 : 0}>
                <More
                  features={features}
                  client={props.client}
                  tz={props.tz}
                  items={filteredItems}
                  tab={smallModal ? moreTab : undefined}
                  setTab={smallModal ? setMoreTab : undefined}
                />
              </Grid>}
          </div>
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = state => R.merge(state.clientDialog, {
  isOptedOutTwoWay: R.includes('TwoWayMessages', R.pathOr([], ['clientDialog', 'client', 'optout', 'optouts'], state)),
  isOptedOutSMS: !R.pathOr(true, ['clientDialog', 'client', 'optout', 'sms'], state),
  tz: state.login.office.timezone,
  features: R.pathOr([], ['login', 'features'])(state),
  ehrSystem: R.pathOr(null, ['login', 'office', 'ehrSystem'])(state),
  timeIntervals: R.pathOr(15, ['login', 'ehrSettings', 'blockLength'])(state),
  professionals: R.sortBy(R.prop('isHidden'), state.professionals.professionals),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  getProfessionals: (h, e) => dispatch(getProfessionals(h, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditDialog);
