import * as at from '../../actionTypes';
import { remoteAction } from '../../services/actionService.js';
import api from '../../services/api.js';
import {
  sort, ascend, prop,
  has, propEq, merge,
  find, includes, pipe,
  map, mergeAll, uniqBy,
  concat, assoc
} from 'ramda';
import { LocalDate, ZoneId, ZoneOffset, DateTimeFormatter } from '@js-joda/core';
import { tzParseFormat } from '../../services/joda.js';
import { getAppointmentType, getClient } from '../Appointments/appointments.service';
import {
  fetchAppointments, fetchApptEvents
} from '../CalendarV2/services/getCalendarApi.js';

export const SCHEDULED = 'scheduled';
export const IN_LOBBY = 'in-lobby';
export const IN_ROOM = 'in-room';
export const SEEN = 'seen';
export const CANCELED = 'canceled';
export const MISSED = 'missed';
export const RESCHEDULED = 'rescheduled';

export const getInitial = () => api.get('waitingroom/initial');

export const getStopManagers = (body = { perPage: 1000, page: 1, query: {} }) => {
  return api.post('stops/query', body).catch(e => {
    console.log('error getting initial stops');
    console.error(e);
    return {
      data: []
    };
  });
};

export const updateFrontdeskMessage = ({ stop, status }) => api.put(`stops/${stop.id}`, {
  done: status,
});

export const getStop = (id) => api.get(`stops/${id}`);

const getRoomCalling = () => {
  try {
    return JSON.parse(localStorage.roomCalling);
  } catch (e) {
    return false;
  }
};

const saveRoomCalling = b => {
  localStorage.roomCalling = JSON.stringify(b);
};

export const getSettings = () => remoteAction({
  type: at.REMOTE_WAITING_GET_SETTINGS,
  action: () => Promise.all([
    api.get('office/settings'),
    api.get('waitingroom/settings'),
    api.get('waitingroom/room')
  ]).then(([office, waiting, rooms]) => ({
    roomCalling: getRoomCalling(),
    geofenceSetting: office.geofenceSetting,
    messageText: waiting.messageText,
    checkInMethod: office.checkInMethod,
    checkInAfterMinutes: waiting.checkInAfterMinutes,
    checkInBeforeMinutes: waiting.checkInBeforeMinutes,
    minutesUntilMissed: waiting.minutesUntilMissed || 5,
    gotoRoomMessage: waiting.gotoRoomMessage || '{{client.firstName}}, please go to {{room.name}}.',
    gotoRoomMessageEnabled: waiting.gotoRoomMessageEnabled || false,

    roomsSyncEnabled: waiting.roomsSyncEnabled,
    rooms: sort(ascend(prop('index')), rooms),
  }))
});

const addRoom = ({ name, displayName, index }) => api.post('waitingroom/room', { name, displayName, index });
const deleteRoom = ({ id }) => api.delete(`waitingroom/room/${id}`);
const updateRoom = ({ id, name, displayName, index }) => api.put(`waitingroom/room/${id}`, { name, displayName, index });

const saveRooms = rooms => {
  const roomsToAdd = rooms.filter(r => r.id < 0);
  const roomsToDelete = rooms.filter(r => r.id > 0 && r.isDeleted);
  const roomsToUpdate = rooms.filter(r => r.id > 0 && !r.isDeleted && r.isTouched);

  const addRooms = roomsToAdd.map(addRoom);
  const deleteRooms = roomsToDelete.map(deleteRoom);
  const updateRooms = roomsToUpdate.map(updateRoom);

  const promises = [].concat(addRooms, deleteRooms, updateRooms);

  return Promise.all(promises);
};

export const saveSettings = ({
  messageText,
  geofenceSetting,
  minutesUntilMissed,
  rooms,
  roomCalling,
  hasFrontdesk,
  checkInBeforeMinutes,
  checkInAfterMinutes,
  checkInMethod,
  gotoRoomMessage,
  gotoRoomMessageEnabled,
  roomsSyncEnabled
}) => remoteAction({
  type: at.REMOTE_WAITING_SAVE_SETTINGS,
  successText: 'Settings saved',
  action: () => Promise.all([
    api.put('office/settings', { geofenceSetting, checkInMethod }),
    api.post('waitingroom/settings', {
      messageText,
      minutesUntilMissed,
      checkInBeforeMinutes,
      checkInAfterMinutes,
      gotoRoomMessage,
      gotoRoomMessageEnabled,
      roomsSyncEnabled
    }),
    hasFrontdesk ? saveRooms(rooms) : Promise.resolve()
  ]).then(() => {
    saveRoomCalling(roomCalling);
  })
});


export const setWaitingState = f => (dispatch, getStore) => {
  const root = getStore();
  const state = root.waiting.state;
  const newState = f(state);
  return dispatch({
    type: at.WAITING_SET_STATE,
    data: newState
  });
};

const getDayRange = (date, timezone) => {
  const start = LocalDate
    .parse(date)
    .atStartOfDayWithZone(ZoneId.of(timezone))
    .withZoneSameInstant(ZoneOffset.UTC)
    .format(DateTimeFormatter.ISO_INSTANT);

  const end = LocalDate
    .parse(date)
    .plusDays(1)
    .atStartOfDayWithZone(ZoneId.of(timezone))
    .format(DateTimeFormatter.ISO_INSTANT);

  return {
    after: start,
    before: end
  };
};

const showNext = (nextAppt, tz, pros) => {
  if (nextAppt.data.length === 0) {
    return { time: 'None', apptType: '' };
  }


  const a = nextAppt.data[0];
  const pro = pros.find(propEq('id', a.appointmentType.professionalId));
  return {
    time: tzParseFormat(a.time, tz, 'EE MM/dd/yyyy h:mm a'),
    apptType: a.appointmentType.name + (pro ? (' with ' + pro.firstName + ' ' + pro.lastName) : '')
  };
};

const getNextAppointment = (clientId) => {
  const now = new Date().toISOString().replace('Z', '');
  return api.post('appointment/query', {
    query: {
      after: now,
      clientId,
      status: ['Scheduled']
    },
    perPage: 1,
    page: 0
  });
};

export const updateWithNextAppt = (appt) => (dispatch, getStore) => {
  return getNextAppointment(appt.client.id).then(nextAppt => {
    const store = getStore();
    const tz = store.login.office.timezone;
    const proState = store.professionals;
    const display = showNext(nextAppt, tz, proState.professionals);
    //Get most recent appointment
    const existingAppt = store.waiting.state.appointments.find(propEq('id', appt.id));
    const updatedAppt = {
      ...existingAppt,
      nextAppt: display
    };
    dispatch({
      type: 'WAITING_UPDATE_NEXT_APPT',
      data: updatedAppt
    });
  });
};

export const handleApptEvent = (data) => (dispatch, getStore) => {
  console.log('handle appt event', data);
  const store = getStore();
  const tz = store.login.office.timezone;
  const state = store.waiting.state;

  const today = LocalDate.now().toString();
  const range = getDayRange(today, tz);

  if (data.time < range.after || data.time > range.before) {
    console.log('date and time out of range');
    return;
  }

  const existingAppt = state.appointments.find(propEq('id', data.id));
  if (existingAppt) {
    const stop = find(propEq('appointmentId', data.id), state.stops);
    /* If it's back to scheduled or missed, update that, otherwise use the exising
       waiting room status
       Any other status changes will be handled by the waiting room update handler
    */
    const status = has('Scheduled', data.status) ||
      has('Missed', data.status) || has('Canceled', data.status) ?
      SCHEDULED : existingAppt.waitingRoomStatus;
    const appt = merge(data, {
      waitingRoomStatus: status,
      client: existingAppt.client,
      appointmentType: existingAppt.appointmentType,
      arrivedAt: existingAppt.arrivedAt,
      stop
    });

    //Update Appt
    dispatch(updateApptScheduled(appt));
    return dispatch(updateWithNextAppt(appt));
  }

  //Appointment isn't in front desk
  //get it
  return Promise.all([
    getClient(data.clientId),
    getAppointmentType(data.appointmentTypeId)
  ]).then(([client, appointmentType]) => {
    const stop = find(propEq('appointmentId', data.id), state.stops);
    const appt = merge(data, {
      waitingRoomStatus: SCHEDULED,
      client,
      appointmentType,
      stop
    });

    //Update Appt
    dispatch(updateApptScheduled(appt));
    return dispatch(updateWithNextAppt(appt));
  }).catch(() => {
    console.log('error getting client and appointment type for appointment update');
  });
};

const ehrList = ['Platinum', 'ChiroTouch', 'Genesis'];

export const handleWaitingRoomUpdate = (status, data) => (dispatch, getStore) => {
  const store = getStore();
  const state = store.waiting.state;

  if (state.blockId && data.appointment.id === state.blockId) {
    console.log('Blocking appt udpate from WS.');
    return dispatch({
      type: 'WAITING_SET_STATE',
      data: {
        ...state,
        blockId: null,
      }
    });
  }

  const {
    roomNumber,
    appointment,
    arrivedAt,
    client,
    appointmentType,
    source,
  } = data;

  const from = includes(source, ehrList) ? 'ehr' : 'sked';
  const current = state.appointments.find(propEq('id', appointment.id));
  const stop = find(propEq('appointmentId', appointment.id), state.stops);
  const appt = merge(appointment, {
    waitingRoomStatus: status,
    roomNumber,
    arrivedAt,
    client,
    appointmentType,
    from,
    stop,
    nextAppt: current && current.nextAppt
  });

  dispatch(updateApptScheduled(appt));

  if (status === IN_ROOM || status === SEEN) {
    dispatch(updateWithNextAppt(appt));
  }

  if (status === IN_ROOM) {
    callToRoom({
      roomId: roomNumber,
      client,
      settings: store.waiting.settings
    });
  }
};

const callToRoom = ({ roomId, client, settings }) => {
  const room = settings.rooms.find(r => r.id === roomId);
  if (room && SpeechSynthesisUtterance && window.speechSynthesis && settings.roomCalling) {
    const utterThis = new SpeechSynthesisUtterance(`${client.firstName} ${client.lastName}, to ${room.name}`);
    const voices = window.speechSynthesis.getVoices();
    const defaultVoice = voices.find(v => v.default);
    const pref = voices.find(v => v.name === 'Samantha');
    utterThis.voice = pref || defaultVoice;
    window.speechSynthesis.speak(utterThis);
  }
};

export const updateApptScheduled = (apptUpdate) => ({
  type: at.WAITING_UPDATE_APPT_SCHEDULED,
  data: apptUpdate
});

export const updateApptOptimistic = (data) => {
  return ({
    type: 'WAITING_APPT_OPT',
    data
  });
};

export const initFrontDesk = (overrides) => ({
  type: 'WAITING_INIT',
  data: overrides,
});

export const frontDeskLeavePage = () => ({
  type: 'WAITING_LEAVE_PAGE'
});


//Get initial data functions

const setInitial = (data, state, proState, syncEnabled) => {
  const format = ({ status, items = [] }) => {
    return pipe(
      map(a => {
        const from = includes(a.source, ehrList) ? 'ehr' : 'sked';
        const id = a.id ? a.id : a.appointment.id;
        const stop = find(propEq('appointmentId', id))(state.stops);
        const professional = find(propEq('id', a.appointmentType.professionalId), proState.professionals);
        return mergeAll([a.appointment, a, { from, waitingRoomStatus: status, professional, stop }]);
      }),
      uniqBy(prop('id'))
    )(items);
  };
  const inLobby = format({ status: IN_LOBBY, items: data.checkedIn });
  const roomed = format({ status: IN_ROOM, items: data.toRoom });
  const seen = format({ status: SEEN, items: data.arrived });
  const appointments = pipe(
    concat(inLobby),
    concat(roomed),
    concat(seen),
    uniqBy(prop('id'))
  )(syncEnabled ? [] : state.appointments);
  return appointments;
};

export const getInitialState = () => (dispatch, getStore) => {
  const today = LocalDate.now().toString();
  const tz = getStore().login.office.timezone;

  dispatch({
    type: 'WAITING_LEAVE_PAGE'
  });
  dispatch({
    type: 'WAITING_REMOTE_GET_STATE',
    state: 'REQUEST'
  });

  return Promise.all([
    getInitial(),
    fetchAppointments({
      date: today,
      timezone: tz,
      view: 'day',
      status: ['Scheduled', 'Missed']
    }),
    fetchApptEvents({
      date: today,
      timezone: tz,
      view: 'day',
      status: ['Canceled']
    }),
    getStopManagers()
  ]).then(([frontDeskStatuses, scheduled, canceled, stops]) => {
    const store = getStore();
    const proState = store.professionals;
    const waitingState = store.waiting.state;

    const syncEnabled = store.waiting.settings.roomsSyncEnabled
                         && store.login.office.ehrSystem === 'Genesis';
    const frontDeskAppointments = setInitial(frontDeskStatuses, waitingState, proState, syncEnabled);
    const canceledAppts = canceled.map(prop('appointment'));
    const updatedSchedule = scheduled.concat(canceledAppts).map((apt) => {
      /* event log doesn't contain the appointment type so we much find one
         there is probably one in the list of types from the professionals state
      */
      let apptType = apt.appointmentType;
      if (!apptType) {
        apptType = find(propEq('id', apt.appointmentTypeId), proState.types);
      }
      const pro = apptType &&
        find(propEq('id', apptType.professionalId), proState.professionals);
      return pipe(
        assoc('waitingRoomStatus', SCHEDULED),
        assoc('professional', pro),
        assoc('appointmentType', apptType)
      )(apt);
    });
    const allAppointments = pipe(
      concat(frontDeskAppointments),
      concat(updatedSchedule),
      uniqBy(prop('id')),
      map((apt) => {
        const stop = find(propEq('appointmentId', apt.id), stops.data);
        if (stop) {
          return assoc('stop', stop, apt);
        } else {
          return apt;
        }
      })
    )(waitingState.appointments);

    dispatch({
      type: 'WAITING_REMOTE_GET_STATE',
      state: 'RESPONSE',
      data: {
        appointments: allAppointments,
        stops: stops.data
      }
    });

    allAppointments.reduce((prom, appt) => {
      return prom.then(() => {
        const leaving = getStore().waiting.leaving;
        if (leaving) {
          console.log('leaving, try to reject');
          return Promise.reject(new Error('leaving page stop getting next appt'));
        }
        return dispatch(updateWithNextAppt(appt));
      }).catch((e) => {
        const leaving = getStore().waiting.leaving;
        //Rethrow the error if leaving the page
        if (leaving) {
          console.log('should end promise chain');
          throw e;
        }
      });
    }, Promise.resolve());

  }).catch(e => {
    console.error('error getting initial appointment', e);
    dispatch({
      type: 'WAITING_REMOTE_GET_STATE',
      state: 'ERROR',
      errorMessage: e.message
    });
  });
};
