import * as at from '../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  isSaving: false,
  isShowingHiddenProfessional: false,
  professionals: [],
  professional: { id: 0 },
  data: [],
  form: [],
  selectedId: -1,
  types: [],
  editedTypes: [],
  selectedTypes: [],
  selectedType: {},
  editedSelectedType: {},
  editing: undefined,
  isLoading: false,
  error: {},
  bulkUpdate: {
    loading: false,
    errorMessage: ''
  },
  pie: false,
  fallbacks: [],
};

export default (professionals = init, action) => {
  switch (action.type) {
    case at.PRO_PATCH:
      return R.merge(professionals, action.data);
    case at.PRO_EDIT: {
      const newType = action.data.newType;
      const data = R.dissoc('newType', action.data);
      return R.pipe(
        R.mergeLeft(data),
        R.evolve({
          types: R.map((type) => {
            if (type.id === newType.id) {
              return newType;
            } else {
              return type;
            }
          })
        }))(professionals);
    }
    case at.PRO_TOGGLE_OPEN: {
      return R.evolve({
        types: R.map((type) => {
          if (type.id === action.data.id) {
            return R.assoc('opened', action.data.open, type);
          }
          return type;
        })
      }, professionals);
    }
    case at.PRO_UPDATE_COLOR:
      return R.evolve({
        types: R.map((type) => {
          if (type.id === action.data.id) {
            return R.pipe(
              R.assoc('color', action.data.color),
              R.assoc('edited', true)
            )(type);
          }
          return type;
        })
      }, professionals);
    case at.PRO_REMOTE_PROS_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(professionals, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(professionals, action.data);
      } else if (action.state === 'ERROR') {
        return R.merge(professionals, {
          busy: false,
        });
      }
      return professionals;
    }
    case at.SKEDTYPE_TYPE_DISABLED: {
      if (action.state === 'REQUEST') {
        return R.merge(professionals, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const newTypes = R.filter(({ id }) => id !== action.data.id)(professionals.types);
        return R.merge(professionals, { types: newTypes, busy: false });
      } else if (action.state === 'ERROR') {
        return R.merge(professionals, {
          busy: false,
        });
      }
      return professionals;
    }
    case at.PRO_ADD_PROFESSIONAL:
      if (action.state === 'REQUEST') {
        return R.merge(professionals, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          form: R.reject(R.propEq('id', Number(action.metadata.id))),
          data: R.reject(R.propEq('id', Number(action.metadata.id))),
          selectedId: R.always(R.pipe(
            R.prop('data'),
            R.reject(R.propEq('id', Number(action.metadata.id))),
            R.head,
            R.propOr(-1, 'id')
          )(professionals)),
          isSaving: R.F
        }, professionals);
      } else if (action.state === 'ERROR') {
        return R.merge(professionals, {
          loadError: action.data,
          isLoading: false,
        });
      }
      return professionals;
    case at.PRO_SAVED: {
      const newPro = action.data.newPro;
      const data = R.dissoc('newPro', action.data);
      return R.pipe(
        R.mergeLeft(data),
        R.evolve({
          professionals: (pros) => {
            return R.map((pro) => {
              if (pro.id === newPro.id) {
                return newPro;
              } else {
                return pro;
              }
            })(pros);
          },
          professional: R.always(newPro),
        })
      )(professionals);
    }
    case at.PRO_REMOTE_TYPES_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(professionals, {
          isLoading: true,
          editing: undefined,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(professionals, {
          types: R.sortBy(R.prop('internalName'))(action.data),
          isLoading: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(professionals, {
          loadError: action.data,
          isLoading: false,
          editing: undefined,
        });
      }
      return professionals;
    }
    case at.PRO_SELECT: {
      const professional = action.data.professional;
      return R.merge(
        professionals,
        {
          professional,
          selectedTypes: []
        });
    }
    case at.PRO_REMOTE_SAVE_FALLBACK: {
      if (action.state === 'REQUEST') {
        return R.merge(professionals, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(professionals, {
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(professionals, {
          busy: false,
        });
      }
      return professionals;
    }
    case at.PRO_REMOTE_DELETE_FALLBACK: {
      if (action.state === 'REQUEST') {
        return R.merge(professionals, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(professionals, {
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(professionals, {
          busy: false,
        });
      }
      return professionals;
    }
    case at.APPT_TYPE_TOGGLE_ALL: {
      if (professionals.selectedTypes.length > 0) {
        console.log('togge off all selected appt types');
        return {
          ...professionals,
          selectedTypes: []
        };
      }
      const id = professionals.professional.id;
      const selectedTypes = R.pipe(
        R.filter(R.either(
          () => id === -1,
          R.propEq('professionalId', id)
        )),
        R.map(R.prop('id'))
      )(professionals.types);

      return {
        ...professionals,
        selectedTypes
      };
    }
    case at.APPT_TYPE_TOGGLE: {
      const { typeId } = action.data;
      const selectedIndex = R.findIndex(R.equals(typeId), professionals.selectedTypes);
      if (selectedIndex >= 0) {
        return R.evolve({
          selectedTypes: R.remove(selectedIndex, 1)
        }, professionals);
      }
      return R.evolve({
        selectedTypes: R.append(typeId)
      }, professionals);
    }
    case at.APPT_TYPE_DUPLICATE:
    case at.APPT_TYPE_BULK_UPDATE: {
      if (action.state === 'REQUEST') {
        return {
          ...professionals,
          bulkUpdate: {
            loading: true,
            errorMessage: ''
          }
        };
      } else if (action.state === 'RESPONSE') {
        return {
          ...professionals,
          types: action.data,
          bulkUpdate: {
            loading: false,
            errorMessage: ''
          }
        };
      } else if (action.state === 'ERROR') {
        return {
          ...professionals,
          bulkUpdate: {
            loading: false,
            errorMessage: 'There was an error updating the appt types.'
          }
        };
      }
      return professionals;
    }
    case 'AUTH_LOGOUT':
      return init;
    default:
      return professionals;
  }
};
