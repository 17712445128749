import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  editButton: {
    border: '1.5px solid #D6F3D5',
    borderLeft: 'solid 2px rgba(0,0,0,.2)',
    borderRadius: 0,
    padding: '4px 6px',
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: -2,

    '&:hover': {
      border: '1.5px solid rgba(0,0,0,0.1)',
      borderLeft: 'solid 2px rgba(0,0,0,.2)',
    }
  },
  onlyIconEdit: {
    borderRadius: '50%',
    background: '#D6F3D5',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingRight: 2,
    
    '& > button': {
      borderColor: '#D6F3D5 !important',
    },
  },
  onlyIconEditButton: {
    borderRadius: '50%',
    paddingLeft: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: '#D6F3D5',
    position: 'relative',

    '& > button': {
      borderColor: '#D6F3D5 !important',
    },
  },
  divide: {
    position: 'absolute',
    width: 2,
    height: 23,
    top: 5,
    left: 0,
    background: 'rgba(0,0,0,.2)',
  },
  selectItem: {
    marginBottom: 4,
    padding: 0,
    paddingRight: 8,
  },
  selected: {
    background: 'rgba(0, 139, 207, 0.1)',
    marginBottom: 4,
    padding: 0,
    paddingRight: 8,
  },
  selectFontSize: {
    fontSize: 14,

    '& > span': {
      fontSize: '14px !important',
    }
  },
  hidden: {
    '& .MuiListItemText-primary': {
      color: '#AAA'
    }
  },
  icon: {
    width: 17,
    height: 17,
  }
}));
