import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    width: '100%',
    height: '100%',
    // transition: 'width 0.25s',
  },
  resizeHandle: {
    top: 0,
    right: 0,
    width: 4,
    height: '100%',
    position: 'absolute',
    borderRadius: 3,
    
    '&:hover': {
      background: '#dce3e7'
    }
  }
}));
