import React, { ComponentProps } from 'react';

const FolderIcon = ({ color, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.15718 13.4977C5.28763 13.4977 3.41808 13.4987 1.54853 13.4969C1.1084 13.4965 0.781627 13.2936 0.583238 12.876C0.537175 12.779 0.532215 12.6928 0.581402 12.5853C1.4905 10.5989 2.39625 8.61076 3.29863 6.62099C3.57665 6.00794 4.04799 5.74954 4.67746 5.75006C6.91675 5.7519 9.15607 5.75077 11.3954 5.75077C12.614 5.75077 13.8326 5.75193 15.0511 5.75016C15.4619 5.74956 15.8348 5.85204 16.1304 6.16965C16.5323 6.60152 16.6106 7.15776 16.3448 7.72025C15.9254 8.60796 15.5012 9.4932 15.0793 10.3796C14.7179 11.1389 14.3541 11.897 13.9968 12.6584C13.7315 13.2236 13.3097 13.502 12.7034 13.5C10.8547 13.4938 9.00591 13.4977 7.15718 13.4977ZM0.529254 10.4924C0.55191 10.4454 0.575439 10.3987 0.597105 10.3512C1.30582 8.79521 2.00977 7.23683 2.72836 5.6859C2.8253 5.47667 2.97406 5.28048 3.13703 5.12111C3.40953 4.85462 3.76286 4.80305 4.1255 4.80321C7.33308 4.80466 10.5406 4.80405 13.7482 4.80404H13.9717C13.9717 4.39285 13.9732 4.00654 13.9708 3.62025C13.97 3.50081 13.9741 3.37769 13.949 3.26269C13.8423 2.77277 13.4522 2.4669 12.9395 2.46654C11.065 2.46523 9.19044 2.46433 7.31589 2.46962C7.18509 2.46998 7.11015 2.42635 7.03787 2.31732C6.75308 1.8878 6.45358 1.46893 6.17101 1.03786C5.92978 0.66984 5.60599 0.498196 5.18142 0.500014C3.98381 0.505142 2.78617 0.501593 1.58853 0.501892C0.897078 0.502065 0.500051 0.915052 0.500035 1.63353C0.49997 4.52974 0.500015 7.42596 0.500015 10.3222V10.4851C0.509765 10.4875 0.519504 10.49 0.529254 10.4924Z" fill={color || '#24A0F6'} />
    </svg>
  );
};

export default FolderIcon;
