import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  Tooltip, Paper, Button, Collapse, Typography, InputAdornment, SvgIconProps,
  IconButton, AutocompleteRenderGroupParams, Box, FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Row, Spacer } from '../PageHeader';
import { pickColor } from '../../services/utilities.js';
import { populateProfessional } from '../../routes/CalendarV2/services/oranizeTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import ChevronIcon from '../../icons/Chevron.icon';
import {
  Autocomplete, TextField, Chip, Checkbox
} from '../components.component';
import Circle from '@mui/icons-material/Circle';
import { AppointmentType, Professional } from '../../routes/Appointments/appointments.types';

import { useStyles } from './type-selector.styles';

interface ExpandMoreProps extends SvgIconProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return (
    <ChevronIcon
      {...other}
    />
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    backgroundColor: 'unset',
  },
}));

type TypeDragProps = {
  type: AppointmentType;
  onClick: (id: number) => void;
}

const TypeDrag = ({ type, onClick }: TypeDragProps) => {
  return (
    <Tooltip title={type.name} arrow>
      <div
        style={{
          width: '241px',
          backgroundColor: type.clicked ? '#008BCF' : '#CECECE',
          color: type.clicked ? 'white' : 'black',
          marginTop: '10px',
          marginBottom: '10px',
          cursor: 'pointer',
          border: '1px solid rgb(191, 191, 191)',
          borderRadius: '50px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        onClick={() => onClick(type.id)}
      >
        <div
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '100px',
            backgroundColor: pickColor(type.color),
          }}>
        </div>
        <div
          key={type.id}
          style={{
            margin: '5px 0px 5px 10px',
            width: '190px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}>
          {type.internalName}
        </div>
      </div>
    </Tooltip>
  );
};

type ProContainerProps = {
  pro: Professional;
  update: (value: Professional) => void;
  ProComponent: (pro: Professional) => void;
  children: JSX.Element | JSX.Element[]
}

const ProContainer = ({
  pro,
  update,
  ProComponent,
  children,
}: ProContainerProps) => {
  return (
    <div
      id={`${pro.id}`}
      key={pro.id}
      style={{ width: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            update(R.merge(pro, { hidden: !pro.hidden }));
          }}
        >
          {pro.displayFirstName + ' ' + pro.displayLastName}
          {pro.hidden ?
            <ArrowRightIcon style={{ fontSize: '24px' }} />
            :
            <ArrowDropDownIcon style={{ fontSize: '24px' }} />
          }
        </div>
        {ProComponent &&
          ProComponent(pro) as unknown as string}
      </div>
      {!pro.hidden && children}
    </div>
  );
};

type List1 = {
  value: Professional[];
  name: string;
}

type TypeSelectorProps = {
  selected: number[];
  types: AppointmentType[];
  professionals: Professional[];
  disabled?: boolean;
  onChange: (selected: number[]) => void;
  ProComponent?: (pro: Professional) => void;
}
type TypeSelectorState = {
  state?: string;
  selected?: Professional[];
  unselected?: Professional[];
}

const TypeSelector = (props: TypeSelectorProps) => {
  const [state, setState] = useState({
    state: 'INIT',
    selected: [],
    unselected: [],
  });

  const orderTypes = () => {
    const propBy = (app: AppointmentType): string => {
      return R.prop('professionalId', app)?.toString();
    };
    const groupBy = (apps: AppointmentType[]) => {
      return R.groupBy(propBy)(apps);
    };
    const selected: Professional[] = R.pipe(
      R.map((id: number) => R.find(R.propEq('id', id))(props.types)),
      groupBy,
      R.values,
      R.map((types: AppointmentType[]) => {
        const type = R.head(types);
        if (type) {
          const { professionalId } = type;
          const pro = R.find(R.propEq('id', professionalId))(props.professionals);
          if (pro) {
            return R.merge(pro, { types });
          }
        }
        return null;
      }),
      R.filter((v) => v !== null)
    )(props.selected || []) as Professional[];

    const unselected: Professional[] = R.pipe(
      R.filter((type: AppointmentType) => !R.includes(type.id, props.selected || [])),
      groupBy,
      R.values,
      R.map((types: AppointmentType[]) => {
        const { professionalId } = R.head(types);
        const pro = R.find(R.propEq('id', professionalId))(props.professionals);
        if (pro)
          return R.merge(pro, { types });
        return null;
      }),
      R.filter((v) => v !== null)
    )(props.types) as unknown as Professional[];

    return [selected, unselected];
  };

  useEffect(() => {
    if ((state.state === 'INIT' || props.selected?.length && !state.selected?.length) &&
      !R.isEmpty(props.types) &&
      !R.isEmpty(props.professionals)) {
      const [selected, unselected] = orderTypes();
      setState(R.merge(state, {
        selected,
        unselected,
        state: 'WAITING',
      }));
    }
  }, [props.types, props.professionals, props.selected]);

  useEffect(() => {
    if (state.state === 'WAITING') {
      const selected = R.pipe(
        R.map((pro: Professional) => pro.types),
        R.flatten,
        R.map(({ id }) => id)
      )(state.selected);
      props.onChange(selected);
    }
  }, [state.selected]);

  useEffect(() => {
    return () => {
      setState({
        selected: [],
        unselected: [],
        state: 'INIT',
      });
    };
  }, []);

  const toggleType = (listName: keyof TypeSelectorState, index: number, pro: Professional, id: number) => {
    console.log('toggle type', { listName, index, pro, id });
    const newState = {
      [listName]: R.update(
        index,
        R.evolve({
          types: R.map((t: AppointmentType) => {
            if (id === t.id) {
              return R.merge(t, { clicked: !t.clicked });
            }
            return t;
          }),
        })(pro),
        state[listName] as Professional[]),
    };
    setState({ ...state, ...newState, state: 'WAITING' });
  };

  const selectAll = (listName: keyof TypeSelectorState, isSelected: boolean) => {
    const newState = {
      [listName]: R.map((pro) => {
        return R.evolve({
          types: R.map((t: AppointmentType) => {
            return R.merge(t, { clicked: isSelected });
          })
        }, pro);
      })(state[listName] as Professional[])
    };
    setState({ ...state, ...newState });
  };

  const togglePro = (listName: keyof TypeSelectorState, index: number) => {
    return (newPro: Professional) => {
      const newState = {
        [listName]: R.update(index, newPro, state[listName] as Professional[])
      };
      setState({ ...state, ...newState });
    };
  };

  const pushClicked = (list1: List1, list2: List1) => {
    const newSelected: Professional[] = R.map((pro) => {
      return R.evolve({
        types: R.filter((t: AppointmentType) => t.clicked),
      }, pro);
    })(list1.value) as Professional[];

    const newUnselected = R.pipe(
      R.map((pro: Professional) => {
        return R.evolve({
          types: R.filter((t: AppointmentType) => !t.clicked),
        }, pro);
      }),
      (prop: Professional[]) => R.filter(({ types }: Professional) => !R.isEmpty(types))(prop)
    )(list1.value);

    const updatedExisting = R.map((pro: Professional) => {
      const existingPro: Professional = R.find(R.propEq('id', pro.id))(newSelected) as Professional;
      if (existingPro) {
        const newProTypes = R.map((t: AppointmentType) => R.merge(t, { clicked: false }))(existingPro.types);
        return R.evolve({
          types: R.pipe(
            R.concat(newProTypes),
            R.sortBy(R.prop('internalName'))
          )
        }, pro);
      } else {
        return pro;
      }
    })(list2.value);

    const addedNewSelected = R.pipe(
      R.filter((pro: Professional) => !R.find(R.propEq('id', pro.id))(updatedExisting) && !R.isEmpty(pro.types)),
      R.map(R.evolve({
        types: R.map((t: AppointmentType) => R.merge(t, { clicked: false })),
      })),
      R.concat(updatedExisting),
      R.sortBy(R.prop('firstName'))
    )(newSelected);

    const newState = {
      [list2.name]: addedNewSelected,
      [list1.name]: newUnselected,
    };
    setState({ ...state, ...newState });
  };

  const appointmentList = ({ title, listName }: { title: string; listName: keyof TypeSelectorState }) => {
    const list = state[listName] as Professional[];

    return (
      <Paper style={{ paddingTop: '10px', paddingBottom: '10px', userSelect: 'none' }}>
        <div style={{ width: '100%', textAlign: 'center', marginBottom: '5px' }}>
          {title}
        </div>
        <div
          style={{
            backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '10px',
            height: '400px',
            width: '258px',
            overflowY: 'auto',
            marginBottom: '10px',
          }}>
          {R.isEmpty(list) &&
            <div style={{ color: 'gray' }}>
              <em>
                Empty
              </em>
            </div>}
          {list.map((pro: Professional, ind: number) => {
            const propBy = (prop: AppointmentType) => {
              return R.prop('internalName', prop);
            };
            return (
              <ProContainer
                key={pro.id}
                pro={pro}
                // index={ind}
                ProComponent={props.ProComponent}
                update={togglePro(listName, ind)}>
                {R.pipe(
                  R.sort(R.ascend(propBy)),
                  R.map((type: AppointmentType) => (
                    <TypeDrag
                      key={type.id}
                      type={type}
                      onClick={(id) => toggleType(listName, ind, pro, id)}
                    ></TypeDrag>
                  ))
                )(pro.types) as JSX.Element[]}
              </ProContainer>
            );
          })}
        </div>
        <Row style={{ marginRight: '10px', marginLeft: '10px' }}>
          <Button size="small" variant='outlined' onClick={() => {
            selectAll(listName, true);
          }}>
            Select all
          </Button>
          <Spacer />
          <Button size="small" variant='outlined' onClick={() => {
            selectAll(listName, false);
          }}>
            Unselect all
          </Button>
        </Row>
      </Paper>
    );
  };

  const {
    unselected,
    selected,
  } = state;

  return (
    <div style={{
      display: 'flex',
      alignItems: 'flex-start',
    }}>

      {appointmentList({
        title: 'Unselected Appointment Types',
        listName: 'unselected'
      })}


      <div style={{
        height: '100%',
        alignSelf: 'center',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Button onClick={() => {
          pushClicked({
            value: selected,
            name: 'selected'
          }, {
            value: unselected,
            name: 'unselected'
          });
        }}>
          <ArrowBackIcon />
        </Button>
        <Button style={{ marginTop: '20px' }} onClick={() => {
          pushClicked({
            value: unselected,
            name: 'unselected'
          }, {
            value: selected,
            name: 'selected'
          });
        }}>
          <ArrowForwardIcon />
        </Button>
      </div>

      {appointmentList({
        title: 'Selected Appointment Types',
        listName: 'selected'
      })}
    </div>
  );
};

type FilterApptTypesProps = {
  setApptTypes: (types: AppointmentType | AppointmentType[]) => void;
  professionals: Professional[];
  allTypes: AppointmentType[];
  selectedTypes: AppointmentType | AppointmentType[];
  style?: any;
  multiple?: boolean;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>, p: Professional) => void;
  checkLabel?: string;
  checkFn?: (p: Professional) => boolean;
}

interface ProType {
  [id: number]: AppointmentType[];
}

interface ProTypeCompProps {
  pro: Professional;
  types: AppointmentType[];
  deleteType: (id: number) => () => void;
  lastChild: boolean;
  checked: boolean;
  checkLabel?: string;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>, p: Professional) => void;
}

const ProTypeComp = ({
  pro, types, deleteType, lastChild, checked, checkLabel, onCheck
}: ProTypeCompProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const localOnCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(e, pro);
  };
  const orderedTypes = React.useMemo(() => {
    return R.sortBy(R.propOr('', 'internalName'), types);
  }, [types]);

  return (
    <div className={classes.selectedTypesContainer}>
      <div
        className={classes.proNameContainer}
        style={lastChild && !expanded ? {
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
          boxShadow: 'unset',
          gridTemplateColumns: onCheck ? '3fr 1fr' : '100%',
        } : {
          gridTemplateColumns: onCheck ? '3fr 1fr' : '100%',
        }}>
        <div
          onClick={handleExpandClick}
          className={classes.nameExpander}>
          <Typography>
            {pro.displayFirstName} {pro.displayLastName} ({types.length})
          </Typography>
          <ExpandMore
            className={classes.expand}
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more">
          </ExpandMore>
        </div>
        {onCheck &&
         <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
           <FormControlLabel
             label={checkLabel || 'Special'}
             control={
               <Checkbox
                 value={checked}
                 onChange={localOnCheck}
                 color='primary'
               />
             }
           />
         </div>}
      </div>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}>
        {orderedTypes.map(({ id, internalName, color }: AppointmentType) => {
          return (
            <Chip
              icon={(
                <Circle
                  style={{
                    color: pickColor(color),
                    marginLeft: '8px',
                    width: '0.8em',
                  }}
                />
              )}
              sx={{
                '.MuiChip-deleteIcon': {
                  fontSize: '15px'
                },
              }}
              onDelete={deleteType(id)}
              label={internalName}
            />
          );
        })}
      </Collapse>
    </div>
  );
};

const transSkedBlue = '#EEF5FF';

interface RenderGroupType {
  types: AppointmentType | AppointmentType[];
  list: AppointmentType[];
  params: AutocompleteRenderGroupParams;
  handleCheckAll: (p: AutocompleteRenderGroupParams, c: boolean) => void;
  handleCheck: (p: AutocompleteRenderGroupParams, c: boolean) => void;
  handleExpand: (b: boolean, n: string) => void;
  expand: {[name: string]: boolean};
}

const RenderGroup = ({
  types, list, handleCheckAll, handleCheck, params, handleExpand, expand,
}: RenderGroupType) => {
  const classes = useStyles();

  const [open, setOpen] = useState(expand[params.group]);

  useEffect(() => {
    setOpen(expand[params.group]);
  }, [expand]);

  const [all, some, num, gAll, gSome] = React.useMemo(() => {
    let all = false;
    let some = false;
    let gAll = false;
    let gSome = false;
    if (Array.isArray(types)) {
      const allPro = list.filter(({ professionalName }) => {
        return professionalName === params.group;
      });
      const selected = allPro.filter((ty) => {
        const found = Boolean(types.find((t: AppointmentType) => t.id === ty.id));
        if (found) {
          some = true;
        }
        return found;
      });
      all = selected.length === allPro.length;
      if (String(params.key) === '0') {
        gAll = types.length === list.length;
        gSome = !R.isEmpty(types);
      }
      return [all, some, selected.length, gAll, gSome];
    } else {
      return [false, false, 0, false, false];
    }
  }, [types, list]);

  const word = React.useMemo(() => {
    return R.any(R.identity, R.values(expand));
  }, [expand]);

  const handleButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const n = !open;
    setOpen(n);
    handleExpand(n, params.group);
  };

  return (
    <>
      {String(params.key) === '0' &&
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 120px',
          bgcolor: all ? transSkedBlue : 'white',
          '&:hover': {
            bgcolor: all ? '#DFEDFB' : 'rgba(0,0,0,0.04)',
          },
        }}>
        <li
          key='-1'
          className={gAll ?
            classes.selectedHeaderGroup : classes.headerGroup
          }
          onClick={() => {
            handleCheckAll(params, !gAll);
          }}
          style={gAll || gSome ? {
            color: '#37AEFD',
          } : { color: '#697275', }}>
          <Checkbox
            checked={gAll}
            indeterminate={gSome && !gAll}
            style={{ marginRight: 5 }}
          />
          Select All {
            Array.isArray(types) && types.length ?
              `(${types.length})` : ''
          }
        </li>
        <div
          className={classes.expandButton}
          style={gAll || gSome ? {
            color: '#37AEFD',
          } : {}}>
          <Button
            color="secondary"
            style={{ height: 20 }}
            onClick={() => handleExpand(!word, '')}
          >
            {word ? 'Collapse' : 'Expand'} All
          </Button>
        </div>
      </Box>}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 40px',
          bgcolor: all ? transSkedBlue : 'white',
          '&:hover': {
            bgcolor: all ? '#DFEDFB' : 'rgba(0,0,0,0.04)',
          },
        }}>
        <li
          key={params.key}
          className={all ?
            classes.selectedHeaderGroup : classes.headerGroup
          }
          onClick={() => {
            handleCheck(params, !all);
          }}
          style={all || some ? {
            color: '#37AEFD',
            paddingLeft: 8,
          } : {
            color: '#697275',
            paddingLeft: 8,
          }}>
          <Checkbox
            checked={all}
            indeterminate={some && !all}
            style={{ marginRight: 5 }}
          />
          {params.group} {num ? `(${num})` : ''}
        </li>
        <div
          className={classes.expandButton}
          style={all || some ? {
            color: '#37AEFD',
          } : {}}>
          <IconButton
            onClick={handleButton}>
            <ExpandMore
              className={classes.expandMore}
              expand={open}
              aria-expanded={open}
              aria-label="show more">
            </ExpandMore>
          </IconButton>
        </div>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {params.children}
      </Collapse>
    </>
  );
};

export const ApptTypeSelector = ({
  selectedTypes, professionals, allTypes, style, multiple, setApptTypes,
  onCheck, checkLabel, checkFn,
}: FilterApptTypesProps) => {
  const classes = useStyles();
  const [types, setTypes] = useState(selectedTypes);
  const [expand, setExpand] = useState<{[name: string]: boolean}>();

  const handleExpand = (v: boolean, name: string) => {
    console.log(expand, name, v);
    if (name === '') {
      setExpand(
        Object.fromEntries(
          Object.entries(expand).map(
            ([k]) => [k, v]
          )
        ));
    } else
      setExpand(R.assoc(name, v, expand));
  };

  const list: AppointmentType[] = React.useMemo(() => {
    const items = populateProfessional(allTypes, professionals);
    return R.remove(0, 1, items);
  }, [allTypes, professionals]);


  useEffect(() => {
    let hash: {[name: string]: boolean} = {};
    list.forEach(({ professionalName }) => {
      if (!hash[professionalName]) {
        hash = {
          ...hash,
          [professionalName]: true,
        };
      }
    });
    setExpand(hash);
  }, [list]);

  const handleSelect = (value: AppointmentType | AppointmentType[]) => {
    setTypes(value);
    setApptTypes(value);
  };

  const deleteType = (typeId: number) => () => {
    if (Array.isArray(types)) {
      const without = types.filter(({ id }) => id !== typeId);
      setTypes(without);
      setApptTypes(without);
    }
  };

  const filterOptions = (list: AppointmentType[], { inputValue = '' }) => {
    const searchInput = inputValue.toLowerCase();
    return list.filter(({ name, professionalName, internalName }) =>
      name.toLowerCase().indexOf(searchInput) !== -1 ||
      internalName.toLowerCase().indexOf(searchInput) !== -1 ||
      professionalName.toLowerCase().indexOf(searchInput) !== -1
    );
  };

  useEffect(() => {
    if (Array.isArray(selectedTypes)) {
      setTypes(selectedTypes.filter((t: AppointmentType) => Boolean(t)));
    } else {
      setTypes(selectedTypes);
    }
  }, [selectedTypes]);

  const proTypes: ProType = React.useMemo(() => {
    if (Array.isArray(types)) {
      return types
        .filter((t) => Boolean(t))
        .reduce((acc: ProType, value: AppointmentType) => {
          const proId = value.professionalId ? value.professionalId :
            allTypes.find(({ id }) => id === value.id)?.professionalId;
          if (!acc[proId]) {
            acc[proId] = [];
          }
          acc[proId].push(value);
          return acc;
        }, {});
    }
    return null;
  }, [types]);

  const orderedPros = React.useMemo(() => {
    if (proTypes) {
      return R.sortBy(
        R.propOr('', 'lastName'),
        R.keys(proTypes).map((proId) => {
          const pro = professionals
            .find(({ id }: Professional) => Number(proId) === id);
          return pro;
        }));
    }
    return [];
  }, [proTypes]);

  const lastProIndex = React.useMemo(() => {
    return orderedPros.length - 1;
  }, [orderedPros]);

  const handleCheck = (params: AutocompleteRenderGroupParams, check: boolean) => {
    const allPro = list.filter(({ professionalName }) => {
      return professionalName === params.group;
    });
    if (check) {
      if (Array.isArray(types)) {
        const newValue = types.concat(allPro);
        handleSelect(R.uniqBy(R.prop('id'), newValue));
      }
    } else {
      if (Array.isArray(types)) {
        const newValue = types.filter(({ id }) => {
          return !allPro.find((ap) => id === ap.id);
        });
        handleSelect(R.uniqBy(R.prop('id'), newValue));
      }
    }
  };

  const handleCheckAll = (params: AutocompleteRenderGroupParams, check: boolean) => {
    if (check) {
      if (Array.isArray(types)) {
        handleSelect(list);
      }
    } else {
      if (Array.isArray(types)) {
        handleSelect([]);
      }
    }
  };

  const orderedList: AppointmentType[] = React.useMemo(() => {
    if (Array.isArray(list)) {
      const grouped = R.groupBy(R.propOr(0, 'professionalId'), list);
      let orderedGroup = {};
      for (const [key, value] of Object.entries(grouped)) {
        const sorted = R.sortBy(R.propOr('', 'internalName'), value);
        orderedGroup = {
          ...orderedGroup,
          [key]: sorted as AppointmentType[],
        };
      }
      return R.sortBy(
        R.propOr('', 'professionalName'),
        R.flatten(Object.values(orderedGroup)) as AppointmentType[]
      );
    }
  }, [list]);

  return (
    <div style={{
      ...style,
      borderRadius: 7,
      border: '1px lightgray solid',
      position: 'relative',
      maxWidth: 600,
    }}>
      <Autocomplete
        multiple={multiple}
        id="tags-outlined"
        options={orderedList}
        getOptionLabel={(option: AppointmentType) => `${option.internalName || ''} (${option.name || ''})`}
        onChange={(_e: any, value: AppointmentType[]) => handleSelect(value)}
        value={types}
        groupBy={(option: AppointmentType) => option.professionalName}
        disableCloseOnSelect={multiple}
        disableClearable
        autoHighlight
        filterOptions={filterOptions}
        isOptionEqualToValue={(option: AppointmentType, value: AppointmentType) => option.id === value?.id}
        renderTags={() => null}
        className={classes.autocompleteClass}
        sx={{
          '& .MuiPopper-root-MuiAutocomplete-popper': {
            zIndex: 1300,
            transform: 'translate3d(281px, 400px, 0px) !important',
          },
        }}
        renderGroup={(params: AutocompleteRenderGroupParams) => (
          <RenderGroup
            params={params}
            list={list}
            types={types}
            expand={expand}
            handleCheck={handleCheck}
            handleCheckAll={handleCheckAll}
            handleExpand={handleExpand}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            className={selected ?
              classes.selectedAutoItem : classes.autoItem
            }>
            <Checkbox
              style={{ marginRight: 5 }}
              checked={selected}
            />
            {option.internalName} ({option.name})
          </li>
        )}
        renderInput={(params: any) => {
          const proName = (!Array.isArray(types) && (types?.professionalName ||
            list.find(({ id }) => {
              return id === types?.id;
            })?.professionalName)) || '';
          const prefix = !multiple && !Array.isArray(types) && types ?
            `${proName}:` : '';
          return (
            <TextField
              {...params}
              color='primary'
              placeholder={`Select Appt Type${multiple ? 's' : ''}`}
              variant='outlined'
              style={{ width: '100%' }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" style={{ marginTop: 1 }}>
                    {prefix}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {multiple && params.inputProps.value !== '' &&
                  <IconButton
                    aria-label="clear text"
                    style={{ marginRight: '20px' }}
                    onClick={() => {
                      params.inputProps.onChange({
                        target: {
                          ...params.inputProps.ref.current,
                          value: '',
                        },
                      });
                    }}
                  >
                    <CloseIcon style={{ fontSize: '14px' }}/>
                  </IconButton>}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
      <div style={{
        backgroundColor: 'white',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        paddingTop: '33px',
        maxHeight: 440,
        overflow: 'hidden auto',
      }}>
        {multiple && orderedPros.map((pro, idx: number) => {
          if (pro) {
            return (
              <ProTypeComp
                pro={pro}
                types={proTypes[pro.id]}
                deleteType={deleteType}
                lastChild={lastProIndex === idx}
                onCheck={onCheck}
                checkLabel={checkLabel}
                checked={checkFn && checkFn(pro)}
              />
            );
          } else
            return null;
        })}
      </div>
    </div>
  );
};

export default TypeSelector;
