import * as at from '../../actionTypes';
import { AnyAction } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as actionTypes from './clients.actionTypes';

import { Tag } from '../Tags/tags.types';
import { Client } from './clients.types';

type ClientsReducer = {
  busy: boolean;
  clients: Client[]
  state: string;
  currentClient: Client;
  search: string;
  page: number;
  perPage: number;
  searchBy: string;
  totalCount: number;
  totalPages: number;
  status: string[];
  tags: Tag[],
  allTags: Tag[],
  tagsSearch: string;
  selectedTags: Tag[],
  allowNotifications: string[],
}

const init: ClientsReducer = {
  busy: false,
  clients: [],
  state: 'CLIENTS_LIST',
  currentClient: {} as Client,
  search: '',
  page: 1,
  perPage: 25,
  searchBy: 'name',
  totalCount: 0,
  totalPages: 1,
  status: ['Active'],
  tags: [],
  allTags: [],
  tagsSearch: '',
  selectedTags: [],
  allowNotifications: [],
};

export default (clients = init, action: AnyAction): ClientsReducer => {
  switch (action.type) {
    case actionTypes.CLIENTS_PATCH:
      return R.merge(clients, action.data);
    case at.CLIENTS_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(clients, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(clients, {
          clients: action.data.data,
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          page: action.data.page,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clients, {
          busy: action.isCancel,
        });
      }
      return clients;
    }
    case actionTypes.CLIENTS_SELECT:
      return R.merge(clients, action.data);
    case actionTypes.CLIENTS_VIEW_APT:
      return R.merge(clients, action.data);
    case at.CLIENTS_BACK_TO_LIST:
      return R.merge(clients, action.data);
    case at.CLIENTS_SEARCH_PATCH:
      return R.merge(clients, action.data);
    case actionTypes.CLIENTS_DATE_PATCH:
      return R.merge(clients, action.data);
    case at.CLIENTS_UPDATE_CURRENT:
      return R.evolve({
        clients: R.map((client: Client) => {
          if (client.id === action.data.id) {
            return R.merge(client, action.data);
          }
          return client;
        })
      }, clients);
    case at.CLIENTS_REMOTE_GET_TAGS: {
      if (action.state === 'REQUEST') {
        return R.merge(clients, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const allTags = R.sortBy(R.prop('tag'))(action.data) as Tag[];
        return R.merge(clients, {
          busy: false,
          allTags,
          tags: allTags,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clients, {
          busy: false,
        });
      }
      return clients;
    }
    default:
      return clients;
  }
};
